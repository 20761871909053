import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { UIBlockInterceptor, UILanguageInterceptor, VersionInterceptor } from "@ankaadia/ankaadia-shared-frontend";
import { TranslocoService } from "@jsverse/transloco";
import { AuthInterceptor } from "./auth.interceptor";
import { UpdateRecognizerInterceptor } from "./updateRecognizer.interceptor";

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: UIBlockInterceptor, multi: true }, // Important to be the first
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, // Auth Interceptor emits two requests, but apparently cancelles one directly
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UILanguageInterceptor, deps: [TranslocoService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UpdateRecognizerInterceptor, multi: true },
  ],
})
export class InterceptorsModule {}
