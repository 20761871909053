import { errorMessageKey } from "./user-error-messages";

type SingleDocumentSet = "NOTARIZATIONPHRAE" | "TRANSLATIONSET";

const SingleDocumentSetData: Record<SingleDocumentSet, { formats: string[]; errorKey: errorMessageKey }> = {
  NOTARIZATIONPHRAE: {
    formats: ["CERTIFIEDCOPY", "CERTIFIEDCOPY+APOSTILLE"],
    errorKey: errorMessageKey.NOTARIZATIONSALREADYUPLOADED,
  },
  TRANSLATIONSET: {
    formats: ["TRANSLATION", "TRANSLATION+CERTIFICATION", "COPYTRANS"],
    errorKey: errorMessageKey.TRANSLATIONSALREADYUPLOADED,
  },
};

export function isSingleDocumentSet(fileType: string): boolean {
  return !!SingleDocumentSetData[<SingleDocumentSet>fileType];
}

export function getSingleDocumentSetTypes(): string[] {
  return Object.keys(SingleDocumentSetData);
}

export function getSingleDocumentSetFormats(fileType: string): string[] {
  return SingleDocumentSetData[<SingleDocumentSet>fileType].formats;
}

export function getSingleDocumentSetErrorKey(fileType: string): errorMessageKey {
  return SingleDocumentSetData[<SingleDocumentSet>fileType].errorKey;
}
