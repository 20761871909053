<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <div class="p-datatable" *ngIf="email.to">
    <table class="p-datatable-table">
      <thead class="p-datatable-thead">
        <tr>
          <th>{{ t("name.title") }}</th>
          <th>{{ t("email.title") }}</th>
          <th>{{ t("invited.title") }}</th>
        </tr>
      </thead>
      <tbody class="p-datatable-tbody">
        <tr *ngFor="let to of email.to">
          <td>{{ to.name }}</td>
          <td>{{ to.email }}</td>
          <td class="text-center">
            <i *ngIf="to.invited" class="pi pi-check p-success"></i>
            <i *ngIf="!to.invited" class="pi pi-times p-error"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
