import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, Version } from "@angular/core";
import { Observable } from "rxjs";
import { APP_VERSION } from "../services/tokens.model";

@Injectable({ providedIn: "root" })
export class VersionInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_VERSION) private readonly version: Version) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes("graphql")) {
      req = req.clone({
        headers: req.headers.append("X-App-Version", this.version.full),
      });
    }
    return next.handle(req);
  }
}
