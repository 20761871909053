import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { combineLatest, filter } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { MainComponent } from "../main/main.component";
import { IndexedMenuItem } from "../menu/menu.model";
import { MenuService } from "../menu/menu.service";
import { ISideBar } from "./sidebar.component.model";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrl: "./sidebar.component.scss",
})
export class SidebarComponent implements OnInit, ISideBar {
  sections: IndexedMenuItem[] = [];

  get helpUrl(): string {
    return this.menuService.helpUrl;
  }

  get activeTabIndex(): number {
    return this.main.activeTabIndex;
  }

  constructor(
    private readonly main: MainComponent,
    private readonly settings: SettingsService,
    private readonly menuService: MenuService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.settings.isAuthenticated$.pipe(filter((x) => !!x)),
      this.settings.isAuthorized$.pipe(filter((x) => !!x)),
    ]).subscribe(() => {
      this.sections = this.menuService.getSections();
      this.setActiveItem();
    });
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => this.setActiveItem());
  }

  onSidebarClick(): void {
    this.main.onSidebarClick();
  }

  closeSidebar(event: Event): void {
    this.main.closeSidebar(event);
  }

  onTabClick(event: Event, index: number): void {
    this.main.onTabClick(event, index);
  }

  openFeedback(): void {
    this.main.userFeedback.open();
  }

  openFirstItem(index: number): void {
    const item = this.sections.find((s) => s.index === index)?.items[0];
    if (item) {
      this.router.navigate(item.routerLink, { relativeTo: this.route });
    }
  }

  private setActiveItem(): void {
    if (this.router.url === "/app") {
      this.main.activeTabIndex = null;
      return;
    }

    for (const section of this.sections) {
      if (
        section.items.some((i) =>
          this.router.isActive(
            this.router.createUrlTree(i.routerLink, { relativeTo: this.route }),
            i.routerLinkActiveOptions?.exact
              ? { paths: "exact", queryParams: "exact", fragment: "ignored", matrixParams: "ignored" }
              : { paths: "subset", queryParams: "subset", fragment: "ignored", matrixParams: "ignored" }
          )
        )
      ) {
        this.main.activeTabIndex = section.index;
        break;
      }
    }
  }
}
