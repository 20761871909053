import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DocumentActivityTypes } from "@ankaadia/ankaadia-shared";
import { ActivityFilterOption, ActivityType } from "@ankaadia/graphql";
import { translate } from "@ngneat/transloco";
import { intersection } from "lodash";
import { BlockableUI } from "primeng/api";
import { Observable, finalize, forkJoin, map, of, startWith, switchMap, tap } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { ActivityService } from "../activity.service";
import { ActivityFilter } from "./activity-filter.model";

@Component({
  selector: "app-activity-filter",
  templateUrl: "./activity-filter.component.html",
})
export class ActivityFilterComponent implements OnInit, BlockableUI {
  private readonly emptyFilter: ActivityFilter = {
    type: null,
    documentType: null,
    date: null,
    organization: [this.organizationId],
    user: null,
    collection: null,
    candidate: null,
    process: null,
  };

  readonly types = [
    { label: translate("enum.ActivityType.CandidateAdded"), value: ActivityType.CandidateAdded },
    { label: translate("enum.ActivityType.CandidateUpdated"), value: ActivityType.CandidateUpdated },
    { label: translate("enum.ActivityType.CandidateDeleted"), value: ActivityType.CandidateDeleted },
    { label: translate("enum.ActivityType.CandidateDeletionReminder"), value: ActivityType.CandidateDeletionReminder },
    { label: translate("enum.ActivityType.CandidateBirthday"), value: ActivityType.CandidateBirthday },
    { label: translate("enum.ActivityType.CandidateBirthdaySoon"), value: ActivityType.CandidateBirthdaySoon },
    { label: translate("enum.ActivityType.FileUploaded"), value: ActivityType.FileUploaded },
    { label: translate("enum.ActivityType.FileModified"), value: ActivityType.FileModified },
    {
      label: translate("enum.ActivityType.DocumentSetMetaDataModified"),
      value: ActivityType.DocumentSetMetaDataModified,
    },
    { label: translate("enum.ActivityType.FileDeleted"), value: ActivityType.FileDeleted },
    { label: translate("enum.ActivityType.FileExpiresSoon"), value: ActivityType.FileExpiresSoon },
    { label: translate("enum.ActivityType.CollectionAdded"), value: ActivityType.CollectionAdded },
    { label: translate("enum.ActivityType.CollectionDeleted"), value: ActivityType.CollectionDeleted },
    { label: translate("enum.ActivityType.CollectionUpdated"), value: ActivityType.CollectionUpdated },
    {
      label: translate("enum.ActivityType.CandidateAddedToCollection"),
      value: ActivityType.CandidateAddedToCollection,
    },
    {
      label: translate("enum.ActivityType.CandidateRemovedFromCollection"),
      value: ActivityType.CandidateRemovedFromCollection,
    },
    {
      label: translate("enum.ActivityType.CollectionEmbeddedIntoCollection"),
      value: ActivityType.CollectionEmbeddedIntoCollection,
    },
    {
      label: translate("enum.ActivityType.CollectionRemovedFromCollection"),
      value: ActivityType.CollectionRemovedFromCollection,
    },
    { label: translate("enum.ActivityType.SharingAdded"), value: ActivityType.SharingAdded },
    { label: translate("enum.ActivityType.SharingDeleted"), value: ActivityType.SharingDeleted },
    { label: translate("enum.ActivityType.SharingUpdated"), value: ActivityType.SharingUpdated },
    { label: translate("enum.ActivityType.SharingEnabled"), value: ActivityType.SharingEnabled },
    { label: translate("enum.ActivityType.SharingDisabled"), value: ActivityType.SharingDisabled },
    { label: translate("enum.ActivityType.SharingTypeModified"), value: ActivityType.SharingTypeModified },
    { label: translate("enum.ActivityType.ProcessCreated"), value: ActivityType.ProcessCreated },
    { label: translate("enum.ActivityType.ProcessDeleted"), value: ActivityType.ProcessDeleted },
    { label: translate("enum.ActivityType.TaskManuallyCreated"), value: ActivityType.TaskManuallyCreated },
    { label: translate("enum.ActivityType.TaskDeleted"), value: ActivityType.TaskDeleted },
    { label: translate("enum.ActivityType.TaskStarted"), value: ActivityType.TaskStarted },
    { label: translate("enum.ActivityType.TaskFinished"), value: ActivityType.TaskFinished },
    { label: translate("enum.ActivityType.TaskCancelled"), value: ActivityType.TaskCancelled },
    { label: translate("enum.ActivityType.TaskFailed"), value: ActivityType.TaskFailed },
    { label: translate("enum.ActivityType.TaskReminderSent"), value: ActivityType.TaskReminderSent },
    { label: translate("enum.ActivityType.EmailNotDelivered"), value: ActivityType.EmailNotDelivered },
    { label: translate("enum.ActivityType.CandidateLoggedIn"), value: ActivityType.CandidateLoggedIn },
    { label: translate("enum.ActivityType.UserLoggedId"), value: ActivityType.UserLoggedIn },
    { label: translate("enum.ActivityType.FeedbackProvided"), value: ActivityType.FeedbackProvided },
    { label: translate("enum.ActivityType.CandidateDroppedOut"), value: ActivityType.CandidateDroppedOut },
    {
      label: translate("enum.ActivityType.AutoProcessOperationTriggered"),
      value: ActivityType.AutoProcessOperationTriggered,
    },
    { label: translate("enum.ActivityType.TaskNoteAdded"), value: ActivityType.TaskNoteAdded },
    { label: translate("enum.ActivityType.CandidateOptedOut"), value: ActivityType.CandidateOptedOut },
    { label: translate("enum.ActivityType.CandidateOptedIn"), value: ActivityType.CandidateOptedIn },
    { label: translate("enum.ActivityType.CandidateNotFound"), value: ActivityType.CandidateNotFound },
    { label: translate("enum.ActivityType.UserNotFound"), value: ActivityType.UserNotFound },
    { label: translate("enum.ActivityType.CollectionNotFound"), value: ActivityType.CollectionNotFound },
    { label: translate("enum.ActivityType.ProcessNotFound"), value: ActivityType.ProcessNotFound },
    { label: translate("enum.ActivityType.DocumentNotFound"), value: ActivityType.DocumentNotFound },
    { label: translate("enum.ActivityType.OrganizationNotFound"), value: ActivityType.OrganizationNotFound },
    { label: translate("enum.ActivityType.TaskNotFound"), value: ActivityType.TaskNotFound },
    {
      label: translate("enum.ActivityType.InvalidSharingTemplate"),
      value: ActivityType.InvalidSharingTemplate,
    },
    {
      label: translate("enum.ActivityType.InvalidUserGroupRoleMapping"),
      value: ActivityType.InvalidUserGroupRoleMapping,
    },
    { label: translate("enum.ActivityType.OrganizationNotLinked"), value: ActivityType.OrganizationNotLinked },
  ];

  @Input()
  disabled: boolean;

  @Input({ required: true })
  organizations: ActivityFilterOption[] = [];

  @Input()
  filter: ActivityFilter;

  @Output()
  readonly filterChange = new EventEmitter<ActivityFilter>();

  @Output()
  readonly busy = new EventEmitter<boolean>();

  form: FormGroup = this.formBuilder.group({
    type: [null],
    documentType: [null],
    date: [null],
    organization: [null],
    user: [null],
    collection: [null],
    candidate: [null],
    process: [null],
  });

  protected showDocumentTypeFilter$: Observable<boolean>;
  users: ActivityFilterOption[];
  collections: ActivityFilterOption[];
  candidates: ActivityFilterOption[];
  processes: ActivityFilterOption[];

  get organizationId(): string {
    return this.settings.organizationId;
  }

  constructor(
    private readonly settings: SettingsService,
    private readonly formBuilder: FormBuilder,
    private readonly activityService: ActivityService,
    private readonly elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.setFormFilter();
    this.form.controls.organization.valueChanges
      .pipe(
        startWith(this.form.controls.organization.value),
        switchMap((organizationIds) => {
          if (!organizationIds?.length) {
            this.resetFilters();
            return of(null);
          }

          this.busy.emit(true);
          return forkJoin([
            this.activityService.getActivityFilterUsers(organizationIds).pipe(tap((x) => (this.users = x))),
            this.activityService.getActivityFilterCollections(organizationIds).pipe(tap((x) => (this.collections = x))),
            this.activityService.getActivityFilterCandidates(organizationIds).pipe(tap((x) => (this.candidates = x))),
            this.activityService.getActivityFilterProcesses(organizationIds).pipe(tap((x) => (this.processes = x))),
          ]).pipe(
            tap(() => {
              this.removeUnavailableOptions(this.users, "user");
              this.removeUnavailableOptions(this.collections, "collection");
              this.removeUnavailableOptions(this.candidates, "candidate");
              this.removeUnavailableOptions(this.processes, "process");
            }),
            finalize(() => this.busy.emit(false))
          );
        })
      )
      .subscribe();

    this.showDocumentTypeFilter$ = this.form.controls.type.valueChanges.pipe(
      startWith(this.form.controls.type.value),
      map((types) => types ?? []),
      map((types) => {
        if (!types.length) {
          return true;
        }

        if (!intersection(types, DocumentActivityTypes).length) {
          this.form.controls.documentType.reset();
          return false;
        }

        return true;
      })
    );
  }

  getBlockableElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  search(): void {
    this.filterChange.emit(this.form.value);
  }

  clear(): void {
    this.form.setValue(this.emptyFilter);
    this.search();
  }

  private setFormFilter(): void {
    const filter = Object.assign({}, this.emptyFilter, this.filter ?? {});
    if (<any>filter.organization === "all") {
      filter.organization = this.organizations.map((x) => x.id);
    }
    this.form.setValue(filter);
    this.search();
  }

  private resetFilters(): void {
    this.users = null;
    this.collections = null;
    this.candidates = null;
    this.processes = null;

    this.form.controls.user.reset();
    this.form.controls.collection.reset();
    this.form.controls.candidate.reset();
    this.form.controls.process.reset();
  }

  private removeUnavailableOptions(options: ActivityFilterOption[], field: keyof ActivityFilter): void {
    const control = this.form.controls[field];
    const selected: string[] = control.value ?? [];
    const unavailable = selected.filter((x) => options.every((y) => y.id !== x));
    if (unavailable.length) {
      const available = selected.filter((x) => !unavailable.includes(x));
      control.setValue(available);
    }
  }
}
