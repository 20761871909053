<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <div class="set-selector flex flex-column gap-5 align-items-stretch" [formGroup]="form">
    <ng-container formArrayName="documentSets">
      <ng-container *ngFor="let documentSetForm of form.controls.documentSets.controls; index as i">
        <div
          *ngIf="!form.controls.selectedSets.value || isSelectedSetForm(documentSetForm)"
          [formGroup]="documentSetForm"
          [class.default-set]="documentSetForm.controls.isDefaultSet.value"
        >
          <app-document-selector
            [form]="documentSetForm"
            [documentForm]="form"
            [mode]="mode"
            [readonly]="readonly"
            [preselectTags]="preselectTags"
            [processLanguage]="processLanguage"
            [singleFileUpload]="isSingleDocumentSet"
            [availableTags]="availableTags"
          >
            <ng-template #toolbar *ngIf="mode === DocumentMode.Candidate">
              <div class="flex align-items-center gap-2 flex-1">
                <div *ngIf="getForeignKeyData(documentSetForm) as data" class="link-indicator">
                  <div><i class="pi pi-link"></i></div>
                  <div>{{ data.index + 1 }}</div>
                </div>
                <div class="flex-1">
                  <ng-container *ngIf="hasDeclaredDocumentSetTypes; else setName">
                    <div *ngIf="documentSetForm.controls.type.value as setType">
                      {{ t($any("documentSet.types." + setType)) }}
                    </div>
                    <small class="p-error" *ngIf="documentSetForm.controls.type.errors?.required">
                      {{ t("type.required") }}
                    </small>
                    <small class="p-error" *ngIf="documentSetForm.controls.type.errors?.forbidden">
                      {{ t("type.forbidden") }}
                    </small>
                    <small class="p-error" *ngIf="form.controls.documentSets.errors?.unique?.includes(i)">
                      {{ t("documentSet.errors.duplicateType") }}
                    </small>
                  </ng-container>
                  <ng-template #setName>
                    <p-autoComplete
                      [suggestions]="foreignKeyData"
                      [dropdown]="foreignKeyData.length > 0"
                      [placeholder]="t('documentSet.placeholder')"
                      [ngModel]="{
                        id: documentSetForm.controls.foreignKey.value,
                        label: getAutoCompleteLabel(documentSetForm),
                      }"
                      [ngModelOptions]="{ standalone: true }"
                      (ngModelChange)="updateDocumentSetName(documentSetForm, $event)"
                      (completeMethod)="cloneForeignKeyData()"
                      dataKey="id"
                      field="label"
                    >
                      <ng-template let-item pTemplate="item">
                        <div class="flex gap-2 align-items-center">
                          <small style="font-weight: 700">({{ item.index + 1 }})</small>
                          <div>{{ item.label }}</div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                    <small class="p-error" *ngIf="documentSetForm.controls.name.errors?.required">
                      {{ t("name.required") }}
                    </small>
                    <small class="p-error" *ngIf="documentSetForm.controls.name.errors?.forbidden">
                      {{ t("name.forbidden") }}
                    </small>
                    <small class="p-error" *ngIf="form.controls.documentSets.errors?.unique?.includes(i)">
                      <ng-container *ngIf="documentSetForm.controls.foreignKey.value; else duplicateName">
                        {{ t("documentSet.errors.duplicateForeignKey") }}
                      </ng-container>
                      <ng-template #duplicateName>
                        {{ t("documentSet.errors.duplicateName") }}
                      </ng-template>
                    </small>
                  </ng-template>
                </div>
                <ng-container *ngIf="documentSetForm.controls.isDefaultSet.value; else notDefaultSet">
                  <i class="default-set-icon pi pi-check-circle"></i>
                </ng-container>
                <ng-template #notDefaultSet>
                  <p-button
                    *ngIf="!isCandidate && isEmpty(form.controls.selectedSets.value)"
                    icon="pi pi-check-circle"
                    class="align-self-center"
                    styleClass="white-space-nowrap mr-0"
                    [pTooltip]="t('documentSet.makeDefault')"
                    (click)="makeDefaultSet(i)"
                    tooltipPosition="left"
                  />
                </ng-template>
                <p-button
                  *ngIf="
                    !isInMetadataMode && !hasDeclaredDocumentSetTypes && !documentSetForm.controls.isDefaultSet.value
                  "
                  class="align-self-center"
                  styleClass="white-space-nowrap mr-0"
                  icon="pi pi-times"
                  [pTooltip]="t('documentSet.remove')"
                  tooltipPosition="left"
                  [disabled]="readonly"
                  (onClick)="removeDocumentSet(i)"
                />
              </div>
            </ng-template>
            <ng-template #footer *ngIf="mode === DocumentMode.Candidate">
              <p-accordion>
                <p-accordionTab
                  [header]="t('additionalInfo.title')"
                  [selected]="expandMetadata || isSelectedSet(documentSetForm)"
                  [headerStyleClass]="
                    (documentSetForm | hasFormErrorsExceptFor: ['files']) ? 'p-accordion-header-error' : ''
                  "
                >
                  <div class="grid">
                    <div class="col-6">
                      <div>{{ t("validFrom.title") }}:</div>
                      <div>
                        <p-calendar
                          #calendar
                          formControlName="validFrom"
                          [showClear]="true"
                          [styleClass]="isSelectedField(documentSetForm, 'validFrom') ? 'p-highlight-input' : null"
                        ></p-calendar>
                        <small class="p-error" *ngIf="documentSetForm.controls.validFrom.errors?.required">
                          {{ t("required") }}
                        </small>
                      </div>
                    </div>
                    <div class="col-6" [class.highlighted-field]="isSelectedField(documentSetForm, 'validUntil')">
                      <div>{{ t("validUntil.title") }}:</div>
                      <div>
                        <p-calendar
                          #calendar
                          formControlName="validUntil"
                          [showClear]="true"
                          [styleClass]="isSelectedField(documentSetForm, 'validUntil') ? 'p-highlight-input' : null"
                        ></p-calendar>
                        <small class="p-error" *ngIf="documentSetForm.controls.validUntil.errors?.required">
                          {{ t("required") }}
                        </small>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>{{ t("issueDate.title") }}:</div>
                      <div>
                        <p-calendar
                          #issueDateCalendar
                          formControlName="issueDate"
                          [maxDate]="today"
                          [showClear]="true"
                          [styleClass]="isSelectedField(documentSetForm, 'issueDate') ? 'p-highlight-input' : null"
                        ></p-calendar>
                        <small class="p-error" *ngIf="documentSetForm.controls.issueDate.errors?.required">
                          {{ t("required") }}
                        </small>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>{{ t("dateOfReceipt.title") }}:</div>
                      <div>
                        <p-calendar
                          #dateOfReceiptCalendar
                          formControlName="dateOfReceipt"
                          [maxDate]="today"
                          [showClear]="true"
                          [styleClass]="isSelectedField(documentSetForm, 'dateOfReceipt') ? 'p-highlight-input' : null"
                        ></p-calendar>
                        <small class="p-error" *ngIf="documentSetForm.controls.dateOfReceipt.errors?.required">
                          {{ t("required") }}
                        </small>
                      </div>
                    </div>
                    <div *ngIf="!isCandidate" class="col-6">
                      <div>{{ t("resubmissionDate.title") }}:</div>
                      <div>
                        <p-calendar
                          #resubmissionDateCalendar
                          formControlName="resubmissionDate"
                          [showClear]="true"
                          [styleClass]="
                            isSelectedField(documentSetForm, 'resubmissionDate') ? 'p-highlight-input' : null
                          "
                          (onClear)="clearResubmissionDate(documentSetForm)"
                        ></p-calendar>
                        <small class="p-error" *ngIf="documentSetForm.controls.resubmissionDate.errors?.required">
                          {{ t("required") }}
                        </small>
                      </div>
                    </div>
                    <div *ngIf="!isCandidate" class="col-6">
                      <div>{{ t("resubmissionReason.title") }}:</div>
                      <input
                        id="resubmissionReason"
                        pInputText
                        formControlName="resubmissionReason"
                        maxlength="300"
                        [readonly]="
                          !form.controls.requirement.value?.resubmissionReason &&
                          !documentSetForm.controls.resubmissionDate.value
                        "
                      />
                      <small class="p-error" *ngIf="documentSetForm.controls.resubmissionReason.errors?.required">
                        {{ t("required") }}
                      </small>
                    </div>
                    <ng-container *ngIf="!isCandidate && !isSingleDocumentSet && availablePhysicalFileTypes.length">
                      <!-- In Papierform vorhanden. Multiselect für den Kandidaten nicht setzbar -->
                      <div class="col-6">
                        <div>{{ t("paperForm.title") }}</div>
                        <p-multiSelect
                          inputId="physicalTypes"
                          [options]="availablePhysicalFileTypes"
                          formControlName="physicalTypes"
                          [placeholder]="t('paperForm.placeholder')"
                          [styleClass]="isSelectedField(documentSetForm, 'physicalTypes') ? 'p-highlight-input' : null"
                          optionLabel="label"
                          optionValue="value"
                        ></p-multiSelect>
                        <small class="p-error" *ngIf="documentSetForm.controls.physicalTypes.errors?.required">
                          {{ t("required") }}
                        </small>
                      </div>
                      <div class="col-6">
                        <div>&nbsp;</div>
                        <button
                          type="button"
                          (click)="takeoverDocumentFormats(documentSetForm)"
                          [style.padding]="'calc(0.75rem + 1px)'"
                          class="w-full p-button block"
                        >
                          {{ t("paperForm.takeover.title") }}
                          <i class="ml-1 pi pi-info-circle" [pTooltip]="t('paperForm.takeover.tooltip')"></i>
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </p-accordionTab>
              </p-accordion>
            </ng-template>
          </app-document-selector>
        </div>
      </ng-container>
    </ng-container>

    <div
      *ngIf="form.errors || form.controls.freeFormatFiles.errors || form.controls.documentSets.errors"
      class="align-self-center text-center"
    >
      <div *ngIf="form.controls.documentSets.errors?.corruptDefault">
        <small class="p-error">
          {{ t("documentSet.errors.corruptDefault") }}
        </small>
      </div>
      <div *ngIf="form.controls.documentSets.errors?.setTypeMismatch">
        <small class="p-error">
          {{ t("documentSet.errors.setTypeMismatch") }}
        </small>
      </div>
      <div *ngIf="form.controls.documentSets.errors?.malformed">
        <small class="p-error">
          {{ t("documentSet.errors.malformed") }}
        </small>
      </div>
      <div *ngIf="form.errors?.empty; else emptyFilesCheck">
        <small class="p-error">
          {{ t("document.empty") }}
        </small>
      </div>
      <ng-template #emptyFilesCheck>
        <div *ngIf="form.controls.documentSets.errors?.requiredFiles">
          <small class="p-error">
            {{ t("document.requiredFiles") }}
          </small>
        </div>
      </ng-template>
      <div *ngIf="form.errors?.document || form.controls.freeFormatFiles.errors?.forbidden">
        <small class="p-error">
          {{ mode === DocumentMode.Template ? t("template.badTags") : t("document.badTags") }}
        </small>
      </div>
    </div>

    <div *ngIf="!hasDeclaredDocumentSetTypes && maxDocumentSets > 1">
      <div class="message-area">
        <p-messages *ngIf="canAddDocumentSet && getRequestedLink() as link" severity="warn">
          <ng-template pTemplate>
            <div class="flex gap-2 align-items-center">
              <i class="pi pi-link font-bold"></i>
              <div>
                <div>{{ t("documentSet.linkRequested", { index: link.index + 1, label: link.label }) }}</div>
                <a
                  [style.cursor]="'pointer'"
                  (click)="addDocumentSet(false, link.label, link.id)"
                  [innerText]="t('documentSet.addLinked')"
                ></a>
              </div>
            </div>
          </ng-template>
        </p-messages>
      </div>
      <p-button
        styleClass="white-space-nowrap mr-0"
        icon="pi pi-plus"
        [pTooltip]="t('documentSet.add')"
        tooltipPosition="left"
        [disabled]="isLocked || !canAddDocumentSet"
        (onClick)="newSet = createSetForm(null, false); writeSelectableForeignKeyData(); add.show($event)"
        [label]="t('documentSet.add')"
      />
    </div>
  </div>
  <p-overlayPanel #add>
    <ng-template pTemplate>
      <div [formGroup]="newSet">
        <div class="field-checkbox" *ngIf="!isCandidate">
          <p-checkbox [binary]="true" [label]="t('documentSet.addDefault')" formControlName="isDefaultSet" />
        </div>
        <div class="flex gap-2 align-items-stretch">
          <p-autoComplete
            [placeholder]="newSet.controls.isDefaultSet.value ? DefaultDocumentSetName : t('name.title')"
            [suggestions]="selectableForeignKeyData"
            [dropdown]="selectableForeignKeyData.length > 0"
            [disabled]="newSet.controls.isDefaultSet.value"
            [ngModel]="{
              id: newSet.controls.foreignKey.value,
              label: getAutoCompleteLabel(newSet),
            }"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="updateDocumentSetName(newSet, $event)"
            (completeMethod)="writeSelectableForeignKeyData()"
            class="flex-1"
            dataKey="id"
            field="label"
          >
            <ng-template let-item pTemplate="item">
              <div class="flex gap-2 align-items-center">
                <small style="font-weight: 700">({{ item.index + 1 }})</small>
                <div>{{ item.label }}</div>
              </div>
            </ng-template>
          </p-autoComplete>
          <button
            submitOnEnter
            type="button"
            pButton
            [label]="t('common.ok')"
            [disabled]="!newSet.controls.isDefaultSet.value && !isSetNameValid(newSet.controls.name.value)"
            (click)="addDocumentSetForm(newSet); add.hide()"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>
</ng-container>
