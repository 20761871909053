import { Injectable } from "@angular/core";
import {
  GetEmploymentApprovalAuthoritiesGQL,
  GetEmploymentApprovalAuthorityByIdGQL,
  StaticDataModel,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class EmploymentApprovalAuthorityService {
  constructor(
    private readonly authorityByIdGet: GetEmploymentApprovalAuthorityByIdGQL,
    private readonly authoritiesGet: GetEmploymentApprovalAuthoritiesGQL
  ) {}

  getEmploymentApprovalAuthorityById(
    id: string,
    organizationId: string,
    immigrationCountry: SupportedImmigrationCountry
  ): Observable<StaticDataModel> {
    return this.authorityByIdGet
      .fetch({ input: { id, organizationId, immigrationCountry } }, { fetchPolicy: "cache-first" })
      .pipe(
        map((x) => x.data.getEmploymentApprovalAuthorityById),
        map((x) => ({ label: `${x.title} - ${x.location} - ${x.responsibility}`, value: x.id }))
      );
  }

  getEmploymentApprovalAuthorities(
    organizationId: string,
    immigrationCountry: SupportedImmigrationCountry
  ): Observable<StaticDataModel[]> {
    return this.authoritiesGet
      .fetch({ input: { organizationId, immigrationCountry } }, { fetchPolicy: "cache-first" })
      .pipe(
        map((x) =>
          x.data.getEmploymentApprovalAuthorities
            .map((x) => ({ label: `${x.title} - ${x.location} - ${x.responsibility}`, value: x.id }))
            .sort((a, b) => a.label.localeCompare(b.label))
        )
      );
  }
}
