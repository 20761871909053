import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { noop } from "lodash";
import { Observable } from "rxjs";
import { StaticDataService } from "../../../shared/static-data/static-data.service";

@Component({
  selector: "app-document-type-selector",
  templateUrl: "./document-type-selector.component.html",
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DocumentTypeSelectorComponent, multi: true }],
})
export class DocumentTypeSelectorComponent implements OnInit, ControlValueAccessor {
  private readonly language = this.transloco.getActiveLang();

  protected model: string[] = [];
  protected disabled: boolean;
  protected types$: Observable<StaticDataModel[]>;

  onModelChange = noop;
  onModelTouched = noop;

  @Input()
  inputId: string;

  @Input()
  formControl: FormControl;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly transloco: TranslocoService,
    private readonly staticData: StaticDataService
  ) {}

  ngOnInit(): void {
    this.types$ = this.staticData.getStaticData(StaticDataType.AllowedUploadFileTypes, this.language);
  }

  writeValue(model: string[]): void {
    this.model = model;
    this.changeDetector.markForCheck();
  }

  registerOnChange(onModelChange: () => void): void {
    this.onModelChange = onModelChange;
  }

  registerOnTouched(onModelTouched: () => void): void {
    this.onModelTouched = onModelTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.changeDetector.markForCheck();
  }

  protected updateModel(model: string[]): void {
    this.model = model;
    this.onModelChange(model);
    this.formControl?.setValue(model);
  }
}
