<ng-container *appTranslate="let t">
  <p-treeTable [value]="diff" [scrollable]="false" [tableStyle]="{ 'min-width': '20rem', 'max-width': '70vw' }">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th>{{ t("name.title") }}</th>
        <th>{{ t("differences.oldValue") }}</th>
        <th>{{ t("differences.newValue") }}</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-node let-diff="rowData">
      <tr>
        <td>
          <p-treeTableToggler [rowNode]="node"></p-treeTableToggler>
          <span [pTooltip]="diff.property">{{ t(diff.captionKey, diff.captionParameter) }}</span>
        </td>
        <td>{{ diff.oldValue }}</td>
        <td>{{ diff.newValue }}</td>
      </tr>
    </ng-template>
  </p-treeTable>
</ng-container>
