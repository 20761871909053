import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { NumberFormatOptions, TranslocoLocaleService } from "@jsverse/transloco-locale";

@Pipe({ name: "appDecimal" })
export class AppDecimalPipe implements PipeTransform {
  private readonly language = this.transloco.getActiveLang();

  constructor(
    private readonly localeService: TranslocoLocaleService,
    private readonly transloco: TranslocoService
  ) {}

  protected currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  transform(value: number, options: NumberFormatOptions, language?: string): string {
    return this.localeService.localizeNumber(value, "decimal", language ?? this.language, options);
  }
}
