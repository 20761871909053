import { Injectable } from "@angular/core";
import {
  ActivityDiffFragment,
  FieldActivityDiffFragment,
  GetActivityDiffGQL,
  GetFieldActivityDiffGQL,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ActivityDiffService {
  constructor(
    private readonly activityDiff: GetActivityDiffGQL,
    private readonly fieldActivityDiff: GetFieldActivityDiffGQL
  ) {}

  getActivityDiff(activityId: string, organizationId: string): Observable<ActivityDiffFragment[]> {
    return this.activityDiff.fetch({ input: { activityId, organizationId } }).pipe(map((x) => x.data.getActivityDiff));
  }

  getFieldActivityDiff(
    entityId: string,
    entityTypeName: string,
    organizationId: string,
    parentPath: string,
    parentEntryId: string,
    path: string
  ): Observable<FieldActivityDiffFragment[]> {
    return this.fieldActivityDiff
      .fetch({ input: { entityId, entityTypeName, organizationId, parentPath, parentEntryId, path } })
      .pipe(map((x) => x.data.getFieldActivityDiff));
  }
}
