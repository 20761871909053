<p-inputNumber
  *ngIf="props.type === 'number'"
  [formControl]="$any(formControl)"
  [readonly]="props.readonly"
  [formlyAttributes]="field"
  [maxFractionDigits]="props.maxFractionDigits"
  [minFractionDigits]="props.minFractionDigits"
/>

<input
  *ngIf="props.type !== 'number'"
  pInputText
  [type]="props.type || 'text'"
  [formControl]="$any(formControl)"
  [readonly]="props.readonly"
  [formlyAttributes]="field"
/>
