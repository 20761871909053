import { DocumentSelectionCriterion } from "./document-selection.model";

export enum DocumentLockMode {
  Unlocked = "Unlocked",
  LockedByCandidateOwner = "LockedByCandidateOwner",
  LockedByAProcessOwner = "LockedByAProcessOwner",
}

export enum DocumentMode {
  Organization = "Organization",
  Template = "Template",
  Candidate = "Candidate",
}

export enum CompletionState {
  Complete = "Complete",
  Incomplete = "Incomplete",
}

export enum FileAccess {
  Full = "Full",
  None = "None",
}

export enum DocumentAccess {
  Full = "Full",
  FullWhenNotInternal = "FullWhenNotInternal",
  Metadata = "Metadata",
  None = "None",
}

export enum DocumentWriteMode {
  Full = "Full",
  Metadata = "Metadata",
}

export interface DocumentModel {
  organizationId: string;
  type?: string;
  candidateId?: string;
  familyMemberId?: string;
  mode: DocumentMode;
  comment?: string;
  documentSets?: DocumentSetModel[];
  freeFormatFiles?: DocumentFreeFormatFileModel[];
  selectionCriteria?: DocumentSelectionCriterion[];
  completionState?: CompletionState;
  isInternalDocument?: boolean;
}

export interface DocumentModelWithAccessData extends DocumentModel {
  isInternalByRequirement: boolean;
}

export interface DocumentSetModel {
  id: string;
  isDefaultSet: boolean;
  name?: string;
  type?: string;
  foreignKey?: string;
  validFrom?: Date;
  validUntil?: Date;
  issueDate?: Date;
  dateOfReceipt?: Date;
  resubmissionDate?: Date;
  resubmissionReason?: string;
  physicalTypes?: string[];
  files?: DocumentFileModel[];
}

export interface DocumentFreeFormatFileModel {
  blob: string;
  name: string;
  size: number;
  type: string;
  createdAt?: Date;
}

export interface DocumentFileModel extends DocumentFreeFormatFileModel {
  tags?: string[];
  selectionValues?: string[][];
  singleSetConfig?: SingleSetConfigModel[];
}

export interface SingleSetConfigModel {
  type: string;
  formats: string[];
}

export function isDocumentSelectionMode(mode: DocumentMode): boolean {
  return mode === DocumentMode.Template || mode === DocumentMode.Organization;
}
