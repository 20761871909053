<ng-container *appTranslate="let t">
  <form [formGroup]="form" (ngSubmit)="search()">
    <h6>{{ t("activityLog.criteria") }}</h6>

    <div class="p-fluid">
      <div class="field">
        <label for="type">{{ t("type.title") }}</label>
        <app-activity-type-selector inputId="type" formControlName="type"></app-activity-type-selector>
      </div>

      <div class="field" *ngIf="showDocumentTypeFilter$ | async">
        <label for="type">{{ t("documentType.title") }}</label>
        <app-document-type-selector inputId="type" formControlName="documentType"></app-document-type-selector>
      </div>

      <div class="field">
        <label for="date">{{ t("date.title") }}</label>
        <p-calendar #date formControlName="date" selectionMode="range" [showClear]="true"></p-calendar>
      </div>

      <div class="field">
        <label for="organization">{{ t("organization.title") }}</label>
        <p-multiSelect
          inputId="organization"
          [options]="organizations"
          optionLabel="name"
          optionValue="id"
          [placeholder]="t('organization.placeholder')"
          [showToggleAll]="false"
          [showClear]="true"
          formControlName="organization"
        ></p-multiSelect>
      </div>

      <div class="field">
        <label for="user">{{ t("user.title") }}</label>
        <p-multiSelect
          inputId="user"
          [options]="users"
          optionLabel="name"
          optionValue="id"
          [placeholder]="t('user.placeholder')"
          [showToggleAll]="true"
          [showClear]="true"
          formControlName="user"
        >
          <ng-template pTemplate="item" let-item>
            <span>{{ item.name }}</span>
            <span *ngIf="item.organizationId && item.organizationId !== organizationId" class="p-text-secondary ml-1">
              ({{ item.organizationName }})
            </span>
          </ng-template>
        </p-multiSelect>
      </div>

      <div class="field">
        <label for="collection">{{ t("collection.title") }}</label>
        <p-multiSelect
          inputId="collection"
          [options]="collections"
          optionLabel="name"
          optionValue="id"
          [placeholder]="t('collection.placeholder')"
          [showToggleAll]="false"
          [showClear]="true"
          formControlName="collection"
        >
          <ng-template pTemplate="item" let-item>
            <span>{{ item.name }}</span>
            <span *ngIf="item.organizationId !== organizationId" class="p-text-secondary ml-1">
              ({{ item.organizationName }})
            </span>
          </ng-template>
        </p-multiSelect>
      </div>

      <div class="field">
        <label for="candidate">{{ t("candidate.title") }}</label>
        <p-multiSelect
          inputId="candidate"
          [options]="candidates"
          optionLabel="name"
          optionValue="id"
          [placeholder]="t('candidate.placeholder')"
          [showToggleAll]="false"
          [showClear]="true"
          formControlName="candidate"
        >
          <ng-template pTemplate="item" let-item>
            <span>{{ item.name }}</span>
            <span *ngIf="item.organizationId !== organizationId" class="p-text-secondary ml-1">
              ({{ item.organizationName }})
            </span>
          </ng-template>
        </p-multiSelect>
      </div>

      <div class="field">
        <label for="process">{{ t("process.title") }}</label>
        <p-multiSelect
          inputId="process"
          [options]="processes"
          optionLabel="name"
          optionValue="id"
          [placeholder]="t('process.placeholder')"
          [showToggleAll]="false"
          [showClear]="true"
          formControlName="process"
        >
          <ng-template pTemplate="item" let-item>
            <span>{{ item.name }}</span>
            <span *ngIf="item.organizationId !== organizationId" class="p-text-secondary ml-1">
              ({{ item.organizationName }})
            </span>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>

    <div class="flex justify-content-between">
      <p-button
        [disabled]="disabled"
        [label]="t('common.clear')"
        icon="pi pi-times"
        type="reset"
        (onClick)="clear()"
      ></p-button>
      <p-button [disabled]="disabled" [label]="t('common.search')" icon="pi pi-search" type="submit"></p-button>
    </div>
  </form>
</ng-container>
