import {
  CustomSkillSet,
  GetProfessionAreasGQL,
  GetProfessionsGQL,
  GetProfessionSkillsGQL,
  GetProfessionSubAreasGQL,
  GetProfessionTreeGQL,
  OccupationSkillSet,
  StaticDataModel,
  TreeDataModel,
  OccupationSkillType,
  FindOccupationSkillsGQL,
  OccupationSkillForFilterFragment,
  SearchOccupationSkillsGQL,
} from "@ankaadia/graphql";
import { Injectable } from "@angular/core";
import { map, Observable, of } from "rxjs";
import { FetchPolicy } from "@apollo/client/core/watchQueryOptions";

@Injectable({ providedIn: "root" })
export class ProfessionService {
  constructor(
    private readonly areasGet: GetProfessionAreasGQL,
    private readonly subAreasGet: GetProfessionSubAreasGQL,
    private readonly professionsGet: GetProfessionsGQL,
    private readonly treeGet: GetProfessionTreeGQL,
    private readonly skillsGet: GetProfessionSkillsGQL,
    private readonly searchOccupationSkills: SearchOccupationSkillsGQL,
    private readonly findOccupationSkillsByIds: FindOccupationSkillsGQL
  ) {}

  getAreas(language: string, organizationId: string, all?: boolean): Observable<StaticDataModel[]> {
    return this.areasGet
      .fetch({ input: { language, organizationId, all } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getProfessionAreas));
  }

  getSubAreas(area: string, language: string, organizationId: string, all?: boolean): Observable<StaticDataModel[]> {
    if (!area) {
      return of(null);
    }
    return this.subAreasGet
      .fetch({ input: { area, language, organizationId, all } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getProfessionSubAreas));
  }

  getProfessions(
    area: string,
    subArea: string,
    language: string,
    organizationId: string,
    all?: boolean
  ): Observable<StaticDataModel[]> {
    return this.professionsGet
      .fetch(
        { input: { area, subArea, language, organizationId: organizationId, all } },
        { fetchPolicy: "cache-first" }
      )
      .pipe(map((x) => x.data.getProfessions));
  }

  getProfessionTree(language: string, organizationId: string, all?: boolean): Observable<TreeDataModel[]> {
    return this.treeGet
      .fetch({ input: { language, organizationId, all } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getProfessionTree));
  }

  getProfessionSkills(
    professionId: string,
    organizationId: string,
    fetchPolicy: FetchPolicy = "network-only"
  ): Observable<readonly [OccupationSkillSet[], CustomSkillSet[]]> {
    if (!professionId || !organizationId) return of([[], []]);
    return this.skillsGet
      .fetch({ professionId, organizationId }, { fetchPolicy: fetchPolicy })
      .pipe(map((x) => [x.data.standard, x.data.custom] as const));
  }

  searchSkills(query: string, skillType: OccupationSkillType): Observable<OccupationSkillForFilterFragment[]> {
    return this.searchOccupationSkills
      .fetch({ input: { query, skillType } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.searchOccupationSkills.skills));
  }

  findOccupationSkills(
    skillIds: string[],
    skillType: OccupationSkillType
  ): Observable<OccupationSkillForFilterFragment[]> {
    return this.findOccupationSkillsByIds
      .fetch({ input: { skillIds, skillType } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.findOccupationSkills.skills));
  }
}
