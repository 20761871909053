import { Component, Input, OnInit } from "@angular/core";
import { Language, mapIso2CodeToIso2CodeLanguage } from "@ankaadia/ankaadia-shared";
import { translate } from "@jsverse/transloco";
import { SettingsService } from "../../../shared/services/settings.service";
import { MessageService } from "../../message/message.service";
import { UsersService } from "../users.service";

@Component({
  selector: "app-communication-language",
  templateUrl: "./communication-language.component.html",
})
export class CommunicationLanguageComponent implements OnInit {
  protected preferredCommunicationLanguage?: Language;

  readonly availableLanguages$ = this.userSvc.getAvailableCommunicationLanguages(this.settings.isCandidate);

  @Input()
  label?: string;

  constructor(
    private readonly userSvc: UsersService,
    private readonly settings: SettingsService,
    private readonly messageService: MessageService
  ) {}

  ngOnInit(): void {
    if (this.settings.communicationLanguage)
      this.preferredCommunicationLanguage = mapIso2CodeToIso2CodeLanguage(this.settings.communicationLanguage);
  }

  onClick(event: Event): void {
    event.stopPropagation();
  }

  setCommunicationLanguage(): void {
    this.userSvc.setCommunicationLanguage(this.preferredCommunicationLanguage.code).subscribe(() => {
      this.messageService.add({ severity: "success", summary: translate("userLanguage.changedSuccess") });
    });
  }
}
