import { Directive } from "@angular/core";
import { Dropdown } from "primeng/dropdown";

@Directive({ selector: "p-dropdown" })
export class DropdownOptionsDirective {
  constructor(private readonly dropdown: Dropdown) {
    this.dropdown.filter = true;
    this.dropdown.autoDisplayFirst = false;
  }
}
