import { Directive, OnInit } from "@angular/core";
import { MultiSelect } from "primeng/multiselect";

/**
 * Fixes null reference errors when trying to display a label of a multi select item
 * before options have been loaded (for example, if they are being loaded via the async pipe).
 *
 * Might be fixed in the current version.
 */
@Directive({ selector: "p-multiSelect" })
export class MultiSelectFindLabelFixDirective implements OnInit {
  private findLabelByValueSource: MultiSelect["findLabelByValue"];

  constructor(private readonly multiSelect: MultiSelect) {}

  ngOnInit(): void {
    this.findLabelByValueSource = this.multiSelect.findLabelByValue;
    this.multiSelect.findLabelByValue = (value: unknown): string => this.findLabelByValue(this.multiSelect, value);
  }

  private findLabelByValue(multiSelect: MultiSelect, value: unknown): string {
    if (!multiSelect.options) {
      return null;
    }
    return this.findLabelByValueSource.call(multiSelect, value);
  }
}
