import { Directive, Optional } from "@angular/core";
import { AutoComplete } from "primeng/autocomplete";
import { Calendar } from "primeng/calendar";
import { CascadeSelect } from "primeng/cascadeselect";
import { ContextMenu } from "primeng/contextmenu";
import { Dropdown } from "primeng/dropdown";
import { Menu } from "primeng/menu";
import { MultiSelect } from "primeng/multiselect";
import { OverlayPanel } from "primeng/overlaypanel";
import { SplitButton } from "primeng/splitbutton";
import { TieredMenu } from "primeng/tieredmenu";
import { TreeSelect } from "primeng/treeselect";

@Directive({
  selector:
    "p-calendar, p-dropdown, p-multiSelect, p-overlayPanel, p-menu, p-tieredMenu, p-autoComplete, p-cascadeSelect, p-splitButton, p-treeSelect, p-contextMenu",
})
export class AppendToBodyDirective {
  constructor(
    @Optional() private readonly calendar: Calendar,
    @Optional() private readonly dropdown: Dropdown,
    @Optional() private readonly multiSelect: MultiSelect,
    @Optional() private readonly overlayPanel: OverlayPanel,
    @Optional() private readonly menu: Menu,
    @Optional() private readonly tieredMenu: TieredMenu,
    @Optional() private readonly autoComplete: AutoComplete,
    @Optional() private readonly cascadeSelect: CascadeSelect,
    @Optional() private readonly splitButton: SplitButton,
    @Optional() private readonly treeSelect: TreeSelect,
    @Optional() private readonly contextMenu: ContextMenu
  ) {
    const element =
      this.calendar ??
      this.dropdown ??
      this.multiSelect ??
      this.overlayPanel ??
      this.menu ??
      this.tieredMenu ??
      this.autoComplete ??
      this.cascadeSelect ??
      this.splitButton ??
      this.treeSelect ??
      this.contextMenu;
    if (element) {
      element.appendTo = "body";
    }
  }
}
