import { Observable, ReplaySubject, Subject } from "rxjs";
import { environment } from "../../../environments/environment";

export function loadFacebookSDK(): Observable<void> {
  const subject = new ReplaySubject<void>(1);
  if (document.getElementById("facebook-jssdk")) {
    subject.next();
    return subject;
  }
  const js = document.createElement("script");
  js.id = "facebook-jssdk";
  js.src = "https://connect.facebook.net/de_DE/sdk.js";
  const head = document.getElementsByTagName("head").item(0);
  head.appendChild(js);
  window["fbAsyncInit"] = function (): void {
    FB.init({
      appId: environment.facebookAppId,
      cookie: false,
      xfbml: true,
      version: "v15.6",
    });
    subject.next();
  };
  return subject;
}

export function getAccessToken(): Observable<string> {
  const subject = new Subject<string>();
  FB.getLoginStatus(function (response) {
    if (response.status === "connected") {
      const accessToken = response.authResponse.accessToken;
      subject.next(accessToken);
      subject.complete();
    }
  });
  return subject;
}

export function loginAtFacebook(
  scope: string,
  additionalOptions: any,
  returnScope = false,
  auth_type: "rerequest" | "reauthenticate" | "reauthorize" = "rerequest"
): Observable<fb.StatusResponse> {
  const subject = new Subject<fb.StatusResponse>();
  FB.login(
    (response) => {
      subject.next(response);
    },
    { auth_type: auth_type, scope: scope, return_scopes: returnScope, ...additionalOptions }
  );
  return subject;
}

export function parseFacebookControls(): void {
  FB.XFBML.parse();
}
