import { Component, OnInit } from "@angular/core";
import { DocumentTemplateWarning } from "@ankaadia/graphql";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-document-template-warnings-dialog",
  templateUrl: "./document-template-warnings-dialog.component.html",
})
export class DocumentTemplateWarningsDialogComponent implements OnInit {
  protected warningsOrErrors: DocumentTemplateWarning[];
  protected isWarning: boolean;

  constructor(
    private readonly dialog: DynamicDialogRef,
    private readonly config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.isWarning = this.config.data.warnings != null;
    this.warningsOrErrors = this.config.data.warnings ?? this.config.data.errors;
  }

  close(): void {
    this.dialog.close();
  }
}
