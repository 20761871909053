export interface ILocalizedString {
  language: string;
  value: string;
}

export function getLocalizedString(strings: ILocalizedString[], language: string): string {
  const fallback = "de-DE";
  return (
    // either the language we want
    strings?.find((x) => x.language === language)?.value ??
    // or the fallback language
    strings?.find((x) => x.language === fallback)?.value ??
    // or the first language that's there
    strings?.[0]?.value
  );
}
