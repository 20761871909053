import { Directive } from "@angular/core";
import { noop } from "lodash";
import { BlockUI } from "primeng/blockui";

/* just a placeholder so that the import does not look weird */
@Directive({ selector: "p-blockUI" })
export class BlockUIFixDirective {
  constructor(private readonly blockUI: BlockUI) {
    noop(this.blockUI);
  }
}

BlockUI.prototype.ngAfterViewInit = function (this: BlockUI): void {
  // added
  if (this._blocked) this.block();

  if (this.target && !this.target.getBlockableElement) {
    throw new Error("Target of BlockUI must implement BlockableUI interface");
  }
};
