import {
  Document,
  DocumentFile,
  DocumentFreeFormatFile,
  DocumentRequirement,
  DocumentSet,
  DocumentWriteMode,
} from "@ankaadia/graphql";
import { FormElementGroup, Replace } from "ngx-mf";
import { AllFormGroups, GraphQLFormModel } from "../../shared/services/form.helper";
import { SelectedSet } from "@ankaadia/ankaadia-shared";
import { FormControl } from "@angular/forms";

type CleanDocumentFile = DocumentFile & { original?: never; file?: never };
type CleanDocumentFreeFormatFile = DocumentFreeFormatFile & { file?: never };
type CleanDocumentSet = Omit<DocumentSet, "files"> & { original?: never; files: CleanDocumentFile[] };
export type CleanDocument = Omit<Document, "documentSets" | "freeFormatFiles"> & {
  writeMode?: never;
  requirement?: never;
  selectedSets?: never;
  documentSets?: CleanDocumentSet[];
  freeFormatFiles?: CleanDocumentFreeFormatFile[];
};

export type FormDocumentFile = Omit<DocumentFile, "createdAt"> & { original: boolean; file: File };
export type FormDocumentFreeFormatFile = DocumentFreeFormatFile & { file: File };
export type FormDocumentSet = Omit<DocumentSet, "files"> & { original: boolean; files: FormDocumentFile[] };
export type FormDocument = Omit<Document, "documentSets" | "freeFormatFiles"> & {
  writeMode: DocumentWriteMode;
  requirement?: DocumentRequirement;
  selectedSets?: SelectedSet[];
  documentSets?: FormDocumentSet[];
  freeFormatFiles?: FormDocumentFreeFormatFile[];
};

export type DocumentForm = GraphQLFormModel<
  FormDocument,
  {
    generationStrategy: { configuration: FormElementGroup };
    documentSets: [{ files: [{ singleSetConfig: [FormElementGroup]; file: Replace<FormControl<File>> }] }];
    freeFormatFiles: [{ file: Replace<FormControl<File>> }];
  }
>;

export type AnyDocumentForm = AllFormGroups<DocumentForm>;
export type DocumentSetForm = DocumentForm["controls"]["documentSets"]["controls"][number];
export type DocumentFileForm = DocumentSetForm["controls"]["files"]["controls"][number];
export type DocumentFileTagsControl = DocumentFileForm["controls"]["tags"];
export type SingleSetConfigForm = DocumentFileForm["controls"]["singleSetConfig"]["controls"][number];
export type FreeFormatFileForm = DocumentForm["controls"]["freeFormatFiles"]["controls"][number];

export function toCleanDocument(form: DocumentForm): CleanDocument {
  const { writeMode: _1, requirement: _2, selectedSets: _3, ...cleanedDocument } = form.getRawValue();
  return {
    ...cleanedDocument,
    freeFormatFiles: cleanedDocument.freeFormatFiles?.map(({ file: _1, ...cleanedFile }) => cleanedFile),
    documentSets: cleanedDocument.documentSets?.map((documentSet) => {
      const { original: _, ...cleanedSet } = documentSet;
      return {
        ...cleanedSet,
        files: documentSet.files?.map(({ original: _1, file: _2, ...cleanedFile }) => cleanedFile),
      };
    }),
  };
}
