<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <p-blockUI [target]="table" [blocked]="!model.id">
    <span class="block-ui-message">{{ t("candidate.filesWarning") }}</span>
  </p-blockUI>

  <app-document-table
    #table
    class="candidate-files app-form"
    [isBlocked]="!model.id"
    [organizationId]="model.organizationId"
    [candidateId]="model.id"
    [candidate]="model"
    [candidateForm]="candidateForm"
    [familyMemberId]="selectedFamilyMemberId !== model.id ? selectedFamilyMemberId : null"
    [confirmCreation]="confirmCreation"
    [personName]="personName"
    [mode]="mode"
    [processLanguage]="processLanguage"
    [readonly]="readonly"
    [showInternalDoc]="showInternalDoc"
    [showDocumentCompletion]="showCompletionStatus"
    [selectedSharing]="selectedSharing"
    [candidateImmigrationCountry]="candidateImmigrationCountry"
    [showArrows]="true"
    [requiredDocumentSetIds]="selectedRequiredDocumentSet.value"
    [requiredDocumentsOrganizationId]="selectedOrganizationId.value"
    (saved)="saved.emit()"
  >
    <ng-template #caption>
      <ng-container *ngIf="familyMembers?.length > 1 || displayTitle">
        <h3 *ngIf="displayTitle" class="inline-block mr-4 mb-0 vertical-align-middle">
          {{ t("attachments.title") }}
        </h3>
      </ng-container>
      <ng-container>
        <div class="flex felx-row">
          <p-dropdown
            class="pl-2"
            *ngIf="familyMembers?.length > 1"
            [options]="familyMembers"
            [(ngModel)]="selectedFamilyMemberId"
            dataKey="value"
          ></p-dropdown>
          <p-dropdown
            *ngIf="organizations.length > 0 && !settings.isCandidate"
            class="pl-2"
            inputId="organization"
            [options]="organizations"
            [formControl]="selectedOrganizationId"
          ></p-dropdown>
          <p-multiSelect
            *ngIf="organizations.length > 0 && !settings.isCandidate"
            class="pl-2"
            inputId="requiredDocumentSet"
            [options]="requiredDocumentSets"
            optionLabel="name"
            optionValue="id"
            [placeholder]="t('requiredDocument.placeholder')"
            [showToggleAll]="true"
            [showClear]="true"
            [formControl]="selectedRequiredDocumentSet"
          ></p-multiSelect>
        </div>
      </ng-container>
    </ng-template>
  </app-document-table>
</ng-container>
