import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserPermission } from "@ankaadia/ankaadia-shared";
import { GuetegemeinschaftService } from "../../organization-specific/guetegemeinschaft/guetegemeinschaft.service";
import { SettingsService } from "../../shared/services/settings.service";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
})
export class LandingComponent implements OnInit {
  readonly hasUserPermission = this.settings.hasAnyPermission([UserPermission.User]);

  get isCandidate(): boolean {
    return this.settings.isCandidate;
  }

  constructor(
    private readonly settings: SettingsService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly guetegemeinschaftService: GuetegemeinschaftService
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams["linkGuetegemeinschaftOrganization"] === "true") {
      this.guetegemeinschaftService
        .link()
        .subscribe(
          (x) =>
            (window.location.href = this.router
              .createUrlTree([`/app/processes/view/${x.organizationId}/${x.processId}`])
              .toString())
        );
    }
  }
}
