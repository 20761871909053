import { Directive, OnInit } from "@angular/core";
import { MultiSelect, MultiSelectItem } from "primeng/multiselect";

@Directive({ selector: "p-multiSelect" })
export class MultiSelectReadonlyFixDirective implements OnInit {
  private clearSource: MultiSelect["clear"];
  private removeChipSource: MultiSelect["removeChip"];

  constructor(private readonly multiSelect: MultiSelect) {}

  ngOnInit(): void {
    this.clearSource = this.multiSelect.clear;
    this.multiSelect.clear = (event: Event): void => this.clear(this.multiSelect, event);

    this.removeChipSource = this.multiSelect.removeChip;
    this.multiSelect.removeChip = (chip: MultiSelectItem, event: MouseEvent): void =>
      this.removeChip(this.multiSelect, chip, event);
  }

  private clear(multiSelect: MultiSelect, event: Event): void {
    if (multiSelect.readonly) {
      return;
    }
    return this.clearSource.call(multiSelect, event);
  }

  private removeChip(multiSelect: MultiSelect, chip: MultiSelectItem, event: MouseEvent): void {
    if (multiSelect.readonly) {
      return;
    }
    return this.removeChipSource.call(multiSelect, chip, event);
  }
}
