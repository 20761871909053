import { Injectable } from "@angular/core";
import {
  CreateProcessGQL,
  CreateTaskGQL,
  DeleteProcessGQL,
  DeleteTaskGQL,
  GetAccessibleProcessesGQL,
  GetAllProcessesGQL,
  GetCandidateProcessRoleListGQL,
  GetCommentedStatesIdsGQL,
  GetPredecessorsGQL,
  GetProcessFormlyGQL,
  GetProcessGQL,
  GetProcessHeatmapGQL,
  GetProcessPresetGQL,
  GetProcessProgressGQL,
  GetProcessesForCandidateGQL,
  GetProcessesForDelegationGQL,
  GetProcessesForOrganizationGQL,
  GetStateGQL,
  GetTaskAndStateGQL,
  GetTaskCopyDataGQL,
  GetTaskGQL,
  GetVisibleTasksGQL,
  InstanceStateEnum,
  PerformTaskGQL,
  PrepareNewTaskGQL,
  PrepareNewTaskInput,
  ProcessActivateInput,
  ProcessCreateInput,
  ProcessDeleteInput,
  ProcessForDelegationFragment,
  ProcessForListFragment,
  ProcessFormlyModelFragment,
  ProcessFragment,
  ProcessFragmentDoc,
  ProcessHeatmapFragment,
  ProcessListInput,
  ProcessPredecessorPhase,
  ProcessPresetFragment,
  ProcessPresetInput,
  ProcessProgressFragment,
  ProcessSelectionListFragment,
  ProcessStateFragment,
  ProcessStateUpdateInput,
  ProcessSyncInput,
  ProcessTaskDeleteInput,
  ProcessTaskFragment,
  ProcessTaskFragmentDoc,
  ProcessTaskListViewFragment,
  ProcessUpdateInput,
  SendProcessEmailGQL,
  SendProcessEmailsGQL,
  SyncAllProcessesGQL,
  SyncProcessGQL,
  ToggleProcessStateGQL,
  UpdateProcessGQL,
  UpdateTaskGQL,
  ProcessRoleNameFragment,
  GetProcessDelegationStateGQL,
  ProcessPerformResultFragment,
  ProcessSyncInputOptions,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";

export const CandidateRole = "Candidate";

@Injectable({ providedIn: "root" })
export class ProcessService {
  constructor(
    private readonly settings: SettingsService,
    private readonly processFormly: GetProcessFormlyGQL,
    private readonly process: GetProcessGQL,
    private readonly allProcesses: GetAllProcessesGQL,
    private readonly accessibleProcesses: GetAccessibleProcessesGQL,
    private readonly processesForOrganization: GetProcessesForOrganizationGQL,
    private readonly processesForCandidate: GetProcessesForCandidateGQL,
    private readonly processesForDelegation: GetProcessesForDelegationGQL,
    private readonly progress: GetProcessProgressGQL,
    private readonly heatmap: GetProcessHeatmapGQL,
    private readonly task: GetTaskGQL,
    private readonly state: GetStateGQL,
    private readonly taskAndState: GetTaskAndStateGQL,
    private readonly visibleTasks: GetVisibleTasksGQL,
    private readonly allPredecessors: GetPredecessorsGQL,
    private readonly processCreate: CreateProcessGQL,
    private readonly processUpdate: UpdateProcessGQL,
    private readonly processSync: SyncProcessGQL,
    private readonly processSyncAll: SyncAllProcessesGQL,
    private readonly processDelete: DeleteProcessGQL,
    private readonly processState: ToggleProcessStateGQL,
    private readonly taskCreate: CreateTaskGQL,
    private readonly taskUpdate: UpdateTaskGQL,
    private readonly taskPerform: PerformTaskGQL,
    private readonly taskDelete: DeleteTaskGQL,
    private readonly prepareNewTaskQuery: PrepareNewTaskGQL,
    private readonly taskCopyData: GetTaskCopyDataGQL,
    private readonly outboxSend: SendProcessEmailsGQL,
    private readonly outboxOneSend: SendProcessEmailGQL,
    private readonly processPreset: GetProcessPresetGQL,
    private readonly processCommentedStates: GetCommentedStatesIdsGQL,
    private readonly candidateProcessRoleList: GetCandidateProcessRoleListGQL,
    private readonly getProcessDelegationStateGQL: GetProcessDelegationStateGQL
  ) {}

  getProcessDelegationStates(processId: string, organizationId: string): Observable<boolean> {
    return this.getProcessDelegationStateGQL
      .fetch({ orgId: organizationId, processId: processId })
      .pipe(map((x) => x.data.getProcess.hasDelegation));
  }

  getProcessFormly(processId: string, orgId: string): Observable<ProcessFormlyModelFragment> {
    return this.processFormly.fetch({ orgId, processId }).pipe(map((x) => x.data.getProcessFormly));
  }

  getProcess(processId: string, orgId: string): Observable<ProcessFragment> {
    return this.process.fetch({ processId: processId, orgId: orgId }).pipe(map((x) => x.data.getProcess));
  }

  getAllProcesses(input: ProcessListInput): Observable<ProcessForListFragment[]> {
    return this.allProcesses.watch({ input: input }).valueChanges.pipe(map((x) => x.data.getAllProcesses));
  }

  getAccessibleProcessIds(): Observable<string[]> {
    return this.accessibleProcesses.fetch().pipe(map((x) => x.data.getAccessibleProcesses.map((y) => y.id)));
  }

  getProcesses(organizationId: string): Observable<ProcessSelectionListFragment[]> {
    // VZ: Seems illogical for a candidate to access the full processes.
    return this.settings.isCandidate
      ? this.processesForCandidate
          .watch({ orgId: organizationId, candidateId: this.settings.userOrCandidateId })
          .valueChanges.pipe(map((x) => x.data.getProcessesForCandidate))
      : this.processesForOrganization
          .watch({
            input: { organizationId: organizationId, destinationOrganizationId: this.settings.organizationId },
          })
          .valueChanges.pipe(map((x) => x.data.getProcessesForOrganization));
  }

  getCandidateProcessRoles(organizationId: string, candidateId: string): Observable<ProcessRoleNameFragment[]> {
    return this.candidateProcessRoleList
      .fetch({ orgId: organizationId, candidateId: candidateId }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getProcessesRolesForCandidate));
  }

  getProcessCommentedStatesId(processId: string, orgId: string): Observable<string[]> {
    return this.processCommentedStates
      .watch({
        input: {
          processId: processId,
          organizationId: orgId,
        },
      })
      .valueChanges.pipe(map((x) => x.data.getCommentedStatesIds));
  }

  getProcessesForDelegation(): Observable<ProcessForDelegationFragment[]> {
    return this.processesForDelegation
      .fetch({ input: { organizationId: this.settings.organizationId } })
      .pipe(map((x) => x.data.getProcessesForDelegation));
  }

  getProcessProgress(
    organizationId: string,
    processId: string,
    candidateId?: string,
    language?: string
  ): Observable<ProcessProgressFragment> {
    return this.progress
      .watch({ input: { processId, organizationId, candidateId, language } }, { fetchPolicy: "network-only" })
      .valueChanges.pipe(map((x) => x.data.getProcessProgress));
  }

  getProcessHeatmap(organizationId: string, processId: string, language?: string): Observable<ProcessHeatmapFragment> {
    return this.heatmap
      .watch({ input: { processId, organizationId, language } }, { fetchPolicy: "network-only" })
      .valueChanges.pipe(map((x) => x.data.getProcessHeatmap));
  }

  getProcessPreset(input: ProcessPresetInput): Observable<ProcessPresetFragment> {
    return this.processPreset.fetch({ input: input }).pipe(map((x) => x.data.getProcessPreset));
  }

  createProcess(input: ProcessCreateInput): Observable<ProcessFragment> {
    delete input["id"];
    delete input["_etag"];
    return this.processCreate
      .mutate(
        { input: input },
        {
          update: (cache, result) =>
            cache.modify({
              fields: {
                getAllProcesses: (refs, helper) =>
                  updateProcessApolloCache(input, refs, helper, cache, result.data.createProcess),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createProcess));
  }

  updateProcess(input: ProcessUpdateInput): Observable<ProcessFragment> {
    return this.processUpdate.mutate({ input: input }).pipe(map((x) => x.data.updateProcess));
  }

  toggleState(input: ProcessActivateInput): Observable<ProcessFragment> {
    const copy = new ProcessActivateInput();
    copy.id = input.id;
    copy.organizationId = input.organizationId;
    copy._etag = input._etag;
    copy.state = input.state;
    return this.processState.mutate({ input: copy }).pipe(map((x) => x.data.toggleProcessState));
  }

  syncProcess(input: ProcessSyncInput, syncOptions: ProcessSyncInputOptions): Observable<ProcessFragment> {
    const copy = new ProcessSyncInput();
    copy.id = input.id;
    copy.organizationId = input.organizationId;
    copy._etag = input._etag;
    return this.processSync.mutate({ input: copy, syncOptions: syncOptions }).pipe(map((x) => x.data.syncProcess));
  }

  syncAllProcesses(organizationId: string, syncOptions: ProcessSyncInputOptions): Observable<ProcessFragment[]> {
    return this.processSyncAll
      .mutate({ input: { organizationId: organizationId }, syncOptions: syncOptions })
      .pipe(map((x) => x.data.syncAllProcesses));
  }

  deleteProcess(input: ProcessDeleteInput): Observable<boolean> {
    const copy = new ProcessDeleteInput();
    copy.id = input.id;
    copy.organizationId = input.organizationId;
    copy._etag = input._etag;
    return this.processDelete
      .mutate(
        { input: copy },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getAllProcesses: (refs, { readField }) => refs.filter((ref) => readField("id", ref) !== input.id),
                getProcessesForOrganization: (refs, { readField }) =>
                  refs.filter((ref) => readField("id", ref) !== input.id),
                getProcessesForCandidate: (refs, { readField }) =>
                  refs.filter((ref) => readField("id", ref) !== input.id),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteProcess.status));
  }

  getTask(taskId: string, orgId: string, language: string): Observable<ProcessTaskFragment> {
    return this.task.fetch({ taskId: taskId, orgId: orgId, language: language }).pipe(map((x) => x.data.getTask));
  }

  getVisibleTasks(
    organizationId: string,
    processId: string,
    instanceStates: InstanceStateEnum[],
    language: string,
    candidateId?: string
  ): Observable<ProcessTaskListViewFragment[]> {
    return this.visibleTasks
      .watch(
        { input: { organizationId, processId, candidateId, instanceStates, language } },
        { fetchPolicy: "network-only" }
      )
      .valueChanges.pipe(map((x) => x.data.getVisibleTasks));
  }

  getPredecessors(
    organizationId: string,
    processId: string,
    language: string,
    candidateId?: string
  ): Observable<ProcessPredecessorPhase[]> {
    return this.allPredecessors
      .fetch({ input: { organizationId, processId, candidateId, language } })
      .pipe(map((x) => x.data.getPredecessors));
  }

  prepareNewTask(prepareNewTaskInput: PrepareNewTaskInput, language: string): Observable<ProcessTaskFragment> {
    return this.prepareNewTaskQuery
      .fetch({ input: prepareNewTaskInput, language: language })
      .pipe(map((x) => x.data.prepareNewTask));
  }

  createTask(task: ProcessTaskFragment, language: string): Observable<ProcessTaskFragment> {
    const input = { ...task, typeName: task.__typename };
    delete input["__typename"];
    delete input["id"];
    delete input["_etag"];
    return this.taskCreate
      .mutate(
        { input: input, language: language },
        {
          update: (cache, result) =>
            cache.modify({
              fields: {
                getVisibleTasks: (refs, helper) =>
                  updateTaskApolloCache(input, refs, helper, cache, result.data.createTask),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createTask));
  }

  updateTask(task: ProcessTaskFragment, language: string): Observable<ProcessTaskFragment> {
    const input = { ...task, typeName: task.__typename };
    delete input["__typename"];
    return this.taskUpdate.mutate({ input: input, language: language }).pipe(map((x) => x.data.updateTask));
  }

  performTask(input: ProcessStateUpdateInput): Observable<ProcessPerformResultFragment> {
    return this.taskPerform.mutate({ input: input }).pipe(map((x) => x.data.performTask));
  }

  deleteTask(input: ProcessTaskDeleteInput): Observable<boolean> {
    const copy = new ProcessTaskDeleteInput();
    copy.id = input.id;
    copy.organizationId = input.organizationId;
    copy.rootId = input.rootId;
    copy._etag = input._etag;
    return this.taskDelete.mutate({ input: copy }).pipe(map((x) => x.data.deleteTask.status));
  }

  getTaskAndState(
    taskId: string,
    organizationId: string,
    language: string
  ): Observable<[ProcessTaskFragment, ProcessStateFragment]> {
    return this.taskAndState
      .fetch({ taskId: taskId, orgId: organizationId, language: language })
      .pipe(map((x) => [x.data.getTaskForAct, x.data.getState]));
  }

  getState(taskId: string, organizationId: string): Observable<ProcessStateFragment> {
    return this.state.fetch({ taskId: taskId, orgId: organizationId }).pipe(map((x) => x.data.getState));
  }

  getTaskCopyData(taskId: string, processId: string, organizationId: string): Observable<string> {
    return this.taskCopyData
      .fetch({ input: { taskId: taskId, processId: processId, organizationId: organizationId } })
      .pipe(map((x) => x.data.getTaskCopyData));
  }

  sendProcessEmails(organizationId: string, processId: string, candidateId?: string): Observable<boolean> {
    return this.outboxSend
      .mutate({ input: { organizationId: organizationId, processId: processId, candidateId: candidateId } })
      .pipe(map((x) => x.data.sendProcessEmails.status));
  }

  sendProcessEmail(
    emailId: string,
    subject: string,
    body: string,
    organizationId: string,
    processId: string
  ): Observable<boolean> {
    return this.outboxOneSend
      .mutate({
        input: {
          organizationId: organizationId,
          processId: processId,
          emailId: emailId,
          subject: subject,
          body: body,
        },
      })
      .pipe(map((x) => x.data.sendProcessEmail.status));
  }
}

function updateProcessApolloCache(input, refs, { storeFieldName }, cache, data): any[] {
  if (!storeFieldName.includes(input.organizationId)) return refs;
  const ref = cache.writeFragment({
    data: data,
    fragment: ProcessFragmentDoc,
    fragmentName: "Process",
  });
  if (refs != null && refs.length > 0) {
    return [...refs, ref];
  } else {
    return [ref];
  }
}

function updateTaskApolloCache(input, refs, { storeFieldName }, cache, data): any[] {
  if (!storeFieldName.includes(input.organizationId)) return refs;
  const ref = cache.writeFragment({
    data: data,
    fragment: ProcessTaskFragmentDoc,
    fragmentName: "ProcessTask",
  });
  if (refs != null && refs.length > 0) {
    return [...refs, ref];
  } else {
    return [ref];
  }
}
