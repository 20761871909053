import { Injectable } from "@angular/core";
import { GetSentMessageGQL, GetSentMessageQuery, SendMessageGQL } from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class MessengerService {
  constructor(
    private readonly sendMessageMutation: SendMessageGQL,
    private readonly getsendMessageQuery: GetSentMessageGQL
  ) {}

  sendMessageToCurrentUser(message: string): Observable<boolean> {
    return this.sendMessageMutation.mutate({ message: message }).pipe(map((x) => x.data.sendMessage.status));
  }

  getSentMessage(
    candidateId: string,
    organizationId: string,
    messageId: string
  ): Observable<GetSentMessageQuery["getSentMessage"]> {
    return this.getsendMessageQuery
      .fetch({ candidateId: candidateId, messageId: messageId, organizationId: organizationId })
      .pipe(map((x) => x.data.getSentMessage));
  }
}
