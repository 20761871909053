<ng-container *appTranslate="let t; lang: viewLanguage + '|static'">
  <p-tabView
    #tabs
    styleClass="flex flex-column p-tabview-mobile"
    (onChange)="tabs.el.nativeElement.querySelector('.p-tabview-panels').scroll(0, 0)"
    [(activeIndex)]="activeIndex"
    (activeIndexChange)="onTabChanged()"
  >
    <p-tabPanel
      *ngFor="let consent of consents"
      [header]="t($any('enum.language.' + ensure4LetterIsoLanguage(consent.language)))"
    >
      <div class="ql-editor consent-content" [innerHtml]="consent.contents | safe: 'html'"></div>
    </p-tabPanel>
  </p-tabView>
</ng-container>
