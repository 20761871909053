<ng-container *transloco="let t; lang: processLanguage + '|static'">
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="submit($event)">
    <p-card
      [header]="
        form.value.id
          ? mode === DocumentMode.Template
            ? t('template.edit') +
              '  (' +
              (document.templateMode == DocumentTemplateMode.Xlsx
                ? document.generationMode == DocumentGenerationMode.AllCandidates
                  ? t('template.xlsxReportTemplate')
                  : t('template.xlsxTemplate')
                : t('template.docxTemplate')) +
              ')'
            : t('document.edit')
          : mode === DocumentMode.Template
            ? t('template.create') +
              '  (' +
              (document.templateMode == DocumentTemplateMode.Xlsx
                ? document.generationMode == DocumentGenerationMode.AllCandidates
                  ? t('template.xlsxReportTemplate')
                  : t('template.xlsxTemplate')
                : t('template.docxTemplate')) +
              ')'
            : t('document.create')
      "
      [subheader]="personName"
    >
      <div class="p-fluid">
        <div class="grid">
          <div class="col-{{ isDocumentSelectionMode(mode) ? 11 : 12 }} ">
            <!-- Dokument Name (Nur aktiv für Templates und Organisationsdokumente) -->
            <div class="field" *ngIf="mode === DocumentMode.Organization || mode === DocumentMode.Template">
              <label for="displayName">{{ t("name.title") }}</label>
              <input
                id="displayName"
                pInputText
                formControlName="displayName"
                maxlength="100"
                class="mr-2"
                [class.p-disabled]="isDisplayNameReadOnly"
                [readonly]="isDisplayNameReadOnly"
              />
              <small class="p-error" *ngIf="form.controls.displayName.errors?.required">
                {{ t("name.required") }}
              </small>
              <small class="p-error" *ngIf="form.controls.displayName.errors?.unique">
                {{ t("name.unique") }}
              </small>
            </div>
          </div>

          <!-- Dokument Selektionskriterien (Nur aktiv für Templates und Organisationsdokumente) -->
          <div class="col-1" *ngIf="isDocumentSelectionMode(mode) && !isReadonly">
            <div class="field">
              <label class="alignment-with-the-other-fields">&nbsp;</label>

              <div class="mt-1 flex flex-row">
                <button
                  pButton
                  type="button"
                  [pTooltip]="t('selectionCriteria.configure')"
                  icon="pi pi-cog"
                  class="p-button-rounded p-button-text ml-2"
                  (click)="selectionCriteriaOverlay.toggle($event)"
                  [disabled]="document?.generationMode == DocumentGenerationMode.AllCandidates"
                ></button>

                <p-overlayPanel #selectionCriteriaOverlay [style]="{ width: '300px' }">
                  <ng-template pTemplate>
                    <app-document-criteria-config [form]="form"></app-document-criteria-config>
                  </ng-template>
                </p-overlayPanel>
              </div>
            </div>
          </div>
        </div>

        <!-- Dokumententyp (Nur aktiv für Kandidatendokumente) -->
        <div class="field" *ngIf="mode === DocumentMode.Candidate">
          <label for="type">{{ t("fileType.title") }}</label>
          <p-dropdown
            #documentTypeDropdown
            inputId="type"
            [options]="availableFileTypes"
            filterBy="label"
            [required]="true"
            [placeholder]="t('fileType.placeholder')"
            [disabled]="form.controls.type.disabled"
            [ngModel]="form.controls.type.value"
            [ngModelOptions]="{ standalone: true }"
            (onChange)="onDocumentTypeChange($event)"
            (onHide)="updateDocumentType()"
          >
            <ng-template let-availableFileTypes pTemplate="item">
              <div
                class="white-space-normal mr-1 ml-1"
                [pTooltip]="
                  availableFileTypes.value
                    | staticData: StaticDataType.DocumentDescription : processLanguage : staticDataRequest
                    | async
                "
                tooltipPosition="left"
              >
                {{ availableFileTypes.label }}
              </div>
            </ng-template>
          </p-dropdown>
          <small class="p-error" *ngIf="form.controls.type.errors?.required">
            {{ t("fileType.required") }}
          </small>
          <div #target></div>
        </div>

        <!-- Dokumententyp Beschreibung (Nur aktiv für Kandidatendokumente und sofern Beschreibung existiert) -->
        <ng-container *ngIf="mode === DocumentMode.Candidate && form.value.type">
          <p-message
            *ngIf="
              form.value.type
                | staticData: StaticDataType.DocumentDescription : processLanguage : staticDataRequest
                | async as typeDescription
            "
            severity="info"
            styleClass="mb-2"
            [text]="typeDescription"
          />
        </ng-container>

        <!-- Dokumenten Upload -->
        <div class="field mt-5 mb-5">
          <app-document-set-selector
            [form]="form"
            [readonly]="isReadonly"
            [mode]="mode"
            [processLanguage]="processLanguage"
            [selectedField]="isSelectedSetField(selectedField) ? selectedField : null"
            [candidateFormOrCandidate]="candidateForm"
            [additionalForeignKeySourceData]="additionalForeignKeySourceData"
            [availablePhysicalFileTypes]="availablePhysicalFileTypes"
            [availableTags]="(documentFormats$ | async)?.availableFormats"
            (upload)="uploadedFiles($event)"
          />
        </div>

        <app-document-properties
          [form]="form"
          [language]="processLanguage"
          [readonly]="isReadonly"
          [showInternalDoc]="showInternalDoc"
          [showDocumentCompletion]="showDocumentCompletion"
          [selectedField]="isSelectedDocumentField(selectedField) ? selectedField : null"
        />

        <app-report-data-strategy
          *ngIf="
            document.templateMode == DocumentTemplateMode.Xlsx &&
            document.generationMode == DocumentGenerationMode.AllCandidates
          "
          [documentForm]="form"
          mode="Configuration"
        />

        <div
          class="field-checkbox mt-1"
          *ngIf="mode === DocumentMode.Template && document.generationMode != DocumentGenerationMode.AllCandidates"
        >
          <p-checkbox formControlName="availableForCandidates" [binary]="true" inputId="availableForCandidates" />
          <label for="availableForCandidates">{{ t("availableForCandidates.title") }}</label>
        </div>

        <div
          class="field-checkbox mt-1"
          *ngIf="mode === DocumentMode.Template && document.generationMode != DocumentGenerationMode.AllCandidates"
        >
          <p-checkbox
            formControlName="hideCandidateNameInFileName"
            [binary]="true"
            inputId="hideCandidateNameInFileName"
          />
          <label for="hideCandidateNameInFileName">{{ t("hideCandidateNameInFileName.title") }}</label>
        </div>
        <div
          class="field-checkbox mt-1"
          *ngIf="mode === DocumentMode.Template && document.generationMode != DocumentGenerationMode.AllCandidates"
        >
          <p-checkbox formControlName="throwErrorOnNoSelection" [binary]="true" inputId="throwErrorOnNoSelection" />
          <label for="throwErrorOnNoSelection">{{ t("throwErrorOnNoSelection.title") }}</label>
          <i class="pi pi-info-circle p-info ml-2" [pTooltip]="t('throwErrorOnNoSelection.help')" [escape]="false"></i>
        </div>

        <ng-container *ngIf="(documentFormats$ | async)?.formatsUploadedAsDocumentSets as formats">
          <p-message
            *ngIf="formats.length"
            severity="info"
            styleClass="mb-2"
            [text]="
              t('formatsAlreadyInDocumentSets.title', {
                formats:
                  formats | staticData: StaticDataType.DocumentFormats : processLanguage : staticDataRequest | async,
              })
            "
          />
        </ng-container>

        <!-- Weitere Dateien -->
        <p-fieldset
          *ngIf="!isInMetadataMode && showFreeFormatFiles"
          [toggleable]="true"
          [collapsed]="!form.controls.freeFormatFiles?.value.length"
        >
          <ng-template pTemplate="header">
            <div class="flex align-items-center text-primary">
              <span>{{ t("freeFormatFiles.title") }}</span>
              <i class="pi pi-info-circle ml-3" [pTooltip]="t('freeFormatFiles.tooltip')"></i>
            </div>
          </ng-template>
          <div>
            <app-free-format-document-selector
              [form]="form"
              [mode]="mode"
              [readonly]="isReadonly"
              [processLanguage]="processLanguage"
              (upload)="uploadedFreeFormatFiles($event)"
            />
          </div>
        </p-fieldset>
      </div>

      <!-- Speichern / Abbrechen -->
      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            *ngIf="!isReadonly"
            [disabled]="!form.valid || (!form.dirty && !!documentId) || submitDisabled"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
          />
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="closeDialog()"
          />
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
