export enum errorMessageKey {
  ORGHASCHILDREN = "ORGHASCHILDREN",
  ORGNOTCREATED = "ORGNOTCREATED",
  ORGNOTUPDATED = "ORGNOTUPDATED",
  USEREMAILNOTUNIQUE = "USEREMAILNOTUNIQUE",
  GROUPNAMENOTUNIQUE = "GROUPNAMENOTUNIQUE",
  COLLECTIONALLREADYSHARED = "COLLECTIONALLREADYSHARED",
  COLLECTIONUSEDINAUTOPROCESSOPERATION = "COLLECTIONUSEDINAUTOPROCESSOPERATION",
  CANDIDATEALREADYINVITED = "CANDIDATEALREADYINVITED",
  CANDIDATENOTSUPPORTEDSOURCE = "CANDIDATENOTSUPPORTEDSOURCE",
  CANDIDATENOAGENCY = "CANDIDATENOAGENCY",
  CANDIDATENOBIRTHDATE = "CANDIDATENOBIRTHDATE",
  COLLECTIONRUNNINGPROCESSES = "COLLECTIONRUNNINGPROCESSES",
  GROUPRUNNINGPROCESSES = "GROUPRUNNINGPROCESSES",
  ORGRUNNINGPROCESSES = "ORGRUNNINGPROCESSES",
  ORGSHAREDCOLLECTIONS = "ORGSHAREDCOLLECTIONS",
  SHARINGRUNNINGPROCESSES = "SHARINGRUNNINGPROCESSES",
  PROCESSDUPLICATEIDS = "PROCESSDUPLICATEIDS",
  PROCESSMISSINGPREDECESSORS = "PROCESSMISSINGPREDECESSORS",
  PROCESSMISSINGATLEASTONEPREDECESSORS = "PROCESSMISSINGATLEASTONEPREDECESSORS",
  PROCESSMISSINGATTACHMENTTASKS = "PROCESSMISSINGATTACHMENTTASKS",
  PROCESSUNSUPPORTEDFILETYPES = "PROCESSUNSUPPORTEDFILETYPES",
  PROCESSMISSINGROLES = "PROCESSMISSINGROLES",
  PROCESSMISSINGANYROLES = "PROCESSMISSINGANYROLES",
  PROCESSATLEASTONEVISIBILEFORROLE = "PROCESSATLEASTONEVISIBILEFORROLE",
  PROCESSDUPLICATEMODELID = "PROCESSDUPLICATEMODELID",
  PROCESSEMPTDISPLAYNAME = "PROCESSEMPTDISPLAYNAME",
  PROCESSEMPTYATTACHMENTDISPLAYNAME = "PROCESSEMPTYATTACHMENTDISPLAYNAME",
  PROCESSEMPTYPROCESSDOCUMENTDISPLAYNAME = "PROCESSEMPTYPROCESSDOCUMENTDISPLAYNAME",
  PROCESSMISSINGDOCUMENTTEMPLATE = "PROCESSMISSINGDOCUMENTTEMPLATE",
  PROCESSTEMPLATESNOTSPECIFIED = "PROCESSTEMPLATESNOTSPECIFIED",
  PROCESSMISSINGDOCUMENT = "PROCESSMISSINGDOCUMENT",
  PROCESSMISSINGDOCUMENTSET = "PROCESSMISSINGDOCUMENTSET",
  PROCESSMISSINGEMAILTEMPLATE = "PROCESSMISSINGEMAILTEMPLATE",
  PROCESSMISSINGEMAILTEMPLATELANGUAGE = "PROCESSMISSINGEMAILTEMPLATELANGUAGE",
  PROCESSBADTEMPLATE = "PROCESSBADTEMPLATE",
  PROCESSWRONGCONVERSIONTYPE = "PROCESSWRONGCONVERSIONTYPE",
  PROCESSMISSINGMODELNAME = "PROCESSMISSINGMODELNAME",
  PROCESSMISSINGROLENAME = "PROCESSMISSINGROLENAME",
  PROCESSMISSINGDOCUMENTNAME = "PROCESSMISSINGDOCUMENTNAME",
  PROCESSIDNOTWELLFORMED = "PROCESSIDNOTWELLFORMED",
  PROCESSROLENAMENOTWELLFORMED = "PROCESSROLENAMENOTWELLFORMED",
  PROCESSDELEGATIONOPERATIONONPARENTTASKINVALID = "PROCESSDELEGATIONOPERATIONONPARENTTASKINVALID",
  PROCESSMODELISNOTVALID = "PROCESSMODELISNOTVALID",
  PROCESSFINISHLABELISMISSING = "PROCESSFINISHLABELISMISSING",
  PROCESSMODELISEMPTY = "PROCESSMODELISEMPTY",
  AREAMODELISEMPTY = "AREAMODELISEMPTY",
  PHASEMODELISEMPTY = "PHASEMODELISEMPTY",
  ORGLINKED = "ORGLINKED",
  ORGALREADYLINKED = "ORGALREADYLINKED",
  ORGLICENSED = "ORGLICENSED",
  ORGUNLICENSED = "ORGUNLICENSED",
  FILEMALFORMED = "FILEMALFORMED",
  FILEEMPTY = "FILEEMPTY",
  FILEMISSINGEMAIL = "FILEMISSINGEMAIL",
  FILEINVALIDEMAIL = "FILEINVALIDEMAIL",
  FILEDUPLICATEEMAIL = "FILEDUPLICATEEMAIL",
  FILEEXISTINGEMAIL = "FILEEXISTINGEMAIL",
  FILEMISSINGFIRSTNAME = "FILEMISSINGFIRSTNAME",
  FILEMISSINGLASTNAME = "FILEMISSINGLASTNAME",
  FILEMISSINGSALUTATION = "FILEMISSINGSALUTATION",
  FILEMISSINGBIRTHDATE = "FILEMISSINGBIRTHDATE",
  FILEMISSINGCITYOFBIRTH = "FILEMISSINGCITYOFBIRTH",
  FILEMISSINGCOUNTRYOFBIRTH = "FILEMISSINGCOUNTRYOFBIRTH",
  FILEMISSINGCITIZENSHIP = "FILEMISSINGCITIZENSHIP",
  FILEMISSINGADDRESS = "FILEMISSINGADDRESS",
  FILEMISSINGCOUNTRYOFORIGIN = "FILEMISSINGCOUNTRYOFORIGIN",
  FILEMISSINGPHONE = "FILEMISSINGPHONE",
  FILEMISSINGMARTIALSTATUS = "FILEMISSINGMARTIALSTATUS",
  FILEMISSINGRECOGNOTICEFEDERALSTATE = "FILEMISSINGRECOGNOTICEFEDERALSTATE",
  FILEMISSINGREUNIFICATION = "FILEMISSINGREUNIFICATION",
  FILEMISSINGFIELDS = "FILEMISSINGFIELDS",
  DOCUMENTLOCKED = "DOCUMENTLOCKED",
  DOCUMENTUNLOCKINGERROR = "DOCUMENTUNLOCKINGERROR",
  DOCUMENTUNLOCKINGERROR2 = "DOCUMENTUNLOCKINGERROR2",
  DOCUMENTWRONGLOCKMODE = "DOCUMENTWRONGLOCKMODE",
  PROCESSACTIVATIONOVERFLOW = "PROCESSACTIVATIONOVERFLOW",
  COLLECTIONNAMENOTUNIQUE = "COLLECTIONNAMENOTUNIQUE",
  PROCESSNAMENOTUNIQUE = "PROCESSNAMENOTUNIQUE",
  FILEMISSINGQUALIFICATION = "FILEMISSINGQUALIFICATION",
  CANDIDATEPROFILECONFIGALREADYEXISTS = "CANDIDATEPROFILECONFIGALREADYEXISTS",
  LOCALPARTNERPROFILECONFIGALREADYEXISTS = "LOCALPARTNERPROFILECONFIGALREADYEXISTS",
  LOCALPARTNERPROFILECONFIGINUSE = "LOCALPARTNERPROFILECONFIGINUSE",
  UNEXPECTEDCANDIDATEROLE = "UNEXPECTEDCANDIDATEROLE",
  ACTIVATIONCONDITIONERROR = "ACTIVATIONCONDITIONERROR",
  RULERUNCONDITIONERROR = "RULERUNCONDITIONERROR",
  REMINDEREMAILTEMPLATENOTFOUND = "REMINDEREMAILTEMPLATENOTFOUND",
  CANDIDATEREMINDEREMAILTEMPLATENOTFOUND = "CANDIDATEREMINDEREMAILTEMPLATENOTFOUND",
  ESCALATIONEMAILTEMPLATENOTFOUND = "ESCALATIONEMAILTEMPLATENOTFOUND",
  DEMOALREADYLICENSED = "DEMOALREADYLICENSED",
  EMAILTEMPLATENAMEALREADYEXISTS = "EMAILTEMPLATENAMEALREADYEXISTS",
  EMAILTEMPLATEHTMLNOTWELLFORMED = "EMAILTEMPLATEHTMLNOTWELLFORMED",
  EMAILTEMPLATESUBJECTNOTWELLFORMED = "EMAILTEMPLATESUBJECTNOTWELLFORMED",
  EMAILTEMPLATEISREQUIRED = "EMAILTEMPLATEISREQUIRED",
  REQUIREDOCUMENTNAMEALREADYEXISTS = "REQUIREDOCUMENTNAMEALREADYEXISTS",
  REQUIREDDOCUMENTSREQUIRED = "REQUIREDDOCUMENTSREQUIRED",
  RECOGNITIONAUTHORITYREQUIRESFEDERALSTATE = "RECOGNITIONAUTHORITYREQUIRESFEDERALSTATE",
  RULEMISSINGTARGET = "RULEMISSINGTARGET",
  RULEMISSINGPROPERTYNAME = "RULEMISSINGPROPERTYNAME",
  RULEEVENTNOTSUPPORTED = "RULEEVENTNOTSUPPORTED",
  RULETARGETIDSNOTSUPPORTED = "RULETARGETIDSNOTSUPPORTED",
  RULEMISSINGTARGETTASKS = "RULEMISSINGTARGETTASKS",
  RULETARGETTASKIDSNOTSUPPORTED = "RULETARGETTASKIDSNOTSUPPORTED",
  RULEMISSINGTARGETTASKIDS = "RULEMISSINGTARGETTASKIDS",
  RULEMISSINGTARGETPHASEIDS = "RULEMISSINGTARGETPHASEIDS",
  RULETARGETPHASEIDSNOTSUPPORTED = "RULETARGETPHASEIDSNOTSUPPORTED",
  RULEMISSINGTARGETAREAIDS = "RULEMISSINGTARGETAREAIDS",
  RULETARGETAREIDSNOTSUPPORTED = "RULETARGETAREIDSNOTSUPPORTED",
  RULEINVALIDTASKID = "RULEINVALIDTASKID",
  RULEINVALIDTARGETID = "RULEINVALIDTARGETID",
  FILEINVALIDPROFESSION = "FILEINVALIDPROFESSION",
  DROPPEDOUTFORBIDDEN = "DROPPEDOUTFORBIDDEN",
  PROCESSMODELNOTFOUND = "PROCESSMODELNOTFOUND",
  PROCESSMODELOFWRONGTYPE = "PROCESSMODELOFWRONGTYPE",
  PROCESSMODELCONTAINSERRORS = "PROCESSMODELCONTAINSERRORS",
  PROCESSMODELIMPORTMODELMISSING = "PROCESSMODELIMPORTMODELMISSING",
  PROCESSMODELEXCLUDEIDNOTFOUND = "PROCESSMODELEXCLUDEIDNOTFOUND",
  PROCESSMODELINSERTAREAIDEXISTS = "PROCESSMODELINSERTAREAIDEXISTS",
  PROCESSMODELINSERTPHASEIDEXISTS = "PROCESSMODELINSERTPHASEIDEXISTS",
  PROCESSMODELINSERTTASKIDEXISTS = "PROCESSMODELINSERTTASKIDEXISTS",
  PROCESSMODELREPLACEAREAIDNOTFOUND = "PROCESSMODELREPLACEAREAIDNOTFOUND",
  PROCESSMODELREPLACEPHASEIDNOTFOUND = "PROCESSMODELREPLACEPHASEIDNOTFOUND",
  PROCESSMODELREPLACETASKIDNOTFOUND = "PROCESSMODELREPLACETASKIDNOTFOUND",
  PROCESSMODELINUSE = "PROCESSMODELINUSE",
  UNDEFINEDCANDIDATEPROPERTY = "UNDEFINEDCANDIDATEPROPERTY",
  RELEASEDPROCESSMODELINUSE = "RELEASEDPROCESSMODELINUSE",
  RELEASEDPROCESSMODELISPUBLISHED = "RELEASEDPROCESSMODELISPUBLISHED",
  CANDIDATEEMAILNOTUNIQUE = "CANDIDATEEMAILNOTUNIQUE",
  DOCUMENTNAMENOTUNIQUE = "DOCUMENTNAMENOTUNIQUE",
  DOCUMENTTEMPLATEISREQUIRED = "DOCUMENTTEMPLATEISREQUIRED",
  PROCESSMODELNOTAVAILABLE = "PROCESSMODELNOTAVAILABLE",
  SHARINGCONFIGURATIONCHANGED = "SHARINGCONFIGURATIONCHANGED",
  SHARINGISNOTCOLLABORATION = "SHARINGISNOTCOLLABORATION",
  SHARINGNOTVALID = "SHARINGNOTVALID",
  CANDIDATEMISSINGFIELDS = "CANDIDATEMISSINGFIELDS",
  SHARINGNNOTSUPPORTEDITECANDIDATE = "SHARINGNNOTSUPPORTEDITECANDIDATE",
  ORGUSEDASSOURCEORLANGSCHOOL = "ORGUSEDASSOURCEORLANGSCHOOL",
  DOCUMENTSALREADYUPLOADED = "DOCUMENTSALREADYUPLOADED",
  NOTARIZATIONSALREADYUPLOADED = "NOTARIZATIONSALREADYUPLOADED",
  TRANSLATIONSALREADYUPLOADED = "TRANSLATIONSALREADYUPLOADED",
  TASKDELETED = "TASKDELETED",
  MATCHINGENABLED = "MATCHINGENABLED",
  PARTICIPATION_CONDITION_PROCESS_CREATE = "PARTICIPATION_CONDITION_PROCESS_CREATE",
  PARTICIPATION_CONDITION_PROCESS_SYNC = "PARTICIPATION_CONDITION_PROCESS_SYNC",
  PARTICIPATION_CONDITION_PROCESS_SYNC_ALL = "PARTICIPATION_CONDITION_PROCESS_SYNC_ALL",
  PARTICIPATION_CONDITION_PROCESS_COLLECTION_CHANGE = "PARTICIPATION_CONDITION_PROCESS_COLLECTION_CHANGE",
  PARTICIPATION_CONDITION_CANDIDATE_ADD = "PARTICIPATION_CONDITION_CANDIDATE_ADD",
  PARTICIPATION_CONDITION_CANDIDATE_UPDATE = "PARTICIPATION_CONDITION_CANDIDATE_UPDATE",
  USEREXISTASCANDIDATE = "USEREXISTASCANDIDATE",
  NOFITTINGCVSEMPLATEAVAILABLE = "NOFITTINGCVSEMPLATEAVAILABLE",
  PROCESSINVALIDAUTOSTATECHANGESETTINGS = "PROCESSINVALIDAUTOSTATECHANGESETTINGS",
  LAYOUT_TEMPLATE_AT_LEAST_ONE_PLACEHOLDER = "LAYOUT_TEMPLATE_AT_LEAST_ONE_PLACEHOLDER",
  LAYOUT_TEMPLATE_MISSING_MARKERS = "LAYOUT_TEMPLATE_MISSING_MARKERS",
  LAYOUT_TEMPLATE_MISSING_MAIN_MARKER = "LAYOUT_TEMPLATE_MISSING_MAIN_MARKER",
  LAYOUT_TEMPLATE_UNIQUE_MARKERS = "LAYOUT_TEMPLATE_UNIQUE_MARKERS",
  CONTENT_TEMPLATE_AT_LEAST_ONE_NODE = "CONTENT_TEMPLATE_AT_LEAST_ONE_NODE",
  CONTENT_TEMPLATE_MISSING_MAIN_MARKER = "CONTENT_TEMPLATE_MISSING_MAIN_MARKER",
  TEMPLATE_OR_COLLECTION_NOT_FOUND = "TEMPLATE_OR_COLLECTION_NOT_FOUND",
  TEMPLATE_AND_COLLECTION_MISMATCH = "TEMPLATE_AND_COLLECTION_MISMATCH",
  TEMPLATE_AND_COLLECTION_VARIABLE_MISMATCH = "TEMPLATE_AND_COLLECTION_VARIABLE_MISMATCH",
  PROCESS_OPERATION_PROCESS_MODEL_MISMATCH = "PROCESS_OPERATION_PROCESS_MODEL_MISMATCH",
  SYNC_ALL_WITH_TEMPLATE_FAILED = "SYNC_ALL_WITH_TEMPLATE_FAILED",
  TEMPLATE_VARIABLES_CHANGED = "TEMPLATE_VARIABLES_CHANGED",
  TEMPLATE_USED_IN_PROCESSES = "TEMPLATE_USED_IN_PROCESSES",
  TEMPLATE_USED_IN_ACTIONS = "TEMPLATE_USED_IN_ACTIONS",
  CANDIDATE_NOT_FOUND = "CANDIDATE_NOT_FOUND",
  COLLECTION_NOT_FOUND = "COLLECTION_NOT_FOUND",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  TASK_NOT_FOUND = "TASK_NOT_FOUND",
  ORGANIZATION_NOT_FOUND = "ORGANIZATION_NOT_FOUND",
  DOCUMENT_NOT_FOUND = "DOCUMENT_NOT_FOUND",
  PROCESS_NOT_FOUND = "PROCESS_NOT_FOUND",
  TEMPLATE_NAME_NOT_UNIQUE = "TEMPLATE_NAME_NOT_UNIQUE",
  EDUCATION_EXAM_RESULTS_EXIST = "EDUCATION_EXAM_RESULTS_EXIST",
  ORGANIZATIONDOCUMENTISREQUIRED = "ORGANIZATIONDOCUMENTISREQUIRED",
  FEATURENOTAVAILABLE = "FEATURENOTAVAILABLE",
}
