<ng-container *appTranslate="let t">
  <p-card styleClass="flex flex-column user-emails">
    <ng-template pTemplate="header">
      <div class="pt-5 pb-4 pl-3 pr-3">
        <div class="pl-1 pr-1 pt-2 mt-1">
          <h3>{{ t("emails.ofUser") }} {{ userName$ | async }}</h3>
        </div>
      </div>
    </ng-template>

    <app-emails [userId]="userId" [organizationId]="organizationId"></app-emails>

    <ng-template pTemplate="footer">
      <div class="footer">
        <p-button
          [label]="t('common.back')"
          icon="pi pi-angle-left"
          class="p-button-success"
          [routerLink]="['../..']"
        ></p-button>
      </div>
    </ng-template>
  </p-card>
</ng-container>
