import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl } from "@angular/forms";
import { IVaccinationModel } from "@ankaadia/ankaadia-shared";
import { RequiredVaccinationsResultEntry, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { FieldType } from "@ngx-formly/core";
import { groupBy, isEmpty, isEqual } from "lodash";
import { Message } from "primeng/api";
import { combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, startWith, switchMap } from "rxjs";
import { CurrentCollectionService } from "../../features/candidates/current-collection.service";
import { RequiredVaccinationsService } from "../../features/required-vaccinations/required-vaccinations.service";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataService } from "../../shared/static-data/static-data.service";

enum VaccinationInfoSource {
  UserOrganization = "UserOrganization",
  ParentOrganization = "ParentOrganization",
}

@Component({
  selector: "app-formly-vaccination-information",
  templateUrl: "./formly-vaccination-information.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyVaccinationInformationComponent extends FieldType implements OnInit {
  private readonly lang = this.transloco.getActiveLang();
  private readonly cacheKey = "vaccination-information-source-organization";

  sourceOrganizationControlSwitch = new FormControl<VaccinationInfoSource>(this.getDefaultVaccinationInfoSource());

  organizationOptions = this.getOptions();

  vaccineStaticData$ = this.staticDataService.getStaticData(StaticDataType.Vaccination, this.lang);
  vaccineFunctionStaticData$ = this.staticDataService.getStaticData(StaticDataType.VaccineFunction, this.lang);

  messages: Message[] = [];
  showSourceOrganizationControlSwitch = false;

  constructor(
    private readonly settings: SettingsService,
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly requiredVaccinesService: RequiredVaccinationsService,
    private readonly currentCollectionService: CurrentCollectionService,
    private readonly destroyRef: DestroyRef,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.showSourceOrganizationControlSwitch =
      !this.settings.isCandidate && this.settings.organizationId != this.formState.organizationId;

    combineLatest([
      this.getRequiredVaccinations$(),
      this.getFullVaccines$(),
      this.vaccineStaticData$,
      this.vaccineFunctionStaticData$,
    ])
      .pipe(
        map(([required, current, vaccineStaticData, vaccineFunctionStaticData]) =>
          this.getMissingVaccines(required, current, vaccineStaticData, vaccineFunctionStaticData)
        ),
        distinctUntilChanged(isEqual),
        map((x) => this.getWarnings(x))
      )
      .subscribe((messages) => {
        this.messages = messages;
        this.changeDetector.detectChanges();
      });

    this.sourceOrganizationControlSwitch.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((vaccinationSource) => this.cacheVaccinationSource(vaccinationSource));
  }

  private getFullVaccines$(): Observable<string[]> {
    const formControl = this.field?.parent?.fieldGroup?.find((x) => x.key === "vaccinations")?.formControl;
    return formControl.valueChanges.pipe(
      startWith(formControl.value),
      map(
        (entry: IVaccinationModel[]) =>
          entry?.filter((x) => x.vaccinationStatus === "FULL")?.flatMap((v) => v.vaccinations) ?? []
      )
    );
  }

  private getRequiredVaccinations$(): Observable<RequiredVaccinationsResultEntry[]> {
    return this.getRequiredVaccinationsSourceOrgId$().pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(50),
      switchMap((x) => this.loadRequiredVaccinations$(x))
    );
  }

  private getRequiredVaccinationsSourceOrgId$(): Observable<string> {
    if (this.formState.organizationId == this.settings.organizationId) return of(this.settings.organizationId);

    return this.sourceOrganizationControlSwitch.valueChanges.pipe(
      startWith(this.sourceOrganizationControlSwitch.value),
      map((useUserOrgConfig) =>
        useUserOrgConfig === VaccinationInfoSource.UserOrganization
          ? this.settings.organizationId
          : this.currentCollectionService.organizationId
      )
    );
  }

  private loadRequiredVaccinations$(overviewOrganizationId: string): Observable<RequiredVaccinationsResultEntry[]> {
    if (!this.formState.organizationId || !this.formState.candidateId) return of([]);
    return this.requiredVaccinesService.get({
      overviewOrganizationId: overviewOrganizationId ?? this.formState.organizationId,
      candidateOrganizationId: this.formState.organizationId,
      candidateId: this.formState.candidateId,
    });
  }

  private getMissingVaccines(
    required: RequiredVaccinationsResultEntry[],
    current: string[],
    vaccineStaticData: StaticDataModel[],
    vaccineFunctionStaticData: StaticDataModel[]
  ): { type: string; missing: string[] }[] {
    const grouped: Record<string, RequiredVaccinationsResultEntry[]> = groupBy(required, (v) => v.vaccineFunction);

    return Object.entries(grouped)
      .map(([key, value]) => ({
        type: vaccineFunctionStaticData.find((v) => v.value === key)?.label,
        missing: value
          .flatMap((v) => v.vaccines)
          .filter((v) => !current.includes(v))
          .map((x) => vaccineStaticData.find((v) => v.value === x)?.label),
      }))
      .filter((x) => !isEmpty(x.missing));
  }

  private getWarnings(missing: { type: string; missing: string[] }[]): Message[] {
    return isEmpty(missing)
      ? []
      : missing.map((x) => ({
          severity: "warn",
          summary: translate("vaccinations.missing.title", { type: x.type }),
          detail: x.missing.join(", "),
        }));
  }

  private getOptions(): { value: VaccinationInfoSource; label: string }[] {
    return [
      { value: VaccinationInfoSource.UserOrganization, label: this.settings.organizationName },
      {
        value: VaccinationInfoSource.ParentOrganization,
        label: this.currentCollectionService.organizationName,
      },
    ].filter((x) => this.settings.isLicensed || x.value === VaccinationInfoSource.ParentOrganization);
  }

  private cacheVaccinationSource(vaccinationSource: VaccinationInfoSource): void {
    localStorage.setItem(this.cacheKey, vaccinationSource);
  }

  private getDefaultVaccinationInfoSource(): VaccinationInfoSource {
    if (!this.settings.isLicensed) return VaccinationInfoSource.ParentOrganization;

    return localStorage.getItem(this.cacheKey) == VaccinationInfoSource.ParentOrganization
      ? VaccinationInfoSource.ParentOrganization
      : VaccinationInfoSource.UserOrganization;
  }
}
