import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  languageSkillLabelMapping,
  DocumentType,
  languageLevelGermanCertificateMapping,
  ENABLE_EDUCATION,
} from "@ankaadia/ankaadia-shared";
import { CertifiedEducationExamFragment, StaticDataType } from "@ankaadia/graphql";
import { FieldType } from "@ngx-formly/core";
import { Observable, of, map } from "rxjs";
import { EducationExamService } from "../../features/education/education-exams/education-exam.service";
import { StaticDataService } from "../../shared/static-data/static-data.service";

@Component({
  selector: "app-formly-certified-education-exams",
  templateUrl: "./formly-certified-education-exams.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyCertifiedEducationExamsComponent extends FieldType implements OnInit {
  readonly languageSkillLabelMapping = languageSkillLabelMapping;
  readonly languages$ = this.staticDataService.getStaticData(StaticDataType.Languages);
  readonly modules$ = this.staticDataService.getStaticData(StaticDataType.LanguageModules);
  readonly institutions$ = this.staticDataService.getStaticData(StaticDataType.ExamInstitutions);

  exams$: Observable<CertifiedExamData[]>;

  constructor(
    private readonly educationExamService: EducationExamService,
    private readonly staticDataService: StaticDataService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.exams$ = this.formState.candidateId && ENABLE_EDUCATION ? this.getCertifiedExamData() : of(null);
    this.changeDetector.detectChanges();
  }

  private getSelectedSet(skillLevel: string): DocumentType {
    if (!skillLevel) return "LANGCERT";

    return languageLevelGermanCertificateMapping[skillLevel] ?? "LANGCERT";
  }

  private getCertifiedExamData(): Observable<CertifiedExamData[]> {
    return this.educationExamService
      .getCertified(this.formState.candidateId, this.formState.organizationId)
      .pipe(map((x) => x.map((x) => this.toCertifiedExamData(x))));
  }

  private toCertifiedExamData(exam: CertifiedEducationExamFragment): CertifiedExamData {
    return {
      ...exam,
      documentType: this.getSelectedSet(exam.languageLevel),
    };
  }
}

interface DocumentData {
  documentType: DocumentType;
}

type CertifiedExamData = CertifiedEducationExamFragment & DocumentData;
