<ng-container *appTranslate="let t">
  <app-table
    [items]="enhancedTemplates"
    [columns]="mode === 'candidate' ? candidateColumns : collectionColumns"
    [captionOperations]="captionOperations"
    [tableOperations]="tableOperations"
    [scrollable]="true"
    [caption]="templatesOf"
    [search]="mode === 'candidate'"
  >
    <ng-template #caption *ngIf="mode === 'collection'">
      <p-multiSelect
        *ngIf="availableTemplates"
        [options]="availableTemplates"
        optionLabel="label"
        optionValue="value"
        [ngModel]="selectedTemplates"
        [placeholder]="t('template.placeholder')"
        [showToggleAll]="false"
        styleClass="mw-250 mr-2"
        (onChange)="templatesSelected($event.value)"
      ></p-multiSelect>
      <p-multiSelect
        *ngIf="!availableTemplates"
        [options]="groupSelect"
        optionLabel="label"
        optionValue="value"
        [group]="true"
        [ngModel]="selectedTemplates"
        [placeholder]="t('template.placeholder')"
        [showToggleAll]="false"
        styleClass="mw-250 mr-2"
        (onChange)="templatesSelected($event.value)"
      >
        <ng-template let-group pTemplate="group">
          <div class="flex align-items-center">
            <app-flag *ngIf="group.value" class="mr-2" [country]="group.value" [small]="true"></app-flag>
            <span>{{ group.label }}</span>
          </div>
        </ng-template>
      </p-multiSelect>
    </ng-template>
  </app-table>

  <p-overlayPanel #warningPanel [style]="{ width: '55vw' }">
    <ng-template pTemplate>
      <app-document-template-warnings [warnings]="selectedWarnings"></app-document-template-warnings>
    </ng-template>
  </p-overlayPanel>

  <p-sidebar [visible]="!!selectedTemplate" styleClass="p-sidebar-lg" [fullScreen]="isFullScreen">
    <ng-template pTemplate="header"
      ><button
        class="mr-2 p-button-rounded p-button-text"
        *ngIf="isFullScreen"
        pButton
        type="button"
        (click)="isFullScreen = false"
        icon="pi pi-arrow-right"
      ></button>
      <button
        class="mr-2 p-button-rounded p-button-text"
        *ngIf="!isFullScreen"
        pButton
        type="button"
        (click)="isFullScreen = true"
        icon="pi pi-arrow-left"
      ></button>
    </ng-template>
    <app-document-template-preview-dialog
      *ngIf="selectedTemplate"
      [url]="selectedTemplate.url"
      [fileName]="selectedTemplate.fileName"
      (saved)="saveEdit($event)"
      (closed)="closeEdit()"
    ></app-document-template-preview-dialog>
  </p-sidebar>
</ng-container>
