import { Injectable } from "@angular/core";
import { GetImmigrationAuthoritiesGQL, StaticDataModel, SupportedImmigrationCountry } from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ImmigrationAuthorityService {
  constructor(private readonly authoritiesGet: GetImmigrationAuthoritiesGQL) {}

  getImmigrationAuthorities(
    organizationId: string,
    immigrationCountry: SupportedImmigrationCountry,
    federalStates?: string[]
  ): Observable<StaticDataModel[]> {
    return this.authoritiesGet
      .fetch({ input: { organizationId, immigrationCountry, federalStates } }, { fetchPolicy: "cache-first" })
      .pipe(
        map((x) =>
          x.data.getImmigrationAuthorities
            .map((x) => ({ label: `${x.type} ${x.title}`, value: x.id }))
            .sort((a, b) => a.label.localeCompare(b.label))
        )
      );
  }
}
