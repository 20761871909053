import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { ActivityModule } from "../activity/activity.module";
import { NotificationBadgeComponent } from "./notification-badge/notification-badge.component";
import { NotificationConfigurationComponent } from "./notification-configuration/notification-configuration.component";
import { NotificationListDialogComponent } from "./notification-list-dialog/notification-list-dialog.component";
import { NotificationsComponent } from "./notifications.component";

@NgModule({
  imports: [SharedModule, ActivityModule],
  declarations: [
    NotificationsComponent,
    NotificationBadgeComponent,
    NotificationConfigurationComponent,
    NotificationListDialogComponent,
  ],
  exports: [NotificationsComponent, NotificationBadgeComponent],
})
export class NotificationsModule {}
