import { Directive, OnInit } from "@angular/core";
import { AutoComplete } from "primeng/autocomplete";

@Directive({ selector: "p-autoComplete" })
export class AutocompleteEmptySuggestionsFixDirective implements OnInit {
  private handleSuggestionsChangeSource: AutoComplete["handleSuggestionsChange"];

  constructor(private readonly autoComplete: AutoComplete) {}

  ngOnInit(): void {
    this.handleSuggestionsChangeSource = this.autoComplete.handleSuggestionsChange;
    this.autoComplete.handleSuggestionsChange = (): void => this.handleSuggestionsChange(this.autoComplete);
  }

  private handleSuggestionsChange(autoComplete: AutoComplete): void {
    if (!autoComplete._suggestions()) return;
    return this.handleSuggestionsChangeSource.call(autoComplete);
  }
}
