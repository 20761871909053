<app-table
  *appTranslate="let t"
  [columns]="columns"
  [title]="t('users.manage') + ' ' + (organizationName | async)"
  [items]="users"
  [newOperations]="newOperations"
  [tableOperations]="tableOperations"
  [footerOperations]="footerOperations"
  [(showSidebar)]="showDialog"
>
  <app-user-dialog (closed)="closeSidebar()"></app-user-dialog>
</app-table>
