import { Injectable } from "@angular/core";
import {
  GetDocumentRequirementGQL,
  GetDocumentRequirementsGQL,
  GetDocumentRequirementByTypeGQL,
  UpsertManyDocumentRequirementsGQL,
  GetDocumentRequirementQuery,
  GetDocumentRequirementsQuery,
  GetDocumentRequirementByTypeQuery,
  DocumentRequirementGetInput,
  DocumentRequirementListInput,
  DocumentRequirementGetByTypeInput,
  DocumentRequirementsUpsertInput,
  UpsertManyDocumentRequirementsMutation,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class DocumentRequirementService {
  constructor(
    private readonly getGql: GetDocumentRequirementGQL,
    private readonly getAllGql: GetDocumentRequirementsGQL,
    private readonly getByTypeGql: GetDocumentRequirementByTypeGQL,
    private readonly upsertManyGql: UpsertManyDocumentRequirementsGQL
  ) {}

  get(input: DocumentRequirementGetInput): Observable<GetDocumentRequirementQuery["getDocumentRequirement"]> {
    return this.getGql.fetch({ input: input }).pipe(map((result) => result.data.getDocumentRequirement));
  }

  getByType(
    input: DocumentRequirementGetByTypeInput
  ): Observable<GetDocumentRequirementByTypeQuery["getDocumentRequirementByType"]> {
    return this.getByTypeGql.fetch({ input: input }).pipe(map((result) => result.data.getDocumentRequirementByType));
  }

  getAll(input: DocumentRequirementListInput): Observable<GetDocumentRequirementsQuery["getDocumentRequirements"]> {
    return this.getAllGql.fetch({ input: input }).pipe(map((result) => result.data.getDocumentRequirements));
  }

  upsertMany(
    input: DocumentRequirementsUpsertInput
  ): Observable<UpsertManyDocumentRequirementsMutation["upsertManyDocumentRequirements"]> {
    return this.upsertManyGql
      .mutate({ input: input })
      .pipe(map((result) => result.data.upsertManyDocumentRequirements));
  }
}
