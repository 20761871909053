import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SelectedDocumentField, nameofFactory } from "@ankaadia/ankaadia-shared";
import { CompletionState, StaticDataModel } from "@ankaadia/graphql";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { DocumentForm } from "../document-form.model";
import { isNil } from "lodash";

const nameOf = nameofFactory<DocumentPropertiesComponent>();

@Component({
  selector: "app-document-properties",
  templateUrl: "./document-properties.component.html",
  styleUrls: ["./document-properties.component.css"],
})
export class DocumentPropertiesComponent implements OnChanges {
  @Input({ required: true }) form: DocumentForm;
  @Input({ required: true }) language: string;
  @Input({ required: true }) readonly: boolean;

  @Input() showInternalDoc: boolean;
  @Input() showDocumentCompletion: boolean;
  @Input() selectedField: SelectedDocumentField;

  protected readonly isNil = isNil;

  protected completionStates: StaticDataModel[] = [];
  protected showInternalDocInternal: boolean = null;
  protected showDocumentCompletionInternal: boolean = null;

  constructor(
    private readonly settings: SettingsService,
    private readonly staticData: StaticDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const organizationId = this.form.controls.organizationId.value;
    this.showInternalDocInternal =
      (this.showInternalDoc ?? organizationId == this.settings.organizationId) && !this.settings.isCandidate;

    this.showDocumentCompletionInternal =
      (this.showDocumentCompletion ?? organizationId == this.settings.organizationId) && !this.settings.isCandidate;

    if (changes[nameOf("language")]) {
      this.completionStates = this.staticData.transformEnumToStaticDataModel("CompletionState", CompletionState, {
        language: this.language,
      });
    }
  }

  isHighlighted(field: SelectedDocumentField): boolean {
    return this.selectedField === field;
  }
}
