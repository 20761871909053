import { keys } from "lodash";
import { Merge, PickProperties, Primitive } from "ts-essentials";
import { Property } from "./nameofFactory";

export type Predicate<T> = (value: T) => boolean;
export type ArrayProperties<T> = { [K in keyof T]: T[K] extends any[] ? K : never }[keyof T];
export type Intersect<T, K> = Pick<T | K, keyof T & keyof K>;
export type Union<T extends readonly string[]> = T[number];
export type PickPropertyKeys<T, TFieldType> = keyof PickProperties<T, TFieldType>;
export type RequireKeys<T extends keyof any> = { [K in T]: K };
export type PrefixKeys<T extends Record<string, any>, Prefix extends string> = {
  [K in keyof T as `${Prefix}${string & K}`]: T[K];
};
export type KeyOf<T> = keyof T;
export type KeysOf<T> = KeyOf<T>[];

export type DerivedProperties<T, Replacements> = {
  [K in keyof Replacements]: K extends keyof T ? (Replacements[K] extends T[K] ? Replacements[K] : never) : never;
};

export type SafeMerge<T, Replacements extends DerivedProperties<T, Replacements>> = Merge<T, Replacements>;

export type SplitProperty<S extends string> = S extends `${infer T}.${infer U}` ? [T, ...SplitProperty<U>] : [S];
export type GetPropertyTypeViaArrayPath<T, Path extends readonly string[]> = Path extends [infer Head, ...infer Tail]
  ? Head extends keyof T
    ? Tail extends readonly string[]
      ? GetPropertyTypeViaArrayPath<T[Head], Tail>
      : never
    : never
  : T;
export type GetPropertyType<T, P extends Property<T>> = GetPropertyTypeViaArrayPath<T, SplitProperty<P>>;

/**
 * Recursively removes `TField` from `TModel`.
 */
export type WithoutField<TModel, TField extends string> = TModel extends Primitive | Primitive[] | Date
  ? TModel
  : {
      [key in keyof Omit<TModel, TField>]: TModel[key] extends (infer TInferredArrayValueType)[]
        ? WithoutField<TInferredArrayValueType, TField>[]
        : TModel[key] extends Record<string, any>
          ? WithoutField<TModel[key], TField>
          : TModel[key];
    };

export function requireKeys<T extends string>(values: RequireKeys<T>): T[] {
  return keys(values).map((key) => values[key]);
}

export function prefixKeys<Prefix extends string, T extends Record<string, any>>(
  prefix: Prefix,
  obj: T
): PrefixKeys<T, Prefix> {
  const result = {} as PrefixKeys<T, Prefix>;

  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const newLocal = `${prefix}${key}`;
      result[newLocal] = obj[key];
    }
  }

  return result;
}
