import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { SettingsService } from "../../../shared/services/settings.service";
import { DocumentTemplatesService } from "../document-templates.service";
import { CustomLazyLoadEvent } from "../../collections/collection-edit-assigned-candidates/custom-filter.model";

@Component({
  selector: "app-document-template-reports-button",
  templateUrl: "./document-template-reports-button.component.html",
  styleUrl: "./document-template-reports-button.component.scss",
})
export class DocumentTemplateReportsButtonComponent implements OnInit, OnChanges {
  @Input()
  organizationId: string;

  @Input()
  collectionId: string;

  @Input()
  sharingId: string;

  @Input()
  sharingOrganizationId: string;

  @Input()
  customLazyLoadEvent: CustomLazyLoadEvent;

  constructor(
    private readonly templateService: DocumentTemplatesService,
    protected readonly settings: SettingsService
  ) {}

  ngOnChanges(_changes: SimpleChanges): void {
    this.disableButton = false;
  }

  protected showButton = false;

  protected showSideBar = false;

  protected disableButton = true;

  showReportSidebarClicked(_event: MouseEvent): void {
    this.showSideBar = true;
  }

  onSideBarClose(_event: any): void {
    this.showSideBar = false;
  }

  ngOnInit(): void {
    this.templateService.getAvailableReportTemplates().subscribe((xs) => {
      const availableReportTemplates = xs?.slice();
      this.showButton = this.settings.isLicensed && !this.settings.isCandidate && availableReportTemplates?.length > 0;
    });
  }
}
