import { Injectable } from "@angular/core";
import {
  AdHocEducationExamCreateInput,
  AdHocEducationExamFragment,
  AdHocEducationExamUpdateInput,
  CertifiedEducationExamFragment,
  CreateAdHocEducationExamGQL,
  CreateEducationExamGQL,
  DeleteEducationExamGQL,
  EducationExamBaseDataFragment,
  EducationExamCreateInput,
  EducationExamFragment,
  EducationExamFragmentDoc,
  EducationExamUpdateInput,
  ExamStatus,
  GetAdHocEducationExamByCandidateIdGQL,
  GetAdHocEducationExamsGQL,
  GetCertifiedEducationExamsGQL,
  GetEducationExamByCandidateIdGQL,
  GetEducationExamByCourseIdGQL,
  GetEducationExamDataGQL,
  GetEducationExamsGQL,
  UpdateAdHocEducationExamGQL,
  UpdateEducationExamGQL,
  GetHighestPlacementTestGQL,
  HighestPlacementEducationExamFragment,
  EducationExamForCandidateFragment,
  AdHocEducationExamForCandidateFragment,
} from "@ankaadia/graphql";
import { omit, pick } from "lodash";
import { Observable, map } from "rxjs";
import { removeQueryFromRootCache } from "../education-exam-results/education-exam-results.service";

@Injectable({
  providedIn: "root",
})
export class EducationExamService {
  constructor(
    private readonly examDataGet: GetEducationExamDataGQL,
    private readonly examsGet: GetEducationExamsGQL,
    private readonly examGet: GetEducationExamByCourseIdGQL,
    private readonly examGetByCandidateId: GetEducationExamByCandidateIdGQL,
    private readonly examCreate: CreateEducationExamGQL,
    private readonly examUpdate: UpdateEducationExamGQL,
    private readonly examDelete: DeleteEducationExamGQL,
    private readonly adHocExamsGet: GetAdHocEducationExamsGQL,
    private readonly adHocExamGetByCandidateId: GetAdHocEducationExamByCandidateIdGQL,
    private readonly adHocExamCreate: CreateAdHocEducationExamGQL,
    private readonly adHocExamUpdate: UpdateAdHocEducationExamGQL,
    private readonly certified: GetCertifiedEducationExamsGQL,
    private readonly highestPlacementTest: GetHighestPlacementTestGQL
  ) {}

  getSingle(organizationId: string, id: string): Observable<EducationExamBaseDataFragment> {
    return this.examDataGet.fetch({ input: { organizationId, id } }).pipe(map((x) => x.data.getEducationExamData));
  }

  getAll(organizationId: string, status?: ExamStatus): Observable<EducationExamFragment[]> {
    return this.examsGet
      .watch({ input: { organizationId, status } })
      .valueChanges.pipe(map((x) => x.data.getEducationExams));
  }

  getByCourseId(courseId: string, organizationId: string): Observable<EducationExamFragment[]> {
    return this.examGet
      .fetch({ input: { courseId, organizationId } })
      .pipe(map((x) => x.data.getEducationExamsByCourseId));
  }

  getByCandidateId(candidateId: string, organizationId: string): Observable<EducationExamForCandidateFragment[]> {
    return this.examGetByCandidateId
      .fetch({ input: { candidateId, organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getEducationExamsByCandidateId));
  }

  create(exam: EducationExamCreateInput): Observable<EducationExamFragment> {
    return this.examCreate
      .mutate(
        { input: omit(exam, "id", "_etag", "changedAt", "changedAt") },
        {
          update: (cache, x) =>
            cache.modify({
              fields: {
                getEducationExams: (refs) => insertIntoCache(refs, cache, x.data.createEducationExam),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createEducationExam));
  }

  update(exam: EducationExamUpdateInput): Observable<EducationExamFragment> {
    return this.examUpdate
      .mutate({ input: omit(exam, "changedAt", "changedAt") })
      .pipe(map((x) => x.data.updateEducationExam));
  }

  delete(exam: EducationExamFragment): Observable<boolean> {
    const input = pick(exam, "id", "_etag", "organizationId");
    return this.examDelete
      .mutate(
        { input },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getEducationExams: (refs, helper) => removeFromCache(input, refs, helper),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteEducationExam.status));
  }

  getAllAdHoc(organizationId: string, status?: ExamStatus): Observable<AdHocEducationExamFragment[]> {
    return this.adHocExamsGet
      .watch({ input: { organizationId, status } })
      .valueChanges.pipe(map((x) => x.data.getAdHocEducationExams));
  }

  getAdHocByCandidateId(
    candidateId: string,
    organizationId: string
  ): Observable<AdHocEducationExamForCandidateFragment[]> {
    return this.adHocExamGetByCandidateId
      .fetch({ input: { candidateId, organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getAdHocEducationExamsByCandidateId));
  }

  getCertified(candidateId: string, organizationId: string): Observable<CertifiedEducationExamFragment[]> {
    return this.certified
      .fetch({ input: { candidateId, organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getCertifiedEducationExams));
  }

  getHighestPlacementTest(
    candidateId: string,
    organizationId: string
  ): Observable<HighestPlacementEducationExamFragment> {
    return this.highestPlacementTest
      .fetch({ input: { candidateId, organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getHighestPlacementTest));
  }

  createAdHoc(exam: AdHocEducationExamCreateInput): Observable<AdHocEducationExamFragment> {
    return this.adHocExamCreate
      .mutate(
        { input: omit(exam, "id", "_etag", "changedAt", "changedAt") },
        {
          update: (cache, x) =>
            cache.modify({
              fields: {
                getAdHocEducationExams: (refs) => insertIntoCache(refs, cache, x.data.createAdHocEducationExam),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createAdHocEducationExam));
  }

  updateAdHoc(exam: AdHocEducationExamUpdateInput): Observable<AdHocEducationExamFragment> {
    return this.adHocExamUpdate
      .mutate(
        { input: omit(exam, "changedAt", "changedAt") },
        { update: (cache) => removeCandidateExamQueriesFromCache(cache) }
      )
      .pipe(map((x) => x.data.updateAdHocEducationExam));
  }

  deleteAdHoc(exam: AdHocEducationExamFragment): Observable<boolean> {
    const input = pick(exam, "id", "_etag", "organizationId");
    return this.examDelete
      .mutate(
        { input },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getAdHocEducationExams: (refs, helper) => removeFromCache(input, refs, helper),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteEducationExam.status));
  }
}

export function removeExamsByCandidateFromCache(cache: any, candidateId = ""): void {
  removeQueryFromRootCache(cache, new RegExp(`getEducationExamsByCandidateId.*${candidateId}`));
}

export function removeAdHocExamsByCandidateFromCache(cache: any, candidateId = ""): void {
  removeQueryFromRootCache(cache, new RegExp(`getAdHocEducationExamsByCandidateId.*${candidateId}`));
}

function removeGetCertifiedEducationExamsFromCache(cache: any, candidateId = ""): void {
  removeQueryFromRootCache(cache, new RegExp(`getCertifiedEducationExams.*${candidateId}`));
}

function removeGetHighestPlacementTestFromCache(cache: any, candidateId = ""): void {
  removeQueryFromRootCache(cache, new RegExp(`getHighestPlacementTest.*${candidateId}`));
}

export function removeCandidateExamQueriesFromCache(cache: any, candidateId = ""): void {
  removeGetCertifiedEducationExamsFromCache(cache, candidateId);
  removeAdHocExamsByCandidateFromCache(cache, candidateId);
  removeExamsByCandidateFromCache(cache, candidateId);
  removeGetHighestPlacementTestFromCache(cache, candidateId);
}

function insertIntoCache(refs, cache, data): any {
  const ref = cache.writeFragment({
    data: data,
    fragment: EducationExamFragmentDoc,
    fragmentName: "EducationExam",
  });
  if (refs != null && refs.length > 0) {
    return [...refs, ref];
  } else {
    return [ref];
  }
}

function removeFromCache(input, refs, { readField }): any {
  return refs.filter((ref) => readField("id", ref) !== input.id);
}
