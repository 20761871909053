<ng-container *transloco="let t">
  <p-table [value]="diff">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th>{{ t("differences.oldValue") }}</th>
        <th>{{ t("differences.newValue") }}</th>
        <th>{{ t("changedBy.title") }}</th>
        <th>{{ t("changedAt.title") }}</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-diff>
      <tr>
        <td>{{ diff.data.oldValue }}</td>
        <td>{{ diff.data.newValue }}</td>
        <td>{{ diff.data.changedBy }}</td>
        <td>{{ diff.data.changedAt | appDate: { dateStyle: "short", timeStyle: "short" } : language }}</td>

        <!--
          // todo: should be rendered like this
          <div *ngIf="...it's an html field..." class="ql-editor" [innerHtml]="diff.data.newValue | safe: 'html'"></div>
          // perhaps extract it to an diff-value component that can be used 4 times: for the old and new value, in the activity-diff and field-diff components
        -->
      </tr>
    </ng-template>
  </p-table>
</ng-container>
