import { AfterViewInit, Directive, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { MultiSelect } from "primeng/multiselect";

@Directive({ selector: "p-multiSelect[emptyValue]" })
export class MultiSelectEmptyDirective implements AfterViewInit {
  @Input()
  emptyValue: string;

  constructor(
    private readonly multiSelect: MultiSelect,
    private readonly control: NgControl
  ) {}

  ngAfterViewInit(): void {
    if (this.emptyValue) {
      this.multiSelect.onChange.subscribe((e) => {
        if (e.itemValue === this.emptyValue) {
          if (this.control.control.value?.length > 0) {
            this.control.control.setValue([this.emptyValue]);
          } else {
            this.control.control.setValue([]);
          }
        } else {
          this.control.control.setValue(this.multiSelect.value.filter((v) => v !== this.emptyValue));
        }
      });
    }
  }
}
