import { Directive, Input, OnInit } from "@angular/core";
import { AutoComplete } from "primeng/autocomplete";
import { ObjectUtils } from "primeng/utils";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

@Directive({ selector: "p-autoComplete[optionValue]" })
export class AutocompleteOptionValueFixDirective implements OnInit {
  private readonly writeValue: AutoComplete["writeValue"];
  private handleSuggestionsChange: AutoComplete["handleSuggestionsChange"];

  constructor(private readonly autoComplete: AutoComplete) {
    this.writeValue = this.autoComplete.writeValue;
    this.autoComplete.writeValue = (value: any): void => this.handleWriteValue(this.autoComplete, value);
  }

  @Input() optionValue: string;

  private readonly suggestionsChanged = new BehaviorSubject<any[]>(null);
  private readonly unsubscribe = new Subject();

  ngOnInit(): void {
    this.handleSuggestionsChange = this.autoComplete.handleSuggestionsChange;
    this.autoComplete.handleSuggestionsChange = (): void => this.handleSuggestionsChanges(this.autoComplete);
    this.autoComplete.selectItem = (option: any, focus: boolean): void => this.selectItem(option, focus);
  }

  handleWriteValue(autoComplete: AutoComplete, value: any): void {
    if (value) {
      this.suggestionsChanged.pipe(takeUntil(this.unsubscribe)).subscribe((suggestions) => {
        const value2 = autoComplete.suggestions?.find((suggestion) => suggestion[this.optionValue] === value);
        this.writeValue.call(autoComplete, value2 ?? { [this.optionValue]: value, [autoComplete.field]: value });
        if (suggestions) {
          this.unsubscribe.next(null);
        }
      });
    } else {
      this.writeValue.call(autoComplete, { [this.optionValue]: value, [autoComplete.field]: value });
    }
  }

  handleSuggestionsChanges(autoComplete: AutoComplete): void {
    this.suggestionsChanged.next(autoComplete.suggestions);
    this.handleSuggestionsChange.call(autoComplete);
  }

  selectItem(option: any, focus = true): void {
    if (this.autoComplete.forceSelectionUpdateModelTimeout) {
      clearTimeout(this.autoComplete.forceSelectionUpdateModelTimeout);
      this.autoComplete.forceSelectionUpdateModelTimeout = null;
    }

    if (this.autoComplete.multiple) {
      this.autoComplete.multiInputEl.nativeElement.value = "";
      this.autoComplete.value = this.autoComplete.value || [];
      if (!this.autoComplete.isSelected(option) || !this.autoComplete.unique) {
        this.autoComplete.value = [...this.autoComplete.value, ObjectUtils.resolveFieldData(option, this.optionValue)];
        this.autoComplete.onModelChange(this.autoComplete.value);
      }
    } else {
      this.autoComplete.inputEL.nativeElement.value = this.autoComplete.resolveFieldData(option);
      this.autoComplete.value = ObjectUtils.resolveFieldData(option, this.optionValue);
      this.autoComplete.onModelChange(this.autoComplete.value);
    }

    this.autoComplete.onSelect.emit(option);
    this.autoComplete.updateFilledState();

    if (focus) {
      this.autoComplete.itemClicked = true;
      this.autoComplete.focusInput();
    }

    this.autoComplete.hide();
  }
}
