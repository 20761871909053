import { Component, OnInit } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

@Component({
  selector: "app-update-dialog",
  templateUrl: "./update-dialog.component.html",
})
export class UpdateDialogComponent implements OnInit {
  readonly language = this.transloco.getActiveLang();

  updates: { date: Date; version: string; changes: string }[];

  constructor(
    private readonly config: DynamicDialogConfig,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.updates = this.config.data;
  }
}
