import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import {
  ANKAADIA_VERSION,
  getFiles,
  getFreeFormatFiles,
  getSetFiles,
  getSetLabel,
  getSets,
  isDocumentSelectionMode,
  isInternalDocument,
  isSingleDocumentSet,
  isSupportedByDocumentPreview,
  nameofFactory,
  safeJoin,
} from "@ankaadia/ankaadia-shared";
import {
  CandidateForProcessFragment,
  Document,
  DocumentFile,
  DocumentGenerationMode,
  DocumentLockAllInput,
  DocumentLockInput,
  DocumentLockMode,
  DocumentMode,
  DocumentSet,
  DocumentTemplateMode,
  Sharing,
  StaticDataModel,
  StaticDataType,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { clone, concat, isEmpty, noop } from "lodash";
import { BlockableUI, ConfirmationService, MenuItem, MenuItemCommandEvent, PrimeIcons } from "primeng/api";
import { Observable, Subscription, finalize, forkJoin, map, of, switchMap, tap } from "rxjs";
import { AppDateTimePipe } from "../../../shared/pipes/date.pipe";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { DateFormatterService } from "../../../shared/services/date-formatter.service";
import { DownloadService } from "../../../shared/services/download.service";
import { safeForkJoin } from "../../../shared/services/safe-fork-join";
import { SettingsService } from "../../../shared/services/settings.service";
import { TagColor } from "../../../shared/services/style.helper";
import { StaticDataPipe } from "../../../shared/static-data/static-data.pipe";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import {
  PipeDescription,
  TableColumn,
  TableMenuItem,
  TableOperation,
  TableOperationMode,
  TableTag,
} from "../../../shared/table/table.model";
import { CandidateDocumentMetadataService } from "../../candidate-document-metadata/candidate-document-metadata.service";
import { CandidateForm } from "../../candidates/candidate-form/candidate-form.model";
import { extractCandidateFromForm } from "../../candidates/candidate-helper";
import { MessageService } from "../../message/message.service";
import { DocumentCriteriaService } from "../document-criteria-config/document-criteria.service";
import { DocumentsService } from "../documents.service";

const nameOf = nameofFactory<Document>();
const nameOfComponent = nameofFactory<DocumentTableComponent>();

@Component({
  selector: "app-document-table",
  templateUrl: "./document-table.component.html",
  styleUrl: "./document-table.component.scss",
})
export class DocumentTableComponent implements OnInit, OnChanges, OnDestroy, BlockableUI {
  protected readonly language = this.transloco.getActiveLang();
  protected readonly StaticDataType = StaticDataType;
  protected readonly DocumentMode = DocumentMode;
  protected readonly isDocumentSelectionMode = isDocumentSelectionMode;
  protected readonly extractCandidateFromForm = extractCandidateFromForm;

  @Input()
  isBlocked: boolean;

  @Input()
  organizationId: string;

  @Input()
  candidateId: string;

  @Input()
  candidate?: CandidateForProcessFragment;

  @Input()
  candidateForm?: CandidateForm;

  @Input()
  familyMemberId: string;

  @Input()
  personName: string;

  @Input()
  confirmCreation: boolean;

  @ContentChild("caption", { read: TemplateRef })
  captionTemplate: TemplateRef<any>;

  @Input()
  mode: DocumentMode;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  readonly: boolean;

  @Input()
  showInternalDoc?: boolean;

  @Input()
  showDocumentCompletion?: boolean;

  @Input()
  candidateImmigrationCountry: string;

  @Input()
  selectedSharing?: Sharing;

  @Input()
  showArrows? = false;

  @Input()
  requiredDocumentsOrganizationId: string;

  @Input()
  requiredDocumentSetIds: string[];

  @Input()
  styleClass?: string;

  @Output()
  readonly saved = new EventEmitter<void>();

  protected title: string;
  protected warningMessage: string;
  protected tableOperations: TableOperation[];
  protected newOperations: TableOperation[];
  protected captionOperations: TableOperation[];
  protected columns: TableColumn[];
  protected documents: Document[];
  protected documentSubscription: Subscription;
  protected selectedDocument: Document;
  protected creatableDocumentTypes: StaticDataModel[];
  protected selectedFile: { file: DocumentFile; document: Document; url: string };
  protected showSidebar: boolean;
  protected tagClickDisabled: boolean;
  protected isDownloadingZip: boolean;
  protected staticDataDocFormats: StaticDataModel[];
  protected staticDataAllowedFileTypes: StaticDataModel[];
  protected sortField: string;

  constructor(
    protected readonly settings: SettingsService,
    private readonly documentService: DocumentsService,
    private readonly metadataService: CandidateDocumentMetadataService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly transloco: TranslocoService,
    private readonly dateFormatterService: DateFormatterService,
    private readonly downloadService: DownloadService,
    private readonly staticDataService: StaticDataService,
    private readonly elementRef: ElementRef,
    private readonly criteriaService: DocumentCriteriaService
  ) {}

  ngOnInit(): void {
    this.updateTableOperations(this.processLanguage);
  }

  getBlockableElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const candidateIdChanges = changes[nameOfComponent("candidateId")];
    const organizationIdChanges = changes[nameOfComponent("organizationId")];
    const familyMemberIdChanges = changes[nameOfComponent("familyMemberId")];
    const requiredDocumentSetIdsChanges = changes[nameOfComponent("requiredDocumentSetIds")];
    const requiredDocumentsOrganizationIdChanges = changes[nameOfComponent("requiredDocumentsOrganizationId")];

    if (
      candidateIdChanges ||
      organizationIdChanges ||
      familyMemberIdChanges ||
      requiredDocumentSetIdsChanges ||
      requiredDocumentsOrganizationIdChanges
    ) {
      if (this.organizationId != null) {
        this.reload(this.organizationId, this.candidateId, this.familyMemberId);
      }
    }
    if (candidateIdChanges?.currentValue != null) {
      this.updateColumns(candidateIdChanges.currentValue, this.organizationId, this.mode, this.processLanguage);
    }
    if (organizationIdChanges) {
      this.updateColumns(this.candidateId, organizationIdChanges.currentValue, this.mode, this.processLanguage);
    }
    const modeChanges = changes[nameOfComponent("mode")];
    if (modeChanges) {
      this.updateColumns(this.candidateId, this.organizationId, modeChanges.currentValue, this.processLanguage);
      this.updateOperations(modeChanges.currentValue, this.processLanguage);
      this.updateTitle(modeChanges.currentValue, this.processLanguage);
      if (modeChanges.currentValue == DocumentMode.Template) {
        this.documentService.getMissingDocumentTemplates(this.settings.organizationId).subscribe((missingTemplates) => {
          this.warningMessage =
            missingTemplates?.length > 0
              ? this.transloco.translate("missing.templates", {
                  templates: missingTemplates.join(", "),
                })
              : null;
        });
      } else if (modeChanges.currentValue == DocumentMode.Organization) {
        this.documentService
          .getMissingOrganizationDocuments(this.settings.organizationId)
          .subscribe((missingDocuments) => {
            this.warningMessage =
              missingDocuments?.length > 0
                ? this.transloco.translate("missing.documents", {
                    documents: missingDocuments.join(", "),
                  })
                : null;
          });
      }
    }

    const processLanguageChanges = changes[nameOfComponent("processLanguage")];
    if (processLanguageChanges) {
      this.updateColumns(this.candidateId, this.organizationId, this.mode, processLanguageChanges.currentValue);
      this.updateOperations(this.mode, processLanguageChanges.currentValue);
      this.updateTitle(this.mode, processLanguageChanges.currentValue);
      this.updateTableOperations(processLanguageChanges.currentValue);
    }
    if (changes[nameOfComponent("readonly")]) {
      this.updateOperations(this.mode, this.processLanguage);
      this.updateTableOperations(this.processLanguage);
    }
  }

  ngOnDestroy(): void {
    this.documentSubscription?.unsubscribe();
  }

  cannotToggleLock(document: Document): boolean {
    return (
      this.settings.isCandidate ||
      (document.lockState == DocumentLockMode.LockedByCandidateOwner &&
        document.lockingOrganizationId != this.settings.organizationId)
    );
  }

  canNotDelete(document: Document): boolean {
    return !this.canDelete(document);
  }

  canDelete(document: Document): boolean {
    return !this.documentService.isReadOnly(document);
  }

  getLockIcon(document: Document): string {
    return document.lockState == DocumentLockMode.LockedByAProcessOwner ||
      document.lockState == DocumentLockMode.LockedByCandidateOwner
      ? PrimeIcons.LOCK
      : PrimeIcons.LOCK_OPEN;
  }

  toggleLockDocument(document: Document): void {
    const input: DocumentLockInput = {
      lockingOrganizationId: this.settings.organizationId,
      candidateId: document.candidateId,
      lockMode:
        document.lockState == DocumentLockMode.Unlocked || document.lockState == null
          ? this.settings.organizationId == document.organizationId
            ? DocumentLockMode.LockedByCandidateOwner
            : DocumentLockMode.LockedByAProcessOwner
          : DocumentLockMode.Unlocked,
      documents: [{ organizationId: document.organizationId, id: document.id }],
    };
    this.documentService.changeLockModeForDocuments(input).subscribe(
      () =>
        this.messageService.add({
          severity: "success",
          summary:
            input.lockMode != DocumentLockMode.Unlocked
              ? translate("document.locked", null, this.processLanguage)
              : translate("document.unlocked", null, this.processLanguage),
        }),
      () =>
        this.documentService
          .getAll(
            this.mode,
            this.organizationId,
            this.candidateId,
            this.familyMemberId,
            null,
            this.requiredDocumentSetIds,
            this.requiredDocumentsOrganizationId
          )
          .subscribe()
    );
  }

  unLockAllDocuments(event: Event): void {
    this.setLockModeOnAllDocuments(event, DocumentLockMode.Unlocked);
  }

  lockAllDocuments(event: Event): void {
    this.setLockModeOnAllDocuments(
      event,
      this.organizationId == this.settings.organizationId
        ? DocumentLockMode.LockedByCandidateOwner
        : DocumentLockMode.LockedByAProcessOwner
    );
  }

  setLockModeOnAllDocuments(event: Event, lockMode: DocumentLockMode): void {
    this.confirmationService.confirm({
      target: event.target,
      message:
        lockMode == DocumentLockMode.Unlocked
          ? translate("documents.confirmUnlock", null, this.processLanguage)
          : translate("documents.confirmLock", null, this.processLanguage),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        const input: DocumentLockAllInput = {
          lockingOrganizationId: this.settings.organizationId,
          lockMode: lockMode,
          candidateId: this.candidateId,
          familyMemberId: this.familyMemberId,
          organizationId: this.organizationId,
        };
        this.documentService.changeLockModeForDocumentsByCandidate(input).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary:
              lockMode != DocumentLockMode.Unlocked
                ? translate("documents.allLocked", null, this.processLanguage)
                : translate("documents.allUnlocked", null, this.processLanguage),
          });
        });
      },
    });
  }

  edit(document: Document): void {
    this.documentService
      .get(document.id, document.organizationId, document.candidateId)
      .pipe(
        switchMap((document) => {
          if (document?.mode !== DocumentMode.Candidate) {
            return of({ document, creatableDocumentTypes: [] });
          }

          const language = this.processLanguage;
          const { candidateId, familyMemberId, organizationId } = document;
          return this.documentService
            .getCreatableDocumentTypes({ candidateId, familyMemberId, organizationId, language })
            .pipe(map((creatableDocumentTypes) => ({ document, creatableDocumentTypes })));
        })
      )
      .subscribe(({ document, creatableDocumentTypes }) => {
        this.selectedDocument = document;
        this.creatableDocumentTypes = creatableDocumentTypes;
        this.selectedFile = null;
        this.showSidebar = true;
      });
  }

  delete(document: Document, event: Event): void {
    if (!this.canDelete(document)) {
      this.messageService.add({
        severity: "error",
        summary: translate(this.getTranslateKey("deleted.cant"), null, this.processLanguage),
      });

      return;
    }

    this.getDeletionConfirmationMessage(document).subscribe((message) => {
      this.confirmationService.confirm({
        target: event.target,
        message: message,
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        accept: () =>
          this.documentService.delete(document).subscribe(() => {
            this.messageService.add({
              severity: "success",
              summary: translate(this.getTranslateKey("deleted.title"), null, this.processLanguage),
              detail: translate(this.getTranslateKey("deleted.message"), document, this.processLanguage),
            });
            this.saved.emit();
          }),
      });
    });
  }

  private getDeletionConfirmationMessage(document: Document): Observable<string> {
    const lang = this.processLanguage;
    const title = this.metadataService.getTitle(document);
    return this.metadataService.getMetadata(document, extractCandidateFromForm(this.candidateForm)).pipe(
      map((metadata) => {
        const setLabels = getSets(document)
          .map((set) => metadata.find((meta) => meta.id === set.foreignKey))
          .filter((data) => data)
          .sort((a, b) => a.index - b.index)
          .map((data) => `#${data.index + 1}: ${data.label}`);

        if (setLabels.length) {
          const sets = safeJoin(setLabels, ", ");
          const params = { ...document, title, sets };
          return translate(this.getTranslateKey("confirmDeleteOfLinkedDocument"), params, lang);
        }

        return translate(this.getTranslateKey("confirmDelete"), document, lang);
      })
    );
  }

  save(): void {
    this.close();
  }

  downloadZip(): void {
    this.isDownloadingZip = true;
    this.documentService
      .getCandidateArchive(
        this.processLanguage,
        this.organizationId,
        this.candidateId,
        this.familyMemberId,
        this.requiredDocumentSetIds,
        this.requiredDocumentsOrganizationId
      )
      .pipe(finalize(() => (this.isDownloadingZip = false)))
      .subscribe((archive) => this.downloadService.downloadFile(archive.name, archive.url));
  }

  downloadMergedPDF(): void {
    this.documentService
      .convertManyDocuments(
        this.processLanguage,
        this.organizationId,
        this.candidateId,
        this.familyMemberId,
        this.requiredDocumentSetIds,
        this.requiredDocumentsOrganizationId
      )
      .subscribe({
        next: (file) => this.downloadService.downloadFile(file.fileName, file.url),
        error: (_) =>
          this.messageService.add({
            severity: "error",
            summary: translate("document.error.download", null, this.processLanguage),
          }),
      });
  }

  downloadFile(document: Document, file: DocumentFile, convert2PDF: boolean): void {
    if (convert2PDF) {
      if (!file.name.match(/.(png|jpe?g|docx|webp)$/g)) {
        this.messageService.add({
          severity: "warning",
          summary: translate("document.warning.convert", null, this.processLanguage),
        });
        return;
      }
      this.documentService
        .convertAndGetDocumentDownloadUrl(
          file.blob,
          document.organizationId,
          document.candidateId,
          this.processLanguage
        )
        .subscribe({
          next: (file) => this.downloadService.downloadFile(file.fileName, file.url),
          error: (_) =>
            this.messageService.add({
              severity: "error",
              summary: translate("document.error.download", null, this.processLanguage),
            }),
        });
    } else {
      this.documentService
        .getDownloadUrlAndFileName(file.blob, document.organizationId, document.candidateId, this.processLanguage)
        .subscribe({
          next: (file) => this.downloadService.downloadFile(file.fileName, file.url),
          error: (_) =>
            this.messageService.add({
              severity: "error",
              summary: translate("document.error.download", null, this.processLanguage),
            }),
        });
    }
  }

  close(): void {
    this.selectedDocument = null;
    this.creatableDocumentTypes = [];
    this.selectedFile = null;
    this.showSidebar = false;
  }

  protected create(templateMode: DocumentTemplateMode, generationMode: DocumentGenerationMode): void {
    const language = this.processLanguage;
    const { mode, candidateId, familyMemberId, organizationId } = this;
    const request = { candidateId, familyMemberId, organizationId, language };
    const isCandidateMode = this.mode === DocumentMode.Candidate;
    const document$ = this.documentService.createEmptyDocument(mode, organizationId, candidateId, familyMemberId, null);
    const creatableDocumentTypes$ = isCandidateMode ? this.documentService.getCreatableDocumentTypes(request) : of([]);

    forkJoin([document$, creatableDocumentTypes$]).subscribe(([document, creatableDocumentTypes]) => {
      document.templateMode = templateMode;
      document.generationMode = generationMode;
      this.selectedDocument = document;
      this.creatableDocumentTypes = creatableDocumentTypes;
      this.selectedFile = null;
      this.showSidebar = true;
    });
  }

  protected getKnownVariablesDocument(): void {
    this.documentService.getKnownTemplateVariablesDocument(this.settings.organizationId).subscribe((document) => {
      this.downloadService.downloadFile(
        `Known_Variables_${ANKAADIA_VERSION.replaceAll(".", "_")}_${new Date().getFullYear()}${
          new Date().getMonth() + 1
        }${new Date().getDate()}.docx`,
        document
      );
    });
  }

  private loadStaticData(candidateId: string, organizationId: string): Observable<void> {
    return forkJoin([
      this.staticDataService.getStaticData(StaticDataType.AllowedUploadFileTypes, this.processLanguage, {
        candidateId,
        organizationId,
      }),
      this.staticDataService.getStaticData(StaticDataType.DocumentFormats, this.processLanguage, {
        candidateId,
        organizationId,
      }),
    ]).pipe(
      tap(([staticDataAllowedFileTypes, docFformats]) => {
        this.staticDataAllowedFileTypes = staticDataAllowedFileTypes;
        this.staticDataDocFormats = docFformats;
      }),
      map(() => null)
    );
  }

  private getTags(document: Document): Observable<TableTag[] | null> {
    return isDocumentSelectionMode(this.mode)
      ? !document.selectionCriteria
        ? of(null)
        : this.getTagFromCriteria(document)
      : of(this.getTagsForDocumentSets(document));
  }

  private getTagFromCriteria(document: Document): Observable<TableTag[] | null> {
    if (!document.selectionCriteria) {
      return null;
    }

    const label = document.selectionCriteria.map((criterion) => this.criteriaService.getLabel(criterion)).join(", ");
    const fileTagObservables = getFiles(document).map((file) => this.criteriaService.getFileTags(document, file));
    return safeForkJoin(fileTagObservables, []).pipe(map((children) => [{ label, children }]));
  }

  private getTagsForDocumentSets(document: Document): TableTag[] {
    return isSingleDocumentSet(document.type)
      ? this.getTagsFromSingleSet(document)
      : this.getTagsFromMultipleSets(document);
  }

  private getTagsFromSingleSet(document: Document): TableTag[] {
    return getSets(document).map((set) => {
      return {
        label: getSetLabel(set, (key) => translate(key, null, this.processLanguage)),
        value: getSetFiles(set)[0],
      };
    });
  }

  private getTagsFromMultipleSets(document: Document): TableTag[] {
    return getSets(document)
      .map((set) => this.getTagForSet(set))
      .filter((tableTag) => tableTag.children.length > 0);
  }

  private getTagForSet(documentSet: DocumentSet): TableTag {
    const children = getSetFiles(documentSet).map((file) => this.getTagFromDocFormats(file));
    return { label: this.getLabelForSet(documentSet), children: children ?? [] };
  }

  private getTagFromDocFormats(file: DocumentFile): TableTag {
    const label = file.tags
      .map((tag) => this.staticDataDocFormats.find((staticDataEntry) => staticDataEntry.value == tag)?.label)
      .join(", ");

    return { label: label, value: file };
  }

  private getLabelForSet(documentSet: DocumentSet): string {
    let label = getSetLabel(documentSet, (key) => translate(key, null, this.processLanguage));
    const validUntil = documentSet.validUntil;
    const validFrom = documentSet.validFrom;

    const fromLabel = validFrom ? `${translate("from.title")} ${this.getLocalizeDate(validFrom)}` : "";
    const untilLabel = validUntil ? `${translate("until.title")} ${this.getLocalizeDate(validUntil)}` : "";

    const labels = validUntil || validFrom ? `${fromLabel} ${untilLabel}`.trim() : null;
    label += labels ? ` (${labels})` : "";
    return label;
  }

  private getLocalizeDate(date: Date): string {
    return this.dateFormatterService.transformDate(date, { dateStyle: "short" }, this.processLanguage);
  }

  private onTagClick(document: Document, file: DocumentFile): void {
    if (!this.tagClickDisabled) {
      this.tagClickDisabled = true;
      this.documentService.getDownloadUrl(file.blob, document.organizationId, document.candidateId).subscribe((url) => {
        if (isSupportedByDocumentPreview(file.name, file.type)) {
          this.selectedFile = { file: file, document: document, url: url };
          this.showSidebar = true;
        } else {
          this.downloadService.downloadFile(file.name, url);
        }
        this.tagClickDisabled = false;
      });
    }
  }

  private getFreeFormatTags(document: Document): TableTag[] {
    const children = getFreeFormatFiles(document).map((file) => ({ label: file.name, value: file }));
    return !isEmpty(children) ? [{ label: translate("freeFormatFiles.title"), children }] : [];
  }

  reload(organizationId: string, candidateId?: string, familyMemberId?: string): void {
    this.documentSubscription?.unsubscribe();
    this.documents = [];
    if (!this.isBlocked) {
      this.documentSubscription = this.documentService
        .getAll(
          this.mode,
          organizationId,
          candidateId,
          familyMemberId,
          this.selectedSharing,
          this.requiredDocumentSetIds,
          this.requiredDocumentsOrganizationId
        )
        .pipe(
          map((documents) => clone(documents)),
          switchMap((documents) => {
            const observables = documents.map((document) => this.metadataService.patchDocumentNames(document));
            return safeForkJoin(observables, []);
          })
        )
        .subscribe((documents) => (this.documents = documents));
    }
  }

  private updateColumns(candidateId: string, organizationId: string, mode: DocumentMode, language: string): void {
    this.loadStaticData(candidateId, organizationId).subscribe(() => {
      this.columns = [
        ...(mode === DocumentMode.Organization || mode === DocumentMode.Template
          ? [
              {
                header: translate("name.title", null, language),
                fieldname: nameOf("displayName"),
                sortable: true,
                includeInGlobalFilter: true,
              },
            ]
          : [
              {
                header: translate("file.type", null, language),
                fieldname: nameOf("type"),
                sortable: true,
                includeInGlobalFilter: true,
                pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.AllowedUploadFileTypes, language, {
                  organizationId,
                  candidateId,
                }),
                forceDisplayField: true,
                tags: (x: Document): Observable<TableTag[]> =>
                  isInternalDocument(x)
                    ? of([{ label: translate("internal.title"), styleClass: TagColor.Info }])
                    : of([]),
              },
            ]),
        {
          header: translate("changedBy.title", null, language),
          fieldname: nameOf("changedBy"),
          sortable: true,
          includeInGlobalFilter: true,
        },
        {
          header: translate("changedAt.title", null, language),
          fieldname: nameOf("changedAt"),
          sortable: true,
          includeInGlobalFilter: true,
          pipeDescription: new PipeDescription(AppDateTimePipe, { dateStyle: "short", timeStyle: "short" }, language),
        },
        {
          header: this.getAvailableFormatsLabel(mode, language),
          fieldname: nameOf("documentSets"),
          sortable: false,
          includeInGlobalFilter: false,
          fullWidthTags: isDocumentSelectionMode(mode),
          tags: (x: Document): Observable<TableTag[]> => this.getTags(x),
          tagClick: (x: Document, f: DocumentFile): void => this.onTagClick(x, f),
        },
        ...(this.settings.isCandidate || mode === DocumentMode.Organization || mode === DocumentMode.Template
          ? []
          : [
              {
                header: translate("freeFormatFiles.title"),
                fieldname: nameOf("freeFormatFiles"),
                sortable: true,
                includeInGlobalFilter: true,
                fullWidthTags: false,
                tags: (x: Document): Observable<TableTag[]> => of(this.getFreeFormatTags(x)),
                tagClick: (x: Document, f: DocumentFile): void => this.onTagClick(x, f),
              },
            ]),
        {
          header: translate("comment.title"),
          icon: (item: Document): string => (item.comment ? PrimeIcons.INFO_CIRCLE : null),
          fieldname: nameOf("comment"),
          sortable: true,
          includeInGlobalFilter: true,
          tooltip: (x: Document): string => x.comment,
        },
        ...(mode != DocumentMode.Template
          ? []
          : [
              {
                header: translate("type.title"),
                fieldname: nameOf("templateMode"),
                sortable: true,
                includeInGlobalFilter: true,
                pipeName: EnumPipe,
                pipeArgs: ["DocumentTemplateType", null, null, DocumentTemplateMode.Docx],
              } as TableColumn,
            ]),
      ];
      this.sortField = this.columns[0].fieldname;
    });
  }

  private updateOperations(mode: DocumentMode, language: string): void {
    if (this.readonly) {
      this.newOperations = null;
      this.captionOperations = null;
      return;
    }

    const knownVariableOperations: TableOperation[] = [
      {
        label: translate("knownVariables.title", null, language),
        icon: PrimeIcons.QUESTION_CIRCLE,
        operation: (): void => this.getKnownVariablesDocument(),
      },
    ];

    const createOperations: TableOperation[] = [
      {
        label: translate("common.new", null, language),
        icon: PrimeIcons.PLUS,
        operation: (): void => this.create(null, null),
      },
    ];
    const createTemplateOperations: TableOperation[] = [
      {
        label: translate("template.newDocx", null, language),
        icon: PrimeIcons.PLUS,
        operation: (): void => this.create(DocumentTemplateMode.Docx, null),
        mode: TableOperationMode.SplitButton,
        items: [
          {
            label: translate("template.newXlsx", null, language),
            icon: PrimeIcons.FILE_EXCEL,
            operation: (): void => this.create(DocumentTemplateMode.Xlsx, DocumentGenerationMode.OneCandidate),
          },
          {
            label: translate("template.newXlsxReport", null, language),
            icon: PrimeIcons.LIST,
            operation: (): void => this.create(DocumentTemplateMode.Xlsx, DocumentGenerationMode.AllCandidates),
          },
        ],
      },
    ];
    const lockAllOperations: TableOperation[] = [
      {
        label: translate("documents.lock", null, language),
        icon: PrimeIcons.LOCK,
        operation: (_, e: Event): void => this.lockAllDocuments(e),
        mode: TableOperationMode.SplitButton,
        items: [
          {
            label: translate("documents.unlock", null, language),
            icon: PrimeIcons.LOCK_OPEN,
            operation: (_, e: Event): void => this.unLockAllDocuments(e),
          },
        ],
      },
    ];
    const downloadOperations: TableOperation[] = [
      {
        mode: TableOperationMode.SplitButton,
        label: translate("common.download", null, language),
        icon: PrimeIcons.DOWNLOAD,
        operation: (): void => this.downloadZip(),
        canOperate: (): boolean => !this.isDownloadingZip,
        items: [
          {
            label: translate("documents.downloadAllAsPDF", null, language),
            icon: PrimeIcons.FILE_PDF,
            operation: (): void => this.downloadMergedPDF(),
          },
        ],
      },
    ];

    if (mode === DocumentMode.Organization) {
      this.newOperations = concat(...createOperations);
      this.captionOperations = null;
    } else if (mode === DocumentMode.Template) {
      this.newOperations = concat(...knownVariableOperations, ...createTemplateOperations);
      this.captionOperations = null;
    } else {
      this.newOperations = null;
      this.captionOperations = this.settings.isCandidate
        ? this.settings.readonlyProfile
          ? []
          : concat(...createOperations)
        : concat(...downloadOperations, ...createOperations, ...lockAllOperations);
    }
  }

  private updateTableOperations(language: string): void {
    this.tableOperations = [
      ...(!this.readonly
        ? [
            <TableOperation>{
              label: translate("common.edit", null, language),
              icon: PrimeIcons.PENCIL,
              operation: (x: Document): void => this.edit(x),
            },
          ]
        : []),
      ...[
        <TableOperation>{
          label: translate("document.download"),
          icon: PrimeIcons.DOWNLOAD,
          mode: TableOperationMode.Menu,
          operation: (_: unknown): void => noop(),
          canOperate: (_: unknown): boolean => this.mode === DocumentMode.Candidate,
          items: (document: Document): TableMenuItem[] => this.getDocumentSelectionForDownload(document, false),
          disabled: (document: Document): boolean =>
            isEmpty(getFiles(document)) && isEmpty(getFreeFormatFiles(document)),
        },
      ],
      ...(!this.settings.isCandidate
        ? [
            <TableOperation>{
              label: translate("document.convert"),
              icon: PrimeIcons.FILE_PDF,
              mode: TableOperationMode.Menu,
              operation: (_: unknown): void => noop(),
              canOperate: (_: unknown): boolean => this.mode === DocumentMode.Candidate,
              items: (document: Document): TableMenuItem[] => this.getDocumentSelectionForDownload(document, true),
              disabled: (document: Document): boolean =>
                isEmpty(getFiles(document)) && isEmpty(getFreeFormatFiles(document)),
            },
          ]
        : []),
      ...(!this.settings.isCandidate && !this.readonly
        ? [
            <TableOperation>{
              label: translate("document.toggleLock", null, language),
              icon: (x: Document): string => this.getLockIcon(x),
              operation: (x: Document): void => this.toggleLockDocument(x),
              disabled: (x: Document): boolean => this.cannotToggleLock(x),
              canOperate: (): boolean => this.mode !== DocumentMode.Template,
            },
          ]
        : []),
      ...(!this.readonly
        ? [
            <TableOperation>{
              label: translate("common.delete", null, language),
              icon: PrimeIcons.TRASH,
              operation: (x: Document, e: Event): void => this.delete(x, e),
              disabled: (x: Document): boolean => this.canNotDelete(x),
            },
          ]
        : []),
    ];
  }

  private updateTitle(mode: DocumentMode, language: string): void {
    this.title =
      mode === DocumentMode.Organization
        ? translate("documents.title", null, language)
        : mode === DocumentMode.Template
          ? translate("documentTemplates.title", null, language)
          : null;
  }

  private getTranslateKey(key: string): string {
    return `${this.mode === DocumentMode.Template ? "template" : "document"}.${key}`;
  }

  private getAvailableFormatsLabel(mode: DocumentMode, language: string): string {
    if (isDocumentSelectionMode(mode)) {
      return translate("availableVersions.title", null, language);
    } else {
      return translate("availableFormats.title", null, language);
    }
  }

  private getDocumentSelectionForDownload(document: Document, convert2PDF: boolean): TableMenuItem[] {
    if (this.mode != DocumentMode.Candidate || (isEmpty(getFiles(document)) && isEmpty(getFreeFormatFiles(document))))
      return [];

    const documentTags = this.getTagsForDocumentSets(document); //show the same stuff as within in the tags
    const freeFormatTags = this.getFreeFormatTags(document);
    if (isEmpty(documentTags) && isEmpty(freeFormatTags)) return [];

    return this.getDownloadMenuForMultipleSets(document, convert2PDF, [...documentTags, ...freeFormatTags]);
  }

  private getDownloadMenuForMultipleSets(document: Document, convert2PDF: boolean, tags: TableTag[]): TableMenuItem[] {
    const flatTags = tags.filter((tag) => !tag.children);
    const nestedTags = tags.filter((tag) => tag.children);

    const hasFlatTags = flatTags.length >= 1;
    const hasNestedTags = nestedTags.length >= 1;

    if (hasFlatTags && !hasNestedTags)
      return tags.map(({ label, value }) => ({
        label: label,
        operation: (_: Document): void => this.downloadFile(document, value, convert2PDF),
      }));

    if (hasFlatTags && hasNestedTags) {
      const menuItemsFromFlat = this.getDownloadMenuEntryForDocumentSet(
        document,
        this.transloco.translate("availableFormats.title"),
        this.getFlatFiles(flatTags),
        convert2PDF
      );

      const menuItemsByChildrenTags = nestedTags.map(({ label, children }) => {
        return this.getDownloadMenuEntryForDocumentSet(document, label, this.getFlatFiles(children), convert2PDF);
      });

      return [menuItemsFromFlat].concat(menuItemsByChildrenTags);
    }

    return tags.map(({ label, children, value }) => {
      if (children) {
        return this.getDownloadMenuEntryForDocumentSet(document, label, this.getFlatFiles(children), convert2PDF);
      }
      return {
        label: label,
        operation: (_: Document): void => this.downloadFile(document, value, convert2PDF),
      };
    });
  }

  private getFlatFiles(tags: TableTag[]): { label: string; file: DocumentFile }[] {
    return (tags ?? []).map((x) => ({ label: x.label, file: x.value }));
  }

  private getDownloadMenuEntryForDocumentSet(
    document: Document,
    setName: string,
    files: { label: string; file: DocumentFile }[],
    convert2PDF: boolean
  ): TableMenuItem {
    const createMenuItem = (docLabel: string, file: DocumentFile): MenuItem => {
      return {
        label: docLabel,
        command: (_: MenuItemCommandEvent): void => this.downloadFile(document, file, convert2PDF),
      };
    };

    return {
      label: setName,
      operation: (_: any): void => noop(),
      items: files.map(({ label, file }) => createMenuItem(label, file)),
    };
  }
}
