import { Directive, Input, OnInit, Optional } from "@angular/core";
import { Dropdown } from "primeng/dropdown";
import { EditableColumn, Table } from "primeng/table";
import { take } from "rxjs";

/**
 * PrimeNG's `pEditableColumn` can only focus HTML elements on cell click.
 * This teaches it to focus dropdowns automatically.
 */
@Directive({ selector: "p-dropdown" })
export class DropdownEditableColumnAutoFocusDirective implements OnInit {
  @Input()
  noAutoFocus: boolean;

  constructor(
    private readonly dropdown: Dropdown,
    @Optional() private readonly editableColumn: EditableColumn,
    @Optional() private readonly table: Table
  ) {}

  ngOnInit(): void {
    if (this.editableColumn && this.table && !this.noAutoFocus) {
      this.dropdown.show();
      this.dropdown.cd.detectChanges();
      this.dropdown.onHide
        .pipe(take(1))
        .subscribe((event) => this.editableColumn.closeEditingCell(true, <Event>(<unknown>event)));
    }
  }
}
