export interface MatchingAgreement {
  location: string;
  contractTemplate: string;
  interviewDate: Date;
  address: string;
  careFacility: string[];
  experienceField: string[];
  function: string;
  salary: number;
  vacationDays: number;
  workingHoursPerWeek: number;
  mandatoryOvertime: boolean;
  contractType: string;
  transferLanguageLevel: string;
  pathOfRecognition: string;
  recognitionReimbursement: boolean;
  travelReimbursement: boolean;
  regulatoryReimbursement: boolean;
  benefits: string[];
}
