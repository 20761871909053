import { Injectable } from "@angular/core";
import { ILanguageSkillModel, IVaccinationModel, getSets } from "@ankaadia/ankaadia-shared";
import {
  Candidate,
  Document,
  Housing,
  QualificationEvaluationsDe,
  ResidencePermit,
  StaticDataType,
  CertifiedEducationExam,
} from "@ankaadia/graphql";
import { TranslocoService } from "@ngneat/transloco";
import { isEmpty, some } from "lodash";
import { Observable, combineLatest, map, of, switchMap } from "rxjs";
import { StaticDataService } from "../../shared/static-data/static-data.service";
import {
  DocumentForeignKeyHandler,
  IDocumentForeignKeyHandler,
  AdditionalForeignKeySourceData,
} from "./candidate-document-foreign-key-handler";
import { CompleteCandidate, ForeignKeyData } from "./candidate-document-metadata.model";
import { EducationExamService } from "../education/education-exams/education-exam.service";
import { SettingsService } from "../../shared/services/settings.service";

@Injectable({ providedIn: "root" })
export class CandidateDocumentMetadataService {
  private readonly language = this.transloco.getActiveLang();

  private readonly foreignKeyHandlers: Record<string, IDocumentForeignKeyHandler> = {
    RESIDENCEPERMIT: new DocumentForeignKeyHandler({
      title: this.transloco.translate("residencePermit.title"),
      getEntities: { DE: (migration): ResidencePermit[] => migration?.residence?.residencePermits },
      getLabel: (permit, context): Observable<string> => {
        const value = permit.residencePermit;
        return this.staticData.getStaticDataLabel(value, StaticDataType.ResidencePermits, this.language, context);
      },
    }),
    FICTIONALCERT: new DocumentForeignKeyHandler({
      title: this.transloco.translate("residencePermit.title"),
      getEntities: { DE: (migration): ResidencePermit[] => migration?.residence?.residencePermits },
      getLabel: (permit, context): Observable<string> => {
        const value = permit.residencePermit;
        return this.staticData.getStaticDataLabel(value, StaticDataType.ResidencePermits, this.language, context);
      },
    }),
    RENTALAGREEMENT: new DocumentForeignKeyHandler({
      title: this.transloco.translate("housing.title"),
      getEntities: (candidate): Housing[] => candidate?.migration?.housingAcquisition?.housings,
      getLabel: (housing): string => housing.name,
    }),
    APPVALEDU: this.createQualificationEvaluationForeignKeyHandler(),
    CERTZAB: this.createQualificationEvaluationForeignKeyHandler(),
    CERTLAB: this.createQualificationEvaluationForeignKeyHandler(),
    REJECTCERTAB: this.createQualificationEvaluationForeignKeyHandler(),
    ADDREQCERTAB: this.createQualificationEvaluationForeignKeyHandler(),
    CERTOFVACC: new DocumentForeignKeyHandler({
      title: this.transloco.translate("vaccinations.title"),
      getEntities: (candidate): IVaccinationModel[] => candidate?.os?.profile?.vaccinations,
      getLabel: (vaccinations, context): Observable<string> => {
        const values = vaccinations.vaccinations ?? [];
        return this.staticData.getStaticData(StaticDataType.Vaccination, this.language, context).pipe(
          map((staticData) =>
            staticData.filter((vaccination) => values.includes(vaccination.value)).map((x) => x.label)
          ),
          map((labels) => (some(labels) ? labels.join(", ") : null))
        );
      },
    }),
    LANGCERT: this.createLanguageSkillForeignKeyHandler(),
    LANGCERTGERA1: this.createLanguageSkillForeignKeyHandler("DE", ["A1"]),
    LANGCERTGERA2: this.createLanguageSkillForeignKeyHandler("DE", ["A2"]),
    LANGCERTGERB1: this.createLanguageSkillForeignKeyHandler("DE", ["B1", "B1_NURSE"]),
    LANGCERTGERB2: this.createLanguageSkillForeignKeyHandler("DE", ["B2", "B2_NURSE", "B2_DOCTOR"]),
    LANGCERTGERC1: this.createLanguageSkillForeignKeyHandler("DE", ["C1", "C1_DOCTOR"]),
    LANGCERTGERC2: this.createLanguageSkillForeignKeyHandler("DE", ["C2"]),
  };

  constructor(
    private readonly transloco: TranslocoService,
    private readonly staticData: StaticDataService,
    private readonly educationExamService: EducationExamService,
    private readonly settings: SettingsService
  ) {}

  getTitle(document: Document): string | null {
    return this.foreignKeyHandlers[document.type]?.title ?? null;
  }

  getMetadata(
    documentType: string,
    candidate: Candidate,
    additionalData?: AdditionalForeignKeySourceData
  ): Observable<ForeignKeyData[]> {
    if (!documentType) {
      return of([]);
    }

    const handler = this.foreignKeyHandlers[documentType];
    if (!handler) {
      return of([]);
    }

    const additionForeignKeyData = this.getAdditionalForeignKeyData(candidate, documentType, additionalData);
    return combineLatest([of(candidate as CompleteCandidate), additionForeignKeyData]).pipe(
      switchMap(([candidate, additionalForeignKeyData]) =>
        candidate ? handler.getForeignData(candidate, additionalForeignKeyData) : of([])
      )
    );
  }

  patchDocumentNames(
    document: Document,
    candidate?: Candidate,
    additionalData?: AdditionalForeignKeySourceData
  ): Observable<Document> {
    if (!document) {
      return of(null);
    }

    return this.getMetadata(document.type, candidate, additionalData).pipe(
      map((data) => ({
        ...document,
        documentSets: getSets(document).map((set) => {
          const foreignKeyData = data.find(({ id }) => set.foreignKey === id);

          return foreignKeyData ? { ...set, name: `#${foreignKeyData.index + 1}: ${foreignKeyData.label}` } : set;
        }),
      }))
    );
  }

  private createQualificationEvaluationForeignKeyHandler(): DocumentForeignKeyHandler<QualificationEvaluationsDe> {
    return new DocumentForeignKeyHandler({
      title: this.transloco.translate("qualificationEvaluation.title"),
      getEntities: { DE: (migration) => migration?.qualificationEvaluation?.qualificationEvaluations },
      getLabel: (evaluation) => evaluation.evaluatedQualification,
    });
  }

  private createLanguageSkillForeignKeyHandler(
    language?: string,
    skillLevels?: string[]
  ): DocumentForeignKeyHandler<ILanguageSkillModel> {
    return new DocumentForeignKeyHandler({
      title: this.transloco.translate("languageSkills.title"),
      getEntities: (
        candidate,
        additionalData
      ): { id: string; language: string; skillLevel: string; institution: string }[] => {
        const languageSkills = (candidate?.os?.profile?.languageSkills ?? [])
          .filter(
            (x) =>
              (isEmpty(skillLevels) || skillLevels.includes(x.skillLevel)) && (!language || x.language === language)
          )
          .map((x) => ({ id: x.id, language: x.language, skillLevel: x.skillLevel, institution: x.examInstitution }));

        const examLanguageSkills = (additionalData?.certifiedExams ?? []).map((x) => ({
          id: x.examResultId,
          language: "DE",
          skillLevel: x.languageLevel,
          institution: x.examInstitution,
        }));

        return [...languageSkills, ...examLanguageSkills];
      },
      getLabel: (languageSkill, context): Observable<string> => {
        if (!languageSkill.language || !languageSkill.skillLevel) {
          return of(null);
        }

        const languageTranslated$ = this.staticData.getStaticDataLabel(
          languageSkill.language,
          StaticDataType.Languages,
          this.language,
          context
        );

        const skillLevelTranslated$ = this.staticData.getStaticDataLabel(
          languageSkill.skillLevel,
          StaticDataType.LanguageLevels,
          this.language,
          context
        );

        const institutionTranslated$ = this.staticData.getStaticDataLabel(
          languageSkill.institution,
          StaticDataType.ExamInstitutions,
          this.language,
          context
        );

        return combineLatest([languageTranslated$, skillLevelTranslated$, institutionTranslated$]).pipe(
          map((labels) => labels.join(" "))
        );
      },
    });
  }

  private getAdditionalForeignKeyData(
    candidate: Candidate,
    documentType: string,
    additionalData: AdditionalForeignKeySourceData
  ): Observable<AdditionalForeignKeySourceData> {
    return this.getAdditionalForeignKeyExamData(candidate, documentType, additionalData?.certifiedExams).pipe(
      map((certifiedExams) => ({ certifiedExams: certifiedExams }))
    );
  }

  private getAdditionalForeignKeyExamData(
    candidate: Candidate,
    documentType: string,
    certifiedExams?: CertifiedEducationExam[]
  ): Observable<CertifiedEducationExam[]> {
    const documentWithExamData = [
      "LANGCERT",
      "LANGCERTGERA1",
      "LANGCERTGERA2",
      "LANGCERTGERB1",
      "LANGCERTGERB2",
      "LANGCERTGERC1",
      "LANGCERTGERC2",
    ];

    if (!candidate || !documentWithExamData.includes(documentType)) return of([]);

    if (!isEmpty(certifiedExams)) {
      return of(certifiedExams);
    }

    return this.settings.isCandidate
      ? this.educationExamService.getCertified(this.settings.userOrCandidateId, this.settings.organizationId)
      : this.educationExamService.getCertified(candidate.id, candidate.organizationId);
  }
}
