<form [formGroup]="form" class="user-form" (ngSubmit)="onSubmit()" *appTranslate="let t">
  <p-card [header]="isEdit ? t('user.edit') : t('user.create')">
    <ng-template pTemplate="header"> </ng-template>
    <div class="p-fluid">
      <div class="field">
        <label for="title">{{ t("title.title") }}</label>
        <p-dropdown
          inputId="title"
          formControlName="title"
          [options]="titles | async"
          [showClear]="true"
          [placeholder]="t('title.placeholder')"
        ></p-dropdown>
      </div>
      <div class="field">
        <label for="salutation">{{ t("salutation.title") }}</label>
        <p-dropdown
          inputId="salutation"
          formControlName="salutation"
          [options]="salutations | async"
          [showClear]="true"
          [placeholder]="t('salutation.placeholder')"
        ></p-dropdown>
        <small class="p-error" *ngIf="form.controls.salutation.errors?.required">
          {{ t("salutation.required") }}
        </small>
      </div>
      <div class="field">
        <label for="firstname">{{ t("firstName.title") }}</label>
        <input type="text" pInputText id="firstname" formControlName="firstname" required />
        <small class="p-error" *ngIf="form.controls.firstname.errors?.required">{{ t("firstName.required") }}</small>
      </div>
      <div class="field">
        <label for="lastname">{{ t("lastName.title") }}</label>
        <input type="text" pInputText id="lastname" formControlName="lastname" required />
        <small class="p-error" *ngIf="form.controls.lastname.errors?.required">{{ t("lastName.required") }}</small>
      </div>
      <div class="field">
        <label for="email">{{ t("email.title") }}</label>
        <input
          type="email"
          pInputText
          [readonly]="isEdit"
          [class.p-disabled]="isEdit"
          id="email"
          formControlName="email"
          required
          email
        />
        <small class="p-error" *ngIf="form.controls.email.errors?.required">
          {{ t("email.required") }}
        </small>
        <small class="p-error" *ngIf="form.controls.email.errors?.pattern">
          {{ t("email.invalid") }}
        </small>
      </div>
      <div class="field">
        <label for="phoneNumber">{{ t("phone.title") }}</label>
        <input type="text" pInputText id="phoneNumber" formControlName="phoneNumber" maxlength="50" />
      </div>
      <div class="field">
        <label for="mobileNumber">{{ t("mobile.title") }}</label>
        <input type="text" pInputText id="mobileNumber" formControlName="mobileNumber" maxlength="50" />
      </div>
      <div class="field">
        <label for="position">{{ t("position.title") }}</label>
        <input type="text" pInputText id="position" formControlName="position" maxlength="50" />
      </div>
      <div class="field">
        <label for="signatureAddition">{{ t("signatureAddition.title") }}</label>
        <p-dropdown
          inputId="signatureAddition"
          formControlName="signatureAddition"
          [options]="signatureAddition$ | async"
          [showClear]="true"
          [placeholder]="t('signatureAddition.placeholder')"
        ></p-dropdown>
      </div>
      <div class="field">
        <label for="userRole">{{ t("userRole.title") }}</label>
        <p-multiSelect
          inputId="userRole"
          [options]="roleList"
          formControlName="userRoles"
          optionLabel="name"
          [placeholder]="t('userRole.placeholder')"
          [filter]="false"
          display="chip"
        ></p-multiSelect>
        <small class="p-error" *ngIf="form.controls.userRoles.errors?.required">{{ t("userRole.required") }}</small>
      </div>
      <div class="field">
        <label for="language">{{ t("userLanguage.title") }}</label>
        <p-dropdown
          inputId="language"
          formControlName="language"
          [options]="languages | async"
          optionLabel="label"
          optionValue="code"
          [placeholder]="t('userLanguage.placeholder')"
        ></p-dropdown>
        <small class="p-error" *ngIf="form.controls.language.errors?.required">
          {{ t("userLanguage.required") }}
        </small>
      </div>
      <div class="field mt-3">
        <app-messages-reception
          [messagesReception]="messagesReception"
          [showDetails]="isEdit ? true : false"
          [useDefault]="isEdit ? false : true"
          (toggle)="toggleMsgReception($event)"
        ></app-messages-reception>
      </div>
      <ng-container *ngIf="isEdit">
        <div class="field mt-3">
          <label for="consentDate">
            {{
              consentDate
                ? t("consentDate.accepted", {
                    date: consentDate | appDateTime: { dateStyle: "short", timeStyle: "medium" } : language,
                  })
                : t("consentDate.notAccepted")
            }}
          </label>
        </div>
        <div class="field mt-2">
          <label for="consentDate">
            {{ t("lastLogin.title") }}:
            <ng-container *ngIf="lastLoginDate$ | async as lastLoginDate; else unknownLoginDate">
              {{ lastLoginDate | appDateTime: { dateStyle: "short", timeStyle: "short" } }}
            </ng-container>
            <ng-template #unknownLoginDate>{{ t("Unknown") }}</ng-template>
          </label>
        </div>
      </ng-container>
    </div>

    <ng-template pTemplate="footer">
      <div class="flex flex-row justify-content-end">
        <p-button
          [disabled]="!form.valid || !form.dirty || disableSubmit"
          [label]="t('common.save')"
          icon="pi pi-check"
          type="submit"
          class="mr-2"
        ></p-button>
        <p-button
          closeOnEscape
          [label]="t('common.cancel')"
          icon="pi pi-times"
          class="p-button-secondary"
          type="reset"
          (onClick)="close()"
        ></p-button>
      </div>
    </ng-template>
  </p-card>
</form>
