import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { translate } from "@jsverse/transloco";
import { combineLatest, take } from "rxjs";
import { MessageDialogService } from "../../shared/message-dialog/message-dialog.service";
import { SettingsService } from "../../shared/services/settings.service";
import { SSOLoginService } from "./sso-login-service";

@Component({
  selector: "app-sso-link-account-after-sso-login",
  templateUrl: "./sso-linkaccount-after-sso-login.html",
  styleUrl: "./sso-linkaccount-after-sso-login.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SSOLinkAccountAfterSSOLoginComponent implements OnInit {
  protected inLinkingProcess = false;
  protected inRedirectingProcess = false;

  constructor(
    private readonly loginService: SSOLoginService,
    private readonly settings: SettingsService,
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly errorDialog: MessageDialogService
  ) {}

  ngOnInit(): void {
    this.inLinkingProcess = this.route.snapshot.queryParams.afterLogin == "true";
    if (this.route.snapshot.queryParams.ssoId && this.route.snapshot.queryParams.login) {
      this.inRedirectingProcess = true;
      const redirect_uri = new URL(`${origin}/sso-link-account-afterlogin`);
      redirect_uri.searchParams.append("afterLogin", "true");
      this.auth.loginWithRedirect({
        authorizationParams: {
          connection: "Username-Password-Authentication",
          redirect_uri: redirect_uri.toString(),
        },
        appState: {
          target: "/sso-link-account-afterlogin",
          ssoId: this.route.snapshot.queryParams.ssoId,
          linkAccount: true,
          redirectTo: this.route.snapshot.queryParams.redirectTo,
        },
      });
    }
    this.auth.handleRedirectCallback(window.location.href);
    combineLatest([this.auth.appState$, this.auth.user$])
      .pipe(take(1))
      .subscribe((stateAndUser) => {
        const appState = stateAndUser[0];
        const user = stateAndUser[1];
        if (user?.sub?.startsWith("auth0|") && appState.linkAccount && appState.ssoId) {
          this.loginService
            .linkAccountAfterLogin({ connection: "facebook", ssoId: appState.ssoId })
            .subscribe((result) => {
              if (result) {
                if (appState.redirectTo) {
                  window.location.href = appState.redirectTo;
                } else {
                  this.router.navigate(["/"]);
                }
              } else {
                this.handleError();
              }
            });
        }
      });
  }

  handleError(): void {
    this.errorDialog
      .showMessage(translate("errorOccured.title"), translate("connectWithFacebook.failure"))
      .subscribe(() => {
        this.settings.logout();
      });
  }

  linkAccount(): void {
    this.auth.user$.subscribe((user) => {
      const url = new URL(window.location.origin + "/sso-link-account-afterlogin");
      url.searchParams.append("ssoId", user?.sub ?? "");
      url.searchParams.append("login", "true");
      url.searchParams.append("redirectTo", this.route.snapshot.queryParams.redirectTo);
      this.auth.logout({ logoutParams: { returnTo: url.toString() } });
    });
  }
}
