<ng-container *transloco="let t; lang: language + '|static'">
  <form *ngIf="form" [formGroup]="form">
    <!-- Internes Dokument, das für den Kandidaten nicht sichtbar ist. Checkbox für Kandidaten nicht setzbar. -->
    <ng-container *ngIf="showInternalDocInternal">
      <div
        class="field-checkbox"
        [ngClass]="{
          'mb-3': isNil(form.controls.isInternalDocument.value),
          'mb-5': !isNil(form.controls.isInternalDocument.value),
        }"
      >
        <p-triStateCheckbox formControlName="isInternalDocument" inputId="isInternalDocument" />
        <label for="isInternalDocument">{{ t("isInternalDocument.title") }}</label>
        <i class="pi pi-info-circle ml-3" [pTooltip]="t('isInternalDocument.tooltip')"></i>
      </div>

      <p-message
        *ngIf="isNil(form.controls.isInternalDocument.value)"
        severity="warn"
        styleClass="mb-5"
        [text]="t('isInternalDocument.managedByRequirements')"
      />
    </ng-container>

    <div class="field" *ngIf="showDocumentCompletionInternal">
      <div>
        <label for="completionState">{{ t("completionState.title") }}</label>
        <i class="pi pi-info-circle ml-3" [pTooltip]="t('completionState.tooltip')"></i>
      </div>
      <p-dropdown
        inputId="completionState"
        [options]="completionStates"
        formControlName="completionState"
        [showClear]="true"
        [placeholder]="t('completionState.placeholder')"
        [styleClass]="isHighlighted('completionState') ? 'p-highlight-input' : null"
      />
      <small class="p-error" *ngIf="form.controls.completionState.errors?.required">
        {{ t("required") }}
      </small>
    </div>

    <div class="field">
      <div>{{ t("comment.title") }}</div>
      <textarea
        id="comment"
        pInputTextarea
        rows="3"
        formControlName="comment"
        maxlength="600"
        [readonly]="readonly"
        [class.p-highlight-input]="isHighlighted('comment')"
      ></textarea>
      <small class="p-error" *ngIf="form.controls.comment.errors?.required">
        {{ t("required") }}
      </small>
    </div>
  </form>
</ng-container>
