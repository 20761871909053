const sortedDegrees = ["PHD", "DIPLOMA", "MS", "BA", "VT", "IA", "HS", "GS", "SS", "PS"]; // Degrees sorted by importance

export function getHighestDegree(degrees: string[]): string {
  for (const degree of sortedDegrees) {
    const found = degrees?.find((y) => y == degree);
    if (found) {
      return found;
    }
  }
  return null;
}
