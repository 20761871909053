import { Injectable } from "@angular/core";
import { ANYTHING, DocumentSelectionCriterion } from "@ankaadia/ankaadia-shared";
import {
  Document,
  DocumentFile,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { isEmpty, memoize, uniqBy, without } from "lodash";
import { Observable, map, of, switchMap, tap } from "rxjs";
import { safeForkJoin } from "../../../shared/services/safe-fork-join";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { TableTag } from "../../../shared/table/table.model";
import { DiplomaticMissionService } from "../../diplomatic-missions/diplomatic-mission.service";
import { ImmigrationAuthorityService } from "../../immigration-authorities/immigration-authority.service";
import { OrganizationsService } from "../../organizations/organizations.service";
import { ProfessionService } from "../../profession/profession.service";
import { RecognitionAuthorityService } from "../../recognition-authorities/recognition-authorities.service";

class CriteriaValueSelection implements Record<DocumentSelectionCriterion, string[]> {
  Language: string[] = [];
  Profession: string[] = [];
  FederalState: string[] = [];
  ImmigrationCountry: string[] = [];
  ImmigrationAuthority: string[] = [];
  RecognitionAuthority: string[] = [];
  CountryOfOrigin: string[] = [];
  DiplomaticMission: string[] = [];
  Employer: string[] = [];
  EmployerAfterRecognition: string[] = [];
  Source: string[] = [];
  Function: string[] = [];
  QualificationMeasure: string[] = [];
}

type StaticDataObservable = Observable<StaticDataModel[]> | Observable<StaticDataModel[]>[];
type BloblessDocumentFile = Omit<DocumentFile, "blob">;

interface DocumentSelectionCriterionMeta {
  options: (criteria: CriteriaValueSelection) => StaticDataObservable;
  label: string;
  placeholder: string;
}

interface CountryMeta {
  country: string;
  states: string[];
}

export interface CriterionValueSelection {
  criterion: DocumentSelectionCriterion;
  values: string[];
}

@Injectable({ providedIn: "root" })
export class DocumentCriteriaService {
  private readonly language = this.transloco.getActiveLang();

  private readonly map: Record<DocumentSelectionCriterion, DocumentSelectionCriterionMeta> = {
    [DocumentSelectionCriterion.Language]: {
      options: () => this.staticDataService.getStaticData(StaticDataType.Languages, this.language),
      label: translate("language.title"),
      placeholder: translate("language.placeholder"),
    },
    [DocumentSelectionCriterion.Profession]: {
      options: () => this.professionService.getProfessions(null, null, this.language, this.settings.organizationId),
      label: translate("occupation.title"),
      placeholder: translate("occupation.placeholder"),
    },
    [DocumentSelectionCriterion.FederalState]: {
      options: memoize((criteria) => this.getFederalStates(criteria.ImmigrationCountry)),
      label: translate("federalState.title"),
      placeholder: translate("federalState.placeholder"),
    },
    [DocumentSelectionCriterion.ImmigrationCountry]: {
      options: () => this.staticDataService.getStaticData(StaticDataType.SupportedImmigrationCountries, this.language),
      label: translate("immigrationCountry.title"),
      placeholder: translate("immigrationCountry.placeholder"),
    },
    [DocumentSelectionCriterion.ImmigrationAuthority]: {
      options: memoize((criteria) =>
        this.getStaticDataPerCountry(criteria, ({ country, states }) =>
          this.immigrationAuthorityService.getImmigrationAuthorities(
            this.settings.organizationId,
            country as SupportedImmigrationCountry,
            states
          )
        )
      ),
      label: translate("immigrationAuthority.title"),
      placeholder: translate("immigrationAuthority.placeholder"),
    },
    [DocumentSelectionCriterion.RecognitionAuthority]: {
      options: memoize((criteria) =>
        this.getStaticDataPerCountry(criteria, ({ country, states }) =>
          this.recognitionAuthorityService.getRecognitionAuthorities(this.settings.organizationId, country, states)
        )
      ),
      label: translate("recogNoticeAuthority.title"),
      placeholder: translate("recogNoticeAuthority.placeholder"),
    },
    [DocumentSelectionCriterion.CountryOfOrigin]: {
      options: () => this.staticDataService.getStaticData(StaticDataType.Countries, this.language),
      label: translate("countryOfOrigin.title"),
      placeholder: translate("country.placeholder"),
    },
    [DocumentSelectionCriterion.DiplomaticMission]: {
      options: memoize((criteria) =>
        criteria.ImmigrationCountry.map((country) =>
          this.diplomaticMissionService.getManyDiplomaticMissionsAbroad(
            country as SupportedImmigrationCountry,
            criteria.CountryOfOrigin
          )
        )
      ),
      label: translate("diplomaticMission.title"),
      placeholder: translate("diplomaticMission.placeholder"),
    },
    [DocumentSelectionCriterion.Employer]: {
      options: memoize(() =>
        this.organizationService
          .getLinkedOrganizations(this.settings.organizationId, true)
          .pipe(map((orgs) => orgs.map((org) => ({ label: org.name, value: org.id }))))
      ),
      label: translate("employer.title"),
      placeholder: translate("employer.placeholder"),
    },
    [DocumentSelectionCriterion.Source]: {
      options: memoize(() =>
        this.organizationService
          .getLinkedOrganizations(this.settings.organizationId, true)
          .pipe(map((orgs) => orgs.map((org) => ({ label: org.name, value: org.id }))))
      ),
      label: translate("partner.source"),
      placeholder: translate("partner.sourcePlaceholder"),
    },
    [DocumentSelectionCriterion.EmployerAfterRecognition]: {
      options: memoize(() =>
        this.organizationService
          .getLinkedOrganizations(this.settings.organizationId, true)
          .pipe(map((orgs) => orgs.map((org) => ({ label: org.name, value: org.id }))))
      ),
      label: translate("employer.titleAfterRecognition"),
      placeholder: translate("employer.placeholderAfterRecognition"),
    },
    [DocumentSelectionCriterion.Function]: {
      options: memoize(() => this.staticDataService.getStaticData(StaticDataType.Functions, this.language)),
      label: translate("function.title"),
      placeholder: translate("function.placeholder"),
    },
    [DocumentSelectionCriterion.QualificationMeasure]: {
      options: memoize(() => this.staticDataService.getStaticData(StaticDataType.QualificationMeasures, this.language)),
      label: translate("qualificationMeasure.title"),
      placeholder: translate("qualificationMeasure.placeholder"),
    },
  };

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly recognitionAuthorityService: RecognitionAuthorityService,
    private readonly immigrationAuthorityService: ImmigrationAuthorityService,
    private readonly diplomaticMissionService: DiplomaticMissionService,
    private readonly professionService: ProfessionService,
    private readonly organizationService: OrganizationsService,
    private readonly settings: SettingsService
  ) {}

  getAllCriteria(): StaticDataModel[] {
    return Object.entries(this.map).map(([value, { label }]) => ({ value, label }));
  }

  getLabel(criterion: DocumentSelectionCriterion): string {
    return this.map[criterion].label;
  }

  getPlaceholder(criterion: DocumentSelectionCriterion): string {
    return this.map[criterion].placeholder;
  }

  getOptions(
    criterion: DocumentSelectionCriterion,
    parameters: CriterionValueSelection[]
  ): Observable<StaticDataModel[]> {
    const dictionary = this.buildCriteriaValueSelection(parameters);
    const staticDataObservables = this.map[criterion].options(dictionary);

    const combinedObservable = Array.isArray(staticDataObservables) ? staticDataObservables : [staticDataObservables];

    return safeForkJoin(combinedObservable, []).pipe(
      map((staticData) => staticData.flat()),
      map((staticData) => uniqBy(staticData, (entry) => entry.value)),
      map((staticData) => [{ label: ANYTHING, value: ANYTHING }, ...staticData])
    );
  }

  getFileTags(document: Document, file: BloblessDocumentFile): Observable<TableTag> {
    const criteriaValues = this.extractValuesAttachedToCriteria(document, file);

    const tagObservables = criteriaValues.map((kv) => {
      return this.getOptions(kv.criterion, criteriaValues).pipe(
        map(
          (options) =>
            `(${this.extractLabels(kv.values, options)
              .filter((x) => !isEmpty(x))
              .join(", ")})`
        )
      );
    });

    return safeForkJoin(tagObservables, []).pipe(
      map((labels) => labels.join(", ")),
      map((label) => ({ value: file, label: label }))
    );
  }

  private buildCriteriaValueSelection(parameters: CriterionValueSelection[]): CriteriaValueSelection {
    return parameters.reduce((object, selection) => {
      object[selection.criterion] = selection.values?.filter((value) => value !== ANYTHING) ?? [];
      return object;
    }, new CriteriaValueSelection());
  }

  private extractValuesAttachedToCriteria(document: Document, file: BloblessDocumentFile): CriterionValueSelection[] {
    return document.selectionCriteria.map((criterion, index) => ({ criterion, values: file.selectionValues[index] }));
  }

  private extractLabels(values: string[], options: StaticDataModel[]): string[] {
    return values.map((value) =>
      value === ANYTHING ? value : options?.find((staticDataEntry) => staticDataEntry.value == value)?.label
    );
  }

  private getStaticDataPerCountry(
    criteria: CriteriaValueSelection,
    getStaticData: (country: CountryMeta) => Observable<StaticDataModel[]>
  ): Observable<StaticDataModel[]> {
    return of(criteria.ImmigrationCountry).pipe(
      map((countryNames) => countryNames.map((name) => this.buildCountryMetaData(name, criteria))),
      switchMap((countryObservables) => safeForkJoin(countryObservables, [])),
      tap((countries) => this.cleanupFederalStates(criteria, countries)),
      map((countries) => countries.map((country) => getStaticData(country))),
      switchMap((staticDataSetObservables) => safeForkJoin(staticDataSetObservables, [])),
      map((staticDataSets) => uniqBy(staticDataSets.flat(), (entry) => entry.value))
    );
  }

  private cleanupFederalStates(criteria: CriteriaValueSelection, countries: CountryMeta[]): void {
    const foundStates = countries.map((country) => country.states).flat();
    const missingStateCriteria = criteria.FederalState.filter((state) => !foundStates.includes(state));
    if (missingStateCriteria.length) {
      criteria.FederalState = without(criteria.FederalState, ...missingStateCriteria);
    }
  }

  private buildCountryMetaData(country: string, criteria: CriteriaValueSelection): Observable<CountryMeta> {
    return this.getFederalStates([country]).pipe(
      map((staticData) => ({
        country,
        states: staticData
          .map((entry) => entry.value)
          .filter((federalState) => criteria.FederalState.includes(federalState)),
      }))
    );
  }

  private getFederalStates(countries: string[]): Observable<StaticDataModel[]> {
    return this.staticDataService.getStaticData(
      StaticDataType.FederalStates,
      this.language,
      countries.map((country) => ({
        immigrationCountry: country,
        organizationId: this.settings.organizationId,
      }))
    );
  }
}
