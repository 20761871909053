import { Injectable } from "@angular/core";
import { CandidateForViewFragment, StaticDataType } from "@ankaadia/graphql";
import {
  ICandidatePathOfRecognition,
  ICandidateQualificationMeasure,
  ICountrySpecificRequiredDocumentsFields,
  MigrationSpecifics,
} from "../migration-specifics.model";

@Injectable({ providedIn: "root" })
export class GermanySpecifics implements MigrationSpecifics {
  getMigrationCountrySpecificRequiredDocumentsFields(): ICountrySpecificRequiredDocumentsFields[] {
    return [
      {
        labelKey: "recognitionType.title",
        name: "recognitionType",
        staticDataType: StaticDataType.RecognitionType,
      },
      {
        labelKey: "equivalenceTest.title",
        name: "equivalenceTest",
        staticDataType: StaticDataType.EquivalenceTest,
      },
      {
        labelKey: "residencePermit.title",
        name: "residencePermit",
        staticDataType: StaticDataType.ResidencePermits,
      },
    ];
  }

  getRecognitionPath(candidate: CandidateForViewFragment): ICandidatePathOfRecognition {
    return candidate?.migration?.de?.recognitionPath;
  }

  getQualificationMeasure(candidate: CandidateForViewFragment): ICandidateQualificationMeasure {
    const qualificationMeasure = candidate?.migration?.de?.qualificationMeasure;
    if (!qualificationMeasure) return null;
    return {
      theoryHours: qualificationMeasure?.theoryHours,
      practiceHours: qualificationMeasure?.practiceHours,
      qualificationMeasure: qualificationMeasure?.qualificationMeasure,
      careSituations: qualificationMeasure?.careSituations,
    };
  }
}
