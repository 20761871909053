import { Directive, Input, OnInit } from "@angular/core";
import { RadioButton } from "primeng/radiobutton";
import { AnyFunction } from "ts-essentials";

/* Because p-radioButton does not have read-only mode. */
@Directive({ selector: "p-radioButton[readonly]" })
export class RadioButtonReadonlyDirective implements OnInit {
  private static readonly methodSelector = <T extends keyof RadioButton>(xs: T[]): readonly T[] => xs;
  // those are methods used in the component's template
  // hopefully, user interactions will be rendered pointless as soon as we intercept these
  private static readonly methods = RadioButtonReadonlyDirective.methodSelector([
    "onInputFocus",
    "onInputBlur",
    "handleClick",
    "select",
  ]);

  private readonly sources: Record<keyof typeof RadioButtonReadonlyDirective.methods, AnyFunction> = <any>{};

  @Input()
  readonly: boolean;

  constructor(private readonly radioButton: RadioButton) {}

  ngOnInit(): void {
    for (const method of RadioButtonReadonlyDirective.methods) {
      this.sources[method] = this.radioButton[method];
      this.radioButton[method] = (...args: any[]): any => {
        if (this.readonly) {
          args?.filter((x) => x instanceof Event).forEach((x) => x.preventDefault());
        } else {
          return this.sources[method].call(this.radioButton, ...args);
        }
      };
    }
  }
}
