<ng-container *appTranslate="let t">
  <p-dropdown
    [(ngModel)]="preferredCommunicationLanguage"
    [options]="availableLanguages$ | async"
    [placeholder]="t('userLanguage.placeholder')"
    (onClick)="onClick($event)"
    (onChange)="setCommunicationLanguage()"
    styleClass="compact minimalistic"
  >
    <ng-template pTemplate="selectedItem">
      <div class="flex align-items-center gap-2" *ngIf="preferredCommunicationLanguage">
        {{ label ?? t("userLanguage.title") }}
        <span class="flag flag-small flag-{{ preferredCommunicationLanguage.flag }}"></span>
      </div>
    </ng-template>

    <ng-template let-language pTemplate="item">
      <div class="flex align-items-center gap-2">
        <span class="flag flag-{{ language.flag }}"></span>
        <span class="ml-2">{{ language.label }}</span>
      </div>
    </ng-template>
  </p-dropdown>
</ng-container>
