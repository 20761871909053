import { singleOrDefault } from "./lodash-extra";
import { DocumentAccess } from "./model/document.model";

const orderedAccessLevels = [
  DocumentAccess.None,
  DocumentAccess.ReadDocumentWhenNotInternal,
  DocumentAccess.ReadDocument,
  DocumentAccess.ReadWriteMetadata,
  DocumentAccess.ReadDocumentWhenNotInternalAndReadWriteMetadata,
  DocumentAccess.ReadDocumentAndReadWriteMetadata,
  DocumentAccess.ReadWriteDocumentWhenNotInternal,
  DocumentAccess.ReadWriteDocument,
] as const;

interface ComposedAccessLevelInstruction {
  composedLevel: DocumentAccess;
  dependencies: [DocumentAccess, DocumentAccess];
}

const composedAccessLevelInstructions: ComposedAccessLevelInstruction[] = [
  {
    composedLevel: DocumentAccess.ReadDocumentAndReadWriteMetadata,
    dependencies: [DocumentAccess.ReadDocument, DocumentAccess.ReadWriteMetadata],
  },
  {
    composedLevel: DocumentAccess.ReadDocumentWhenNotInternalAndReadWriteMetadata,
    dependencies: [DocumentAccess.ReadDocumentWhenNotInternal, DocumentAccess.ReadWriteMetadata],
  },
];

export function determineHighestAccessLevel(previous: DocumentAccess, current: DocumentAccess): DocumentAccess {
  if (current === previous) {
    return current;
  }

  const composedAccessLevels = composedAccessLevelInstructions
    .filter(({ dependencies }) => dependencies.includes(current) && dependencies.includes(previous))
    .map(({ composedLevel }) => composedLevel);

  const tested: DocumentAccess = singleOrDefault(composedAccessLevels) ?? current;
  return orderedAccessLevels[Math.max(orderedAccessLevels.indexOf(previous), orderedAccessLevels.indexOf(tested))];
}
