<ng-container *appTranslate="let t">
  <div class="p-fluid">
    <p-message [text]="t('translationProposal.gatheringComponent.translationHint')"></p-message>
    <p-fieldset [legend]="t('translationProposal.gatheringComponent.existingTranslation')">
      <strong>{{ textToCorrect }}</strong>
    </p-fieldset>
    <div class="mt-4">
      <label for="textToCorrect">{{ t("translationProposal.gatheringComponent.proposedTranslation") }}</label>
      <input id="textToCorrect" pInputText type="text" [(ngModel)]="textCorrected" />
    </div>
    <div class="mt-4">
      <label for="reasonForCorrection">{{ t("translationProposal.gatheringComponent.reasonForProposal") }}</label>
      <input id="reasonForCorrection" pInputText type="text" [(ngModel)]="reasonForCorrection" />
    </div>
  </div>
  <div class="flex justify-content-end mt-4">
    <p-button
      [label]="t('common.ok')"
      type="submit"
      icon="pi pi-check"
      class="mr-2"
      [disabled]="textCorrected == '' || textCorrected == null || textCorrected == textToCorrect"
      (onClick)="submit($event)"
    ></p-button>
    <p-button [label]="t('common.cancel')" type="cancel" icon="pi pi-times" (onClick)="cancel($event)"></p-button>
  </div>
</ng-container>
