import { Injectable } from "@angular/core";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { concatMap, from, map, mergeMap, Observable, tap } from "rxjs";
import { AddCandidateDeletionRequestGQL, ResetPasswordGQL } from "@ankaadia/graphql";
import { environment } from "../../../environments/environment";
import { TranslocoService } from "@ngneat/transloco";
import { SSOLoginService } from "../../pages/login/sso-login-service";
import { SettingsService } from "./settings.service";
import { loadFacebookSDK, loginAtFacebook } from "../facebook/facebook-sdk";

@Injectable({ providedIn: "root" })
export class MyAccountService {
  constructor(
    private readonly passwordReset: ResetPasswordGQL,
    private readonly trans: TranslocoService,
    private readonly ssoLoginService: SSOLoginService,
    private readonly settings: SettingsService,
    private readonly candidateDeletionRequest: AddCandidateDeletionRequestGQL
  ) {}

  resetPassword(): Observable<boolean> {
    return this.passwordReset.mutate().pipe(map((x) => x.data.resetPassword.status));
  }

  linkLoggedInAccountWithFacebook(): Observable<boolean> {
    // we need a new instance here in order to not use the same auth0 client because we need to be logged in twice
    const auth0Client = new Auth0Client({ domain: environment.auth0_domain, clientId: environment.auth0_clientId });
    return from(
      auth0Client.loginWithPopup({
        authorizationParams: {
          connection: "facebook",
          scope: "public_profile,user_messenger_contact",
          display: "popup",
          prompt: "login",
          max_age: 0,
          ui_locales: this.trans.getActiveLang(),
          messenger_page_id: environment.facebookPageId,
        },
      })
    ).pipe(
      concatMap(() => from(auth0Client.getIdTokenClaims())),
      concatMap((token) => {
        return this.ssoLoginService
          .linkAccountAfterLogin({ connection: "facebook", ssoId: token.sub })
          .pipe(tap((x) => (x ? this.settings.reload(false).subscribe() : null)));
      })
    );
  }

  unLinkLoggedInAccountWithFacebook(): Observable<boolean> {
    return loadFacebookSDK().pipe(
      map(() => loginAtFacebook(null, { reset_messenger_state: true })),
      mergeMap(() =>
        this.ssoLoginService.unlinkAccount(this.settings.facebookUserId).pipe(
          tap(
            () =>
              setTimeout(() => {
                this.settings.logout();
              }, 4000) // We need to wait a bit because the login process at facebook is not finished yet
          )
        )
      )
    );
  }

  loginWithFacebookNative(): void {
    loadFacebookSDK().subscribe(() => {
      loginAtFacebook(
        "user_messenger_contact",
        { reset_messenger_state: 1, messenger_page_id: environment.facebookPageId },
        true,
        "reauthenticate"
      ).subscribe(() => {
        /*noop*/
      });
    });
  }

  deleteCandidateData(): Observable<boolean> {
    return this.candidateDeletionRequest
      .mutate({ candidateId: this.settings.userOrCandidateId, orgId: this.settings.organizationId })
      .pipe(map((x) => (x.data.addCandidateDeletionRequest ? true : false)));
  }
}
