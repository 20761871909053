<div class="exception-body accessdenied-body" *transloco="let t">
  <div class="exception-logo">
    <a class="logo" [routerLink]="['/app']">
      <img class="logo-img" src="assets/layout/images/logo.png" />
      <img class="logo-txt" src="assets/layout/images/logo-text.svg" />
    </a>
  </div>

  <div class="exception-panel">
    <img src="assets/layout/images/exception/icon-access.png" />

    <p-button *ngIf="!(loggedIn | async)" [label]="t('login.title')" [routerLink]="['/login']"></p-button>
    <p-button *ngIf="loggedIn | async" [label]="t('home.title')" [routerLink]="['/']"></p-button>
  </div>

  <div class="exception-band">
    <div class="exception-content">
      <h3>{{ t("accessDenied.title") }}</h3>
      <p *ngIf="loggedIn | async">{{ t("accessDenied.noPermissions") }}</p>
      <p *ngIf="!(loggedIn | async)">{{ t("accessDenied.notLoggedIn") }}</p>
    </div>
  </div>
</div>
