import { AfterViewInit, DestroyRef, Directive, Input } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TabView } from "primeng/tabview";
import { tap } from "rxjs";
import { SettingsService } from "../../services/settings.service";

@Directive({ selector: "p-tabView[rememberTab]" })
export class TabViewRememberTabDirective implements AfterViewInit {
  constructor(
    private readonly tabView: TabView,
    private readonly settingsService: SettingsService,
    private readonly destroyRef: DestroyRef
  ) {}

  @Input({ required: true })
  stateKey: string;

  ngAfterViewInit(): void {
    const rememberedTab = this.getRememberedTab();
    if (rememberedTab) {
      this.tabView.open(null, this.tabView.tabs[rememberedTab]);
    }
    this.tabView.activeIndexChange
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((index) => this.saveState(index))
      )
      .subscribe();
  }

  private saveState(index: number): void {
    const tabName = this.tabView?.tabs[index]?.header;
    if (tabName) localStorage.setItem(this.getKey(), tabName);
  }

  getRememberedTab(): number | null {
    const rememberedTab = localStorage.getItem(this.getKey());
    if (rememberedTab) {
      return this.tabView.tabs
        .map((t, i) => ({ header: t.header, index: i }))
        .find((t, _) => t.header === rememberedTab)?.index;
    }
    return null;
  }

  private getKey(): string {
    return `${this.settingsService.userOrCandidateId}_${this.stateKey}`;
  }
}
