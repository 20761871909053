import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { UpdateDialogComponent } from "../update-dialog/update-dialog.component";
import { translate, TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-ankaadia-updates",
  templateUrl: "./ankaadia-updates.component.html",
})
export class AnkaadiaUpdatesComponent implements OnChanges {
  readonly language = this.transloco.getActiveLang();

  @Input()
  updates: any[];

  @Output() readonly popUpSeen = new EventEmitter<boolean>();

  constructor(
    private readonly transloco: TranslocoService,
    private readonly dialogService: DialogService
  ) {}

  ngOnChanges(): void {
    const unseenVersions = this.updates?.filter((v) => !v.isSeen) || [];

    if (unseenVersions.length) {
      this.showUpdateDialog(unseenVersions);
    }
  }

  showUpdateDialog(updates?: any[]): void {
    const data = updates ?? this.updates;
    this.dialogService
      .open(UpdateDialogComponent, {
        data,
        header: translate("dashboard.ankaadiaUpdates"),
      })
      .onClose.subscribe((_) => this.popUpSeen.emit(true));
  }
}
