import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { MessageErrorComponent } from "./message-error/message-error.component";
import { MessageSuccessComponent } from "./message-success/message-success.component";

@NgModule({
  imports: [SharedModule],
  declarations: [MessageSuccessComponent, MessageErrorComponent],
  exports: [MessageSuccessComponent, MessageErrorComponent],
})
export class MessageModule {}
