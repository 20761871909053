import { AbstractControl } from "@angular/forms";
import { EmploymentTerms, MatchingAgreement, nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  ContractTemplateFragment,
  EmployerFragment,
  EmploymentRelationshipType,
  OrganizationFragment,
  ProcessTask,
  RemunerationCalculationBasis,
  StaticDataType,
} from "@ankaadia/graphql";
import { TranslateParams, translate as transloco } from "@jsverse/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { Observable, tap } from "rxjs";
import { ContractTemplateService } from "../../../features/organizations/contract-template/contract-template.service";
import { DateFormatterService } from "../../../shared/services/date-formatter.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { OrganizationSpecific } from "../../organization-specific";

export interface MatchingAgreementContext {
  task: ProcessTask;
  employer: OrganizationFragment;
  employerProfile: EmployerFragment;
  organizationSpecifics: OrganizationSpecific;
  contractTemplates: ContractTemplateFragment[];
  contractTemplateService: ContractTemplateService;
  formatter: DateFormatterService;
}

export function matchingAgreementFormFactory(
  context: MatchingAgreementContext | null,
  language: string,
  staticDataService: StaticDataService
): FormlyFieldConfig[] {
  const nameOf = nameofFactory<MatchingAgreement>();
  const nameOfEmploymentTerms = nameofFactory<EmploymentTerms>();
  const translate = <T>(key: TranslateParams, params?: Record<string, any>): T => transloco<T>(key, params, language);

  const getEmploymentTermsFormlyFields = (): FormlyFieldConfig[] => {
    return [
      {
        className: "col-12 lg:col-6",
        type: "dropdown",
        key: nameOfEmploymentTerms("function"),
        props: {
          label: translate("function.title"),
          placeholder: translate("function.placeholder"),
          filter: true,
          filterBy: "label",
          required: true,
          hideRequiredMarker: true,
          options: [
            { label: translate("function.specialist"), value: "SPECIALIST" },
            { label: translate("function.assistant"), value: "ASSISTANT" },
          ],
          blocklyType: "field_staticdata_functions",
        },
      },
      {
        key: nameOfEmploymentTerms("contractTemplate"),
        type: "dropdown",
        className: "col-12 lg:col-6",
        props: {
          label: translate("contractTemplate.matching.label"),
          filter: true,
          filterBy: "label",
          placeholder:
            (context?.contractTemplates ?? []).length > 0 ? translate("contractTemplate.matching.placeholder") : "",
          options: context?.contractTemplates?.map((x) => ({ label: x.name, value: x })) ?? [],
          required: (context?.contractTemplates ?? []).length > 0,
          dataKey: "name",
          hideRequiredMarker: true,
        },
        hooks: {
          onInit: ({ form }): any => {
            const controls = <Record<keyof EmploymentTerms, AbstractControl>>form.controls;
            return controls.contractTemplate.valueChanges.pipe(
              tap((contractTemplateSelected: MatchingAgreementContext["contractTemplates"][0]) => {
                if (contractTemplateSelected) {
                  context?.contractTemplateService
                    .get(contractTemplateSelected.id, contractTemplateSelected.organizationId)
                    .subscribe((contractTemplate) => {
                      controls.contractType.setValue(
                        contractTemplate.employmentRelationshipType === EmploymentRelationshipType.Temporary
                          ? "FIXEDTERM"
                          : "PERMANENT"
                      );
                      controls.workingHoursPerWeek.setValue(contractTemplate.workingHoursPerWeek);
                      controls.vacationDays.setValue(contractTemplate.holidayEntitlement);
                      if (contractTemplate.remunerationCalculationBasis === RemunerationCalculationBasis.PerMonth) {
                        controls.salary.setValue(contractTemplate.paymentPerMonth);
                      }
                      controls.mandatoryOvertime.setValue(contractTemplate.overtimeHours > 0);
                    });
                  // #2159 is postponed after refactoring
                  // controls.benefits.setValue(contractTemplate.otherPecuniaryBenefits);
                }
              })
            );
          },
        },
      },
      {
        className: "col-12 lg:col-4",
        type: "input",
        key: nameOfEmploymentTerms("salary"),
        props: {
          label: translate("salary.title"),
          type: "number",
          required: true,
          hideRequiredMarker: true,
          min: 0,
          blocklyType: "Number",
        },
      },
      {
        className: "col-12 lg:col-4",
        type: "input",
        key: nameOfEmploymentTerms("vacationDays"),
        props: {
          label: translate("vacationDays.title"),
          type: "number",
          required: true,
          hideRequiredMarker: true,
          min: 0,
          blocklyType: "Number",
        },
      },
      {
        type: "input",
        className: "col-12 lg:col-4",
        key: nameOfEmploymentTerms("workingHoursPerWeek"),
        props: {
          label: translate("workingHoursPerWeek.title"),
          type: "number",
          maxFractionDigits: 2,
          minFractionDigits: 0,
          required: true,
          hideRequiredMarker: true,
          min: 0,
          blocklyType: "Number",
        },
      },
      {
        className: "col-12 lg:col-6",
        type: "dropdown",
        key: nameOfEmploymentTerms("mandatoryOvertime"),
        props: {
          label: translate("mandatoryOvertime.title"),
          placeholder: translate("mandatoryOvertime.placeholder"),
          filter: true,
          required: false,
          filterBy: "label",
          hideRequiredMarker: true,
          options: [
            { label: translate("mandatoryOvertime.yes"), value: true },
            { label: translate("mandatoryOvertime.no"), value: false },
          ],
          blocklyType: "Boolean",
        },
      },
      {
        className: "col-12 lg:col-6",
        type: "dropdown",
        key: nameOfEmploymentTerms("contractType"),
        props: {
          label: translate("contract.type"),
          placeholder: translate("contract.placeholder"),
          filter: true,
          filterBy: "label",
          required: true,
          hideRequiredMarker: true,
          options: [
            { label: translate("contract.fixedTerm"), value: "FIXEDTERM" },
            { label: translate("contract.permanent"), value: "PERMANENT" },
          ],
        },
      },
    ];
  };

  return [
    {
      fieldGroupClassName: "grid",
      fieldGroup: [
        {
          className: "col-6",
          fieldGroup: [
            {
              wrappers: ["fieldset"],
              props: {
                label: translate("candidate.title"),
                styleClass: "h-full",
              },
              fieldGroup: [
                { template: `<p>${context?.task.candidateDisplayName}</p>` },
                { template: `<p>${context?.task.candidateDisplayId}</p>` },
              ],
            },
          ],
        },
        {
          className: "col-6",
          fieldGroup: [
            {
              wrappers: ["fieldset"],
              props: {
                label: translate("employer.title"),
                styleClass: "h-full",
              },
              fieldGroup: [
                { template: `<p>${context?.employer.name} (${context?.employer.code})  </p>` },
                {
                  key: nameOf("location"),
                  type: "dropdown",
                  props: {
                    styleClass: "p-mb-0",
                    filter: true,
                    filterBy: "label",
                    placeholder: translate("location.placeholder"),
                    options: context?.employerProfile?.locations?.map((x) => ({ label: x.name, value: x })) ?? [],
                    required:
                      (context?.employerProfile?.locations?.map((x) => ({ label: x.name, value: x })) ?? []).length > 0,
                    dataKey: "name",
                    hideRequiredMarker: true,
                  },
                  hooks: {
                    onInit: ({ form }): Observable<any> => {
                      const controls = <Record<keyof MatchingAgreement, AbstractControl>>form.controls;
                      return controls.location.valueChanges.pipe(
                        tap((location: MatchingAgreementContext["employerProfile"]["locations"][0]) => {
                          if (location) {
                            controls.address.setValue(location.address);
                            controls.careFacility.setValue(location.careFacility);
                            controls.experienceField.setValue(location.experienceField);
                            controls.transferLanguageLevel.setValue(location.vacancies[0].transferLanguageLevel);
                            controls.pathOfRecognition.setValue(location.vacancies[0].pathOfRecognition);
                            controls.benefits.setValue(location.vacancies[0].benefits);
                          }
                        })
                      );
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      wrappers: ["fieldset"],
      props: {
        label: translate("interview.title"),
      },
      fieldGroup: [
        {
          key: nameOf("interviewDate"),
          type: "datepicker",
          validation: {
            messages: {
              required: translate("interviewDate.required"),
              maxDate: translate("interviewDate.invalid"),
            },
          },
          props: {
            label: translate("interviewDate.title"),
            showIcon: true,
            required: true,
            hideRequiredMarker: true,
            maxDate: new Date(),
            blocklyType: "field_date",
          },
        },
        {
          template: "",
          expressionProperties: {
            template: (model: MatchingAgreement): Date | string => {
              return model.interviewDate
                ? `<p>${translate("matchingAgreement.text", {
                    date: context?.formatter.transformDateTime(model.interviewDate, { dateStyle: "short" }, language),
                  })}</p>`
                : "";
            },
          },
        },
      ],
    },
    {
      fieldGroupClassName: "grid",
      wrappers: ["fieldset"],
      props: {
        label: translate("occupation.title"),
      },
      fieldGroup: [
        {
          className: "col-12",
          type: "input",
          key: nameOf("address"),
          props: {
            label: translate("address.title"),
            type: "text",
            maxLength: 100,
            required: true,
            hideRequiredMarker: true,
            blocklyType: "String",
          },
        },
        {
          className: "col-12",
          type: "multiselect",
          key: nameOf("experienceField"),
          props: {
            label: translate("experienceField.title"),
            placeholder: translate("experienceField.placeholder"),
            showToggleAll: false,
            filter: true,
            required: true,
            hideRequiredMarker: true,
            options: staticDataService.getStaticData(StaticDataType.ProfessionalFields, language),
          },
        },
        {
          className: "col-12 lg:col-6",
          type: "multiselect",
          key: nameOf("careFacility"),
          props: {
            label: translate("careFacility.title"),
            placeholder: translate("careFacility.placeholder"),
            showToggleAll: false,
            filter: true,
            required: true,
            hideRequiredMarker: true,
            options: staticDataService.getStaticData(StaticDataType.CareFacilities, language),
          },
        },
      ],
    },
    {
      fieldGroup: [
        {
          type: "inputSwitch",
          key: nameOf("diffrentEmploymentTerms"),
          props: {
            label: translate("employmentTerms.differ"),
            styleClass: "mt-4",
          },
        },
      ],
    },
    {
      wrappers: ["fieldset"],
      fieldGroupClassName: "grid",
      key: nameOf("employemntTermsBeforeRecognition"),
      props: {
        label: translate("employmentTerms.before"),
      },
      fieldGroup: getEmploymentTermsFormlyFields(),
    },
    {
      wrappers: ["fieldset"],
      fieldGroupClassName: "grid",
      key: nameOf("employemntTermsAfterRecognition"),
      props: {
        label: translate("employmentTerms.after"),
      },
      expressions: {
        hide: (field: FormlyFieldConfig): boolean => !field.parent.model.diffrentEmploymentTerms,
      },
      fieldGroup: getEmploymentTermsFormlyFields(),
    },
    {
      fieldGroupClassName: "grid",
      wrappers: ["fieldset"],
      props: {
        label: translate("generalConditions.title"),
      },
      fieldGroup: [
        {
          type: "dropdown",
          className: "col-12 lg:col-6",
          key: nameOf("transferLanguageLevel"),
          props: {
            label: translate("transferLanguageLevel.title"),
            placeholder: translate("transferLanguageLevel.placeholder"),
            filter: true,
            filterBy: "label",
            required: true,
            hideRequiredMarker: true,
            options: context?.organizationSpecifics.getTransferLanguageLevels(language),
          },
        },
        {
          type: "dropdown",
          className: "col-12 lg:col-6",
          key: nameOf("pathOfRecognition"),
          props: {
            label: translate("pathOfRecognition.title"),
            placeholder: translate("pathOfRecognition.placeholder"),
            filter: true,
            filterBy: "label",
            required: true,
            hideRequiredMarker: true,
            options: staticDataService.getStaticData(StaticDataType.PathOfRecognition, language),
            blocklyType: "field_staticdata_pathofrecognition",
          },
        },
        {
          fieldGroup: [
            {
              type: "checkbox",
              key: nameOf("recognitionReimbursement"),
              props: {
                label: translate("recognitionReimbursement.title"),
                blocklyType: "Boolean",
              },
            },
            {
              type: "checkbox",
              key: nameOf("travelReimbursement"),
              props: {
                label: translate("travelReimbursement.title"),
                blocklyType: "Boolean",
              },
            },
            {
              type: "checkbox",
              key: nameOf("regulatoryReimbursement"),
              props: {
                label: translate("regulatoryReimbursement.title"),
                blocklyType: "Boolean",
              },
            },
          ],
        },
        {
          className: "col-12",
          type: "multiselect",
          key: nameOf("benefits"),
          props: {
            label: translate("benefits.title"),
            placeholder: translate("benefits.placeholder"),
            showToggleAll: false,
            filter: true,
            required: false,
            hideRequiredMarker: true,
            readonlyProxy: true,
            options: staticDataService.getStaticData(StaticDataType.Benefits, language),
          },
        },
      ],
    },
  ];
}
