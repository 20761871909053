<ng-container *transloco="let t">
  <div class="p-inputgroup mb-2">
    <p-dropdown
      #criterionSelector
      [options]="unselectedCriteria"
      [ngModel]="null"
      [placeholder]="t('selectionCriteria.placeholder')"
      [disabled]="!unselectedCriteria?.length"
    ></p-dropdown>

    <button
      pButton
      icon="pi pi-plus"
      [disabled]="!criterionSelector.value"
      (click)="addCriterion(criterionSelector.value); criterionSelector.value = null"
    ></button>
  </div>

  <p-table
    [value]="selectedCriteria"
    [style]="{ 'min-height': '200px' }"
    styleClass="mb-2 border-black-alpha-10 border-1"
  >
    <ng-template pTemplate="body" let-criterion let-index="rowIndex">
      <tr [pReorderableRow]="index">
        <td class="p-0 w-1">
          <span class="block button-alignment pi pi-bars" pReorderableRowHandle></span>
        </td>
        <td class="p-0">
          <span class="block button-alignment">
            <app-document-criterion-label [criterion]="criterion.value"></app-document-criterion-label>
          </span>
        </td>
        <td class="p-0 w-1">
          <button
            pButton
            icon="pi pi-minus"
            class="p-button-rounded p-button-text"
            (click)="$event.preventDefault(); removeCriterion(criterion.value)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="flex flex-row justify-content-end">
    <p-button [label]="t('common.save')" icon="pi pi-check" class="mr-2" (onClick)="save()"></p-button>
    <p-button
      closeOnEscape
      [label]="t('common.cancel')"
      icon="pi pi-times"
      class="p-button-secondary"
      (onClick)="cancel()"
    ></p-button>
  </div>
</ng-container>
