import { Directive, OnInit } from "@angular/core";
import { MultiSelect } from "primeng/multiselect";

@Directive({ selector: "p-multiSelect" })
export class MultiSelectReadonlyFixDirective implements OnInit {
  private clearSource: MultiSelect["clear"];
  private removeOptionSource: MultiSelect["removeOption"];

  constructor(private readonly multiSelect: MultiSelect) {}

  ngOnInit(): void {
    this.clearSource = this.multiSelect.clear;
    this.multiSelect.clear = (event: Event): void => this.clear(this.multiSelect, event);

    this.removeOptionSource = this.multiSelect.removeOption;
    this.multiSelect.removeOption = (option: unknown, event: MouseEvent): void =>
      this.removeOption(this.multiSelect, option, event);
  }

  private clear(multiSelect: MultiSelect, event: Event): void {
    if (multiSelect.readonly) {
      return;
    }
    return this.clearSource.call(multiSelect, event);
  }

  private removeOption(multiSelect: MultiSelect, option: unknown, event: MouseEvent): void {
    if (multiSelect.readonly) {
      return;
    }
    return this.removeOptionSource.call(multiSelect, option, event);
  }
}
