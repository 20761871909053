<ng-container *transloco="let t; lang: processLanguage + '|static'">
  <p-table
    #table
    [value]="emailsForTable"
    [globalFilterFields]="$any([getGlobalFilterExpression])"
    dataKey="id"
    class="app-form"
    styleClass="p-datatable app-form email-selector"
    sortField="date"
    [sortOrder]="-1"
    selectionMode="single"
    (onRowSelect)="openPreview.emit($event.data)"
    (onRowUnselect)="openPreview.emit(null)"
  >
    <ng-template pTemplate="caption">
      <div class="flex">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="table.filterGlobal($any($event.target).value, 'contains')"
            [placeholder]="t('common.search')"
          />
        </span>
        <ng-content></ng-content>
      </div>
    </ng-template>

    <ng-template pTemplate="header"></ng-template>

    <ng-template pTemplate="body" let-email>
      <tr [pSelectableRow]="email">
        <td>
          <div class="email-cell">
            <div class="email-to white-space-nowrap text-overflow-ellipsis overflow-hidden mb-2">
              <ng-container *ngFor="let to of email.to; index as i">
                <span>{{ to.name }}</span>
                <span>{{ to.id }}</span>
                <span *ngIf="i < email.to.length - 1">; </span>
                <span *ngIf="to.displayId != null">({{ to.displayId }})</span>
              </ng-container>
            </div>

            <div class="email-subject white-space-nowrap text-overflow-ellipsis overflow-hidden p-text-secondary">
              {{ email.subject }}
            </div>

            <div class="email-sent-at p-text-secondary">
              {{ email.date | appDate: { dateStyle: "short", timeStyle: "short" } : processLanguage }}
            </div>
            <button
              *ngIf="email.messengerMessageIds?.length > 0"
              pButton
              type="button"
              icon="pi pi-facebook"
              class="p-button-rounded p-button-text show-messages-button"
              [pTooltip]="t('messenger.show')"
              tooltipPosition="left"
              [disabled]="showMessengerMessageDisabled"
              (click)="showMessengerMessages.emit({ email: email, event: $event })"
            ></button>

            <button
              *ngIf="email.events?.length > 0"
              pButton
              type="button"
              icon="pi pi-clock"
              class="p-button-rounded p-button-text show-events-button"
              [class.p-button-warning]="email.wasNotDelivered"
              [class.p-button-danger]="email.deliveryFailed"
              [pTooltip]="t('events.show')"
              tooltipPosition="left"
              [disabled]="showEventsDisabled"
              (click)="showEvents.emit({ email: email, event: $event })"
            ></button>

            <button
              *ngIf="showBannedVisible && email.anyRecipientNotInvited"
              pButton
              type="button"
              icon="pi pi-ban"
              class="p-button-rounded p-button-text show-events-button"
              [class.p-button-warning]="email.anyRecipientNotInvited && !email.allRecipientsNotInvited"
              [class.p-button-danger]="email.allRecipientsNotInvited"
              [pTooltip]="t('invited.show')"
              tooltipPosition="left"
              (click)="showBanned.emit({ email: email, event: $event })"
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
