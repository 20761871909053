<ng-container *transloco="let t">
  <ng-container [ngSwitch]="activity.type">
    <a
      *ngSwitchCase="ActivityType.CandidateAdded"
      [routerLink]="['/app/candidates/edit', activity.candidate.organizationId, activity.candidate.id]"
    >
      {{ t("activityLog.description.candidateAdded") }}
    </a>
    <span *ngSwitchCase="ActivityType.InvalidUserGroupRoleMapping">
      {{
        t("activityLog.description.invalidUserGroupRoleMapping", {
          roleName: activity.parameters.roleName,
          organizationName: activity.parameters.organizationName,
          operationType: activity.parameters.operationType | enum: "OrganizationLinkOperationType",
        })
      }}
    </span>
    <span *ngSwitchCase="ActivityType.InvalidSharingTemplate">
      {{
        t("activityLog.description.invalidSharingTemplate", {
          variableName: activity.parameters.variableName,
          organizationName: activity.parameters.organizationName,
          operationType: activity.parameters.operationType | enum: "OrganizationLinkOperationType",
          collectionName: activity.collection.name,
        })
      }}
    </span>
    <span *ngSwitchCase="ActivityType.OrganizationNotLinked">
      {{
        t("activityLog.description.organizationNotLinked", {
          notLinkedOrganization: activity.parameters.notLinkedOrganizationName,
          operationType: activity.parameters.operationType | enum: "OrganizationLinkOperationType",
        })
      }}
    </span>
    <span *ngSwitchCase="ActivityType.OrganizationNotFound">
      {{
        t("activityLog.description.organizationNotFound", {
          id: activity.parameters.id,
          organizationId: activity.parameters.organizationId,
        })
      }}
    </span>
    <span *ngSwitchCase="ActivityType.ProcessNotFound">
      {{
        t("activityLog.description.processNotFound", {
          id: activity.parameters.id,
          organizationId: activity.parameters.organizationId,
        })
      }}
    </span>
    <span *ngSwitchCase="ActivityType.DocumentNotFound">
      {{
        t("activityLog.description.documentNotFound", {
          id: activity.parameters.id,
          organizationId: activity.parameters.organizationId,
        })
      }}
    </span>
    <span *ngSwitchCase="ActivityType.TaskNotFound">
      {{
        t("activityLog.description.taskNotFound", {
          id: activity.parameters.id,
          organizationId: activity.parameters.organizationId,
        })
      }}
    </span>
    <span *ngSwitchCase="ActivityType.CollectionNotFound">
      {{
        t("activityLog.description.collectionNotFound", {
          id: activity.parameters.id,
          organizationId: activity.parameters.organizationId,
        })
      }}
    </span>
    <span *ngSwitchCase="ActivityType.CandidateNotFound">
      {{
        t("activityLog.description.candidateNotFound", {
          id: activity.parameters.id,
          organizationId: activity.parameters.organizationId,
        })
      }}
    </span>
    <span *ngSwitchCase="ActivityType.UserNotFound">
      {{
        t("activityLog.description.userNotFound", {
          id: activity.parameters.id,
          organizationId: activity.parameters.organizationId,
        })
      }}
    </span>

    <span *ngSwitchCase="ActivityType.CandidateUpdated">
      {{ t("activityLog.description.candidateModified") }}
    </span>
    <span *ngSwitchCase="ActivityType.CandidateOptedIn">
      {{ t("activityLog.description.candidateOptedIn") }}
    </span>
    <span *ngSwitchCase="ActivityType.CandidateOptedOut">
      {{ t("activityLog.description.candidateOptedOut") }}
    </span>
    <span *ngSwitchCase="ActivityType.CandidateDeleted">
      {{ t("activityLog.description.candidateDeleted") }}
    </span>
    <span *ngSwitchCase="ActivityType.CandidateDeletionReminder">
      {{ t("activityLog.description.candidateDeletionReminder") }}
    </span>
    <span *ngSwitchCase="ActivityType.CandidateRequestDeletion">
      {{ t("activityLog.description.CandidateRequestDeletion") }}
    </span>
    <span *ngSwitchCase="ActivityType.CandidateBirthday">
      {{ t("activityLog.description.candidateBirthday") }}
    </span>

    <span *ngSwitchCase="ActivityType.CandidateBirthdaySoon">
      {{ t("activityLog.description.candidateBirthdaySoon", { days: activity.parameters.in }) }}
    </span>

    <span *ngSwitchCase="ActivityType.FileUploaded">
      {{
        t("activityLog.description.fileUploaded", {
          name: activity.parameters.name,
          type: activity.parameters.type | staticData: StaticDataType.AllowedUploadFileTypes | async,
          formats: activity.parameters.formats | staticData: StaticDataType.DocumentFormats | async,
        })
      }}
    </span>

    <span *ngSwitchCase="ActivityType.FileModified">
      {{
        t("activityLog.description.fileModified", {
          name: activity.parameters.name,
          type: activity.parameters.type | staticData: StaticDataType.AllowedUploadFileTypes | async,
          oldFormats: activity.parameters.oldFormats | staticData: StaticDataType.DocumentFormats | async,
          newFormats: activity.parameters.newFormats | staticData: StaticDataType.DocumentFormats | async,
        })
      }}
    </span>

    <span *ngSwitchCase="ActivityType.DocumentSetMetaDataModified">
      {{
        t("activityLog.description.documentSetMetaDataModified", {
          type: activity.parameters.type | staticData: StaticDataType.AllowedUploadFileTypes | async,
          changes: getDocumentSetModifiedChanges(activity),
        })
      }}
    </span>

    <span *ngSwitchCase="ActivityType.FileDeleted">
      {{
        t("activityLog.description.fileDeleted", {
          name: activity.parameters.name,
          type: activity.parameters.type | staticData: StaticDataType.AllowedUploadFileTypes | async,
          formats: activity.parameters.formats | staticData: StaticDataType.DocumentFormats | async,
        })
      }}
    </span>

    <span *ngSwitchCase="ActivityType.FileExpiresSoon">
      {{
        t("activityLog.description.fileExpiresSoon", {
          name: activity.parameters.name,
          type: activity.parameters.type | staticData: StaticDataType.AllowedUploadFileTypes | async,
          formats: activity.parameters.formats | staticData: StaticDataType.DocumentFormats | async,
          days: activity.parameters.in,
        })
      }}
    </span>

    <span *ngSwitchCase="ActivityType.FreeFormatFileUploaded">
      {{
        t("activityLog.description.freeFormatFileUploaded", {
          name: activity.parameters.name,
          type: activity.parameters.type | staticData: StaticDataType.AllowedUploadFileTypes | async,
          formats: activity.parameters.formats | staticData: StaticDataType.DocumentFormats | async,
        })
      }}
    </span>

    <span *ngSwitchCase="ActivityType.FreeFormatFileDeleted">
      {{
        t("activityLog.description.freeFormatFileDeleted", {
          name: activity.parameters.name,
          type: activity.parameters.type | staticData: StaticDataType.AllowedUploadFileTypes | async,
          formats: activity.parameters.formats | staticData: StaticDataType.DocumentFormats | async,
        })
      }}
    </span>

    <a
      *ngSwitchCase="ActivityType.CollectionAdded"
      [routerLink]="[
        '/app/collections',
        activity.source.organizationId === organizationId ? 'edit' : 'view',
        activity.source.organizationId,
        activity.source.id,
      ]"
    >
      {{ t("activityLog.description.collectionAdded", { collection: activity.collection.name }) }}
    </a>

    <span *ngSwitchCase="ActivityType.CollectionDeleted">
      {{ t("activityLog.description.collectionDeleted", { collection: activity.collection.name }) }}
    </span>

    <a
      *ngSwitchCase="ActivityType.CollectionUpdated"
      [routerLink]="[
        '/app/collections',
        activity.source.organizationId === organizationId ? 'edit' : 'view',
        activity.source.organizationId,
        activity.source.id,
      ]"
    >
      <ng-container *ngIf="activity.parameters?.from?.name as oldName; else notRenamed">
        <ng-container *ngIf="oldName !== activity.collection.name; else notRenamed">
          {{
            t("activityLog.description.collectionUpdatedAndRenamed", {
              collection: oldName,
              newName: activity.collection.name,
            })
          }}
        </ng-container>
      </ng-container>
      <ng-template #notRenamed>
        {{ t("activityLog.description.collectionUpdated", { collection: activity.collection.name }) }}
      </ng-template>
    </a>

    <a
      *ngSwitchCase="ActivityType.CandidateAddedToCollection"
      [routerLink]="[
        '/app/collections',
        activity.source.organizationId === organizationId ? 'edit' : 'view',
        activity.parameters.to.organizationId,
        activity.parameters.to.id,
      ]"
    >
      {{
        t("activityLog.description.candidateAddedToCollection", {
          candidate: activity.candidate.name,
          collection: activity.parameters.to.name,
        })
      }}
    </a>

    <a
      *ngSwitchCase="ActivityType.CandidateRemovedFromCollection"
      [routerLink]="[
        '/app/collections',
        activity.source.organizationId === organizationId ? 'edit' : 'view',
        activity.parameters.from.organizationId,
        activity.parameters.from.id,
      ]"
    >
      {{
        t("activityLog.description.candidateRemovedFromCollection", {
          candidate: activity.candidate.name,
          collection: activity.parameters.from.name,
        })
      }}
    </a>

    <span *ngSwitchCase="ActivityType.CollectionEmbeddedIntoCollection">
      {{
        t("activityLog.description.collectionEmbeddedIntoCollection", {
          collection: activity.parameters.embedded.name,
          toCollection: activity.collection.name,
        })
      }}
    </span>

    <span *ngSwitchCase="ActivityType.CollectionRemovedFromCollection">
      {{
        t("activityLog.description.collectionRemovedFromCollection", {
          collection: activity.parameters.embedded.name,
          fromCollection: activity.collection.name,
        })
      }}
    </span>

    <a
      *ngSwitchCase="ActivityType.SharingAdded"
      [routerLink]="[
        '/app/collections',
        activity.source.organizationId === organizationId ? 'edit' : 'view',
        activity.collection.organizationId,
        activity.collection.id,
      ]"
    >
      {{
        t("activityLog.description.sharingAdded", {
          collection: activity.collection.name,
          organization: activity.parameters.organization.name,
        })
      }}
    </a>

    <span *ngSwitchCase="ActivityType.SharingDeleted">
      {{
        t("activityLog.description.sharingDeleted", {
          collection: activity.collection.name,
          organization: activity.parameters.organization.name,
        })
      }}
    </span>

    <a
      *ngSwitchCase="ActivityType.SharingUpdated"
      [routerLink]="[
        '/app/collections',
        activity.source.organizationId === organizationId ? 'edit' : 'view',
        activity.collection.organizationId,
        activity.collection.id,
      ]"
    >
      {{ t("activityLog.description.sharingUpdated", { collection: activity.collection.name }) }}
    </a>

    <a
      *ngSwitchCase="ActivityType.SharingEnabled"
      [routerLink]="[
        '/app/collections',
        activity.source.organizationId === organizationId ? 'edit' : 'view',
        activity.collection.organizationId,
        activity.collection.id,
      ]"
    >
      {{
        t("activityLog.description.sharingEnabled", {
          collection: activity.collection.name,
          organization: activity.parameters.organization.name,
        })
      }}
    </a>

    <a
      *ngSwitchCase="ActivityType.SharingDisabled"
      [routerLink]="[
        '/app/collections',
        activity.source.organizationId === organizationId ? 'edit' : 'view',
        activity.collection.organizationId,
        activity.collection.id,
      ]"
    >
      {{
        t("activityLog.description.sharingDisabled", {
          collection: activity.collection.name,
          organization: activity.parameters.organization.name,
        })
      }}
    </a>

    <a
      *ngSwitchCase="ActivityType.SharingTypeModified"
      [routerLink]="[
        '/app/collections',
        activity.source.organizationId === organizationId ? 'edit' : 'view',
        activity.collection.organizationId,
        activity.collection.id,
      ]"
    >
      {{
        t("activityLog.description.sharingTypeModified", {
          collection: activity.collection.name,
          oldType: activity.parameters.oldType | enum: "SharingTypeEnum",
          newType: activity.parameters.newType | enum: "SharingTypeEnum",
        })
      }}
    </a>

    <a
      *ngSwitchCase="ActivityType.ProcessCreated"
      [routerLink]="['/app/processes/view', activity.process.organizationId, activity.process.id]"
    >
      {{
        t("activityLog.description.processCreated", {
          process: activity.process.name,
        })
      }}
    </a>

    <span *ngSwitchCase="ActivityType.ProcessDeleted">
      {{ t("activityLog.description.processDeleted") }}
    </span>

    <a
      *ngSwitchCase="ActivityType.TaskManuallyCreated"
      [routerLink]="['/app/processes/view', activity.process.organizationId, activity.process.id]"
    >
      {{
        t("activityLog.description.taskManuallyCreated", {
          task: activity.task.name | processString,
          process: activity.process.name,
        })
      }}
    </a>
    <span *ngSwitchCase="ActivityType.TaskDeleted">
      {{
        t("activityLog.description.taskDeleted", {
          task: activity.task.name | processString,
          process: activity.process.name,
        })
      }}
    </span>
    <a
      *ngSwitchCase="ActivityType.TaskStarted"
      [routerLink]="['/app/processes/view', activity.process.organizationId, activity.process.id]"
    >
      {{
        t("activityLog.description.taskStarted", {
          task: activity.task.name | processString,
          process: activity.process.name,
        })
      }}
    </a>
    <a
      *ngSwitchCase="ActivityType.TaskNoteAdded"
      [routerLink]="['/app/processes/view', activity.process.organizationId, activity.process.id]"
    >
      {{
        t("activityLog.description.TaskNoteAdded", {
          task: activity.task.name | processString,
          process: activity.process.name,
        })
      }}
    </a>
    <a
      *ngSwitchCase="ActivityType.TaskFinished"
      [routerLink]="['/app/processes/view', activity.process.organizationId, activity.process.id]"
    >
      {{
        t("activityLog.description.taskFinished", {
          task: activity.task.name | processString,
          process: activity.process.name,
        })
      }}
    </a>

    <a
      *ngSwitchCase="ActivityType.TaskCancelled"
      [routerLink]="['/app/processes/view', activity.process.organizationId, activity.process.id]"
    >
      {{
        t("activityLog.description.taskCancelled", {
          task: activity.task.name | processString,
          process: activity.process.name,
        })
      }}
    </a>

    <a
      *ngSwitchCase="ActivityType.TaskFailed"
      [routerLink]="['/app/processes/view', activity.process.organizationId, activity.process.id]"
    >
      {{
        t("activityLog.description.taskFailed", {
          task: activity.task.name | processString,
          process: activity.process.name,
        })
      }}
    </a>

    <span *ngSwitchCase="ActivityType.TaskReminderSent">
      {{ t("activityLog.description.taskReminderSent", { subject: activity.parameters.subject }) }}
    </span>

    <span *ngSwitchCase="ActivityType.EmailNotDelivered">
      {{
        t("activityLog.description.emailNotDelivered", {
          subject: activity.parameters.subject,
          recipient: activity.parameters.recipient,
        })
      }}
    </span>

    <span *ngSwitchCase="ActivityType.CandidateLoggedIn">
      {{ t("activityLog.description.candidateLoggedIn") }}
    </span>
    <span *ngSwitchCase="ActivityType.UserLoggedIn">
      {{ t("activityLog.description.userLoggedIn") }}
    </span>

    <a
      *ngSwitchCase="ActivityType.FeedbackProvided"
      [routerLink]="[
        '/app/candidates/view',
        activity.user.organizationId,
        activity.collection.id,
        activity.candidate.id,
      ]"
    >
      {{
        activity.parameters?.statusPhaseOne
          ? t("activityLog.description.feedbackProvided", {
              state: [
                [activity.parameters.statusPhaseOne | enum: "FeedbackStatusPhaseOne"],
                activity.parameters.interviewConducted ? [t("feedback.interviewConducted")] : [],
                activity.parameters.statusPhaseTwo
                  ? [activity.parameters.statusPhaseTwo | enum: "FeedbackStatusPhaseTwo"]
                  : [],
              ]
                .flat()
                .join(" - "),
              organization: activity.source.organizationName,
            })
          : t("activityLog.description.feedbackReset", { organization: activity.source.organizationName })
      }}
    </a>

    <span *ngSwitchCase="ActivityType.CandidateDroppedOut">
      {{ t("activityLog.description.candidateDroppedOut") }}
    </span>
    <span *ngSwitchCase="ActivityType.AutoProcessOperationTriggered">
      {{
        t("activityLog.description.autoProcessOperationTriggered", {
          token: activity.parameters.token | enum: "AutoProcessToken",
        })
      }}
    </span>
    <a
      *ngSwitchCase="ActivityType.ProcessModelReleaseCreated"
      [routerLink]="['/app/process-models/', activity.processModel.organizationId, activity.processModel.id]"
    >
      {{
        t("activityLog.description.processModelReleaseCreated", {
          processModel: activity.processModel.name | processModelString,
        })
      }}
    </a>
    <a
      *ngSwitchCase="ActivityType.ProcessModelPublished"
      [routerLink]="['/app/process-models/', activity.processModel.organizationId, activity.processModel.id]"
    >
      {{
        t("activityLog.description.processModelPublished", {
          processModel: activity.processModel.name | processModelString,
        })
      }}
    </a>
    <a
      *ngSwitchCase="ActivityType.ProcessModelUnpublished"
      [routerLink]="['/app/process-models/', activity.processModel.organizationId, activity.processModel.id]"
    >
      {{
        t("activityLog.description.processModelUnpublished", {
          processModel: activity.processModel.name | processModelString,
        })
      }}
    </a>
    <span *ngSwitchCase="ActivityType.ProcessModelDeleted">
      {{
        t("activityLog.description.processModelDeleted", {
          processModel: activity.processModel.name | processModelString,
        })
      }}
    </span>
  </ng-container>
</ng-container>
