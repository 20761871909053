<div>
  <span class="font-bold">Type: </span>
  <span>{{ documentType }}</span>
</div>
<div>
  <span class="font-bold">Label: </span>
  <span>{{ documentType | staticData: StaticDataType.AllowedUploadFileTypes | async }}</span>
</div>
<ng-container *ngIf="selectedField['field'] as setField; else documentField">
  <div>
    <span class="font-bold">Field: </span>
    <span>{{ setField }}</span>
  </div>
  <div *ngIf="selectedField['selectedSet'] as set">
    <span class="font-bold">Set: </span>
    <span *ngIf="set['foreignKey'] as foreignKey">{{ foreignKey }}</span>
    <span *ngIf="set['documentSetType'] as documentSetType">{{ documentSetType }}</span>
    <span *ngIf="isString(set)">{{ set }}</span>
  </div>
</ng-container>
<ng-template #documentField>
  <div>
    <span class="font-bold">Field: </span>
    <span>{{ selectedField }}</span>
  </div>
</ng-template>
