import { isImage } from "@ankaadia/ankaadia-shared";
import { DocumentFile, DocumentFreeFormatFile } from "@ankaadia/graphql";

type PreviewableFreeFormatFile = Omit<DocumentFreeFormatFile, "blob">;
type PreviewableDocumentFile = Omit<DocumentFile, "blob">;
type PreviewableFile = PreviewableDocumentFile | PreviewableFreeFormatFile;

export type FileType = "pdf" | "doc" | "docx" | "xls" | "xlsx" | "ppt" | "pptx" | "image" | "unknown";

const extensionToFileType: Record<Exclude<FileType, "image" | "unknown">, true> = {
  pdf: true,
  doc: true,
  docx: true,
  xls: true,
  xlsx: true,
  ppt: true,
  pptx: true,
};

export function getFileType(file?: PreviewableFile, fileName?: string): FileType {
  if (file && isImage(file.type)) {
    return "image";
  }

  const lowerCasedFileName = file?.name?.toLowerCase() || fileName?.toLowerCase();
  if (!lowerCasedFileName) {
    return "unknown";
  }

  const fileExtension = lowerCasedFileName.split(".").pop();
  return fileExtension && extensionToFileType[fileExtension] ? (fileExtension as FileType) : "unknown";
}
