<ng-container *transloco="let t">
  <p-multiSelect
    #multiSelect
    [inputId]="inputId"
    [options]="types$ | async"
    [disabled]="disabled"
    [placeholder]="t('documentType.placeholder')"
    [showToggleAll]="false"
    [showClear]="true"
    [ngModel]="model"
    (onBlur)="onModelTouched()"
    (onChange)="updateModel($event.value)"
    (onClear)="updateModel(null)"
  ></p-multiSelect>
</ng-container>
