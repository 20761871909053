<ng-container *transloco="let t">
  <div class="p-datatable" *ngIf="warnings">
    <table class="p-datatable-table">
      <tbody class="p-datatable-tbody">
        <tr *ngFor="let warning of warnings">
          <td>{{ t("template.errors." + warning.translationKey, warning.parameters) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
