import { Injectable, Type } from "@angular/core";
import {
  AddressType,
  EmployerRelocationLanguageLevels,
  ENABLE_EDUCATION,
  flatMapSuperDeep,
  getAllEducationVoucherRoles,
  getAllHousingAcquisitionRoles,
  getAllQualificationEvaluationPaymentRoles,
  getAllRecognitionPaymentRoles,
  getAllResponsibleRoles,
  getAllScholarshipRoles,
  KnownFormToken,
  OtherRole,
  responsibleEmployeeRoles,
  KnowledgeProficiency,
  YearsOfSkillExperience,
} from "@ankaadia/ankaadia-shared";
import {
  Candidate,
  CandidateRelation,
  CandidateStatus,
  EducationCourseAttendance,
  EducationCourseCandidateStatus,
  EducationCoursePerformanceStatus,
  EmploymentRelationshipType,
  ExamModuleType,
  FeedbackStatusPhaseOne,
  FeedbackStatusPhaseTwo,
  MatchingStatus,
  ProcessTaskInformationCollection,
  ProfileDataEditStatus,
  RemunerationCalculationBasis,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
  TechnicalSourceEnum,
  TerminationEmploymentActor,
  VoteType,
  WorkExperiencePrecissionEnum,
  OccupationSkillType,
  OccupationSkillForFilterFragment,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@ngneat/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { clone, isEmpty } from "lodash";
import { forkJoin, map, Observable, of, switchMap, take } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import {
  candidateConditionFactory,
  CandidateFilterGroup,
  ExistingImmigrationCountry,
  StaticDataCountry,
  StaticDataCountryGroup,
} from "../../features/candidate-filter/candidate-filter.model";
import { CandidateCardComponent } from "../../features/candidates/candidate-card-component.model";
import { CandidateProfileComponent } from "../../features/candidates/candidate-profile-component.model";
import {
  CandidateProfileField,
  CandidateProfileFieldGroup,
} from "../../features/candidates/candidate-profile-field-select/candidate-profile-field-configuration.model";
import { getSortedCandidateStatusList } from "../../features/candidates/candidate-status/candidate-status-enum-helper";
import { CandidateProfile, CandidatesService } from "../../features/candidates/candidates.service";
import { CertificateValuationAuthorityService } from "../../features/certificate-valuation-authorities/certificate-valuation-authorities.service";
import { DiplomaticMissionService } from "../../features/diplomatic-missions/diplomatic-mission.service";
import { EmployerService } from "../../features/employer/employer.service";
import { EmploymentApprovalAuthorityService } from "../../features/employment-approval-authority/employment-approval-authority.service";
import { ImmigrationAuthorityService } from "../../features/immigration-authorities/immigration-authority.service";
import { ContractTemplateService } from "../../features/organizations/contract-template/contract-template.service";
import { OrganizationsService } from "../../features/organizations/organizations.service";
import { ProcessService } from "../../features/process/process.service";
import { RecognitionAuthorityService } from "../../features/recognition-authorities/recognition-authorities.service";
import { UsersService } from "../../features/users/users.service";
import { DateFormatterService } from "../../shared/services/date-formatter.service";
import { safeForkJoin } from "../../shared/services/safe-fork-join";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataService } from "../../shared/static-data/static-data.service";
import { candidateProfileFactory } from "../base-candidate-profile/formly-definition";
import { CandidateProfileTab, OrganizationSpecific, SupportedTaskTypes } from "../organization-specific";
import { DefaultCardViewComponent } from "./default-candidate-card/default-card-view-component.component";
import { DefaultProfileComponent } from "./default-candidate-profile/default-profile.component";
import { candidateAllowanceFormFactory } from "./forms/candidateAllowance-form.factory";
import { interviewAppointmentConfirmationFormFactory } from "./forms/interview-appointment-confirmation-form.factory";
import { interviewAppointmentProposalFormFactory } from "./forms/interview-appointment-proposal-form.factory";
import { interviewAppointmentScheduleFormFactory } from "./forms/interview-appointment-schedule-form.factory";
import { interviewAppointmentScheduleSingleDateFormFactory } from "./forms/interview-appointment-schedule-single-date.factory";
import { matchingAgreementFormFactory } from "./forms/matching-agreement-form.factory";
import { qualificationEvaluationConfigurationFormFactory } from "./forms/qualification-evaluation-configuration-form.factory";
import { qualificationEvaluationReceivedFormFactory } from "./forms/qualification-evaluation-received-form.factory";
import { qualificationEvaluationRequestFormFactory } from "./forms/qualification-evaluation-request-form.factory";
import { shippingInformationFormFactory } from "./forms/shipping-information-form.factory";
import { submissionDateFormFactory } from "./forms/submission-date.form.factory";
import { travelBookingFormFactory } from "./forms/travel-booking-form.factory";
import { AnabinService } from "../../features/anabin/anabin.service";
import { ProfessionalExperienceService } from "../../features/professional-field/professional-experience.service";
import { ProfessionService } from "../../features/profession/profession.service";

@Injectable({ providedIn: "root" })
export class DefaultSpecific implements OrganizationSpecific {
  protected get language(): string {
    return this.transloco.getActiveLang();
  }

  constructor(
    protected readonly transloco: TranslocoService,
    protected readonly settings: SettingsService,
    protected readonly organizationService: OrganizationsService,
    protected readonly contractTemplateService: ContractTemplateService,
    protected readonly recognitionAuthorityService: RecognitionAuthorityService,
    protected readonly immigrationAuthorityService: ImmigrationAuthorityService,
    protected readonly employmentApprovalAuthorityService: EmploymentApprovalAuthorityService,
    protected readonly certificateValuationAuthorityService: CertificateValuationAuthorityService,
    protected readonly diplomaticMissionService: DiplomaticMissionService,
    protected readonly formatter: DateFormatterService,
    protected readonly processService: ProcessService,
    protected readonly candidateService: CandidatesService,
    protected readonly employerService: EmployerService,
    protected readonly staticDataService: StaticDataService,
    protected readonly usersService: UsersService,
    protected readonly anabinService: AnabinService,
    protected readonly professionService: ProfessionService,
    protected readonly professionalExperienceService: ProfessionalExperienceService
  ) {}

  showCandidateFunctionFilter(): boolean {
    return false;
  }

  getBirthdate(candidate: Candidate): Date {
    return candidate.os.profile.birthdate;
  }

  getCandidateProfileCustomFieldsTabName(): string {
    return this.getCandidateProfileCustomFields()?.[0]?.props?.label;
  }

  getCandidateProfileCustomFields(
    _requiredFields?: string[],
    _workExperiencePrecission?: WorkExperiencePrecissionEnum,
    _educationPrecision?: WorkExperiencePrecissionEnum
  ): FormlyFieldConfig[] {
    return null;
  }

  getCandidateProfileFormlyFields(
    requiredFields?: string[],
    workExperiencePrecission?: WorkExperiencePrecissionEnum,
    educationPrecision?: WorkExperiencePrecissionEnum
  ): FormlyFieldConfig[] {
    const defaultFields = candidateProfileFactory(
      this.transloco.getActiveLang(),
      this,
      this.staticDataService,
      this.anabinService,
      this.professionService,
      requiredFields,
      workExperiencePrecission,
      educationPrecision
    );
    const customFields = this.getCandidateProfileCustomFields(
      requiredFields,
      workExperiencePrecission,
      educationPrecision
    );
    if (!isEmpty(customFields)) {
      const customFieldsGroup: FormlyFieldConfig = {
        id: "customFields",
        key: "customFields",
        props: customFields[0].props,
        hideExpression: (_model, formState) =>
          !!(formState?.sharedTabs && formState?.sharedTabs?.customFields === false),
        fieldGroup: customFields,
      };
      defaultFields[0].fieldGroup.push(customFieldsGroup);
    }

    return defaultFields;
  }

  isSecondFeedbackPhaseEnabled(): boolean {
    return false;
  }

  getCandidateProfileComponent(): Type<CandidateProfileComponent> {
    return DefaultProfileComponent;
  }

  getCandidateCardViewComponent(): Type<CandidateCardComponent> {
    return DefaultCardViewComponent;
  }

  getHelpUrl(): string {
    return "https://docs.ankaadia.com";
  }

  getInterviewLanguageLevels(language: string): Observable<StaticDataModel[]> {
    return this.staticDataService
      .getStaticData(StaticDataType.LanguageLevels, language)
      .pipe(map((values) => values.filter((x) => ["A2", "B1"].includes(x.value))));
  }

  getTransferLanguageLevels(language: string): Observable<StaticDataModel[]> {
    return this.staticDataService
      .getStaticData(StaticDataType.LanguageLevels, language)
      .pipe(map((values) => values.filter((x) => ["B1", "B2"].includes(x.value))));
  }

  getCandidateProfileTabs(includeEmail = false): CandidateProfileTab[] {
    return this.getCandidateProfileFormlyFields()[0].fieldGroup.map((t) => ({
      id: t.id,
      key: t.key?.toString(),
      label: t.props.label,
      icon: t.props.icon,
      fields: flatMapSuperDeep(t.fieldGroup, (x) => x.fieldGroup)
        .filter((f) => f.key && (f.props?.label || f.type === "documentUpload") && (includeEmail || f.key != "email")) // email can't be edited by candidates
        .map((f) => ({
          key: <string>f.key,
          label: f.type != "documentUpload" ? f.props.label : translate("profileAccess.documentUpload.title"),
        })),
    }));
  }

  loadProfile(_profile: CandidateProfile, _tabs: CandidateProfileTab[]): void {
    return;
  }

  saveProfile(_profile: CandidateProfile, _tabs: CandidateProfileTab[]): void {
    return;
  }

  getFormlyConfiguration(
    taskOrFormToken: ProcessTaskInformationCollection | KnownFormToken,
    language: string
  ): Observable<FormlyFieldConfig[]> {
    const formToken = (
      typeof taskOrFormToken === "string" ? taskOrFormToken : taskOrFormToken.formToken
    ) as KnownFormToken;
    const task = typeof taskOrFormToken === "string" ? null : taskOrFormToken;

    switch (formToken) {
      case KnownFormToken.ShippingInformationForm:
        return this.getShippingInformationForm(language);
      case KnownFormToken.MatchingAgreementForm:
        return this.getMatchingAgreementForm(task, language);
      case KnownFormToken.InterviewAppointmentProposalForm:
        return this.getInterviewAppointmentProposalForm(language);
      case KnownFormToken.InterviewAppointmentConfirmationForm:
        return this.getInterviewAppointmentConfirmationForm(language);
      case KnownFormToken.InterviewAppointmentScheduleForm:
        return this.getInterviewAppointmentScheduleForm(language);
      case KnownFormToken.InterviewAppointmentScheduleSingleDateForm:
        return this.getInterviewAppointmentScheduleSingleDateForm(language);
      case KnownFormToken.SubmissionDate:
        return this.getSubmissionDateForm(language);
      case KnownFormToken.CandidateAllowence:
        return this.getCandidateAllowanceForm(language);
      case KnownFormToken.TravelBookingForm:
        return this.getTravelBookingForm(language);
      case KnownFormToken.QualificationEvaluationConfiguration:
        return this.getQualificationEvaluationConfigurationForm(language);
      case KnownFormToken.QualificationEvaluationRequest:
        return this.getQualificationEvaluationRequestForm(language);
      case KnownFormToken.QualificationEvaluationReceived:
        return this.getQualificationEvaluationReceivedForm(language);
      default:
        return of([]);
    }
  }

  getCustomCandidateFilters(countries: StaticDataCountry[]): Observable<CandidateFilterGroup[]> {
    return forkJoin([
      this.organizationService
        .getCascadedLinkedOrganizations(this.settings.organizationId)
        .pipe(map((xs) => xs.map((x) => ({ label: x.name, value: x.id })))),
      this.contractTemplateService.getAllForList(this.settings.organizationId).pipe(
        take(1),
        map((xs) => xs.map((x) => ({ label: x.name, value: x.id })))
      ),
    ]).pipe(
      map(
        ([organizations, contracts]) =>
          new Array<CandidateFilterGroup>(
            {
              label: translate("masterData.title"),
              fields: [
                {
                  label: translate("status.title"),
                  value: "status",
                  conditions: candidateConditionFactory("choice")(
                    of(getSortedCandidateStatusList(this.staticDataService, this.language))
                  ),
                },
                {
                  label: translate("candidateDeletion.filter"),
                  value: "candidatesDeletion",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("immigrationCountry.title"),
                  value: "immigrationCountry",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.SupportedImmigrationCountries, this.language)
                  ),
                },
              ],
            },
            {
              label: translate("family.title"),
              fields: [
                {
                  label: translate("reunification.title"),
                  value: "familyMemberReunification",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("emergencyContact.title"),
                  value: "familyMemberEmergencyContact",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("relation.title"),
                  value: "familyMemberRelation",
                  conditions: candidateConditionFactory("choice")(
                    of([
                      { label: translate("enum.CandidateRelation.Partner"), value: CandidateRelation.Partner },
                      { label: translate("enum.CandidateRelation.Child"), value: CandidateRelation.Child },
                      { label: translate("enum.CandidateRelation.Mother"), value: CandidateRelation.Mother },
                      { label: translate("enum.CandidateRelation.Father"), value: CandidateRelation.Father },
                      { label: translate("enum.CandidateRelation.Other"), value: CandidateRelation.Other },
                    ])
                  ),
                },
              ],
            },
            {
              label: translate("beforeRecognition.shortTitle"),
              fields: [
                {
                  label: translate("employer.title"),
                  value: "migration.employmentRelationship.beforeRecognition.employerId",
                  conditions: candidateConditionFactory("choice")(of(organizations)),
                },
                {
                  label: translate("contractTemplate.title"),
                  value: "migration.employmentRelationship.beforeRecognition.contractTemplateId",
                  conditions: candidateConditionFactory("choice")(of(contracts)),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("contractSignDate.title"),
                  value: "document(CONTRACTWORK).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("confirmationSignedDate.title"),
                  value: "migration.employmentRelationship.beforeRecognition.confirmationSignedDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("relocationRequirements.title"),
                  value: "migration.employmentRelationship.beforeRecognition.employmentRelocationRequirements",
                  conditions: candidateConditionFactory("choice")(
                    this.getEmployerRelocationRequirementsLanguageLevels(this.language)
                  ),
                },
                {
                  label: translate("endOfProbationDate.title"),
                  value: "migration.employmentRelationship.beforeRecognition.endOfProbationDate",
                  conditions: candidateConditionFactory("date"),
                },
              ],
            },
            {
              label: translate("afterRecognition.shortTitle"),
              fields: [
                {
                  label: translate("employer.title"),
                  value: "migration.employmentRelationship.afterRecognition.employerId",
                  conditions: candidateConditionFactory("choice")(of(organizations)),
                },
                {
                  label: translate("contractTemplate.title"),
                  value: "migration.employmentRelationship.afterRecognition.contractTemplateId",
                  conditions: candidateConditionFactory("choice")(of(contracts)),
                },
                {
                  label: translate("contractSignDate.title"),
                  value: "migration.employmentRelationship.afterRecognition.contractSignDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("confirmationSignedDate.title"),
                  value: "migration.employmentRelationship.afterRecognition.confirmationSignedDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("relocationRequirements.title"),
                  value: "migration.employmentRelationship.afterRecognition.employmentRelocationRequirements",
                  conditions: candidateConditionFactory("choice")(
                    this.getEmployerRelocationRequirementsLanguageLevels(this.language)
                  ),
                },
                {
                  label: translate("endOfProbationDate.title"),
                  value: "migration.employmentRelationship.afterRecognition.endOfProbationDate",
                  conditions: candidateConditionFactory("date"),
                },
              ],
            },
            {
              label: translate("recognitionPath.title"),
              fields: [
                {
                  label: translate("recognitionStarted.title"),
                  value: "migration.recognitionPath.recognitionStarted",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("recognitionStartDate.title"),
                  value: "migration.recognitionPath.recognitionStartDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  country: SupportedImmigrationCountry.At,
                  label: translate("targetRecognition.title"),
                  value: "migration.at.recognitionPath.targetRecognition",
                  conditions: candidateConditionFactory("choice")(
                    this.getStaticData(StaticDataType.TargetRecognition, SupportedImmigrationCountry.At)
                  ),
                },
                {
                  label: translate("pathOfRecognition.title"),
                  value: "migration.recognitionPath.pathOfRecognition",
                  conditions: candidateConditionFactory("choice")(
                    this.getStaticDataCountryGroups(StaticDataType.PathOfRecognition, countries)
                  ),
                },
                {
                  country: SupportedImmigrationCountry.At,
                  label: translate("legalBasis.title"),
                  value: "migration.at.recognitionPath.legalBasis",
                  conditions: candidateConditionFactory("choice")(
                    this.getStaticData(StaticDataType.RecognitionType, SupportedImmigrationCountry.At)
                  ),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("recognitionType.title"),
                  value: "migration.de.recognitionPath.current.recognitionType",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.RecognitionType, this.language)
                  ),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("equivalenceTest.title"),
                  value: "migration.de.recognitionPath.current.equivalenceTest",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.EquivalenceTest, this.language)
                  ),
                },
                {
                  label: translate("fileNumber.title"),
                  value: "migration.recognitionPath.fileNumber",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("federalState.title"),
                  value: "migration.recognitionPath.federalState",
                  conditions: candidateConditionFactory("choice")(
                    this.getStaticDataCountryGroups(StaticDataType.FederalStates, countries)
                  ),
                },
                {
                  label: translate("recogNoticeAuthority.title"),
                  value: "migration.recognitionPath.recognitionAuthority",
                  conditions: candidateConditionFactory("choice")(this.getRecognitionAuthorities(countries)),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("recognitionNoticeKind.title"),
                  value: "migration.de.recognitionPath.current.recognitionNoticeKind",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.RecognitionNoticeKind, this.language)
                  ),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("recognitionOrDeficitNoteReceived.title"),
                  value: "document(RECDOC|DEFICITNOTE).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("recognitionOrDeficitNoteReceiveDate.title"),
                  value: "document(RECDOC|DEFICITNOTE).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("recognitionReceived.title"),
                  value: "document(RECDOC).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("recognitionReceiveDate.title"),
                  value: "document(RECDOC).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("deficitNoteReceived.title"),
                  value: "document(DEFICITNOTE).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("deficitNoteReceiveDate.title"),
                  value: "document(DEFICITNOTE).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("recognitionRejectionReceived.title"),
                  value: "document(REJECTREC).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("recognitionRejectionReceiveDate.title"),
                  value: "document(REJECTREC).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  country: SupportedImmigrationCountry.At,
                  label: translate("recognitionReceived.title"),
                  value: "migration.at.recognitionPath.recognitionReceived",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  country: SupportedImmigrationCountry.At,
                  label: translate("recognitionReceiveDate.title"),
                  value: "migration.at.recognitionPath.recognitionReceiveDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("professionalCertificateRequested.title"),
                  value: "migration.de.recognitionPath.current.certificateRequested",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("professionalCertificateRequestDate.title"),
                  value: "migration.de.recognitionPath.current.certificateRequestDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("recognition.application.arrived"),
                  value: "document(CONFACCEPTAGENCY).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  label: translate("recognition.application.filter"),
                  value: "document(CONFACCEPTAGENCY).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("immigration.application.arrived"),
                  value: "document(CONFIMMIGRAGENCY).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("immigration.application.filter"),
                  value: "document(CONFIMMIGRAGENCY).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("professionalCertificateReceived.title"),
                  value: "document(ACCEPTDOC).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("professionalCertificateReceiveDate.title"),
                  value: "document(ACCEPTDOC).dateOfReceipt",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("recognitionPaymentRole.title"),
                  value: "migration.de.recognitionPath.current.recognitionPaymentRole",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.ProcessRole, this.language).pipe(
                      map((staticData) => {
                        const roles = getAllRecognitionPaymentRoles();
                        return staticData.filter((entry) => roles.includes(entry.value));
                      })
                    )
                  ),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("recognitionPaymentRoleAccelerated.title"),
                  value: "migration.de.recognitionPath.current.recognitionPaymentRoleAccelerated",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.ProcessRole, this.language).pipe(
                      map((staticData) => {
                        const roles = getAllRecognitionPaymentRoles();
                        return staticData.filter((entry) => roles.includes(entry.value));
                      })
                    )
                  ),
                },
              ],
            },
            {
              label: translate("laborMarketAdmission.title"),
              country: SupportedImmigrationCountry.De,
              fields: [
                {
                  label: translate("responsibleRoleLaborMarket.title"),
                  value: "migration.de.laborMarketAdmission.responsibleRoleLaborMarket",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.ProcessRole, this.language).pipe(
                      map((staticData) => {
                        const roles = getAllResponsibleRoles();
                        return staticData.filter((entry) => roles.includes(entry.value));
                      })
                    )
                  ),
                },
                {
                  label: translate("employmentApprovalRequested.title"),
                  value: "migration.de.laborMarketAdmission.employmentApprovalRequested",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("employmentApprovalRequestDate.title"),
                  value: "migration.de.laborMarketAdmission.employmentApprovalRequestDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("employmentApprovalReceived.title"),
                  value: "document(PREAPPROVAL).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  label: translate("employmentApprovalReceiveDate.title"),
                  value: "document(PREAPPROVAL).dateOfReceipt",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("employmentApprovalAuthority.title"),
                  value: "migration.de.laborMarketAdmission.employmentApprovalAuthority",
                  conditions: candidateConditionFactory("choice")(
                    this.employmentApprovalAuthorityService.getEmploymentApprovalAuthorities(
                      this.settings.organizationId,
                      SupportedImmigrationCountry.De
                    )
                  ),
                },
              ],
            },
            {
              label: translate("qualificationMeasure.title"),
              country: SupportedImmigrationCountry.De,
              fields: [
                {
                  label: translate("qualificationMeasure.title"),
                  value: "migration.de.qualificationMeasure.qualificationMeasure",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.QualificationMeasures, this.language)
                  ),
                },
                {
                  label: translate("qualificationType.title"),
                  value: "migration.de.qualificationMeasure.qualificationType",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.QualificationType, this.language)
                  ),
                },
                {
                  label: translate("startOfFurtherEducation.title"),
                  value: "migration.de.qualificationMeasure.startOfFurtherEducation",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("endOfFurtherEducation.title"),
                  value: "migration.de.qualificationMeasure.endOfFurtherEducation",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("theoryHours.title"),
                  value: "migration.de.qualificationMeasure.theoryHours",
                  conditions: candidateConditionFactory("number"),
                },
                {
                  label: translate("theoryInstitution.title"),
                  value: "migration.de.qualificationMeasure.theoryInstitution",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("practiceHours.title"),
                  value: "migration.de.qualificationMeasure.practiceHours",
                  conditions: candidateConditionFactory("number"),
                },
                {
                  label: translate("practiceInstitution.title"),
                  value: "migration.de.qualificationMeasure.practiceInstitution",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("firstFinalInterviewDate.title"),
                  value: "migration.de.qualificationMeasure.firstFinalInterviewDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("firstFinalInterviewPassed.title"),
                  value: "migration.de.qualificationMeasure.firstFinalInterviewPassed",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("secondFinalInterview.title"),
                  value: "migration.de.qualificationMeasure.secondFinalInterviewDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("secondFinalInterviewPassed.title"),
                  value: "migration.de.qualificationMeasure.secondFinalInterviewPassed",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("careSituations.title"),
                  value: "migration.de.qualificationMeasure.careSituations",
                  conditions: candidateConditionFactory("number"),
                },
                {
                  label: translate("firstExamDate.title"),
                  value: "migration.de.qualificationMeasure.firstExamDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("firstExamPassed.title"),
                  value: "migration.de.qualificationMeasure.firstExamPassed",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("secondExamDate.title"),
                  value: "migration.de.qualificationMeasure.secondExamDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("secondExamPassed.title"),
                  value: "migration.de.qualificationMeasure.secondExamPassed",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("educationVoucherRequested.title"),
                  value: "migration.de.qualificationMeasure.educationVoucherRequested",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("educationVoucherRequestDate.title"),
                  value: "migration.de.qualificationMeasure.educationVoucherRequestDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("educationVoucherReceived.title"),
                  value: "document(EDUCATIONVOUCHER).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  label: translate("educationVoucherReceiveDate.title"),
                  value: "document(EDUCATIONVOUCHER).dateOfReceipt",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("responsibleRoleEducationVoucher.title"),
                  value: "migration.de.qualificationMeasure.responsibleRoleEducationVoucher",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.ProcessRole, this.language).pipe(
                      map((staticData) => {
                        const roles = clone(getAllEducationVoucherRoles());
                        return [
                          ...staticData.filter((entry) => roles.includes(entry.value)),
                          { value: OtherRole, label: translate("responsibleRoleEducationVoucher.otherRole") },
                        ];
                      })
                    )
                  ),
                },
                {
                  label: translate("responsibleOrganization.title"),
                  value: "migration.de.qualificationMeasure.responsibleEducationVoucherOrganizationId",
                  conditions: candidateConditionFactory("choice")(of(organizations)),
                },
                {
                  label: translate("qualificationModule.title"),
                  value: "migration.de.qualificationMeasure.qualificationModules.name",
                  conditions: candidateConditionFactory("text"),
                },

                {
                  label: translate("qualificationModule.theoryHours.title"),
                  value: "migration.de.qualificationMeasure.qualificationModules.theoryHours",
                  conditions: candidateConditionFactory("number"),
                },
                {
                  label: translate("qualificationModule.practiceHours.title"),
                  value: "migration.de.qualificationMeasure.qualificationModules.practiceHours",
                  conditions: candidateConditionFactory("number"),
                },
                {
                  label: translate("areaOfDeployment.title"),
                  value: "migration.de.qualificationMeasure.qualificationModules.areaOfDeployment",
                  conditions: candidateConditionFactory("text"),
                },
              ],
            },
            {
              label: translate("complementaryMeasure.title"),
              country: SupportedImmigrationCountry.At,
              fields: [
                {
                  label: translate("responsibleRoleComplementaryMeasure.title"),
                  value: "migration.at.qualificationMeasure.responsibleRoleQualification",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.ProcessRole, this.language).pipe(
                      map((staticData) => {
                        const roles = getAllResponsibleRoles();
                        return staticData.filter((entry) => roles.includes(entry.value));
                      })
                    )
                  ),
                },
                {
                  label: translate("startOfComplementaryMeasure.title"),
                  value: "migration.at.qualificationMeasure.startOfFurtherEducation",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("endOfComplementaryMeasure.title"),
                  value: "migration.at.qualificationMeasure.endOfFurtherEducation",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("institutionName.title"),
                  value: "migration.at.qualificationMeasure.theoryInstitution",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("locationOfComplementaryMeasure.title"),
                  value: "migration.at.qualificationMeasure.locationOfComplementaryMeasure",
                  conditions: candidateConditionFactory("text"),
                },
              ],
            },
            {
              label: translate("vocationalSchool.title"),
              fields: [
                {
                  label: translate("vocationalSchoolStartDate.title"),
                  value: "migration.vocationalSchool.startDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("vocationalSchoolExpectedEndDate.title"),
                  value: "migration.vocationalSchool.expectedEndDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("vocationalSchoolSchoolName.title"),
                  value: "migration.vocationalSchool.schoolName",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("vocationalSchoolSchoolCourse.title"),
                  value: "migration.vocationalSchool.schoolCourse",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("vocationalSchoolAddress.title"),
                  value: "migration.vocationalSchool.address",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("zipCode.title"),
                  value: "migration.vocationalSchool.zipCode",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("city.title"),
                  value: "migration.vocationalSchool.city",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("federalState.title"),
                  value: "migration.vocationalSchool.federalState",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("country.title"),
                  value: "migration.vocationalSchool.country",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.Countries, this.language)
                  ),
                },
                {
                  label: translate("comment.title"),
                  value: "migration.vocationalSchool.comment",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("medicalCheckAttended.title"),
                  value: "migration.vocationalSchool.medicalCheckAttended",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("medicalCheckAttendedDate.title"),
                  value: "migration.vocationalSchool.medicalCheckAttendedDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("medicalCheckPassed.title"),
                  value: "migration.vocationalSchool.medicalCheckPassed",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("medicalCheckPassedDate.title"),
                  value: "migration.vocationalSchool.medicalCheckPassedDate",
                  conditions: candidateConditionFactory("date"),
                },
              ],
            },
            {
              label: translate("visaTransfer.title"),
              country: SupportedImmigrationCountry.De,
              fields: [
                {
                  label: translate("residencePermit.title"),
                  value: "migration.de.visa.residencePermit",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.ResidencePermits, this.language)
                  ),
                },
                {
                  label: translate("visaEnquired.title"),
                  value: "migration.de.visa.visaEnquired",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("visaEnquiredDate.title"),
                  value: "migration.de.visa.visaEnquiredDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("visaRequested.title"),
                  value: "migration.de.visa.visaRequested",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("visaRequestDate.title"),
                  value: "migration.de.visa.visaRequestDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("visaReceived.title"),
                  value: "document(VISA).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  label: translate("visaReceiveDate.title"),
                  value: "document(VISA).dateOfReceipt",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("visaValidFromDate.title"),
                  value: "document(VISA).validFrom",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("visaValidUntilDate.title"),
                  value: "document(VISA).validUntil",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("diplomaticMission.title"),
                  value: "migration.de.visa.diplomaticMissionOfGermanyInCountry",
                  conditions: candidateConditionFactory("choice")(
                    this.diplomaticMissionService.getDiplomaticMissionsAbroad(SupportedImmigrationCountry.De)
                  ),
                },
                {
                  label: translate("flightDate.title"),
                  value: "migration.de.visa.flightDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("arrivalDate.title"),
                  value: "migration.de.visa.arrivalDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("fileNumberVisa.title"),
                  value: "migration.de.visa.fileNumber",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("relocationRequirements.title"),
                  value: "migration.de.visa.visaRelocationRequirements",
                  conditions: candidateConditionFactory("choice")(
                    this.getVisaRelocationRequirementsLanguageLevels(this.language)
                  ),
                },
              ],
            },
            {
              country: SupportedImmigrationCountry.At,
              label: translate("rwrCard.title"),
              fields: [
                {
                  label: translate("rwrRequested.title"),
                  value: "migration.at.visa.visaEnquired",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("rwrRequestedDate.title"),
                  value: "migration.at.visa.visaEnquiredDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("rwrCardReceived.title"),
                  value: "migration.at.visa.visaReceived",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("rwrCardReceivedDate.title"),
                  value: "migration.at.visa.visaReceiveDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("rwrCardValidFromDate.title"),
                  value: "migration.at.visa.visaValidFromDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("rwrCardValidUntilDate.title"),
                  value: "migration.at.visa.visaValidUntilDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("rwrCardFileNumber.title"),
                  value: "migration.at.visa.fileNumber",
                  conditions: candidateConditionFactory("text"),
                },
              ],
            },
            {
              country: SupportedImmigrationCountry.De,
              label: translate("qualificationEvaluation.title"),
              fields: [
                {
                  label: translate("noQualificationEvaluation.title"),
                  value: "migration.de.qualificationEvaluation.noQualificationEvaluation",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("responsibleRoleQualificationEvaluation.title"),
                  value: "migration.de.qualificationEvaluation.responsibleRoleQualificationEvaluation",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.ProcessRole, this.language).pipe(
                      map((staticData) => {
                        const roles = getAllResponsibleRoles();
                        return staticData.filter((entry) => roles.includes(entry.value));
                      })
                    )
                  ),
                },
                {
                  label: translate("responsibleRoleQualificationEvaluationPayment.title"),
                  value: "migration.de.qualificationEvaluation.responsibleRoleQualificationEvaluationPayment",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.ProcessRole, this.language).pipe(
                      map((staticData) => {
                        const roles = getAllQualificationEvaluationPaymentRoles();
                        return staticData.filter((entry) => roles.includes(entry.value));
                      })
                    )
                  ),
                },
                {
                  label: translate("responsibleQualificationEvaluationOrganization.title"),
                  value: "migration.de.qualificationEvaluation.responsibleQualificationEvaluationOrganizationId",
                  conditions: candidateConditionFactory("choice")(of(organizations)),
                },
                {
                  label: translate("evaluatedQualification.title"),
                  value: "migration.de.qualificationEvaluation.qualificationEvaluations.evaluatedQualification",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("responsibleAuthorityForQualification.title"),
                  value:
                    "migration.de.qualificationEvaluation.qualificationEvaluations.responsibleAuthorityForQualification",
                  conditions: candidateConditionFactory("choice")(
                    this.certificateValuationAuthorityService.getCertificateValuationAuthorities(
                      SupportedImmigrationCountry.De
                    )
                  ),
                },
                {
                  label: translate("responsibleAuthorityForQualificationInOrigin.title"),
                  value:
                    "migration.de.qualificationEvaluation.qualificationEvaluations.responsibleAuthorityForQualificationInOrigin",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("federalState.title"),
                  value: "migration.de.qualificationEvaluation.qualificationEvaluations.federalState",
                  conditions: candidateConditionFactory("choice")(
                    this.getStaticDataCountryGroups(StaticDataType.FederalStates, countries)
                  ),
                },
                {
                  label: translate("qualificationEvaluationRequested.title"),
                  value: "document(APPVALEDU).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  label: translate("qualificationEvaluationRequestedDate.title"),
                  value: "document(APPVALEDU).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("qualificationEvaluationNoticeKind.title"),
                  value: "migration.de.qualificationEvaluation.qualificationEvaluations.evaluationNoticeKind",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(
                      StaticDataType.QualificationEvaluationNoticeKind,
                      this.language
                    )
                  ),
                },
                {
                  label: translate("qualificationEvaluationByCentralDepartmentReceived.title"),
                  value: "document(CERTZAB).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  label: translate("qualificationEvaluationByCentralDepartmentReceiveDate.title"),
                  value: "document(CERTZAB).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("qualificationEvaluationByFederalStateAuthorityReceived.title"),
                  value: "document(CERTLAB).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  label: translate("qualificationEvaluationByFederalStateAuthorityReceiveDate.title"),
                  value: "document(CERTLAB).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("qualificationEvaluationRejectionReceived.title"),
                  value: "document(REJECTCERTAB).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  label: translate("qualificationEvaluationRejectionReceiveDate.title"),
                  value: "document(REJECTCERTAB).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("qualificationEvaluationFileNumber.title"),
                  value: "migration.de.qualificationEvaluation.qualificationEvaluations.fileNumber",
                  conditions: candidateConditionFactory("text"),
                },
              ],
            },
            {
              label: translate("residence.title"),
              fields: [
                {
                  label: translate("responsibleRoleResidence.title"),
                  value: "migration.residence.responsibleRoleResidence",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.ProcessRole, this.language).pipe(
                      map((staticData) => {
                        const roles = getAllResponsibleRoles();
                        return staticData.filter((entry) => roles.includes(entry.value));
                      })
                    )
                  ),
                },
                {
                  label: translate("workStartDate.title"),
                  value: "migration.residence.workStartDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("registrationOfficeDate.title"),
                  value: "migration.residence.registrationOfficeDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("appointmentHealthDepartmentDate.title"),
                  value: "migration.de.residence.appointmentHealthDepartmentDate.date",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("appointmentHealthDepartmentKept.title"),
                  value: "migration.de.residence.appointmentHealthDepartmentKept",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("medicalExaminationAppointmentDate.title"),
                  value: "migration.de.residence.medicalExaminationAppointmentDate.date",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("companyDoctorAppointmentDate.title"),
                  value: "migration.de.residence.companyDoctorAppointmentDate.date",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("policeRecordAppointmentDate.title"),
                  value: "migration.de.residence.policeRecordAppointmentDate.date",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("bankAppointmentDate.title"),
                  value: "migration.de.residence.bankAppointmentDate.date",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("immigrationAuthority.title"),
                  value: "migration.de.residence.residencePermits.immigrationAuthority",
                  conditions: candidateConditionFactory("choice")(
                    this.immigrationAuthorityService.getImmigrationAuthorities(
                      this.settings.organizationId,
                      SupportedImmigrationCountry.De
                    )
                  ),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("residencePermitIssueDate.title"),
                  value: "document(RESIDENCEPERMIT).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("residencePermitIssued.title"),
                  value: "migration.de.residence.residencePermits.residencePermit",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.ResidencePermits, this.language)
                  ),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("residencePermit.title"),
                  value: "document(RESIDENCEPERMIT).type",
                  conditions: candidateConditionFactory("exists"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("residencePermitValidFromDate.title"),
                  value: "document(RESIDENCEPERMIT).validFrom",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("residencePermitValidUntilDate.title"),
                  value: "document(RESIDENCEPERMIT).validUntil",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("residenceRequestedElectronicallyDate.title"),
                  value: "migration.de.residence.residencePermits.residenceRequestedElectronicallyDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("residenceRequestedByPostDate.title"),
                  value: "migration.de.residence.residencePermits.residenceRequestedByPostDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("fictionalCertificateAvailable.title"),
                  value: "migration.de.residence.residencePermits.fictionalCertificateAvailable",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("fictionalCertificateDoesNotAuthorisesWorking.title"),
                  value: "migration.de.residence.residencePermits.fictionalCertificateDoesNotAuthorisesWorking",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("fictionalCertificateValidUntilDate.title"),
                  value: "document(FICTIONALCERT).validUntil",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  country: SupportedImmigrationCountry.De,
                  label: translate("fictionalCertificateNumber.title"),
                  value: "migration.de.residence.residencePermits.fictionalCertificateNumber",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  country: SupportedImmigrationCountry.At,
                  label: translate("plannedEntryDate.title"),
                  value: "migration.at.residence.plannedEntryDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  country: SupportedImmigrationCountry.At,
                  label: translate("fingerPrintDate.title"),
                  value: "migration.at.residence.fingerPrintDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  country: SupportedImmigrationCountry.At,
                  label: translate("pickUpDetails.title"),
                  value: "migration.at.residence.pickUpDetails",
                  conditions: candidateConditionFactory("text"),
                },
              ],
            },
            {
              label: translate("housingAcquisition.title"),
              fields: [
                {
                  label: translate("responsibleRoleHousingAcquisition.title"),
                  value: "migration.housingAcquisition.responsibleRoleHousingAcquisition",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.ProcessRole, this.language).pipe(
                      map((staticData) => {
                        const roles = clone(getAllHousingAcquisitionRoles());
                        return [
                          ...staticData.filter((entry) => roles.includes(entry.value)),
                          { value: OtherRole, label: translate("responsibleRoleScholarship.otherRole") },
                        ];
                      })
                    )
                  ),
                },
                {
                  label: translate("responsibleOrganization.title"),
                  value: "migration.housingAcquisition.responsibleOrganizationId",
                  conditions: candidateConditionFactory("choice")(of(organizations)),
                },
                {
                  label: translate("housing.name"),
                  value: "migration.housingAcquisition.housings.name",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("housing.contractSignDate.title"),
                  value: "document(RENTALAGREEMENT).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("housing.cancellationOfContractSignDate.title"),
                  value: "migration.housingAcquisition.housings.cancellationOfContractSignDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("housing.readinessForOccupancyDate.title"),
                  value: "migration.housingAcquisition.housings.readinessForOccupancyDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("housing.movingInDate.title"),
                  value: "migration.housingAcquisition.housings.movingInDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("housing.movingOutDate.title"),
                  value: "migration.housingAcquisition.housings.movingOutDate",
                  conditions: candidateConditionFactory("date"),
                },
              ],
            },
            //Additional Profile Fields: Language Skills
            {
              label: translate("languageSkills.title"),
              fields: [
                {
                  label: translate("examCertificateDate.title"),
                  value:
                    "document(LANGCERT|LANGCERTGERA1|LANGCERTGERA2|LANGCERTGERB1|LANGCERTGERB2|LANGCERTGERC1|LANGCERTGERC2).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
              ],
            },
            //Additional Profile Fields: vaccines
            {
              label: translate("vaccinations.title"),
              fields: [
                {
                  label: translate("vaccinations.lastShot.title"),
                  value: "document(CERTOFVACC).issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
              ],
            },
            {
              country: SupportedImmigrationCountry.At,
              label: translate("healthProfessionsRegister.title"),
              fields: [
                {
                  label: translate("healthProfessionsRegisterAppointmentDate.title"),
                  value: "migration.at.healthProfessionsRegister.appointmentDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("healthProfessionsRegisterDate.title"),
                  value: "migration.at.healthProfessionsRegister.registrationDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("healthProfessionsRegisterSubmission.title"),
                  value: "migration.at.healthProfessionsRegister.submission",
                  conditions: candidateConditionFactory("text"),
                },
              ],
            },
            {
              label: translate("candidate.internal"),
              fields: [
                {
                  label: translate("costCenter.title"),
                  value: "internal.costCenter",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("isReplacementForCandidate.title"),
                  value: "internal.isReplacementForCandidate",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("partner.languageSchool"),
                  value: "internal.languageSchooldId",
                  conditions: candidateConditionFactory("choice")(of(organizations)),
                },
                {
                  label: translate("partner.source"),
                  value: "internal.sourceOrganizationId",
                  conditions: candidateConditionFactory("choice")(of(organizations)),
                },
                {
                  label: translate("category.title"),
                  value: "internal.category",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.Category, this.language)
                  ),
                },
                {
                  label: translate("candidate.notesFull"),
                  value: "internal.notes",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("candidate.scholarship.startDate"),
                  value: "internal.scholarships.startDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("candidate.scholarship.endDate"),
                  value: "internal.scholarships.endDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("candidate.scholarship.amountOfMoney.title"),
                  value: "internal.scholarships.amountOfMoney",
                  conditions: candidateConditionFactory("number"),
                },
                {
                  label: translate("candidate.scholarship.awardFrequency.title"),
                  value: "internal.scholarships.awardFrequency",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.AwardFrequency, this.language)
                  ),
                },
                {
                  label: translate("responsibleRoleScholarship.title"),
                  value: "internal.scholarships.responsibleRoleScholarship",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.ProcessRole, this.language).pipe(
                      map((staticData) => {
                        const roles = clone(getAllScholarshipRoles());
                        return [
                          ...staticData.filter((entry) => roles.includes(entry.value)),
                          { value: OtherRole, label: translate("responsibleRoleScholarship.otherRole") },
                        ];
                      })
                    )
                  ),
                },
                {
                  label: translate("candidate.responsibleEmployees.title"),
                  value: "internal.responsibleEmployees",
                  conditions: candidateConditionFactory("multichoice")(
                    this.usersService.getUsersWithRoles(this.settings.organizationId, responsibleEmployeeRoles).pipe(
                      map((result) =>
                        result.map((user) => ({
                          value: user.id,
                          label: `${user.lastname}, ${user.firstname}`,
                        }))
                      )
                    )
                  ),
                },
                {
                  label: translate("hr.personnelNumber.title"),
                  value: "internal.hr.personnelNumber",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("hr.personnelArea.title"),
                  value: "internal.hr.personnelArea",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("hr.terminationEmploymentActor.title"),
                  value: "internal.hr.terminationEmploymentActor",
                  conditions: candidateConditionFactory("choice")(
                    of([
                      {
                        label: translate("enum.TerminationEmploymentActor.Employee"),
                        value: TerminationEmploymentActor.Employee,
                      },
                      {
                        label: translate("enum.TerminationEmploymentActor.Employer"),
                        value: TerminationEmploymentActor.Employer,
                      },
                    ])
                  ),
                },
                {
                  label: translate("hr.terminationEmploymentDate.title"),
                  value: "internal.hr.terminationEmploymentDate",
                  conditions: candidateConditionFactory("date"),
                },
              ],
            },
            {
              label: translate("candidate.onboarding"),
              fields: [
                {
                  label: translate("profileDataEditStatus.title"),
                  value: "governance.profileDataEditStatus",
                  conditions: candidateConditionFactory("choice")(
                    of([
                      {
                        label: translate("enum.ProfileDataEditStatus.Default"),
                        value: ProfileDataEditStatus.Default,
                      },
                      {
                        label: translate("enum.ProfileDataEditStatus.ReadOnly"),
                        value: ProfileDataEditStatus.ReadOnly,
                      },
                      {
                        label: translate("enum.ProfileDataEditStatus.Editable"),
                        value: ProfileDataEditStatus.Editable,
                      },
                    ])
                  ),
                },
                {
                  label: translate("matching.title"),
                  value: "governance.matching",
                  conditions: candidateConditionFactory("choice")(
                    of([
                      { label: translate("enum.MatchingStatus.Enabled"), value: MatchingStatus.Enabled },
                      { label: translate("enum.MatchingStatus.Viewable"), value: MatchingStatus.Viewable },
                      { label: translate("enum.MatchingStatus.Disabled"), value: MatchingStatus.Disabled },
                    ])
                  ),
                },
                {
                  label: translate("invite.title"),
                  value: "systemOnboarding.invitedToSystem",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("invite.at"),
                  value: "systemOnboarding.invitedToSystemDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("invite.blocked"),
                  value: "systemOnboarding.isBlocked",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("messagesReception.filter"),
                  value: "messagesReception.enabled",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("consent.accepted"),
                  value: "systemOnboarding.termsAndConditionsAccepted",
                  conditions: candidateConditionFactory("boolean"),
                },
                {
                  label: translate("consent.at"),
                  value: "systemOnboarding.termsAndConditionsAcceptedDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("common.createdAt"),
                  value: "creationDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("common.createdThrough"),
                  value: "technicalSource",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.transformEnumToStaticDataModelAsObservable(
                      "TechnicalSourceEnum",
                      TechnicalSourceEnum
                    )
                  ),
                },
              ],
            },
            {
              label: translate("experience.title"),
              fields: [
                {
                  label: translate("experience.total"),
                  value: "os.profile.totalExperienceInMonth",
                  conditions: candidateConditionFactory("number"),
                },
              ],
            },
            {
              label: translate("collection.title"),
              fields: [
                {
                  label: translate("collection.hasEntries"),
                  value: "collections",
                  conditions: candidateConditionFactory("hasEntries"),
                },
                {
                  label: translate("name.title"),
                  value: "collectionName",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("organization.title"),
                  value: "collectionOrganization",
                  conditions: candidateConditionFactory("choice")(of(organizations)),
                },
              ],
            },
            {
              label: translate("document.titleFilter"),
              fields: [
                {
                  label: translate("collection.hasEntries"),
                  value: "documents",
                  conditions: candidateConditionFactory("hasEntries"),
                },
                {
                  label: translate("fileType.title"),
                  value: "document.type",
                  conditions: candidateConditionFactory("document")(
                    this.getStaticDataCountryGroups(StaticDataType.AllowedUploadFileTypes, countries)
                  ),
                },
                {
                  label: translate("documentFormat.title"),
                  value: "document.tags",
                  conditions: candidateConditionFactory("multichoice")(
                    this.staticDataService.getStaticData(StaticDataType.DocumentFormats, this.language)
                  ),
                },
                {
                  label: translate("changedBy.title"),
                  value: "document.changedBy",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("changedAt.title"),
                  value: "document.changedAt",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("validUntil.title"),
                  value: "document.validUntil",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("validFrom.title"),
                  value: "document.validFrom",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("issueDate.title"),
                  value: "document.issueDate",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("dateOfReceipt.title"),
                  value: "document.dateOfReceipt",
                  conditions: candidateConditionFactory("datearray"),
                },
                {
                  label: translate("resubmissionDate.title"),
                  value: "document.resubmissionDate",
                  conditions: candidateConditionFactory("datearray"),
                },
              ],
            },
            ...(ENABLE_EDUCATION
              ? [
                  {
                    label: translate("competencies.title"),
                    fields: [
                      {
                        label: translate("knowledgesFilter.type"),
                        value: "os.profile.competencies.knowledges.skillId",
                        conditions: candidateConditionFactory("lazyChoice")({
                          getFilteredOptions: (query) =>
                            this.searchOccupationSkillsAsStaticDataModel(query, OccupationSkillType.Knowledge),
                          getOptionsByValue: (ids) =>
                            this.findOccupationSkillsAsStaticDataModel(ids, OccupationSkillType.Knowledge),
                        }),
                      },
                      {
                        label: translate("knowledgesFilter.value"),
                        value: "os.profile.competencies.knowledges.proficiency",
                        conditions: candidateConditionFactory("choice")(
                          of(
                            this.staticDataService.transformEnumToStaticDataModel(
                              "KnowledgeProficiency",
                              KnowledgeProficiency
                            )
                          )
                        ),
                      },
                      {
                        label: translate("skillsFilter.type"),
                        value: "os.profile.competencies.skills.skillId",
                        conditions: candidateConditionFactory("lazyChoice")({
                          getFilteredOptions: (query) =>
                            this.searchOccupationSkillsAsStaticDataModel(query, OccupationSkillType.SkillCompetence),
                          getOptionsByValue: (ids) =>
                            this.findOccupationSkillsAsStaticDataModel(ids, OccupationSkillType.SkillCompetence),
                        }),
                      },
                      {
                        label: translate("skillsFilter.value"),
                        value: "os.profile.competencies.skills.proficiency",
                        conditions: candidateConditionFactory("choice")(
                          of(
                            this.staticDataService.transformEnumToStaticDataModel(
                              "YearsOfSkillExperience",
                              YearsOfSkillExperience
                            )
                          )
                        ),
                      },
                      {
                        label: translate("customKnowledgesFilter.type", {
                          organizationName: this.settings.organizationName,
                        }),
                        value: "os.profile.competencies.customKnowledges.skillId",
                        conditions: candidateConditionFactory("choice")(
                          this.professionalExperienceService
                            .getByType({
                              organizationId: this.settings.organizationId,
                              type: OccupationSkillType.Knowledge,
                            })
                            .pipe(
                              map((professionExperiences) =>
                                professionExperiences.map((professionExperience) => ({
                                  label: professionExperience.localizedName,
                                  value: professionExperience.id,
                                }))
                              )
                            )
                        ),
                      },
                      {
                        label: translate("customKnowledgesFilter.value", {
                          organizationName: this.settings.organizationName,
                        }),
                        value: "os.profile.competencies.customKnowledges.proficiency",
                        conditions: candidateConditionFactory("choice")(
                          of(
                            this.staticDataService.transformEnumToStaticDataModel(
                              "KnowledgeProficiency",
                              KnowledgeProficiency
                            )
                          )
                        ),
                      },
                      {
                        label: translate("customSkillsFilter.type", {
                          organizationName: this.settings.organizationName,
                        }),
                        value: "os.profile.competencies.customSkills.skillId",
                        conditions: candidateConditionFactory("choice")(
                          this.professionalExperienceService
                            .getByType({
                              organizationId: this.settings.organizationId,
                              type: OccupationSkillType.SkillCompetence,
                            })
                            .pipe(
                              map((professionExperiences) =>
                                professionExperiences.map((professionExperience) => ({
                                  label: professionExperience.localizedName,
                                  value: professionExperience.id,
                                }))
                              )
                            )
                        ),
                      },
                      {
                        label: translate("customSkillsFilter.value", {
                          organizationName: this.settings.organizationName,
                        }),
                        value: "os.profile.competencies.customSkills.proficiency",
                        conditions: candidateConditionFactory("choice")(
                          of(
                            this.staticDataService.transformEnumToStaticDataModel(
                              "YearsOfSkillExperience",
                              YearsOfSkillExperience
                            )
                          )
                        ),
                      },
                    ],
                  },

                  {
                    label: translate("education.title"),
                    fields: [
                      {
                        label: translate("educationFilter.moduleName"),
                        value: "education(module).name",
                        conditions: candidateConditionFactory("text"),
                      },
                      {
                        label: translate("educationFilter.courseName"),
                        value: "education(course).name",
                        conditions: candidateConditionFactory("text"),
                      },
                      {
                        label: translate("educationFilter.courseStatus"),
                        value: "education(totalPerformance).candidateState",
                        conditions: candidateConditionFactory("choice")(
                          of(
                            this.staticDataService.transformEnumToStaticDataModel(
                              "EducationCourseCandidateStatus",
                              EducationCourseCandidateStatus
                            )
                          )
                        ),
                      },
                      {
                        label: translate("educationFilter.courseAttendance"),
                        value: "education(weeklyPerformance).attendance",
                        conditions: candidateConditionFactory("choice")(
                          of(
                            this.staticDataService.transformEnumToStaticDataModel(
                              "EducationCourseAttendance",
                              EducationCourseAttendance
                            )
                          )
                        ),
                      },
                      {
                        label: translate("educationFilter.coursePerformance"),
                        value: "education(weeklyPerformance).performance",
                        conditions: candidateConditionFactory("choice")(
                          of(
                            this.staticDataService.transformEnumToStaticDataModel(
                              "EducationCoursePerformanceStatus",
                              EducationCoursePerformanceStatus
                            )
                          )
                        ),
                      },
                      {
                        label: translate("educationFilter.examName"),
                        value: "education(exam).name",
                        conditions: candidateConditionFactory("text"),
                      },
                      {
                        label: translate("educationFilter.examResult"),
                        value: "education(examResult).examResult",
                        conditions: candidateConditionFactory("choice")(
                          this.staticDataService.getStaticData(StaticDataType.ExamResultState, this.language)
                        ),
                      },
                      {
                        label: translate("educationFilter.passedExamModules"),
                        value: "education(examResult).passedExamModules",
                        conditions: candidateConditionFactory("multichoice")(
                          of(this.staticDataService.transformEnumToStaticDataModel("ExamModuleType", ExamModuleType))
                        ),
                      },
                      {
                        label: translate("educationFilter.examLanguageLevel"),
                        value: "education(exam).languageLevel",
                        conditions: candidateConditionFactory("choice")(
                          this.staticDataService.getStaticData(StaticDataType.LanguageModules, this.language)
                        ),
                      },
                    ],
                  },
                ]
              : ([] as any[])),
            {
              label: translate("note.title"),
              fields: [
                {
                  label: translate("collection.hasEntries"),
                  value: "notes",
                  conditions: candidateConditionFactory("hasEntries"),
                },
                {
                  label: translate("contents.title"),
                  value: "noteContents",
                  conditions: candidateConditionFactory("text"),
                },
              ],
            },
            {
              label: translate("candidate.interviewsFeedback.title"),
              fields: [
                {
                  label: translate("candidate.interviewsFeedback.employer"),
                  value: "interviewsFeedback.employerId",
                  conditions: candidateConditionFactory("choice")(of(organizations)),
                },
                {
                  label: translate("candidate.interviewsFeedback.comments"),
                  value: "interviewsFeedback.comment",
                  conditions: candidateConditionFactory("text"),
                },
                {
                  label: translate("candidate.interviewsFeedback.interviewDate"),
                  value: "interviewsFeedback.interviewDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("candidate.interviewsFeedback.interviewerFeedback"),
                  value: "interviewsFeedback.interviewerFeedback",
                  conditions: candidateConditionFactory("choice")(
                    of([
                      {
                        label: translate("enum.VoteType.Positive"),
                        value: VoteType.Positive,
                      },
                      {
                        label: translate("enum.VoteType.Neutral"),
                        value: VoteType.Neutral,
                      },
                      {
                        label: translate("enum.VoteType.Negative"),
                        value: VoteType.Negative,
                      },
                    ])
                  ),
                },
                {
                  label: translate("candidate.interviewsFeedback.candidateFeedback"),
                  value: "interviewsFeedback.candidateFeedback",
                  conditions: candidateConditionFactory("choice")(
                    of([
                      {
                        label: translate("enum.VoteType.Positive"),
                        value: VoteType.Positive,
                      },
                      {
                        label: translate("enum.VoteType.Neutral"),
                        value: VoteType.Neutral,
                      },
                      {
                        label: translate("enum.VoteType.Negative"),
                        value: VoteType.Negative,
                      },
                    ])
                  ),
                },
              ],
            },
            {
              label: translate("feedback.title"),
              fields: [
                {
                  label: translate("collection.hasEntries"),
                  value: "feedbacks",
                  conditions: candidateConditionFactory("hasEntries"),
                },
                {
                  label: translate("feedback.statusPhaseOne"),
                  value: "feedbackStatusPhaseOne",
                  conditions: candidateConditionFactory("choice")(
                    of([
                      {
                        label: translate("enum.FeedbackStatusPhaseOne.Rejected"),
                        value: FeedbackStatusPhaseOne.Rejected,
                      },
                      {
                        label: translate("enum.FeedbackStatusPhaseOne.Backlogged"),
                        value: FeedbackStatusPhaseOne.Backlogged,
                      },
                      {
                        label: translate("enum.FeedbackStatusPhaseOne.InterviewRequested"),
                        value: FeedbackStatusPhaseOne.InterviewRequested,
                      },
                    ])
                  ),
                },
                {
                  label: translate("feedback.statusPhaseTwo"),
                  value: "feedbackStatusPhaseTwo",
                  conditions: candidateConditionFactory("choice")(
                    of([
                      {
                        label: translate("enum.FeedbackStatusPhaseTwo.Rejected"),
                        value: FeedbackStatusPhaseTwo.Rejected,
                      },
                      {
                        label: translate("enum.FeedbackStatusPhaseTwo.Selected"),
                        value: FeedbackStatusPhaseTwo.Selected,
                      },
                    ])
                  ),
                },
                {
                  label: translate("organization.title"),
                  value: "feedbackOrganization",
                  conditions: candidateConditionFactory("choice")(of(organizations)),
                },
              ],
            },
            {
              label: translate("application.title"),
              fields: [
                {
                  label: translate("application.hasEntries"),
                  value: "employerApplications",
                  conditions: candidateConditionFactory("hasEntries"),
                },
                {
                  label: translate("organization.title"),
                  value: "employerApplicationOrganization",
                  conditions: candidateConditionFactory("choice")(of(organizations)),
                },
              ],
            },
            {
              label: translate("processStatus.title"),
              fields: [
                {
                  label: translate("estimatedArrivalDate.title"),
                  value: "processStatus.estimatedArrivalDate",
                  conditions: candidateConditionFactory("date"),
                },
                {
                  label: translate("estimatedWorkStartDate.title"),
                  value: "processStatus.estimatedWorkStartDate",
                  conditions: candidateConditionFactory("date"),
                },
              ],
            },
            {
              label: translate("masterData.title"),
              fields: [
                {
                  label: translate("occupation.title"),
                  value: "profession",
                  conditions: candidateConditionFactory("profession")(
                    this.staticDataService.getStaticData(StaticDataType.Profession, this.language)
                  ),
                },
                {
                  label: translate("function.title"),
                  value: "function",
                  conditions: candidateConditionFactory("choice")(
                    this.staticDataService.getStaticData(StaticDataType.Functions, this.language)
                  ),
                },
              ],
            }
          )
      )
    );
  }

  newCandidate(): Partial<Candidate> {
    return {
      status: CandidateStatus.New,
      os: {
        profile: {
          addresses: [
            {
              id: uuidv4(),
              addressType: [AddressType.IdAddress, AddressType.CountryOfOriginAddress, AddressType.CurrentAddress],
            },
          ],
        },
      },
      governance: {
        matching: MatchingStatus.Disabled,
        profileDataEditStatus: ProfileDataEditStatus.Default,
      },
      systemOnboarding: {},
      organizationId: this.settings.organizationId,
    };
  }

  getCandidateImporterTemplateUrl(): string {
    return null;
  }

  getCandidateEmailAddress(candidate: Candidate): string {
    return candidate.os?.profile?.email;
  }

  private getShippingInformationForm(language: string): Observable<FormlyFieldConfig[]> {
    return of(shippingInformationFormFactory({ formatter: this.formatter }, language));
  }

  private getMatchingAgreementForm(
    task: ProcessTaskInformationCollection | null,
    language: string
  ): Observable<FormlyFieldConfig[]> {
    if (task === null) {
      return of(matchingAgreementFormFactory(null, language, this.staticDataService));
    }

    return this.processService.getProcess(task.rootId, task.organizationId).pipe(
      switchMap((process) => {
        const employerId = process.roleOrganisationUsergroupMapping.find(
          (x) => x.processRole === "Employer"
        ).processOrganizationId;
        return forkJoin([
          this.organizationService.getOrganization(employerId),
          // Here we want all locations not just the ones which fit the candidate criteria
          this.employerService.get(employerId, null, task.candidateOrganizationId),
          this.contractTemplateService.getAllForList(employerId).pipe(take(1)),
        ]).pipe(
          map(([employer, employerProfile, contractTemplates]) => ({
            task: task,
            employer: employer,
            employerProfile: employerProfile,
            organizationSpecifics: this,
            contractTemplates: contractTemplates,
            contractTemplateService: this.contractTemplateService,
            formatter: this.formatter,
          })),
          map((context) => matchingAgreementFormFactory(context, language, this.staticDataService))
        );
      })
    );
  }

  private getStaticData(
    staticDataType: StaticDataType,
    immigrationCountry: ExistingImmigrationCountry
  ): Observable<StaticDataModel[]> {
    const context = { immigrationCountry, organizationId: this.settings.organizationId };
    return this.staticDataService.getStaticData(staticDataType, this.language, context);
  }

  private getStaticDataCountryGroups(
    type: StaticDataType,
    countries: StaticDataCountry[]
  ): Observable<StaticDataCountryGroup[]> {
    const observables = countries.map((country) =>
      this.getStaticData(type, country.value).pipe(map((values) => ({ ...country, items: values })))
    );

    return safeForkJoin(observables, []).pipe(map((x) => x.flat()));
  }

  private getRecognitionAuthorities(countries: StaticDataCountry[]): Observable<StaticDataCountryGroup[]> {
    const observables = countries.map((country) =>
      this.recognitionAuthorityService
        .getRecognitionAuthorities(this.settings.organizationId, country.value)
        .pipe(map((values) => ({ ...country, items: values })))
    );

    return safeForkJoin(observables, []).pipe(map((x) => x.flat()));
  }

  private getTravelBookingForm(language: string): Observable<FormlyFieldConfig[]> {
    return of(travelBookingFormFactory(language, this.staticDataService));
  }

  private getQualificationEvaluationConfigurationForm(language: string): Observable<FormlyFieldConfig[]> {
    return of(
      qualificationEvaluationConfigurationFormFactory(
        language,
        this.staticDataService,
        this.certificateValuationAuthorityService,
        { immigrationCountry: SupportedImmigrationCountry.De }
      )
    );
  }

  private getQualificationEvaluationReceivedForm(language: string): Observable<FormlyFieldConfig[]> {
    return of(qualificationEvaluationReceivedFormFactory(language));
  }

  private getQualificationEvaluationRequestForm(language: string): Observable<FormlyFieldConfig[]> {
    return of(qualificationEvaluationRequestFormFactory(language));
  }

  private getCandidateAllowanceForm(language: string): Observable<FormlyFieldConfig[]> {
    return of(candidateAllowanceFormFactory({}, language));
  }

  private getSubmissionDateForm(language: string): Observable<FormlyFieldConfig[]> {
    return of(submissionDateFormFactory({}, language));
  }

  private getInterviewAppointmentProposalForm(language: string): Observable<FormlyFieldConfig[]> {
    return of(interviewAppointmentProposalFormFactory({}, language));
  }

  private getInterviewAppointmentConfirmationForm(language: string): Observable<FormlyFieldConfig[]> {
    return of(interviewAppointmentConfirmationFormFactory({}, language));
  }

  private getInterviewAppointmentScheduleForm(language: string): Observable<FormlyFieldConfig[]> {
    return of(interviewAppointmentScheduleFormFactory({ formatter: this.formatter }, language));
  }

  // TODO @anlampe this is now for de only

  getCustomCandidateFields(): Observable<CandidateProfileFieldGroup[]> {
    return forkJoin([
      this.organizationService
        .getLinkedOrganizations(this.settings.organizationId, true)
        .pipe(map((xs) => xs.map((x) => ({ label: x.name, value: x.id })))),
      this.contractTemplateService.getAllForList(this.settings.organizationId).pipe(
        take(1),
        map((xs) => xs.map((x) => ({ label: x.name, value: x.id })))
      ),
      this.staticDataService.getStaticData(StaticDataType.Benefits, this.language),
      this.staticDataService.getStaticData(StaticDataType.SupportedImmigrationCountries, this.language),
      this.staticDataService.getStaticData(StaticDataType.ProcessRole, this.language).pipe(
        map((staticData) => {
          const roles = getAllRecognitionPaymentRoles();
          return staticData.filter((entry) => roles.includes(entry.value));
        })
      ),
      this.staticDataService.getStaticData(StaticDataType.RecognitionNoticeKind, this.language),
      this.staticDataService.getStaticData(StaticDataType.EquivalenceTest, this.language),
      this.staticDataService.getStaticData(StaticDataType.RecognitionType, this.language),
      this.staticDataService.getStaticData(StaticDataType.ResidencePermits, this.language),
    ]).pipe(
      map(
        ([
          organizationIds,
          contracts,
          benefits,
          supportedImmigrationCountries,
          recognitionPaymentRoles,
          recognitionNoticeKinds,
          equivalenceTests,
          recognitionTypes,
          residencePermits,
        ]) => {
          return [
            {
              label: translate("masterData.title"),
              fields: [
                {
                  label: translate("status.title"),
                  path: "status",
                  type: "select",
                  options: this.staticDataService.transformEnumToStaticDataModel("CandidateStatus", CandidateStatus),
                  enumType: "CandidateStatus",
                } as CandidateProfileField,
                {
                  label: translate("candidateDeletion.filter"),
                  path: "candidatesDeletion",
                  type: "boolean",
                } as CandidateProfileField,
                {
                  label: translate("immigrationCountry.title"),
                  path: "immigrationCountry",
                  type: "select",
                  options: supportedImmigrationCountries,
                  staticDataType: StaticDataType.SupportedImmigrationCountries,
                } as CandidateProfileField,
              ],
            },
            {
              label: translate("governance.title"),
              fields: [
                {
                  label: translate("enum.MatchingStatus.title"),
                  path: "governance.matching",
                  type: "select",
                  options: this.staticDataService.transformEnumToStaticDataModel("MatchingStatus", MatchingStatus),
                  enumType: "MatchingStatus",
                } as CandidateProfileField,
              ],
            },
            {
              label: translate("employer.title"),
              fields: [
                {
                  label: translate("employer.title"),
                  path: "migration.de.employmentRelationship.afterRecognition.employerId",
                  type: "select",
                  options: organizationIds,
                } as CandidateProfileField,
                {
                  label: translate("employer.name"),
                  path: "migration.de.employmentRelationship.afterRecognition.employerName",
                  type: "select",
                  options: organizationIds.map((x) => ({ label: x.label, value: x.label })),
                } as CandidateProfileField,
                {
                  label: translate("contractTemplate.title"),
                  path: "migration.de.employmentRelationship.afterRecognition.contractTemplateId",
                  type: "select",
                  options: contracts,
                } as CandidateProfileField,
                {
                  label: translate("contractTemplate.remunerationCalculationBasis.title"),
                  path: "migration.de.employmentRelationship.afterRecognition.contractTemplate.remunerationCalculationBasis",
                  type: "select",
                  options: this.staticDataService.transformEnumToStaticDataModel(
                    "RemunerationCalculationBasis",
                    RemunerationCalculationBasis
                  ),
                  enumType: "RemunerationCalculationBasis",
                } as CandidateProfileField,
                {
                  label: translate("contractTemplate.paymentPerMonth"),
                  path: "migration.de.employmentRelationship.afterRecognition.contractTemplate.paymentPerMonth",
                  type: "float",
                } as CandidateProfileField,
                {
                  label: translate("contractTemplate.holidayEntitlement"),
                  path: "migration.de.employmentRelationship.afterRecognition.contractTemplate.holidayEntitlement",
                  type: "float",
                } as CandidateProfileField,
                {
                  label: translate("workingHoursPerWeek.title"),
                  path: "migration.de.employmentRelationship.afterRecognition.contractTemplate.workingHoursPerWeek",
                  type: "float",
                } as CandidateProfileField,
                {
                  label: translate("contractTemplate.employmentRelationshipType.title"),
                  path: "migration.de.employmentRelationship.afterRecognition.contractTemplate.employmentRelationshipType",
                  type: "select",
                  options: this.staticDataService.transformEnumToStaticDataModel(
                    "EmploymentRelationshipType",
                    EmploymentRelationshipType
                  ),
                  enumType: "EmploymentRelationshipType",
                } as CandidateProfileField,
                {
                  label: translate("contractTemplate.otherPecuniaryBenefits.title"),
                  path: "migration.de.employmentRelationship.afterRecognition.contractTemplate.otherPecuniaryBenefits",
                  type: "multiselect",
                  options: benefits,
                  staticDataType: StaticDataType.Benefits,
                } as CandidateProfileField,
                {
                  label: translate("contractSignDate.title"),
                  path: "migration.de.employmentRelationship.afterRecognition.contractSignDate",
                  type: "date",
                } as CandidateProfileField,
              ],
            },
            {
              label: translate("qualificationEvaluation.title"),
              fields: [
                {
                  label: translate("noQualificationEvaluation.title"),
                  path: "migration.de.qualificationEvaluation.noQualificationEvaluation",
                  type: "boolean",
                } as CandidateProfileField,
              ],
            },
            {
              label: translate("visaTransfer.title"),
              fields: [
                {
                  label: translate("residencePermit.title"),
                  path: "migration.de.visa.residencePermit",
                  options: residencePermits,
                  staticDataType: StaticDataType.ResidencePermits,
                } as CandidateProfileField,
                {
                  label: translate("visaEnquired.title"),
                  path: "migration.de.visa.visaEnquired",
                  type: "boolean",
                } as CandidateProfileField,
                {
                  label: translate("visaEnquiredDate.title"),
                  path: "migration.de.visa.visaEnquiredDate",
                  type: "date",
                } as CandidateProfileField,
                {
                  label: translate("visaRequested.title"),
                  path: "migration.de.visa.visaRequested",
                  type: "boolean",
                } as CandidateProfileField,
                {
                  label: translate("visaRequestDate.title"),
                  path: "migration.de.visa.visaRequestDate",
                  type: "date",
                } as CandidateProfileField,
                {
                  label: translate("flightDate.title"),
                  path: "migration.de.visa.flightDate",
                  type: "date",
                } as CandidateProfileField,
                {
                  label: translate("arrivalDate.title"),
                  path: "migration.de.visa.arrivalDate",
                  type: "date",
                } as CandidateProfileField,
                {
                  label: translate("fileNumberVisa.title"),
                  path: "migration.de.visa.fileNumber",
                  type: "text",
                } as CandidateProfileField,
              ],
            },
            {
              label: translate("recognitionPath.title"),
              fields: [
                {
                  label: translate("recognitionStarted.title"),
                  path: "migration.recognitionPath.recognitionStarted",
                  type: "boolean",
                } as CandidateProfileField,
                {
                  label: translate("recognitionStartDate.title"),
                  path: "migration.recognitionPath.recognitionStartDate",
                  type: "date",
                } as CandidateProfileField,
                {
                  label: translate("recognitionType.title"),
                  path: "migration.de.recognitionPath.current.recognitionType",
                  options: recognitionTypes,
                  staticDataType: StaticDataType.RecognitionType,
                } as CandidateProfileField,
                {
                  label: translate("equivalenceTest.title"),
                  path: "migration.de.recognitionPath.current.equivalenceTest",
                  options: equivalenceTests,
                  staticDataType: StaticDataType.EquivalenceTest,
                } as CandidateProfileField,
                {
                  label: translate("fileNumber.title"),
                  path: "migration.recognitionPath.fileNumber",
                  type: "text",
                } as CandidateProfileField,
                {
                  label: translate("recognitionNoticeKind.title"),
                  path: "migration.de.recognitionPath.current.recognitionNoticeKind",
                  options: recognitionNoticeKinds,
                  staticDataType: StaticDataType.RecognitionNoticeKind,
                } as CandidateProfileField,
                {
                  label: translate("professionalCertificateRequested.title"),
                  path: "migration.de.recognitionPath.current.certificateRequested",
                  type: "boolean",
                } as CandidateProfileField,
                {
                  label: translate("professionalCertificateRequestDate.title"),
                  path: "migration.de.recognitionPath.current.certificateRequestDate",
                  type: "date",
                } as CandidateProfileField,
                {
                  label: translate("recognitionPaymentRole.title"),
                  path: "migration.de.recognitionPath.current.recognitionPaymentRole",
                  options: recognitionPaymentRoles,
                } as CandidateProfileField,
                {
                  label: translate("recognitionPaymentRoleAccelerated.title"),
                  path: "migration.de.recognitionPath.current.recognitionPaymentRoleAccelerated",
                  options: recognitionPaymentRoles,
                } as CandidateProfileField,
              ],
            },
          ];
        }
      )
    );
  }

  getSupportedTaskTypes(): SupportedTaskTypes[] {
    return [
      "ProcessTaskDocumentCheckModel",
      "ProcessTaskEmailSendModel",
      "ProcessTaskEnterMissingInformationModel",
      "ProcessTaskInformationCollectionModel",
      "ProcessTaskInviteToPlatformModel",
      "ProcessTaskPdfFormGenerationModel",
      "ProcessTaskWorkModel",
      "ProcessTaskUploadModel",
      "ProcessTaskUpdateCandidateProfileModel",
      "ProcessTaskInformationSendOutModel",
    ];
  }

  private getEmployerRelocationRequirementsLanguageLevels(language: string): Observable<StaticDataModel[]> {
    return this.staticDataService
      .getStaticData(StaticDataType.LanguageLevels, language)
      .pipe(map((values) => values.filter((x) => EmployerRelocationLanguageLevels.includes(x.value))));
  }

  private getVisaRelocationRequirementsLanguageLevels(language: string): Observable<StaticDataModel[]> {
    return this.staticDataService
      .getStaticData(StaticDataType.LanguageLevels, language)
      .pipe(map((values) => values.filter((x) => EmployerRelocationLanguageLevels.includes(x.value))));
  }

  private getInterviewAppointmentScheduleSingleDateForm(language: string): Observable<FormlyFieldConfig[]> {
    return of(interviewAppointmentScheduleSingleDateFormFactory({}, language));
  }

  private searchOccupationSkillsAsStaticDataModel(
    query: string,
    type: OccupationSkillType
  ): Observable<StaticDataModel[]> {
    return this.professionService.searchSkills(query, type).pipe(map((skills) => this.skillsToStaticDataModel(skills)));
  }

  private findOccupationSkillsAsStaticDataModel(
    skillIds: string[],
    type: OccupationSkillType
  ): Observable<StaticDataModel[]> {
    return this.professionService
      .findOccupationSkills(skillIds, type)
      .pipe(map((skills) => this.skillsToStaticDataModel(skills)));
  }

  private skillsToStaticDataModel(skills: OccupationSkillForFilterFragment[]): StaticDataModel[] {
    return skills.map((skill) => ({
      value: skill.skillId,
      label: skill.preferredLabel,
    }));
  }
}
