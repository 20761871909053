<ng-container *transloco="let t">
  <img class="logo" *ngIf="logo" [src]="logo" default="/assets/blank.jpeg" />

  <div class="navigation grid justify-content-center">
    <ng-container *ngFor="let section of sections">
      <div class="col-12 md:col-6" *ngIf="section.items[0] as item">
        <a class="navigation-item" [routerLink]="item.routerLink">
          {{ item.label }}

          <span class="navigation-tag p-tag p-tag-danger p-component" *ngIf="item.badge | async as badge">
            <span class="p-tag-value">{{ badge }}</span>
          </span>
        </a>
      </div>
    </ng-container>
  </div>
</ng-container>
