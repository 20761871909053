import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { ActivityFragment, NotificationScope, NotificationSettingsFragment } from "@ankaadia/graphql";
import { clone } from "lodash";
import { Subscription, filter, finalize, forkJoin, switchMap, tap } from "rxjs";
import { ActivityService } from "../activity/activity.service";
import { FavoriteService } from "../favorite/favorite.service";
import { ProcessService } from "../process/process.service";
import { NotificationSettingsService } from "./notification-settings.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  private routerSub: Subscription;

  showSidebar: boolean;
  isBusy: boolean;
  notifications: ActivityFragment[];
  since: Date;
  settings: NotificationSettingsFragment;

  constructor(
    private readonly activityService: ActivityService,
    private readonly notificationSettingsService: NotificationSettingsService,
    private readonly favoriteService: FavoriteService,
    private readonly processService: ProcessService,
    private readonly router: Router,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  open(): void {
    this.isBusy = true;
    forkJoin([this.activityService.getNotifications(), this.notificationSettingsService.get()])
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe(([{ notifications, since }, settings]) => {
        this.notifications = clone(notifications);
        this.since = since;
        this.settings = settings;
        this.showSidebar = true;
        this.routerSub = this.router.events
          .pipe(filter((e) => e instanceof NavigationStart))
          .subscribe(() => this.close());
        this.changeDetector.markForCheck();
      });
  }

  updateSettings(settings: NotificationSettingsFragment): void {
    this.isBusy = true;
    this.notificationSettingsService
      .set(settings)
      .pipe(
        finalize(() => (this.isBusy = false)),
        tap((settings) => (this.settings = settings)),
        switchMap(() => {
          this.activityService.updateNotificationCount();
          return this.activityService.getNotifications();
        })
      )
      .subscribe(({ notifications, since }) => {
        this.notifications = clone(notifications);
        this.since = since;
        this.changeDetector.markForCheck();
      });
  }

  clearNotification(notification: ActivityFragment): void {
    this.isBusy = true;
    this.notificationSettingsService
      .clear(notification.id)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe(() => {
        this.notifications = this.notifications.filter((x) => x.id !== notification.id);
        this.activityService.decrementNotificationCount();
        this.changeDetector.markForCheck();
      });
  }

  clearAllNotifications(): void {
    this.isBusy = true;
    this.notificationSettingsService
      .clearAll()
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe(() => {
        this.notifications = [];
        this.activityService.resetNotificationCount();
        this.changeDetector.markForCheck();
      });
  }

  openActivityLog(): void {
    this.isBusy = true;
    forkJoin([this.favoriteService.getFavoriteCandidateIds(), this.processService.getAccessibleProcessIds()])
      .pipe(
        finalize(() => {
          this.isBusy = false;
          this.close();
        })
      )
      .subscribe(([candidateIds, processIds]) => {
        this.router.navigate(["/app/activity"], {
          state: this.settings.enabled
            ? {
                type: this.settings.activities,
                candidate: this.settings.scopes?.includes(NotificationScope.Candidates) ? candidateIds : null,
                process: this.settings.scopes?.includes(NotificationScope.Processes) ? processIds : null,
                organization: "all",
              }
            : null,
        });
        this.changeDetector.markForCheck();
      });
  }

  close(): void {
    this.showSidebar = false;
    this.routerSub.unsubscribe();
  }
}
