import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { ApplicationInsightsService } from "@ankaadia/ankaadia-shared-frontend";

@Component({
  selector: "app-access-denied",
  templateUrl: "./access-denied.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessDeniedComponent implements OnInit {
  loggedIn: Observable<boolean>;

  constructor(
    private readonly settings: SettingsService,
    private readonly appInsights: ApplicationInsightsService
  ) {}

  ngOnInit(): void {
    this.loggedIn = this.settings.isAuthenticated$;
    this.loggedIn.subscribe((loggedIn) => {
      if (loggedIn) {
        // Do not change string "Access denied in Fronted" as it is used in an alert rule
        this.appInsights.logException(new Error("Access denied in Fronted"), {
          isCandidate: this.settings.isCandidate,
          roles: this.settings,
          organizationId: this.settings.organizationId,
          permissions: this.settings.userPermissions,
        });
      }
    });
  }
}
