import { ListIterator, max, orderBy, times } from "lodash";
import { IStaticDataModel } from "./model/staticData.model";

type SortEntry = string | number;

interface ISortablePermit<T extends IStaticDataModel> {
  sortEntries: SortEntry[];
  permit: T;
}

export function sortResidencePermits<T extends IStaticDataModel>(permits: T[]): T[] {
  const sortablePermits: ISortablePermit<T>[] = permits.map((permit) => ({ sortEntries: getEntries(permit), permit }));
  const sortEntryDepth = max(sortablePermits.map(({ sortEntries }) => sortEntries.length));

  return orderBy(
    sortablePermits,
    times(sortEntryDepth).map((_, index) => buildIterator(index)),
    times(sortEntryDepth).map(() => "asc")
  ).map(({ permit }) => permit);
}

function buildIterator<T extends IStaticDataModel>(index: number): ListIterator<ISortablePermit<T>, SortEntry> {
  return ({ sortEntries }) => (index < sortEntries.length ? sortEntries[index] : 0);
}

function getEntries<T extends IStaticDataModel>(staticData: T): SortEntry[] {
  const matches: string[] = staticData.value.match(/([A-Za-z]+)|(\d+)/g) ?? [];
  return matches.map((match) => (/^\d+$/.test(match) ? Number(match) : match));
}
