import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DocumentGenerationMode, DocumentMode, DocumentTemplateMode, StaticDataModel } from "@ankaadia/graphql";
import { TranslocoService } from "@ngneat/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { DocumentForm } from "../../../documents/document-form.model";
import { DocumentTemplatesService } from "../../document-templates.service";

@Component({
  selector: "app-report-data-strategy",
  templateUrl: "./document-template-reports-dataStrategy.component.html",
  styleUrls: ["./document-template-reports-dataStrategy.component.scss"],
})
export class DocumentReportTemplateDataStrategyComponent implements OnChanges {
  constructor(
    private readonly documentTemplateService: DocumentTemplatesService,
    private readonly transloco: TranslocoService
  ) {}

  @Input({ required: true })
  mode: "Generation" | "Configuration" = null;

  @Input()
  documentForm: DocumentForm = null;

  @Input()
  strategyId: string = null;

  @Input()
  strategyOrganizationId: string = null;

  @Input()
  reportTemplateId: string = null;

  @Input()
  reportTemplateDescription: string = null;

  @Input()
  configurationForm: FormGroup = null;

  @Output()
  readonly modelChanged = new EventEmitter<any>();

  protected DocumentModeEnum: typeof DocumentMode = DocumentMode;
  protected DocumentTemplateModeEnum: typeof DocumentTemplateMode = DocumentTemplateMode;
  protected DocumentGenerationModeEnum: typeof DocumentGenerationMode = DocumentGenerationMode;
  protected availableStrategies: StaticDataModel[] = null;
  protected selectedStrategyId: string = null;

  protected configurationConfigFields: FormlyFieldConfig[] = null;
  protected configurationConfigData: any = {};

  protected generationConfigFields: FormlyFieldConfig[] = null;
  protected generationConfigData: any = {};

  selectedStrategyChanged(): void {
    this.documentForm.controls.generationStrategy.controls.strategyId.setValue(this.selectedStrategyId);
    this.documentForm.controls.generationStrategy.controls.strategyId.markAsDirty();
    this.documentForm.controls.generationStrategy.controls.strategyId.updateValueAndValidity();
    this.configurationConfigData = {};
    this.loadConfigurationConfigData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documentForm) {
      if (this.mode == "Configuration" && this.documentForm.value?.organizationId) {
        this.configurationConfigData = this.documentForm.value?.generationStrategy?.configuration;
        this.documentTemplateService
          .getDataStrategies(this.documentForm.value.organizationId, this.transloco.getActiveLang())
          .subscribe((strategies) => {
            this.availableStrategies = strategies;
            setTimeout(() => {
              if (!this.documentForm?.controls?.generationStrategy?.controls?.strategyId?.value) {
                this.selectedStrategyId = strategies?.[0].value;
                this.selectedStrategyChanged();
              } else {
                this.selectedStrategyId = this.documentForm.controls.generationStrategy.controls.strategyId.value;
                this.loadConfigurationConfigData();
              }
            }, 0);
          });
      }
    }
    if (
      (changes.strategyId || changes.reportTemplateId || changes.configurationForm) &&
      this.strategyOrganizationId &&
      this.strategyId
    ) {
      if (this.mode == "Generation") {
        this.generationConfigFields = null;
        this.documentTemplateService
          .getDataStrategyGenerationFormly(
            this.strategyOrganizationId,
            this.reportTemplateId,
            this.strategyId,
            this.transloco.getActiveLang()
          )
          .subscribe((config) => {
            this.generationConfigFields = config;
            this.generationConfigData = {};
          });
        this.selectedStrategyId = this.strategyId;
      }
    }
  }

  private loadConfigurationConfigData(): void {
    this.documentTemplateService
      .getDataStrategyConfigurationFormly(
        this.documentForm.value.organizationId,
        this.selectedStrategyId,
        this.transloco.getActiveLang()
      )
      .subscribe((config) => {
        this.configurationConfigFields = config;
        this.configurationForm = new FormGroup({});
        this.documentForm.controls.generationStrategy.setControl("configuration", this.configurationForm);
      });
  }
}
