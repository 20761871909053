import { Injectable } from "@angular/core";
import {
  GetSwitchableOrganizationsGQL,
  SwitchUserOrganizationGQL,
  SwitchableOrganizationFragment,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class OrganizationSwitchService {
  constructor(
    private readonly listOrgs: GetSwitchableOrganizationsGQL,
    private readonly switchOrg: SwitchUserOrganizationGQL
  ) {}

  list(search: string): Observable<SwitchableOrganizationFragment[]> {
    return this.listOrgs.fetch({ search: search ?? "" }).pipe(map((x) => x.data.getSwitchableOrganizations));
  }

  switch(organizationId: string): Observable<boolean> {
    return this.switchOrg.mutate({ organizationId }).pipe(map((x) => x.data.switchUserOrganization));
  }
}
