import { Pipe, PipeTransform } from "@angular/core";
import { StaticDataModel } from "@ankaadia/graphql";
import { Observable, isObservable, map, of } from "rxjs";

@Pipe({ name: "options" })
export class OptionsPipe implements PipeTransform {
  transform(value: string | string[], options: StaticDataModel[] | Observable<StaticDataModel[]>): Observable<string> {
    if (!Array.isArray(value)) {
      value = [value];
    }

    if (!isObservable(options)) {
      options = of(options);
    }

    return options.pipe(
      map((options) => {
        return (value as string[])
          .map((value) => {
            const option = options.find((option) => option.value === value);
            return option ? option.label : value;
          })
          .join(", ");
      })
    );
  }
}
