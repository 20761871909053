import { Component, Input } from "@angular/core";

@Component({
  selector: "app-email-messenger-message",
  templateUrl: "./email-messenger-message.component.html",
  styleUrls: ["./email-messenger-message.component.scss"],
})
export class EmailMessengerMessageComponent {
  @Input()
  messengerMessage: string;

  @Input()
  lastState: string;

  @Input()
  lastSentDate: Date;

  @Input()
  errorMessage: string;

  @Input()
  processLanguage?: string;
}
