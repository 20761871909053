import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class SidebarAdditionalComponentService {
  private static readonly componentList = [];

  registerComponentType(component: any): void {
    SidebarAdditionalComponentService.componentList.push(component);
  }

  getComponentTypeList(): any[] {
    return SidebarAdditionalComponentService.componentList;
  }
}
