import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { OrganizationSwitchComponent } from "./organization-switch.component";

@NgModule({
  imports: [SharedModule],
  declarations: [OrganizationSwitchComponent],
  exports: [OrganizationSwitchComponent],
})
export class OrganizationSwitchModule {}
