<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <p-fileUpload
    class="document-selector"
    #fileUpload
    [uploadConfig]="document.organizationId"
    [uploadConfigMode]="document.mode"
    [uploadConfigTemplateMode]="document.templateMode"
    chooseIcon="pi pi-folder-open"
    [invalidFileSizeMessageSummary]="t('primeng.invalidFileSizeMessageSummary')"
    [invalidFileSizeMessageDetail]="t('primeng.invalidFileSizeMessageDetail')"
    [invalidFileTypeMessageSummary]="t('primeng.invalidFileTypeMessageSummary')"
    [invalidFileTypeMessageDetail]="t('primeng.invalidFileTypeMessageDetail')"
    [invalidFileLimitMessageDetail]="t('primeng.invalidFileLimitMessageDetail')"
    [invalidFileLimitMessageSummary]="t('primeng.invalidFileLimitMessageSummary')"
    [multiple]="true"
    [disabled]="readonly"
    [showUploadButton]="false"
    [showCancelButton]="false"
    [customUpload]="true"
    (onSelect)="selectFiles($event.files)"
  >
    <ng-template pTemplate="file"></ng-template>
    <ng-template pTemplate="toolbar">
      <ng-container *ngIf="toolbarTemplate">
        <ng-container *ngTemplateOutlet="toolbarTemplate" />
      </ng-container>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="p-fileupload-prompt-wrapper" *ngIf="freeFormatFiles.controls.length === 0">
        <div class="p-fileupload-prompt">
          {{ t("file.drop") }}
        </div>
      </div>

      <table>
        <tr *ngFor="let file of freeFormatFiles.controls">
          <td class="file-name">
            {{ file.controls.name.value }}
          </td>
          <td class="file-size">
            {{
              file.controls.size.value
                | fileSize
                  : { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  : processLanguage
            }}
          </td>

          <td class="text-right white-space-nowrap file-buttons">
            <p-button
              *ngIf="!readonly"
              [pTooltip]="t('common.delete')"
              [disabled]="fileUpload.uploading"
              icon="pi pi-trash"
              class="ml-2"
              (onClick)="removeFile(file)"
            ></p-button>
            <p-button
              [pTooltip]="t('attachment.download')"
              [disabled]="fileUpload.uploading"
              icon="pi pi-download"
              class="ml-2"
              (onClick)="downloadFile(file)"
            ></p-button>
          </td>
        </tr>
      </table>
    </ng-template>
  </p-fileUpload>
</ng-container>
