type CompareConfigType = { sourceDocumentFormat: string; equalDocumentFormats: string[] }[];

/*
  * This function is used to compare document formats using the provided configuration.

*/
export function isDocumentFormatEqual(
  docFormat1: string,
  docFormat2: string,
  compareConfig: CompareConfigType
): boolean {
  if (compareConfig == null) {
    return docFormat1 === docFormat2;
  } else {
    const doc1Equals = compareConfig.find((config) => config.sourceDocumentFormat == docFormat1);
    const doc2Equals = compareConfig.find((config) => config.sourceDocumentFormat == docFormat2);
    return (
      docFormat1 === docFormat2 ||
      doc1Equals?.equalDocumentFormats.includes(docFormat2) ||
      doc2Equals?.equalDocumentFormats.includes(docFormat1)
    );
  }
}

export function isDocumentFormatIncluded(format: string, formats: string[], compareConfig: CompareConfigType): boolean {
  return formats.some((f) => isDocumentFormatEqual(f, format, compareConfig));
}
