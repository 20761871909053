import { Injectable } from "@angular/core";
import { ResolveShortLinkGQL } from "@ankaadia/graphql";
import { map, Observable } from "rxjs";
import { AuthInterceptor } from "../../shared/interceptors/auth.interceptor";

@Injectable({ providedIn: "root" })
export class ShortLinkService {
  constructor(
    private readonly resolve: ResolveShortLinkGQL,
    private readonly authInterceptor: AuthInterceptor
  ) {
    this.authInterceptor.registerNoLoginOperation(this.resolve);
  }

  resolveShortLink(shortLink: string): Observable<string> {
    return this.resolve.fetch({ shortLink: shortLink }).pipe(map((result) => result.data.resolveShortLink));
  }
}
