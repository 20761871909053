<ng-container *transloco="let t; lang: processLanguage + '|static'">
  <div key="document-ai"></div>
  <p-fileUpload
    class="document-selector"
    #fileUpload
    [uploadConfig]="document.organizationId"
    [uploadConfigMode]="document.mode"
    [uploadConfigTemplateMode]="document.templateMode"
    chooseIcon="pi pi-folder-open"
    [invalidFileSizeMessageSummary]="t('primeng.invalidFileSizeMessageSummary')"
    [invalidFileSizeMessageDetail]="t('primeng.invalidFileSizeMessageDetail')"
    [invalidFileTypeMessageSummary]="t('primeng.invalidFileTypeMessageSummary')"
    [invalidFileTypeMessageDetail]="t('primeng.invalidFileTypeMessageDetail')"
    [invalidFileLimitMessageDetail]="t('primeng.invalidFileLimitMessageDetail')"
    [invalidFileLimitMessageSummary]="t('primeng.invalidFileLimitMessageSummary')"
    [multiple]="!singleFileUpload"
    [disabled]="readonly || documentForm.controls.writeMode.value !== DocumentWriteMode.Full"
    [showUploadButton]="false"
    [showCancelButton]="false"
    [customUpload]="true"
    (onSelect)="selectFiles($event.files)"
    (uploadHandler)="uploadFiles()"
  >
    <ng-template pTemplate="file"></ng-template>
    <ng-template pTemplate="toolbar">
      <ng-container *ngIf="toolbarTemplate">
        <ng-container *ngTemplateOutlet="toolbarTemplate" />
      </ng-container>
    </ng-template>
    <ng-template pTemplate="content">
      <div *ngIf="!isInMetadataMode" class="content">
        <div class="p-fileupload-prompt-wrapper" *ngIf="files.controls.length === 0">
          <div class="p-fileupload-prompt">
            {{ t("file.drop") }}
          </div>
        </div>

        <table>
          <thead *ngIf="isDocumentSelectionMode(mode) && files.controls.length">
            <tr>
              <th class="text-left">{{ t("name.title") }}</th>
              <th class="text-left">
                <table class="file-criteria">
                  <thead>
                    <tr>
                      <th *ngFor="let criterion of documentForm.controls.selectionCriteria.value">
                        <app-document-criterion-label [criterion]="criterion" />
                      </th>
                    </tr>
                  </thead>
                </table>
              </th>
              <th class="text-left"></th>
            </tr>
          </thead>

          <ng-container *ngFor="let file of files.controls">
            <tr>
              <td class="file-name" [title]="file.controls.name.value">{{ file.controls.name.value }}</td>
              <td class="file-size" *ngIf="!isDocumentSelectionMode(mode)">
                {{
                  file.controls.size.value
                    | fileSize
                      : { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      : processLanguage
                }}
              </td>
              <ng-container *ngIf="isDocumentSelectionMode(mode) && getSelectionValues(file) as selectionValues">
                <ng-container *ngIf="getCriterionParameters(selectionValues) as criteria">
                  <td>
                    <table class="file-criteria">
                      <tbody>
                        <tr>
                          <td *ngFor="let criterion of documentForm.controls.selectionCriteria.value; index as i">
                            <app-document-criterion-selector
                              [criterion]="criterion"
                              [parameters]="criteria"
                              [readonly]="readonly || isCriterionReadonly(selectionValues, i)"
                              [value]="selectionValues.value[i]"
                              (valueChange)="setSelectionValue($event, selectionValues, i)"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </ng-container>
              </ng-container>

              <td *ngIf="mode === DocumentMode.Candidate && !isSingleDocumentSet" class="file-tags">
                <p-multiSelect
                  class="ignore-dirty-state"
                  [options]="formats$ | async | documentSelectorFilter: availableTags"
                  [required]="true"
                  [readonly]="readonly"
                  [showToggleAll]="false"
                  [placeholder]="t('documentFormat.placeholder')"
                  [ngModel]="getTags(file)"
                  (onChange)="setTags($event.value, file)"
                  [tooltip]="t('document.tooltipFormat')"
                />
              </td>

              <td class="text-right white-space-nowrap file-buttons">
                <p-button
                  *ngIf="file.controls.original.value"
                  [pTooltip]="t('common.preview')"
                  [disabled]="fileUpload.uploading"
                  icon="pi pi-search"
                  class="ml-2 hidden lg:inline"
                  (onClick)="previewFile(file)"
                />

                <p-button
                  [pTooltip]="t('attachment.download')"
                  [disabled]="fileUpload.uploading"
                  icon="pi pi-download"
                  class="ml-2"
                  (onClick)="downloadFile(file)"
                />
                <p-button
                  *ngIf="!readonly && file.controls.original.value"
                  [pTooltip]="t('common.delete')"
                  [disabled]="fileUpload.uploading"
                  icon="pi pi-trash"
                  class="ml-2"
                  (onClick)="removeFile(file)"
                />
                <p-button
                  *ngIf="!readonly && file.controls.original.value"
                  [pTooltip]="t('document.replace')"
                  [disabled]="fileUpload.uploading"
                  icon="pi pi-sync"
                  class="ml-2"
                  (onClick)="replaceFile(file)"
                ></p-button>
                <p-button
                  *ngIf="isSingleDocumentSet && !readonly"
                  [pTooltip]="t('common.add')"
                  icon="pi pi-plus"
                  class="ml-2"
                  (onClick)="addSingleDocumentSet(file)"
                />
              </td>
            </tr>

            <tr *ngIf="getSingleDocumentSetConfig(file) as singleSetConfig">
              <td colspan="3">
                <table class="single-document-set">
                  <tr *ngFor="let set of singleSetConfig.controls; index as i">
                    <td>
                      <p-dropdown
                        class="ignore-dirty-state"
                        [options]="singleDocumentSetTypes$ | async"
                        [required]="true"
                        [readonly]="readonly"
                        [placeholder]="t('fileType.placeholder')"
                        [ngModel]="getSingleDocumentSetType(file, i)"
                        (onChange)="setSingleDocumentSetType(file, $event.value, i)"
                      />
                    </td>
                    <td>
                      <p-multiSelect
                        class="ignore-dirty-state"
                        [options]="singleDocumentSetFormats$ | async"
                        [required]="true"
                        [readonly]="readonly"
                        [showToggleAll]="false"
                        [placeholder]="t('documentFormat.placeholder')"
                        [tooltip]="t('document.tooltipFormat')"
                        [ngModel]="getSingleDocumentSetFormats(file, i)"
                        (onChange)="setSingleDocumentSetFormats(file, $event.value, i)"
                      />
                    </td>
                    <td *ngIf="!readonly" class="single-document-set-buttons">
                      <p-button
                        [pTooltip]="t('common.delete')"
                        icon="pi pi-trash"
                        (onClick)="deleteSingleDocumentSet(file, i)"
                      />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
      <ng-container *ngIf="footerTemplate">
        <div class="footer">
          <ng-container *ngTemplateOutlet="footerTemplate" />
        </div>
      </ng-container>
    </ng-template>
  </p-fileUpload>
  <small class="p-error" *ngIf="form.controls.files.errors?.required">
    {{ t("documentSet.files.required") }}
  </small>
  <small class="p-error" *ngIf="form.controls.files.errors?.duplicateTags">
    {{ t("documentSet.files.duplicateTags") }}
  </small>

  <p-dialog
    *ngIf="previewVisible"
    [(visible)]="previewVisible"
    [maximizable]="true"
    [closeOnEscape]="true"
    appendTo="body"
    [style]="{ height: '85vh', width: '85vw' }"
  >
    <app-document-preview-dialog
      *ngIf="fileToPreview"
      [file]="fileToPreview"
      [fileName]="fileToPreview.name"
      [url]="fileToPreviewUrl"
      [processLanguage]="processLanguage"
      [showExternalLinkButton]="true"
      [renderAsCard]="false"
    />
    <p-button *ngIf="fileToPreview" closeOnEscape [style]="{ display: 'none' }" />
    <!-- Workaround for p-dialog closeOnEscape. Hence close only dialog and not the sidebar-->
  </p-dialog>
</ng-container>
