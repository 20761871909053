<ng-container *appTranslate="let t">
  <div class="formgrid grid p-fluid">
    <div class="field col-12 lg:col-6">
      <label>{{ t("languageTrainingTracking.languageLevelTestResult.title") }}</label>
      <input pInputText [ngModel]="languageLevelLabel" [readonly]="true" />
    </div>

    <div class="field col-12 lg:col-6">
      <label>{{ t("languageTrainingTracking.languageLevelTestDate.title") }}</label>
      <p-calendar
        [ngModel]="exam?.examDateAndTime?.date"
        [readonly]="true"
        [showIcon]="false"
        [placeholder]="''"
        [showTime]="exam?.examDateAndTime?.hasTime"
      ></p-calendar>
    </div>
  </div>
</ng-container>
