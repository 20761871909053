import { Component, ChangeDetectorRef, OnInit, DestroyRef } from "@angular/core";
import { StaticDataType, HighestPlacementEducationExamFragment } from "@ankaadia/graphql";
import { EducationExamService } from "../../features/education/education-exams/education-exam.service";
import { TranslocoService } from "@ngneat/transloco";
import { StaticDataService } from "../../shared/static-data/static-data.service";
import { FieldType } from "@ngx-formly/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { combineLatest } from "rxjs";

@Component({
  selector: "app-formly-language-level-by-placement-test",
  templateUrl: "./formly-language-level-by-placement-test.component.html",
})
export class FormlyLanguageLevelByPlacementTestComponent extends FieldType implements OnInit {
  exam: HighestPlacementEducationExamFragment;
  languageLevelLabel: string = null;

  constructor(
    private readonly educationExamService: EducationExamService,
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.formState.candidateId && this.formState.organizationId) {
      const language = this.transloco.getActiveLang();
      combineLatest([
        this.educationExamService
          .getHighestPlacementTest(this.formState.candidateId, this.formState.organizationId)
          .pipe(takeUntilDestroyed(this.destroyRef)),
        this.staticDataService.getStaticData(StaticDataType.LanguageModules, language),
      ]).subscribe(([exam, languageModules]) => {
        this.languageLevelLabel = languageModules.find((module) => module.value === exam?.languageLevel)?.label;
        this.exam = exam;
        this.changeDetector.detectChanges();
      });
    }
  }
}
