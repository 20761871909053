import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { PreviewableDocumentFileActivityTypes } from "@ankaadia/ankaadia-shared";
import { Activity, ActivityDiffFragment, ActivityFragment, ActivityType, PreviewableFile } from "@ankaadia/graphql";
import { TranslocoService } from "@ngneat/transloco";
import { cloneDeep } from "lodash";
import { OverlayPanel } from "primeng/overlaypanel";
import { Table, TableLazyLoadEvent } from "primeng/table";
import { SettingsService } from "../../../shared/services/settings.service";
import { DocumentsService } from "../../documents/documents.service";
import { ActivityDiffService } from "../activity-diff/activity-diff.service";

interface PreviewableFileWithUrl extends PreviewableFile {
  url: string;
}

@Component({
  selector: "app-activity-table",
  templateUrl: "./activity-table.component.html",
  styleUrls: ["./activity-table.component.scss"],
})
export class ActivityTableComponent {
  protected readonly language = this.transloco.getActiveLang();
  protected readonly organizationId = this.settings.organizationId;
  protected readonly ActivityType = ActivityType;
  protected selectedFile?: PreviewableFileWithUrl;
  protected showSidebar = false;

  @Input({ required: true })
  activities: Activity[] = [];

  @Input()
  diff: ActivityDiffFragment[];

  @Input({ required: true })
  totalActivities: number;

  @Input({ required: true })
  lazy: boolean;

  @Input()
  showCandidate = true;

  @Input()
  showProcess = true;

  @Input()
  showUser = true;

  @Input()
  showDate = true;

  @Input()
  showNotificationClear = false;

  @Input()
  disabled: boolean;

  @Output()
  readonly notificationCleared = new EventEmitter<ActivityFragment>();

  @Output()
  readonly pageChange = new EventEmitter<TableLazyLoadEvent>();

  @ViewChild(Table)
  table: Table;

  @ViewChild("infoPanel")
  infoPanel: OverlayPanel;

  @ViewChild("diffPanel")
  diffPanel: OverlayPanel;

  selectedActivityDiff: ActivityDiffFragment[];
  selectedActivity: ActivityFragment;

  get previewableFilesExist(): boolean {
    return this.activities?.some((activity) => activity.previewableFiles);
  }

  constructor(
    private readonly settings: SettingsService,
    private readonly transloco: TranslocoService,
    private readonly activityDiffService: ActivityDiffService,
    private readonly documentService: DocumentsService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  showInfo(activity: ActivityFragment, event: Event): void {
    this.selectedActivity = activity;
    this.infoPanel.show(event);
  }

  showDiff(activity: ActivityFragment, event: Event): void {
    this.selectedActivityDiff = null;
    this.activityDiffService.getActivityDiff(activity.id, activity.organizationId).subscribe((diff) => {
      this.selectedActivityDiff = cloneDeep(diff);
      this.changeDetectorRef.detectChanges();
    });
    this.diffPanel.show(event);
  }

  resetPage(): void {
    this.table.onPageChange({ first: 0, rows: this.table.rows });
  }

  hasPreviewableFiles(activity: ActivityFragment): boolean {
    return PreviewableDocumentFileActivityTypes.some((type) => type === activity.type);
  }

  getFileLabel(previableFile: PreviewableFile): string {
    if (previableFile.setName) return previableFile.setName;
    if (previableFile.setType) return this.transloco.translate(`documentSet.types.${previableFile.setType}`);
    return this.transloco.translate("freeFormatFiles.title");
  }

  showFile(previewableFile: PreviewableFile): void {
    this.documentService
      .getDownloadUrl(
        previewableFile.file.blob,
        previewableFile.document.organizationId,
        previewableFile.document.candidateId
      )
      .subscribe((url) => {
        this.showSidebar = true;
        this.selectedFile = { ...previewableFile, url };
      });
  }

  close(): void {
    this.showSidebar = false;
    this.selectedFile = null;
  }
}
