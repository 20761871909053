<ng-container *appTranslate="let t">
  <ng-container *ngIf="exams$ | async as exams">
    <p-fieldset *ngIf="exams?.length" [legend]="t('certifiedEducationExams.title')">
      <ng-container *ngFor="let exam of exams; index as i">
        <div class="col formgrid grid mw-1 p-fluid">
          <div class="field col-12 lg:col-2">
            <label>{{ t(languageSkillLabelMapping.language) }}</label>
            <p-dropdown [options]="languages$ | async" [ngModel]="'DE'" [readonly]="true"></p-dropdown>
          </div>

          <div class="field col-12 lg:col-2">
            <label>{{ t(languageSkillLabelMapping.skillLevel) }}</label>
            <p-dropdown [options]="modules$ | async" [ngModel]="exam.languageLevel" [readonly]="true"></p-dropdown>
          </div>

          <div class="field col-12 lg:col-2">
            <label>{{ t(languageSkillLabelMapping.examInstitution) }}</label>
            <p-dropdown
              [options]="institutions$ | async"
              [ngModel]="exam.examInstitution"
              [readonly]="true"
            ></p-dropdown>
          </div>

          <div class="field-checkbox col-12 lg:col-2 pt-5">
            <p-checkbox
              [binary]="true"
              [label]="t(languageSkillLabelMapping.certified)"
              [ngModel]="true"
              [readonly]="true"
            ></p-checkbox>
          </div>

          <div class="field col-12 lg:col-2">
            <app-candidate-document-date
              [candidateId]="formState?.candidateId"
              [candidateOrganizationId]="formState?.organizationId"
              [candidateForm]="field.form"
              [additionalForeignKeySourceData]="{ certifiedExams: exams }"
              [readonly]="to?.readonly || to?.disabled"
              [disabled]="to?.disabled"
              [label]="t(languageSkillLabelMapping.examCertificateDate)"
              [documentType]="exam?.documentType"
              [selectedSet]="{ foreignKey: exam.examResultId }"
              [dateField]="'issueDate'"
            ></app-candidate-document-date>
          </div>
        </div>

        <hr class="mt-0" *ngIf="i < exams.length - 1" />
      </ng-container>
    </p-fieldset>
  </ng-container>
</ng-container>
