import { Directive, EmbeddedViewRef, Input } from "@angular/core";
import { HashMap, TranslocoDirective } from "@jsverse/transloco";
import { Content } from "@jsverse/transloco/lib/template-handler";

import { TranslationKey } from "../_generated/translation-keys";

type TranslateFn = (key: TranslationKey, params?: HashMap) => any;

interface ViewContext {
  $implicit: TranslateFn;
  currentLang: string;
}

@Directive({
  selector: "[appTranslate]",
  standalone: true,
})
export class TranslateDirective extends TranslocoDirective {
  override view: EmbeddedViewRef<ViewContext> | undefined;

  @Input("appTranslate") override key: TranslationKey | undefined;
  @Input("appTranslateParams") override params: HashMap = {};
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("appTranslateScope") override inlineScope: string | undefined;
  @Input("appTranslatePrefix") override prefix: string | undefined;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("appTranslateLang") override inlineLang: string | undefined;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("appTranslateLoadingTpl") override inlineTpl: Content | undefined;


  static override ngTemplateContextGuard(
    // @ts-expect-error parameter is not used
    directive: TranslateDirective,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore parameter is not used
    context: unknown): context is ViewContext {
    return true;
  }
}
