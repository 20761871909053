<ng-container *appTranslate="let t">
  <div class="p-datatable" *ngIf="warnings">
    <table class="p-datatable-table">
      <tbody class="p-datatable-tbody">
        <tr *ngFor="let warning of warnings">
          <td>{{ t($any("template.errors." + warning.translationKey), warning.parameters) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
