import { Injectable, RendererFactory2 } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class OverlayDetectorService {
  readonly hasOverlay$ = new BehaviorSubject<boolean>(false);

  constructor(rendererFactory: RendererFactory2) {
    const renderer = rendererFactory.createRenderer(null, null);
    const body: HTMLBodyElement = renderer.selectRootElement("body", true);

    const observer = new MutationObserver(() => this.hasOverlay$.next(this.hasOverlayAsDirectChild(body)));
    observer.observe(body, { childList: true, subtree: false });
  }

  private hasOverlayAsDirectChild(body: HTMLBodyElement): boolean {
    return Array.from(body.children).some((child) => child.classList.contains("p-component-overlay"));
  }
}
