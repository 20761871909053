<ng-container *transloco="let t">
  <p-multiSelect
    #multiSelect
    [inputId]="inputId"
    [options]="types"
    [disabled]="disabled"
    [placeholder]="t('type.placeholder')"
    [showToggleAll]="false"
    [showClear]="true"
    [ngModel]="model"
    (onChange)="updateModel($event.value)"
    (onClear)="updateModel(null)"
  >
    <ng-template pTemplate="item" let-item>
      <app-activity-icon [type]="item.value"></app-activity-icon>
      <span class="ml-1 vertical-align-middle">{{ item.value | enum: "ActivityType" }}</span>
    </ng-template>
    <ng-template pTemplate="selectedItems" let-types>
      <ng-container *ngIf="!types?.length">{{ multiSelect.placeholder }}</ng-container>
      <span *ngFor="let type of types; index as i" class="mr-1">
        <app-activity-icon [type]="type"></app-activity-icon>
        {{ type | enum: "ActivityType" }}<ng-container *ngIf="i < types.length - 1">, </ng-container>
      </span>
    </ng-template>
  </p-multiSelect>
</ng-container>
