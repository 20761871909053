import { customFieldLabelMapping, ICustomFieldsModel, nameofFactory } from "@ankaadia/ankaadia-shared";
import { translate } from "@ngneat/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { PrimeIcons } from "primeng/api";

const dummy: ICustomFieldsModel = null;
const nameOfDeep = nameofFactory<typeof dummy.defa>();

const path = "os.profile.customFields";

export const defaCustomFieldsFactory: (requiredFields: string[]) => FormlyFieldConfig[] = (
  requiredFields: string[]
) => {
  return [
    {
      id: "defa-custom",
      props: {
        label: translate("customFields.aboutMe.title"),
        icon: PrimeIcons.USER_EDIT,
      },
      fieldGroupClassName: "p-fluid",
      fieldGroup: [
        {
          fieldGroupClassName: "p-fluid formgrid grid",
          fieldGroup: [
            {
              key: nameOfDeep("expectationWorkliveInGermany"),
              type: "textarea",
              className: "field col-12 lg:col-12",
              props: {
                type: "text",
                rows: 4,
                maxLength: 500,
                label: translate(customFieldLabelMapping.defa.expectationWorkliveInGermany),
              },
              expressionProperties: {
                "props.required": () =>
                  requiredFields?.includes(`${path}.${nameOfDeep("expectationWorkliveInGermany")}`),
              },
              validation: {
                messages: {
                  required: translate("required"),
                },
              },
            },
            {
              key: nameOfDeep("whyStopWorkingForExistingEmployer"),
              type: "textarea",
              className: "field col-12 lg:col-12",
              props: {
                type: "text",
                rows: 4,
                maxLength: 500,
                label: translate(customFieldLabelMapping.defa.whyStopWorkingForExistingEmployer),
              },
              expressionProperties: {
                "props.required": () =>
                  requiredFields?.includes(`${path}.${nameOfDeep("whyStopWorkingForExistingEmployer")}`),
              },
              validation: {
                messages: {
                  required: translate("required"),
                },
              },
            },
          ],
        },
      ],
    },
  ];
};
