import {
  InterviewAppointmentConfirmation,
  InterviewAppointmentConfirmationDate,
  nameofFactory,
} from "@ankaadia/ankaadia-shared";
import { translate as transloco, TranslateParams } from "@ngneat/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";

export interface InterviewAppointmentConfirmationContext {}

export function interviewAppointmentConfirmationFormFactory(
  _context: InterviewAppointmentConfirmationContext,
  language: string
): FormlyFieldConfig[] {
  const nameOf = nameofFactory<InterviewAppointmentConfirmation>();
  const nameOfDate = nameofFactory<InterviewAppointmentConfirmationDate>();
  const translate = <T>(key: TranslateParams, params?: Record<string, any>): T => transloco<T>(key, params, language);
  return [
    {
      className: "block m-1",
      fieldGroup: [
        {
          key: nameOf("dates"),
          type: "repeat",
          props: {
            readonly: true,
          },
          fieldArray: {
            fieldGroupClassName: "p-fluid formgrid grid",
            fieldGroup: [
              {
                type: "checkbox",
                key: nameOfDate("selected"),
                className: "field col mb-0 mt-2 mr-2 align-self-center flex-grow-0",
                props: {
                  required: false,
                  blocklyType: "Boolean",
                },
              },
              {
                type: "datepicker",
                key: nameOfDate("date"),
                className: "field col",
                props: {
                  label: translate("date.title"),
                  showIcon: true,
                  showTime: true,
                  inline: false,
                  readonly: true,
                  showTimezone: true,
                  readonlyFormat: { dateStyle: "short", timeStyle: "short" },
                  blocklyType: "field_date",
                },
              },
            ],
          },
          validators: {
            all: (control, field) => field.options.formState?.taskCanceled || control.value.some((x) => x.selected),
          },
        },
      ],
    },
  ];
}
