import { Injectable } from "@angular/core";
import { TranslocoConfig, TranslocoMissingHandler } from "@jsverse/transloco";

@Injectable({ providedIn: "root" })
export class MissingKeyHandler implements TranslocoMissingHandler {
  private static readonly missingKey = new Map<string, string>();
  private static timerHandle: number = null;

  handle(key: string, config: TranslocoConfig): string {
    if (!config.prodMode) {
      if (!MissingKeyHandler.timerHandle) {
        MissingKeyHandler.timerHandle = window.setInterval(
          () => {
            if (MissingKeyHandler.missingKey.size > 0) {
              // eslint-disable-next-line no-console
              console.warn(`Missing translations: ${Array.from(MissingKeyHandler.missingKey.keys())}`);
            }
          },
          1000 * 60 * 2
        );
      }
      MissingKeyHandler.missingKey.set(key, "empty");
    }
    return key;
  }
}
