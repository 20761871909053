import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { BlockableUI, FilterService, MenuItem, SortEvent, TreeNode } from "primeng/api";
import { Sidebar } from "primeng/sidebar";
import { TreeTable } from "primeng/treetable";
import { Subject, Subscription, debounceTime } from "rxjs";
import {
  SideBarSizeType,
  StartOperationType,
  TableCache,
  TableColumn,
  TableMenuItem,
  TableOperation,
  TableOperationMode,
  TableTag,
} from "../table/table.model";
import { TableService } from "./../table/table.service";

@Component({
  selector: "app-tree-table",
  templateUrl: "./tree-table.component.html",
  styleUrls: ["./tree-table.component.scss"],
})
export class TreeTableComponent implements OnInit, OnDestroy, OnChanges, BlockableUI {
  readonly filterName = `custom-${Date.now()}`;
  readonly newOp = { id: "new" };
  readonly captionOp = { id: "caption" };
  readonly footerOp = { id: "footer" };

  @Input() title: string;
  @Input() warningMessage: string;
  @Input() items: TreeNode[];
  @Input() columns: TableColumn[];
  @Input() tableOperations: TableOperation[];
  @Input() newOperations: TableOperation[];
  @Input() captionOperations: TableOperation[];
  @Input() footerOperations: TableOperation[];
  @Input() sidebarSize: SideBarSizeType = "medium";
  @Input() numberOfRows = 10;
  @Input() scrollable = true;
  @Input() sortField: string;
  @Input() sortOrder: number;
  @Input() search = true;
  @Input() paginator = false;
  @Input() showArrows? = false;
  @Input()
  language: string = this.transloco.getActiveLang();

  sideBarSizeMapping = { medium: "p-sidebar-md", large: "p-sidebar-lg" };

  TableOperationMode: typeof TableOperationMode = TableOperationMode;

  globalFilterFields: string[];
  customSort: boolean;
  eventTarget: EventTarget;
  calculatedTags: Record<string, TableTag[]>[];
  calculatedTooltips: Record<string, string>[];
  calculatedIcons: Record<string, string>[];
  calculatedButtonItems: Record<string, MenuItem[][]>;
  private _showSidebar: boolean;

  @Output() readonly showSidebarChange = new EventEmitter<any>();

  @Input() get showSidebar(): boolean {
    return this._showSidebar;
  }

  set showSidebar(value: boolean) {
    this._showSidebar = value;
    this.showSidebarChange.emit(value);
  }

  searchInput: string;

  @ViewChild("sidebar")
  sidebar: Sidebar;

  @ViewChild("dt")
  treeTable: TreeTable;

  @ContentChild("caption", { read: TemplateRef })
  captionTemplate: TemplateRef<any>;

  get toggleArrow(): boolean {
    return this.sidebarSize === "medium" ? true : false;
  }

  private readonly debounce = new Subject<StartOperationType>();
  private debounceSub: Subscription;
  private cache: TableCache;

  constructor(
    private readonly filterService: FilterService,
    private readonly transloco: TranslocoService,
    private readonly elementRef: ElementRef,
    private readonly tableService: TableService
  ) {}

  ngOnInit(): void {
    this.globalFilterFields = this.columns?.filter((col) => col.includeInGlobalFilter).map((col) => col.fieldname);
    this.debounceSub = this.debounce.pipe(debounceTime(200)).subscribe((x) => x.operation(x.rowData, x.event));
    this.filterService.register(this.filterName, (value, filter, locale) =>
      this.tableService.filter(value, filter, locale, this.cache)
    );
  }

  stringify(value: unknown): string {
    return JSON.stringify(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items || changes.columns) {
      this.cache = this.tableService.buildPipeCache(this.columns, this.items, this.language);
    }
    if (changes.items) {
      // Reset Searchbox if items are added only
      if (this.searchInput != null && changes.items.currentValue?.length != changes.items.previousValue?.length) {
        this.searchInput = null;
        this.treeTable.filterGlobal(null, this.filterName);
      }
      this.calculateExtraProperties(changes.items.currentValue, this.columns);
      this.calculateButtonItems(
        changes.items.currentValue,
        this.tableOperations,
        this.newOperations,
        this.captionOperations,
        this.footerOperations
      );
    }
    if (changes.columns) {
      this.globalFilterFields = this.columns?.filter((col) => col.includeInGlobalFilter).map((col) => col.fieldname);

      this.calculateExtraProperties(this.items, changes.columns.currentValue);
    }
    if (changes.tableOperations) {
      this.calculateButtonItems(
        this.items,
        changes.tableOperations.currentValue,
        this.newOperations,
        this.captionOperations,
        this.footerOperations
      );
    }
    if (changes.newOperations) {
      this.calculateButtonItems(
        this.items,
        this.tableOperations,
        changes.newOperations.currentValue,
        this.captionOperations,
        this.footerOperations
      );
    }
    if (changes.captionOperations) {
      this.calculateButtonItems(
        this.items,
        this.tableOperations,
        this.newOperations,
        changes.captionOperations.currentValue,
        this.footerOperations
      );
    }
    if (changes.footerOperations) {
      this.calculateButtonItems(
        this.items,
        this.tableOperations,
        this.newOperations,
        this.captionOperations,
        changes.footerOperations.currentValue
      );
    }
  }

  ngOnDestroy(): void {
    this.debounceSub.unsubscribe();
    delete this.filterService.filters[this.filterName];
  }

  resetPagination(): void {
    if (this.treeTable) {
      this.treeTable.first = 0;
    }
  }

  getButtonIcon(icon: string | ((item: unknown) => string), rowData: unknown): string {
    return typeof icon === "function" ? icon(rowData) : icon;
  }

  prepareTagChildren(col: TableColumn, rowData: unknown, tag: TableTag): MenuItem[] {
    return tag.children.map((t) => ({
      label: t.label,
      command: () => (col.tagClick ? col.tagClick(rowData, t.value) : {}),
    }));
  }

  startOperation(event: Event, rowData: unknown, operation: (item: unknown, event?: unknown) => void): void {
    if (operation != null && typeof operation == "function") {
      this.debounce.next({
        event: this.eventTarget ? <Event>{ target: this.eventTarget } : event,
        rowData: rowData,
        operation: operation,
      });
    }
    this.eventTarget = null;
  }

  getDisabledState(item: unknown, disabledState: boolean | ((item: unknown) => boolean)): boolean {
    return typeof disabledState === "function" ? disabledState(item) : disabledState;
  }

  getBlockableElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  sort(event: SortEvent): void {
    this.tableService.sort(event, this.cache);
  }

  private calculateExtraProperties(items: unknown[], columns: TableColumn[]): void {
    function calculate<K extends keyof TableColumn, T = ReturnType<TableColumn[K]>>(prop: K): Record<string, T>[] {
      return columns?.map((c) => {
        const reduceRecursively = (item: unknown, acc: Record<string, T>): Record<string, T> => {
          if (c[prop]) {
            acc[(<any>item).id] = c[prop](item);
            if ((<any>item)?.children) {
              (<any>item).children.forEach((v) => reduceRecursively(v, acc));
            }
          }
          return acc;
        };

        return items?.reduce<Record<string, T>>((acc, v) => {
          reduceRecursively(v, acc);
          return acc;
        }, {});
      });
    }
    this.calculatedTags = calculate("tags");
    this.calculatedTooltips = calculate("tooltip");
    this.calculatedIcons = calculate("icon");
  }

  private calculateButtonItems(
    items: unknown[],
    tableOperations: TableOperation[],
    newOperations: TableOperation[],
    captionOperations: TableOperation[],
    footerOperations: TableOperation[]
  ): void {
    const calculate = (ops: TableOperation[], rowData: unknown): any =>
      ops?.map((op) =>
        this.getTableMenuItems(op.items, rowData)
          ?.filter((x) => !x.canOperate || x.canOperate(rowData))
          .map((x) => ({ ...x, command: (e) => this.startOperation(e, rowData, x.operation) }))
      );
    const buttonItems = (items ?? []).reduce<Record<string, MenuItem[][]>>(
      (acc, v) => ((acc[(<any>v).id] = calculate(tableOperations, v)), acc),
      {}
    );
    buttonItems[this.newOp.id] = calculate(newOperations, this.newOp);
    buttonItems[this.captionOp.id] = calculate(captionOperations, this.captionOp);
    buttonItems[this.footerOp.id] = calculate(footerOperations, this.footerOp);
    this.calculatedButtonItems = buttonItems;
  }

  private getTableMenuItems(
    items: TableMenuItem[] | ((item: unknown) => TableMenuItem[]),
    rowData: unknown
  ): TableMenuItem[] {
    const isData = items instanceof Function;

    return (isData ? items(rowData) : items) ?? [];
  }
}
