<ng-template #itemTemplate let-update>
  <span>{{ update.date | appDate: { dateStyle: "short" } : language }} — {{ update.version }}</span>
  <div [innerHTML]="update.changes | safe: 'html'"></div>
</ng-template>
<app-dashboard-block-layout *transloco="let t" [label]="t('dashboard.ankaadiaUpdates')" (click)="showUpdateDialog()">
  <ul class="p-reset" *ngIf="updates?.length">
    <li [class.mb-2]="i < updates.length - 1" *ngFor="let item of updates; index as i">
      <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
    </li>
  </ul>
</app-dashboard-block-layout>
