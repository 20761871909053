import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { AbstractControl, FormArray, FormControl } from "@angular/forms";
import {
  ANYTHING,
  DocumentWriteMode,
  getDocumentSelectionDependencies,
  getSingleDocumentSetFormats,
  getSingleDocumentSetTypes,
  isDocumentSelectionMode,
  isSingleDocumentSet,
} from "@ankaadia/ankaadia-shared";
import {
  Document,
  DocumentFile,
  DocumentMode,
  DocumentSelectionCriterion,
  StaticDataModel,
  StaticDataType,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@ngneat/transloco";
import { clone } from "lodash";
import { FileUpload } from "primeng/fileupload";
import { Observable, concat, forkJoin, map, of, toArray } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { MessageDialogService } from "../../../shared/message-dialog/message-dialog.service";
import { DownloadService } from "../../../shared/services/download.service";
import { FileUploadService } from "../../../shared/services/file-upload.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { CriterionValueSelection } from "../document-criteria-config/document-criteria.service";
import { DocumentFileForm, DocumentForm, DocumentSetForm, SingleSetConfigForm } from "../document-form.model";
import { DocumentFormBuilder, DocumentFormService } from "../document-form.service";
import { DocumentsService } from "../documents.service";
import { SettingsService } from "../../../shared/services/settings.service";

@Component({
  selector: "app-document-selector",
  templateUrl: "./document-selector.component.html",
  styleUrls: ["./document-selector.component.scss"],
  providers: [FileUploadService],
})
export class DocumentSelectorComponent implements OnInit, OnChanges, AfterViewInit {
  private readonly language = this.transloco.getActiveLang();
  private singleDocumentSetFormatMap: Record<string, Observable<StaticDataModel[]>> = {};
  private uploadQueue: { blob: string; file: File }[];

  protected readonly DocumentWriteMode = DocumentWriteMode;
  protected readonly DocumentMode = DocumentMode;
  protected readonly isDocumentSelectionMode = isDocumentSelectionMode;

  protected singleDocumentSetTypes$: Observable<StaticDataModel[]>;
  protected formats$: Observable<StaticDataModel[]>;

  @Input({ required: true })
  form: DocumentSetForm;

  @Input({ required: true })
  documentForm: DocumentForm;

  @Input({ required: true })
  mode: DocumentMode;

  @Input({ required: true })
  readonly: boolean;

  @Input()
  preselectTags: string[];

  @Input()
  availableTags?: string[];

  @Input()
  customDownload: (file: { blob: string; file: File }) => void;

  @Input()
  customUpload: (files: { blob: string; file: File }[]) => void;

  @Input()
  processLanguage?: string;

  @Input()
  singleFileUpload: boolean;

  @Output()
  readonly upload = new EventEmitter<boolean>();

  @ViewChild("fileUpload")
  fileUpload: FileUpload;

  @ContentChild("toolbar", { read: TemplateRef })
  toolbarTemplate: TemplateRef<any>;

  @ContentChild("footer", { read: TemplateRef })
  footerTemplate: TemplateRef<any>;

  get document(): Document {
    return this.documentForm.getRawValue();
  }

  get files(): FormArray<DocumentFileForm> {
    return this.form.controls.files;
  }

  get isSingleDocumentSet(): boolean {
    return isSingleDocumentSet(this.documentForm.controls.type.value);
  }

  get singleDocumentSetFormats$(): Observable<StaticDataModel[]> {
    return this.singleDocumentSetFormatMap[this.documentForm.controls.type.value];
  }

  get isInMetadataMode(): boolean {
    return this.documentForm.controls.writeMode.value === DocumentWriteMode.Metadata;
  }

  protected fileToPreview: DocumentFile = null;
  protected fileToPreviewUrl: string = null;
  protected previewVisible = false;

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly documentService: DocumentsService,
    private readonly fileUploadService: FileUploadService,
    private readonly errorService: MessageDialogService,
    private readonly downloadService: DownloadService,
    private readonly documentFormService: DocumentFormService,
    protected readonly settings: SettingsService
  ) {}

  ngOnInit(): void {
    this.fileUploadService.onProgress.subscribe((x) => {
      this.fileUpload.progress = x;
      this.fileUpload.cd.detectChanges();
    });
    this.fileUploadService.onUpload.subscribe(() => this.onUpload());
    this.fileUploadService.onError.subscribe((x) => this.onError(x));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form) {
      this.initFiles();
    }
    if (changes.documentForm) {
      this.initFiles();
    }
    if (changes.processLanguage) {
      this.updateStaticData(changes.processLanguage.currentValue);
    }
  }

  ngAfterViewInit(): void {
    this.initFiles();
  }

  triggerUpload(): void {
    this.fileUpload.upload();
  }

  selectFiles(selectedFiles: File[]): void {
    if (this.singleFileUpload) {
      this.files.clear({ emitEvent: false });
      this.files.updateValueAndValidity();
    }

    // Filter Files with same file name
    const builder = this.createDocumentFormBuilder();
    const finallySelectedFiles = this.fileUploadService.ensureValidFiles(this.fileUpload, selectedFiles);
    const tags =
      !this.isSingleDocumentSet &&
      !isDocumentSelectionMode(this.mode) &&
      finallySelectedFiles.length === 1 &&
      this.preselectTags
        ? this.preselectTags
        : [];

    for (const file of finallySelectedFiles) {
      if (this.findIndexInFiles(file) === -1) {
        const control = builder.createFileForm(file, true, tags);
        this.files.push(control);
        control.markAsDirty();
      }
    }

    this.fileUpload.progress = 0;
    this.changeDetector.detectChanges();
  }

  uploadFiles(): void {
    const fileControls = this.files.controls.filter((file) => file.controls.file.value);
    this.validateFiles(fileControls.map((control) => control.getRawValue().file)).subscribe({
      next: () => {
        const controlsToUpload = fileControls.filter((control) => !control.controls.blob.value);
        controlsToUpload.forEach((control) => control.controls.blob.setValue(uuidv4()));
        this.uploadQueue = controlsToUpload.map((control) => {
          const { blob, file } = control.getRawValue();
          return { blob, file };
        });

        if (this.customUpload) {
          this.customUpload(this.uploadQueue);
        } else {
          this.onUpload();
        }
      },
      error: (e) => this.onError(e),
    });
  }

  previewFile(fileForm: DocumentFileForm): void {
    const documentFile = fileForm.getRawValue();
    const { file, blob } = documentFile;

    const url$ = blob
      ? this.documentService.getDownloadUrl(blob, this.document.organizationId, this.document.candidateId)
      : of(URL.createObjectURL(file));

    url$.subscribe((url) => {
      this.fileToPreview = documentFile;
      this.fileToPreviewUrl = url;
      this.previewVisible = true;
    });
  }

  replaceFile(fileForm: DocumentFileForm): void {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".docx, .xlsx";

    input.onchange = (e): void => {
      const selectedFile = (e.target as HTMLInputElement).files[0];
      if (selectedFile.name && selectedFile.size) {
        fileForm.controls.blob.setValue(null);
        fileForm.controls.name.setValue(selectedFile.name);
        fileForm.controls.size.setValue(selectedFile.size);
        fileForm.controls.file.setValue(selectedFile);
        this.files.markAsDirty();
      }
    };
    input.click();
  }

  removeFile(fileForm: DocumentFileForm): void {
    const index = this.files.controls.indexOf(fileForm);
    this.files.removeAt(index);
    this.files.markAsDirty();
    this.changeDetector.detectChanges();
  }

  downloadFile(fileForm: DocumentFileForm): void {
    const { file, blob, name } = fileForm.getRawValue();
    if (this.customDownload) {
      this.customDownload({ blob, file });
      return;
    }

    if (blob) {
      const { organizationId, candidateId } = this.document;
      this.documentService
        .getDownloadUrl(blob, organizationId, candidateId)
        .subscribe((url) => this.downloadService.downloadFile(name, url));
    } else {
      this.downloadService.downloadFile(file.name, URL.createObjectURL(file));
    }
  }

  getTags(fileForm: DocumentFileForm): string | string[] {
    return fileForm.controls.tags.value ?? null;
  }

  setTags(tags: string[], fileForm: DocumentFileForm): void {
    const control = fileForm.controls.tags;
    if (control) {
      control.setValue(tags);
      this.files.markAsDirty();
    }
  }

  setSelectionValue(values: string[], control: AbstractControl, index: number): void {
    const selectionValues = clone<string[][]>(control.value ?? []);
    selectionValues[index] = values;
    this.fixupCriteria(selectionValues);
    control.setValue(selectionValues);
    control.markAsDirty();
  }

  isCriterionReadonly(control: AbstractControl, index: number): boolean {
    const criteria = this.documentForm.controls.selectionCriteria.value;
    // a child criterion is readonly if parent criterion is "*"
    for (const dependency of getDocumentSelectionDependencies()) {
      if (dependency.children.includes(criteria[index])) {
        const parentIndex = criteria.indexOf(dependency.parent);
        if (parentIndex > -1) {
          if (control.value?.[parentIndex]?.includes(ANYTHING)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getSelectionValues(fileForm: DocumentFileForm): FormControl<string[][]> {
    return fileForm.controls.selectionValues;
  }

  getCriterionParameters(control: AbstractControl): CriterionValueSelection[] {
    const criteria = this.documentForm.controls.selectionCriteria.value;
    return criteria.map((criterion, index) => ({ criterion: criterion, values: control.value?.[index] }));
  }

  getSingleDocumentSetConfig(fileForm: DocumentFileForm): FormArray<SingleSetConfigForm> {
    if (!this.isSingleDocumentSet) {
      return null;
    }
    return fileForm.controls.singleSetConfig;
  }

  getSingleDocumentSetType(fileForm: DocumentFileForm, index: number): string {
    const config = this.getSingleDocumentSetConfig(fileForm);
    return config.get([index, "type"] as const).value;
  }

  setSingleDocumentSetType(fileForm: DocumentFileForm, value: string, index: number): void {
    const config = this.getSingleDocumentSetConfig(fileForm);
    config.get([index, "type"] as const).setValue(value);
    config.markAsDirty();
  }

  getSingleDocumentSetFormats(fileForm: DocumentFileForm, index: number): string[] {
    const config = this.getSingleDocumentSetConfig(fileForm);
    return config.get([index, "formats"] as const).value;
  }

  setSingleDocumentSetFormats(fileForm: DocumentFileForm, value: string[], index: number): void {
    const config = this.getSingleDocumentSetConfig(fileForm);
    config.get([index, "formats"] as const).setValue(value);
    config.markAsDirty();
  }

  addSingleDocumentSet(fileForm: DocumentFileForm): void {
    const singleSetConfig = this.getSingleDocumentSetConfig(fileForm);
    const builder = this.createDocumentFormBuilder();
    singleSetConfig.push(builder.createSingleSetConfigForm(null));
    singleSetConfig.markAsDirty();
  }

  deleteSingleDocumentSet(fileForm: DocumentFileForm, index: number): void {
    const singleSetConfig = this.getSingleDocumentSetConfig(fileForm);
    singleSetConfig.removeAt(index);
    singleSetConfig.markAsDirty();
    if (!singleSetConfig.length) {
      this.addSingleDocumentSet(fileForm);
    }
  }

  private fixupCriteria(selectionValues: string[][]): void {
    const criteria = this.documentForm.controls.selectionCriteria.value;
    // if parent is set to "*", set child values to "*"
    // if parent is unselected, unselect children

    const dependencies = getDocumentSelectionDependencies();
    criteria.forEach((criterion, index) => {
      const dependency = dependencies.find((dependency) => dependency.parent === criterion);
      if (!dependency?.children.length) {
        return;
      }

      const criterionValues = selectionValues[index];
      if (criterionValues?.includes(ANYTHING)) {
        for (const child of dependency.children) {
          this.setCriterion(child, [ANYTHING], criteria, selectionValues);
        }
      } else if (!criterionValues?.length) {
        for (const child of dependency.children) {
          this.setCriterion(child, [], criteria, selectionValues);
        }
      }
    });
  }

  private setCriterion(
    criterion: DocumentSelectionCriterion,
    values: string[],
    criteria: DocumentSelectionCriterion[],
    selectionValues: string[][]
  ): void {
    const index = criteria.indexOf(criterion);
    if (index > -1) {
      selectionValues[index] = values;
    }
  }

  private validateFiles(files: File[]): Observable<any> {
    return files.length > 0 ? concat(...files.map((f) => this.fileUploadService.validate(f))).pipe(toArray()) : of({});
  }

  private initFiles(): void {
    if (this.fileUpload) {
      this.fileUpload.files = [];
      this.fileUpload.progress = 100;
      this.fileUpload.cd.detectChanges();
      this.changeDetector.detectChanges();
    }
  }

  private onUpload(): void {
    const upload = this.uploadQueue.pop();
    if (upload) {
      this.documentService
        .getUploadUrl(upload.blob, this.document.organizationId, this.document.candidateId)
        .subscribe(({ url }) => this.fileUploadService.upload(upload.file, url));
    } else {
      this.upload.emit(true);
    }
  }

  private onError(event: any): void {
    this.errorService.showMessage(
      translate("file.uploadFailed", null, this.processLanguage),
      translate("file.bad", { name: event.message }, this.processLanguage)
    );
    this.upload.emit(false);
  }

  private findIndexInFiles(file: File): number {
    return this.files.value.findIndex((x) => x.name === file.name && x.size === file.size && x.type === file.type);
  }

  private updateStaticData(language: string): void {
    this.formats$ = this.loadStaticData(StaticDataType.DocumentFormats, language);
    this.singleDocumentSetTypes$ = this.loadStaticData(StaticDataType.AllowedUploadFileTypes, language).pipe(
      map((xs) => xs.filter((x) => !isSingleDocumentSet(x.value)))
    );

    this.singleDocumentSetFormatMap = getSingleDocumentSetTypes().reduce((acc, type) => {
      acc[type] = this.loadStaticData(StaticDataType.DocumentFormats, language).pipe(
        map((xs) => xs.filter((x) => getSingleDocumentSetFormats(type).includes(x.value)))
      );
      return acc;
    }, {});
  }

  private loadStaticData(type: StaticDataType, language: string): Observable<StaticDataModel[]> {
    return forkJoin([
      this.staticDataService.getStaticData(type, language, {
        candidateId: this.document?.candidateId,
        organizationId: this.document?.organizationId,
      }),
      this.staticDataService.getStaticData(type, this.language, {
        candidateId: this.document?.candidateId,
        organizationId: this.document?.organizationId,
      }),
    ]).pipe(map(([data, fallback]) => data ?? fallback));
  }

  private createDocumentFormBuilder(): DocumentFormBuilder {
    return this.documentFormService.createDocumentFormBuilder(this.document, {
      documentMode: this.mode,
      isReadonly: this.readonly,
    });
  }
}
