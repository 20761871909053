import { Directive, Input, OnInit } from "@angular/core";
import { EditableColumn } from "primeng/table";
import { AnyFunction } from "ts-essentials";

/**
 * PrimeNG's `pEditableColumn` intercepts navigation keys (like arrows) and navigates inside the table.
 * Sane people, of course, do not normally need this feature, so this is our attempt to disable it.
 */
@Directive({ selector: "[pEditableColumn]" })
export class TableEditableColumnNoKeyboardNavigationDirective implements OnInit {
  private static readonly methodSelector = <T extends keyof EditableColumn>(xs: T[]): readonly T[] => xs;
  private static readonly methods = TableEditableColumnNoKeyboardNavigationDirective.methodSelector([
    "onTabKeyDown",
    "onShiftKeyDown",
    "onArrowDown",
    "onArrowUp",
    "onArrowLeft",
    "onArrowRight",
  ]);

  private readonly sources: Record<keyof typeof TableEditableColumnNoKeyboardNavigationDirective.methods, AnyFunction> =
    <any>{};

  @Input()
  pEditableColumnNoKeyboardNavigation: boolean;

  constructor(private readonly editableColumn: EditableColumn) {}

  ngOnInit(): void {
    for (const method of TableEditableColumnNoKeyboardNavigationDirective.methods) {
      this.sources[method] = this.editableColumn[method];
      this.editableColumn[method] = (...args: any[]): any => {
        if (!this.pEditableColumnNoKeyboardNavigation) {
          return this.sources[method].call(this.editableColumn, ...args);
        }
      };
    }
  }
}
