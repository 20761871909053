import { v4 as uuidv4 } from "uuid";

/* eslint-disable no-console */
export async function benchmark<T>(label: string, func: () => Promise<T>): Promise<T> {
  const identifier = `benchmark [${uuidv4()}] - ${label}`;

  console.time(identifier);
  const result = await func();
  console.timeEnd(identifier);

  return result;
}
