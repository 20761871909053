import { Injectable } from "@angular/core";
import { DefaultTranspiler, Translation } from "@ngneat/transloco";
import { ApplicationInsightsService } from "../services/application-insights.service";

// We do not want that e failed interpolation of a string causes an error

@Injectable({ providedIn: "root" })
export class RelaxedTranslocoTranspiler extends DefaultTranspiler {
  override transpile(value: any, params: any, translation: Translation, key: string): any {
    try {
      return super.transpile(value, params, translation, key);
    } catch (e) {
      ApplicationInsightsService.bigTroubleNotifier("Transloco Transpiler. Interpolation failed", {
        exception: e,
        exceptionMessage: e.message,
        key: key,
        value: value,
        params: params,
      });
      return key;
    }
  }
}
