<ng-container *transloco="let t">
  <div class="layout-wrapper p-input-filled layout-overlay-menu" style="height: 100px">
    <div class="flex justify-content-center align-items-center" style="width: 100%; height: 100%">
      <p-card header="Anmeldung zur Rezertifizierung des Gütezeichens" [style]="{ width: '800px' }">
        <div>
          <div class="flex grid justify-content-center align-items-center">
            <div class="col-3">
              <img
                src="../../../assets/partner/guete/RAL_GZ_FaireAnwerbungFair+Recruitment_RGB-518h.webp"
                alt="Logo"
                style="width: 100%"
              />
            </div>
            <div class="col-9">
              <div *ngIf="message">
                <div [innerHtml]="message"></div>
              </div>
              <div *ngIf="!message">
                <form [formGroup]="form">
                  <div class="p-fluid formgrid grid">
                    <div class="field col-12">
                      <label for="organizationName">{{ t("organization.title") }}</label>
                      <input
                        pInputText
                        id="organizationName"
                        type="text"
                        [formControlName]="nameOf('organizationName')"
                      />
                      <small class="p-error" *ngIf="form.controls.organizationName.errors?.required">
                        {{ t("common.required") }}
                      </small>
                    </div>
                    <div class="field col-12">
                      <label for="organizationType">{{ t("organizationType.title") }}</label>
                      <p-dropdown
                        id="organizationType"
                        [options]="organizationTypes$ | async"
                        [formControlName]="nameOf('organizationType')"
                      ></p-dropdown>
                      <small class="p-error" *ngIf="form.controls.organizationType.errors?.required">
                        {{ t("common.required") }}
                      </small>
                    </div>
                    <div class="field col-2">
                      <label for="salutation">{{ t("salutation.title") }}</label>
                      <p-dropdown
                        id="salutation"
                        [options]="salutations$ | async"
                        [formControlName]="nameOf('salutation')"
                      ></p-dropdown>
                      <small class="p-error" *ngIf="form.controls.salutation.errors?.required">
                        {{ t("common.required") }}
                      </small>
                    </div>
                    <div class="field col-5">
                      <label for="firstname">{{ t("firstName.title") }}</label>
                      <input pInputText id="firstname" type="text" [formControlName]="nameOf('firstName')" />
                      <small class="p-error" *ngIf="form.controls.firstName.errors?.required">
                        {{ t("common.required") }}
                      </small>
                    </div>
                    <div class="field col-5">
                      <label for="lastname">{{ t("lastName.title") }}</label>
                      <input pInputText id="lastname" type="text" [formControlName]="nameOf('lastName')" />
                      <small class="p-error" *ngIf="form.controls.lastName.errors?.required">
                        {{ t("common.required") }}
                      </small>
                    </div>
                    <div class="field col-6">
                      <label for="emailAdress">{{ t("email.title") }}</label>
                      <input pInputText id="emailAdress" type="text" [formControlName]="nameOf('emailAddress')" />
                      <small class="p-error" *ngIf="form.controls.emailAddress.errors?.required">
                        {{ t("common.required") }}
                      </small>
                      <small class="p-error" *ngIf="form.controls.emailAddress.errors?.pattern">
                        {{ t("email.invalid") }}
                      </small>
                      <small class="p-error" *ngIf="form.errors?.emailAddressMismatch">
                        {{ t("email.unequal") }}
                      </small>
                    </div>
                    <div class="field col-6">
                      <label for="emailAdress2">{{ t("email.title") }} - Wiederholung</label>
                      <input pInputText id="emailAdress2" type="text" [formControlName]="nameOf('emailAddress2')" />
                      <small class="p-error" *ngIf="form.controls.emailAddress2.errors?.pattern">
                        {{ t("email.invalid") }}
                      </small>
                      <small class="p-error" *ngIf="form.errors?.emailAddressMismatch">
                        {{ t("email.unequal") }}
                      </small>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <p-splitter></p-splitter>
        </div>
        <ng-template pTemplate="footer">
          <div class="flex flex-row justify-content-end">
            <p-button
              *ngIf="!message"
              label="Anmelden"
              icon="pi pi-check"
              [disabled]="form.invalid"
              (onClick)="submit($event)"
            ></p-button>
            <p-button *ngIf="!!message" [label]="buttonLabel" (onClick)="back()"></p-button>
          </div>
        </ng-template>
      </p-card>
    </div>
  </div>
</ng-container>
