<div class="flex flex-wrap align-items-center justify-content-center h-screen background-color" *appTranslate="let t">
  <p-card class="w-25rem">
    <div class="flex flex-wrap flex-column align-items-center justify-content-center">
      <p-image src="../../../assets/logo.png" class="m-2" alt="Ankaadia logo" width="150"></p-image>
      <ng-container *ngIf="!inLinkingProcess && !inRedirectingProcess">
        <p class="text-center">{{t("afterSSOLogin.description")}}</p>
        <button pButton class="w-10rem m-2 h-4rem" iconPos="right" (click)="linkAccount() ">
          <div class="flex flex-wrap flex-column align-items-center justify-content-center w-full">
            <i class="pi pi-key mt-2 text-2xl"></i>
            <p class="m-1">{{t("firstLogin.email")}}</p>
          </div>
        </button>
      </ng-container>
      <ng-container *ngIf="inLinkingProcess">
        <p class="text-center">{{t("afterSSOLogin.linkingAccount")}}</p>
      </ng-container>
      <ng-container *ngIf="inRedirectingProcess">
        <p class="text-center">{{t("afterSSOLogin.redirectingTo")}}</p>
      </ng-container>
    </div>
  </p-card>
</div>
