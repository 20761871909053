import { AbstractControl, FormArray, FormGroup, ValidationErrors } from "@angular/forms";
import {
  addressLabelMapping,
  AddressType,
  bankDetailsLabelMapping,
  candidateLabelMapping,
  competenciesLabelMapping,
  emailPattern,
  experienceLabelMapping,
  ExperienceType,
  furtherEducationLabelMapping,
  getCityState,
  getCurrencyForCountry,
  IAddressModel,
  IBankDetailsModel,
  ICandidateOSModel,
  ICompetenciesModel,
  ICompetencyModel,
  IExperienceModel,
  IFurtherEducation,
  ILanguageSkillModel,
  IOtherModel,
  IQualificationModel,
  isRealImmigrationCountry,
  IVaccinationModel,
  languageLevelGermanCertificateMapping,
  languageSkillLabelMapping,
  MedHealthCareCoreProfessions,
  nameofFactory,
  otherLabelMapping,
  qualificationLabelMapping,
  VaccinationLabelMapping,
  YearsOfSkillExperience,
  KnowledgeProficiency,
} from "@ankaadia/ankaadia-shared";
import {
  AnabinAssetType,
  EmploymentRelationshipType,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
  WorkExperiencePrecissionEnum,
  WorkingTimeType,
  OccupationSkillSet,
  CustomSkillSet,
} from "@ankaadia/graphql";
import { translate } from "@ngneat/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { isEqual } from "lodash";
import { PrimeIcons } from "primeng/api";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  startWith,
  switchMap,
  tap,
} from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { AnabinService } from "../../features/anabin/anabin.service";
import {
  ExistingImmigrationCountry,
  StaticDataWithCountry,
} from "../../features/candidate-filter/candidate-filter.model";
import { Icon } from "../../shared/services/icon.helper";
import { safeForkJoin } from "../../shared/services/safe-fork-join";
import { StaticDataService } from "../../shared/static-data/static-data.service";
import { OrganizationSpecific } from "../organization-specific";
import { ProfessionService } from "../../features/profession/profession.service";

const nameOf = nameofFactory<ICandidateOSModel>();
const nameOfQualification = nameofFactory<IQualificationModel>();
const nameOfExperience = nameofFactory<IExperienceModel>();
const nameOfLanguageSkill = nameofFactory<ILanguageSkillModel>();
const nameOfVaccinations = nameofFactory<IVaccinationModel>();
const nameOfFurtherEducation = nameofFactory<IFurtherEducation>();
const nameOfAddress = nameofFactory<IAddressModel>();
const nameOfOther = nameofFactory<IOtherModel>();
const nameOfBankDetails = nameofFactory<IBankDetailsModel>();
const nameOfCompetencies = nameofFactory<ICompetenciesModel>();
const nameOfCompetency = nameofFactory<ICompetencyModel>();

function getAddressTypeOptions(): StaticDataModel[] {
  return [
    { value: AddressType.IdAddress, label: translate("enum.AddressType.IdAddress") },
    { value: AddressType.CountryOfOriginAddress, label: translate("enum.AddressType.CountryOfOriginAddress") },
    { value: AddressType.CurrentAddress, label: translate("enum.AddressType.CurrentAddress") },
    { value: AddressType.ImmigrationCountryAddress, label: translate("enum.AddressType.ImmigrationCountryAddress") },
  ];
}

function getAddressTypeLabel(addressType: AddressType): string | AddressType {
  return getAddressTypeOptions().find((x) => x.value === addressType)?.label || addressType;
}

async function AddressesValidator(control: AbstractControl, field: FormlyFieldConfig): Promise<ValidationErrors> {
  const addresses = control.value as IAddressModel[];

  if (!addresses || addresses.length === 0) {
    return Promise.resolve({ addresses: { message: translate("addresses.isMissing") } });
  }

  if (!addresses.every((x) => IsAddressTypeCombinationValid(x.addressType))) {
    return Promise.resolve({ addresses: { message: translate("addressType.invalidCombination") } });
  }

  const countAddressTypes = (addresses: IAddressModel[], addressType: AddressType): number =>
    addresses.filter((x) => x.addressType?.includes(addressType)).length;

  const idAddressCount = countAddressTypes(addresses, AddressType.IdAddress);
  if (idAddressCount === 0) {
    return Promise.resolve({ addresses: { message: translate("addresses.idAddressIsMissing") } });
  }

  const types: AddressType[] = [
    AddressType.IdAddress,
    AddressType.ImmigrationCountryAddress,
    AddressType.CountryOfOriginAddress,
    AddressType.CurrentAddress,
  ];
  for (const type of types) {
    const count = countAddressTypes(addresses, type);
    if (count > 1) {
      return Promise.resolve({
        addresses: { message: `${translate("addresses.mustBeUnique")}: ${getAddressTypeLabel(type)} ` },
      });
    }
  }

  const immigrationCountrySubject = field.options.formState.immigrationCountry as BehaviorSubject<string>;
  const immigrationCountry = immigrationCountrySubject.value;
  const invalidImmigrationAddresses = addresses.filter(
    (address) =>
      address.addressType?.includes(AddressType.ImmigrationCountryAddress) && address.country !== immigrationCountry
  );
  if (invalidImmigrationAddresses.length > 0) {
    const tabNames = invalidImmigrationAddresses.map((x) => renderAddressLabel(x)).join(", ");
    return Promise.resolve({
      addresses: { message: `${tabNames}: ${translate("addresses.immigrationCountryMismatch")}` },
    });
  }

  const addressesWithMissingType = addresses.filter((x) => !x.addressType || x.addressType.length === 0);
  if (addressesWithMissingType.length > 0) {
    const tabNames = addressesWithMissingType.map((x) => renderAddressLabel(x)).join(", ");
    return Promise.resolve({ addresses: { message: `${tabNames}: ${translate("addressType.required")}` } });
  }

  return null;
}

async function AddressTypeValidator(control: AbstractControl): Promise<ValidationErrors> {
  const addressType = control.value;
  if (!IsAddressTypeCombinationValid(addressType)) {
    return Promise.resolve({ addressType: { message: translate("addressType.invalidCombination") } });
  }
  return null;
}

function IsAddressTypeCombinationValid(addressType: string[]): boolean {
  return (
    !addressType ||
    addressType.length == 1 ||
    !(
      addressType.includes(AddressType.ImmigrationCountryAddress) &&
      addressType.includes(AddressType.CountryOfOriginAddress)
    )
  );
}

function WorkExperienceValidator(control: AbstractControl): ValidationErrors | null {
  if (!(control as any)?._fields?.some((x) => x.props.required)) return null; //only used this when the field is required (candidate profile)
  const experience =
    (control.value as IExperienceModel[])?.filter(
      (x) => !x.experienceType || x.experienceType === ExperienceType.Normal
    ) ?? [];
  const noExperienceAvailable = control?.parent?.value?.noExperienceAvailable;

  if (!noExperienceAvailable && experience.length == 0) {
    return { workExperience: { message: translate("common.validation.required") } };
  }
  return null;
}

function renderAddressLabel(address: IAddressModel): string {
  return (
    (address?.addressType || [])
      .map((x) => getAddressTypeOptions().find((y) => y.value === x)?.label)
      .filter((x) => x)
      .join(" / ") || translate("addresses.newAddress")
  );
}

/**
 * It is very important on which level the key field is set. This determines if the object below is required or not
 * One need always test this in the candidate edit view and in the psa edit view
 */

export const candidateProfileFactory: (
  language: string,
  specifics: OrganizationSpecific,
  staticDataService: StaticDataService,
  anabinService: AnabinService,
  professionService: ProfessionService,
  requiredFields?: string[],
  workExperiencePrecission?: WorkExperiencePrecissionEnum,
  educationPrecision?: WorkExperiencePrecissionEnum
) => FormlyFieldConfig[] = (
  language,
  specifics,
  staticDataService,
  anabinService,
  professionService,
  requiredFields,
  workExperiencePrecission,
  educationPrecision
) => {
  const getImmigrationSpecificStaticData = (
    // Please check the immigrationCountryOverwriter.service.ts if you add any type here, since it does not require the organizationId to be sent.
    staticDataType:
      | StaticDataType.FederalStates
      | StaticDataType.KnownCities
      | StaticDataType.PathOfRecognition
      | StaticDataType.PreferredTypeOfImmigration,
    immigrationCountry?: ExistingImmigrationCountry
  ): Observable<StaticDataWithCountry[]> => {
    const immigrationCountries: ExistingImmigrationCountry[] = immigrationCountry
      ? [immigrationCountry]
      : [SupportedImmigrationCountry.De, SupportedImmigrationCountry.At];

    const contexts = immigrationCountries.map((immigrationCountry) => ({ immigrationCountry: immigrationCountry }));

    const observables = contexts.map((context) =>
      staticDataService
        .getStaticData(staticDataType, language, context)
        .pipe(
          map((entries) =>
            entries.map<StaticDataWithCountry>((entry) => ({ ...entry, country: context.immigrationCountry }))
          )
        )
    );

    return safeForkJoin(observables, []).pipe(map((results) => results.flat()));
  };

  return [
    {
      type: "tabs",
      props: {
        vertical: true,
        tabStateKey: "candidate-vertical-tabs",
      },
      fieldGroup: [
        {
          id: "general",
          props: {
            label: translate("masterData.title"),
            icon: PrimeIcons.USER_EDIT,
          },
          hideExpression: (_model, formState) => !!(formState?.sharedTabs && formState?.sharedTabs?.general === false),
          fieldGroupClassName: "p-fluid",
          fieldGroup: [
            {
              fieldGroupClassName: "p-fluid formgrid grid",
              fieldGroup: [
                {
                  key: nameOf("title"),
                  type: "dropdown",
                  className: "field col-12 lg:col-2",
                  props: {
                    label: translate(candidateLabelMapping.title),
                    filter: true,
                    filterBy: "label",
                    placeholder: translate("title.placeholder"),
                    options: staticDataService.getStaticData(StaticDataType.Title, language),
                    staticDataType: StaticDataType.Title,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("title")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("title.required"),
                    },
                  },
                },
                {
                  key: nameOf("salutation"),
                  type: "dropdown",
                  className: "field col-12 lg:col-2",
                  props: {
                    label: translate(candidateLabelMapping.salutation),
                    filter: true,
                    filterBy: "label",
                    placeholder: translate("salutation.placeholder"),
                    options: staticDataService.getStaticData(StaticDataType.Salutation, language),
                    staticDataType: StaticDataType.Salutation,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("salutation")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("salutation.required"),
                    },
                  },
                },
                {
                  key: nameOf("firstname"),
                  type: "input",
                  className: "field col-12 lg:col-2",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.firstname),
                    maxLength: 100,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("firstname")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("firstName.required"),
                    },
                  },
                },
                {
                  key: nameOf("lastname"),
                  type: "input",
                  className: "field col-12 lg:col-3",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.lastname),
                    maxLength: 100,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("lastname")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("lastName.required"),
                    },
                  },
                },
                {
                  key: nameOf("birthname"),
                  type: "input",
                  className: "field col-12 lg:col-3",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.birthname),
                    maxLength: 100,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("birthname")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("birthName.required"),
                    },
                  },
                },
              ],
            },
            {
              fieldGroupClassName: "p-fluid formgrid grid",
              fieldGroup: [
                {
                  key: nameOf("email"),
                  type: "input",
                  className: "field col-12 lg:col-3",
                  props: {
                    type: "email",
                    label: translate(candidateLabelMapping.email),
                    pattern: emailPattern,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("email")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("email.required"),
                      pattern: translate("email.invalid"),
                    },
                  },
                },
                {
                  key: nameOf("phone"), //done
                  type: "input",
                  className: "field col-12 lg:col-3",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.phone),
                    maxLength: 50,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("phone")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("phone.required"),
                    },
                  },
                },
                {
                  key: nameOf("mobilePhone"), //done
                  type: "input",
                  className: "field col-12 lg:col-3",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.mobilePhone),
                    maxLength: 50,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("mobilePhone")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("mobile.required"),
                    },
                  },
                },
                {
                  key: nameOf("immigrationCountryPhone"),
                  type: "input",
                  className: "field col-12 lg:col-3",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.immigrationCountryPhone),
                    required: false,
                    maxLength: 50,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("immigrationCountryPhone")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("immigrationCountryPhone.required"),
                    },
                  },
                },
              ],
            },
            {
              fieldGroupClassName: "p-fluid formgrid grid",
              fieldGroup: [
                {
                  key: nameOf("birthdate"), //done
                  type: "datepicker",
                  className: "field col-12 lg:col-3",
                  props: {
                    label: translate(candidateLabelMapping.birthdate),
                    showIcon: true,
                    inline: false,
                    minDate: new Date(1900, 0, 1),
                    maxDate: new Date(),
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("birthdate")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("birthDate.required"),
                      maxDate: translate("birthDate.invalid"),
                      minDate: translate("birthDate.invalid"),
                    },
                  },
                },
                {
                  key: nameOf("martialStatus"), //done
                  type: "dropdown",
                  className: "field col-12 md:col-6 lg:col-3",
                  props: {
                    filter: true,
                    filterBy: "label",
                    label: translate(candidateLabelMapping.martialStatus),
                    placeholder: translate("maritalStatus.placeholder"),
                    options: staticDataService.getStaticData(StaticDataType.MartialStatus, language),
                    staticDataType: StaticDataType.MartialStatus,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("martialStatus")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("maritalStatus.required"),
                    },
                  },
                },
                {
                  key: nameOf("maritalStatusSince"),
                  type: "datepicker",
                  className: "field col-12 lg:col-3",
                  props: {
                    label: translate(candidateLabelMapping.maritalStatusSince),
                    showIcon: true,
                    inline: false,
                    minDate: new Date(1900, 0, 1),
                    maxDate: new Date(),
                  },
                  expressionProperties: {
                    "props.disabled": (model) =>
                      model.martialStatus == null || ["SINGLE", "UNKNOWN"].includes(model.martialStatus),
                  },
                },
                {
                  key: nameOf("numberOfChildren"), //done
                  type: "input",
                  className: "field col-12 md:col-6 lg:col-3",
                  props: {
                    type: "number",
                    label: translate(candidateLabelMapping.numberOfChildren),
                    min: 0,
                    max: 20,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("numberOfChildren")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("children.required"),
                      min: translate("children.invalid"),
                      max: translate("children.invalid"),
                    },
                  },
                },
              ],
            },
            {
              type: "repeat-tabs",
              key: nameOf("addresses"),
              className: "field col-12",
              props: {
                vertical: false,
                minCount: 1,
                maxCount: 4,
                formatLabel: (data, tabIndex) => data && data.length > tabIndex && renderAddressLabel(data[tabIndex]),
                label: translate(candidateLabelMapping.addresses),
                activeIndex: 0,
                defaultValue: () => ({ id: uuidv4() }),
              },
              asyncValidators: {
                validation: [AddressesValidator],
              },
              hooks: {
                onInit: (fieldConfig): void => {
                  const immigrationCountry = fieldConfig.options.formState
                    .immigrationCountry as BehaviorSubject<string>;

                  immigrationCountry.pipe(distinctUntilChanged(isEqual)).subscribe((immigrationCountry) => {
                    const addressesFormArray = fieldConfig.formControl as FormArray<FormGroup>;
                    addressesFormArray.controls.forEach((addressControl) => {
                      const addressCountry = addressControl.controls.country.value;
                      if (addressCountry === immigrationCountry) return;

                      const addressTypeControl = addressControl.controls.addressType;
                      const addressType = addressTypeControl.value;
                      if (!addressType?.includes(AddressType.ImmigrationCountryAddress)) return;

                      addressTypeControl.setValue(
                        addressType.filter((type) => type !== AddressType.ImmigrationCountryAddress)
                      );
                    });
                  });
                },
              },
              fieldArray: {
                fieldGroupClassName: "p-fluid formgrid grid col",
                fieldGroup: [
                  {
                    type: "input",
                    key: nameOfAddress("id"),
                    props: {
                      type: "hidden",
                    },
                  },
                  {
                    key: nameOfAddress("addressType"), //done
                    type: "multiselect",
                    className: "field col-12 lg:col-9",
                    required: true,
                    props: {
                      label: translate(addressLabelMapping.addressType),
                      placeholder: translate("addressType.placeholder"),
                      options: getAddressTypeOptions(),
                      filter: true,
                      filterBy: "label",
                      enumType: "AddressType",
                    },
                    asyncValidators: {
                      validation: [AddressTypeValidator],
                    },
                  },
                  {
                    key: nameOfAddress("residentSince"),
                    type: "datepicker",
                    className: "field col-12 lg:col-3",
                    required: false,
                    props: {
                      label: translate(addressLabelMapping.residentSince),
                      placeholder: translate("residentSince.placeholder"),
                      showIcon: true,
                      inline: false,
                      minDate: new Date(1900, 0, 1),
                      maxDate: new Date(),
                    },
                  },
                  {
                    key: nameOfAddress("address"), //done
                    type: "textarea",
                    className: "field col-12 lg:col-12",
                    props: {
                      type: "text",
                      label: translate(addressLabelMapping.address),
                      rows: 4,
                      maxLength: 500,
                      required: false,
                    },
                    validation: {
                      messages: {
                        required: translate("address.required"),
                      },
                    },
                  },
                  {
                    key: nameOfAddress("zipcode"), //done
                    type: "input",
                    className: "field col-12 lg:col-3",
                    props: {
                      type: "text",
                      label: translate(addressLabelMapping.zipcode),
                      maxLength: 200,
                    },
                  },
                  {
                    key: nameOfAddress("city"), //done
                    type: "input",
                    className: "field col-12 lg:col-3",
                    props: {
                      type: "text",
                      label: translate(addressLabelMapping.city),
                      maxLength: 200,
                    },
                  },
                  {
                    key: nameOfAddress("region"), //done
                    type: "input",
                    className: "field col-12 lg:col-3",
                    props: {
                      type: "text",
                      label: translate(addressLabelMapping.region),
                      maxLength: 200,
                    },
                  },
                  {
                    key: nameOfAddress("country"), //done
                    type: "dropdown",
                    className: "field col-12 lg:col-3",
                    props: {
                      label: translate(addressLabelMapping.country),
                      placeholder: translate("country.placeholder"),
                      required: false,
                      options: staticDataService.getStaticData(StaticDataType.Countries, language),
                      filter: true,
                      filterBy: "label",
                      staticDataType: StaticDataType.Countries,
                    },
                    hooks: {
                      onInit: (fieldConfig): void => {
                        const formGroup = fieldConfig.formControl.parent as FormGroup;
                        formGroup.controls.addressType.valueChanges.subscribe((addressTypes) => {
                          if (fieldConfig.formControl.value) {
                            return;
                          }

                          if (!addressTypes?.includes(AddressType.ImmigrationCountryAddress)) {
                            return;
                          }

                          const formState = fieldConfig.options.formState;
                          const immigrationCountry = (formState.immigrationCountry as BehaviorSubject<string>).value;

                          if (isRealImmigrationCountry(immigrationCountry)) {
                            fieldConfig.formControl.setValue(immigrationCountry);
                          }
                        });
                      },
                    },
                  },
                ],
              },
            },
            {
              fieldGroupClassName: "p-fluid formgrid grid",
              fieldGroup: [
                {
                  key: nameOf("citizenship"), //done
                  type: "dropdown",
                  className: "field col-12 md:col-6 lg:col-4",
                  props: {
                    label: translate(candidateLabelMapping.citizenship),
                    placeholder: translate("citizenship.placeholder"),
                    filter: true,
                    filterBy: "label",
                    options: staticDataService.getStaticData(StaticDataType.Countries, language),
                    staticDataType: StaticDataType.Countries,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("citizenship")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("citizenship.required"),
                    },
                  },
                },
                {
                  key: nameOf("countryOfOrigin"), //done
                  type: "dropdown",
                  className: "field col-12 md:col-6 lg:col-4",
                  props: {
                    label: translate(candidateLabelMapping.countryOfOrigin),
                    placeholder: translate("country.placeholder"),
                    options: staticDataService.getStaticData(StaticDataType.Countries, language),
                    filter: true,
                    filterBy: "label",
                    staticDataType: StaticDataType.Countries,
                  },
                  expressionProperties: {
                    "model.countryOfOrigin": (model) =>
                      model.citizenship != null && model.countryOfOrigin == null
                        ? model.citizenship
                        : model.countryOfOrigin,
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("countryOfOrigin")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("countryOfOrigin.required"),
                    },
                  },
                },
                {
                  key: nameOf("countryOfHabitualResidence"), //done
                  type: "dropdown",
                  className: "field col-12 md:col-6 lg:col-4",
                  props: {
                    label: translate(candidateLabelMapping.countryOfHabitualResidence),
                    placeholder: translate("country.placeholder"),
                    options: staticDataService.getStaticData(StaticDataType.Countries, language),
                    filter: true,
                    filterBy: "label",
                    staticDataType: StaticDataType.Countries,
                  },
                  expressionProperties: {
                    "model.countryOfHabitualResidence": (model) =>
                      model.citizenship != null && model.countryOfHabitualResidence == null
                        ? model.citizenship
                        : model.countryOfHabitualResidence,
                    "props.required": () =>
                      requiredFields?.includes(`os.profile.${nameOf("countryOfHabitualResidence")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("countryOfHabitualResidence.required"),
                    },
                  },
                },
                {
                  key: nameOf("countryOfBirth"), //done
                  type: "dropdown",
                  className: "field col-12 md:col-6 lg:col-3",
                  props: {
                    label: translate(candidateLabelMapping.countryOfBirth),
                    placeholder: translate("country.placeholder"),
                    options: staticDataService.getStaticData(StaticDataType.Countries, language),
                    filter: true,
                    filterBy: "label",
                    staticDataType: StaticDataType.Countries,
                  },
                  expressionProperties: {
                    "model.countryOfBirth": (model) =>
                      model.citizenship != null && model.countryOfBirth == null
                        ? model.citizenship
                        : model.countryOfBirth,
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("countryOfBirth")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("countryOfBirth.required"),
                    },
                  },
                },
                {
                  key: nameOf("cityOfBirth"), //done
                  type: "input",
                  className: "field col-12 md:col-6 lg:col-3",
                  props: {
                    type: "text",
                    maxLength: 100,
                    label: translate(candidateLabelMapping.cityOfBirth),
                    placeholder: translate("cityOfBirth.placeholder"),
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("cityOfBirth")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("cityOfBirth.required"),
                    },
                  },
                },
                {
                  key: nameOf("passNumber"), //done
                  type: "input",
                  className: "field col-12 md:col-6 lg:col-3",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.passNumber),
                    maxLength: 100,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("passNumber")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("passNumber.required"),
                    },
                  },
                },
                {
                  key: nameOf("passAuthority"),
                  type: "input",
                  className: "field col-12 md:col-6 lg:col-3",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.passAuthority),
                    maxLength: 350,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("passAuthority")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("passAuthority.required"),
                    },
                  },
                },
                {
                  key: nameOf("idCardNumber"), //done
                  type: "input",
                  className: "field col-12 md:col-6 lg:col-3",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.idCardNumber),
                    maxLength: 100,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("idCardNumber")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("idCardNumber.required"),
                    },
                  },
                },
                {
                  key: nameOf("drivingLicense"),
                  type: "multiselect",
                  className: "field col-12 md:col-6 lg:col-3",
                  props: {
                    label: translate("drivingLicense.title"),
                    placeholder: translate(candidateLabelMapping.drivingLicense),
                    options: staticDataService.getStaticData(StaticDataType.DrivingLicenses, language),
                    filter: true,
                    filterBy: "label",
                    emptyValue: "NONE",
                    staticDataType: StaticDataType.DrivingLicenses,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("drivingLicense")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("drivingLicense.required"),
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          id: "qualifications",
          props: {
            label: translate("qualification.title"),
            icon: PrimeIcons.BOOK,
            description: translate("qualification.description"),
          },
          fieldGroup: [
            {
              key: nameOf("qualifications"),
              className: "field col-12",
              type: "repeat",
              props: {
                label: translate(candidateLabelMapping.qualifications),
                addLabel: translate("qualification.add"),
                defaultValue: () => ({ id: uuidv4() }),
              },
              fieldArray: {
                fieldGroupClassName: "p-fluid formgrid grid col",
                fieldGroup: [
                  {
                    type: "input",
                    key: nameOfQualification("id"),
                    props: {
                      type: "hidden",
                    },
                  },
                  {
                    key: nameOfQualification("country"),
                    type: "dropdown",
                    className: "field col-12 md:col-6 lg:col-4 xl:col-4",
                    props: {
                      label: translate("country.title"),
                      placeholder: translate("country.placeholder"),
                      required: false,
                      filter: true,
                      filterBy: "label",
                      options: staticDataService.getStaticData(StaticDataType.Countries, language),
                      staticDataType: StaticDataType.Countries,
                    },
                  },
                  {
                    key: nameOfQualification("professionalTitle"),
                    type: "autocomplete",
                    className: "field col-12 md:col-6 lg:col-4 xl:col-4",
                    props: {
                      label: translate(qualificationLabelMapping.professionalTitle),
                      tooltip: translate("professionalTitle.tooltip"),
                      type: AnabinAssetType.AnabinAbschluesse,
                      getSuggestions: (query: string, props: any) =>
                        anabinService.getMinimalAnabinAssetData(props.type, props.lookupId, props.country, query),
                      firstSuggestion: (id: string) =>
                        anabinService.getMinimalAnabinAssetById(AnabinAssetType.AnabinAbschluesse, id),
                      getDetails: (id: string) => anabinService.getDetails(id, AnabinAssetType.AnabinAbschluesse),
                    },
                    hooks: {
                      onInit: (fieldConfig): void => {
                        const countryControl = fieldConfig.parent.get(nameOfQualification("country")).formControl;

                        // set the country also when the candidate is first loaded
                        countryControl.valueChanges
                          .pipe(startWith(countryControl.value))
                          .subscribe((value) => (fieldConfig.props.country = value));

                        // don't delete the professional title when candidate is first loaded...
                        countryControl.valueChanges.subscribe(() => fieldConfig.formControl.setValue(null));
                      },
                    },
                  },
                  {
                    key: nameOfQualification("occupationalTitle"),
                    type: "input",
                    className: "field col-12 md:col-6 lg:col-4 xl:col-4",
                    props: {
                      type: "text",
                      label: translate(qualificationLabelMapping.occupationalTitle),
                      maxLength: 100,
                      tooltip: translate("occupationalTitle.tooltip"),
                    },
                  },
                  {
                    key: nameOfQualification("occupation"), //done
                    type: "profession",
                    className: "field col-12 md:col-6 lg:col-3 xl:col-3",
                    props: {
                      label: translate(qualificationLabelMapping.occupation),
                      all: true,
                    },
                    expressionProperties: {
                      "props.profession": (_model, formState) => (<BehaviorSubject<string>>formState.profession).value,
                    },
                  },
                  {
                    key: nameOfQualification("qualification"), //done
                    type: "dropdown",
                    className: "field col-12 md:col-6 lg:col-3 xl:col-3",
                    props: {
                      label: translate(qualificationLabelMapping.qualification),
                      placeholder: translate("qualification.placeholder"),
                      options: staticDataService.getStaticData(StaticDataType.Education, language),
                      filter: true,
                      filterBy: "label",
                      staticDataType: StaticDataType.Education,
                    },
                  },
                  {
                    key: nameOfQualification("startOfTraining"), //done
                    type: "datepicker",
                    className: "field col-12 md:col-6 lg:col-2 xl:col-2",
                    props: {
                      label: translate(qualificationLabelMapping.startOfTraining),
                      showIcon: true,
                      inline: false,
                      view: educationPrecision == WorkExperiencePrecissionEnum.Day ? "date" : "month",
                      minDate: new Date(1900, 0, 1),
                    },
                    validation: {
                      messages: {
                        maxDate: translate("startOfTraining.invalid"),
                        minDate: translate("startOfTraining.invalid"),
                      },
                    },
                  },
                  {
                    key: nameOfQualification("endOfTraining"), //done
                    type: "datepicker",
                    className: "field col-12 md:col-6 lg:col-2 xl:col-2",
                    props: {
                      label: translate(qualificationLabelMapping.endOfTraining),
                      showIcon: true,
                      inline: false,
                      view: educationPrecision == WorkExperiencePrecissionEnum.Day ? "date" : "month",
                      minDate: new Date(1900, 0, 1),
                    },
                    expressionProperties: {
                      "props.minDate": "model?.startOfTraining",
                    },
                    validation: {
                      messages: {
                        maxDate: translate("endOfTraining.invalid"),
                        minDate: translate("endOfTraining.invalid"),
                      },
                    },
                  },
                  {
                    key: nameOfQualification("diplomaIssuance"), //done
                    type: "datepicker",
                    className: "field col-12 md:col-6 lg:col-2 xl:col-2",
                    props: {
                      label: translate(qualificationLabelMapping.diplomaIssuance),
                      showIcon: true,
                      inline: false,
                      tooltip: translate("diplomaIssuance.tooltip"),
                    },
                  },
                  {
                    key: nameOfQualification("nameOfInstitution"), //done
                    type: "autocomplete",
                    className: "field col-12 md:col-3",
                    props: {
                      label: translate(qualificationLabelMapping.nameOfInstitution),
                      type: AnabinAssetType.AnabinInstitutions,
                      getSuggestions: (query: string, props: any) =>
                        anabinService.getMinimalAnabinAssetData(props.type, props.lookupId, props.country, query),
                      firstSuggestion: (id: string, props: any) =>
                        anabinService.getMinimalAnabinAssetById(props.type, id),
                      getDetails: (id: string, props: any) => anabinService.getDetails(id, props.type),
                    },
                    hooks: {
                      onInit: (fieldConfig): void => {
                        const professionalTitel = fieldConfig.parent.get(nameOfQualification("professionalTitle"));
                        const country = fieldConfig.parent.get(nameOfQualification("country"));

                        // set the data also when the candidate is first loaded
                        country.formControl.valueChanges
                          .pipe(startWith(country.formControl.value))
                          .subscribe((value) => (fieldConfig.props.country = value));
                        professionalTitel.formControl.valueChanges
                          .pipe(startWith(professionalTitel.formControl.value))
                          .subscribe((value) => {
                            if (!isNaN(value) && value) {
                              fieldConfig.props.lookupId = value;
                            }
                          });

                        // don't delete the data when candidate is first loaded...
                        country.formControl.valueChanges.subscribe(() => fieldConfig.formControl.setValue(null));
                        professionalTitel.formControl.valueChanges.subscribe(() =>
                          fieldConfig.formControl.setValue(null)
                        );
                      },
                    },
                  },
                  {
                    key: nameOfQualification("addressOfInstitution"), //done
                    type: "input",
                    className: "field col-12 md:col-6 lg:col-3 xl:col-3",
                    props: {
                      type: "text",
                      label: translate(qualificationLabelMapping.addressOfInstitution),
                      maxLength: 500,
                    },
                  },
                  {
                    key: nameOfQualification("city"), //done
                    type: "input",
                    className: "field col-12 md:col-6 lg:col-3 xl:col-3",
                    props: {
                      type: "text",
                      label: translate(qualificationLabelMapping.city),
                      maxLength: 500,
                    },
                  },
                ],
              },
            },
            {
              id: "qualificationDocuments",
              key: "qualificationDocuments",
              type: "documentUpload",
              props: {
                tooltip: translate("file.upload"),
                types: [
                  "DIPLOMA",
                  "LISTOFGRADES",
                  "DIPLOMATRAINEE",
                  "LISTOFGRADESTRAINEE",
                  "DIPLOMASCHOOL",
                  "DIPLOMANURSING",
                  "NURSINGLICENSE",
                  "STATEEXAMINATION",
                ],
              },
            },
          ],
          hideExpression: (_model, formState) => !!(formState?.sharedTabs && !formState?.sharedTabs?.qualifications),
        },
        {
          id: "furtherEducations",
          props: {
            label: translate("furtherEducation.title"),
            icon: PrimeIcons.GLOBE,
          },
          hideExpression: (_model, formState) => !!(formState?.sharedTabs && !formState?.sharedTabs?.furtherEducations),
          fieldGroup: [
            {
              type: "checkbox",
              key: nameOf("noFurtherEducationAvailable"),
              className: "field col-12",
              props: {
                label: translate("experience.noFurtherEducationAvailable"),
              },
              expressionProperties: {
                "props.disabled": (model) => model.furtherEducations?.length > 0,
              },
              hideExpression: (model, _formState): boolean => model.furtherEducations?.length > 0,
            },
            {
              key: nameOf("furtherEducations"),
              className: "field col-12",
              type: "repeat",
              props: {
                label: translate(candidateLabelMapping.furtherEducations),
                addLabel: translate("furtherEducation.add"),
                disabledAddButton: false,
                defaultValue: () => ({ id: uuidv4() }),
                isRequiredExpressionInCandidateEditMode: (
                  _model: unknown,
                  _formState: unknown,
                  field: FormlyFieldConfig
                ): boolean => {
                  return !field.parent?.model?.noFurtherEducationAvailable;
                },
              },
              expressionProperties: {
                "props.disabledAddButton": (_model, _formState, field): boolean =>
                  field.parent.model?.noFurtherEducationAvailable,
              },
              fieldArray: {
                fieldGroupClassName: "p-fluid formgrid grid col mw-1",
                fieldGroup: [
                  {
                    type: "input",
                    key: nameOfFurtherEducation("id"),
                    props: {
                      type: "hidden",
                    },
                  },
                  {
                    type: "input",
                    key: nameOfFurtherEducation("nameOfEducation"),
                    className: "field col-12 lg:col-3 xl:col-2",
                    props: {
                      type: "text",
                      label: translate(furtherEducationLabelMapping.nameOfEducation),
                      maxLength: 500,
                    },
                  },
                  {
                    type: "checkbox",
                    key: nameOfFurtherEducation("notProfessionRelated"),
                    className: "flex flex-column justify-content-center lg:col-3 xl:col-2",
                    props: {
                      label: translate(furtherEducationLabelMapping.notProfessionRelated),
                    },
                  },
                  {
                    className: "field col-12 lg:col-3 xl:col-3",
                    fieldGroupClassName: "p-fluid formgrid grid col mw-1",
                    fieldGroup: [
                      {
                        type: "datepicker",
                        key: nameOfFurtherEducation("startDate"),
                        className: "field col-12 lg:col-6 xl:col-6",
                        props: {
                          label: translate(furtherEducationLabelMapping.startDate),
                          showIcon: true,
                          inline: false,
                          required: true,
                          minDate: new Date(1900, 0, 1),
                        },
                        validation: {
                          messages: {
                            required: translate("furtherEducation.startDate.required"),
                            maxDate: translate("furtherEducation.startDate.invalid"),
                            minDate: translate("furtherEducation.startDate.invalid"),
                          },
                        },
                      },
                      {
                        type: "datepicker",
                        key: nameOfFurtherEducation("endDate"),
                        className: "field col-12 lg:col-6 xl:col-6",
                        props: {
                          label: translate(furtherEducationLabelMapping.endDate),
                          showIcon: true,
                          inline: false,
                          required: true,
                          minDate: new Date(1900, 0, 1),
                        },
                        validation: {
                          messages: {
                            required: translate("furtherEducation.endDate.required"),
                            maxDate: translate("furtherEducation.endDate.invalid"),
                            minDate: translate("furtherEducation.endDate.invalid"),
                          },
                        },
                      },
                    ],
                  },
                  {
                    type: "dropdown",
                    key: nameOfFurtherEducation("fieldOfEducation"),
                    className: "field col-12 lg:col-2 xl:col-2",
                    props: {
                      label: translate(furtherEducationLabelMapping.fieldOfEducation),
                      placeholder: translate("furtherEducation.fieldOfEducation.placeholder"),
                      required: false,
                      showToggleAll: false,
                      filter: true,
                      filterBy: "label",
                      options: staticDataService.getStaticData(StaticDataType.ProfessionalFields, language),
                      staticDataType: StaticDataType.ProfessionalFields,
                    },
                    hideExpression: (model, formState): boolean => {
                      const profession = (<BehaviorSubject<string>>formState.profession).value;
                      if (!MedHealthCareCoreProfessions.includes(profession)) {
                        if (model?.fieldOfEducation) delete model.fieldOfEducation;
                        return true;
                      } else if (model?.notProfessionRelated) {
                        return true;
                      }
                      return false;
                    },
                  },
                  {
                    type: "input",
                    key: nameOfFurtherEducation("nameOfInstitution"),
                    className: "field col-12 lg:col-2 xl:col-2",
                    props: {
                      type: "text",
                      label: translate(furtherEducationLabelMapping.nameOfInstitution),
                      maxLength: 500,
                    },
                  },
                  {
                    type: "input",
                    key: nameOfFurtherEducation("totalHours"),
                    className: "field col-12 lg:col-1 xl:col-1",
                    props: {
                      type: "number",
                      label: translate(furtherEducationLabelMapping.totalHours),
                      min: 0,
                      max: 10000,
                    },
                  },
                  {
                    type: "textarea",
                    key: nameOfFurtherEducation("description"),
                    className: "field col-12 lg:col-12",
                    props: {
                      type: "text",
                      label: translate(furtherEducationLabelMapping.description),
                      rows: 2,
                      maxLength: 3000,
                    },
                  },
                ],
              },
            },
            {
              id: "furtherEducationDocuments",
              key: "furtherEducationDocuments",
              type: "documentUpload",
              props: {
                tooltip: translate("file.upload"),
                types: ["CERTOFEDU", "LISTOFGRADESFURTHEREDU"],
              },
            },
          ],
        },
        {
          id: "experiences",
          props: {
            label: translate("experience.title"),
            icon: PrimeIcons.GLOBE,
            description: translate("experience.description"),
          },
          hideExpression: (model, formState) =>
            !!(model && formState?.sharedTabs && !formState?.sharedTabs?.experiences),
          fieldGroup: [
            {
              type: "checkbox",
              key: nameOf("noExperienceAvailable"),
              className: "field col-12",
              props: {
                label: translate("experience.noExperienceAvailable"),
              },
              expressionProperties: {
                "props.disabled": (model) =>
                  model.experiences?.filter((x) => !x.experienceType || x.experienceType === ExperienceType.Normal)
                    ?.length > 0,
              },
              hideExpression: (model, _formState): boolean =>
                model.experiences?.filter((x) => !x.experienceType || x.experienceType === ExperienceType.Normal)
                  ?.length > 0,
            },
            {
              key: nameOf("experiences"),
              className: "field col-12",
              type: "repeat",
              props: {
                label: translate(candidateLabelMapping.experiences),
                addLabel: translate("experience.add"),
                defaultValue: () => ({ id: uuidv4(), experienceType: ExperienceType.Normal }),
                additionalLabel: translate("experience.addActivities"),
                additionalDefaultValue: () => ({ id: uuidv4(), experienceType: ExperienceType.OtherActivities }),
                disabledAddButton: false,
                isRequiredExpressionInCandidateEditMode: (
                  _model: unknown,
                  _formState: unknown,
                  field: FormlyFieldConfig
                ): boolean => {
                  return !field.parent?.model?.noExperienceAvailable;
                },
              },
              validators: {
                validation: [WorkExperienceValidator],
              },
              expressionProperties: {
                "props.disabledAddButton": (_model, _formState, field): boolean =>
                  field.parent.model?.noExperienceAvailable,
              },
              fieldArray: {
                fieldGroupClassName: "p-fluid formgrid grid col mw-1",
                fieldGroup: [
                  {
                    type: "input",
                    key: nameOfExperience("id"),
                    props: {
                      type: "hidden",
                    },
                  },
                  {
                    type: "dropdown",
                    key: nameOfExperience("experienceType"),
                    className: "field col-12 lg:col-2 xl:col-2 hidden",
                    props: {
                      label: translate(experienceLabelMapping.experienceType),
                      placeholder: translate("experienceType.placeholder"),
                      filterBy: "label",
                      options: staticDataService.getExperienceTypeData(),
                    },
                  },
                  {
                    key: nameOfExperience("occupation"),
                    type: "profession",
                    className: "field col-12 lg:col-3 xl:col-3",
                    props: {
                      label: translate("occupation.title"),
                      all: true,
                      required: true,
                    },
                    expressionProperties: {
                      "props.profession": (_model, formState) => (<BehaviorSubject<string>>formState.profession).value,
                      "props.required": (model) => model.experienceType !== ExperienceType.OtherActivities,
                    },
                    validation: {
                      messages: {
                        required: translate("occupation.required"),
                      },
                    },
                    hideExpression: (model): boolean => model?.experienceType === ExperienceType.OtherActivities,
                  },
                  {
                    type: "dropdown",
                    key: nameOfExperience("otherActivity"),
                    className: "field col-12 lg:col-3 xl:col-3",
                    props: {
                      label: translate("otherActivities.title"),
                      placeholder: translate("otherActivities.placeholder"),
                      filter: true,
                      filterBy: "label",
                      required: true,
                      options: staticDataService.getStaticData(StaticDataType.OtherActivities, language),
                      staticDataType: StaticDataType.OtherActivities,
                    },
                    expressionProperties: {
                      "props.required": (model) => model.experienceType === ExperienceType.OtherActivities,
                    },
                    hideExpression: (model): boolean => {
                      return model.experienceType !== ExperienceType.OtherActivities;
                    },
                  },
                  {
                    type: "datepicker",
                    key: nameOfExperience("experienceStartDate"),
                    className: "field col-12 lg:col-2 xl:col-2",
                    props: {
                      label: translate(experienceLabelMapping.experienceStartDate),
                      showIcon: true,
                      inline: false,
                      required: true,
                      view: workExperiencePrecission == WorkExperiencePrecissionEnum.Day ? "date" : "month",
                      minDate: new Date(1900, 0, 1),
                      maxDate: new Date(),
                    },
                    validation: {
                      messages: {
                        required: translate("startOfExperience.required"),
                        maxDate: translate("startOfExperience.invalid"),
                        minDate: translate("startOfExperience.invalid"),
                      },
                    },
                    expressionProperties: {
                      "props.required": (model) => !model.experienceMonth && !model.experienceYears,
                    },
                  },
                  {
                    type: "datepicker",
                    key: nameOfExperience("experienceEndDate"),
                    className: "field col-12 lg:col-2 xl:col-2",
                    props: {
                      label: translate(experienceLabelMapping.experienceEndDate),
                      showIcon: true,
                      inline: false,
                      view: workExperiencePrecission == WorkExperiencePrecissionEnum.Day ? "date" : "month",
                      minDate: new Date(1900, 0, 1),
                    },
                    validation: {
                      messages: {
                        maxDate: translate("endOfExperience.invalid"),
                        minDate: translate("endOfExperience.invalid"),
                      },
                    },
                    expressionProperties: {
                      "props.minDate": "model.experienceStartDate",
                    },
                  },
                  {
                    type: "dropdown",
                    key: nameOfExperience("experienceFunction"),
                    className: "field col-12 lg:col-2 xl:col-2",
                    props: {
                      label: translate(experienceLabelMapping.experienceFunction),
                      placeholder: translate("function.placeholder"),
                      filter: true,
                      filterBy: "label",
                      options: staticDataService.getStaticData(StaticDataType.Positions, language),
                      staticDataType: StaticDataType.Positions,
                    },
                    hideExpression: (model): boolean => model?.experienceType === ExperienceType.OtherActivities,
                  },
                  {
                    type: "checkbox",
                    key: nameOfExperience("asFreelancer"),
                    className: "field col-2 pt-5",
                    props: {
                      label: translate(experienceLabelMapping.asFreelancer),
                    },
                    hideExpression: (model): boolean =>
                      model?.experienceType === ExperienceType.OtherActivities ||
                      model?.experienceFunction === "INTERN",
                  },
                  {
                    type: "checkbox",
                    key: nameOfExperience("mandatoryInternship"),
                    className: "field col-2 pt-5",
                    props: {
                      label: translate(experienceLabelMapping.mandatoryInternship),
                    },
                    hideExpression: (model): boolean =>
                      model?.experienceType === ExperienceType.OtherActivities || model?.experienceFunction != "INTERN",
                  },
                  {
                    type: "dropdown",
                    key: nameOfExperience("workHours"),
                    className: "field col-12 lg:col-4",
                    props: {
                      label: translate(experienceLabelMapping.workHours),
                      placeholder: translate("workHours.placeholder"),
                      options: staticDataService.getStaticData(StaticDataType.WorkingTimeType),
                      staticDataType: StaticDataType.WorkingTimeType,
                    },
                    hideExpression: (model): boolean => model?.experienceType === ExperienceType.OtherActivities,
                  },
                  {
                    type: "input",
                    key: nameOfExperience("hoursPerWeek"),
                    className: "field col-12 lg:col-4",
                    props: {
                      type: "number",
                      label: translate(experienceLabelMapping.hoursPerWeek),
                      min: 0,
                      maxFractionDigits: 1,
                    },
                    hideExpression: (model): boolean => model?.experienceType === ExperienceType.OtherActivities,
                  },
                  {
                    type: "checkbox",
                    key: nameOfExperience("shiftSystem"),
                    className: "field col-12 lg:col-4 pt-5",
                    props: {
                      label: translate(experienceLabelMapping.shiftSystem),
                    },
                    hideExpression: (model): boolean => model?.experienceType === ExperienceType.OtherActivities,
                  },
                  {
                    type: "dropdown",
                    key: nameOfExperience("experienceFacility"),
                    className: "field col-12 lg:col-3 xl:col-3",
                    props: {
                      label: translate("careFacility.title"),
                      placeholder: translate("careFacility.placeholder"),
                      required: true,
                      filter: true,
                      filterBy: "label",
                      options: staticDataService.getStaticData(StaticDataType.CareFacilities, language),
                      staticDataType: StaticDataType.CareFacilities,
                    },
                    expressionProperties: {
                      "props.required": (model) => MedHealthCareCoreProfessions.includes(model.occupation),
                    },
                    hideExpression: (model): boolean => {
                      if (!MedHealthCareCoreProfessions.includes(model.occupation)) {
                        delete model.experienceFacility;
                        return true;
                      } else if (model?.experienceType === ExperienceType.OtherActivities) {
                        return true;
                      }
                      return false;
                    },
                  },
                  {
                    type: "dropdown",
                    key: nameOfExperience("experienceBedCount"),
                    className: "field col-12 lg:col-2 xl:col-2",
                    props: {
                      label: translate("beds.titleFull"),
                      placeholder: translate("beds.placeholder"),
                      options: staticDataService.getStaticData(StaticDataType.Beds, language),
                      filter: true,
                      filterBy: "label",
                      staticDataType: StaticDataType.Beds,
                    },
                    hideExpression: (model): boolean => {
                      if (
                        !MedHealthCareCoreProfessions.includes(model.occupation) ||
                        model.experienceFacility == "DOCOFFICE"
                      ) {
                        delete model.experienceBedCount;
                        return true;
                      } else if (model?.experienceType === ExperienceType.OtherActivities) {
                        return true;
                      }
                      return false;
                    },
                  },
                  {
                    type: "chips",
                    key: nameOfExperience("experienceFields"),
                    className: "field col-12 lg:col-12 xl:col-12",
                    props: {
                      label: translate(experienceLabelMapping.experienceFields),
                      placeholder: translate("experienceFields.placeholder"),
                      options: staticDataService.getStaticData(StaticDataType.ProfessionalFields, language),
                      language: language,
                      staticDataType: StaticDataType.ProfessionalFields,
                    },
                    expressionProperties: {
                      "props.required": (model) => MedHealthCareCoreProfessions.includes(model.occupation),
                    },
                    hideExpression: (model): boolean => {
                      if (!MedHealthCareCoreProfessions.includes(model.occupation)) {
                        delete model.experienceFields;
                        return true;
                      } else if (model?.experienceType === ExperienceType.OtherActivities) {
                        return true;
                      }
                      return false;
                    },
                  },
                  {
                    type: "dropdown",
                    key: nameOfExperience("experienceWorkArea"),
                    className: "field col-12 lg:col-2 xl:col-2",
                    props: {
                      label: translate(experienceLabelMapping.experienceWorkArea),
                      placeholder: translate("experienceWorkArea.placeholder"),
                      options: staticDataService.getStaticData(StaticDataType.WorkArea, language),
                      filter: true,
                      filterBy: "label",
                      staticDataType: StaticDataType.WorkArea,
                    },
                    expressionProperties: {
                      //"props.disabled": (model) => model.experienceFields?.includes("GY") #4576,
                      "model.experienceWorkArea": (model) =>
                        !model.experienceWorkArea &&
                        model.experienceFields?.find((field) => field.experienceField === "GY")
                          ? "STATION"
                          : model.experienceWorkArea,
                    },
                    hideExpression: (model): boolean => {
                      if (!MedHealthCareCoreProfessions.includes(model.occupation)) {
                        delete model.experienceWorkArea;
                        return true;
                      } else if (model?.experienceType === ExperienceType.OtherActivities) {
                        return true;
                      }
                      return false;
                    },
                  },
                  {
                    type: "input",
                    key: nameOfExperience("experienceCount"),
                    className: "field col-12 lg:col-2 xl:col-2",
                    props: {
                      type: "number",
                      label: translate(experienceLabelMapping.experienceCount),
                      min: 0,
                      max: 1000,
                    },
                    expressionProperties: {
                      "props.label": (model) =>
                        model.experienceFields?.find((field) => field.experienceField === "GY")
                          ? translate("experience.birthPerMonth")
                          : translate("experience.opPerMonth"),
                    },
                    hideExpression: (model): boolean => {
                      if (
                        (model.experienceWorkArea == "STATION" &&
                          !model.experienceFields?.find((field) => field.experienceField === "GY")) ||
                        !MedHealthCareCoreProfessions.includes(model.occupation)
                      ) {
                        delete model.experienceCount;
                        return true;
                      } else if (model?.experienceType === ExperienceType.OtherActivities) {
                        return true;
                      }
                      return false;
                    },
                  },
                  {
                    type: "input",
                    key: nameOfExperience("experienceYears"),
                    className: "field col-12 lg:col-2 xl:col-1",
                    props: {
                      type: "number",
                      label: translate(experienceLabelMapping.experienceYears),
                      min: 0,
                      max: 30,
                    },
                    hideExpression: (model) =>
                      model?.experienceType === ExperienceType.OtherActivities ||
                      (!model.experienceMonth && !model.experienceYears) ||
                      model.experienceStartDate ||
                      model.experienceEndDate,
                  },
                  {
                    type: "input",
                    key: nameOfExperience("experienceMonth"),
                    className: "field col-12 lg:col-2 xl:col-1",
                    defaultValue: 0,
                    props: {
                      type: "number",
                      label: translate(experienceLabelMapping.experienceMonth),
                      min: 0,
                      max: 12,
                    },
                    hideExpression: (model) =>
                      model?.experienceType === ExperienceType.OtherActivities ||
                      (!model.experienceMonth && !model.experienceYears) ||
                      model.experienceStartDate ||
                      model.experienceEndDate,
                  },
                  {
                    key: nameOfExperience("experienceInstitution"),
                    type: "input",
                    className: "field col-12 lg:col-4 xl:col-4",
                    props: {
                      type: "text",
                      label: translate("nameOfInstitution2.title"),
                      maxLength: 500,
                    },
                    hideExpression: (model): boolean => model?.experienceType === ExperienceType.OtherActivities,
                  },
                  {
                    key: nameOfExperience("city"),
                    type: "input",
                    className: "field col-12 lg:col-4 xl:col-4",
                    props: {
                      type: "text",
                      label: translate("city.title"),
                      maxLength: 500,
                    },
                    hideExpression: (model): boolean => model?.experienceType === ExperienceType.OtherActivities,
                  },
                  {
                    key: nameOfQualification("country"),
                    type: "dropdown",
                    className: "field col-12 lg:col-4 xl:col-4",
                    props: {
                      label: translate("country.title"),
                      placeholder: translate("country.placeholder"),
                      required: false,
                      filter: true,
                      filterBy: "label",
                      options: staticDataService.getStaticData(StaticDataType.Countries, language),
                      staticDataType: StaticDataType.Countries,
                    },
                    hideExpression: (model): boolean => model?.experienceType === ExperienceType.OtherActivities,
                  },

                  {
                    type: "textarea",
                    key: nameOfExperience("experienceCoreTasks"),
                    className: "field col-12 lg:col-12",
                    props: {
                      type: "text",
                      label: translate("experience.coreTasks"),
                      rows: 2,
                      maxLength: 3000,
                    },
                    expressionProperties: {
                      "props.label": (model) =>
                        model?.experienceType === ExperienceType.OtherActivities
                          ? translate("experience.notes")
                          : translate("experience.coreTasks"),
                    },
                  },
                ],
              },
            },
            {
              id: "experienceDocuments",
              key: "experienceDocuments",
              type: "documentUpload",
              props: {
                tooltip: translate("file.upload"),
                types: ["WORKCERT", "PROOFOFINTERNSHIP"],
              },
            },
          ],
        },
        {
          id: nameOf("competencies"),
          key: nameOf("competencies"),
          props: {
            label: translate(candidateLabelMapping.competencies),
            icon: Icon.TROPHY,
          },
          hooks: {
            onInit: (fieldConfig): void => {
              const formState = fieldConfig.options.formState;
              const profession = formState.profession as BehaviorSubject<string>;

              profession
                .pipe(
                  startWith(profession.value),
                  switchMap((value) =>
                    professionService.getProfessionSkills(value, formState.organizationId, "cache-first")
                  ),
                  map((x) => hasSkills(x))
                )
                .subscribe(
                  (value) =>
                    (fieldConfig.hide = !!(formState?.sharedTabs && !formState?.sharedTabs?.competencies) || !value)
                );

              function hasSkills(allSets: readonly [OccupationSkillSet[], CustomSkillSet[]]): boolean {
                return allSets.flatMap((sets) => sets.flatMap((set) => set.skills)).length > 0;
              }
            },
          },
          fieldGroup: [
            {
              key: nameOfCompetencies("knowledges"),
              type: "competencies",
              className: "w-full",
              fieldGroupClassName: "p-fluid formgrid grid",
              props: {
                label: translate(competenciesLabelMapping.knowledges),
              },
              fieldArray: {
                className: "field col-12 md:col-6 lg:col-3 xl:col-3",
                fieldGroup: [
                  { key: nameOfCompetency("id"), type: "input", props: { type: "hidden" } },
                  { key: nameOfCompetency("skillId"), type: "input", props: { type: "hidden" } },
                  {
                    key: nameOfCompetency("proficiency"),
                    type: "dropdown",
                    props: {
                      placeholder: translate("proficiency.placeholder"),
                      options: staticDataService.transformEnumToStaticDataModel(
                        "KnowledgeProficiency",
                        KnowledgeProficiency
                      ),
                    },
                  },
                ],
              },
            },
            {
              key: nameOfCompetencies("skills"),
              type: "competencies",
              className: "w-full",
              fieldGroupClassName: "p-fluid formgrid grid",
              props: {
                label: translate(competenciesLabelMapping.skills),
              },
              fieldArray: {
                className: "field col-12 md:col-6 lg:col-3 xl:col-3",
                fieldGroup: [
                  { key: nameOfCompetency("id"), type: "input", props: { type: "hidden" } },
                  { key: nameOfCompetency("skillId"), type: "input", props: { type: "hidden" } },
                  {
                    key: nameOfCompetency("proficiency"),
                    type: "dropdown",
                    props: {
                      placeholder: translate("yearsOfExperience.placeholder"),
                      options: staticDataService.transformEnumToStaticDataModel(
                        "YearsOfSkillExperience",
                        YearsOfSkillExperience
                      ),
                    },
                  },
                ],
              },
            },
            {
              key: nameOfCompetencies("customKnowledges"),
              type: "competencies",
              className: "w-full",
              fieldGroupClassName: "p-fluid formgrid grid",
              props: {
                label: translate(competenciesLabelMapping.customKnowledges),
              },
              fieldArray: {
                className: "field col-12 md:col-6 lg:col-3 xl:col-3",
                fieldGroup: [
                  { key: nameOfCompetency("id"), type: "input", props: { type: "hidden" } },
                  { key: nameOfCompetency("skillId"), type: "input", props: { type: "hidden" } },
                  {
                    key: nameOfCompetency("proficiency"),
                    type: "dropdown",
                    props: {
                      placeholder: translate("proficiency.placeholder"),
                      options: staticDataService.transformEnumToStaticDataModel(
                        "KnowledgeProficiency",
                        KnowledgeProficiency
                      ),
                    },
                  },
                ],
              },
            },
            {
              key: nameOfCompetencies("customSkills"),
              type: "competencies",
              className: "w-full",
              fieldGroupClassName: "p-fluid formgrid grid",
              props: {
                label: translate(competenciesLabelMapping.customSkills),
              },
              fieldArray: {
                className: "field col-12 md:col-6 lg:col-3 xl:col-3",
                fieldGroup: [
                  { key: nameOfCompetency("id"), type: "input", props: { type: "hidden" } },
                  { key: nameOfCompetency("skillId"), type: "input", props: { type: "hidden" } },
                  {
                    key: nameOfCompetency("proficiency"),
                    type: "dropdown",
                    props: {
                      placeholder: translate("yearsOfExperience.placeholder"),
                      options: staticDataService.transformEnumToStaticDataModel(
                        "YearsOfSkillExperience",
                        YearsOfSkillExperience
                      ),
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          id: nameOf("languageSkills"),
          props: {
            label: translate("languageSkills.title"),
            icon: PrimeIcons.COMMENT,
          },
          hideExpression: (_model, formState) => !!(formState?.sharedTabs && !formState?.sharedTabs?.languageSkills),
          fieldGroupClassName: "p-fluid",
          fieldGroup: [
            {
              wrappers: ["fieldset"],
              props: {
                label: translate("germanSkillsAtStart.title"),
              },
              fieldGroupClassName: "p-fluid formgrid grid",
              fieldGroup: [
                {
                  key: nameOf("languageLevelCV"),
                  type: "dropdown",
                  className: "field col-12 lg:col-4",
                  props: {
                    label: translate(candidateLabelMapping.languageLevelCV),
                    placeholder: translate("placeholder.title"),
                    options: staticDataService.getStaticData(StaticDataType.LanguageLevels, language),
                    staticDataType: StaticDataType.LanguageLevels,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("languageLevelCV")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("required"),
                    },
                  },
                },
                {
                  key: "languageLevelByPlacementTest",
                  props: {
                    label: translate("languageTrainingTracking.languageLevelTestResult.title"),
                    hideLabel: true,
                  },
                  type: "language-level-by-placement-test",
                  className: "field col-12 lg:col-8",
                },
              ],
            },
            {
              key: nameOf("languageSkills"),
              className: "field col-12",
              type: "repeat",
              props: {
                label: translate(candidateLabelMapping.languageSkills),
                addLabel: translate("languageSkills.add"),
                defaultValue: () => ({ id: uuidv4() }),
              },
              fieldArray: {
                fieldGroupClassName: "p-fluid formgrid grid col mw-1",
                expressionProperties: {
                  "props.documentType": (model): string => {
                    if (!model?.skillLevel || model?.language !== "DE") return "LANGCERT";

                    return languageLevelGermanCertificateMapping[model.skillLevel] ?? "LANGCERT";
                  },
                },
                fieldGroup: [
                  {
                    type: "input",
                    key: nameOfLanguageSkill("id"),
                    props: {
                      type: "hidden",
                    },
                  },
                  {
                    type: "dropdown",
                    key: "language",
                    className: "field col-12 lg:col-2",
                    props: {
                      label: translate(languageSkillLabelMapping.language),
                      placeholder: translate("language.placeholder"),
                      required: true,
                      options: staticDataService.getStaticData(StaticDataType.Languages, language),
                      filter: true,
                      filterBy: "label",
                      useAlignmentCheckBox: true,
                      staticDataType: StaticDataType.Languages,
                    },
                  },
                  {
                    type: "dropdown",
                    key: nameOfLanguageSkill("skillLevel"),
                    className: "field col-12 lg:col-2",
                    props: {
                      label: translate(languageSkillLabelMapping.skillLevel),
                      placeholder: translate("skillLevel.placeholder"),
                      required: true,
                      options: staticDataService.getStaticData(StaticDataType.LanguageLevels, language),
                      filter: true,
                      filterBy: "label",
                      useAlignmentCheckBox: true,
                      staticDataType: StaticDataType.LanguageLevels,
                    },
                  },
                  {
                    type: "dropdown",
                    key: nameOfLanguageSkill("examInstitution"),
                    className: "field col-12 lg:col-2",
                    props: {
                      label: translate(languageSkillLabelMapping.examInstitution),
                      placeholder: translate("examInstitution.placeholder"),
                      options: staticDataService.getStaticData(StaticDataType.ExamInstitutions, language),
                      filter: true,
                      filterBy: "label",
                      useAlignmentCheckBox: true,
                    },
                  },
                  {
                    type: "checkbox",
                    key: nameOfLanguageSkill("certified"),
                    className: "flex flex-column justify-content-center col-12 lg:col-2 pt-5",
                    props: {
                      label: translate("languageSkills.certified"),
                    },
                  },
                  {
                    type: "document-date",
                    key: "examCertificateDate",
                    className: "field col-12 lg:col-2",
                    props: {
                      label: translate("examCertificateDate.title"),
                      dateField: "issueDate",
                    },
                    expressionProperties: {
                      "props.candidateId": (_model, formState) => formState.candidateId,
                      "props.organizationId": (_model, formState) => formState.organizationId,
                      "props.selectedSet": (model) => ({ foreignKey: model?.id }),
                      "props.disabled": (model) => !model?.certified || !model?.language || !model?.skillLevel,
                      "props.documentType": (_model, _formState, fieldConfig): string =>
                        fieldConfig?.parent?.props?.documentType ?? "LANGCERT",
                    },
                  },
                ],
              },
            },
            {
              key: "certifiedEducationExams",
              type: "certified-education-exams",
              className: "col-12 mt-4",
              props: {
                label: translate("certifiedEducationExams.title"),
                hideLabel: true,
              },
            },
            {
              id: "languageSkillsDocuments",
              key: "languageSkillsDocuments",
              type: "documentUpload",
              props: {
                tooltip: translate("file.upload"),
                types: [
                  "LANGCERT",
                  "CONFOLANGSCHOOL",
                  "LANGCERTGERA1",
                  "LANGCERTGERA2",
                  "LANGCERTGERB1",
                  "LANGCERTGERB2",
                  "LANGCERTGERC1",
                  "LANGCERTGERC2",
                ],
              },
            },
          ],
        },
        {
          id: "preferences",
          props: {
            label: translate("preferences.title"),
            icon: PrimeIcons.THUMBS_UP,
          },
          hideExpression: (_model, formState) => !!(formState?.sharedTabs && !formState?.sharedTabs?.preferences),

          fieldGroup: [
            {
              fieldGroup: [
                {
                  fieldGroupClassName: "p-fluid formgrid grid",
                  fieldGroup: [
                    {
                      key: nameOf("preferredCareFacility"),
                      type: "multiselect",
                      className: "field col-12 lg:col-10",
                      props: {
                        showToggleAll: false,
                        label: translate(candidateLabelMapping.preferredCareFacility),
                        placeholder: translate("preferences.careFacility.placeholder"),
                        options: staticDataService.getStaticData(StaticDataType.CareFacilities, language),
                        staticDataType: StaticDataType.CareFacilities,
                      },
                      hideExpression: (model, formState): boolean => {
                        const profession = (<BehaviorSubject<string>>formState.profession).value;
                        if (!MedHealthCareCoreProfessions.includes(profession)) {
                          delete model.preferredCareFacility;
                          return true;
                        }
                        return false;
                      },
                    },
                    {
                      type: "checkbox",
                      key: nameOf("preferredCareFacilityFlexibility"),
                      className: "field col-12 lg:col-2 pt-5",
                      props: {
                        label: translate("preferences.flexible"),
                        filterLabel: translate(candidateLabelMapping.preferredCareFacilityFlexibility),
                      },
                      hideExpression: (model, formState): boolean => {
                        const profession = (<BehaviorSubject<string>>formState.profession).value;
                        if (!MedHealthCareCoreProfessions.includes(profession)) {
                          delete model.preferredCareFacilityFlexibility;
                          return true;
                        }
                        return false;
                      },
                    },
                    {
                      key: nameOf("preferredWorkingField"),
                      type: "multiselect",
                      className: "field col-12 lg:col-10",
                      props: {
                        showToggleAll: false,
                        label: translate(candidateLabelMapping.preferredWorkingField),
                        placeholder: translate("preferences.field.placeholder"),
                        options: staticDataService.getStaticData(StaticDataType.ProfessionalFields, language),
                        staticDataType: StaticDataType.ProfessionalFields,
                      },
                      hideExpression: (model, formState): boolean => {
                        const profession = (<BehaviorSubject<string>>formState.profession).value;
                        if (!MedHealthCareCoreProfessions.includes(profession)) {
                          delete model.preferredWorkingField;
                          return true;
                        }
                        return false;
                      },
                    },
                    {
                      type: "checkbox",
                      key: nameOf("preferredWorkingFieldFlexibility"),
                      className: "field col-12 lg:col-2 pt-5",
                      props: {
                        label: translate("preferences.flexible"),
                        filterLabel: translate(candidateLabelMapping.preferredWorkingFieldFlexibility),
                      },
                      hideExpression: (model, formState): boolean => {
                        const profession = (<BehaviorSubject<string>>formState.profession).value;
                        if (!MedHealthCareCoreProfessions.includes(profession)) {
                          delete model.preferredWorkingFieldFlexibility;
                          return true;
                        }
                        return false;
                      },
                    },
                    {
                      key: nameOf("preferredLocationState"),
                      type: "multiselect",
                      className: "field col-12 lg:col-10",
                      props: {
                        showToggleAll: false,
                        label: translate(candidateLabelMapping.preferredLocationState),
                        placeholder: translate("preferences.state.placeholder"),
                        // Prefill all available static data for the candidate filters to work.
                        options: getImmigrationSpecificStaticData(StaticDataType.FederalStates),
                        staticDataType: StaticDataType.FederalStates,
                      },
                      hooks: {
                        onInit: (fieldConfig): void => {
                          const formState = fieldConfig.options.formState;
                          const immigrationCountry = formState.immigrationCountry as BehaviorSubject<string>;
                          fieldConfig.props.options = immigrationCountry.pipe(
                            distinctUntilChanged(isEqual),
                            switchMap((country) =>
                              getImmigrationSpecificStaticData(StaticDataType.FederalStates, country)
                            )
                          );
                        },
                      },
                    },
                    {
                      type: "checkbox",
                      key: nameOf("preferredLocationStateFlexibility"),
                      className: "field col-12 lg:col-2 pt-5",
                      props: {
                        label: translate("preferences.flexible"),
                        filterLabel: translate(candidateLabelMapping.preferredLocationStateFlexibility),
                      },
                    },
                    {
                      key: nameOf("preferredCity"),
                      type: "multiselect",
                      className: "field col-12 lg:col-10",
                      props: {
                        showToggleAll: false,
                        label: translate(candidateLabelMapping.preferredCity),
                        placeholder: translate("preferences.city.placeholder"),
                        // Prefill all available static data for the candidate filters to work.
                        options: getImmigrationSpecificStaticData(StaticDataType.KnownCities),
                        staticDataType: StaticDataType.KnownCities,
                      },
                      // No Observable in expressionPropertiespossible
                      //https://github.com/ngx-formly/ngx-formly/issues/1818
                      expressionProperties: {
                        "props.options": (model, _, fieldConfig): StaticDataModel[] => {
                          const immigrationCountry = fieldConfig.options.formState
                            .immigrationCountry as BehaviorSubject<string>;
                          return fieldConfig.props.allOptions?.filter((x) =>
                            model.preferredLocationState?.includes(getCityState(immigrationCountry.value, x.value))
                          );
                        },
                      },
                      hooks: {
                        onInit: (fieldConfig): void => {
                          const formState = fieldConfig.options.formState;
                          const immigrationCountry = formState.immigrationCountry as BehaviorSubject<string>;
                          immigrationCountry
                            .pipe(
                              distinctUntilChanged(isEqual),
                              switchMap((country) =>
                                getImmigrationSpecificStaticData(StaticDataType.KnownCities, country)
                              )
                            )
                            .subscribe((options) => {
                              // let's save all cities to a special field and filter them out in an expression
                              fieldConfig.props.allOptions = options;
                              fieldConfig.options.checkExpressions(fieldConfig);
                            });

                          combineLatest([
                            <Observable<string[]>>(
                              fieldConfig.form.controls[nameOf("preferredLocationState")].valueChanges
                            ),
                            <Observable<string>>fieldConfig.options.formState.immigrationCountry,
                          ])
                            .pipe(distinctUntilChanged(isEqual))
                            .subscribe(([state, immigrationCountry]) => {
                              fieldConfig.formControl.setValue(
                                fieldConfig.formControl.value?.filter((c) =>
                                  state?.includes(getCityState(immigrationCountry, c))
                                ) ?? null
                              );
                            });
                        },
                      },
                    },
                    {
                      type: "checkbox",
                      key: nameOf("preferredCityFlexibility"),
                      className: "field col-12 lg:col-2 pt-5",
                      props: {
                        label: translate("preferences.flexible"),
                        filterLabel: translate(candidateLabelMapping.preferredCityFlexibility),
                      },
                    },
                    {
                      key: nameOf("preferredCandidates"),
                      type: "input",
                      className: "field col-12 lg:col-6",
                      props: {
                        type: "text",
                        label: translate(candidateLabelMapping.preferredCandidates),
                        maxLength: 200,
                      },
                    },
                    {
                      key: nameOf("preferredPathOfRecognition"),
                      type: "dropdown",
                      className: "field col-12 lg:col-6",
                      props: {
                        label: translate(candidateLabelMapping.preferredPathOfRecognition),
                        placeholder: translate("preferences.pathOfRecognition.placeholder"),
                        filter: true,
                        filterBy: "label",
                        // Prefill all available static data for the candidate filters to work.
                        options: getImmigrationSpecificStaticData(StaticDataType.PathOfRecognition),
                        staticDataType: StaticDataType.PathOfRecognition,
                      },
                      hooks: {
                        onInit: (fieldConfig): void => {
                          const formState = fieldConfig.options.formState;
                          const immigrationCountry = formState.immigrationCountry as BehaviorSubject<string>;
                          fieldConfig.props.options = immigrationCountry.pipe(
                            distinctUntilChanged(isEqual),
                            switchMap((country) =>
                              getImmigrationSpecificStaticData(StaticDataType.PathOfRecognition, country)
                            ),
                            map((options) => options.filter((x) => x.value !== "RECOGNITION"))
                          );
                        },
                      },
                    },
                    {
                      key: nameOf("preferredLanguageLevelForInterview"),
                      type: "dropdown",
                      className: "field col-12 lg:col-6",
                      props: {
                        label: translate(candidateLabelMapping.preferredLanguageLevelForInterview),
                        placeholder: translate("preferences.languageLevelForInterview.placeholder"),
                        options: specifics.getInterviewLanguageLevels(language),
                        filter: true,
                        filterBy: "label",
                        staticDataType: StaticDataType.LanguageLevels,
                      },
                    },
                    {
                      key: nameOf("preferredLanguageLevelForTransfer"),
                      type: "dropdown",
                      className: "field col-12 lg:col-6",
                      props: {
                        label: translate(candidateLabelMapping.preferredLanguageLevelForTransfer),
                        placeholder: translate("preferences.languageLevelForTransfer.placeholder"),
                        options: specifics.getTransferLanguageLevels(language),
                        filter: true,
                        filterBy: "label",
                        staticDataType: StaticDataType.LanguageLevels,
                      },
                    },
                    {
                      type: "datepicker",
                      key: nameOf("preferredStartDate"),
                      className: "field col-12 lg:col-6",
                      props: {
                        label: translate(candidateLabelMapping.preferredStartDate),
                        showIcon: true,
                        inline: false,
                        minDate: new Date(1900, 0, 1),
                      },
                      validation: {
                        messages: {
                          maxDate: translate("preferences.preferredStartDate.invalid"),
                          minDate: translate("preferences.preferredStartDate.invalid"),
                        },
                      },
                    },
                    {
                      key: nameOf("preferredEmploymentType"),
                      type: "dropdown",
                      className: "field col-12 lg:col-6",
                      props: {
                        label: translate(candidateLabelMapping.preferredEmploymentType),
                        placeholder: translate("preferences.employmentType.placeholder"),
                        options: [
                          {
                            value: EmploymentRelationshipType.Internship,
                            label: translate("enum.EmploymentRelationshipType.Internship"),
                          },
                          {
                            value: EmploymentRelationshipType.JobShadowing,
                            label: translate("enum.EmploymentRelationshipType.JobShadowing"),
                          },
                          {
                            value: EmploymentRelationshipType.Permanent,
                            label: translate("enum.EmploymentRelationshipType.Permanent"),
                          },
                          {
                            value: EmploymentRelationshipType.SecondJob,
                            label: translate("enum.EmploymentRelationshipType.SecondJob"),
                          },
                          {
                            value: EmploymentRelationshipType.Temporary,
                            label: translate("enum.EmploymentRelationshipType.Temporary"),
                          },
                        ],
                        filter: true,
                        filterBy: "label",
                        enumType: "EmploymentRelationshipType",
                      },
                    },
                    {
                      key: nameOf("preferredTemporalScope"),
                      type: "dropdown",
                      className: "field col-12 lg:col-6",
                      props: {
                        label: translate(candidateLabelMapping.preferredTemporalScope),
                        placeholder: translate("preferences.temporalScope.placeholder"),
                        options: [
                          { value: WorkingTimeType.FullTime, label: translate("enum.WorkingTimeType.FullTime") },
                          { value: WorkingTimeType.PartTime, label: translate("enum.WorkingTimeType.PartTime") },
                          {
                            value: WorkingTimeType.MarginalEmployment,
                            label: translate("enum.WorkingTimeType.MarginalEmployment"),
                          },
                        ],
                        filter: true,
                        filterBy: "label",
                        enumType: "WorkingTimeType",
                      },
                    },
                    {
                      key: nameOf("preferredFamilyReunion"),
                      type: "dropdown",
                      className: "field col-12 lg:col-6",
                      props: {
                        label: translate("preferences.preferredFamilyReunion.title"),
                        placeholder: translate("preferences.preferredFamilyReunion.placeholder"),
                        filter: true,
                        filterBy: "label",
                        options: staticDataService.getStaticData(StaticDataType.PreferredFamilyReunion, language),
                        staticDataType: StaticDataType.PreferredFamilyReunion,
                      },
                    },
                    {
                      key: nameOf("preferredTypeOfImmigration"),
                      type: "dropdown",
                      className: "field col-12 lg:col-6",
                      props: {
                        label: translate("preferences.preferredTypeOfImmigration.title"),
                        placeholder: translate("preferences.preferredTypeOfImmigration.placeholder"),
                        filter: true,
                        filterBy: "label",
                        options: getImmigrationSpecificStaticData(StaticDataType.PreferredTypeOfImmigration),
                        staticDataType: StaticDataType.PreferredTypeOfImmigration,
                      },
                      hooks: {
                        onInit: (fieldConfig): void => {
                          const formState = fieldConfig.options.formState;
                          const immigrationCountry = formState.immigrationCountry as BehaviorSubject<string>;
                          fieldConfig.props.options = immigrationCountry.pipe(
                            distinctUntilChanged(isEqual),
                            switchMap((country) =>
                              getImmigrationSpecificStaticData(StaticDataType.PreferredTypeOfImmigration, country)
                            )
                          );
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: "vaccinations",
          props: {
            label: translate("vaccinations.title"),
            icon: PrimeIcons.SHIELD,
          },
          hideExpression: (_model, formState) => !!(formState?.sharedTabs && !formState?.sharedTabs?.vaccinations),

          fieldGroup: [
            {
              key: "vaccinationInformation",
              type: "vaccination-information",
              className: "field col-12",
            },
            {
              key: nameOf("vaccinations"),
              className: "field col-12",
              type: "repeat",
              props: {
                label: translate("vaccinations.title"),
                addLabel: translate("vaccinations.add"),
                defaultValue: () => ({ id: uuidv4() }),
              },
              fieldArray: {
                fieldGroupClassName: "p-fluid formgrid grid col mw-1",
                fieldGroup: [
                  {
                    type: "input",
                    key: nameOfVaccinations("id"),
                    props: {
                      type: "hidden",
                    },
                  },
                  {
                    key: nameOf("vaccinations"),
                    type: "multiselect",
                    className: "field col-12 lg:col-3 xl:col-3",
                    props: {
                      label: translate(VaccinationLabelMapping.vaccinations),
                      placeholder: translate("vaccinations.name.placeholder"),
                      options: staticDataService.getStaticData(StaticDataType.Vaccination, language),
                      filter: true,
                      filterBy: "label",
                      staticDataType: StaticDataType.Vaccination,
                    },
                  },
                  {
                    key: nameOfVaccinations("vaccine"),
                    type: "input",
                    className: "field col-12 lg:col-3 xl:col-3",
                    props: {
                      type: "text",
                      label: translate(VaccinationLabelMapping.vaccine),
                      maxLength: 200,
                    },
                  },
                  {
                    key: nameOfVaccinations("comments"),
                    type: "input",
                    className: "field col-12 lg:col-3 xl:col-3",
                    props: {
                      type: "text",
                      label: translate(VaccinationLabelMapping.comments),
                      maxLength: 200,
                    },
                  },
                  {
                    key: nameOfVaccinations("vaccinationStatus"),
                    type: "dropdown",
                    className: "field col-12 lg:col-3 xl:col-3",
                    props: {
                      label: translate(VaccinationLabelMapping.vaccinationStatus),
                      placeholder: translate("vaccinations.vaccinationStatus.placeholder"),
                      options: staticDataService.getStaticData(StaticDataType.VaccinationStatus, language),
                      filter: false,
                      showClear: false,
                      staticDataType: StaticDataType.VaccinationStatus,
                    },
                  },
                  {
                    type: "document-date",
                    key: "lastShot",
                    className: "field col-12 lg:col-6 xl:col-6",
                    props: {
                      label: translate("vaccinations.lastShot.title"),
                      dateField: "issueDate",
                      documentType: "CERTOFVACC",
                    },
                    hideExpression: (model) => !["FULL", "PARTIAL"].includes(model.vaccinationStatus),
                    expressionProperties: {
                      "props.candidateId": (_model, formState) => formState.candidateId,
                      "props.organizationId": (_model, formState) => formState.organizationId,
                      "props.selectedSet": (model) => ({ foreignKey: model?.id }),
                    },
                  },
                  {
                    type: "datepicker",
                    key: nameOfVaccinations("plannedFinalShot"),
                    className: "field col-12 lg:col-6 xl:col-6",
                    props: {
                      label: translate(VaccinationLabelMapping.plannedFinalShot),
                      showIcon: true,
                      inline: false,
                      minDate: new Date(1900, 0, 1),
                      useAlignmentCheckBox: true,
                    },
                    hideExpression: (model) => model.vaccinationStatus != "PARTIAL",
                    validation: {
                      messages: {
                        maxDate: translate("vaccinations.plannedFinalShot.invalid"),
                        minDate: translate("vaccinations.plannedFinalShot.invalid"),
                      },
                    },
                  },
                  {
                    type: "datepicker",
                    key: nameOfVaccinations("plannedNextShot"),
                    className: "field col-12 lg:col-6 xl:col-6",
                    props: {
                      label: translate(VaccinationLabelMapping.plannedNextShot),
                      showIcon: true,
                      inline: false,
                      minDate: new Date(1900, 0, 1),
                      useAlignmentCheckBox: true,
                    },
                    hideExpression: (model) => ["FULL", "PARTIAL"].includes(model.vaccinationStatus),
                    validation: {
                      messages: {
                        maxDate: translate("vaccinations.plannedNextShot.invalid"),
                        minDate: translate("vaccinations.plannedNextShot.invalid"),
                      },
                    },
                  },
                ],
              },
            },
            {
              id: "vaccinationsDocuments",
              key: "vaccinationsDocuments",
              type: "documentUpload",
              props: {
                tooltip: translate("file.upload"),
                types: ["CERTOFVACC"],
              },
            },
          ],
        },
        {
          id: "insuranceAndTax",
          props: {
            label: translate("insuranceAndTax.title"),
            icon: PrimeIcons.BRIEFCASE,
          },
          hideExpression: (_model, formState) => !!(formState?.sharedTabs && !formState?.sharedTabs?.insuranceAndTax),

          fieldGroup: [
            {
              fieldGroupClassName: "p-fluid formgrid grid",
              wrappers: ["fieldset"],
              props: {
                label: translate("insuranceAndTax.description"),
              },
              fieldGroup: [
                {
                  key: nameOf("socSecNumber"),
                  type: "input",
                  className: "field col-12 md:col-6 lg:col-3",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.socSecNumber),
                    tooltip: translate("socSecNumber.tooltip"),
                    maxLength: 50,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("socSecNumber")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("socSecNumber.required"),
                    },
                  },
                },
                {
                  key: nameOf("taxNumber"),
                  type: "input",
                  className: "field col-12 md:col-6 lg:col-3",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.taxNumber),
                    tooltip: translate("taxNumber.tooltip"),
                    maxLength: 100,
                  },
                  expressionProperties: {
                    "props.required": () => requiredFields?.includes(`os.profile.${nameOf("taxNumber")}`),
                  },
                  validation: {
                    messages: {
                      required: translate("taxNumber.required"),
                    },
                  },
                },
                {
                  key: nameOf("healthInsuranceCompanyName"),
                  type: "input",
                  className: "field col-12 lg:col-3",
                  props: {
                    type: "text",
                    maxLength: 100,
                    label: translate(candidateLabelMapping.healthInsuranceCompanyName),
                  },
                },
                {
                  key: nameOf("healthInsuranceNumber"),
                  type: "input",
                  className: "field col-12 lg:col-3",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.healthInsuranceNumber),
                    maxLength: 100,
                  },
                },
              ],
            },
            {
              fieldGroupClassName: "p-fluid formgrid grid",
              wrappers: ["fieldset"],
              props: { label: translate("liabilityInsurance.title") },
              fieldGroup: [
                {
                  key: nameOf("hasLiabilityInsurance"),
                  type: "checkbox",
                  className: "field col-12 lg:col-3 pt-5",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.hasLiabilityInsurance),
                    maxLength: 100,
                  },
                },
                {
                  key: nameOf("liabilityInsuranceNumber"),
                  type: "input",
                  className: "field col-12 lg:col-3",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.liabilityInsuranceNumber),
                    maxLength: 100,
                  },
                  expressionProperties: {
                    "props.disabled": (model) =>
                      model.hasLiabilityInsurance === false || model.hasLiabilityInsurance == null,
                  },
                },
                {
                  key: nameOf("liabilityInsuranceCompanyName"),
                  type: "input",
                  className: "field col-12 lg:col-3",
                  props: {
                    type: "text",
                    label: translate(candidateLabelMapping.liabilityInsuranceCompanyName),
                    maxLength: 100,
                  },
                  expressionProperties: {
                    "props.disabled": (model) =>
                      model.hasLiabilityInsurance === false || model.hasLiabilityInsurance == null,
                  },
                },
              ],
            },
          ],
        },
        {
          id: nameOf("bankDetails"),
          props: {
            wrappers: ["fieldset"],
            label: translate("bankDetails.title"),
            icon: PrimeIcons.BUILDING,
          },
          hideExpression: (_model, formState) => !!(formState?.sharedTabs && !formState?.sharedTabs?.bankDetails),
          fieldGroup: [
            {
              key: nameOf("bankDetails"),
              className: "field col-12",
              type: "repeat",
              props: {
                label: translate(candidateLabelMapping.bankDetails),
                addLabel: translate("bankDetails.add"),
                disabledAddButton: false,
                defaultValue: () => ({ id: uuidv4() }),
              },
              hooks: {
                // sort bank details by isImmigrationCountryBankDetail and isCurrentAccount
                afterViewInit: (field): void => {
                  sortBankDetails();

                  function sortBankDetails(): void {
                    field?.fieldGroup
                      ?.sort((a, b) => {
                        const a1 = getModel(a)?.isImmigrationCountryBankDetail === true;
                        const b1 = getModel(b)?.isImmigrationCountryBankDetail === true;
                        return ~~a1 - ~~b1 > 0 ? -1 : 1;
                      })
                      ?.sort((a, b) => {
                        const a1 = getModel(a)?.isCurrentAccount === true;
                        const b1 = getModel(b)?.isCurrentAccount === true;
                        return ~~a1 - ~~b1 > 0 ? -1 : 1;
                      });

                    function getModel(formlyField: FormlyFieldConfig): IBankDetailsModel {
                      return formlyField?.fieldGroup[0]?.form?.value;
                    }
                  }
                },
              },
              fieldArray: {
                fieldGroupClassName: "p-fluid formgrid grid col",
                fieldGroup: [
                  {
                    wrappers: ["fieldset"],
                    fieldGroupClassName: "p-fluid formgrid grid col",
                    className: "field col-12",
                    fieldGroup: [
                      {
                        type: "input",
                        key: nameOfBankDetails("id"),
                        props: {
                          type: "hidden",
                        },
                      },
                      {
                        key: nameOfBankDetails("isCurrentAccount"),
                        type: "checkbox",
                        className: "field col-12 lg:col-2",
                        props: {
                          label: translate(bankDetailsLabelMapping.isCurrentAccount),
                        },
                        hooks: {
                          afterViewInit: (field): void => {
                            const isCurrentAccountControl = field.form.controls[
                              nameOfBankDetails("isCurrentAccount")
                            ] as AbstractControl<boolean>;

                            const currentChange$ = isCurrentAccountControl.valueChanges.pipe(
                              startWith(isCurrentAccountControl.value)
                            );

                            currentChange$
                              .pipe(
                                tap((isCurrentAccount) => setFieldSetName(isCurrentAccount)),
                                filter((isCurrentAccount) => isCurrentAccount),
                                tap((_) => setUniqueCurrentBankDetail())
                              )
                              .subscribe();

                            function setUniqueCurrentBankDetail(): void {
                              if (!field.form.parent) return;
                              const other = (field.form.parent.controls as AbstractControl[]).filter((x) => {
                                return x.get(nameOfBankDetails("isCurrentAccount")).value === true;
                              });

                              if (other.length > 1) {
                                other.forEach((x) =>
                                  x.get(nameOfBankDetails("isCurrentAccount")).setValue(false, { emitEvent: true })
                                );
                                const control = field.form.controls[nameOfBankDetails("isCurrentAccount")];
                                if (control) control.setValue(true, { emitEvent: true });
                              }
                            }

                            function setFieldSetName(isCurrentAccount: boolean): void {
                              if (isCurrentAccount === true) {
                                field.parent.props.label = translate("isCurrentAccount.title");
                              } else {
                                field.parent.props.label = translate("isNotCurrentAccount.title");
                              }
                            }
                          },
                        },
                      },
                      {
                        type: "radio",
                        key: nameOfBankDetails("isImmigrationCountryBankDetail"),
                        className: "field flex flex-column justify-content-center col-12 lg:col-3",
                        props: {
                          defaultValue: true,
                          label: translate(bankDetailsLabelMapping.isImmigrationCountryBankDetail),
                          options: [
                            { value: true, label: translate("bankDetails.immigrationCountry") },
                            { value: false, label: translate("bankDetails.originalCountry") },
                          ],
                        },
                      },
                      {
                        type: "radio",
                        key: nameOfBankDetails("isSepa"),
                        className: "field flex flex-column justify-content-center col-12 lg:col-12",
                        props: {
                          defaultValue: true,
                          label: translate(bankDetailsLabelMapping.isSepa),
                          options: [
                            { value: true, label: translate("sepa.title") },
                            { value: false, label: translate("swift.title") },
                          ],
                        },
                        hooks: {
                          afterViewInit: (fieldConfig): void => {
                            const isSepaControl = fieldConfig.formControl as AbstractControl<boolean>;

                            isSepaControl.valueChanges
                              .pipe(
                                startWith(isSepaControl.value),
                                filter((x) => x === false),
                                // change to country observable --> not defined (hidden) when isSepa = true
                                debounceTime(100),
                                switchMap(() => getCountryControl().valueChanges),
                                tap((x) => setCurrency(x))
                              )
                              .subscribe();

                            function getCountryControl(): AbstractControl<string> {
                              return fieldConfig.form.controls[nameOfBankDetails("bankCountry")];
                            }

                            function setCurrency(country: string): void {
                              const control = fieldConfig.form.controls[nameOfBankDetails("currency")];
                              if (!control) return;

                              control.setValue(getCurrencyForCountry(country), { emitEvent: true });
                            }
                          },
                        },
                      },
                      {
                        type: "input",
                        key: nameOfBankDetails("bankName"),
                        className: "field col-12 lg:col-3 xl:col-3",
                        props: {
                          type: "text",
                          label: translate(bankDetailsLabelMapping.bankName),
                          maxLength: 100,
                        },
                      },
                      {
                        type: "input",
                        key: nameOfBankDetails("accountHolder"),
                        className: "field col-12 lg:col-3 xl:col-3",
                        props: {
                          type: "text",
                          label: translate(bankDetailsLabelMapping.accountHolder),
                          maxLength: 100,
                        },
                      },
                      {
                        type: "input",
                        key: nameOfBankDetails("iban"),
                        className: "field col-12 lg:col-3 xl:col-3",
                        props: {
                          type: "text",
                          label: translate(bankDetailsLabelMapping.iban),
                          maxLength: 100,
                        },
                        hideExpression: (model): boolean => !model?.isSepa, //bic only
                      },
                      {
                        type: "input",
                        key: nameOfBankDetails("bic"),
                        className: "field col-12 lg:col-3 xl:col-3",
                        props: {
                          type: "text",
                          label: translate(bankDetailsLabelMapping.bic),
                          maxLength: 100,
                        },
                        hideExpression: (model): boolean => !model?.isSepa, //bic only
                      },
                      {
                        key: nameOfBankDetails("bankCountry"),
                        type: "dropdown",
                        className: "field col-12 lg:col-3 xl:col-3",
                        props: {
                          label: translate(bankDetailsLabelMapping.bankCountry),
                          placeholder: translate("bankCountry.placeholder"),
                          required: false,
                          filter: true,
                          filterBy: "label",
                          options: staticDataService.getStaticData(StaticDataType.Countries, language),
                          staticDataType: StaticDataType.Countries,
                        },
                        hideExpression: (model): boolean => !!model?.isSepa, //bic only
                      },
                      {
                        key: nameOfBankDetails("currency"),
                        type: "dropdown",
                        className: "field col-12 lg:col-3 xl:col-3",
                        props: {
                          label: translate(bankDetailsLabelMapping.currency),
                          placeholder: translate("currency.placeholder"),
                          required: false,
                          filter: true,
                          filterBy: "label",
                          options: staticDataService.getStaticData(StaticDataType.Currencies, language),
                          staticDataType: StaticDataType.Currencies,
                        },
                        hideExpression: (model): boolean => !!model?.isSepa, //bic only
                      },
                      {
                        type: "input",
                        key: nameOfBankDetails("bankAccountNumber"),
                        className: "field col-12 lg:col-4 xl:col-4",
                        props: {
                          type: "text",
                          label: translate(bankDetailsLabelMapping.bankAccountNumber),
                          maxLength: 100,
                        },
                        hideExpression: (model): boolean => !!model?.isSepa, //bic only
                      },
                      {
                        type: "radio",
                        key: nameOfBankDetails("isBic"),

                        className: "col-12 lg:col-4 pt-4",
                        props: {
                          defaultValue: true,
                          options: [
                            { value: true, label: translate("bic.title") },
                            { value: false, label: translate("swift.title") },
                          ],
                        },
                        hideExpression: (model): boolean => !!model?.isSepa, //swift only
                      },
                      {
                        type: "input",
                        key: nameOfBankDetails("bankCode"),
                        className: "field col-12 lg:col-4 xl:col-4",
                        props: {
                          type: "text",
                          label: translate("bankSortNumber.title"),
                          maxLength: 100,
                        },
                        hideExpression: (model): boolean => !!model?.isSepa || !model?.isBic, //swift-bic only
                      },
                      {
                        type: "input",
                        key: nameOfBankDetails("bankCode"),
                        className: "field col-12 lg:col-4 xl:col-4",
                        props: {
                          type: "text",
                          label: translate(bankDetailsLabelMapping.bankCode),
                          maxLength: 100,
                        },
                        hideExpression: (model): boolean => !!model?.isSepa || !!model?.isBic, //swift only
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
        {
          id: "other",
          props: {
            label: translate("other.title2"),
            icon: PrimeIcons.STAR,
          },
          key: nameOf("other"),
          hideExpression: (_model, formState) => !!(formState?.sharedTabs && !formState?.sharedTabs?.other),
          fieldGroup: [
            {
              fieldGroupClassName: "p-fluid formgrid grid",
              fieldGroup: [
                {
                  key: nameOfOther("wayToUs"),
                  type: "dropdown",
                  className: "field col-12 lg:col-12",
                  props: {
                    label: translate(otherLabelMapping.wayToUs),
                    placeholder: translate("wayToUs.placeholder"),
                    options: staticDataService.getStaticData(StaticDataType.WayToUs, language),
                    filter: true,
                    filterBy: "label",
                    staticDataType: StaticDataType.WayToUs,
                  },
                },
                {
                  key: nameOfOther("hobbies"),
                  type: "textarea",
                  className: "field col-12 lg:col-12",
                  props: {
                    type: "text",
                    label: translate(otherLabelMapping.hobbies),
                    rows: 4,
                    maxLength: 500,
                    required: false,
                  },
                },
                {
                  key: nameOfOther("socialSkills"),
                  type: "textarea",
                  className: "field col-12 lg:col-12",
                  props: {
                    type: "text",
                    label: translate(otherLabelMapping.socialSkills),
                    rows: 4,
                    maxLength: 500,
                    required: false,
                  },
                },
                {
                  key: nameOfOther("itSkills"),
                  type: "textarea",
                  className: "field col-12 lg:col-12",
                  props: {
                    type: "text",
                    label: translate(otherLabelMapping.itSkills),
                    rows: 4,
                    maxLength: 500,
                    required: false,
                  },
                },
                {
                  key: nameOfOther("familiarEquipment"),
                  type: "textarea",
                  className: "field col-12 lg:col-12",
                  props: {
                    type: "text",
                    label: translate(otherLabelMapping.familiarEquipment),
                    rows: 4,
                    maxLength: 500,
                    required: false,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};
