import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({ selector: "[appScrollIntoView]" })
export class ScrollIntoViewDirective implements OnInit {
  @Input()
  appScrollIntoView: boolean;

  @Input()
  appScrollIntoViewOptions: ScrollIntoViewOptions;

  constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.appScrollIntoView) {
        this.elementRef.nativeElement.scrollIntoView(this.appScrollIntoViewOptions);
      }
    }, 0);
  }
}
