export const germanyMigrationCountrySpecificRequiredDocumentsFields = [
  "equivalenceTest", //order maters
  "recognitionType",
  "residencePermit",
] as const;

export const austriaMigrationCountrySpecificRequiredDocumentsFields = [
  "targetRecognition", //order maters
  "pathOfRecognition",
  "legalBasis",
] as const;
