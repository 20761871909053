export function mapExternalCountryCodeToInternalCountry(value: string): string {
  if (!value) {
    return null;
  }
  return ioc[value.toUpperCase()] ?? alpha3[value.toUpperCase()];
}

export function mapInternalCountryCodeToExternalCountry(value: string): string {
  const objects = [ioc, alpha3];
  for (const object of objects) {
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const element = object[key];
        if (element === value) {
          return key;
        }
      }
    }
  }
  return "";
}
export function mapInternalCountryCodeToExternalCountryAlpha3(value: string): string {
  for (const key in alpha3) {
    if (Object.prototype.hasOwnProperty.call(alpha3, key)) {
      const element = alpha3[key];
      if (element === value) {
        return key;
      }
    }
  }

  return "";
}

const ioc = {
  AFG: "AF",
  ALB: "AL",
  ALG: "DZ",
  ASA: "AS",
  AND: "AD",
  ANG: "AO",
  AIA: "AI",
  ANT: "AG",
  ARG: "AR",
  ARM: "AM",
  ARU: "AW",
  AUS: "AU",
  AUT: "AT",
  AZE: "AZ",
  BAH: "BS",
  BRN: "BH",
  BAN: "BD",
  BAR: "BB",
  BLR: "BY",
  BEL: "BE",
  BIZ: "BZ",
  BEN: "BJ",
  BER: "BM",
  BHU: "BT",
  BOL: "BO",
  AHO: "BQ",
  BIH: "BA",
  BOT: "BW",
  BRA: "BR",
  BRU: "BN",
  BUL: "BG",
  BUR: "BF",
  BDI: "BI",
  CAM: "KH",
  CMR: "CM",
  CAN: "CA",
  CPV: "CV",
  CAY: "KY",
  CAF: "CF",
  CHA: "TD",
  CHI: "CL",
  CHN: "CN",
  COL: "CO",
  COM: "KM",
  CGO: "CG",
  COD: "CD",
  COK: "CK",
  CRC: "CR",
  CIV: "CI",
  CRO: "HR",
  CUB: "CU",
  CYP: "CY",
  CZE: "CZ",
  DEN: "DK",
  DJI: "DJ",
  DMA: "DM",
  DOM: "DO",
  ECU: "EC",
  EGY: "EG",
  ESA: "SV",
  GEQ: "GQ",
  ERI: "ER",
  EST: "EE",
  ETH: "ET",
  FLK: "FK",
  FAR: "FO",
  FIJ: "FJ",
  FIN: "FI",
  FRA: "FR",
  FGU: "GF",
  FPO: "PF",
  GAB: "GA",
  GAM: "GM",
  GEO: "GE",
  GER: "DE",
  GHA: "GH",
  GIB: "GI",
  GRE: "GR",
  GRL: "GL",
  GRN: "GD",
  GUD: "GP",
  GUM: "GU",
  GUA: "GT",
  GUI: "GN",
  GBS: "GW",
  GUY: "GY",
  HAI: "HT",
  HON: "HN",
  HKG: "HK",
  HUN: "HU",
  ISL: "IS",
  IND: "IN",
  INA: "ID",
  IRI: "IR",
  IRQ: "IQ",
  IRL: "IE",
  ISR: "IL",
  ITA: "IT",
  JAM: "JM",
  JPN: "JP",
  JOR: "JO",
  KAZ: "KZ",
  KEN: "KE",
  KIR: "KI",
  PRK: "KP",
  KOR: "KR",
  KUW: "KW",
  KGZ: "KG",
  LAO: "LA",
  LAT: "LV",
  LIB: "LB",
  LES: "LS",
  LBR: "LR",
  LBA: "LY",
  LIE: "LI",
  LTU: "LT",
  LUX: "LU",
  MAC: "MO",
  MKD: "MK",
  MAD: "MG",
  MAW: "MW",
  MAS: "MY",
  MDV: "MV",
  MLI: "ML",
  MLT: "MT",
  MSH: "MH",
  MRT: "MQ",
  MTN: "MR",
  MRI: "MU",
  MAY: "YT",
  MEX: "MX",
  FSM: "FM",
  MDA: "MD",
  MON: "MC",
  MGL: "MN",
  MGO: "ME",
  MNT: "MS",
  MAR: "MA",
  MOZ: "MZ",
  MYA: "MM",
  NAM: "NA",
  NRU: "NR",
  NEP: "NP",
  NED: "NL",
  NCD: "NC",
  NZL: "NZ",
  NCA: "NI",
  NIG: "NE",
  NGR: "NG",
  NIU: "NU",
  NFI: "NF",
  NMA: "MP",
  NOR: "NO",
  OMA: "OM",
  PAK: "PK",
  PLW: "PW",
  PLE: "PS",
  PAN: "PA",
  PNG: "PG",
  PAR: "PY",
  PER: "PE",
  PHI: "PH",
  POL: "PL",
  POR: "PT",
  PUR: "PR",
  QAT: "QA",
  REU: "RE",
  ROU: "RO",
  RUS: "RU",
  RWA: "RW",
  HEL: "SH",
  SKN: "KN",
  LCA: "LC",
  SPM: "PM",
  VIN: "VC",
  SAM: "WS",
  SMR: "SM",
  STP: "ST",
  KSA: "SA",
  SEN: "SN",
  SRB: "RS",
  SEY: "SC",
  SLE: "SL",
  SIN: "SG",
  SVK: "SK",
  SLO: "SI",
  SOL: "SB",
  SOM: "SO",
  RSA: "ZA",
  ESP: "ES",
  SRI: "LK",
  SUD: "SD",
  SUR: "SR",
  SWZ: "SZ",
  SWE: "SE",
  SUI: "CH",
  SYR: "SY",
  TPE: "TW",
  TJK: "TJ",
  TAN: "TZ",
  THA: "TH",
  TLS: "TL",
  TOG: "TG",
  TGA: "TO",
  TTO: "TT",
  TUN: "TN",
  TUR: "TR",
  TKM: "TM",
  TKS: "TC",
  TUV: "TV",
  UGA: "UG",
  UKR: "UA",
  UAE: "AE",
  GBR: "GB",
  USA: "US",
  URU: "UY",
  UZB: "UZ",
  VAN: "VU",
  VEN: "VE",
  VIE: "VN",
  IVB: "VG",
  ISV: "VI",
  WAF: "WF",
  YEM: "YE",
  ZAM: "ZM",
  ZIM: "ZW",
};

const alpha3 = {
  AFG: "AF",
  ALA: "AX",
  ALB: "AL",
  DZA: "DZ",
  ASM: "AS",
  AND: "AD",
  AGO: "AO",
  AIA: "AI",
  ATA: "AQ",
  ATG: "AG",
  ARG: "AR",
  ARM: "AM",
  ABW: "AW",
  AUS: "AU",
  AUT: "AT",
  AZE: "AZ",
  BHS: "BS",
  BHR: "BH",
  BGD: "BD",
  BRB: "BB",
  BLR: "BY",
  BEL: "BE",
  BLZ: "BZ",
  BEN: "BJ",
  BMU: "BM",
  BTN: "BT",
  BOL: "BO",
  BES: "BQ",
  BIH: "BA",
  BWA: "BW",
  BVT: "BV",
  BRA: "BR",
  IOT: "IO",
  BRN: "BN",
  BGR: "BG",
  BFA: "BF",
  BDI: "BI",
  KHM: "KH",
  CMR: "CM",
  CAN: "CA",
  CPV: "CV",
  CYM: "KY",
  CAF: "CF",
  TCD: "TD",
  CHL: "CL",
  CHN: "CN",
  CXR: "CX",
  CCK: "CC",
  COL: "CO",
  COM: "KM",
  COG: "CG",
  COD: "CD",
  COK: "CK",
  CRI: "CR",
  CIV: "CI",
  HRV: "HR",
  CUB: "CU",
  CUW: "CW",
  CYP: "CY",
  CZE: "CZ",
  DNK: "DK",
  DJI: "DJ",
  DMA: "DM",
  DOM: "DO",
  ECU: "EC",
  EGY: "EG",
  SLV: "SV",
  GNQ: "GQ",
  ERI: "ER",
  EST: "EE",
  ETH: "ET",
  FLK: "FK",
  FRO: "FO",
  FJI: "FJ",
  FIN: "FI",
  FRA: "FR",
  GUF: "GF",
  PYF: "PF",
  ATF: "TF",
  GAB: "GA",
  GMB: "GM",
  GEO: "GE",
  DEU: "DE",
  GHA: "GH",
  GIB: "GI",
  GRC: "GR",
  GRL: "GL",
  GRD: "GD",
  GLP: "GP",
  GUM: "GU",
  GTM: "GT",
  GGY: "GG",
  GIN: "GN",
  GNB: "GW",
  GUY: "GY",
  HTI: "HT",
  HMD: "HM",
  HND: "HN",
  HKG: "HK",
  HUN: "HU",
  ISL: "IS",
  IND: "IN",
  IDN: "ID",
  IRN: "IR",
  IRQ: "IQ",
  IRL: "IE",
  IMN: "IM",
  ISR: "IL",
  ITA: "IT",
  JAM: "JM",
  JPN: "JP",
  JEY: "JE",
  JOR: "JO",
  KAZ: "KZ",
  KEN: "KE",
  KIR: "KI",
  PRK: "KP",
  KOR: "KR",
  KOS: "XK",
  KWT: "KW",
  KGZ: "KG",
  LAO: "LA",
  LVA: "LV",
  LBN: "LB",
  LSO: "LS",
  LBR: "LR",
  LBY: "LY",
  LIE: "LI",
  LTU: "LT",
  LUX: "LU",
  MAC: "MO",
  MKD: "MK",
  MDG: "MG",
  MWI: "MW",
  MYS: "MY",
  MDV: "MV",
  MLI: "ML",
  MLT: "MT",
  MHL: "MH",
  MTQ: "MQ",
  MRT: "MR",
  MUS: "MU",
  MYT: "YT",
  MEX: "MX",
  FSM: "FM",
  MDA: "MD",
  MCO: "MC",
  MNG: "MN",
  MNE: "ME",
  MSR: "MS",
  MAR: "MA",
  MOZ: "MZ",
  MMR: "MM",
  NAM: "NA",
  NRU: "NR",
  NPL: "NP",
  NLD: "NL",
  NCL: "NC",
  NZL: "NZ",
  NIC: "NI",
  NER: "NE",
  NGA: "NG",
  NIU: "NU",
  NFK: "NF",
  MNP: "MP",
  NOR: "NO",
  OMN: "OM",
  PAK: "PK",
  PLW: "PW",
  PSE: "PS",
  PAN: "PA",
  PNG: "PG",
  PRY: "PY",
  PER: "PE",
  PHL: "PH",
  PCN: "PN",
  POL: "PL",
  PRT: "PT",
  PRI: "PR",
  QAT: "QA",
  REU: "RE",
  ROU: "RO",
  RUS: "RU",
  RWA: "RW",
  BLM: "BL",
  SHN: "SH",
  KNA: "KN",
  LCA: "LC",
  MAF: "MF",
  SPM: "PM",
  VCT: "VC",
  WSM: "WS",
  SMR: "SM",
  STP: "ST",
  SAU: "SA",
  SEN: "SN",
  SRB: "RS",
  SYC: "SC",
  SLE: "SL",
  SGP: "SG",
  SXM: "SX",
  SVK: "SK",
  SVN: "SI",
  SLB: "SB",
  SOM: "SO",
  ZAF: "ZA",
  SGS: "GS",
  SSD: "SS",
  ESP: "ES",
  LKA: "LK",
  SDN: "SD",
  SUR: "SR",
  SJM: "SJ",
  SWZ: "SZ",
  SWE: "SE",
  CHE: "CH",
  SYR: "SY",
  TWN: "TW",
  TJK: "TJ",
  TZA: "TZ",
  THA: "TH",
  TLS: "TL",
  TGO: "TG",
  TKL: "TK",
  TON: "TO",
  TTO: "TT",
  TUN: "TN",
  TUR: "TR",
  TKM: "TM",
  TCA: "TC",
  TUV: "TV",
  UGA: "UG",
  UKR: "UA",
  ARE: "AE",
  GBR: "GB",
  UMI: "UM",
  USA: "US",
  URY: "UY",
  UZB: "UZ",
  VUT: "VU",
  VAT: "VA",
  VEN: "VE",
  VNM: "VN",
  VGB: "VG",
  VIR: "VI",
  WLF: "WF",
  ESH: "EH",
  YEM: "YE",
  ZMB: "ZM",
  ZWE: "ZW",
};
