export const enum ErrorCode {
  NOT_FOUND = "NOTFOUND",
  CONCURRENCY_VIOLATION = "OPTIMISTICCONCURRENCYVIALOTAION",
  GENERALERROR = "GENERALERROR",
  BULKOPERATIONFAILEDERROR = "BULKOPERATIONFAILEDERROR",
  AGGREGATEERROR = "AGGREGATEERROR",
  VERSIONERROR = "VERSIONERROR",
  BAD_USER_INPUT = "BAD_USER_INPUT",
  FORBIDDEN = "FORBIDDEN",
}
