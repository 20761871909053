<ng-container *ngIf="reRender">
  <div class="flex flex-column consent-wrap" *appTranslate="let t; lang: viewLanguage + '|static'">
    <h5 class="mt-4">{{ t("consent.title") }}</h5>
    <div class="consent">
      <app-tc-consent-view
        [consents]="consents"
        [viewLanguage]="viewLanguage"
        [initalIndex]="lastIndex"
        (tabChanged)="onTabChanged($event)"
      ></app-tc-consent-view>
    </div>
    <div>
      <div class="field-checkbox">
        <p-checkbox [binary]="true" [formControl]="agreedControl" inputId="binary" class="mt-2"></p-checkbox>
        <label for="binary" class="mt-2">
          <strong>{{ t("consent.read") }}</strong>
        </label>
      </div>
      <div class="align-items-end flex flex-row justify-content-end">
        <p-button [label]="t('common.ok')" (onClick)="okClicked()" [disabled]="!agreedControl.value"> </p-button>
      </div>
    </div>
  </div>
</ng-container>
