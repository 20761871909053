import { isEqual, omitBy, isNil } from "lodash";
import { CandidateEntry, DocumentDropZoneRow } from "./document-dropzone-form.model";
import { CollectionInformation, ComparableDocument, ComparableFile, ComparableSet } from "./document-dropzone.model";
import {
  CandidateFamilyMember,
  DocumentAiExtractionResult,
  DocumentAiExtractionResultData,
  StaticDataModel,
} from "@ankaadia/graphql";

export function isCandidateRecommended(candidate: CandidateEntry, extraction: DocumentAiExtractionResult): boolean {
  return isRecommended(extraction, (data) => {
    const { id, orgId } = data?.candidateId ?? {};
    return id && orgId && candidate.id === id && candidate.organizationId === orgId;
  });
}

export function isFamilyMemberRecommended(
  familyMember: CandidateFamilyMember,
  extraction: DocumentAiExtractionResult
): boolean {
  return isRecommended(extraction, (data) => {
    const { familyMemberId } = data;
    return familyMemberId && familyMember.id === familyMemberId;
  });
}

export function isDocumentTypeRecommended(
  recommendedDocumentType: StaticDataModel,
  extraction: DocumentAiExtractionResult
): boolean {
  return isRecommended(extraction, (data) => {
    const { documentType } = data;
    return documentType && recommendedDocumentType.value === documentType;
  });
}

export function isCollectionInformation(
  candidate: CandidateEntry | CollectionInformation
): candidate is CollectionInformation {
  const tested = candidate as CollectionInformation;
  return typeof tested.event === "object" && !isNil(tested.event);
}

export function extractDocumentDetails(row: DocumentDropZoneRow): ComparableDocument {
  const { candidateEntry, familyMember, documentType } = row;
  return {
    organizationId: candidateEntry?.organizationId,
    candidateId: candidateEntry?.id,
    familyMemberId: familyMember?.id,
    type: documentType,
  };
}

export function areDocumentsEqual(doc1: ComparableDocument, doc2: ComparableDocument): boolean {
  const reduced1 = reduceDocumentDetails(doc1);
  const reduced2 = reduceDocumentDetails(doc2);
  return isEqual(reduced1, reduced2);
}

export function areSetsEqual(set1: ComparableSet, set2: ComparableSet): boolean {
  const reduced1 = reduceSetDetails(set1);
  const reduced2 = reduceSetDetails(set2);
  return isEqual(reduced1, reduced2);
}

export function areFilesEqual(file1: ComparableFile, file2: ComparableFile): boolean {
  const reduced1 = reduceFileDetails(file1);
  const reduced2 = reduceFileDetails(file2);
  return isEqual(reduced1, reduced2);
}

function reduceDocumentDetails(doc: ComparableDocument): ComparableDocument {
  const { organizationId, candidateId, familyMemberId, type } = doc;
  return omitBy<ComparableDocument>({ organizationId, candidateId, familyMemberId, type }, isNil);
}

function reduceSetDetails(set: ComparableSet): ComparableSet {
  const { type, name, foreignKey } = set;
  return omitBy<ComparableSet>({ type, name, foreignKey }, isNil);
}

function reduceFileDetails(file: ComparableFile): ComparableFile {
  const { name, type, size } = file;
  return { name, type, size };
}

function isRecommended(
  extraction: DocumentAiExtractionResult,
  predicate: (data: DocumentAiExtractionResultData) => boolean
): boolean {
  const { done, success, data } = extraction;
  return done && success && predicate(data);
}
