import { AfterViewInit, Directive } from "@angular/core";
import { Dialog } from "primeng/dialog";

@Directive({ selector: "p-dialog" })
export class DialogFixDirective implements AfterViewInit {
  constructor(private readonly dialog: Dialog) {}

  ngAfterViewInit(): void {
    // Fixes: ExpressionChangedAfterItHasBeenCheckedError
    // PrimeNG Bug Item: https://github.com/primefaces/primeng/issues/13497
    // Fixed By: https://github.com/primefaces/primeng/pull/13538
    // Bug detected by us in: @16.3.2
    const ariaLabelledBy = this.dialog.getAriaLabelledBy();
    this.dialog.getAriaLabelledBy = (): string => ariaLabelledBy;
  }
}
