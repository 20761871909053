<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <div class="p-datatable" *ngIf="email">
    <table class="p-datatable-table">
      <thead class="p-datatable-thead">
        <tr>
          <th>{{ t("type.title") }}</th>
          <th>{{ t("email.title") }}</th>
          <th>{{ t("date.title") }}</th>
        </tr>
      </thead>
      <tbody class="p-datatable-tbody">
        <tr *ngFor="let event of email.events">
          <td>
            {{ event.type | enum: "EmailEventType" : processLanguage }}
            <i
              *ngIf="
                event.type === EmailEventType.Dropped ||
                event.type === EmailEventType.Blocked ||
                event.type === EmailEventType.Bounced ||
                event.type === EmailEventType.Failed
              "
              class="pi pi-exclamation-circle p-error"
              [pTooltip]="event.message"
              [escape]="true"
            ></i>
            <i
              *ngIf="event.type === EmailEventType.Resent"
              class="pi pi-info-circle p-info"
              [pTooltip]="event.message"
              [escape]="true"
            ></i>
          </td>
          <td>{{ event.email }}</td>
          <td>{{ event.date | appDateTime: { dateStyle: "short", timeStyle: "short" } : processLanguage }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
