import { IQualificationEvaluationRequest, nameofFactory } from "@ankaadia/ankaadia-shared";
import { translate as transloco, TranslateParams } from "@ngneat/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";

export function qualificationEvaluationRequestFormFactory(language: string): FormlyFieldConfig[] {
  const nameOf = nameofFactory<IQualificationEvaluationRequest>();
  const translate = <T>(key: TranslateParams, params?: Record<string, any>): T => transloco<T>(key, params, language);
  return [
    {
      fieldGroupClassName: "grid",
      fieldGroup: [
        {
          type: "checkbox",
          key: nameOf("evaluationRequested"),
          className: "field col-6",
          props: {
            label: translate("qualificationEvaluationRequested.title"),
            blocklyType: "Boolean",
          },
        },
        {
          key: nameOf("evaluationRequestedDate"), //done
          type: "datepicker",
          className: "field col-6",
          props: {
            label: translate("qualificationEvaluationRequestedDate.title"),
            showIcon: true,
            inline: false,
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(),
            blocklyType: "field_date",
          },
          expressionProperties: {
            "props.disabled": (model) => !model.evaluationRequested,
            "props.required": (model) => model.evaluationRequested,
          },
        },
        {
          className: "col-12",
          type: "input",
          key: nameOf("fileNumber"),
          props: {
            label: translate("qualificationEvaluationFileNumber.title"),
            type: "text",
            maxLength: 300,
            blocklyType: "String",
          },
        },
      ],
    },
  ];
}
