import { Directive, OnInit } from "@angular/core";
import { AutoComplete } from "primeng/autocomplete";

@Directive({ selector: "p-autoComplete" })
export class AutocompleteReadonlyFixDirective implements OnInit {
  private openDropdown: AutoComplete["handleDropdownClick"];

  constructor(private readonly autoComplete: AutoComplete) {}

  ngOnInit(): void {
    this.openDropdown = this.autoComplete.handleDropdownClick;
    this.autoComplete.handleDropdownClick = (event: Event): void => this.handleDropdownClick(this.autoComplete, event);
  }

  handleDropdownClick(autoComplete: AutoComplete, event: Event): void {
    if (autoComplete.readonly) {
      return;
    }
    this.openDropdown.call(autoComplete, event);
  }
}
