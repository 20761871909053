<app-candidate-document-date
  [candidateId]="to?.candidateId"
  [candidateOrganizationId]="to?.organizationId"
  [candidateForm]="field.form"
  [readonly]="to?.readonly || to?.disabled"
  [disabled]="to?.disabled"
  [label]="to?.label"
  [documentType]="to?.documentType"
  [selectedSet]="to.selectedSet"
  [dateField]="to?.dateField"
  [class]="to?.class"
></app-candidate-document-date>
