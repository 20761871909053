import { AfterContentInit, ChangeDetectorRef, Directive } from "@angular/core";
import { Toolbar } from "primeng/toolbar";

// https://github.com/angular/angular/issues/38976
@Directive({ selector: "p-toolbar" })
export class ToolbarFixDirective implements AfterContentInit {
  constructor(
    private readonly toolbar: Toolbar,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngAfterContentInit(): void {
    this.toolbar.templates.changes.subscribe(() => {
      this.toolbar.startTemplate = null;
      this.toolbar.endTemplate = null;
      // eslint-disable-next-line @angular-eslint/no-lifecycle-call
      this.toolbar.ngAfterContentInit();
      this.changeDetector.markForCheck();
    });
  }
}
