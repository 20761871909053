import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import Viewer from "viewerjs";

@Directive({ selector: "[appImageViewer]" })
export class ImageViewerDirective implements AfterViewInit, OnDestroy {
  @Input() viewerOptions: Viewer.Options = {};

  @Output() readonly viewerReady = new EventEmitter<Event>();
  @Output() readonly viewerShow = new EventEmitter<Event>();
  @Output() readonly viewerShown = new EventEmitter<Event>();
  @Output() readonly viewerHide = new EventEmitter<Event>();
  @Output() readonly viewerHidden = new EventEmitter<Event>();
  @Output() readonly viewerView = new EventEmitter<Event>();
  @Output() readonly viewerViewed = new EventEmitter<Event>();
  @Output() readonly viewerZoom = new EventEmitter<Event>();
  @Output() readonly viewerZoomed = new EventEmitter<Event>();

  instance: Viewer;

  private readonly nativeElement: HTMLElement;

  constructor(private readonly elementRef: ElementRef) {
    this.nativeElement = this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    this.initViewer();
  }

  private initViewer(): void {
    if (this.instance) {
      this.instance.destroy();
    }

    this.instance = new Viewer(this.nativeElement, {
      // Transitions currently break the Viewer when running optimizations during ng build (i.e in prod mode)
      // TODO: Find a fix for this so we don't have to force disable transitions

      transition: false,
      ...this.viewerOptions,
    });

    this.nativeElement.addEventListener("ready", (event) => this.viewerReady.emit(event), false);
    this.nativeElement.addEventListener("show", (event) => this.viewerShow.emit(event), false);
    this.nativeElement.addEventListener("shown", (event) => this.viewerShown.emit(event), false);
    this.nativeElement.addEventListener("hide", (event) => this.viewerHide.emit(event), false);
    this.nativeElement.addEventListener("hidden", (event) => this.viewerHidden.emit(event), false);
    this.nativeElement.addEventListener("view", (event) => this.viewerView.emit(event), false);
    this.nativeElement.addEventListener("viewed", (event) => this.viewerViewed.emit(event), false);
    this.nativeElement.addEventListener("zoom", (event) => this.viewerZoom.emit(event), false);
    this.nativeElement.addEventListener("zoomed", (event) => this.viewerZoomed.emit(event), false);
  }

  ngOnDestroy(): void {
    if (this.instance) {
      this.instance.destroy();
    }
  }
}
