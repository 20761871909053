import { DestroyRef, Directive, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NgControl } from "@angular/forms";
import { TranslocoService } from "@ngneat/transloco";
import { cloneDeep, groupBy } from "lodash";
import { Dropdown } from "primeng/dropdown";
import { tap } from "rxjs";

@Directive({ selector: "p-dropdown[formControlValueAlwaysInOptions]" })
export class DropdownFormControlValuePersistingDirective implements OnInit {
  @Input({ required: true })
  options: any[];

  /*
   * Disables the form control value from being always in the options
   * Changing this could be useful for example when :
   * 1. The form control value is not in the options when loaded, but we want to make sure it is never lost at the beginning
   * 2. The form control value depends on another, whose change triggers clearing of this form control value (which is prevented by this directive)
   * (Solution: set this input to true, before updating the form control value)
   * */
  @Input()
  disableFormControlValueAlwaysInOptions = false;

  @Input()
  optionLabel = "label";

  @Input()
  optionValue = "value";

  constructor(
    private readonly dropdown: Dropdown,
    private readonly control: NgControl,
    private readonly transloco: TranslocoService,
    private readonly destroyRef: DestroyRef
  ) {
    const { set: originalOptionsSet, get: originalOptionsGet } = Object.getOwnPropertyDescriptor(
      Dropdown.prototype,
      "options"
    );

    Object.defineProperty(dropdown, "options", {
      get: () => originalOptionsGet.call(dropdown),
      set: (options: any[]) =>
        originalOptionsSet.call(dropdown, this.getOptionsInclFormControlValue(cloneDeep(options), this.control?.value)),
    });
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.updateDropDown())
      )
      .subscribe();
  }

  getOptionsInclFormControlValue(options: any[], formValue: any): any[] {
    const plainOptions = (options ?? []).filter((x) => x[this.optionLabel] !== this.getInitialOptionLabel()) ?? []; //remove "dataNotViewable" option from options if it somehow ended up into it

    if (this.disableFormControlValueAlwaysInOptions) return plainOptions;

    if (formValue) {
      const optionsInclFormValue = [this.getOptionForFormValue(formValue), ...(plainOptions ?? [])];

      return Object.values(groupBy(optionsInclFormValue, this.optionValue)).map(
        (optionsForValue) =>
          optionsForValue.find((x) => x[this.optionLabel] != this.getInitialOptionLabel()) ?? optionsForValue[0]
      );
    }
    return plainOptions;
  }

  private updateDropDown(): void {
    this.dropdown.options = this.options;
  }

  private toOption(value: any, label: any): any {
    const option = {};
    option[this.optionLabel] = label;
    option[this.optionValue] = value;

    return option;
  }

  protected getOptionForFormValue(formVal: any): any {
    return this.toOption(formVal, this.getInitialOptionLabel());
  }

  private getInitialOptionLabel(): string {
    return this.transloco.translate("dataNotViewable");
  }
}
