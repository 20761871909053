<div class="unsubscribe-body" *transloco="let t">
  <div id="wrapper">
    <div id="unsubPanel">
      <div id="unsubForm" class="unsub-logo">
        <a class="logo" [routerLink]="['/app']">
          <img class="logo-img" src="assets/layout/images/logo.png" />
          <img class="logo-txt" src="assets/layout/images/logo-text.svg" />
        </a>
      </div>
      <div id="unsubForm">
        <div class="unsub-panel">
          <p>{{ t("unsubscribe.question") }}</p>
          <small>
            {{ t("unsubscribe.info") }}
          </small>
          <p-button [label]="t('unsubscribe.title')" (onClick)="onUnsubscribe()" [disabled]="showSuccess"></p-button>
          <small *ngIf="showSuccess"> {{ t("unsubscribe.success") }}</small>
        </div>
      </div>
    </div>
  </div>
</div>
