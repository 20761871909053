import { Injectable } from "@angular/core";
import {
  ApplyEmployerGQL,
  EmployerApplicationFragment,
  EmployerFilterDefaults,
  EmployerFilterFields,
  EmployerForListFragment,
  EmployerFragment,
  GetEmployerApplicationsGQL,
  GetEmployerFilterDefaultsGQL,
  GetEmployerFilterFieldsGQL,
  GetEmployerGQL,
  GetEmployersGQL,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";
import { EmployerFilter } from "./employer.component.model";

@Injectable({ providedIn: "root" })
export class EmployerService {
  constructor(
    private readonly filterDefaults: GetEmployerFilterDefaultsGQL,
    private readonly filterFields: GetEmployerFilterFieldsGQL,
    private readonly allEmployers: GetEmployersGQL,
    private readonly oneEmployer: GetEmployerGQL,
    private readonly applyEmployer: ApplyEmployerGQL,
    private readonly applications: GetEmployerApplicationsGQL
  ) {}

  getFilterDefaults(candidateId: string, organizationId: string): Observable<EmployerFilterDefaults> {
    return this.filterDefaults
      .fetch({ input: { candidateId, organizationId } })
      .pipe(map((x) => x.data.getEmployerFilterDefaults));
  }

  getFilterFields(candidateId: string, organizationId: string): Observable<EmployerFilterFields> {
    return this.filterFields
      .fetch({ input: { candidateId, organizationId } })
      .pipe(map((x) => x.data.getEmployerFilterFields));
  }

  getAll(candidateId: string, organizationId: string, input: EmployerFilter): Observable<EmployerForListFragment[]> {
    return this.allEmployers
      .fetch({ input: { ...input, candidateId: candidateId, organizationId: organizationId } })
      .pipe(map((x) => x.data.getEmployers));
  }

  get(
    employerOrganizationId: string,
    candidateId: string,
    organizationId: string,
    input: EmployerFilter = {}
  ): Observable<EmployerFragment> {
    return this.oneEmployer
      .fetch({
        input: {
          ...input,
          employerOrganizationId: employerOrganizationId,
          candidateId: candidateId,
          organizationId: organizationId,
        },
      })
      .pipe(map((x) => x.data.getEmployer));
  }

  getApplications(candidateId: string, organizationId: string): Observable<EmployerApplicationFragment[]> {
    return this.applications
      .fetch({ input: { candidateId: candidateId, organizationId: organizationId } })
      .pipe(map((x) => x.data.getEmployerApplications));
  }

  apply(
    employerOrganizationId: string,
    candidateId: string,
    organizationId: string,
    selectedLocations: string[],
    selectedPosition: string,
    coverLetter: string
  ): Observable<boolean> {
    return this.applyEmployer
      .mutate({
        input: {
          employerOrganizationId: employerOrganizationId,
          candidateId: candidateId,
          organizationId: organizationId,
          selectedLocations: selectedLocations,
          selectedPosition: selectedPosition,
          coverLetter: coverLetter,
        },
      })
      .pipe(map((x) => x.data.applyEmployer.status));
  }
}
