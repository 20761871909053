import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { EmailEventType, EmailForListFragment, EmailFragment } from "@ankaadia/graphql";

type EmailForTable = EmailForListFragment & {
  date: Date;
  wasNotDelivered: boolean;
  deliveryFailed: boolean;
  allRecipientsNotInvited: boolean;
  anyRecipientNotInvited: boolean;
};

@Component({
  selector: "app-email-selector",
  templateUrl: "./email-selector.component.html",
  styleUrl: "./email-selector.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailSelectorComponent {
  @Input()
  set emails(emails: EmailForListFragment[]) {
    this.emailsForTable = this.toEmailsForTable(emails);
  }

  get emails(): EmailForListFragment[] {
    return this.emailsForTable;
  }

  @Input()
  showEventsDisabled: boolean;

  @Input()
  showMessengerMessageDisabled: boolean;

  @Input()
  showBannedVisible: boolean;

  @Input()
  processLanguage?: string;

  @Output()
  readonly openPreview = new EventEmitter<EmailForListFragment>();

  @Output()
  readonly showEvents = new EventEmitter<{ email: EmailForListFragment; event: Event }>();

  @Output()
  readonly showMessengerMessages = new EventEmitter<{ email: EmailForListFragment; event: Event }>();

  @Output()
  readonly showBanned = new EventEmitter<{ email: EmailForListFragment; event: Event }>();

  emailsForTable: EmailForTable[];

  getGlobalFilterExpression(email: EmailFragment): string {
    return [email.subject, email.processName, ...email.to.map((r) => `${r.name} ${r.email}`)].join(" ");
  }

  private toEmailsForTable(emails: EmailForListFragment[]): EmailForTable[] {
    return emails?.map((email) => ({
      ...email,

      date:
        // if the email was sent to any recipient, display the date it was sent at
        // otherwise display the date the email was generated
        email.events?.find((e) => e.type === EmailEventType.Sent)?.date ??
        email.events?.find((e) => e.type === EmailEventType.OptedOut)?.date,

      wasNotDelivered: email.events?.some((e) =>
        [
          EmailEventType.Blocked,
          EmailEventType.Bounced,
          EmailEventType.Dropped,
          EmailEventType.NotSent,
          EmailEventType.OptedOut,
        ].includes(e.type)
      ),

      deliveryFailed: email.events?.some((e) => e.type === EmailEventType.Failed),

      allRecipientsNotInvited: email.to?.every((e) => !e.invited),

      anyRecipientNotInvited: email.to?.some((e) => !e.invited),
    }));
  }
}
