import { Pipe, PipeTransform } from "@angular/core";
import { Locale, ValidDate } from "@jsverse/transloco-locale";
import { Observable, map, of, startWith, tap } from "rxjs";
import { AppDateTimeFormatOptions } from "../../shared/pipes/date.pipe";
import { DateFormatterService } from "../../shared/services/date-formatter.service";
import { UsersService } from "./users.service";

const cache = new Map<string, Map<string, { expiryDate: Date; value: string }>>();

function cacheResult(organizationId: string, auth0Id: string, date: Date): void {
  const expiryDate = new Date(new Date().getTime() + 1000 * 60 * 5); // 5 minutes
  if (!cache.has(organizationId)) {
    cache.set(organizationId, new Map());
  }
  cache.get(organizationId).set(auth0Id, { expiryDate, value: date?.toISOString() });
}

function getFromCache(organizationId: string, auth0Id: string): string | undefined {
  const cacheEntry = cache.get(organizationId)?.get(auth0Id);
  if (cacheEntry && new Date(cacheEntry.expiryDate) > new Date()) {
    return cacheEntry.value;
  }
  return undefined;
}

@Pipe({ name: "appLastLoginDate" })
export class AppLoginDatePipe implements PipeTransform {
  constructor(
    private readonly usersService: UsersService,
    private readonly dateFormatterService: DateFormatterService
  ) {}

  transform(
    input: { auth0Id: string; organizationId: string },
    initalValue: string,
    options: AppDateTimeFormatOptions,
    locale?: Locale
  ): Observable<string> {
    const cachedValue = getFromCache(input.organizationId, input.auth0Id);
    if (cachedValue !== undefined) {
      return of(this.dateFormatterService.transformDateTime(new Date(cachedValue), options, locale));
    }
    return this.usersService.getLastLoginDate(input.auth0Id, input.organizationId).pipe(
      tap((date) => cacheResult(input.organizationId, input.auth0Id, date)),
      map((date: ValidDate) => this.dateFormatterService.transformDateTime(date, options, locale)),
      startWith(initalValue)
    );
  }
}
