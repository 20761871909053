import { Component, Input } from "@angular/core";
import { ActivityFragment } from "@ankaadia/graphql";
import { TranslocoService } from "@ngneat/transloco";
import { SettingsService } from "../../../shared/services/settings.service";

@Component({
  selector: "app-activity-info",
  templateUrl: "./activity-info.component.html",
})
export class ActivityInfoComponent {
  readonly language = this.transloco.getActiveLang();
  readonly organizationId = this.settings.organizationId;

  @Input()
  activity: ActivityFragment;

  constructor(
    private readonly settings: SettingsService,
    private readonly transloco: TranslocoService
  ) {}
}
