import { Component, DestroyRef, Input, OnInit } from "@angular/core";
import { DateWithTime } from "@ankaadia/graphql";
import { GraphQLFormModel } from "../services/form.helper";
import { TranslocoService } from "@ngneat/transloco";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

export type DateTimeForm = GraphQLFormModel<DateWithTime>;

@Component({
  selector: "app-date-time",
  templateUrl: "./date-time.component.html",
})
export class DateTimeComponent implements OnInit {
  private readonly language = this.transloco.getActiveLang();

  @Input()
  form: DateTimeForm;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  inputId: string;

  @Input()
  readonly: boolean;

  @Input()
  showClear = false;

  constructor(
    private readonly transloco: TranslocoService,
    private readonly desRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.form.controls.hasTime.valueChanges.pipe(takeUntilDestroyed(this.desRef)).subscribe((hasTime) => {
      if (!hasTime) {
        this.form.controls.hasTime.setValue(false, { emitEvent: false });
        const date = this.form.controls.date.value;
        if (date) {
          date.setUTCHours(0, 0, 0, 0);
          this.form.controls.date.setValue(date, { emitEvent: false });
        }
      }
    });
  }
}
