import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { MessagesReception } from "@ankaadia/graphql";
import { TranslocoService } from "@ngneat/transloco";
import { SettingsService } from "../../../shared/services/settings.service";
import { UsersService } from "../users.service";

@Component({
  selector: "app-messages-reception",
  templateUrl: "./messages-reception.component.html",
})
export class MessagesReceptionComponent implements OnInit, OnChanges {
  readonly language = this.transloco.getActiveLang();
  readonly defaultValue = true;
  enabled = true;

  @Input()
  messagesReception?: MessagesReception;

  @Input()
  readonly: boolean;

  @Input()
  showDetails: boolean;

  @Input()
  useDefault: boolean;

  @Output() readonly toggle = new EventEmitter<{ value: boolean; target: EventTarget }>();

  constructor(
    private readonly transloco: TranslocoService,
    private readonly userSvc: UsersService,
    private readonly settings: SettingsService
  ) {}

  ngOnInit(): void {
    if (!this.messagesReception && !this.useDefault) {
      this.fetchCurrentMsgReception();
    }
    this.userSvc.messagesReceptionChanged.subscribe(() => this.fetchCurrentMsgReception());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messagesReception) this.enabled = changes.messagesReception?.currentValue?.enabled ?? this.defaultValue;
    else this.enabled = this.defaultValue;
  }

  fetchCurrentMsgReception(): void {
    this.userSvc.getMessagesReception(this.settings.isCandidate).subscribe((val) => {
      this.messagesReception = val;
      this.enabled = val?.enabled;
    });
  }

  changed(event: Event): void {
    event["originalEvent"]?.stopPropagation();
    this.toggle.emit({ value: this.enabled, target: event["originalEvent"]?.target });
  }
}
