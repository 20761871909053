import { Directive, Input, OnInit } from "@angular/core";
import { Calendar } from "primeng/calendar";
import { AnyFunction } from "ts-essentials";

/* Because p-calendar does not have read-only mode. */
@Directive({ selector: "p-calendar[readonly]" })
export class CalendarReadonlyDirective implements OnInit {
  private static readonly methodSelector = <T extends keyof Calendar>(xs: T[]): readonly T[] => xs;
  // those are methods used in the component's template
  // hopefully, user interactions will be rendered pointless as soon as we intercept these
  private static readonly methods = CalendarReadonlyDirective.methodSelector([
    "onInputFocus",
    "onInputKeydown",
    "onInputClick",
    "onInputBlur",
    "onUserInput",
    "clear",
    "onButtonClick",
    "onOverlayClick",
    "onContainerButtonKeydown",
    "onNextButtonClick",
    "onPrevButtonClick",
    "switchToMonthView",
    "switchToYearView",
    "onDateSelect",
    "onDateCellKeydown",
    "onMonthSelect",
    "onMonthCellKeydown",
    "onYearSelect",
    "onYearCellKeydown",
    "incrementHour",
    "decrementHour",
    "incrementMinute",
    "decrementMinute",
    "incrementSecond",
    "decrementSecond",
    "onTimePickerElementMouseDown",
    "onTimePickerElementMouseUp",
    "onTimePickerElementMouseLeave",
    "toggleAMPM",
    "onTodayButtonClick",
    "onClearButtonClick",
  ]);

  private readonly sources: Record<keyof typeof CalendarReadonlyDirective.methods, AnyFunction> = <any>{};

  @Input()
  readonly: boolean;

  constructor(private readonly calendar: Calendar) {}

  ngOnInit(): void {
    for (const method of CalendarReadonlyDirective.methods) {
      this.sources[method] = this.calendar[method];
      this.calendar[method] = (...args: any[]): any => {
        if (this.readonly) {
          if (method === "onInputKeydown") {
            const handled = this.onInputKeydown.call(this.calendar, ...args);
            if (handled) {
              return true;
            }
          }

          args?.filter((x) => x instanceof Event).forEach((x) => x.preventDefault());
          return false;
        } else {
          return this.sources[method].call(this.calendar, ...args);
        }
      };
    }
  }

  private onInputKeydown(event: KeyboardEvent): boolean {
    if ((event.key === "c" || event.key === "Insert") && (event.ctrlKey || event.metaKey)) {
      // that's a copy command: ctrl+c, ctrl+insert or cmd+c
      return true;
    }
    return false;
  }
}
