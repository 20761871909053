import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { EmailFragment, FileAttachmentFragment } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { ConfirmationService, MenuItem, PrimeIcons } from "primeng/api";
import { SideBarSizeType } from "../../../shared/table/table.model";
import { DocumentsService } from "../../documents/documents.service";
import { EmailViewEncapsulationComponent } from "../email-viewencapsulation/email-viewencapsulation.component";

@Component({
  selector: "app-email-details",
  templateUrl: "./email-details.component.html",
  styleUrl: "./email-details.component.scss",
})
export class EmailDetailsComponent implements OnChanges {
  @Input()
  email: EmailFragment;

  @Input()
  outdated: boolean;

  @Input()
  editable: boolean;

  @Input()
  processLanguage?: string;

  @Output()
  readonly update = new EventEmitter<void>();

  @Output()
  readonly send = new EventEmitter<{ subject: string; body: string }>();

  @ViewChild("subjectEl")
  subjectEl: ElementRef<HTMLDivElement>;

  @ViewChild(EmailViewEncapsulationComponent)
  bodyEl: EmailViewEncapsulationComponent;

  isEditing: boolean;
  emailbody: string;

  protected attachments: MenuItem[] = [];
  protected previewAttachment: FileAttachmentFragment;
  protected fileUrl: string;
  protected sidebarSize: SideBarSizeType = "medium";
  protected sideBarSizeMapping = { medium: "p-sidebar-md", large: "p-sidebar-lg" };

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly documentService: DocumentsService
  ) {}

  get toggleArrow(): boolean {
    return this.sidebarSize === "medium" ? true : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.email) {
      this.onCancel();
      if (this.email != null) {
        this.attachments = (this.email.attachments ?? []).map((a) => ({
          label: a.name,
          command: (): void => this.openPreview(a),
        }));
      }
    }
  }

  closePreview(): void {
    this.previewAttachment = null;
    this.fileUrl = null;
  }

  openPreview(a: FileAttachmentFragment): void {
    this.documentService
      .getDownloadWithContainerUrl(
        a.containerName,
        a.fileId,
        this.email.candidateIds?.[0]?.orgId ?? this.email.processOrganizationId ?? this.email.organizationId,
        this.email.candidateIds?.[0]?.id
      )
      .subscribe((url) => {
        this.previewAttachment = a;
        this.fileUrl = url;
      });
  }

  allNotInvited(): boolean {
    return this.email?.to?.every((r) => r.invited === false);
  }

  anyNotInvited(): boolean {
    return this.email?.to?.some((r) => r.invited === false);
  }

  notInvitedCandidates(): string[] {
    return this.email?.to?.filter((r) => r.invited === false).map((r) => r.name) ?? [];
  }

  onEdit(): void {
    this.isEditing = true;
  }

  onCancel(): void {
    this.isEditing = false;
    if (this.subjectEl) {
      this.subjectEl.nativeElement.innerText = this.email?.subject;
    }
    setTimeout(() => {
      this.emailbody = this.email?.body + "dummy"; // force change detection
      setTimeout(() => {
        this.emailbody = this.email?.body;
      }, 0);
    }, 0);
  }

  onSend(event: Event, subject: string, body: string): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("email.confirmSend", null, this.processLanguage),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.isEditing = false;
        this.send.emit({ subject: subject, body: body });
      },
    });
  }
}
