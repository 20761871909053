import { FormlyExtension, FormlyFieldConfig, FormlyFieldProps } from "@ngx-formly/core";

export class FormlyReadonlyExtension implements FormlyExtension {
  static readonly Expression =
    "formState?.readonly === true ? true : (formState?.readonly === false ? false : field.props?.readonly) ";

  prePopulate(field: FormlyFieldConfig<FormlyFieldProps & Record<string, any>>): void {
    field.expressionProperties ??= {};
    if (field.expressionProperties["props.readonly"] == null) {
      field.expressionProperties["props.readonly"] = FormlyReadonlyExtension.Expression;
    }
  }
}
