<div class="exception-body accessdenied-body" *transloco="let t">
  <div class="exception-logo">
    <a class="logo" [routerLink]="['/app']">
      <img class="logo-img" src="assets/layout/images/logo.png" />
      <img class="logo-txt" src="assets/layout/images/logo-text.svg" />
    </a>
  </div>

  <div class="exception-panel">
    <img src="assets/layout/images/exception/icon-access.png" />

    <p class="exception-extra">{{ t("loginDenied.message2") }}</p>
  </div>

  <div class="exception-band">
    <div class="exception-content">
      <h3>{{ t("loginDenied.title") }}</h3>
      <p>{{ t("loginDenied.message") }}</p>
    </div>
  </div>
</div>
