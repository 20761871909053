import { first, groupBy, orderBy, toPairs } from "lodash";
import { ILanguageSkillModel } from "./model/candidate.model";

const sortedLanguageLevels = [
  "NV",
  "C2_CERT",
  "C2",
  "C2.3",
  "C2.2",
  "C2.1",
  "C1_DOCTOR",
  "C1_CERT",
  "C1",
  "C1.3",
  "C1.2",
  "C1.1",
  "B2_DOCTOR_CERT",
  "B2_DOCTOR",
  "B2_NURSE_CERT",
  "B2_NURSE",
  "B2_CERT",
  "B2",
  "B2.3",
  "B2.2",
  "B2.1",
  "B1_CERT",
  "B1_NURSE_CERT",
  "B1_NURSE",
  "B1",
  "B1.3",
  "B1.2",
  "B1.1",
  "A2_CERT",
  "A2",
  "A2.3",
  "A2.2",
  "A2.1",
  "A1_CERT",
  "A1",
  "A1.3",
  "A1.2",
  "A1.1",
];

function guardLanguageSkillIsIncludedInSortedList(level: string | string[]): void {
  if (Array.isArray(level)) {
    level.forEach((l) => guardLanguageSkillIsIncludedInSortedList(l));
    return;
  }
  if (!sortedLanguageLevels.includes(level)) {
    // eslint-disable-next-line no-console
    console.error(`Language level ${level} is not included in the sorted language levels.`);
  }
}

type LanguageLevel = (typeof sortedLanguageLevels)[number];

export type GermanLanguageLevelCertsType =
  | "LANGCERTGERA1"
  | "LANGCERTGERA2"
  | "LANGCERTGERB1"
  | "LANGCERTGERB2"
  | "LANGCERTGERC1"
  | "LANGCERTGERC2";

export type LanguageSkillSortMode = "FavorCertified" | "IgnoreCertification";
interface SortableLanguageLevel {
  certified?: boolean;
  skillLevel: string;
}

/*
 * Compare two language levels.
 * @param a - first language level
 * @param b - second language level
 *
 * @returns -1 if a is lower than b, 0 if a is equal to b, 1 if a is higher than b
 */
export function compareLanguageLevels(a: LanguageLevel, b: LanguageLevel): number {
  guardLanguageSkillIsIncludedInSortedList(a);
  guardLanguageSkillIsIncludedInSortedList(b);
  return sortedLanguageLevels.indexOf(b) - sortedLanguageLevels.indexOf(a);
}

export function sortLanguageSkills<T extends SortableLanguageLevel>(
  languageSkills: T[],
  mode: LanguageSkillSortMode
): T[] {
  guardLanguageSkillIsIncludedInSortedList((languageSkills ?? []).map((s) => s.skillLevel));
  return mode === "FavorCertified"
    ? orderBy(
        languageSkills,
        [(s): boolean => !!s.certified, (s): number => sortedLanguageLevels.indexOf(s.skillLevel)],
        ["desc", "asc"]
      )
    : orderBy(languageSkills, (s): number => sortedLanguageLevels.indexOf(s.skillLevel), "asc");
}

export function compressLanguageSkills<T extends ILanguageSkillModel>(
  languageSkills: T[],
  mode: LanguageSkillSortMode
): T[] {
  guardLanguageSkillIsIncludedInSortedList((languageSkills ?? []).map((s) => s.skillLevel));
  const groups = toPairs(groupBy(languageSkills, (skill) => skill.language));
  return groups.map(([_, skills]) => first(sortLanguageSkills(skills, mode)));
}

export const languageLevelGermanCertificateMapping: Record<LanguageLevel, GermanLanguageLevelCertsType | "LANGCERT"> = {
  NV: "LANGCERT",
  C2: "LANGCERTGERC2",
  C1_DOCTOR: "LANGCERTGERC1",
  C1: "LANGCERTGERC1",
  B2_DOCTOR: "LANGCERTGERB2",
  B2_NURSE: "LANGCERTGERB2",
  B2: "LANGCERTGERB2",
  B1_NURSE: "LANGCERTGERB1",
  B1: "LANGCERTGERB1",
  A2: "LANGCERTGERA2",
  A1: "LANGCERTGERA1",
};

export const EmployerRelocationLanguageLevels = [
  "A1",
  "A2",
  "B1",
  "B1_NURSE",
  "B2",
  "B2_NURSE",
  "B2_DOCTOR",
  "C1",
  "C2",
  "A1_CERT",
  "A2_CERT",
  "B1_CERT",
  "B1_NURSE_CERT",
  "B2_CERT",
  "B2_NURSE_CERT",
  "B2_DOCTOR_CERT",
  "C1_CERT",
  "C2_CERT",
];
export const VisaRelocationLanguageLevels = [
  "NOT_NEEDED",
  "A1",
  "A2",
  "B1",
  "B2",
  "C1",
  "C2",
  "A1_CERT",
  "A2_CERT",
  "B1_CERT",
  "B2_CERT",
  "C1_CERT",
  "C2_CERT",
];
