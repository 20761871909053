import { Injectable } from "@angular/core";
import {
  GetDiplomaticMissionsAbroadGQL,
  GetDiplomaticMissionsAtHomeGQL,
  StaticDataModel,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { uniqBy } from "lodash";
import { Observable, forkJoin, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class DiplomaticMissionService {
  constructor(
    private readonly atHome: GetDiplomaticMissionsAtHomeGQL,
    private readonly abroad: GetDiplomaticMissionsAbroadGQL
  ) {}

  getDiplomaticMissionsAtHome(
    immigrationCountry: SupportedImmigrationCountry,
    country?: string
  ): Observable<StaticDataModel[]> {
    return this.atHome.fetch({ input: { immigrationCountry, country } }, { fetchPolicy: "cache-first" }).pipe(
      map((x) =>
        x.data.getDiplomaticMissionsAtHome
          .map((diplomaticMission) => ({
            label: diplomaticMission.title + (diplomaticMission.city ? " - " + diplomaticMission.city : ""),
            value: diplomaticMission.id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      )
    );
  }

  getDiplomaticMissionsAbroad(
    immigrationCountry: SupportedImmigrationCountry,
    country?: string
  ): Observable<StaticDataModel[]> {
    return this.abroad.fetch({ input: { immigrationCountry, country } }, { fetchPolicy: "cache-first" }).pipe(
      map((x) =>
        x.data.getDiplomaticMissionsAbroad
          .map((mission) => {
            const city = mission.city ? " - " + mission.city : "";
            const country = mission.city ? ", " + mission.country : " - " + mission.country;
            const label = mission.title + city + (mission.country ? country : "");

            return {
              label: label,
              value: mission.id,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      )
    );
  }

  getManyDiplomaticMissionsAbroad(
    immigrationCountry: SupportedImmigrationCountry,
    countries?: string[]
  ): Observable<StaticDataModel[]> {
    return countries?.length
      ? forkJoin(countries.map((x) => this.getDiplomaticMissionsAbroad(immigrationCountry, x))).pipe(
          map((xs) => uniqBy(xs.flat(), (x) => x.value))
        )
      : this.getDiplomaticMissionsAbroad(immigrationCountry);
  }
}
