import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  DocumentField,
  DocumentWriteMode,
  SelectedDocumentField,
  getForeignKey,
  isDocumentSelectionMode,
  isSelectedDocumentField,
  isSelectedSetField,
  isSingleDocumentSet,
  nameofFactory,
} from "@ankaadia/ankaadia-shared";
import {
  Document,
  DocumentFormats,
  DocumentGenerationMode,
  DocumentMode,
  DocumentRequirement,
  DocumentTemplateMode,
  StaticDataModel,
  StaticDataType,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { isEmpty, isNil } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { Dropdown, DropdownChangeEvent } from "primeng/dropdown";
import {
  Observable,
  catchError,
  concat,
  concatMap,
  finalize,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
  throwError,
  toArray,
} from "rxjs";
import { observableOf } from "../../../shared/functions/observable-of";
import { VOID } from "../../../shared/functions/rxjs-extra";
import { MessageDialogService } from "../../../shared/message-dialog/message-dialog.service";
import { FileUploadService } from "../../../shared/services/file-upload.service";
import { updateValueAndValidity } from "../../../shared/services/form.helper";
import { safeForkJoin } from "../../../shared/services/safe-fork-join";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataRequest, StaticDataService } from "../../../shared/static-data/static-data.service";
import { AdditionalForeignKeySourceData } from "../../candidate-document-metadata/candidate-document-foreign-key-handler";
import { AnyCandidateForm } from "../../candidates/candidate-form/candidate-form.model";
import { DocumentRequirementService } from "../../document-requirements/document-requirement.service";
import { MessageService } from "../../message/message.service";
import {
  CleanDocument,
  DocumentFileForm,
  DocumentForm,
  FreeFormatFileForm,
  toCleanDocument,
} from "../document-form.model";
import { DocumentFormService } from "../document-form.service";
import { getWriteMode } from "../document-helper";
import { DocumentSetSelectorComponent } from "../document-set-selector/document-set-selector.component";
import { DocumentsService } from "../documents.service";
import { FreeFormatDocumentSelectorComponent } from "../free-format-document-selector/free-format-document-selector.component";

const nameOf = nameofFactory<DocumentEditDialogComponent>();

@Component({
  selector: "app-document-edit-dialog",
  templateUrl: "./document-edit-dialog.component.html",
  providers: [FileUploadService],
})
export class DocumentEditDialogComponent implements OnInit, OnChanges {
  private readonly language = this.transloco.getActiveLang();

  protected readonly DocumentMode = DocumentMode;
  protected readonly DocumentGenerationMode = DocumentGenerationMode;
  protected readonly DocumentTemplateMode = DocumentTemplateMode;
  protected readonly StaticDataType = StaticDataType;
  protected readonly isSelectedDocumentField = isSelectedDocumentField;
  protected readonly isSelectedSetField = isSelectedSetField;
  protected readonly isDocumentSelectionMode = isDocumentSelectionMode;
  protected readonly isSingleDocumentSet = isSingleDocumentSet;
  protected readonly generationModes: StaticDataModel[] = [
    { label: translate("enum.DocumentGenerationMode.OneCandidate"), value: DocumentGenerationMode.OneCandidate },
    { label: translate("enum.DocumentGenerationMode.AllCandidates"), value: DocumentGenerationMode.AllCandidates },
  ];

  protected form: DocumentForm;
  protected documentFormats$: Observable<DocumentFormats>;
  protected availablePhysicalFileTypes: StaticDataModel[] = [];
  protected submitDisabled: boolean;
  protected availableFileTypes: { value: string; label: string }[];
  protected unavailableFileNames: string[];
  protected staticDataRequest: StaticDataRequest = null;
  protected showInternalDocInternal = false;
  protected showDocumentCompletionInternal = false;

  @Input({ required: true })
  document: Document;

  @Input({ required: true })
  mode: DocumentMode;

  @Input()
  readonly = false;

  @Input()
  creatableDocumentTypes: StaticDataModel[] = [];

  @Input()
  existingDocuments: Document[] = [];

  @Input()
  processLanguage?: string = this.language;

  @Input()
  personName?: string;

  @Input()
  confirmCreation = false;

  @Input()
  showInternalDoc?: boolean = null;

  @Input()
  showDocumentCompletion?: boolean = null;

  @Input()
  selectedField?: SelectedDocumentField;

  @Input()
  candidateForm?: AnyCandidateForm;

  @Input()
  additionalForeignKeySourceData: AdditionalForeignKeySourceData;

  @Output()
  readonly saved = new EventEmitter<Document | null>();

  @Output()
  readonly closed = new EventEmitter<void>();

  @ViewChild("target")
  confirmationTarget: ElementRef;

  @ViewChild("documentTypeDropdown")
  documentTypeDropdown: Dropdown;

  @ViewChild(DocumentSetSelectorComponent)
  documentSelector: DocumentSetSelectorComponent;

  @ViewChild(FreeFormatDocumentSelectorComponent)
  freeFormatDocumentSelector: FreeFormatDocumentSelectorComponent;

  get documentId(): string {
    return this.form.value.id;
  }

  get isDisplayNameReadOnly(): boolean {
    return this.mode === DocumentMode.Template && this.document.id ? !this.settings.isMasterUser : false;
  }

  get showFreeFormatFiles(): boolean {
    return this.mode === DocumentMode.Candidate && !this.settings.isCandidate;
  }

  get isCandidate(): boolean {
    return this.settings.isCandidate;
  }

  get isInMetadataMode(): boolean {
    return this.form.controls.writeMode.value === DocumentWriteMode.Metadata;
  }

  get isReadonly(): boolean {
    return this.readonly || this.documentService.isReadOnly(this.document);
  }

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly documentService: DocumentsService,
    private readonly errorService: MessageDialogService,
    private readonly fileUploadService: FileUploadService,
    private readonly settings: SettingsService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly documentRequirementService: DocumentRequirementService,
    private readonly documentFormService: DocumentFormService
  ) {}

  ngOnInit(): void {
    const { mode: documentMode, isReadonly } = this;
    this.form = this.documentFormService
      .createDocumentFormBuilder(this.document, { documentMode, isReadonly })
      .createDocumentForm({
        unavailableFileNames: this.unavailableFileNames,
        foreignKey: getForeignKey(this.selectedField),
      });

    of(this.mode !== DocumentMode.Candidate || !this.document.type)
      .pipe(switchMap((useDefault) => (useDefault ? this.getDefaultRestrictions() : this.getRestrictionsByType())))
      .subscribe(([writeMode, requirement]) => {
        this.form.controls.writeMode.setValue(writeMode);
        this.form.controls.requirement.setValue(requirement);
        this.form.controls.isInternalByRequirement.setValue(requirement.isInternal);
        this.updateDocumentTypeControl();
        updateValueAndValidity(this.form);
        this.changeDetector.detectChanges();
      });

    this.documentFormats$ = observableOf(this.form.controls.type).pipe(
      switchMap(() => this.documentService.loadDocumentFormats(this.form))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes[nameOf("document")]) {
      this.staticDataRequest = {
        organizationId: this.document.organizationId,
        candidateId: this.document.candidateId,
      };
    }

    const isCandidateMode = this.mode === DocumentMode.Candidate;
    if (
      isCandidateMode &&
      (changes[nameOf("document")] || changes[nameOf("creatableDocumentTypes")] || changes[nameOf("processLanguage")])
    ) {
      this.updateDocumentFormats();
      this.updateAvailableFileTypes();
    }

    if (!isCandidateMode && (changes[nameOf("document")] || changes[nameOf("existingDocuments")])) {
      this.unavailableFileNames = this.existingDocuments
        .filter((document) => document.id !== this.document.id)
        .map((document) => document.displayName);
    }

    if (this.form && (changes[nameOf("readonly")] || changes[nameOf("creatableDocumentTypes")])) {
      this.updateDocumentTypeControl();
    }
  }

  onDocumentTypeChange(event: DropdownChangeEvent): void {
    const originalEvent = event?.originalEvent;
    if (!this.documentTypeDropdown?.overlayVisible || !(originalEvent instanceof KeyboardEvent)) {
      this.updateDocumentType();
    }
  }

  updateDocumentType(): void {
    if (this.documentSelector && this.documentTypeDropdown?.value && this.confirmationTarget?.nativeElement) {
      this.documentSelector.updateDocumentType(this.documentTypeDropdown.value, this.confirmationTarget.nativeElement);
      this.form.markAsDirty();
    }
  }

  submit(event: SubmitEvent): void {
    if (this.confirmCreation && !this.document.id) {
      this.confirmationService.confirm({
        target: event.submitter,
        message: translate(
          this.getTranslateKey("confirmCreate"),
          { personName: this.personName },
          this.processLanguage
        ),
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        accept: () => this.upload(),
      });
    } else {
      this.upload();
    }
  }

  closeDialog(): void {
    this.closed.emit();
  }

  upload(): void {
    this.submitDisabled = true;
    const { organizationId, candidateId } = this.form.getRawValue();
    if (this.isInMetadataMode) {
      this.form.controls.documentSets.controls.forEach((set) => set.controls.files.clear());
      this.form.controls.freeFormatFiles.clear();
    }

    const documentFileForms = this.getFiles(this.form);
    const freeFormatFileForms = this.getFreeFormatFiles(this.form);
    const fileForms = [...documentFileForms, ...freeFormatFileForms];
    const fileUploads$ = fileForms.map((fileForm) => {
      const { blob, file } = fileForm.getRawValue();
      return this.documentService.getUploadUrl(blob, organizationId, candidateId).pipe(
        switchMap(({ url }) => this.fileUploadService.uploadAsObservable(url, file)),
        catchError((error) => {
          this.errorService.showMessage(
            translate("file.uploadFailed", null, this.processLanguage),
            translate("file.bad", { name: error.message }, this.processLanguage)
          );

          return throwError(() => error);
        })
      );
    });

    const document = toCleanDocument(this.form);
    this.validateDocument(document)
      .pipe(
        switchMap(() => this.validateFiles(fileForms.map((fileForm) => fileForm.controls.file.value))),
        switchMap(() => safeForkJoin(fileUploads$, [])),
        concatMap(() => (isNil(document.id) ? this.createDocument(document) : this.updateDocument(document))),
        tap((document) => this.saved.emit(document)),
        finalize(() => (this.submitDisabled = false))
      )
      .subscribe();
  }

  private updateDocument(document: CleanDocument): Observable<Document> {
    return this.documentService.update(document, this.form.controls.writeMode.value).pipe(
      tap(() => {
        const summary = translate(this.getTranslateKey("updated.title"), null, this.processLanguage);
        const detail = translate(this.getTranslateKey("updated.message"), document, this.processLanguage);
        this.messageService.add({ severity: "success", summary, detail });
      })
    );
  }

  private createDocument(document: CleanDocument): Observable<Document> {
    return this.documentService.create(document, this.form.controls.writeMode.value).pipe(
      tap(() => {
        const summary = translate(this.getTranslateKey("created.title"), null, this.processLanguage);
        const detail = translate(this.getTranslateKey("created.message"), document, this.processLanguage);
        this.messageService.add({ severity: "success", summary, detail });
      })
    );
  }

  isSelected(field: DocumentField): boolean {
    return isSelectedDocumentField(this.selectedField) && this.selectedField === field;
  }

  private getFiles(documentForm: DocumentForm): DocumentFileForm[] {
    return documentForm.controls.documentSets.controls
      .flatMap((set) => set.controls.files.controls)
      .filter((file) => !isNil(file.controls.file.value));
  }

  private getFreeFormatFiles(documentForm: DocumentForm): FreeFormatFileForm[] {
    return documentForm.controls.freeFormatFiles.controls.filter((file) => !isNil(file.controls.file.value));
  }

  private updateDocumentFormats(): void {
    this.staticDataService
      .getStaticData(StaticDataType.DocumentFormats, this.processLanguage, this.staticDataRequest)
      .subscribe((documentFormats) => (this.availablePhysicalFileTypes = documentFormats));
  }

  private updateAvailableFileTypes(): void {
    this.staticDataService
      .getStaticData(StaticDataType.AllowedUploadFileTypes, this.processLanguage, this.staticDataRequest)
      .pipe(
        map((fileTypes) => {
          const { id, type } = this.document;
          const creatableDocumentTypes = this.creatableDocumentTypes.map(({ value }) => value);
          return isEmpty(creatableDocumentTypes)
            ? fileTypes.filter(({ value }) => value === type)
            : fileTypes.filter(({ value }) => creatableDocumentTypes.includes(value) || (id && value === type));
        })
      )
      .subscribe((fileTypes) => (this.availableFileTypes = fileTypes));
  }

  private updateDocumentTypeControl(): void {
    if (this.isReadonly || isEmpty(this.creatableDocumentTypes)) {
      this.form.controls.type.disable();
    } else {
      this.form.controls.type.enable();
    }
  }

  private getDefaultRestrictions(): Observable<[DocumentWriteMode, DocumentRequirement]> {
    return forkJoin([of(DocumentWriteMode.Full), of(new DocumentRequirement())]);
  }

  private getRestrictionsByType(): Observable<[DocumentWriteMode, DocumentRequirement]> {
    const { organizationId, candidateId, type, isInternalDocument } = this.document;
    const requirement$ = this.documentRequirementService.getByType({ type, organizationId });
    const access$ = this.documentService.getEffectiveDocumentAccess({ organizationId, candidateId, type });
    return forkJoin([requirement$, access$]).pipe(
      map(([requirement, access]) => {
        const writeMode = getWriteMode(access, { isInternalDocument, isInternalByRequirement: requirement.isInternal });
        return [writeMode, requirement];
      })
    );
  }

  private getTranslateKey(key: string): string {
    return `${this.mode === DocumentMode.Template ? "template" : "document"}.${key}`;
  }

  private validateDocument(document: CleanDocument): Observable<boolean> {
    return this.mode === DocumentMode.Template ? this.documentService.validateTemplate(document) : of(true);
  }

  private validateFiles(files: File[]): Observable<void> {
    return !isEmpty(files) ? concat(...files.map((f) => this.fileUploadService.validate(f))).pipe(toArray()) : of(VOID);
  }
}
