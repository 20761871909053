import { CandidateAllowance, nameofFactory } from "@ankaadia/ankaadia-shared";
import { translate as transloco, TranslateParams } from "@ngneat/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";

export interface CandidateAllowanceContext {}

export function candidateAllowanceFormFactory(
  _context: CandidateAllowanceContext,
  language: string
): FormlyFieldConfig[] {
  const nameOf = nameofFactory<CandidateAllowance>();
  const translate = <T>(key: TranslateParams, params?: Record<string, any>): T => transloco<T>(key, params, language);
  return [
    {
      className: "block m-1",
      fieldGroup: [
        {
          key: nameOf("amount"),
          type: "input",
          props: {
            type: "number",
            label: translate("candidateAllowance.title"),
            min: 0,
            inline: false,
            required: true,
            blocklyType: "Number",
          },
        },
      ],
    },
  ];
}
