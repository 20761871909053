import { Pipe, PipeTransform } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { isEmpty, keys } from "lodash";
import { FormErrorsExceptForPipe, FormErrorsPipe } from "./form-errors.pipe";
import { KeysOf } from "@ankaadia/ankaadia-shared";

// eslint-disable-next-line @angular-eslint/no-pipe-impure
@Pipe({ name: "hasFormErrors", pure: false })
export class HasFormErrorsPipe implements PipeTransform {
  constructor(private readonly formErrorsPipe: FormErrorsPipe) {}

  transform(form: FormGroup | FormArray): boolean {
    return !isEmpty(keys(this.formErrorsPipe.transform(form)));
  }
}

// eslint-disable-next-line @angular-eslint/no-pipe-impure
@Pipe({ name: "hasFormErrorsExceptFor", pure: false })
export class HasFormErrorsExceptForPipe implements PipeTransform {
  constructor(private readonly formErrorsExceptForFieldsPipe: FormErrorsExceptForPipe) {}

  transform<T extends FormGroup | FormArray>(form: T, exclude?: KeysOf<T["controls"]>): boolean {
    return !isEmpty(keys(this.formErrorsExceptForFieldsPipe.transform(form, exclude)));
  }
}
