import { AfterViewInit, Directive, OnDestroy, Renderer2 } from "@angular/core";
import { ConfirmPopup } from "primeng/confirmpopup";

@Directive({ selector: "p-confirmPopup" })
export class DialogKeysDirective implements AfterViewInit, OnDestroy {
  private static counter = 0;
  private static listener: () => void;

  constructor(
    private readonly confirmPopup: ConfirmPopup,
    private readonly renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    if (DialogKeysDirective.counter === 0) {
      DialogKeysDirective.listener = this.renderer.listen("document", "keydown", (event) => {
        if (this.confirmPopup.visible) {
          switch (event.which) {
            case 13:
              setTimeout(() => this.confirmPopup.accept(), 0);
              event.preventDefault();
              break;
            case 27:
              setTimeout(() => this.confirmPopup.reject(), 0);
              event.preventDefault();
              break;
          }
        }
      });
    }
    DialogKeysDirective.counter++;
  }

  ngOnDestroy(): void {
    DialogKeysDirective.counter--;
    if (DialogKeysDirective.counter === 0) {
      DialogKeysDirective.listener();
    }
  }
}
