//nameofFactory.ts

// helps to ensure typesafetiness for property names in typescript
export const nameofFactory =
  <T>() =>
  (name: keyof T): keyof T =>
    name;

// helps to ensure typesafetiness for property names in typescript
export const propertyofFactory =
  <T>() =>
  (name: Property<T>): Property<T> =>
    name;

/*
Example usage
import nameofFactory from './nameofFactory';

const nameof = nameofFactory<Person>();


interface Person {
    firstName: string;
    lastName: string;
}

const personName = nameof<Person>("noName");    //error!
 */

/* V2 of this ^ */

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${"" extends P ? "" : "."}${P}`
    : never
  : never;

// `Prev` should monotonically count up to the default `D` value of the `Property` down there!
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, ...0[]];

// `D` is the maximum depth level. If you change it, you need to ensure `Prev` up there runs till at least this number!
export type Property<T, D extends number = 6> = [D] extends [never]
  ? never
  : T extends Date
    ? never
    : T extends object
      ? {
          [K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Property<T[K], Prev[D]>> : never;
        }[keyof T]
      : "";

/* example usage:

const p1: Property<Candidate> = "firstName"; // ok
const p2: Property<Candidate> = "systemOnboarding.isBlocked"; // also ok
const p3: Property<Candidate> = "nonExistingProperty"; // nope
 */
