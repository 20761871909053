import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  AvailableDocumentTemplate,
  DocumentTemplateForListFragment,
  DocumentTemplateFragment,
  DocumentTemplateStatus,
  DocumentTemplateType,
  DocumentTemplateWarning,
  StaticDataModel,
  StaticDataType,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { BlockableUI, ConfirmationService, PrimeIcons, SelectItemGroup } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { OverlayPanel } from "primeng/overlaypanel";
import { Observable, catchError, finalize, forkJoin, map, switchMap, throwError } from "rxjs";
import { MessageDialogService } from "../../../shared/message-dialog/message-dialog.service";
import { AppDateTimePipe } from "../../../shared/pipes/date.pipe";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { DownloadService } from "../../../shared/services/download.service";
import { FileUploadService } from "../../../shared/services/file-upload.service";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { PipeDescription, TableColumn, TableOperation, TableOperationMode } from "../../../shared/table/table.model";
import { DocumentsService } from "../../documents/documents.service";
import { MessageService } from "../../message/message.service";
import { isSupportedByDocumentTemplatePreview } from "../document-template-preview/document-template-preview.component";
import { DocumentTemplateWarningsDialogComponent } from "../document-template-warnings-dialog/document-template-warnings-dialog.component";
import { DocumentTemplateStatusIcons } from "../document-templates.model";
import { DocumentTemplatesService } from "../document-templates.service";

type DocumentTemplateForTable = DocumentTemplateForListFragment & {
  localizedName: string;
  collectionId?: string;
  collectionOrganizationId?: string;
};
type DocumentTemplateForPreview = DocumentTemplateFragment & { localizedName: string; url: string };
const nameOf = nameofFactory<DocumentTemplateForTable>();

@Component({
  selector: "app-document-templates",
  templateUrl: "./document-templates.component.html",
  styleUrl: "./document-templates.component.scss",
  providers: [FileUploadService],
})
export class DocumentTemplatesComponent implements OnInit, OnChanges, BlockableUI {
  private isBusy = false;

  readonly tableOperations: TableOperation[] = [
    {
      label: translate("template.generate"),
      icon: PrimeIcons.FILE,
      operation: (x: DocumentTemplateForTable, e: Event): void =>
        this.generate(
          { ...x, collectionId: this.collectionId, collectionOrganizationId: this.collectionOrganizationId },
          e
        ),
      disabled: (): boolean => this.isBusy || this.readonly,
    },
    {
      label: translate("template.download"),
      icon: PrimeIcons.DOWNLOAD,
      operation: (x: DocumentTemplateForTable): void => this.download(x),
      disabled: (x: DocumentTemplateForListFragment): boolean =>
        this.isBusy ||
        this.readonly ||
        [
          DocumentTemplateStatus.NotGenerated,
          DocumentTemplateStatus.Error,
          DocumentTemplateStatus.NotAvailable,
          DocumentTemplateStatus.Deprecated,
        ].includes(x.status),
      canOperate: (x: DocumentTemplateForListFragment): boolean =>
        x.type === DocumentTemplateType.Pdf ||
        x.type === DocumentTemplateType.Xlsx ||
        x.type === DocumentTemplateType.Json,
    },
    {
      label: translate("template.download"),
      icon: PrimeIcons.DOWNLOAD,
      mode: TableOperationMode.Menu,
      items: [
        {
          label: translate("template.downloadDocx"),
          operation: (x: DocumentTemplateForTable): void => this.download(x, { type: "docx" }),
        },
        {
          label: translate("template.downloadPdf"),
          operation: (x: DocumentTemplateForTable): void => this.download(x, { type: "pdf" }),
        },
      ],
      operation: (x: DocumentTemplateForTable): void => this.download(x),
      disabled: (x: DocumentTemplateForListFragment): boolean =>
        this.isBusy ||
        this.readonly ||
        [
          DocumentTemplateStatus.NotGenerated,
          DocumentTemplateStatus.Error,
          DocumentTemplateStatus.NotAvailable,
          DocumentTemplateStatus.InProgress,
          DocumentTemplateStatus.Deprecated,
        ].includes(x.status),
      canOperate: (x: DocumentTemplateForListFragment): boolean => x.type === DocumentTemplateType.Docx,
    },
    {
      label: translate("common.edit"),
      icon: PrimeIcons.PENCIL,
      operation: (x: DocumentTemplateForTable): void => this.edit(x),
      disabled: (x: DocumentTemplateForListFragment): boolean =>
        this.isBusy ||
        this.readonly ||
        [
          DocumentTemplateStatus.NotGenerated,
          DocumentTemplateStatus.Error,
          DocumentTemplateStatus.NotAvailable,
          DocumentTemplateStatus.InProgress,
          DocumentTemplateStatus.Deprecated,
        ].includes(x.status) ||
        x.type === DocumentTemplateType.Json,
      canOperate: (x: DocumentTemplateForListFragment): boolean => isSupportedByDocumentTemplatePreview(x.type),
    },
  ];

  readonly captionOperations: TableOperation[] = [
    {
      label: translate("template.generateAll"),
      icon: PrimeIcons.FILE,
      operation: (_, e: Event): void => this.generateAll(e),
      disabled: (): boolean => this.isBusy || this.readonly || !this.templates?.length,
    },
    {
      label: translate("template.downloadAll"),
      icon: PrimeIcons.DOWNLOAD,
      mode: TableOperationMode.SplitButton,
      operation: (): void => this.downloadAll(false),
      disabled: (): boolean =>
        this.isBusy ||
        this.readonly ||
        !this.templates?.length ||
        this.templates.every((x) =>
          [
            DocumentTemplateStatus.NotGenerated,
            DocumentTemplateStatus.NotAvailable,
            DocumentTemplateStatus.Error,
            DocumentTemplateStatus.Deprecated,
          ].includes(x.status)
        ),
      items: [
        {
          label: translate("template.downloadAllInAllFormats"),
          operation: (): void => this.downloadAll(true),
        },
      ],
    },
  ];

  readonly candidateColumns: TableColumn[] = [
    {
      header: translate("template.title"),
      fieldname: nameOf("localizedName"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("type.title"),
      fieldname: nameOf("type"),
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(EnumPipe, "DocumentTemplateType"),
    },
    {
      header: translate("template.generatedBy"),
      fieldname: nameOf("changedBy"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("template.generatedAt"),
      fieldname: nameOf("changedAt"),
      sortable: true,
      includeInGlobalFilter: false,
      pipeDescription: new PipeDescription(AppDateTimePipe, { dateStyle: "short", timeStyle: "short" }),
    },
    {
      header: translate("status.title"),
      fieldname: nameOf("status"),
      sortable: false,
      includeInGlobalFilter: false,
      icon: (x: DocumentTemplateForListFragment): string => DocumentTemplateStatusIcons[x.status],
      iconClick: (x: DocumentTemplateForTable, e: Event): void => this.showWarning(x, e),
      canIconClick: (x: DocumentTemplateForListFragment): boolean =>
        [
          DocumentTemplateStatus.Warning,
          DocumentTemplateStatus.NotAvailable,
          DocumentTemplateStatus.Error,
          DocumentTemplateStatus.Deprecated,
        ].includes(x.status),
    },
  ];

  readonly collectionColumns: TableColumn[] = [
    {
      header: translate("number.title"),
      fieldname: nameOf("candidateDisplayId"),
      sortable: true,
      includeInGlobalFilter: true,
      routeLink: (x: DocumentTemplateForListFragment): any[] => [
        "/app/candidates/edit",
        x.candidateOrganizationId,
        x.candidateId,
      ],
      includeFlag: true,
    },
    {
      header: translate("candidate.title"),
      fieldname: nameOf("candidateDisplayName"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    ...this.candidateColumns,
  ];

  @Input()
  get templates(): DocumentTemplateForListFragment[] {
    return this.enhancedTemplates;
  }

  set templates(templates: DocumentTemplateForListFragment[]) {
    this.enhancedTemplates = templates?.map((template) => ({
      ...template,
      localizedName:
        template.type === DocumentTemplateType.Pdf
          ? translate(`enum.KnownPdfFormTokens.${template.name}`)
          : template.type === DocumentTemplateType.Json
            ? translate(`enum.KnownJsonTemplateTokens.${template.name}`)
            : template.name,
    }));
  }

  @Input()
  candidateId: string;

  @Input()
  organizationId: string;

  @Input()
  collectionId: string;

  @Input()
  collectionOrganizationId: string;

  @Input()
  mode: "candidate" | "collection";

  @Input()
  readonly: boolean;

  @Output()
  readonly reload = new EventEmitter<AvailableDocumentTemplate[]>();

  @ViewChild("warningPanel")
  warningPanel: OverlayPanel;

  availableTemplates: { label: string; value: AvailableDocumentTemplate }[];
  selectedTemplates: AvailableDocumentTemplate[];
  enhancedTemplates: DocumentTemplateForTable[];
  selectedWarnings: DocumentTemplateWarning[];
  selectedTemplate: DocumentTemplateForPreview;
  groupSelect: SelectItemGroup[];
  immigrationCountries: StaticDataModel[];
  isFullScreen = false;

  protected templatesOf = this.transloco.translate("templates.templatesOf", {
    organizationName: this.settings.organizationName,
  });

  constructor(
    private readonly templateService: DocumentTemplatesService,
    private readonly messageService: MessageService,
    private readonly documentService: DocumentsService,
    private readonly downloadService: DownloadService,
    private readonly uploadService: FileUploadService,
    private readonly dialogService: DialogService,
    private readonly confirmationService: ConfirmationService,
    private readonly errorService: MessageDialogService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly staticData: StaticDataService,
    private readonly settings: SettingsService,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.uploadService.onUpload.subscribe(() => this.onUpload());
    this.uploadService.onError.subscribe((x) => this.onError(x));
    this.isFullScreen = false;
  }

  private loadAvailableTemplates(): void {
    forkJoin([
      this.staticData.getStaticData(StaticDataType.SupportedImmigrationCountries),
      this.templateService.getAvailableTemplates(
        this.settings.organizationId,
        this.candidateId,
        this.organizationId,
        this.collectionId
      ),
    ]).subscribe(([immigrationCountries, xs]) => {
      if (xs.length === 1) {
        this.availableTemplates = xs[0].templates.map((x) => ({
          label:
            x.type === DocumentTemplateType.Pdf
              ? translate(`enum.KnownPdfFormTokens.${x.name}`)
              : x.type === DocumentTemplateType.Json
                ? translate(`enum.KnownJsonTemplateTokens.${x.name}`)
                : x.name,
          value: x,
        }));
        this.groupSelect = null;
      } else {
        this.immigrationCountries = immigrationCountries;

        const nonPDFSet = new Set<string>();
        this.groupSelect = xs.map((x) => ({
          label: immigrationCountries.find((y) => y.value === x.immigrationCountry).label,
          value: immigrationCountries.find((y) => y.value === x.immigrationCountry).value,
          items: x.templates
            .map((y) => {
              if (y.type === DocumentTemplateType.Pdf) {
                return {
                  label: translate(`enum.KnownPdfFormTokens.${y.name}`),
                  value: y,
                };
              } else {
                nonPDFSet.add(JSON.stringify(y));
                return null;
              }
            })
            .filter((y) => y !== null),
        }));

        const nonPDFArray = Array.from(nonPDFSet).map((jsonString) => JSON.parse(jsonString));
        this.groupSelect.push({
          label: translate("documents.title"),
          items: nonPDFArray.map((y) => ({
            label: y.name,
            value: y,
          })),
        });
        this.availableTemplates = null;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collectionId) {
      this.templatesSelected(null);
      this.loadAvailableTemplates();
    }
    if (changes.candidateId && !changes.collectionId) {
      this.loadAvailableTemplates();
    }
    this.isFullScreen = false;
  }

  templatesSelected(templates: AvailableDocumentTemplate[]): void {
    this.selectedTemplates = templates;
    this.reload.emit(this.selectedTemplates);
  }

  saveEdit(file: File): void {
    this.isBusy = true;
    this.documentService
      .getUploadUrl(
        this.selectedTemplate.blob,
        this.selectedTemplate.candidateOrganizationId,
        this.selectedTemplate.candidateId
      )
      .pipe(
        catchError((error) => {
          this.isBusy = false;
          return throwError(() => error);
        })
      )
      .subscribe(({ url }) => this.uploadService.upload(file, url));
  }

  closeEdit(): void {
    this.selectedTemplate = null;
    this.changeDetector.detectChanges();
  }

  getBlockableElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  private generate(template: DocumentTemplateForTable, event: Event): void {
    if (template.status === DocumentTemplateStatus.Edited) {
      this.confirmationService.confirm({
        target: event.target,
        message: translate("template.confirmGenerate", { name: template.localizedName }),
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        accept: () => this.generateInternal(template),
      });
    } else {
      this.generateInternal(template);
    }
  }

  private generateInternal(template: DocumentTemplateForTable): void {
    this.isBusy = true;
    this.templateService
      .generate(template)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe((x) => {
        if (x.status === DocumentTemplateStatus.Warning) {
          this.dialogService.open(DocumentTemplateWarningsDialogComponent, {
            header: translate(`warning.title`),
            data: { warnings: x.warnings },
          });
        } else if (
          x.status === DocumentTemplateStatus.Error ||
          x.status === DocumentTemplateStatus.NotAvailable ||
          x.status === DocumentTemplateStatus.Deprecated
        ) {
          this.dialogService.open(DocumentTemplateWarningsDialogComponent, {
            header: x.status !== DocumentTemplateStatus.Deprecated ? translate(`error.title`) : "",
            data: { errors: x.warnings },
          });
        } else {
          this.messageService.add({
            severity: "success",
            summary: translate("template.generated.title"),
            detail: translate("template.generated.message", { name: template.localizedName }),
          });
          if (x.status === DocumentTemplateStatus.InProgress) {
            this.reload.emit(this.selectedTemplates);
          }
        }
      });
  }

  private download(template: DocumentTemplateForTable, params?: unknown): void {
    this.isBusy = true;
    this.getTemplateWithUrl(template, params)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe(({ template, url }) => {
        this.downloadService.downloadFile(template.fileName, url);
      });
  }

  private generateAll(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("template.confirmGenerateAll"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.isBusy = true;
        const obs =
          this.mode === "candidate"
            ? this.templateService.generateAllForCandidate(
                this.candidateId,
                this.organizationId,
                this.settings.organizationId,
                this.collectionId,
                this.collectionOrganizationId
              )
            : this.templateService.generateAllForCollection(
                this.collectionId,
                this.organizationId,
                this.selectedTemplates
              );
        obs.pipe(finalize(() => (this.isBusy = false))).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary: translate("template.generatedAll.title"),
          });
          this.reload.emit(this.selectedTemplates);
        });
      },
    });
  }

  private downloadAll(inAllFormats: boolean): void {
    this.isBusy = true;
    const obs =
      this.mode === "candidate"
        ? this.templateService.downloadAllForCandidate(
            this.candidateId,
            this.organizationId,
            this.settings.organizationId,
            inAllFormats
          )
        : this.templateService.downloadAllForCollection(
            this.collectionId,
            this.organizationId,
            this.selectedTemplates,
            inAllFormats
          );
    obs
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe(({ name, url }) => this.downloadService.downloadFile(name, url));
  }

  private edit(xtemplate: DocumentTemplateForTable): void {
    this.isBusy = true;
    this.getTemplateWithUrl(xtemplate, xtemplate.type === DocumentTemplateType.Docx ? { type: "pdf" } : undefined)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe(
        ({ template, url }) =>
          (this.selectedTemplate = { ...template, url: url, localizedName: xtemplate.localizedName })
      );
  }

  private showWarning(template: DocumentTemplateForTable, event: Event): void {
    this.warningPanel.hide();
    this.selectedWarnings = [];
    this.warningPanel.show(event);

    this.templateService.get(template.id, template.type, template.organizationId).subscribe((x) => {
      this.selectedWarnings = x.warnings;
      this.changeDetector.detectChanges();
      this.warningPanel.align();
    });
  }

  private onUpload(): void {
    this.templateService
      .markAsEdited(this.selectedTemplate)
      .pipe(
        catchError((error) => {
          this.isBusy = false;
          return throwError(() => error);
        })
      )
      .subscribe(() => {
        this.isBusy = false;
        this.messageService.add({
          severity: "success",
          summary: translate("template.saved.title"),
          detail: translate("template.saved.message", { name: this.selectedTemplate.localizedName }),
        });
        this.closeEdit();
      });
  }

  private onError(error: any): void {
    this.errorService.showMessage(translate("file.uploadFailed"), error.message);
  }

  private getTemplateWithUrl(
    template: DocumentTemplateForTable,
    params?: unknown
  ): Observable<{ template: DocumentTemplateFragment; url: string }> {
    return this.templateService
      .get(template.id, template.type, template.organizationId, params)
      .pipe(
        switchMap((x) =>
          this.documentService
            .getDownloadUrl(x.blob, x.candidateOrganizationId, x.candidateId)
            .pipe(map((url) => ({ template: x, url })))
        )
      );
  }
}
