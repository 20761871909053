import { Injectable } from "@angular/core";
import {
  Activity,
  ActivityFilterOption,
  GetActivitiesGQL,
  GetActivityFilterCandidatesGQL,
  GetActivityFilterCollectionsGQL,
  GetActivityFilterOrganizationsGQL,
  GetActivityFilterProcessesGQL,
  GetActivityFilterUsersGQL,
  GetCandidateActivitiesGQL,
  GetNotificationActivitiesGQL,
  GetNotificationActivityCountGQL,
  GetSharingActivitiesGQL,
  NotificationList,
} from "@ankaadia/graphql";
import { TableLazyLoadEvent } from "primeng/table";
import { BehaviorSubject, Observable, map, take, tap } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { CustomLazyLoadEvent } from "../collections/collection-edit-assigned-candidates/custom-filter.model";

@Injectable({ providedIn: "root" })
export class ActivityService {
  private readonly notificationCounter$ = new BehaviorSubject<number>(null);
  readonly notificationCount = this.notificationCounter$.asObservable();

  constructor(
    private readonly settings: SettingsService,
    private readonly activitiesGet: GetActivitiesGQL,
    private readonly notificationsGet: GetNotificationActivitiesGQL,
    private readonly notificationCountGet: GetNotificationActivityCountGQL,
    private readonly organizations: GetActivityFilterOrganizationsGQL,
    private readonly users: GetActivityFilterUsersGQL,
    private readonly collections: GetActivityFilterCollectionsGQL,
    private readonly candidates: GetActivityFilterCandidatesGQL,
    private readonly processes: GetActivityFilterProcessesGQL,
    private readonly candidateActivities: GetCandidateActivitiesGQL,
    private readonly sharingActivities: GetSharingActivitiesGQL
  ) {}

  getAll(event: CustomLazyLoadEvent): Observable<Activity[]> {
    return this.activitiesGet
      .fetch({
        input: {
          organizationId: this.settings.organizationId,
          first: event.first,
          rows: event.rows,
          filters: event.filters,
          sortField: event.sortField as string,
          sortOrder: event.sortOrder,
        },
      })
      .pipe(map((x) => x.data.getActivities));
  }

  getNotifications(): Observable<NotificationList> {
    return this.notificationsGet
      .fetch(null, { fetchPolicy: "network-only" })
      .pipe(map((x) => x.data.getNotificationActivities));
  }

  updateNotificationCount(): void {
    this.notificationCountGet
      .fetch()
      .pipe(
        take(1),
        tap((x) => this.notificationCounter$.next(x.data.getNotificationActivityCount))
      )
      .subscribe();
  }

  decrementNotificationCount(): void {
    this.notificationCounter$.next(this.notificationCounter$.value - 1);
  }

  resetNotificationCount(): void {
    this.notificationCounter$.next(0);
  }

  getActivityFilterOrganizations(): Observable<ActivityFilterOption[]> {
    return this.organizations
      .fetch({ input: { organizationId: this.settings.organizationId } })
      .pipe(map((x) => x.data.getActivityFilterOrganizations));
  }

  getActivityFilterUsers(organizationIds: string[]): Observable<ActivityFilterOption[]> {
    return this.users
      .fetch({ input: { organizationId: this.settings.organizationId, selectedOrganizationIds: organizationIds } })
      .pipe(map((x) => x.data.getActivityFilterUsers));
  }

  getActivityFilterCollections(organizationIds: string[]): Observable<ActivityFilterOption[]> {
    return this.collections
      .fetch({ input: { organizationId: this.settings.organizationId, selectedOrganizationIds: organizationIds } })
      .pipe(map((x) => x.data.getActivityFilterCollections));
  }

  getActivityFilterCandidates(organizationIds: string[]): Observable<ActivityFilterOption[]> {
    return this.candidates
      .fetch({ input: { organizationId: this.settings.organizationId, selectedOrganizationIds: organizationIds } })
      .pipe(map((x) => x.data.getActivityFilterCandidates));
  }

  getActivityFilterProcesses(organizationIds: string[]): Observable<ActivityFilterOption[]> {
    return this.processes
      .fetch({ input: { organizationId: this.settings.organizationId, selectedOrganizationIds: organizationIds } })
      .pipe(map((x) => x.data.getActivityFilterProcesses));
  }

  getCandidateActivities(candidateId: string, event: CustomLazyLoadEvent): Observable<Activity[]> {
    return this.candidateActivities
      .fetch({
        input: {
          organizationId: this.settings.organizationId,
          candidateId: candidateId,
          first: event.first,
          rows: event.rows,
          filters: event.filters,
          sortField: event.sortField as string,
          sortOrder: event.sortOrder,
        },
      })
      .pipe(map((x) => x.data.getCandidateActivities));
  }

  getSharingActivities(sharingId: string, event: TableLazyLoadEvent): Observable<Activity[]> {
    return this.sharingActivities
      .fetch({
        input: {
          organizationId: this.settings.organizationId,
          sharingId: sharingId,
          first: event.first,
          rows: event.rows,
          filters: event.filters,
          sortField: event.sortField as string,
          sortOrder: event.sortOrder,
        },
      })
      .pipe(map((x) => x.data.getSharingActivities));
  }
}
