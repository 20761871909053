import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DirectivesModule } from "../directives/directives.module";
import { FlagModule } from "../flag/flag.module";
import { PipesModule } from "../pipes/pipes.module";
import { PrimeNGModule } from "../primeng/primeng.module";
import { TranslocoModule } from "../transloco/transloco.module";
import { TableSortablePipe } from "./table-sortable/table-sortable.pipe";
import { TableTagComponent } from "./table-tag/table-tag.component";
import { TableComponent } from "./table.component";

@NgModule({
  imports: [CommonModule, PrimeNGModule, TranslocoModule, FlagModule, PipesModule, DirectivesModule],
  declarations: [TableComponent, TableTagComponent, TableSortablePipe],
  exports: [TableComponent, TableSortablePipe],
})
export class TableModule {}
