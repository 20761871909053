import { CandidateForViewFragment, StaticDataType } from "@ankaadia/graphql";

export interface ICountrySpecificRequiredDocumentsFields {
  name: string;
  labelKey: string;
  staticDataType: StaticDataType;
}

export interface ICandidatePathOfRecognition {
  current?: {
    recognitionNoticeKind?: string;
    recognitionReceived?: boolean;
    recognitionReceiveDate?: Date;
    recognitionStarted?: boolean;
    recognitionStartDate?: Date;
  };
  alternative?: {
    recognitionNoticeKind?: string;
    recognitionReceived?: boolean;
    recognitionReceiveDate?: Date;
    recognitionStarted?: boolean;
    recognitionStartDate?: Date;
  };
}

export interface ICandidateQualificationMeasure {
  qualificationMeasure: string;
  careSituations: number;
  theoryHours?: number;
  practiceHours?: number;
}

export interface MigrationSpecifics {
  getMigrationCountrySpecificRequiredDocumentsFields: () => ICountrySpecificRequiredDocumentsFields[];

  getRecognitionPath: (candidate: CandidateForViewFragment) => ICandidatePathOfRecognition;

  getQualificationMeasure: (candidate: CandidateForViewFragment) => ICandidateQualificationMeasure;
}
