import { Injectable } from "@angular/core";
import { Document } from "@ankaadia/graphql";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class DocumentNotificationService {
  updates$ = new Subject<Document>();
  deletions$ = new Subject<Document>();
  reloads$ = new Subject<void>();

  onReloadRequested(): void {
    this.reloads$.next();
  }

  onUpdate(document: Document): void {
    this.updates$.next(document);
  }

  onDelete(document: Document): void {
    this.deletions$.next(document);
  }
}
