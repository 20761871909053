export const ProfessionMap: Record<string, string[]> = {
  "1": [
    "1491",
    "1450",
    "1463",
    "1466",
    "1300",
    "517",
    "1740",
    "1737",
    "1743",
    "1749",
    "1746",
    "1752",
    "1755",
    "1758",
    "577",
    "1400",
    "1272",
    "599",
    "600",
    "1318",
    "1304",
    "75",
    "112",
    "624",
    "117",
    "627",
    "119",
    "629",
    "143",
    "633",
    "1327",
    "531",
    "674",
    "386",
    "217",
    "685",
    "709",
    "269",
    "712",
    "811",
    "1560",
    "734",
    "285",
    "514",
    "1823",
    "1505",
    "752",
    "332",
    "333",
    "753",
    "137",
    "717",
    "1307",
    "1761",
    "1764",
    "1306",
    "1295",
    "1303",
  ],
  "2": [
    "1498",
    "1493",
    "1470",
    "1347",
    "743",
    "571",
    "512",
    "452",
    "507",
    "526",
    "2",
    "6",
    "1773",
    "11",
    "451",
    "18",
    "1343",
    "529",
    "20",
    "21",
    "25",
    "1533",
    "29",
    "1886",
    "33",
    "590",
    "591",
    "34",
    "35",
    "37",
    "592",
    "39",
    "454",
    "42",
    "1873",
    "46",
    "455",
    "1320",
    "1321",
    "1322",
    "1621",
    "1324",
    "49",
    "60",
    "403",
    "67",
    "61",
    "62",
    "63",
    "1860",
    "64",
    "825",
    "1866",
    "1863",
    "404",
    "1359",
    "1360",
    "1315",
    "78",
    "84",
    "1249",
    "85",
    "799",
    "86",
    "1906",
    "760",
    "99",
    "1728",
    "101",
    "100",
    "104",
    "622",
    "105",
    "106",
    "457",
    "107",
    "623",
    "109",
    "1650",
    "114",
    "115",
    "458",
    "826",
    "118",
    "120",
    "630",
    "632",
    "1637",
    "1639",
    "1641",
    "1332",
    "1296",
    "1625",
    "1626",
    "1634",
    "1632",
    "1629",
    "1628",
    "1630",
    "1633",
    "151",
    "153",
    "462",
    "156",
    "157",
    "638",
    "166",
    "169",
    "175",
    "178",
    "180",
    "181",
    "182",
    "650",
    "651",
    "654",
    "655",
    "656",
    "657",
    "658",
    "659",
    "661",
    "662",
    "663",
    "664",
    "1391",
    "186",
    "389",
    "534",
    "184",
    "402",
    "192",
    "209",
    "210",
    "465",
    "676",
    "211",
    "677",
    "568",
    "213",
    "407",
    "679",
    "680",
    "682",
    "215",
    "683",
    "216",
    "1227",
    "467",
    "684",
    "1402",
    "219",
    "220",
    "224",
    "1265",
    "227",
    "688",
    "1651",
    "232",
    "487",
    "234",
    "236",
    "1622",
    "239",
    "240",
    "241",
    "243",
    "702",
    "244",
    "470",
    "248",
    "249",
    "705",
    "253",
    "706",
    "707",
    "708",
    "256",
    "258",
    "262",
    "473",
    "263",
    "264",
    "267",
    "273",
    "274",
    "1652",
    "476",
    "596",
    "275",
    "277",
    "278",
    "279",
    "280",
    "730",
    "728",
    "729",
    "1660",
    "736",
    "289",
    "292",
    "814",
    "297",
    "739",
    "299",
    "740",
    "300",
    "478",
    "824",
    "480",
    "312",
    "1226",
    "518",
    "1797",
    "1454",
    "1455",
    "1457",
    "1850",
    "1459",
    "1460",
    "1461",
    "1462",
    "1847",
    "1464",
    "1465",
    "1469",
    "1473",
    "1476",
    "1477",
    "1558",
    "1478",
    "1479",
    "1480",
    "1482",
    "1483",
    "1484",
    "1485",
    "1488",
    "1492",
    "1495",
    "1496",
    "1497",
    "1500",
    "1841",
    "1507",
    "1838",
    "321",
    "322",
    "323",
    "324",
    "1770",
    "1252",
    "1253",
    "326",
    "750",
    "1298",
    "329",
    "334",
    "483",
    "337",
    "724",
    "339",
    "340",
    "123",
    "341",
    "122",
    "1687",
    "1535",
    "128",
    "486",
    "819",
    "586",
    "1250",
    "136",
    "1305",
    "344",
    "820",
    "490",
    "1229",
  ],
  "3": [
    "1348",
    "1345",
    "15",
    "576",
    "3",
    "4",
    "378",
    "5",
    "8",
    "1312",
    "16",
    "17",
    "19",
    "583",
    "24",
    "30",
    "32",
    "36",
    "453",
    "47",
    "456",
    "68",
    "598",
    "607",
    "76",
    "80",
    "81",
    "87",
    "89",
    "92",
    "93",
    "102",
    "108",
    "116",
    "626",
    "1311",
    "146",
    "565",
    "149",
    "460",
    "155",
    "461",
    "158",
    "164",
    "165",
    "797",
    "176",
    "652",
    "466",
    "188",
    "400",
    "191",
    "1671",
    "208",
    "464",
    "468",
    "222",
    "937",
    "469",
    "229",
    "233",
    "696",
    "697",
    "259",
    "471",
    "265",
    "475",
    "726",
    "287",
    "288",
    "735",
    "308",
    "396",
    "315",
    "481",
    "317",
    "482",
    "1452",
    "1453",
    "1467",
    "1472",
    "1475",
    "1832",
    "1504",
    "330",
    "336",
    "126",
    "130",
    "485",
    "131",
    "796",
    "718",
    "345",
    "488",
  ],
  "4": [
    "185",
    "183",
    "1514",
    "1600",
    "14",
    "1446",
    "1673",
    "495",
    "28",
    "1350",
    "1672",
    "1674",
    "43",
    "44",
    "45",
    "530",
    "1353",
    "55",
    "74",
    "759",
    "1537",
    "500",
    "1515",
    "1516",
    "1517",
    "649",
    "660",
    "1392",
    "665",
    "493",
    "1256",
    "667",
    "668",
    "669",
    "670",
    "1703",
    "1706",
    "1649",
    "511",
    "499",
    "1408",
    "228",
    "1867",
    "1876",
    "1608",
    "1668",
    "1614",
    "250",
    "401",
    "1612",
    "1663",
    "1615",
    "270",
    "1424",
    "504",
    "272",
    "1310",
    "276",
    "1653",
    "296",
    "477",
    "1456",
    "1458",
    "1468",
    "1474",
    "1486",
    "1502",
    "1835",
    "1506",
    "327",
    "1654",
    "1444",
    "1540",
    "135",
    "714",
    "1527",
    "1523",
  ],
  "5": [
    "1494",
    "1688",
    "13",
    "581",
    "22",
    "1809",
    "524",
    "27",
    "1900",
    "1356",
    "59",
    "1909",
    "72",
    "1313",
    "79",
    "1049",
    "82",
    "83",
    "88",
    "94",
    "96",
    "801",
    "1235",
    "800",
    "791",
    "789",
    "790",
    "628",
    "144",
    "634",
    "1379",
    "161",
    "523",
    "194",
    "196",
    "203",
    "205",
    "687",
    "221",
    "694",
    "695",
    "699",
    "788",
    "290",
    "291",
    "1785",
    "741",
    "302",
    "303",
    "304",
    "316",
    "1826",
    "1829",
    "1503",
    "328",
    "751",
    "133",
  ],
  "6": [
    "1921",
    "1853",

    "1390",
    "1386",
    "1308",
    "625",
    "648",
    "610",
    "200",
    "1443",
    "9",
    "10",
    "1274",
    "1275",
    "1278",
    "1283",
    "1532",
    "1285",
    "1364",
    "38",
    "54",
    "609",
    "1903",
    "1686",
    "95",
    "97",
    "98",
    "1536",
    "1232",
    "1231",
    "1698",
    "139",
    "1376",
    "1268",
    "641",
    "172",
    "645",
    "646",
    "174",
    "1709",
    "173",
    "197",
    "1561",
    "204",
    "681",
    "255",
    "271",
    "284",
    "733",
    "310",
    "1440",
    "311",
    "746",
    "1657",
    "320",
    "754",
    "335",
    "723",
    "338",
    "125",
    "i7",
  ],
  "7": [
    "1539",
    "1280",
    "1279",
    "1192",
    "1023",
    "1024",
    "1025",
    "1033",
    "1034",
    "1035",
    "1599",
    "1230",
    "12",
    "582",
    "1273",
    "1806",
    "1286",
    "1815",
    "757",
    "497",
    "564",
    "588",
    "594",
    "1675",
    "1042",
    "1377",
    "398",
    "1363",
    "1684",
    "1375",
    "71",
    "70",
    "608",
    "1233",
    "1371",
    "1234",
    "617",
    "618",
    "619",
    "939",
    "1676",
    "631",
    "1060",
    "1712",
    "1715",
    "647",
    "177",
    "187",
    "189",
    "1225",
    "198",
    "199",
    "1397",
    "1643",
    "1648",
    "1647",
    "1645",
    "1254",
    "686",
    "1411",
    "257",
    "409",
    "266",
    "710",
    "268",
    "711",
    "368",
    "282",
    "283",
    "812",
    "732",
    "513",
    "306",
    "817",
    "372",
    "313",
    "1499",
    "748",
    "749",
    "755",
    "925",
    "399",
    "1530",
    "929",
    "719",
    "127",
    "1529",
    "134",
    "715",
    "1538",
    "373",
  ],
  "8": [
    "1885",
    "1724",
    "1721",
    "1718",
    "1580",
    "1582",
    "1583",
    "1584",
    "1585",
    "1586",
    "1587",
    "1588",
    "1589",
    "1448",
    "1449",
    "1211",
    "1184",
    "1185",
    "1186",
    "1187",
    "1188",
    "1189",
    "1190",
    "1191",
    "1167",
    "1050",
    "1052",
    "1053",
    "1054",
    "1056",
    "1104",
    "1105",
    "1106",
    "1108",
    "1109",
    "1112",
    "1127",
    "1130",
    "1131",
    "1132",
    "1133",
    "1134",
    "1014",
    "802",
    "383",
    "505",
    "351",
    "1893",
    "349",
    "412",
    "1683",
    "1340",
    "7",
    "450",
    "601",
    "393",
    "584",
    "506",
    "585",
    "23",
    "1277",
    "1664",
    "352",
    "893",
    "374",
    "397",
    "930",
    "931",
    "1089",
    "933",
    "1090",
    "934",
    "935",
    "1091",
    "1092",
    "413",
    "422",
    "415",
    "540",
    "416",
    "417",
    "541",
    "423",
    "418",
    "424",
    "425",
    "542",
    "543",
    "544",
    "419",
    "553",
    "555",
    "1209",
    "556",
    "1856",
    "557",
    "558",
    "559",
    "560",
    "554",
    "421",
    "438",
    "420",
    "545",
    "358",
    "426",
    "427",
    "429",
    "430",
    "546",
    "431",
    "435",
    "436",
    "437",
    "444",
    "550",
    "439",
    "547",
    "433",
    "434",
    "548",
    "440",
    "549",
    "441",
    "442",
    "551",
    "443",
    "432",
    "1299",
    "1302",
    "1051",
    "1055",
    "615",
    "1135",
    "1212",
    "1213",
    "1566",
    "1214",
    "1565",
    "1563",
    "1576",
    "1137",
    "1138",
    "1139",
    "1140",
    "1141",
    "1568",
    "1567",
    "1577",
    "1158",
    "1216",
    "1142",
    "1143",
    "1144",
    "1569",
    "1145",
    "1146",
    "1147",
    "1148",
    "1149",
    "1570",
    "1219",
    "1220",
    "1573",
    "1151",
    "1152",
    "1153",
    "1154",
    "1155",
    "1574",
    "1224",
    "1156",
    "1575",
    "1157",
    "1159",
    "1370",
    "620",
    "621",
    "448",
    "509",
    "142",
    "459",
    "353",
    "385",
    "354",
    "1677",
    "1383",
    "380",
    "163",
    "1818",
    "1821",
    "355",
    "382",
    "356",
    "1685",
    "1255",
    "1534",
    "532",
    "1870",
    "212",
    "1399",
    "1882",
    "575",
    "357",
    "226",
    "359",
    "361",
    "362",
    "360",
    "242",
    "1782",
    "692",
    "1678",
    "1680",
    "1210",
    "1522",
    "1890",
    "808",
    "474",
    "388",
    "1314",
    "1064",
    "1695",
    "1912",
    "1665",
    "725",
    "1616",
    "364",
    "365",
    "363",
    "1013",
    "498",
    "1731",
    "1545",
    "1086",
    "392",
    "519",
    "1437",
    "377",
    "1439",
    "1658",
    "1551",
    "928",
    "1487",
    "1542",
    "1518",
    "445",
    "331",
    "371",
    "1918",
    "1524",
    "1525",
    "447",
    "342",
    "1447",
    "343",
    "489",
    "i1",
    "i2",
    "i3",
    "i4",
    "i5",
    "i8",
    "i9",
    "i10",
    "i11",
    "i12",
    "i13",
    "i14",
    "i15",
    "i16",
  ],
  "9": [
    "1378",
    "1333",
    "1342",
    "926",
    "771",
    "773",
    "775",
    "689",
    "690",
    "521",
    "110",
    "1592",
    "1591",
    "1597",
    "1552",
    "1596",
    "1598",
    "1519",
    "1601",
    "1339",
    "1271",
    "31",
    "589",
    "40",
    "1593",
    "1319",
    "1620",
    "1602",
    "1932",
    "595",
    "53",
    "56",
    "597",
    "58",
    "1361",
    "1512",
    "69",
    "91",
    "758",
    "1236",
    "145",
    "635",
    "503",
    "1328",
    "1334",
    "1636",
    "1381",
    "1330",
    "1638",
    "1331",
    "1640",
    "1329",
    "1335",
    "1336",
    "150",
    "636",
    "1635",
    "1631",
    "152",
    "637",
    "1380",
    "154",
    "479",
    "159",
    "160",
    "639",
    "162",
    "642",
    "502",
    "167",
    "643",
    "168",
    "644",
    "170",
    "1594",
    "179",
    "391",
    "1604",
    "494",
    "201",
    "193",
    "195",
    "1788",
    "206",
    "1767",
    "673",
    "1251",
    "827",
    "207",
    "675",
    "1603",
    "1595",
    "1606",
    "1607",
    "1555",
    "223",
    "1406",
    "1701",
    "235",
    "238",
    "1669",
    "1670",
    "1556",
    "700",
    "701",
    "245",
    "703",
    "246",
    "704",
    "1679",
    "1419",
    "1667",
    "1666",
    "491",
    "472",
    "260",
    "1662",
    "1521",
    "1617",
    "1661",
    "281",
    "731",
    "1520",
    "1659",
    "1546",
    "1543",
    "1544",
    "1610",
    "1681",
    "293",
    "737",
    "301",
    "305",
    "1609",
    "1549",
    "501",
    "1513",
    "309",
    "1550",
    "1611",
    "1619",
    "1352",
    "1541",
    "1655",
    "1929",
    "124",
    "722",
    "496",
    "484",
    "492",
    "346",
    "713",
  ],
  "11": [
    "1491",
    "1463",
    "1466",
    "1450",
    "517",
    "1740",
    "1737",
    "1743",
    "1749",
    "1746",
    "1752",
    "1755",
    "1758",
    "577",
    "1400",
    "1272",
    "600",
    "1318",
    "1304",
    "75",
    "112",
    "624",
    "119",
    "629",
    "531",
    "674",
    "217",
    "685",
    "709",
    "269",
    "712",
    "811",
    "1560",
    "734",
    "285",
    "514",
    "1823",
    "1505",
    "752",
    "332",
    "333",
    "753",
    "137",
    "717",
    "1307",
    "1761",
    "1306",
    "1303",
  ],
  "12": ["599", "117", "627", "143", "633", "1327", "386", "1764", "1295", "1300"],
  "21": [
    "1498",
    "1470",
    "6",
    "20",
    "21",
    "25",
    "760",
    "104",
    "622",
    "109",
    "1650",
    "153",
    "462",
    "156",
    "157",
    "638",
    "178",
    "180",
    "181",
    "651",
    "220",
    "256",
    "480",
    "312",
    "1455",
    "1457",
    "1477",
    "1497",
    "329",
    "340",
    "341",
    "122",
    "586",
    "1250",
  ],
  "22": [
    "1533",
    "33",
    "590",
    "42",
    "1873",
    "86",
    "101",
    "623",
    "114",
    "166",
    "169",
    "654",
    "677",
    "682",
    "216",
    "232",
    "487",
    "1461",
    "1473",
    "1479",
    "334",
    "483",
    "123",
  ],
  "23": [
    "743",
    "625",
    "648",
    "37",
    "592",
    "1320",
    "1321",
    "1322",
    "1621",
    "120",
    "630",
    "1637",
    "1639",
    "1332",
    "1296",
    "1625",
    "1626",
    "1634",
    "1632",
    "1628",
    "1630",
    "1633",
    "658",
    "659",
    "661",
    "236",
    "1622",
    "239",
    "240",
    "241",
    "263",
    "264",
    "596",
    "814",
    "1459",
    "1464",
    "1492",
  ],
  "24": [
    "39",
    "454",
    "46",
    "455",
    "799",
    "105",
    "106",
    "457",
    "632",
    "151",
    "657",
    "211",
    "243",
    "702",
    "244",
    "470",
    "258",
    "1652",
    "476",
    "824",
    "518",
    "1462",
    "1465",
    "1469",
    "1558",
    "1488",
    "1838",
    "337",
    "724",
    "339",
    "1687",
    "128",
    "136",
    "344",
    "i17",
  ],
  "25": [
    "1347",
    "512",
    "452",
    "100",
    "507",
    "526",
    "529",
    "1886",
    "99",
    "107",
    "118",
    "182",
    "402",
    "192",
    "568",
    "213",
    "407",
    "1227",
    "467",
    "219",
    "224",
    "1265",
    "292",
    "1476",
    "1478",
    "1483",
    "1484",
    "1495",
    "1841",
    "820",
    "490",
    "1229",
  ],
  "26": [
    "1773",
    "60",
    "403",
    "67",
    "61",
    "62",
    "63",
    "1860",
    "64",
    "825",
    "1866",
    "1863",
    "404",
    "1359",
    "1360",
    "826",
    "175",
    "650",
    "656",
    "1391",
    "186",
    "389",
    "184",
    "679",
    "234",
    "248",
    "730",
    "728",
    "1797",
    "1460",
    "1482",
    "1485",
    "1507",
  ],
  "27": ["18", "29", "534", "676", "705", "278", "729", "1660", "322", "323", "324", "1770", "1493"],
  "28": [
    "2",
    "1343",
    "1324",
    "1249",
    "85",
    "1728",
    "1641",
    "1629",
    "662",
    "664",
    "215",
    "683",
    "227",
    "688",
    "1651",
    "253",
    "706",
    "262",
    "473",
    "267",
    "273",
    "274",
    "275",
    "277",
    "279",
    "280",
    "736",
    "289",
    "297",
    "739",
    "299",
    "740",
    "300",
    "478",
    "1454",
    "1496",
    "1500",
    "321",
    "1252",
    "1253",
    "326",
    "750",
    "1298",
  ],
  "29": [
    "571",
    "11",
    "451",
    "591",
    "34",
    "35",
    "49",
    "1315",
    "78",
    "84",
    "1906",
    "115",
    "458",
    "655",
    "663",
    "209",
    "210",
    "465",
    "680",
    "684",
    "1402",
    "249",
    "707",
    "708",
    "1226",
    "1850",
    "1847",
    "1480",
    "1535",
    "486",
    "819",
    "1305",
  ],
  "31": ["378", "1312", "89", "93", "146", "1671", "937", "396", "1452", "1453", "1504", "126"],
  "32": [
    "5",
    "17",
    "24",
    "36",
    "453",
    "47",
    "456",
    "102",
    "108",
    "565",
    "149",
    "460",
    "158",
    "164",
    "191",
    "469",
    "229",
    "726",
    "308",
    "315",
    "481",
    "330",
    "131",
    "796",
  ],
  "33": [
    "1345",
    "8",
    "16",
    "30",
    "32",
    "68",
    "598",
    "607",
    "80",
    "116",
    "626",
    "155",
    "461",
    "165",
    "797",
    "176",
    "652",
    "188",
    "468",
    "222",
    "265",
    "475",
    "288",
    "735",
    "317",
    "482",
    "336",
    "130",
    "485",
    "345",
    "488",
  ],
  "34": [
    "1348",
    "15",
    "576",
    "3",
    "4",
    "19",
    "583",
    "76",
    "81",
    "87",
    "92",
    "1311",
    "466",
    "400",
    "208",
    "464",
    "233",
    "696",
    "697",
    "259",
    "471",
    "287",
    "1467",
    "1472",
    "1475",
    "1832",
    "718",
  ],
  "41": [
    "1514",
    "1600",
    "14",
    "1673",
    "495",
    "28",
    "1350",
    "1672",
    "1674",
    "43",
    "44",
    "45",
    "530",
    "1353",
    "55",
    "759",
    "649",
    "660",
    "511",
    "499",
    "1867",
    "1876",
    "1608",
    "1614",
    "250",
    "401",
    "1612",
    "270",
    "1424",
    "504",
    "272",
    "1310",
    "276",
    "1653",
    "1456",
    "1458",
    "1506",
    "327",
    "1654",
    "135",
    "1523",
  ],
  "42": [
    "1537",
    "500",
    "1515",
    "1516",
    "1517",
    "1668",
    "1663",
    "1615",
    "296",
    "477",
    "1468",
    "1502",
    "1835",
    "1444",
    "1540",
  ],
  "43": [
    "183",
    "185",
    "1446",
    "74",
    "1392",
    "665",
    "493",
    "1256",
    "667",
    "668",
    "669",
    "670",
    "1703",
    "1706",
    "1649",
    "1408",
    "228",
    "1474",
    "1486",
    "714",
    "1527",
  ],
  "51": [
    "1809",
    "59",
    "1909",
    "79",
    "82",
    "83",
    "96",
    "801",
    "1235",
    "800",
    "791",
    "789",
    "790",
    "194",
    "196",
    "203",
    "205",
    "687",
    "221",
    "695",
    "291",
    "1785",
    "303",
    "316",
    "1829",
    "1503",
  ],
  "52": ["13", "581", "22", "27", "1900", "94", "628", "161", "788", "741", "302", "1826"],
  "53": ["1688", "524", "1356", "72", "1313", "1049", "88", "1379", "523", "694", "699", "290", "304", "133"],
  "54": ["144", "634", "328", "751", "1494"],
  "61": [
    "9",
    "1274",
    "1283",
    "1532",
    "609",
    "1536",
    "1232",
    "1698",
    "641",
    "646",
    "174",
    "1709",
    "1561",
    "200",
    "1921",
    "i7",
  ],
  "62": ["10", "38", "54", "98", "1231", "139", "204", "681", "255", "271", "320", "125", "1390", "1853"],
  "63": [
    "1386",
    "1308",
    "610",
    "1443",
    "1275",
    "1278",
    "1285",
    "1364",
    "1903",
    "1686",
    "95",
    "97",
    "1376",
    "1268",
    "172",
    "645",
    "173",
    "197",
    "284",
    "733",
    "310",
    "1440",
    "311",
    "746",
    "1657",
    "754",
    "335",
    "723",
    "338",
  ],
  "71": [
    "1280",
    "1273",
    "1806",
    "1286",
    "1815",
    "757",
    "497",
    "564",
    "1042",
    "1377",
    "398",
    "1363",
    "1684",
    "1375",
    "1233",
    "1234",
    "631",
    "1060",
    "647",
    "177",
    "1225",
    "1397",
    "1643",
    "1648",
    "1647",
    "1645",
    "1254",
    "710",
    "268",
    "711",
    "1499",
    "748",
    "749",
    "755",
    "925",
    "399",
    "1530",
    "134",
    "715",
  ],
  "72": [
    "1279",
    "1230",
    "12",
    "588",
    "594",
    "608",
    "617",
    "618",
    "939",
    "1712",
    "1715",
    "187",
    "198",
    "686",
    "513",
    "372",
    "313",
    "929",
    "719",
    "373",
  ],
  "73": [
    "1539",
    "1192",
    "1023",
    "1024",
    "1025",
    "1033",
    "1034",
    "1035",
    "1599",
    "582",
    "1675",
    "71",
    "70",
    "1371",
    "619",
    "1676",
    "189",
    "199",
    "1411",
    "257",
    "409",
    "266",
    "368",
    "282",
    "283",
    "812",
    "732",
    "306",
    "817",
    "127",
    "1529",
    "1538",
  ],
  "81": [
    "1885",
    "1724",
    "1580",
    "1582",
    "1583",
    "1584",
    "1585",
    "1586",
    "1587",
    "1588",
    "1589",
    "1448",
    "1449",
    "1184",
    "1185",
    "1186",
    "1187",
    "1188",
    "1189",
    "1190",
    "1191",
    "1050",
    "1052",
    "1053",
    "1054",
    "1056",
    "1104",
    "1105",
    "1106",
    "1108",
    "1109",
    "1112",
    "1127",
    "1130",
    "1131",
    "1132",
    "1133",
    "1134",
    "802",
    "1893",
    "349",
    "412",
    "1340",
    "506",
    "352",
    "374",
    "930",
    "931",
    "1089",
    "933",
    "1090",
    "934",
    "935",
    "1091",
    "1092",
    "413",
    "422",
    "415",
    "540",
    "416",
    "417",
    "541",
    "423",
    "418",
    "424",
    "425",
    "542",
    "543",
    "544",
    "419",
    "553",
    "555",
    "1209",
    "556",
    "1856",
    "557",
    "558",
    "559",
    "560",
    "554",
    "421",
    "438",
    "420",
    "545",
    "358",
    "426",
    "427",
    "429",
    "430",
    "546",
    "431",
    "435",
    "436",
    "437",
    "444",
    "550",
    "439",
    "547",
    "433",
    "434",
    "548",
    "440",
    "549",
    "441",
    "442",
    "551",
    "443",
    "432",
    "1051",
    "1135",
    "1212",
    "1213",
    "1566",
    "1214",
    "1565",
    "1563",
    "1576",
    "1137",
    "1138",
    "1139",
    "1140",
    "1141",
    "1568",
    "1567",
    "1577",
    "1158",
    "1216",
    "1142",
    "1143",
    "1144",
    "1569",
    "1145",
    "1146",
    "1147",
    "1148",
    "1149",
    "1570",
    "1219",
    "1220",
    "1573",
    "1151",
    "1152",
    "1153",
    "1154",
    "1155",
    "1574",
    "1224",
    "1156",
    "1575",
    "1157",
    "1159",
    "1370",
    "620",
    "621",
    "448",
    "353",
    "385",
    "354",
    "355",
    "1870",
    "357",
    "359",
    "361",
    "362",
    "360",
    "242",
    "1678",
    "1680",
    "1210",
    "1890",
    "388",
    "1314",
    "1064",
    "1695",
    "1665",
    "725",
    "1616",
    "364",
    "365",
    "363",
    "498",
    "1731",
    "1086",
    "1658",
    "445",
    "331",
    "371",
    "1525",
    "447",
    "342",
    "1447",
    "i1",
    "i2",
    "i3",
    "i8",
    "i9",
    "i10",
    "i11",
    "i12",
    "i13",
    "i14",
    "i15",
    "i16",
  ],
  "82": [
    "1721",
    "1718",
    "1211",
    "1168",
    "1014",
    "383",
    "505",
    "351",
    "1683",
    "7",
    "450",
    "585",
    "23",
    "142",
    "459",
    "1677",
    "1255",
    "1534",
    "212",
    "1399",
    "1882",
    "226",
    "1782",
    "1522",
    "808",
    "474",
    "1912",
    "928",
    "1487",
    "343",
    "489",
  ],
  "83": [
    "1277",
    "893",
    "397",
    "1299",
    "1302",
    "1055",
    "615",
    "380",
    "163",
    "1818",
    "1821",
    "382",
    "356",
    "1685",
    "692",
    "1437",
    "377",
    "1439",
    "1518",
    "1918",
    "i4",
    "i5",
  ],
  "84": [
    "1167",
    "601",
    "393",
    "584",
    "1664",
    "509",
    "1383",
    "532",
    "575",
    "1013",
    "1545",
    "392",
    "519",
    "1551",
    "1542",
    "1524",
  ],
  "91": [
    "1592",
    "1591",
    "1597",
    "1552",
    "1596",
    "1598",
    "1519",
    "1601",
    "1932",
    "1512",
    "69",
    "503",
    "502",
    "1594",
    "1604",
    "827",
    "1603",
    "1606",
    "1555",
    "1670",
    "1556",
    "1666",
    "491",
    "1662",
    "1521",
    "1617",
    "1661",
    "1520",
    "1546",
    "1544",
    "1609",
    "1549",
    "501",
    "1513",
    "1550",
    "1611",
    "1541",
    "1929",
    "496",
    "492",
  ],
  "92": [
    "1378",
    "926",
    "1271",
    "1236",
    "494",
    "201",
    "193",
    "195",
    "1788",
    "1406",
    "1701",
    "238",
    "1669",
    "301",
    "110",
    "689",
    "690",
    "771",
    "773",
    "775",
  ],
  "93": [
    "1342",
    "1333",
    "31",
    "589",
    "1319",
    "1620",
    "595",
    "53",
    "56",
    "597",
    "58",
    "145",
    "635",
    "1328",
    "1334",
    "1381",
    "1330",
    "1638",
    "1331",
    "1640",
    "1329",
    "1335",
    "1336",
    "150",
    "636",
    "1635",
    "1631",
    "152",
    "637",
    "1380",
    "154",
    "479",
    "159",
    "160",
    "639",
    "162",
    "642",
    "167",
    "643",
    "168",
    "644",
    "170",
    "179",
    "391",
    "206",
    "1767",
    "673",
    "1251",
    "207",
    "675",
    "223",
    "245",
    "703",
    "246",
    "704",
    "472",
    "260",
    "281",
    "731",
    "1543",
    "293",
    "737",
    "305",
    "309",
    "124",
    "722",
    "484",
    "346",
    "713",
  ],
  "94": [
    "521",
    "1339",
    "40",
    "1593",
    "1602",
    "1361",
    "91",
    "758",
    "1636",
    "1595",
    "1607",
    "235",
    "700",
    "701",
    "1679",
    "1419",
    "1667",
    "1659",
    "1610",
    "1681",
    "1619",
    "1352",
    "1655",
  ],
  "999": ["9999"],
  "9999": ["9999"],
};

export const ProfessionAreaMap: Record<string, string[]> = {
  "1": ["11", "12"],
  "2": ["21", "22", "23", "24", "25", "26", "27", "28", "29"],
  "3": ["31", "32", "33", "34"],
  "4": ["41", "42", "43"],
  "5": ["51", "52", "53", "54"],
  "6": ["61", "62", "63"],
  "7": ["71", "72", "73"],
  "8": ["81", "82", "83", "84"],
  "9": ["91", "92", "93", "94"],
  "999": ["9999"],
};

export const MedHealthArea = "81";
export const MedHealthCareCoreProfessions = [
  "1724",
  "1050",
  "1052",
  "1053",
  "1104",
  "1105",
  "1106",
  "1108",
  "1109",
  "1130",
  "1131",
  "1132",
  "1133",
  "1134",
  "1893",
  "412",
  "413",
  "422",
  "415",
  "540",
  "416",
  "417",
  "541",
  "423",
  "418",
  "424",
  "425",
  "542",
  "543",
  "544",
  "419",
  "553",
  "555",
  "1209",
  "556",
  "1856",
  "557",
  "558",
  "559",
  "560",
  "554",
  "421",
  "438",
  "420",
  "545",
  "358",
  "426",
  "427",
  "429",
  "430",
  "546",
  "431",
  "435",
  "436",
  "437",
  "444",
  "550",
  "439",
  "547",
  "433",
  "434",
  "548",
  "440",
  "549",
  "441",
  "442",
  "551",
  "443",
  "432",
  "1051",
  "1370",
  "353",
  "385",
  "354",
  "355",
  "1870",
  "1890",
  "1314",
  "1064",
  "1695",
  "i1",
  "i2",
  "i3",
  "i13",
];

export const doctorProfessions = [
  "412",
  "413",
  "415",
  "416",
  "417",
  "418",
  "419",
  "420",
  "421",
  "422",
  "423",
  "424",
  "425",
  "426",
  "427",
  "429",
  "430",
  "431",
  "432",
  "433",
  "434",
  "435",
  "436",
  "437",
  "438",
  "439",
  "440",
  "441",
  "442",
  "443",
  "444",
  "445",
  "447",
  "448",
  "540",
  "541",
  "542",
  "543",
  "544",
  "545",
  "546",
  "547",
  "548",
  "549",
  "550",
  "551",
  "553",
  "554",
  "555",
  "556",
  "557",
  "558",
  "559",
  "560",
  "1209",
  "1856",
];

export const HealthRegisterProfessions = [
  "354",
  "1890",
  "1314",
  "i13",
  "i14",
  "i15",
  "i16",
  "374",
  "357",
  "388",
  "365",
];

export const RegulatedProfessions = [
  "351",
  "505",
  "507",
  "526",
  "1893",
  "349",
  "378",
  "412",
  "450",
  "451",
  "582",
  "583",
  "529",
  "393",
  "506",
  "524",
  "1886",
  "590",
  "453",
  "454",
  "455",
  "456",
  "1356",
  "352",
  "595",
  "403",
  "404",
  "1951",
  "374",
  "1966",
  "397",
  "598",
  "930",
  "931",
  "1089",
  "933",
  "1090",
  "934",
  "935",
  "1091",
  "1092",
  "413",
  "422",
  "415",
  "540",
  "416",
  "417",
  "541",
  "423",
  "418",
  "424",
  "425",
  "542",
  "543",
  "544",
  "419",
  "553",
  "555",
  "1209",
  "556",
  "1856",
  "557",
  "558",
  "559",
  "560",
  "554",
  "421",
  "438",
  "420",
  "545",
  "358",
  "426",
  "427",
  "429",
  "430",
  "546",
  "431",
  "435",
  "436",
  "437",
  "444",
  "550",
  "439",
  "547",
  "433",
  "434",
  "548",
  "440",
  "549",
  "441",
  "442",
  "551",
  "443",
  "432",
  "1049",
  "1051",
  "1055",
  "758",
  "759",
  "760",
  "1993",
  "1994",
  "1995",
  "1135",
  "1212",
  "1213",
  "1566",
  "1214",
  "1565",
  "1563",
  "1576",
  "1137",
  "1138",
  "1139",
  "1140",
  "1141",
  "1568",
  "1567",
  "1577",
  "1158",
  "1216",
  "1142",
  "1143",
  "1144",
  "1569",
  "1145",
  "1146",
  "1147",
  "1148",
  "1149",
  "1570",
  "1219",
  "1220",
  "1573",
  "1151",
  "1152",
  "1153",
  "1154",
  "1155",
  "1574",
  "1224",
  "1156",
  "1575",
  "1157",
  "1159",
  "620",
  "621",
  "448",
  "509",
  "457",
  "939",
  "458",
  "626",
  "791",
  "789",
  "790",
  "459",
  "1948",
  "460",
  "353",
  "354",
  "385",
  "1379",
  "462",
  "461",
  "638",
  "380",
  "355",
  "382",
  "356",
  "1712",
  "1853",
  "1534",
  "532",
  "531",
  "1715",
  "389",
  "534",
  "523",
  "391",
  "466",
  "400",
  "1969",
  "1870",
  "402",
  "827",
  "464",
  "465",
  "407",
  "467",
  "386",
  "511",
  "575",
  "1960",
  "357",
  "468",
  "937",
  "359",
  "469",
  "487",
  "361",
  "362",
  "360",
  "1970",
  "1867",
  "1933",
  "1942",
  "1936",
  "1945",
  "1876",
  "692",
  "470",
  "1963",
  "1210",
  "471",
  "1890",
  "472",
  "473",
  "474",
  "388",
  "475",
  "409",
  "1314",
  "1064",
  "1695",
  "1912",
  "364",
  "365",
  "788",
  "363",
  "1013",
  "1731",
  "731",
  "368",
  "812",
  "513",
  "1086",
  "735",
  "514",
  "737",
  "477",
  "478",
  "392",
  "519",
  "377",
  "1439",
  "396",
  "480",
  "372",
  "481",
  "482",
  "1954",
  "445",
  "517",
  "483",
  "399",
  "929",
  "719",
  "371",
  "485",
  "586",
  "373",
  "447",
  "489",
  "488",
  "490",
  "1957",
  "1724",
  "1133",
  "i11",
  "i4",
  "i13",
];
