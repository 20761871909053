import { Pipe, PipeTransform } from "@angular/core";
import { DateFormatOptions, Locale, ValidDate } from "@ngneat/transloco-locale";
import { DateFormatterService } from "../services/date-formatter.service";

@Pipe({ name: "appDate" })
export class AppDatePipe implements PipeTransform {
  constructor(private readonly dateFormatterService: DateFormatterService) {}

  transform(
    value: ValidDate,
    options: DateFormatOptions,
    locale?: Locale,
    datePipeOptions = DatePipeOptionsBuilder.default.options
  ): string {
    return this.dateFormatterService.transform(value, options, locale, datePipeOptions);
  }
}

interface IDatePipeOptions {
  get utcDateMode(): boolean;
  get showTimeZone(): boolean;
  get useTime(): boolean;
}

export class DatePipeOptionsBuilder {
  private _options: IDatePipeOptions;

  get options(): IDatePipeOptions {
    return this._options;
  }

  static get default(): DatePipeOptionsBuilder {
    return new DatePipeOptionsBuilder();
  }

  private constructor() {
    this._options = {
      utcDateMode: false,
      showTimeZone: false,
      useTime: false,
    };
  }

  withUtcDateMode(utcDateMode: boolean): DatePipeOptionsBuilder {
    const newOptions = new DatePipeOptionsBuilder();
    newOptions._options = { ...this._options, utcDateMode };
    return newOptions;
  }

  withShowTimeZone(showTimeZone: boolean): DatePipeOptionsBuilder {
    const newOptions = new DatePipeOptionsBuilder();
    newOptions._options = { ...this._options, showTimeZone };
    return newOptions;
  }

  withUseTime(useTime: boolean): DatePipeOptionsBuilder {
    const newOptions = new DatePipeOptionsBuilder();
    newOptions._options = { ...this._options, useTime };
    return newOptions;
  }
}
