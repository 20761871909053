<ng-container *appTranslate="let t">
  <p-dropdown
    [options]="organizations"
    [filter]="switchableOrganizationCount > maxOrganizationsBeforeShowingFilter"
    [filterMatchMode]="$any(filter)"
    filterBy="anything, does not matter. it's just so that Dropdown.visibleOptions is calculated correctlyFF"
    [placeholder]="t('organization.switch')"
    (onClick)="$event.stopPropagation()"
    (onChange)="switched.emit($event.value.id)"
    (onFilter)="filteredSub.next($event.filter)"
    scrollHeight="300px"
    [styleClass]="overlayOnly ? 'overlay-only' : 'compact minimalistic'"
    panelStyleClass="topbar-menu-panel"
  >
    <ng-template pTemplate="item" let-organization>
      <div class="dropdown-item-wrapper flex align-items-center">
        <i class="pi pi-sitemap"></i>

        <div class="flex-auto min-w-0 max-w-full">
          <div class="flex">
            <span class="flex-initial text-overflow-ellipsis overflow-hidden min-w-0 mr-1">
              {{ organization.name }}
            </span>
            <span class="flex-none">({{ organization.code }})</span>
          </div>

          <div
            class="text-xs text-overflow-ellipsis overflow-hidden"
            *ngIf="
              !isCandidate &&
              !organization.isCreatorMasterOrganization &&
              !organization.isLicensed &&
              organization.creatorOrganizationName
            "
          >
            {{ t("organization.providedBy", organization) }}
          </div>
        </div>
      </div>
    </ng-template>
  </p-dropdown>
</ng-container>
