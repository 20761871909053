import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ANYTHING } from "@ankaadia/ankaadia-shared";
import { ProfessionConfiguration, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { cloneDeep, isString, noop } from "lodash";
import { BehaviorSubject, Observable, combineLatest, map, of, switchMap } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { ProfessionConfigurationService } from "../profession-configuration.service";
import { ProfessionService } from "../profession.service";

@Component({
  selector: "app-profession-selector",
  templateUrl: "./profession-selector.component.html",
  styleUrl: "./profession-selector.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ProfessionSelectorComponent, multi: true }],
})
export class ProfessionSelectorComponent implements ControlValueAccessor, OnInit {
  private readonly language = this.transloco.getActiveLang();
  private readonly modelSub = new BehaviorSubject<string | string[]>(null);

  private selectedArea: string;
  private selectedSubArea: string;
  private selectedProfession: string | string[];
  private toggledProfession: string;

  @Input()
  inputId: string;

  @Input()
  multi: boolean;

  @Input()
  placeholder: string;

  @Input()
  disabled: boolean;

  @Input()
  required: boolean;

  @Input()
  all: boolean;

  @Input()
  criteriaMode: boolean;

  @Input()
  candidateOrganizationId: string;

  @Input()
  formControl: FormControl;

  @Input()
  profession: string;

  @Input()
  errorLabel: string;

  @Input()
  showClear = true;

  @Input()
  readonly: boolean;

  @Output()
  readonly changed = new EventEmitter<{ value: string[]; toggledValue: string }>();

  model: string | string[];
  allProfessions: Observable<StaticDataModel[]>;
  areas: Observable<StaticDataModel[]>;
  subAreas: Observable<StaticDataModel[]>;
  professions: Observable<StaticDataModel[]>;
  configProf: Observable<ProfessionConfiguration>;
  onModelChange = noop;
  onModelTouched = noop;

  constructor(
    private readonly settings: SettingsService,
    private readonly transloco: TranslocoService,
    private readonly professionService: ProfessionService,
    private readonly staticDataService: StaticDataService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly professionConfigService: ProfessionConfigurationService
  ) {}

  ngOnInit(): void {
    this.allProfessions = combineLatest([
      this.professionService.getProfessions(
        null,
        null,
        this.language,
        this.candidateOrganizationId ?? this.settings.organizationId,
        this.all
      ),
      this.modelSub,
    ]).pipe(
      map(([xs, model]) => [this.criteriaMode ? [{ label: ANYTHING, value: ANYTHING }, ...xs] : xs, model] as const),
      switchMap(([xs, model]) => this.addMissing(xs, model)),
      switchMap((xs) => this.fetchProfessionsConfig().pipe(map((config) => [xs, config] as const))),
      map(([xs, prof]) => (this.profession ? this.moveToFront(cloneDeep(xs), prof) : xs))
    );

    this.areas = this.professionService.getAreas(
      this.language,
      this.candidateOrganizationId ?? this.settings.organizationId,
      this.all
    );
  }

  writeValue(model: string | string[]): void {
    this.model = model;
    this.modelSub.next(model);
    this.changeDetector.markForCheck();
  }

  registerOnChange(onModelChange: () => void): void {
    this.onModelChange = onModelChange;
  }

  registerOnTouched(onModelTouched: () => void): void {
    this.onModelTouched = onModelTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.changeDetector.markForCheck();
  }

  areaChanged(area: string): void {
    this.selectedArea = area;
    this.subAreas = this.professionService.getSubAreas(
      this.selectedArea,
      this.language,
      this.candidateOrganizationId ?? this.settings.organizationId,
      this.all
    );
    this.subAreaChanged(null);
    this.professionChanged(null, null);
  }

  subAreaChanged(subArea: string): void {
    this.selectedSubArea = subArea;
    this.professions = this.professionService.getProfessions(
      this.selectedArea,
      this.selectedSubArea,
      this.language,
      this.candidateOrganizationId ?? this.settings.organizationId,
      this.all
    );
    this.professionChanged(null, null);
  }

  professionChanged(profession: string | string[], toggledProfession: string): void {
    this.selectedProfession = profession;
    this.toggledProfession = toggledProfession;
  }

  updateModel(): void {
    if (this.selectedProfession) {
      this.model = this.selectedProfession;
      this.onModelChange(this.selectedProfession);
      this.formControl?.setValue(this.selectedProfession);
      if (this.multi) {
        this.changed.emit({ value: <string[]>this.selectedProfession, toggledValue: this.toggledProfession });
      }
    }
  }

  private fetchProfessionsConfig(): Observable<Set<string>> {
    return this.professionConfigService.get().pipe(map((config) => new Set(config?.professions)));
  }

  private moveToFront(professions: StaticDataModel[], configProfessions: Set<string>): StaticDataModel[] {
    this.profession = this.profession?.toString();
    let professionValue: StaticDataModel;
    const sortedArr: StaticDataModel[] = [];
    // vz: I don't know what's going on here, I'm just trying to stop your attempts to modify an array while iterating over it
    const idkWhat: StaticDataModel[] = [];

    professions?.forEach((prof: StaticDataModel) => {
      const elm = configProfessions.has(prof.value);
      if (prof.value === this.profession) {
        professionValue = prof;
      } else if (elm) {
        sortedArr.push(prof);
      } else {
        idkWhat.push(prof);
      }
    });
    return [...(professionValue ? [professionValue] : []), ...sortedArr, ...idkWhat];
  }

  private addMissing(xs: StaticDataModel[], model: string | string[]): Observable<StaticDataModel[]> {
    const missing = (isString(model) ? [model] : model)?.filter((v) => xs.every((x) => x.value !== v));
    if (missing?.length) {
      this.staticDataService
        .getStaticDataLabel(missing, StaticDataType.Profession, this.language)
        .pipe(
          map((ms) =>
            [...ms.map((m, i) => ({ label: m, value: missing[i] })), ...xs].sort((a, b) =>
              a.label?.localeCompare(b.label)
            )
          )
        );
    }
    return of(xs);
  }
}
