import { Directive, Input, OnChanges, OnInit, Optional, SimpleChanges } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({ selector: "p-dropdown" })
export class DropdownPrePopulateSingleOptionDirective implements OnInit, OnChanges {
  @Input()
  options: any[];

  @Input()
  prePopulateSingleOption = false;

  @Input()
  optionValue = "value";

  constructor(@Optional() private readonly control: NgControl) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.setControlValue();
    }
  }

  ngOnInit(): void {
    this.setControlValue();
  }

  private setControlValue(): void {
    if (this.prePopulateSingleOption && this.options?.length === 1 && this.control) {
      this.control.control.setValue(this.options[0][this.optionValue]);
    }
  }
}
