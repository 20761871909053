import { nameofFactory, ShippingInformation } from "@ankaadia/ankaadia-shared";
import { TranslateParams, translate as transloco } from "@jsverse/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";

export interface ShippingInformationContext {}

export function shippingInformationFormFactory(
  _context: ShippingInformationContext,
  language: string
): FormlyFieldConfig[] {
  const nameOf = nameofFactory<ShippingInformation>();
  const translate = <T>(key: TranslateParams, params?: Record<string, any>): T => transloco<T>(key, params, language);
  return [
    {
      className: "block m-1",
      fieldGroup: [
        {
          key: nameOf("date"),
          type: "datepicker",
          props: {
            label: translate("shippingDate.title"),
            showIcon: true,
            inline: false,
            minDate: new Date(1900, 0, 1),
            required: true,
            hideRequiredMarker: true,
            blocklyType: "field_date",
          },
        },
        {
          key: nameOf("trackingNumber"),
          type: "input",
          props: {
            label: translate("trackingNumber.title"),
            placeholder: translate("trackingNumber.placeholder"),
            type: "text",
            maxLength: 1000,
            blocklyType: "String",
          },
        },
      ],
    },
  ];
}
