<div class="flex flex-wrap gap-3">
  <div class="flex align-items-center" *ngFor="let option of props.options | formlySelectOptions: field | async">
    <p-radioButton
      [name]="field.name || id"
      [inputId]="field.name || id"
      [formControl]="$any(option.disabled ? disabledControl : formControl)"
      [value]="option.value"
      [readonly]="props.readonly"
    ></p-radioButton>
    <label for="{{ field.name || id }}" class="ml-2">{{ option.label }}</label>
  </div>
</div>
