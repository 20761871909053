<ng-container [ngSwitch]="type">
  <i *ngSwitchCase="ActivityType.CandidateAdded" class="pi pi-user-edit"></i>
  <i *ngSwitchCase="ActivityType.CandidateUpdated" class="pi pi-user-edit"></i>
  <i *ngSwitchCase="ActivityType.CandidateDeleted" class="pi pi-user-edit"></i>
  <i *ngSwitchCase="ActivityType.CandidateDeletionReminder" class="pi pi-user-edit"></i>
  <i *ngSwitchCase="ActivityType.CandidateRequestDeletion" class="pi pi-user-edit"></i>
  <i *ngSwitchCase="ActivityType.CandidateBirthday" class="pi pi-user-edit"></i>
  <i *ngSwitchCase="ActivityType.CandidateBirthdaySoon" class="pi pi-user-edit"></i>
  <i *ngSwitchCase="ActivityType.FileUploaded" class="pi pi-copy"></i>
  <i *ngSwitchCase="ActivityType.FileModified" class="pi pi-copy"></i>
  <i *ngSwitchCase="ActivityType.DocumentSetMetaDataModified" class="pi pi-copy"></i>
  <i *ngSwitchCase="ActivityType.FileDeleted" class="pi pi-copy"></i>
  <i *ngSwitchCase="ActivityType.FileExpiresSoon" class="pi pi-copy"></i>
  <i *ngSwitchCase="ActivityType.FreeFormatFileUploaded" class="pi pi-copy"></i>
  <i *ngSwitchCase="ActivityType.FreeFormatFileDeleted" class="pi pi-copy"></i>
  <i *ngSwitchCase="ActivityType.CollectionAdded" class="pi pi-list"></i>
  <i *ngSwitchCase="ActivityType.CollectionDeleted" class="pi pi-list"></i>
  <i *ngSwitchCase="ActivityType.CollectionUpdated" class="pi pi-list"></i>
  <i *ngSwitchCase="ActivityType.CandidateAddedToCollection" class="pi pi-list"></i>
  <i *ngSwitchCase="ActivityType.CandidateRemovedFromCollection" class="pi pi-list"></i>
  <i *ngSwitchCase="ActivityType.CollectionEmbeddedIntoCollection" class="pi pi-list"></i>
  <i *ngSwitchCase="ActivityType.CollectionRemovedFromCollection" class="pi pi-list"></i>
  <i *ngSwitchCase="ActivityType.SharingAdded" class="pi pi-list"></i>
  <i *ngSwitchCase="ActivityType.SharingDeleted" class="pi pi-list"></i>
  <i *ngSwitchCase="ActivityType.SharingUpdated" class="pi pi-list"></i>
  <i *ngSwitchCase="ActivityType.SharingEnabled" class="pi pi-list"></i>
  <i *ngSwitchCase="ActivityType.SharingDisabled" class="pi pi-list"></i>
  <i *ngSwitchCase="ActivityType.SharingTypeModified" class="pi pi-list"></i>
  <i *ngSwitchCase="ActivityType.ProcessCreated" class="pi pi-bars"></i>
  <i *ngSwitchCase="ActivityType.ProcessDeleted" class="pi pi-bars"></i>
  <i *ngSwitchCase="ActivityType.TaskManuallyCreated" class="pi pi-briefcase"></i>
  <i *ngSwitchCase="ActivityType.TaskDeleted" class="pi pi-briefcase"></i>
  <i *ngSwitchCase="ActivityType.TaskStarted" class="pi pi-briefcase"></i>
  <i *ngSwitchCase="ActivityType.TaskFinished" class="pi pi-briefcase"></i>
  <i *ngSwitchCase="ActivityType.TaskCancelled" class="pi pi-briefcase"></i>
  <i *ngSwitchCase="ActivityType.TaskFailed" class="pi pi-briefcase"></i>
  <i *ngSwitchCase="ActivityType.TaskReminderSent" class="pi pi-briefcase"></i>
  <i *ngSwitchCase="ActivityType.EmailNotDelivered" class="pi pi-envelope"></i>
  <i *ngSwitchCase="ActivityType.CandidateLoggedIn" class="pi pi-sign-in"></i>
  <i *ngSwitchCase="ActivityType.UserLoggedIn" class="pi pi-sign-in"></i>
  <i *ngSwitchCase="ActivityType.FeedbackProvided" class="pi pi-check-square"></i>
  <i *ngSwitchCase="ActivityType.CandidateDroppedOut" class="pi pi-thumbs-down"></i>
  <i *ngSwitchCase="ActivityType.AutoProcessOperationTriggered" class="pi pi-bolt"></i>
  <i *ngSwitchCase="ActivityType.TaskNoteAdded" class="pi pi-briefcase"></i>
  <i *ngSwitchCase="ActivityType.CandidateOptedOut" class="pi pi-briefcase"></i>
  <i *ngSwitchCase="ActivityType.CandidateOptedIn" class="pi pi-briefcase"></i>
  <i *ngSwitchCase="ActivityType.ProcessModelReleaseCreated" class="pi pi-bars"></i>
  <i *ngSwitchCase="ActivityType.ProcessModelPublished" class="pi pi-bars"></i>
  <i *ngSwitchCase="ActivityType.ProcessModelUnpublished" class="pi pi-bars"></i>
  <i *ngSwitchCase="ActivityType.ProcessModelDeleted" class="pi pi-bars"></i>
  <i *ngSwitchCase="ActivityType.CandidateNotFound" class="pi pi-search"></i>
  <i *ngSwitchCase="ActivityType.CollectionNotFound" class="pi pi-search"></i>
  <i *ngSwitchCase="ActivityType.UserNotFound" class="pi pi-search"></i>
  <i *ngSwitchCase="ActivityType.ProcessNotFound" class="pi pi-search"></i>
  <i *ngSwitchCase="ActivityType.DocumentNotFound" class="pi pi-search"></i>
  <i *ngSwitchCase="ActivityType.OrganizationNotFound" class="pi pi-search"></i>
  <i *ngSwitchCase="ActivityType.TaskNotFound" class="pi pi-search"></i>
  <i *ngSwitchCase="ActivityType.InvalidSharingTemplate" class="pi pi-link"></i>
  <i *ngSwitchCase="ActivityType.InvalidUserGroupRoleMapping" class="pi pi-link"></i>
  <i *ngSwitchCase="ActivityType.OrganizationNotLinked" class="pi pi-link"></i>
  <i *ngSwitchCase="ActivityType.EducationExamCreated" class="pi pi-calculator"></i>
  <i *ngSwitchCase="ActivityType.EducationExamUpdated" class="pi pi-calculator"></i>
  <i *ngSwitchCase="ActivityType.EducationExamDeleted" class="pi pi-calculator"></i>
  <i *ngSwitchCase="ActivityType.CandidateAddedToExam" class="pi pi-calculator"></i>
  <i *ngSwitchCase="ActivityType.CandidateRemovedFromExam" class="pi pi-calculator"></i>
  <i *ngSwitchCase="ActivityType.EducationExamResultCreated" class="pi pi-calculator"></i>
  <i *ngSwitchCase="ActivityType.EducationExamResultDeleted" class="pi pi-calculator"></i>
</ng-container>
