import { Pipe, PipeTransform } from "@angular/core";
import { translate } from "@jsverse/transloco";
import { NumberFormatOptions } from "@jsverse/transloco-locale";
import { AppDecimalPipe } from "./decimal.pipe";

@Pipe({ name: "fileSize" })
export class FileSizePipe implements PipeTransform {
  constructor(private readonly decimalPipe: AppDecimalPipe) {}

  transform(value: string | number, numberFormatOptions?: NumberFormatOptions, language?: string): string {
    const sizeInBytes = Number(value);
    if (sizeInBytes < 1024 * 1024) {
      return `${this.decimalPipe.transform(sizeInBytes / 1024, numberFormatOptions, language)} ${translate("common.unit.kb", null, language)}`;
    }
    return `${this.decimalPipe.transform(sizeInBytes / 1024 / 1024, numberFormatOptions, language)} ${translate("common.unit.mb", null, language)}`;
  }
}
