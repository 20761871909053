import { Component, Input } from "@angular/core";
import { EmailEventType, EmailFragment } from "@ankaadia/graphql";

@Component({
  selector: "app-email-events",
  templateUrl: "./email-events.component.html",
})
export class EmailEventsComponent {
  readonly EmailEventType = EmailEventType;

  @Input()
  email: EmailFragment;

  @Input()
  processLanguage?: string;
}
