<ng-container *transloco="let t">
  <p *ngIf="isWarning">
    {{ t("template.generationWarning") }}
  </p>
  <app-document-template-warnings [warnings]="warningsOrErrors"></app-document-template-warnings>

  <div class="flex justify-content-end mt-4">
    <p-button [label]="t('common.close')" icon="pi pi-times" class="p-button-warning" (onClick)="close()"></p-button>
  </div>
</ng-container>
