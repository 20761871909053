<a
  *ngIf="!item.routerLink || item.items"
  [attr.href]="item.url"
  (click)="itemClick($event)"
  (keydown.enter)="itemClick($event)"
  [attr.target]="item.target"
  [attr.tabindex]="0"
  (mouseenter)="hover = true"
  (mouseleave)="hover = false"
>
  <i [ngClass]="item.icon" class="layout-menuitem-icon pi-fw"></i>
  <span>{{ item.label }}</span>
  <span class="ink" *ngIf="hover"></span>
  <span class="menuitem-badge" *ngIf="item.badge | async as badge">{{ badge }}</span>
  <i class="pi pi-fw pi-angle-down submenu-toggler" *ngIf="item.items"></i>
</a>

<a
  *ngIf="item.routerLink && !item.items"
  (click)="itemClick($event)"
  (mouseenter)="hover = true"
  (mouseleave)="hover = false"
  [routerLink]="item.routerLink"
  routerLinkActive="active-menuitem-routerlink"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions ?? {}"
  [attr.target]="item.target"
  [attr.tabindex]="0"
>
  <i [ngClass]="item.icon" class="layout-menuitem-icon pi-fw"></i>
  <span>{{ item.label }}</span>
  <span class="ink" *ngIf="hover"></span>
  <span class="menuitem-badge" *ngIf="item.badge | async as badge">{{ badge }}</span>
  <i class="pi pi-fw pi-angle-down submenu-toggler" *ngIf="item.items"></i>
</a>

<ul *ngIf="item.items && active" [@children]="active ? 'visibleAnimated' : 'hiddenAnimated'">
  <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
    <li>
      <app-menu-item [item]="child" [index]="i" [parentKey]="key"></app-menu-item>
    </li>
  </ng-template>
</ul>
