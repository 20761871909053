import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { StaticDataModel } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { isEmpty } from "lodash";
import { ConfirmationService } from "primeng/api";
import { MultiSelect } from "primeng/multiselect";
import { v4 as uuidv4 } from "uuid";

export interface SelectedStaticDataItems {
  values: string[];
  overwriteExisting: boolean;
}

@Component({
  selector: "app-static-data-multi-selector",
  templateUrl: "./static-data-multi-selector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticDataMultiSelectorComponent {
  protected readonly dialogKey = uuidv4();
  protected overwriteExisting = false;

  @Input()
  options: StaticDataModel[];

  @Output()
  readonly selected = new EventEmitter<SelectedStaticDataItems>();

  @ViewChild(MultiSelect)
  multiSelect: MultiSelect;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly confirmationService: ConfirmationService
  ) {}

  protected apply(values: string[]): void {
    this.multiSelect.hide();
    if (isEmpty(values)) {
      return;
    }

    const labels = this.options.filter((option) => values.includes(option.value)).map((option) => option.label);
    const label = labels.join(", ");
    const messageKey = this.overwriteExisting
      ? "documents.staticDataSelector.confirmation.messageOverwrite"
      : "documents.staticDataSelector.confirmation.message";

    this.confirmationService.confirm({
      target: this.elementRef.nativeElement,
      message: translate(messageKey, { label }),
      header: translate("documents.staticDataSelector.confirmation.title"),
      icon: "pi pi-exclamation-triangle",
      key: this.dialogKey,
      accept: () => this.selected.emit({ values, overwriteExisting: this.overwriteExisting }),
    });
  }

  show(): void {
    this.multiSelect.updateModel([]);
    this.overwriteExisting = false;
    this.multiSelect.show();
  }
}
