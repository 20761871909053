import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PrimeNGModule } from "../primeng/primeng.module";
import { TranslocoModule } from "../transloco/transloco.module";
import { MessageDialogComponent } from "./message-dialog.component";

@NgModule({
  imports: [CommonModule, PrimeNGModule, TranslocoModule],
  declarations: [MessageDialogComponent],
  exports: [MessageDialogComponent],
})
export class MessageDialogModule {}
