import { Injectable } from "@angular/core";
import { isNil } from "@ngneat/transloco";
import { DateFormatOptions, Locale, TranslocoLocaleService, ValidDate } from "@ngneat/transloco-locale";
import { CalendarUtcFixDirective } from "../primeng/calendar-utc-fix/calendar-utc-fix.directive";
import { DatePipeOptionsBuilder } from "../pipes/date.pipe";

@Injectable({ providedIn: "root" })
export class DateFormatterService {
  protected currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor(private readonly localeService: TranslocoLocaleService) {}

  transform(
    value: ValidDate,
    options: DateFormatOptions,
    locale?: Locale,
    datePipeOptions = DatePipeOptionsBuilder.default.options
  ): string {
    const { utcDateMode, showTimeZone, useTime } = datePipeOptions;
    if (utcDateMode && value instanceof Date) {
      value = CalendarUtcFixDirective.localDateTimeToUTCDateInLocalDateTime(value, useTime) as ValidDate;
    }

    return this.localizeDate(value, options, locale) + (showTimeZone ? ` (${this.currentTimeZone})` : "");
  }

  private localizeDate(date: ValidDate, options: DateFormatOptions = {}, locale?: Locale): string {
    if (isNil(date)) return "";

    locale = locale || this.localeService.getLocale();

    return this.localeService.localizeDate(date, locale, options);
  }
}
