import { DocumentAccessData, DocumentWriteMode, isInternalDocument } from "@ankaadia/ankaadia-shared";
import { DocumentAccess } from "@ankaadia/graphql";

export function getWriteMode(documentAccess: DocumentAccess, document: DocumentAccessData): DocumentWriteMode {
  const throwAccessError = (): DocumentWriteMode => {
    throw new Error("The document cannot be accessed", { cause: documentAccess });
  };

  switch (documentAccess) {
    case DocumentAccess.Full:
      return DocumentWriteMode.Full;
    case DocumentAccess.FullWhenNotInternal:
      return isInternalDocument(document) ? DocumentWriteMode.Metadata : DocumentWriteMode.Full;
    case DocumentAccess.Metadata:
      return DocumentWriteMode.Metadata;
    default:
      return throwAccessError();
  }
}
