import { Directive } from "@angular/core";
import { Dialog } from "primeng/dialog";

@Directive({ selector: "p-dialog" })
export class DialogOptionsDirective {
  constructor(private readonly dialog: Dialog) {
    this.dialog.modal = true;
    this.dialog.style = { width: "50vw" };
    this.dialog.draggable = false;
    this.dialog.resizable = false;
  }
}
