import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { KnownConfigTokens } from "@ankaadia/ankaadia-shared";
import { ClipboardService } from "ngx-clipboard";
import { MessageService } from "../../../features/message/message.service";
import { SettingsService } from "../../../shared/services/settings.service";
import { GuetegemeinschaftService } from "../guetegemeinschaft.service";

@Component({
  selector: "app-guetegemeinschaft-recertification-link",
  templateUrl: "./recertificationLink.component.html",
  styleUrl: "./recertificationLink.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReCertificationLinkComponent implements OnInit {
  showButton = false;

  constructor(
    private readonly gueteService: GuetegemeinschaftService,
    private readonly clipboard: ClipboardService,
    protected readonly settingsService: SettingsService,
    protected readonly messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.showButton = this.settingsService.configToken == KnownConfigTokens.GUETEGEMEINSCHAFT;
  }

  copyToClipboard(): void {
    this.gueteService.getReCertificationLink().subscribe((link) => {
      this.clipboard.copyFromContent(link);
      this.messageService.add({
        severity: "success",
        summary: "Link kopiert",
        detail: "Der Link wurde in die Zwischenablage kopiert",
      });
    });
  }
}
