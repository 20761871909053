import { AbstractControl, FormArray, FormGroup, ValidationErrors } from "@angular/forms";
import { customFieldLabelMapping, ICustomFieldsModel, nameofFactory } from "@ankaadia/ankaadia-shared";
import { translate } from "@jsverse/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { PrimeIcons } from "primeng/api";
import { v4 as uuidv4 } from "uuid";
import { StaticDataType } from "../../_generated/graphql";
import { StaticDataService } from "../../shared/static-data/static-data.service";

const _dummy: ICustomFieldsModel = null;
const path = "os.profile.customFields";
const nameOfDeep = nameofFactory<typeof _dummy.medwing>();
const nameOfProgressTracking = nameofFactory<(typeof _dummy.medwing.languageProgressTracking)[0]>();

export const medwingFactory: (
  language: string,
  staticDataService: StaticDataService,
  requiredFields: string[]
) => FormlyFieldConfig[] = (language: string, staticDataService: StaticDataService, requiredFields: string[]) => {
  return [
    {
      id: "medwing-custom",
      props: {
        label: translate("customFields.languagePerformance"),
        icon: PrimeIcons.USER_EDIT,
      },
      fieldGroupClassName: "p-fluid",
      fieldGroup: [
        {
          id: "moduleTracking",
          fieldGroup: [
            {
              key: nameOfDeep("languageProgressTracking"),
              className: "field col-12",
              type: "repeat",
              props: {
                label: translate("languageTrainingTracking.title"),
                addLabel: translate("languageTrainingTracking.add"),
                defaultValue: () => ({ id: uuidv4() }),
                expressionProperties: {
                  "props.required": () => requiredFields?.includes(`${path}.${nameOfDeep("languageProgressTracking")}`),
                },
              },
              fieldArray: {
                fieldGroupClassName: "p-fluid formgrid grid col",
                fieldGroup: [
                  {
                    id: "moduleLevel",
                    fieldGroupClassName: "p-fluid formgrid grid",
                    className: "field col-12",
                    validators: {
                      validation: [moduleLevelValidator],
                    },
                    fieldGroup: [
                      {
                        type: "input",
                        key: nameOfProgressTracking("id"),
                        props: {
                          type: "hidden",
                        },
                      },

                      {
                        key: nameOfProgressTracking("module"),
                        type: "dropdown",
                        className: "field col-4",
                        props: {
                          label: translate(customFieldLabelMapping.medwing.languageProgressTracking.module),
                          placeholder: translate("placeholder.title"),
                          options: staticDataService.getStaticData(StaticDataType.LanguageModules, language),
                          filter: true,
                          filterBy: "label",
                          required: true,
                          showClear: true,
                          staticDataType: StaticDataType.LanguageModules,
                        },
                        hooks: {
                          afterViewInit: (field): void => {
                            field.formControl.valueChanges.subscribe(() => {
                              const raw = field.form.parent.getRawValue();
                              raw.sort((a, b) => a.module?.localeCompare(b.module));
                              field.form.parent.patchValue(raw, { emitEvent: false });
                              (<FormArray>field.form.parent).controls.forEach((x) => {
                                x.updateValueAndValidity({ emitEvent: true });
                              });
                            });
                          },
                        },
                      },
                      {
                        key: nameOfProgressTracking("presenceRatio"),
                        type: "input",
                        className: "field col-2",
                        props: {
                          type: "number",
                          max: 100,
                          min: 0,
                          step: 1,
                          label: translate(customFieldLabelMapping.medwing.languageProgressTracking.presenceRatio),
                        },
                      },
                      {
                        key: nameOfProgressTracking("comment"),
                        type: "input",
                        className: "field col-6",
                        props: {
                          type: "text",
                          label: translate(customFieldLabelMapping.medwing.languageProgressTracking.comment),
                          maxLength: 200,
                        },
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  ];
};

function moduleLevelValidator(control: AbstractControl): ValidationErrors {
  const formArray = (<FormArray>control?.parent)?.controls;
  const sameModule = formArray?.find(
    (x) =>
      (<FormGroup>x).controls[nameOfProgressTracking("module")]?.value ==
        (<FormGroup>control).controls[nameOfProgressTracking("module")]?.value &&
      (<FormGroup>x).controls[nameOfProgressTracking("module")] !=
        (<FormGroup>control).controls[nameOfProgressTracking("module")]
  );
  if (sameModule) {
    return { moduleLevelValidator: { message: translate("languageTrainingTracking.error.double") } };
  }
  return null;
}
