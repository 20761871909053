import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { filter, switchMap, take } from "rxjs";
import { MyOrganizationService } from "../../features/organizations/my-organization/my-organization.service";
import { OrganizationsService } from "../../features/organizations/organizations.service";
import { SettingsService } from "../../shared/services/settings.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.scss",
})
export class FooterComponent implements OnInit {
  logoLeft?: string;
  logoRight?: string;

  constructor(
    private readonly settings: SettingsService,
    private readonly orgService: OrganizationsService,
    private readonly myOrgService: MyOrganizationService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.settings.isAuthorized$
      .pipe(
        filter((x) => x),
        take(1)
      )
      .subscribe(() => {
        if (this.settings.isLicensed) {
          this.myOrgService.changed
            .pipe(switchMap(() => this.orgService.getOrganizationLogoDownloadURL(this.settings.organizationId)))
            .subscribe((x) => {
              this.logoLeft = x;
              this.changeDetector.detectChanges();
            });
        } else {
          if (this.settings.creatorOrganizationId) {
            this.myOrgService.changed
              .pipe(
                switchMap(() => this.orgService.getOrganizationLogoDownloadURL(this.settings.creatorOrganizationId))
              )
              .subscribe((x) => {
                this.logoLeft = x;
                this.changeDetector.detectChanges();
              });
          }
          this.orgService.getOrganizationLogoDownloadURL(this.settings.organizationId).subscribe((x) => {
            this.logoRight = x;
            this.changeDetector.detectChanges();
          });
        }
      });
  }
}
