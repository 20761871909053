import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { translate } from "@jsverse/transloco";
import { combineLatest, take } from "rxjs";
import { MessageDialogService } from "../../shared/message-dialog/message-dialog.service";
import { SettingsService } from "../../shared/services/settings.service";
import { AppState } from "./firstLoginPage";
import { SSOLoginService } from "./sso-login-service";

@Component({
  selector: "app-sso-login-redirect",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SSOLoginRedirectComponent implements OnInit {
  constructor(
    private readonly loginService: SSOLoginService,
    private readonly auth: AuthService,
    private readonly settings: SettingsService,
    private readonly errorDialog: MessageDialogService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    combineLatest([this.auth.appState$, this.auth.user$])
      .pipe(take(1))
      .subscribe((stateAndUser) => {
        const appState = <AppState>stateAndUser[0];
        const user = stateAndUser[1];
        if (!user.sub.startsWith("auth0|")) {
          this.loginService
            .linkAccountBeforeLogin({
              connection: appState.connection,
              hashValue: appState.hash,
              userOrCandidateId: appState.userId,
              organizationId: appState.organizationId,
              ssoId: user.sub,
              attempts: Number.parseInt(appState.attempts),
              expiryDate: appState.expiry,
              isCandidate: "true" === appState.isCandidate,
            })
            .subscribe({
              next: (result) => {
                if (result) {
                  const origin = window.location.origin;
                  this.auth.loginWithRedirect({ authorizationParams: { redirect_uri: origin } });
                } else {
                  this.handleError();
                }
              },
              error: () => {
                this.settings.navigateToAccessDenied();
              },
            });
        } else {
          this.router.navigate(["/"]);
        }
      });
    this.auth.handleRedirectCallback(window.location.href);
  }

  handleError(): void {
    this.errorDialog
      .showMessage(translate("errorOccured.title"), translate("connectWithFacebook.failure"))
      .subscribe(() => {
        this.settings.logout();
      });
  }
}
