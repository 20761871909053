import { FormlyExtension, FormlyFieldConfig } from "@ngx-formly/core";

export class FormlyTranslateExtension implements FormlyExtension {
  prePopulate(field: FormlyFieldConfig): void {
    if (field.props?.translate) {
      const translations = field.props.translate[field.parent.options.formState.language];
      for (const key in translations) {
        if (Object.prototype.hasOwnProperty.call(translations, key)) {
          const value = translations[key];
          this.set(field, key, value);
        }
      }
    }
  }

  private set(obj: unknown, path: string, value: any): void {
    let schema = obj;
    const pList = path.split(".");
    const len = pList.length;
    for (let i = 0; i < len - 1; i++) {
      const elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }
    schema[pList[len - 1]] = value;
  }
}
