import { Directive } from "@angular/core";
import { Table } from "primeng/table";

@Directive({ selector: "p-table" })
export class TableOptionsDirective {
  constructor(private readonly table: Table) {
    this.table.scrollable = true;
    this.table.scrollHeight = "flex";
  }
}
