import { DocumentTemplateStatus } from "@ankaadia/graphql";
import { PrimeIcons } from "primeng/api";
import { IconColor } from "../../shared/services/style.helper";

export const DocumentTemplateStatusIcons: Record<DocumentTemplateStatus, string> = {
  [DocumentTemplateStatus.NotGenerated]: null,
  [DocumentTemplateStatus.InProgress]: IconColor.Success(PrimeIcons.HOURGLASS),
  [DocumentTemplateStatus.Edited]: IconColor.Info(PrimeIcons.PENCIL),
  [DocumentTemplateStatus.Success]: IconColor.Success(PrimeIcons.CHECK_CIRCLE),
  [DocumentTemplateStatus.Warning]: IconColor.Warning(PrimeIcons.EXCLAMATION_CIRCLE),
  [DocumentTemplateStatus.Error]: IconColor.Error(PrimeIcons.TIMES_CIRCLE),
  [DocumentTemplateStatus.NotAvailable]: IconColor.Warning(PrimeIcons.CIRCLE),
  [DocumentTemplateStatus.Deprecated]: IconColor.Info(PrimeIcons.EXCLAMATION_CIRCLE),
};
