import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ensure4LetterIsoLanguage } from "@ankaadia/ankaadia-shared";
import { TranslocoService } from "@jsverse/transloco";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class UILanguageInterceptor implements HttpInterceptor {
  constructor(private readonly transloco: TranslocoService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      headers: req.headers.append("Accept-Language", ensure4LetterIsoLanguage(this.transloco.getActiveLang())),
    });
    return next.handle(req);
  }
}
