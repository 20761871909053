import { IQualificationEvaluationReceived, nameofFactory } from "@ankaadia/ankaadia-shared";
import { TranslateParams, translate as transloco } from "@jsverse/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";

export function qualificationEvaluationReceivedFormFactory(language: string): FormlyFieldConfig[] {
  const nameOf = nameofFactory<IQualificationEvaluationReceived>();
  const translate = <T>(key: TranslateParams, params?: Record<string, any>): T => transloco<T>(key, params, language);
  return [
    {
      fieldGroupClassName: "grid",
      fieldGroup: [
        {
          type: "checkbox",
          key: nameOf("evaluationFinished"),
          className: "field col-6",
          props: {
            label: translate("qualificationEvaluationFinished.title"),
            blocklyType: "Boolean",
          },
        },
        {
          key: nameOf("evaluationFinishedDate"), //done
          type: "datepicker",
          className: "field col-6",
          props: {
            label: translate("qualificationEvaluationFinishedDate.title"),
            showIcon: true,
            inline: false,
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(),
            blocklyType: "field_date",
          },
          expressionProperties: {
            "props.disabled": (model) => !model.evaluationFinished,
            "props.required": (model) => model.evaluationFinished,
          },
        },
      ],
    },
  ];
}
