import { isPlainObject, mapValues } from "lodash";

//Maps an object and its nested objects and arrays with a function to new values
export function mapValuesDeep(obj: any, fn: (value: any, key: string, obj: any) => any): any {
  return mapValues(obj, (value, key, obj) => {
    if (Array.isArray(value)) {
      return value.map((v) => mapValuesDeep(v, fn));
    } else if (isPlainObject(value)) {
      return mapValuesDeep(value, fn);
    } else return fn(value, key, obj);
  });
}
