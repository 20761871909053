import { Injectable } from "@angular/core";
import { translate } from "@jsverse/transloco";
import { saveAs } from "file-saver";

@Injectable({ providedIn: "root" })
export class DownloadService {
  downloadFile(fileName: string, fileUrl: string): void {
    if (!fileUrl) {
      alert(translate("downloadService.message"));
      return;
    }

    saveAs(fileUrl, fileName);
  }
}
