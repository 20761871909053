import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, finalize, tap } from "rxjs";
import { ApplicationInsightsService } from "../services/application-insights.service";

interface SpinnerTimer {
  timerHandle: number;
  spinnerStarted: boolean;
}

const excludeFromSpinner = [
  "getVisibleTasks",
  "keepAlive",
  "getEmailsForProcess",
  "getOutboxForProcess",
  "getNextOutboxRun",
  "getDashboardMetrics",
  "getAllCollections",
  "getOrganizationList",
  "getCascadedLinkedOrganizations",
  "getNotificationActivityCount",
  "getMessagesReception",
  "getUserInboxCount",
  "getUserOrganizations",
  "getLastLoginDate",
  "getActivityFilterOrganizations",
  "getActivityFilterUsers",
  "getActivityFilterCollections",
  "getActivityFilterCandidates",
  "getActivityFilterProcesses",
  "getCandidateProcessRoleList",
  "getSwitchableOrganizations",
  "getMinimalAnabinDegrees",
  "getMissingDocumentTemplates",
];

@Injectable({ providedIn: "root" })
export class UIBlockInterceptor implements HttpInterceptor {
  private count = 0;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly appInsights: ApplicationInsightsService
  ) {}

  private spinnerStart(): void {
    this.count++;
    void this.spinner.show("Main");
  }

  private spinnerStop(force: boolean): void {
    this.count--;
    if (this.count <= 0 || force) {
      void this.spinner.hide("Main");
    }
  }

  private timerStart(state: SpinnerTimer): void {
    if (state.timerHandle < 0) {
      state.timerHandle = window.setTimeout(() => {
        state.spinnerStarted = true;
        this.spinnerStart();
        this.appInsights.startTrackEvent("Spinner");
      }, 950);
    }
  }

  private timerStop(state: SpinnerTimer): void {
    if (state?.timerHandle != null && state.timerHandle >= 0) {
      clearTimeout(state.timerHandle);
      if (state.spinnerStarted) {
        this.spinnerStop(false);
        state.spinnerStarted = false;
        this.appInsights.stopTrackEvent("Spinner", { timerHandle: state.timerHandle });
      }
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.endsWith("api/graphql") || excludeFromSpinner.includes(req?.body?.operationName)) {
      return next.handle(req);
    } else {
      const state: SpinnerTimer = { spinnerStarted: false, timerHandle: -1 };
      return next.handle(req).pipe(
        tap((x) => {
          if (x.type == HttpEventType.Sent) {
            this.timerStart(state);
          }
          if (x.type == HttpEventType.Response) {
            this.timerStop(state);
          }
        }),
        finalize(() => {
          this.timerStop(state);
        })
      );
    }
  }
}
