<p-card *appTranslate="let t">
  <ng-template pTemplate="content">
    <div class="flex flex-row" style="justify-content: flex-end; margin-top: -14px">
      <div class="mr-2 mb-2">
        <strong class="white-space-nowrap">
          <app-flag *ngIf="data.id" [country]="data.country" [small]="true"></app-flag>
          {{ data?.displayId }}</strong
        >
      </div>
    </div>

    <ng-container
      *ngIf="
        (fileMenu != null && fileMenu.length > 1) ||
        (fileMenu != null && fileMenu.length == 1 && fileMenu[0].items != null && fileMenu[0].items.length > 1)
      "
    >
      <button
        type="button"
        [pTooltip]="t('cv.view')"
        [disabled]="previewOpenDisabled"
        pButton
        class="p-button-outlined file-button"
        icon="pi pi-file"
        (click)="menu.toggle($event)"
      ></button>
      <p-tieredMenu #menu [popup]="true" [model]="fileMenu"></p-tieredMenu>
    </ng-container>

    <ng-container
      *ngIf="fileMenu != null && fileMenu.length == 1 && fileMenu[0].items != null && fileMenu[0].items.length == 1"
    >
      <button
        *ngIf="canPreview($any(fileMenu[0].items[0]).file)"
        type="button"
        [pTooltip]="t('cv.view')"
        [disabled]="previewOpenDisabled"
        pButton
        tooltipPosition="left"
        class="p-button-outlined file-button"
        icon="pi pi-file"
        (click)="fileMenu[0].items[0].command({})"
      ></button>
      <button
        *ngIf="!canPreview($any(fileMenu[0].items[0]).file)"
        type="button"
        [pTooltip]="t('cv.download')"
        [disabled]="previewOpenDisabled"
        pButton
        class="p-button-outlined file-button"
        icon="pi pi-download"
        (click)="fileMenu[0].items[0].command({})"
      ></button>
    </ng-container>
    <ng-container *ngIf="fileMenu != null && fileMenu.length == 1 && fileMenu[0].items == null">
      <button
        type="button"
        [pTooltip]="t('cv.view')"
        [disabled]="previewOpenDisabled"
        pButton
        class="p-button-outlined file-button"
        icon="pi pi-file"
        (click)="fileMenu[0].command({})"
      ></button>
    </ng-container>

    <div class="profile-container flex md:flex-row flex-column">
      <div class="flex flex-column profile-container-column" #leftColumn>
        <div class="profile-container-row flex flex-row">
          <div class="image-column candidate-border">
            <div
              class="p-3 flex align-items-center justify-content-center"
              style="width: 165px; height: 165px; margin-bottom: 10px; min-width: 165px"
            >
              <img default="/assets/user.jpeg" size="xlarge" shape="square" *ngIf="isAnonymousPicture" />
              <img
                #image
                [src]="candidate | thumbnail | async"
                default="/assets/user.jpeg"
                size="xlarge"
                shape="square"
                *ngIf="!isAnonymousPicture"
              />
              <span class="candidate-tag p-tag p-tag-danger p-component" *ngIf="tag">
                <span class="p-tag-value">{{ tag }}</span>
              </span>
            </div>
          </div>
          <div class="general-information-column candidate-border">
            <div>
              <table>
                <tr class="experience-row" *ngIf="data?.os?.profile.firstname || data?.os?.profile.lastname">
                  <td class="p-1 font-bold">{{ t("name.title") + " / " + t("gender.title") }}</td>
                  <td class="p-1">
                    {{ data?.os?.profile.firstname + " " + data?.os?.profile.lastname + " / " + candidateGender }}
                  </td>
                </tr>
                <tr class="experience-row">
                  <td class="p-1 font-bold">{{ t("occupation.title") }}</td>
                  <td class="p-1">{{ data.profession | staticData: StaticDataType.Profession | async }}</td>
                </tr>
                <tr class="experience-row">
                  <td class="p-1 font-bold">{{ t("function.title") }}</td>
                  <td class="p-1">{{ data.function | staticData: StaticDataType.Functions | async }}</td>
                </tr>
                <tr class="p-4 experience-row" *ngIf="data?.os?.profile.countryOfOrigin == null">
                  <td class="p-1 font-bold">{{ t("countryOfOrigin.title") }}</td>
                  <td class="p-1">{{ t("unavailable.title") }}</td>
                </tr>
                <tr class="experience-row" *ngIf="candidateAge">
                  <td class="p-1 font-bold">{{ t("birthDate.title") + " / " + t("birthDate.age") }}</td>
                  <td class="p-1">
                    {{ candidateBirthday | appDate: { dateStyle: "short" } : language }}
                    {{ " / " + candidateAge }}
                  </td>
                </tr>

                <tr class="experience-row" *ngIf="furtherEducations != null && furtherEducations.length > 0">
                  <td class="p-1 font-bold">{{ t("furtherEducation.title") }}</td>
                  <td class="p-1">
                    <ng-container *ngFor="let item of furtherEducations; let last = last">
                      {{ item | staticData: StaticDataType.ProfessionalFields | async }}
                      <ng-container *ngIf="!last">, </ng-container>
                    </ng-container>
                  </td>
                </tr>

                <tr class="experience-row">
                  <td class="p-1 font-bold">{{ t("languageSkills.title") }}</td>
                  <td class="p-1">
                    <ng-container *ngFor="let item of languages; let last = last">
                      {{ item.language | staticData: StaticDataType.Languages | async }}
                      {{
                        "(" + item.skillLevelName + (item.certified ? " - " + t("languageSkills.certified") : "") + ")"
                      }}
                      <ng-container *ngIf="!last">, </ng-container>
                    </ng-container>
                    <ng-container *ngIf="languages == undefined">
                      {{ t("unavailable.title") }}
                    </ng-container>
                  </td>
                </tr>

                <tr class="experience-row">
                  <td class="p-1 font-bold">{{ t("recognition.received") }}</td>
                  <td class="p-1">
                    {{ t(translatedRecognitionState) }}
                    <ng-container *ngIf="showQualificationHours">
                      <span
                        >({{ theoryHours ?? 0 }} {{ t("recognition.theoryHours") }} /
                        {{ practiceHours ?? 0 }}
                        {{ t("recognition.practiceHours") }} )</span
                      >
                    </ng-container>
                    <ng-container *ngIf="showExams">
                      <span>({{ careSituations ?? 0 }} {{ t("recognition.examCases") }})</span>
                    </ng-container>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="profile-container-row candidate-border">
          <p class="pl-1 m-1">
            <strong>{{ t("preferences.title") }}</strong>
          </p>
          <table style="width: 100%">
            <tr
              class="m-1 experience-row"
              *ngIf="
                (preferredCareFacility == [] || preferredCareFacility == null) &&
                (preferredWorkingField == [] || preferredWorkingField == null) &&
                (preferredLocationState == [] || preferredLocationState == null) &&
                (preferredCity == [] || preferredCity == null) &&
                isEmpty(trim(preferredCandidates)) &&
                (preferredPathOfRecognition == '' || preferredPathOfRecognition == null) &&
                (preferredLevelOfInterview == '' || preferredLevelOfInterview == null) &&
                (preferredLevelOfTransfer == '' || preferredLevelOfTransfer == null) &&
                (preferredStartDate == '' || preferredStartDate == null) &&
                (preferredEmploymentType == '' || preferredEmploymentType == null) &&
                (preferredTemporalScope == '' || preferredTemporalScope == null) &&
                (preferredFamilyReunion == '' || preferredFamilyReunion == null) &&
                (preferredTypeOfImmigration == '' || preferredTypeOfImmigration == null)
              "
            >
              <td class="p-1">
                {{ t("unavailable.title") }}
              </td>
            </tr>
            <tr class="m-1 experience-row" *ngIf="preferredCareFacilityFlexibility || !isEmpty(preferredCareFacility)">
              <td class="p-1 font-bold">{{ t("preferredCareFacility.titleShort") }}</td>
              <td class="p-1 comma-separate-children">
                <span *ngIf="preferredCareFacilityFlexibility">{{ t("preferences.flexible") }}</span>
                <span *ngIf="preferredCareFacility">
                  {{
                    preferredCareFacility
                      | staticData: StaticDataType.CareFacilities : language : staticDataRequest
                      | async
                  }}
                </span>
              </td>
            </tr>
            <tr class="m-1 experience-row" *ngIf="preferredWorkingFieldFlexibility || !isEmpty(preferredWorkingField)">
              <td class="p-1 font-bold">{{ t("preferredWorkingField.titleShort") }}</td>
              <td class="p-1 comma-separate-children">
                <span *ngIf="preferredWorkingFieldFlexibility">{{ t("preferences.flexible") }}</span>
                <span *ngIf="preferredWorkingField">
                  {{
                    preferredWorkingField
                      | staticData: StaticDataType.ProfessionalFields : language : staticDataRequest
                      | async
                  }}
                </span>
              </td>
            </tr>
            <tr
              class="m-1 experience-row"
              *ngIf="preferredLocationStateFlexibility || !isEmpty(preferredLocationState)"
            >
              <td class="p-1 font-bold">{{ t("preferredLocationState.titleShort") }}</td>
              <td class="p-1 comma-separate-children">
                <span *ngIf="preferredLocationStateFlexibility">{{ t("preferences.flexible") }}</span>
                <span *ngIf="preferredLocationState">
                  {{
                    preferredLocationState
                      | staticData: StaticDataType.FederalStates : language : staticDataRequest
                      | async
                  }}
                </span>
              </td>
            </tr>
            <tr class="m-1 experience-row" *ngIf="preferredCityFlexibility || !isEmpty(preferredCity)">
              <td class="p-1 font-bold">{{ t("preferredCity.titleShort") }}</td>
              <td class="p-1 comma-separate-children">
                <span *ngIf="preferredCityFlexibility">{{ t("preferences.flexible") }}</span>
                <span *ngIf="preferredCity">
                  {{ preferredCity | staticData: StaticDataType.KnownCities : language : staticDataRequest | async }}
                </span>
              </td>
            </tr>
            <tr class="m-1 experience-row" *ngIf="!isEmpty(trim(preferredCandidates))">
              <td class="p-1 font-bold">
                {{ t("preferredCandidates.titleShort") }}
              </td>
              <td class="p-1">
                {{ preferredCandidates }}
              </td>
            </tr>
            <tr
              class="m-1 experience-row"
              *ngIf="preferredPathOfRecognition != null && preferredPathOfRecognition.length > 0"
            >
              <td class="p-1 font-bold">
                {{ t("preferredPathOfRecognition.titleShort") }}
              </td>
              <td>
                {{
                  preferredPathOfRecognition
                    | staticData: StaticDataType.PathOfRecognition : language : staticDataRequest
                    | async
                }}
              </td>
            </tr>

            <tr
              class="m-1 experience-row"
              *ngIf="preferredLevelOfInterview != null && preferredLevelOfInterview.length > 0"
            >
              <td class="p-1 font-bold">
                {{ t("preferences.languageLevelForInterview.titleShort") }}
              </td>
              <td class="p-1">
                {{ preferredLevelOfInterview }}
              </td>
            </tr>

            <tr
              class="m-1 experience-row"
              *ngIf="preferredLevelOfTransfer != null && preferredLevelOfTransfer.length > 0"
            >
              <td class="p-1 font-bold">
                {{ t("preferences.languageLevelForTransfer.titleShort") }}
              </td>
              <td class="p-1">
                {{ preferredLevelOfTransfer }}
              </td>
            </tr>

            <tr class="m-1 experience-row" *ngIf="preferredStartDate != null">
              <td class="p-1 font-bold">
                {{ t("preferences.preferredStartDate.titleShort") }}
              </td>
              <td class="p-1">
                {{ preferredStartDate | appDate: { dateStyle: "short" } : language }}
              </td>
            </tr>

            <tr class="m-1 experience-row" *ngIf="preferredEmploymentType != '' && preferredEmploymentType != null">
              <td class="p-1 font-bold">
                {{ t("preferences.employmentType.titleShort") }}
              </td>
              <td class="p-1">
                {{ preferredEmploymentType | enum: "EmploymentRelationshipType" : language }}
              </td>
            </tr>

            <tr class="m-1 experience-row" *ngIf="preferredTemporalScope != '' && preferredTemporalScope != null">
              <td class="p-1 font-bold">
                {{ t("preferences.temporalScope.titleShort") }}
              </td>
              <td class="p-1">
                {{ preferredTemporalScope | enum: "WorkingTimeType" : language }}
              </td>
            </tr>

            <tr class="m-1 experience-row" *ngIf="preferredFamilyReunion != '' && preferredFamilyReunion != null">
              <td class="p-1 font-bold">
                {{ t("preferences.preferredFamilyReunion.title") }}
              </td>
              <td class="p-1">
                {{
                  preferredFamilyReunion
                    | staticData: StaticDataType.PreferredFamilyReunion : language : staticDataRequest
                    | async
                }}
              </td>
            </tr>

            <tr
              class="m-1 experience-row"
              *ngIf="preferredTypeOfImmigration != '' && preferredTypeOfImmigration != null"
            >
              <td class="p-1 font-bold">
                {{ t("preferences.preferredTypeOfImmigration.title") }}
              </td>
              <td class="p-1">
                {{
                  preferredTypeOfImmigration
                    | staticData: StaticDataType.PreferredTypeOfImmigration : language : staticDataRequest
                    | async
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div
        class="flex flex-column profile-container-column candidate-border"
        [style]="{ height: leftColumnOffsetHeight + 'px' }"
      >
        <p>
          <strong>{{ t("experience.title") }}</strong>
          <span>
            {{ totalExperience }}
          </span>
        </p>
        <div class="work-experience-timline-container">
          <ng-container *ngIf="!experiencetoShow || experiencetoShow.length == 0; else workExperiences">
            {{ t("unavailable.title") }}
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #workExperiences>
    <p-timeline [value]="experiencetoShow">
      <ng-template pTemplate="content" let-event>
        <ng-container
          [ngTemplateOutlet]="
            event.experienceType === ExperienceType.OtherActivities ? ExperienceTypeOtherActivity : ExperienceTypeNormal
          "
        ></ng-container>

        <ng-template #ExperienceTypeNormal>
          <div class="experience-details-row">
            <strong>{{ event.occupation | staticData: StaticDataType.Profession | async }}</strong>
          </div>
          <div *ngIf="event.experienceInstitution" class="experience-details-row experience-institution">
            {{ event.experienceInstitution }}
            <ng-container *ngIf="event.country"
              >({{ event.country | staticData: StaticDataType.Countries | async }})</ng-container
            >
          </div>
          <div *ngIf="event.experienceFacility" class="experience-details-row">
            <small class="p-text-secondary">{{ t("careFacility.title") }}</small
            ><br />
            {{ event.experienceFacility | staticData: StaticDataType.CareFacilities | async }}
            <ng-container *ngIf="event.experienceBedCount"
              >({{ event.experienceBedCount | staticData: StaticDataType.Beds | async }}
              {{ t("beds.titleFull") }})</ng-container
            >
          </div>
          <div *ngIf="event.experienceFunction" class="experience-details-row">
            <small class="p-text-secondary">{{ t("function.title") }}</small
            ><br />
            {{ event.experienceFunction | staticData: StaticDataType.Positions | async }}
          </div>
          <div *ngIf="event.workHours" class="experience-details-row">
            <small class="p-text-secondary">{{ t("workHours.title") }}</small
            ><br />
            {{ event.workHours | staticData: StaticDataType.WorkingTimeType | async }}
          </div>
          <div *ngIf="event.experienceFields" class="experience-details-row">
            <small class="p-text-secondary">{{ t("experienceFields.title") }}</small
            ><br />
            {{ event.experienceFields | staticData: StaticDataType.ProfessionalFields | async }}
          </div>
          <div *ngIf="event.experienceWorkArea" class="experience-details-row">
            <small class="p-text-secondary">{{ t("experienceWorkArea.title") }}</small
            ><br />
            {{ event.experienceWorkArea | staticData: StaticDataType.WorkArea | async }}
            <ng-container
              *ngIf="
                event?.experienceWorkArea === 'OP' &&
                (Array.isArray(event?.experienceFields)
                  ? !event?.experienceFields.includes('GY')
                  : event?.experienceFields != 'GY') &&
                event?.experienceCount > 0
              "
            >
              ({{ event.experienceCount }} {{ t("experience.opPerMonth") }})
            </ng-container>
            <ng-container
              *ngIf="
                (Array.isArray(event?.experienceFields)
                  ? event?.experienceFields.includes('GY')
                  : event?.experienceFields == 'GY') && event?.experienceCount > 0
              "
            >
              ({{ event.experienceCount }} {{ t("experience.birthPerMonth") }})
            </ng-container>
          </div>
          <div *ngIf="event.experienceCoreTasks" class="experience-details-row">
            <small class="p-text-secondary">{{ t("experience.coreTasks") }}</small
            ><br />
            <app-show-more> {{ event.experienceCoreTasks }} </app-show-more>
          </div>
        </ng-template>
        <ng-template #ExperienceTypeOtherActivity>
          <div class="experience-details-row">
            <strong>{{ event.otherActivity | staticData: StaticDataType.OtherActivities | async }}</strong>
          </div>
          <div *ngIf="event.experienceCoreTasks" class="experience-details-row">
            <small class="p-text-secondary">{{ t("experience.notes") }}</small
            ><br />
            <app-show-more> {{ event.experienceCoreTasks }} </app-show-more>
          </div>
        </ng-template>
      </ng-template>
      <ng-template pTemplate="opposite" let-event>
        <ng-container *ngIf="event.period; else OnlyPeriod">
          <div class="experience-details-row">{{ event.period }}</div>
          <div class="experience-details-row">({{ event.duration }})</div>
        </ng-container>
        <ng-template #OnlyPeriod>
          <div class="experience-details-row">{{ event.duration }}</div>
        </ng-template>
      </ng-template>
    </p-timeline>
  </ng-template>
</p-card>
