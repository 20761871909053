import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Activity, ActivityFilterOption } from "@ankaadia/graphql";
import { cloneDeep, isEqual } from "lodash";
import { TableLazyLoadEvent } from "primeng/table";
import { Observable, Subscription, finalize } from "rxjs";
import { CustomLazyLoadEvent } from "../collections/collection-edit-assigned-candidates/custom-filter.model";
import { ActivityFilter } from "./activity-filter/activity-filter.model";
import { ActivityTableComponent } from "./activity-table/activity-table.component";
import { ActivityService } from "./activity.service";

@Component({
  selector: "app-activities",
  templateUrl: "./activities.component.html",
  styleUrls: ["./activities.component.scss"],
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  private _activityFilter?: ActivityFilter;
  private _pageFilter?: TableLazyLoadEvent;
  private _reachedEnd = false;
  private _subscription?: Subscription;

  protected organizations$: Observable<ActivityFilterOption[]>;
  protected activities: Activity[] = [];
  protected totalActivities = 0;
  protected isLoading = false;
  protected isFilterBlocked = false;

  @ViewChild(ActivityTableComponent)
  table?: ActivityTableComponent;

  get filter(): ActivityFilter {
    return this._activityFilter;
  }

  set filter(filter: ActivityFilter) {
    const isChanged = !isEqual(this._activityFilter, filter);
    this._activityFilter = filter;

    if (isChanged) {
      this._reachedEnd = false;
      this.totalActivities = 0;
      this.table?.resetPage();
    } else {
      this.loadActivities();
    }
  }

  constructor(
    private readonly router: Router,
    private readonly activityService: ActivityService
  ) {
    // getCurrentNavigation should be called in constructor
    this._activityFilter = <ActivityFilter>this.router.getCurrentNavigation()?.extras?.state;
  }

  ngOnInit(): void {
    this.organizations$ = this.activityService.getActivityFilterOrganizations();
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  onPageChange(filter: TableLazyLoadEvent): void {
    this._pageFilter = filter;
    this.loadActivities();
  }

  private loadActivities(): void {
    const filter = this.buildFilterEvent();
    if (!filter) {
      return;
    }

    this.isLoading = true;
    this._subscription?.unsubscribe();
    this._subscription = this.activityService
      .getAll(filter)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((activities) => {
        this.activities = cloneDeep(activities);
        this.updateTotalActivities(activities, filter);
      });
  }

  private buildFilterEvent(): CustomLazyLoadEvent | null {
    const pageFilter = this._pageFilter;
    const activityFilter = this._activityFilter;
    if (!pageFilter || !activityFilter) {
      return null;
    }

    return {
      first: pageFilter.first,
      rows: pageFilter.rows,
      sortField: pageFilter.sortField,
      sortOrder: pageFilter.sortOrder,
      filters: {
        custom: Object.keys(activityFilter).reduce((result, key) => {
          return activityFilter[key]
            ? { ...result, [key]: { value: activityFilter[key], matchMode: "equals" } }
            : result;
        }, {}),
      },
    };
  }

  private updateTotalActivities(nodes: Activity[], filter: CustomLazyLoadEvent): void {
    if (this._reachedEnd) {
      return;
    }

    if (nodes.length < filter.rows) {
      this._reachedEnd = true;
      this.totalActivities = filter.first + nodes.length;
    } else {
      this.totalActivities = Math.max(this.totalActivities, filter.first + 2 * filter.rows);
    }
  }
}
