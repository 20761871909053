import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { CandidateDocumentMetaDataWrapperComponent } from "./candidate-document-metadata-wrapper.component";
import { CandidateDocumentDateComponent } from "./metadata-wrapper-components/candidate-document-date.component";
import { MetadataWrapperDebugViewComponent } from "./metadata-wrapper-debug-view.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    MetadataWrapperDebugViewComponent,
    CandidateDocumentMetaDataWrapperComponent,
    CandidateDocumentDateComponent,
  ],
  exports: [CandidateDocumentDateComponent],
})
export class CandidateDocumentMetadataModule {}
