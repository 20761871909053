import { IsTuple, Builtin } from "ts-essentials";

export type DeepTypeable<Type, Target> = Type extends Builtin
  ? Target
  : Type extends Map<infer Keys, infer Values>
    ? Map<DeepTypeable<Keys, Target>, DeepTypeable<Values, Target>>
    : Type extends ReadonlyMap<infer Keys, infer Values>
      ? ReadonlyMap<DeepTypeable<Keys, Target>, DeepTypeable<Values, Target>>
      : Type extends WeakMap<infer Keys, infer Values>
        ? WeakMap<DeepTypeable<Keys, Target>, DeepTypeable<Values, Target>>
        : Type extends Set<infer Values>
          ? Set<DeepTypeable<Values, Target>>
          : Type extends ReadonlySet<infer Values>
            ? ReadonlySet<DeepTypeable<Values, Target>>
            : Type extends WeakSet<infer Values>
              ? WeakSet<DeepTypeable<Values, Target>>
              : Type extends readonly (infer Values)[]
                ? Type extends IsTuple<Type>
                  ? { [Key in keyof Type]: DeepTypeable<Type[Key], Target> | null }
                  : Type extends Values[]
                    ? DeepTypeable<Values, Target>[]
                    : readonly DeepTypeable<Values, Target>[]
                : Type extends Promise<infer Value>
                  ? Promise<DeepTypeable<Value, Target>>
                  : Type extends NonNullable<unknown>
                    ? { [Key in keyof Type]: DeepTypeable<Type[Key], Target> }
                    : Target;
