import { ChangeDetectorRef, Component, DestroyRef, OnInit, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormArray } from "@angular/forms";
import {
  RequiredVaccinations,
  RequiredVaccinationsEntryAt,
  RequiredVaccinationsEntryDe,
  RequiredVaccinationsUpdateInput,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { translate } from "@ngneat/transloco";
import { Subject, switchMap, tap } from "rxjs";
import { MessageService } from "../message/message.service";
import { VaccinesConfigForm } from "./required-vaccinations-form.model";
import { RequiredVaccinationsFormService } from "./required-vaccinations-form.service";
import { RequiredVaccinationsTableComponent } from "./required-vaccinations-table/required-vaccinations-table.component";
import { RequiredVaccinationsService } from "./required-vaccinations.service";
import { SettingsService } from "../../shared/services/settings.service";

@Component({
  selector: "app-required-vaccinations",
  templateUrl: "./required-vaccinations.component.html",
})
export class RequiredVaccinationsComponent implements OnInit {
  protected readonly SupportedImmigrationCountry = SupportedImmigrationCountry;
  protected readonly organizationId = this.settingsService.organizationId;

  private readonly loadDataTrigger$ = new Subject<void>();

  protected form: VaccinesConfigForm;

  @ViewChild("de")
  table: RequiredVaccinationsTableComponent;

  constructor(
    private readonly settingsService: SettingsService,
    private readonly formService: RequiredVaccinationsFormService,
    private readonly requiredVaccinationsService: RequiredVaccinationsService,
    private readonly messageService: MessageService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef
  ) {
    this.loadDataTrigger$
      .pipe(
        takeUntilDestroyed(),
        switchMap(() => this.requiredVaccinationsService.getConfiguration(this.organizationId))
      )
      .subscribe((x) => this.setForm(x));
  }

  ngOnInit(): void {
    this.loadDataTrigger$.next();
  }

  protected save(): void {
    const update = new RequiredVaccinationsUpdateInput();
    Object.assign(update, this.form.value);

    this.requiredVaccinationsService
      .update(update)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((x) => this.setForm(x)),
        tap(() =>
          this.messageService.add({
            severity: "success",
            summary: translate("requiredVaccines.updated.title"),
            detail: translate("requiredVaccines.updated.message", document),
          })
        )
      )
      .subscribe();
  }

  protected cancel(): void {
    this.loadDataTrigger$.next();
  }

  private setForm(requiredVaccinations: RequiredVaccinations): void {
    const form = this.formService.createFullForm(requiredVaccinations);
    if (this.form) {
      this.form.reset(form.value, { emitEvent: false });
      this.form.patchValue(form.value, { emitEvent: false });

      this.fillTable(this.form.controls.de.controls.configs, requiredVaccinations.de?.configs);
      this.fillTable(this.form.controls.at.controls.configs, requiredVaccinations.at?.configs);

      this.changeDetector.detectChanges();
    } else this.form = form;
  }

  private fillTable(configs: FormArray, data: RequiredVaccinationsEntryDe[] | RequiredVaccinationsEntryAt[]): void {
    configs.clear({ emitEvent: false });
    for (const row of data ?? []) {
      configs.push(this.formService.createRow(row), { emitEvent: false });
    }
  }
}
