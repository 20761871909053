import { Injectable } from "@angular/core";
import {
  AnabinAssetType,
  AnabinDegreeDetailsFragment,
  AnabinInstitutionDetailsFragment,
  GetAnabinDegreeDetailsGQL,
  GetAnabinInstitutionDetailsGQL,
  GetMinimalAnabinDegreeByIdGQL,
  GetMinimalAnabinDegreesGQL,
  StaticDataModel,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

export type AnabinDetails = AnabinDegreeDetailsFragment | AnabinInstitutionDetailsFragment;

@Injectable({ providedIn: "root" })
export class AnabinService {
  constructor(
    private readonly getMinimalAnabinAssetDataGQL: GetMinimalAnabinDegreesGQL,
    private readonly getMinimalAnabinAssetByIdGQL: GetMinimalAnabinDegreeByIdGQL,
    private readonly getAnabinDegreeDetailsGQL: GetAnabinDegreeDetailsGQL,
    private readonly getAnabinInstitutionDetailsGQL: GetAnabinInstitutionDetailsGQL
  ) {}

  getMinimalAnabinAssetData(
    type: AnabinAssetType,
    id: string,
    country: string,
    name?: string
  ): Observable<StaticDataModel[]> {
    return this.getMinimalAnabinAssetDataGQL
      .fetch({ input: { id: id, country: country, name: name, type: type } }, { fetchPolicy: "cache-first" })
      .pipe(map((result) => result.data.getMinimalAnabinAssetData));
  }

  getMinimalAnabinAssetById(type: AnabinAssetType, id: string): Observable<StaticDataModel[]> {
    return this.getMinimalAnabinAssetByIdGQL
      .fetch({ input: { id: id, type: type } }, { fetchPolicy: "cache-first" })
      .pipe(map((result) => result.data.getMinimalAnabinAssetById));
  }

  getDetails(id: string, type: AnabinAssetType): Observable<AnabinDetails> {
    switch (type) {
      case AnabinAssetType.AnabinAbschluesse:
        return this.getDegreeDetails(id);
      case AnabinAssetType.AnabinInstitutions:
        return this.getInstitutionDetails(id);
      default:
        throw new Error(`Unknown AnabinAssetType: ${type}`);
    }
  }

  private getDegreeDetails(id: string): Observable<AnabinDegreeDetailsFragment> {
    return this.getAnabinDegreeDetailsGQL
      .fetch({ ID: id }, { fetchPolicy: "cache-first" })
      .pipe(map((result) => result.data.getAnabinDegreeDetails));
  }

  private getInstitutionDetails(id: string): Observable<AnabinInstitutionDetailsFragment> {
    return this.getAnabinInstitutionDetailsGQL
      .fetch({ ID: id }, { fetchPolicy: "cache-first" })
      .pipe(map((result) => result.data.getAnabinInstitutionDetails));
  }
}
