import { Injectable } from "@angular/core";
import { DashboardMetric, GetDashboardMetricsGQL } from "@ankaadia/graphql";
import { map, Observable } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";

@Injectable({ providedIn: "root" })
export class DashboardService {
  constructor(
    private readonly settings: SettingsService,
    private readonly metricsGet: GetDashboardMetricsGQL
  ) {}

  getMetrics(): Observable<DashboardMetric[]> {
    return this.metricsGet
      .fetch({ input: { organizationId: this.settings.organizationId } })
      .pipe(map((x) => x.data.getDashboardMetrics as DashboardMetric[]));
  }
}
