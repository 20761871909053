import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { BadgeModule } from "primeng/badge";
import { BlockUIModule } from "primeng/blockui";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { TriStateCheckboxModule } from "primeng/tristatecheckbox";
import { ChipModule } from "primeng/chip";
import { ChipsModule } from "primeng/chips";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ContextMenuModule } from "primeng/contextmenu";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { EditorModule } from "primeng/editor";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { GalleriaModule } from "primeng/galleria";
import { ImageModule } from "primeng/image";
import { InplaceModule } from "primeng/inplace";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ListboxModule } from "primeng/listbox";
import { MenuModule } from "primeng/menu";
import { MessageModule } from "primeng/message";
import { MultiSelectModule } from "primeng/multiselect";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PasswordModule } from "primeng/password";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { RippleModule } from "primeng/ripple";
import { ScrollTopModule } from "primeng/scrolltop";
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from "primeng/sidebar";
import { SplitButtonModule } from "primeng/splitbutton";
import { SplitterModule } from "primeng/splitter";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { TieredMenuModule } from "primeng/tieredmenu";
import { TimelineModule } from "primeng/timeline";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { TreeSelectModule } from "primeng/treeselect";
import { TreeTableModule } from "primeng/treetable";
import { AppendToBodyDirective } from "./append-to-body/append-to-body.directive";
import { AutocompleteOptionValueFixDirective } from "./autocomplete-fix/autocomplete-optionValue-fix.directive";
import { AutocompleteReadonlyFixDirective } from "./autocomplete-fix/autocomplete-readonly-fix.directive";
import { AutoResizeFixDirective } from "./autoresize-fix/autoresize-fix.directive";
import { BlockUIFixDirective } from "./block-ui-fix/block-ui-fix.directive";
import { CalendarOptionsDirective } from "./calendar-options/calendar-options.directive";
import { CalendarReadonlyDirective } from "./calendar-readonly/calendar-readonly.directive";
import { CalendarUtcFixDirective } from "./calendar-utc-fix/calendar-utc-fix.directive";
import { ChipsReadonlyDirective } from "./chips-readonly/chips-readonly.directive";
import { ConfirmPopupFixDirective } from "./confirm-popup-fix/confirm-popup-fix.directive";
import { DialogKeysDirective } from "./dialog-keys/dialog-keys.directive";
import { DialogOptionsDirective } from "./dialog-options/dialog-options.directive";
import { DropdownEditableColumnAutoFocusDirective } from "./dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownFormControlValuePersistingDirective } from "./dropdown-form-control-value-persisting/dropdown-form-control-value-persisting.directive";
import { DropdownOptionsDirective } from "./dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "./dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "./dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { DropdownRememberStateDirective } from "./dropdown-remember-state/dropdown-remember-state.directive";
import { DropdownTabFixDirective } from "./dropdown-tab-fix/dropdown-tab-fix.directive";
import { InputNumberOptionsDirective } from "./input-number-options/input-number-options.directive";
import { MenuFocusedOptionFixDirective } from "./menu-focused-option-fix/menu-focused-option-fix.directive";
import { MenuShowFixDirective } from "./menu-show-fix/menu-show-fix.directive";
import { MultiSelectEditableColumnAutoFocusDirective } from "./multi-select-editable-column-auto-focus/multi-select-editable-column-auto-focus.directive";
import { MultiSelectEmptyDirective } from "./multi-select-empty/multi-select-empty.directive";
import { MultiSelectFindLabelFixDirective } from "./multi-select-find-label-fix/multi-select-find-label-fix.directive";
import { MultiSelectReadonlyFixDirective } from "./multi-select-readonly-fix/multi-select-readonly-fix.directive";
import { RadioButtonReadonlyDirective } from "./radio-button-readonly/radio-button-readonly.directive";
import { SidebarAdditionalComponentDirective } from "./sidebar-additional-component/sidebar-additional-component.directive";
import { SidebarOptionsDirective } from "./sidebar-options/sidebar-options.directive";
import { SplitterFixDirective } from "./splitter-fix/splitter-fix.directive";
import { TabViewRememberTabDirective } from "./tab-view-remember-tab/tab-view-remember-tab.directive";
import { TableEditableColumnNoKeyboardNavigationDirective } from "./table-editable-column-no-keyboard-navigation/table-editable-column-no-keyboard-navigation.directive";
import { TableFrozenColumnFixDirective } from "./table-frozen-column-fix/table-frozen-column-fix.directive";
import { TableOptionsDirective } from "./table-options/table-options.directive";
import { ToolbarFixDirective } from "./toolbar-fix/toolbar-fix.directive";
import { TreeSelectNodeExpandFixDirective } from "./tree-select-node-expand-fix/tree-select-node-expand-fix.directive";
import { TreeTableOptionsDirective } from "./tree-table-options/tree-table-options.directive";
import { UploadConfigDirective } from "./upload-config/upload-config.directive";
import { UploadDropAreaDirective } from "./upload-drop-area/upload-drop-area.directive";
import { DialogFixDirective } from "./dialog-fix/dialog-fix.directive";

const modules = [
  AccordionModule,
  AutoCompleteModule,
  BadgeModule,
  BlockUIModule,
  ButtonModule,
  CalendarModule,
  CardModule,
  CarouselModule,
  CascadeSelectModule,
  ChartModule,
  CheckboxModule,
  TriStateCheckboxModule,
  ChipModule,
  ChipsModule,
  ConfirmDialogModule,
  ConfirmPopupModule,
  ContextMenuModule,
  DataViewModule,
  DialogModule,
  DividerModule,
  DropdownModule,
  EditorModule,
  FieldsetModule,
  FileUploadModule,
  GalleriaModule,
  ImageModule,
  InplaceModule,
  InputSwitchModule,
  InputTextareaModule,
  InputTextModule,
  ListboxModule,
  MenuModule,
  MessageModule,
  MultiSelectModule,
  OverlayPanelModule,
  PaginatorModule,
  PanelModule,
  PasswordModule,
  PickListModule,
  ProgressBarModule,
  RadioButtonModule,
  RatingModule,
  RippleModule,
  ScrollTopModule,
  SelectButtonModule,
  SidebarModule,
  SplitButtonModule,
  SplitterModule,
  TableModule,
  TabViewModule,
  TieredMenuModule,
  TimelineModule,
  ToggleButtonModule,
  ToolbarModule,
  TooltipModule,
  TreeModule,
  TreeSelectModule,
  TreeTableModule,
];

const directives = [
  AppendToBodyDirective,
  AutoResizeFixDirective,
  BlockUIFixDirective,
  CalendarOptionsDirective,
  CalendarReadonlyDirective,
  CalendarUtcFixDirective,
  ChipsReadonlyDirective,
  ConfirmPopupFixDirective,
  DialogFixDirective,
  DialogKeysDirective,
  DialogOptionsDirective,
  DropdownEditableColumnAutoFocusDirective,
  MultiSelectEditableColumnAutoFocusDirective,
  DropdownFormControlValuePersistingDirective,
  DropdownOptionsDirective,
  DropdownPrePopulateSingleOptionDirective,
  DropdownReadonlyFixDirective,
  DropdownRememberStateDirective,
  DropdownTabFixDirective,
  InputNumberOptionsDirective,
  MultiSelectEmptyDirective,
  MultiSelectReadonlyFixDirective,
  RadioButtonReadonlyDirective,
  SidebarAdditionalComponentDirective,
  SidebarOptionsDirective,
  SplitterFixDirective,
  TableEditableColumnNoKeyboardNavigationDirective,
  TableFrozenColumnFixDirective,
  TableOptionsDirective,
  TabViewRememberTabDirective,
  ToolbarFixDirective,
  TreeTableOptionsDirective,
  UploadConfigDirective,
  UploadDropAreaDirective,
  AutocompleteReadonlyFixDirective,
  AutocompleteOptionValueFixDirective,
  MenuFocusedOptionFixDirective,
  MenuShowFixDirective,
  MultiSelectFindLabelFixDirective,
  TreeSelectNodeExpandFixDirective,
];

@NgModule({
  imports: [RouterModule, DragDropModule, ...modules],
  declarations: directives,
  exports: [...modules, ...directives],
})
export class PrimeNGModule {}
