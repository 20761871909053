import { EventEmitter } from "@angular/core";
import { ICandidateOSModel } from "@ankaadia/ankaadia-shared";
import { CandidateForViewFragment, Document, Sharing } from "@ankaadia/graphql";

export interface FilePreview {
  url: string;
  fileName: string;
  fileType: string;
}

export type CompleteCandidateForView = Omit<CandidateForViewFragment, "os"> & {
  os?: {
    profile: ICandidateOSModel;
  };
};

export interface CandidateCardComponent {
  data: CompleteCandidateForView;
  sharing: Sharing;
  files: Document[];
  tag: string;
  isPresentation?: boolean;
  filePreview: EventEmitter<FilePreview>;
  selectedOrganizationId: string;
  selectedCollectionId: string;
}
