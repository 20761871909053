import { translate } from "@ngneat/transloco";
import { Observable, map, of } from "rxjs";
import { safeForkJoin } from "../../shared/services/safe-fork-join";
import { StaticDataContext } from "../../shared/static-data/static-data.service";
import { getMigration } from "../candidates/candidate-helper";
import {
  CompleteCandidate,
  ForeignKeyData,
  ForeignKeyInstructions,
  GetForeignEntities,
  GetForeignEntitiesFromCandidate,
  GetForeignEntitiesFromMigration,
  HasLinkableId,
} from "./candidate-document-metadata.model";
import { CertifiedEducationExam } from "@ankaadia/graphql";

export interface AdditionalForeignKeySourceData {
  certifiedExams?: CertifiedEducationExam[];
}

export interface IDocumentForeignKeyHandler {
  get title(): string;
  getForeignData: (
    candidate: CompleteCandidate,
    additionalData: AdditionalForeignKeySourceData
  ) => Observable<ForeignKeyData[]>;
}

export class DocumentForeignKeyHandler<T extends HasLinkableId> implements IDocumentForeignKeyHandler {
  get title(): string {
    return this.meta.title;
  }

  constructor(private readonly meta: ForeignKeyInstructions<T>) {}

  getForeignData(
    candidate: CompleteCandidate,
    additionalData: AdditionalForeignKeySourceData
  ): Observable<ForeignKeyData[]> {
    const context = this.buildContext(candidate);
    const entities = this.getForeignEntities(candidate, additionalData);
    return safeForkJoin(
      entities.map((entity, index) => this.getData(entity, index, context)),
      []
    );
  }

  private getForeignEntities(candidate: CompleteCandidate, additionalData?: AdditionalForeignKeySourceData): T[] {
    const getEntities = this.meta.getEntities;

    if (isMigrationIndepended(getEntities)) {
      return tryGet(() => getEntities(candidate, additionalData));
    }

    const getFromMigration: GetForeignEntitiesFromMigration<T> = getEntities[candidate.immigrationCountry];
    if (!getFromMigration) {
      return [];
    }

    const migration = getMigration(candidate);
    return migration ? tryGet(() => getFromMigration(migration)) : [];
  }

  private getData(entity: T, index: number, context: StaticDataContext): Observable<ForeignKeyData> {
    const result = this.meta.getLabel(entity, context);
    const fallback = `${this.title} ${translate("common.missingLabel")}`;
    if (result === null) {
      return of({ id: entity.id, index, label: fallback });
    }

    const observable = typeof result === "string" ? of(result) : result;
    return observable.pipe(map((label) => ({ id: entity.id, index, label: label ?? fallback })));
  }

  private buildContext(candidate: CompleteCandidate): StaticDataContext {
    return { organizationId: candidate.organizationId, immigrationCountry: candidate.immigrationCountry };
  }
}

function tryGet<T extends HasLinkableId>(get: () => T[]): T[] {
  try {
    return get() ?? [];
  } catch {
    return [];
  }
}

function isMigrationIndepended<T>(
  getEntities: GetForeignEntities<T>
): getEntities is GetForeignEntitiesFromCandidate<T> {
  return typeof getEntities === "function";
}
