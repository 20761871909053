import { Injectable } from "@angular/core";
import { map, Observable, Subject } from "rxjs";
import { AutoCvResponse, GetAutoGeneratedCvUrlGQL } from "@ankaadia/graphql";

@Injectable({ providedIn: "root" })
export class CandidateCardService {
  imageChanged = new Subject<void>();

  constructor(private readonly getUrl: GetAutoGeneratedCvUrlGQL) {}

  getAutoGeneratedCvUrl(
    candidateId: string,
    candidateOrganizatioId: string,
    sharingOrganizationId: string,
    language: string,
    SharingId: string,
    templateOrgId: string,
    collectionOrganizationId: string,
    collectionId: string
  ): Observable<AutoCvResponse> {
    return this.getUrl
      .fetch({
        input: {
          candidateId: candidateId,
          candidateOrganizationId: candidateOrganizatioId,
          sharingOrganizationId: sharingOrganizationId,
          language: language,
          selectedSharingId: SharingId,
          templateOrganizationId: templateOrgId,
          collectionOrganizationId: collectionOrganizationId,
          collectionId: collectionId,
        },
      })
      .pipe(map((result) => result.data.getAutoGeneratedCVUrl));
  }
}
