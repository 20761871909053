import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { TranslationProposalService } from "./translationProposal.service";
import { TranslationProposalGatheringComponent } from "./translationProposalGatheringComponent/translationProposalGathering.component";
import { TranslationProposalButtonComponent } from "./translationProposalButton/translationProposalButton.component";

@NgModule({
  imports: [SharedModule],
  providers: [TranslationProposalService],
  declarations: [TranslationProposalGatheringComponent, TranslationProposalButtonComponent],
  exports: [TranslationProposalButtonComponent],
})
export class TranslationProposalModule {}
