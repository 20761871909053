<ng-container *appTranslate="let t">
  <p-card
    [header]="
      t('notifications.since', { date: since | appDateTime: { dateStyle: 'short', timeStyle: 'medium' } : language })
    "
  >
    <button
      pButton
      type="button"
      icon="pi pi-cog"
      class="p-button-rounded p-button-text config-button"
      [disabled]="isBusy"
      (click)="configOverlay.show($event)"
    ></button>

    <app-activity-table
      [activities]="notifications"
      [totalActivities]="notifications.length"
      [disabled]="isBusy"
      [lazy]="false"
      [showProcess]="false"
      [showUser]="false"
      [showDate]="false"
      [showNotificationClear]="true"
      (notificationCleared)="notificationCleared.emit($event)"
    ></app-activity-table>

    <p-overlayPanel #configOverlay [style]="{ width: '600px' }" [dismissable]="false">
      <ng-template pTemplate>
        <app-notification-configuration
          [settings]="settings"
          (changed)="settingsChanged.emit($event)"
          (closed)="configOverlay.hide()"
        ></app-notification-configuration>
      </ng-template>
    </p-overlayPanel>

    <ng-template pTemplate="footer">
      <div class="flex justify-content-between">
        <span>&nbsp;</span>

        <a href="javascript:void(0)" (click)="activityLogOpened.emit()">
          {{ t("notifications.openActivityLog") }}
        </a>

        <div>
          <p-button
            [label]="t('notifications.clearAll')"
            icon="pi pi-trash"
            [disabled]="isBusy"
            class="mr-2"
            (onClick)="allNotificationsCleared.emit()"
          ></p-button>

          <p-button
            closeOnEscape
            [label]="t('common.close')"
            icon="pi pi-times"
            [disabled]="isBusy"
            (onClick)="closed.emit()"
          ></p-button>
        </div>
      </div>
    </ng-template>
  </p-card>
</ng-container>
