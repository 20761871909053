import { Directive, ElementRef, OnInit } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";
import { ClickCaptureService } from "./click-capture.service";

@Directive({ selector: "[formControl], [formControlName]" })
export class ClickCaptureDirective implements OnInit {
  get control(): FormControl {
    return this.controlDir.control as FormControl;
  }

  constructor(
    private readonly controlDir: NgControl,
    private readonly host: ElementRef<HTMLFormElement>,
    private readonly clickCaptureService: ClickCaptureService
  ) {}

  ngOnInit(): void {
    if (this.controlDir.control) {
      this.clickCaptureService.registerFormControlWithElement(this.host.nativeElement, this.control);
    }
  }
}
