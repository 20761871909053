<ng-container *transloco="let t">
  <label for="switch">{{ t("messagesReception.title") }}</label>
  <p-inputSwitch
    id="switch"
    [(ngModel)]="enabled"
    [readonly]="readonly"
    (onChange)="changed($any($event))"
    class="ml-2"
  ></p-inputSwitch>

  <small
    style="display: block"
    class="ml-2 mb-2"
    *ngIf="showDetails && !enabled && messagesReception?.modifier?.id === messagesReception?.candidateOrUserId"
  >
    {{
      messagesReception?.modifier?.isCandidate
        ? t("messagesReception.candidateLastUpdate", {
            date: messagesReception?.updatedAt | appDate: { dateStyle: "short", timeStyle: "medium" } : language,
          })
        : t("messagesReception.userLastUpdate", {
            date: messagesReception?.updatedAt | appDate: { dateStyle: "short", timeStyle: "medium" } : language,
          })
    }}
  </small>
</ng-container>
