export interface MatchingAgreement {
  location: string;
  interviewDate: Date;
  address: string;
  careFacility: string[];
  experienceField: string[];
  transferLanguageLevel: string;
  pathOfRecognition: string;
  recognitionReimbursement: boolean;
  travelReimbursement: boolean;
  regulatoryReimbursement: boolean;
  benefits: string[];
  diffrentEmploymentTerms: boolean;
  employemntTermsBeforeRecognition: EmploymentTerms;
  employemntTermsAfterRecognition: EmploymentTerms;
}

export interface EmploymentTerms {
  contractTemplate: string; // ContractTemplateFragment
  function: string;
  salary: number;
  vacationDays: number;
  workingHoursPerWeek: number;
  mandatoryOvertime: boolean;
  contractType: string;
}
