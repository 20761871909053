import { ChangeDetectionStrategy, Component, HostListener } from "@angular/core";
import { map } from "rxjs";
import { DocumentTemplateStatusIcons } from "../../document-template/document-templates.model";
import { MessageService } from "../message.service";

@Component({
  selector: "app-message-error",
  templateUrl: "./message-error.component.html",
  styleUrl: "./message-error.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageErrorComponent {
  readonly message$ = this.messageService.message$.pipe(map((x) => (!x || x.severity === "error" ? x : null)));

  readonly Icons = {
    error: DocumentTemplateStatusIcons.Error,
  };

  constructor(private readonly messageService: MessageService) {}

  @HostListener("mouseenter")
  onMouseEnter(): void {
    this.messageService.pause();
  }

  @HostListener("mouseleave")
  onMouseLeave(): void {
    this.messageService.resume();
  }
}
