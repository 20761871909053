<p-chip [styleClass]="'m-1 table-chip ' + (tag.styleClass ?? '')">
  <a *ngIf="!tag.children?.length" href="javascript:void(0)" (click)="column.tagClick(rowData, tag.value)">
    {{ tag.label }}
  </a>
  <ng-container *ngIf="tag.children?.length > 0">
    <p-menu #tagMenu [popup]="true" [model]="menuItems" [styleClass]="column.fullWidthTags ? 'w-auto' : ''"></p-menu>
    <a href="javascript:void(0)" (click)="tagMenu.toggle($event)">
      {{ tag.label }}
    </a>
  </ng-container>
</p-chip>
