import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UnsubscribeService } from "./unsubscribe.service";
@Component({
  selector: "app-unsubscribe",
  templateUrl: "./unsubscribe.component.html",
  styleUrls: ["./unsubscribe.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsubscribeComponent {
  showSuccess = false;

  constructor(private readonly service: UnsubscribeService) {}

  onUnsubscribe(): void {
    this.showSuccess = true;
    this.service.resolveUnsubscribe().subscribe(() => ({}));
  }
}
