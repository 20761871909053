<p-calendar
  [dateFormat]="format"
  [hourFormat]="format"
  [showTime]="props.showTime"
  [showIcon]="props.showIcon"
  [icon]="props.icon ?? 'pi pi-calendar'"
  [showButtonBar]="props.showButtonBar"
  [showOtherMonths]="props.showOtherMonths"
  [selectOtherMonths]="props.selectOtherMonths"
  [selectionMode]="props.selectionMode || 'single'"
  [inline]="props.inline"
  [readonlyInput]="props.readonlyInput"
  [touchUI]="props.touchUI"
  [maxDate]="props.maxDate"
  [minDate]="props.minDate"
  [timeOnly]="props.timeOnly"
  [view]="props.view ?? 'date'"
  [placeholder]="placeholder"
  [formControl]="$any(formControl)"
  [formlyAttributes]="field"
  [showClear]="!props.required"
  [readonly]="props.readonly"
  dataType="date"
>
  <ng-template *ngIf="props.showTimezone" pTemplate="header">{{ currentTimeZone }}</ng-template>
</p-calendar>
