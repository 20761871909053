import { Component, DestroyRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { UserPermission } from "@ankaadia/ankaadia-shared";
import { DeleteUserInput, GetUsersQuery, Organization } from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { Observable, Subscription, catchError, map, of, tap } from "rxjs";
import { BooleanPipe } from "../../shared/pipes/boolean.pipe";
import { EnumPipe } from "../../shared/pipes/enum.pipe";
import { SettingsService } from "../../shared/services/settings.service";
import { PipeDescription, TableColumn, TableOperation } from "../../shared/table/table.model";
import { MessageService } from "../message/message.service";
import { OrganizationsService } from "../organizations/organizations.service";
import { AppLoginDatePipe } from "./login-date.pipe";
import { UserDialogComponent } from "./user-dialog/user-dialog.component";
import { UsersService } from "./users.service";

type UserRow = GetUsersQuery["users"][0] & { lastLoginDate: { auth0Id: string; organizationId: string } };

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
})
export class UsersComponent implements OnInit, OnDestroy {
  private organizationId: string;
  private dataSubScription: Subscription;

  readonly footerOperations: TableOperation[] = [
    {
      label: translate("common.back"),
      icon: PrimeIcons.ANGLE_LEFT,
      operation: (): void => this.back(),
    },
  ];

  readonly newOperations: TableOperation[] = [
    {
      label: translate("common.new"),
      icon: PrimeIcons.PLUS,
      operation: (): void => this.addUser(),
    },
  ];

  readonly tableOperations: TableOperation[] = [
    {
      label: translate("common.edit"),
      icon: PrimeIcons.PENCIL,
      operation: (x: UserRow): void => this.editUser(x),
    },
    {
      label: translate("user.reinvite"),
      icon: PrimeIcons.REPLAY,
      operation: (x: UserRow, e: Event): void => this.reInvite(x, e),
    },
    {
      label: translate("emails.title"),
      icon: PrimeIcons.AT,
      permission: UserPermission.Administrator,
      operation: (x: UserRow): void => this.showEmails(x),
    },
    {
      label: translate("user.blockUnblock"),
      icon: PrimeIcons.LOCK,
      operation: (x: UserRow, e: Event): void => this.blockUnblock(x, e),
    },
    {
      label: translate("common.delete"),
      icon: PrimeIcons.TRASH,
      operation: (x: UserRow, e: Event): void => this.deleteUser(x, e),
    },
  ];

  readonly columns: TableColumn[] = [
    {
      header: translate("firstName.title"),
      fieldname: "firstname",
      sortable: true,
      includeInGlobalFilter: true,
    },
    { header: translate("lastName.title"), fieldname: "lastname", sortable: true, includeInGlobalFilter: true },
    { header: translate("email.title"), fieldname: "email", sortable: true, includeInGlobalFilter: true },
    {
      header: translate("userRoles.title"),
      fieldname: "userRoles",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(EnumPipe, "UserRole"),
    },
    {
      header: translate("blocked.title"),
      fieldname: "isBlocked",
      sortable: true,
      includeInGlobalFilter: false,
      pipeDescription: new PipeDescription(BooleanPipe),
    },
    {
      header: translate("lastLogin.title"),
      fieldname: "lastLoginDate",
      sortable: true,
      includeInGlobalFilter: false,
      pipeDescription: new PipeDescription(AppLoginDatePipe, this.transloco.translate("loading"), {
        dateStyle: "short",
        timeStyle: "short",
      }),
    },
  ];

  @ViewChild(UserDialogComponent)
  userDialog: UserDialogComponent;

  showDialog = false;
  users: UserRow[];
  organizationName: Observable<string>;
  organization: Organization;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly transloco: TranslocoService,
    private readonly settings: SettingsService,
    private readonly orgService: OrganizationsService,
    private readonly usersService: UsersService,
    private readonly confirmationService: ConfirmationService,
    private readonly route: ActivatedRoute,
    private readonly messageService: MessageService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.showDialog = false;
    this.route.params.subscribe((params) => this.routerParamsChanged(params));
  }

  ngOnDestroy(): void {
    this.dataSubScription.unsubscribe();
  }

  closeSidebar(): void {
    this.showDialog = false;
  }

  private routerParamsChanged(params: Params): void {
    this.organizationId = params["orgId"];
    this.organizationName = this.orgService.getOrganization(this.organizationId).pipe(
      takeUntilDestroyed(this.destroyRef),
      tap((x) => (this.organization = x)),
      map((x) => x.name),
      catchError((x) => {
        this.settings.navigateToNotFound();
        return of(x);
      })
    );

    this.dataSubScription?.unsubscribe();
    this.dataSubScription = this.usersService
      .getUsers(this.organizationId)
      .pipe(
        map((users) =>
          users.map((user) => ({
            ...user,
            lastLoginDate: {
              auth0Id: user.auth0Id,
              organizationId: user.organizationId,
            },
          }))
        )
      )
      .subscribe((users) => (this.users = users));
  }

  private addUser(): void {
    this.showDialog = true;
    this.userDialog.addUser(this.organization);
  }

  private editUser(user: UserRow): void {
    this.userDialog.editUser(user.id, this.organization, user.auth0Id).subscribe(() => (this.showDialog = true));
  }

  private reInvite(user: UserRow, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("user.confirmReinvite.message", user),
      header: translate("user.confirmReinvite.title"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () =>
        this.usersService.reInviteUser(user.id, user.organizationId).subscribe((x) => {
          if (x) {
            this.messageService.add({
              severity: "success",
              summary: translate("user.reinvited.title"),
              detail: translate("user.reinvited.message"),
            });
          }
        }),
    });
  }

  private blockUnblock(user: UserRow, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: user.isBlocked
        ? translate("user.confirmUnblock.message", user)
        : translate("user.confirmBlock.message", user),
      header: user.isBlocked ? translate("user.confirmUnblock.title") : translate("user.confirmBlock.title"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () =>
        this.usersService.blockUnBlockUser(user.id, user.organizationId, user.isBlocked).subscribe((x) => {
          if (x) {
            this.messageService.add({
              severity: "success",
              summary: x.isBlocked ? translate("user.blocked.title") : translate("user.unblocked.title"),
              detail: x.isBlocked ? translate("user.blocked.message") : translate("user.unblocked.message"),
            });
          }
        }),
    });
  }

  private deleteUser(user: UserRow, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("user.confirmDelete.message", user),
      header: translate("user.confirmDelete.title"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        const delUser: DeleteUserInput = {
          organizationId: user.organizationId,
          _etag: user._etag,
          auth0Id: user.auth0Id,
          id: user.id,
        };
        this.usersService.deleteUser(delUser).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary: translate("user.deleted.title"),
            detail: translate("user.deleted.message", user),
          });
        });
      },
    });
  }

  private back(): void {
    this.router.navigate(["/app/settings/organizations", this.settings.organizationId]);
  }

  private showEmails(user: UserRow): void {
    this.router.navigate([user.id, "emails"], { relativeTo: this.route });
  }
}
