export enum KnownFormToken {
  InterviewAppointmentConfirmationForm = "InterviewAppointmentConfirmationForm",
  InterviewAppointmentProposalForm = "InterviewAppointmentProposalForm",
  InterviewAppointmentScheduleForm = "InterviewAppointmentScheduleForm",
  InterviewAppointmentScheduleSingleDateForm = "InterviewAppointmentScheduleSingleDateForm",
  MatchingAgreementForm = "MatchingAgreementForm",
  ShippingInformationForm = "ShippingInformationForm",
  SubmissionDate = "SubmissionDate",
  CandidateAllowence = "CandidateAllowence", // Warning: Renaming requires a major database migration
  RecruitingConcept = "RecruitingConcept",
  TravelBookingForm = "TravelBookingForm",
  QualificationEvaluationConfiguration = "QualificationEvaluationConfiguration",
  QualificationEvaluationRequest = "QualificationEvaluationRequest",
  QualificationEvaluationReceived = "QualificationEvaluationReceived",
}
