import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Params,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { UserPermission } from "@ankaadia/ankaadia-shared";
import { AuthService } from "@auth0/auth0-angular";
import { Observable, combineLatest, concatMap, filter, iif, map, of } from "rxjs";
import { SettingsService } from "../services/settings.service";

@Injectable({ providedIn: "root" })
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly authSvc: AuthService,
    private readonly settings: SettingsService,
    private readonly router: Router
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.checkPermission(childRoute.data.permissions, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.checkPermission(route.data.permissions, state);
  }

  private checkPermission(
    requiredPermissions: UserPermission[],
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return combineLatest([this.settings.isAuthorized$, this.settings.isAuthenticated$])
      .pipe(
        map((observeStatus) => {
          const authenticated = observeStatus[1];
          const authorized = observeStatus[0];
          if (!authenticated) {
            const email = state.root.queryParams.email ?? undefined;
            const queryParams: Params = { target: state.url, email: email };
            return of(this.router.createUrlTree(["/login"], { queryParams: queryParams }));
          }
          if (authenticated && !authorized) {
            return this.settings.isAuthorized$.pipe(
              concatMap((x) =>
                iif(
                  () => !x,
                  this.authSvc.user$.pipe(map((user) => (user.sub.startsWith("auth0|") ? true : "sso"))),
                  of(x)
                )
              ),
              filter((y) => y !== false),
              concatMap((value) =>
                iif(
                  () => value != "sso",
                  this.settings.isAuthorized$.pipe(
                    filter((y) => !!y),
                    map((x) =>
                      x && this.settings.hasAnyPermission(requiredPermissions)
                        ? true
                        : this.router.parseUrl("/accessdenied")
                    )
                  ),
                  of("sso")
                )
              ),
              map((value) =>
                value == "sso"
                  ? this.router.parseUrl(
                      `/sso-link-account-afterlogin?redirectTo=${encodeURIComponent(window.location.href)}`
                    )
                  : value === true
                    ? true
                    : <UrlTree>value
              )
            );
          }
          if (authorized && this.settings.hasAnyPermission(requiredPermissions)) {
            return of(true);
          } else {
            return of(this.router.parseUrl("/accessdenied"));
          }
        })
      )
      .pipe(concatMap((x) => x));
  }
}
