import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { emailPattern } from "@ankaadia/ankaadia-shared";
import { MessagesReception, Organization, StaticDataType, UserInput, UserRole } from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { Observable, combineLatest, finalize, map, tap } from "rxjs";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { MessageService } from "../../message/message.service";
import { OrganizationsService } from "../../organizations/organizations.service";
import { UserConsentService } from "../../user-consent/user-consent.service";
import { UsersService } from "../users.service";

interface RoleType {
  name: string;
  code: UserRole;
}

@Component({
  selector: "app-user-dialog",
  templateUrl: "./user-dialog.component.html",
})
export class UserDialogComponent implements OnInit {
  protected user: UserInput;
  form: FormGroup;
  roleList: RoleType[];
  isEdit: boolean;
  isLicensed: boolean;
  disableSubmit = false;
  messagesReception: MessagesReception;
  msgReceptionLastValue = false;
  consentDate: Date;
  lastLoginDate$: Observable<Date>;
  readonly language = this.transloco.getActiveLang();

  readonly titles = this.staticDataService.getStaticData(StaticDataType.Title, this.language);
  readonly salutations = this.staticDataService.getStaticData(StaticDataType.Salutation, this.language);
  readonly signatureAddition$ = this.staticDataService.getStaticData(StaticDataType.SignatureAddition, this.language);
  readonly languages = this.userSvc.getAvailableCommunicationLanguagesTranslated(false, this.language);
  readonly filterListUnlicensed = [
    UserRole.PartnerAdministrator,
    UserRole.ContentAdministrator,
    UserRole.ProcessAdministrator,
    UserRole.CandidateManager,
  ];

  private organization: Organization;
  @Output() readonly closed = new EventEmitter<boolean>();

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly fb: FormBuilder,
    private readonly userSvc: UsersService,
    private readonly enumPipe: EnumPipe,
    private readonly messageService: MessageService,
    private readonly transloco: TranslocoService,
    private readonly userConsentService: UserConsentService,
    private readonly organizationsService: OrganizationsService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      title: [null],
      salutation: [null, Validators.required],
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, Validators.pattern(emailPattern)])],
      phoneNumber: [null, Validators.maxLength(50)],
      mobileNumber: [null, Validators.maxLength(50)],
      position: [null],
      signatureAddition: [null],
      userRoles: [null, Validators.required],
      language: [null, Validators.required],
    });
  }

  private setFormValue(): void {
    this.form.reset();
    this.form.setValue({
      title: this.user.title,
      salutation: this.user.salutation,
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      email: this.user.email,
      phoneNumber: this.user.phoneNumber,
      userRoles: this.isLicensed
        ? this.user.userRoles.map((role) => this.mapRole(role))
        : this.user.userRoles
            .map((role) => this.mapRole(role))
            .filter((role) => !this.filterListUnlicensed.includes(role.code)),
      language: this.user.language,
      position: this.user.position,
      signatureAddition: this.user.signatureAddition,
      mobileNumber: this.user.mobileNumber,
    });
  }

  private setUserValue(): void {
    this.user.title = this.form.value.title;
    this.user.salutation = this.form.value.salutation;
    this.user.firstname = this.form.value.firstname;
    this.user.lastname = this.form.value.lastname;
    this.user.email = this.form.value.email;
    this.user.phoneNumber = this.form.value.phoneNumber;
    this.user.userRoles = this.form.value.userRoles.map((x) => (x as RoleType).code);
    this.user.language = this.form.value.language;
    this.user.position = this.form.value.position;
    this.user.signatureAddition = this.form.value.signatureAddition;
    this.user.mobileNumber = this.form.value.mobileNumber;
  }

  private setRoleList(): void {
    const roleList = this.userSvc.getPossibleUserRoles().map((role) => this.mapRole(role));
    this.roleList = this.isLicensed
      ? roleList
      : roleList.filter((role) => !this.filterListUnlicensed.includes(role.code));
  }

  private mapRole(role: UserRole): RoleType {
    return { name: this.enumPipe.transform(role, "UserRole"), code: role };
  }

  addUser(organization: Organization): void {
    this.organization = organization;
    this.organizationsService.isLicensed(organization.id).subscribe((result) => {
      this.isLicensed = result;
      this.setRoleList();
    });

    this.isEdit = false;
    this.msgReceptionLastValue = true;
    this.form.reset();
    this.form.controls.language.setValue("DE");
  }

  editUser(userId: string, organization: Organization, auth0Id: string): Observable<boolean> {
    this.organization = organization;
    this.isEdit = true;

    return combineLatest([
      this.userSvc.getUser(userId, organization.id),
      this.userSvc.getMessagesReception(false, userId, organization.id),
      this.userConsentService.getConsentData(organization.id, auth0Id),
      this.organizationsService.isLicensed(organization.id),
    ]).pipe(
      tap(([user, msgReception, consentDate, isLicensed]) => {
        this.messagesReception = msgReception;
        this.msgReceptionLastValue = msgReception?.enabled;
        this.consentDate = consentDate;
        this.lastLoginDate$ = this.userSvc.getLastLoginDate(user.auth0Id, user.organizationId);
        this.isLicensed = isLicensed;
        this.setRoleList();
        this.user = new UserInput();
        Object.assign(this.user, user);
        delete this.user["__typename"];
        this.setFormValue();
      }),
      map((val) => (val ? true : false))
    );
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.disableSubmit = true;
      if (!this.isEdit) {
        this.user = new UserInput();
        this.setUserValue();
        this.userSvc
          .addUser(this.user, this.organization.id)
          .pipe(finalize(() => (this.disableSubmit = false)))
          .subscribe((val) => {
            this.closed.emit(val != null);
            this.messageService.add({
              severity: "success",
              summary: translate("user.created.title"),
              detail: translate("user.created.message", val),
            });
            this.userSvc
              .toggleMessagesReception(false, val.id, this.user.organizationId, this.msgReceptionLastValue)
              .subscribe(() => (this.messagesReception = null));
          });
      } else {
        this.setUserValue();
        this.userSvc
          .updateUser(this.user)
          .pipe(finalize(() => (this.disableSubmit = false)))
          .subscribe((val) => {
            this.closed.emit(val != null);
            this.messageService.add({
              severity: "success",
              summary: translate("user.updated.title"),
              detail: translate("user.updated.message", val),
            });
          });
        if (this.messagesReception?.enabled != this.msgReceptionLastValue) {
          this.userSvc.toggleMessagesReception(false, this.user.id, this.user.organizationId).subscribe(() => {
            this.messagesReception = null;
            this.userSvc.messagesReceptionChanged.next();
          });
        }
      }
    }
  }

  toggleMsgReception(update: { value: boolean; target: EventTarget }): void {
    this.msgReceptionLastValue = update.value;
    this.form.markAsDirty();
  }

  close(): void {
    this.messagesReception = null;
    this.closed.emit(false);
  }
}
