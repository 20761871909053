<app-table
  *transloco="let t"
  [items]="documentSets"
  [columns]="columns"
  [title]="t('customDocumentTypes.title')"
  [newOperations]="newOperations"
  [tableOperations]="tableOperations"
  [(showSidebar)]="showDialog"
  [warningMessage]="warningMessage"
>
  <app-custom-document-type-dialog
    *ngIf="showDialog"
    [document]="selectedDoc"
    [availableFileTypes]="availableFileTypes"
    [documentSets]="documentSets"
    [staticTypeNames]="staticTypeNames"
    [staticFileNames]="staticFileNames"
    [isEdit]="isEdit"
    (saved)="save($event)"
    (closed)="close()"
  ></app-custom-document-type-dialog>
</app-table>
