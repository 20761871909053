<app-dashboard-block-layout [label]="label" [labelIcon]="labelIcon">
  <div class="grid grid-nogutter align-items-center text-center">
    <div class="col">
      <i class="icon pi {{ icon }}"></i>
    </div>

    <div class="col">
      <span class="amount">{{ amount ?? "&nbsp;" }}</span>
    </div>
  </div>
</app-dashboard-block-layout>
