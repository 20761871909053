import { Injectable } from "@angular/core";
import {
  ContractTemplate,
  ContractTemplateDeleteInput,
  ContractTemplateForOverviewFragment,
  ContractTemplateForSelectionFragment,
  DeleteContractTemplateGQL,
  GetAllContractTemplatesForSelectionGQL,
  GetAllContractTemplatesGQL,
  GetContractTemplateGQL,
  SetContractTemplateGQL,
} from "@ankaadia/graphql";
import { clone } from "lodash";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ContractTemplateService {
  constructor(
    private readonly contractTemplateGetAll: GetAllContractTemplatesGQL,
    private readonly contractTemplateForSelection: GetAllContractTemplatesForSelectionGQL,
    private readonly contractTemplateGet: GetContractTemplateGQL,
    private readonly contractTemplateSet: SetContractTemplateGQL,
    private readonly contractTemplateDelete: DeleteContractTemplateGQL
  ) {}

  getAllForList(organizationId: string): Observable<ContractTemplateForOverviewFragment[]> {
    return this.contractTemplateGetAll
      .watch({ input: { organizationId } })
      .valueChanges.pipe(map((result) => result.data.getAllContractTemplates));
  }

  getForSelection(
    organizationId: string,
    immigrationCountry: string,
    useCache = true
  ): Observable<ContractTemplateForSelectionFragment[]> {
    return this.contractTemplateForSelection
      .fetch(
        { input: { organizationId, country: immigrationCountry } },
        { fetchPolicy: useCache ? "cache-first" : "network-only" }
      )
      .pipe(map((result) => result.data.getAllContractTemplates));
  }

  get(id: string, organizationId: string): Observable<ContractTemplate> {
    return this.contractTemplateGet
      .fetch({ input: { id, organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((result) => result.data.getContractTemplate));
  }

  set(contractTemplate: ContractTemplate): Observable<ContractTemplate> {
    const input = clone(contractTemplate);
    return this.contractTemplateSet.mutate({ input: input }).pipe(map((result) => result.data.setContractTemplate));
  }

  delete(template: ContractTemplate): Observable<boolean> {
    const input = new ContractTemplateDeleteInput();
    input.id = template.id;
    input._etag = template._etag;
    input.organizationId = template.organizationId;
    return this.contractTemplateDelete
      .mutate(
        { input: input },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getAllContractTemplates: (refs, { readField }) =>
                  refs.filter((ref) => readField("id", ref) !== input.id),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteContractTemplate.status));
  }
}
