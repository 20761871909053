import { Component, Input } from "@angular/core";

@Component({
  selector: "app-dashboard-counter",
  templateUrl: "./dashboard-counter.component.html",
  styleUrls: ["./dashboard-counter.component.scss"],
})
export class DashboardCounterComponent {
  @Input()
  label: string;

  @Input()
  labelIcon: string;

  @Input()
  icon: string;

  @Input()
  amount: number;
}
