<p-sidebar [(visible)]="showSidebar" styleClass="p-sidebar-lg sidebar-with-table">
  <app-notification-list-dialog
    *ngIf="showSidebar"
    [notifications]="notifications"
    [since]="since"
    [settings]="settings"
    [isBusy]="isBusy"
    (settingsChanged)="updateSettings($event)"
    (notificationCleared)="clearNotification($event)"
    (allNotificationsCleared)="clearAllNotifications()"
    (activityLogOpened)="openActivityLog()"
    (closed)="close()"
  ></app-notification-list-dialog>
</p-sidebar>
