import moment from "moment";

export class WorkExperienceDuration {
  private years = 0;
  private months = 0;
  private days = 0;
  private static readonly daysToEqualAMonth = 30;

  // "MONTH" --> Dates are stored as the 1st of the month (e.g. 2021-01-01)
  // "DAY" --> Dates are stored with any day of the month (e.g. 2021-01-15)
  // Logic for a full work experience month is: Calendar month + fallback 30 days
  static fromStartAndEndDate(
    experienceStartDate: Date,
    experienceEndDate: Date | null,
    workExperiencePrecission: "DAY" | "MONTH" | null
  ): WorkExperienceDuration {
    workExperiencePrecission ??= "MONTH";

    const startDate = moment.utc(experienceStartDate);
    let endDate = experienceEndDate ? moment.utc(experienceEndDate) : moment.utc(Date.now());

    if (workExperiencePrecission === "DAY" && this.isEndOfMonth(endDate)) {
      endDate = endDate.add({ days: 1 }); //treat end of month same as beginning of next month...
    }

    const { calenderMonths, remainingDays } = this.getCalenderMonthsAndRemainingDays(startDate, endDate);

    return this.calculateWorkExperience(calenderMonths, remainingDays);
  }

  static fromDuration(years: number, months: number): WorkExperienceDuration {
    const duration = new WorkExperienceDuration();
    duration.years = years;
    duration.months = months;
    return duration;
  }

  static zero(): WorkExperienceDuration {
    return new WorkExperienceDuration();
  }

  add(duration: WorkExperienceDuration): this {
    const totalMonths = this.asMonths() + duration.asMonths();
    const totalDays = this.days + duration.days;

    const wokExperienceDuration = WorkExperienceDuration.calculateWorkExperience(totalMonths, totalDays);

    this.years = wokExperienceDuration.years;
    this.months = wokExperienceDuration.months;
    this.days = wokExperienceDuration.days;
    return this;
  }

  asMonths(): number {
    return this.years * 12 + this.months;
  }

  asMonthAndYears(): { years: number; months: number } {
    const months = this.asMonths();
    return {
      years: Math.floor(months / 12),
      months: Math.round(months % 12),
    };
  }

  private static getCalenderMonthsAndRemainingDays(
    startDate: moment.Moment,
    endDate: moment.Moment
  ): { calenderMonths: number; remainingDays: number } {
    const calenderMonthStartDate = this.getStartOfMonth(startDate, true);
    const calenderMonthEndDate = this.getStartOfMonth(endDate, false);

    const calenderMonths = calenderMonthEndDate.diff(calenderMonthStartDate, "months");
    const daysBetweenStartAndNextCalenderMonthStart = calenderMonthStartDate.diff(startDate, "days");
    const daysBetweenEndAndPreviousCalenderMonthStart = endDate.diff(calenderMonthEndDate, "days");

    const remainingDays = daysBetweenStartAndNextCalenderMonthStart + daysBetweenEndAndPreviousCalenderMonthStart;
    return { calenderMonths, remainingDays };
  }

  private static calculateWorkExperience(calenderMonths: number, days: number): WorkExperienceDuration {
    const { months, remainingDays } = this.calculateMonthAndRemainingDaysFromDays(days);

    const totalMonths = calenderMonths + months;

    const duration = new WorkExperienceDuration();
    duration.days = remainingDays;
    duration.months = totalMonths % 12;
    duration.years = Math.floor(totalMonths / 12);
    return duration;
  }

  private static calculateMonthAndRemainingDaysFromDays(days: number): { months: number; remainingDays: number } {
    return {
      months: Math.floor(days / this.daysToEqualAMonth),
      remainingDays: days % this.daysToEqualAMonth,
    };
  }

  private static isEndOfMonth(date: moment.Moment): boolean {
    const current = moment.utc(date).date();
    const startOfMonth = moment.utc(date).endOf("month").date();
    return current === startOfMonth;
  }

  private static getStartOfMonth(date: moment.Moment, nextMonth: boolean): moment.Moment {
    let firstDayOfMonth = moment.utc(date).startOf("month");
    if (date.date() !== firstDayOfMonth.date()) {
      firstDayOfMonth = moment
        .utc(date)
        .add({ month: nextMonth ? 1 : 0 })
        .startOf("month");
    }
    return firstDayOfMonth;
  }
}
