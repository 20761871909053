import { Injectable } from "@angular/core";
import { CreateTranslationProposalGQL } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { isEmpty } from "lodash";
import { DialogService } from "primeng/dynamicdialog";
import { Observable, Subscription, map } from "rxjs";
import { ClickCaptureService } from "../../shared/click-capture/click-capture.service";
import { SidebarAdditionalComponentService } from "../../shared/primeng/sidebar-additional-component/sidebar-additional-component.service";
import { MessageService } from "../message/message.service";
import { TranslationProposalButtonComponent } from "./translationProposalButton/translationProposalButton.component";
import { TranslationProposalGatheringComponent } from "./translationProposalGatheringComponent/translationProposalGathering.component";

export interface ITranslationProposal {
  textToCorrect: string;
  translationProposal: string;
  reasonForCorrection?: string;
}

@Injectable({ providedIn: "root" })
export class TranslationProposalService {
  private _isOn = false;
  private _canBeActivated = true;

  get canBeActivated(): boolean {
    return this._canBeActivated;
  }

  private subscription: Subscription;

  get isOn(): boolean {
    return this._isOn;
  }

  toggle(event: EventTarget): void {
    if (this.isOn) {
      this.turnOff();
    } else {
      this.turnOn(event);
    }
  }

  turnOn(target: EventTarget): void {
    if (!this._isOn && this.canBeActivated) {
      this.clickCaptureService.enableAllFormControlsAtHovering = true;
      this.clickCaptureService.tabClickEnabled = false;
      this.clickCaptureService.buttonClickEnabled = false;
      this.subscription = this.clickCaptureService.elementClicked
        .pipe()
        .subscribe((element) => this.handleElementClicked(element));
      this._isOn = true;
      this.clickCaptureService.turnOn(target);
    }
  }

  turnOff(): void {
    if (this._isOn) {
      this.subscription?.unsubscribe();
      this.subscription = null;
      this.clickCaptureService.turnOff();
      this._isOn = false;
    }
  }

  constructor(
    private readonly clickCaptureService: ClickCaptureService,
    private readonly dynamicDialogService: DialogService,
    private readonly transloco: TranslocoService,
    private readonly createTranslationProposalGQL: CreateTranslationProposalGQL,
    private readonly messageService: MessageService,
    private readonly additionalComponentService: SidebarAdditionalComponentService
  ) {
    this.additionalComponentService.registerComponentType(TranslationProposalButtonComponent);
    // in order to avoid mixture with other service
    this.clickCaptureService.onStateChanged.subscribe((value) => {
      if (!this.isOn && value) {
        this._canBeActivated = false;
      } else {
        this._canBeActivated = true;
      }
    });
  }

  createProposal(
    language: string,
    elementPath: string,
    textToCorrect: string,
    translationProposal: string,
    reasonForCorrection?: string
  ): Observable<boolean> {
    return this.createTranslationProposalGQL
      .mutate({
        input: {
          language: language,
          elementPath: elementPath,
          textToCorrect: textToCorrect,
          translationProposal: translationProposal,
          reasonForCorrection: reasonForCorrection,
        },
      })
      .pipe(map((x) => x.data.createTranslationProposal.status));
  }

  handleElementClicked(element: HTMLElement): void {
    if (element?.innerText?.length > 0) {
      this.clickCaptureService.suspend();
      this.dynamicDialogService
        .open(TranslationProposalGatheringComponent, {
          header: this.transloco.translate("translationProposal.gatheringComponent.header"),
          closable: false,
          closeOnEscape: false,
          dismissableMask: true,
          maximizable: false,
          appendTo: this.getElementToAppend(element),
          width: "60%",
          data: {
            textToTranslate: element.innerText,
          },
        })
        .onClose.subscribe((result: ITranslationProposal) => {
          this.clickCaptureService.resume();
          if (result) {
            this.createProposal(
              this.transloco.getActiveLang(),
              this.buildPath(element),
              result.textToCorrect,
              result.translationProposal,
              result.reasonForCorrection
            ).subscribe(() => {
              this.turnOff();
              this.messageService.add({
                severity: "success",
                summary: this.transloco.translate("translationProposal.success"),
              });
            });
          }
        });
    }
  }

  private getElementToAppend(element: Element): Element {
    let lastElement: Element = element;
    while (element && !element.className.includes("layout-wrapper")) {
      lastElement = element;
      element = element.parentElement;
    }
    return element ?? lastElement;
  }

  private buildPath(element: HTMLElement): string {
    const path: string[] = [];
    let i = 0;
    while (element && i++ < 12) {
      path.push(`${element.tagName}${!isEmpty(element.id) ? "(" + element.id + ")" : ""}`);
      element = element.parentElement;
    }
    return path.reverse().join(".");
  }
}
