import { v4 as uuidv4 } from "uuid";

export function encodeUserRef(userOrCandidateId: string, organizationId: string): string {
  return `${userOrCandidateId}_${organizationId}_${uuidv4()}`;
}

export function decodeUserRef(
  userRef: string
): { userOrCandidateId: string; organizationId: string; randomGuid: string } | null {
  const splitted = userRef?.split("_") ?? null;
  return splitted != null
    ? { userOrCandidateId: splitted[0], organizationId: splitted[1], randomGuid: splitted[2] }
    : null;
}
