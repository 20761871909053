import { AnimationEvent } from "@angular/animations";
import { Directive, Input, OnInit, Optional } from "@angular/core";
import { MultiSelect } from "primeng/multiselect";
import { EditableColumn, Table } from "primeng/table";
import { Subject, take } from "rxjs";

/**
 * PrimeNG's `pEditableColumn` can only focus HTML elements on cell click.
 * This teaches it to focus multi-selects automatically.
 */
@Directive({ selector: "p-multiSelect" })
export class MultiSelectEditableColumnAutoFocusDirective implements OnInit {
  private onOverlayAnimationStartSource: MultiSelect["onOverlayAnimationStart"];
  private readonly onHide = new Subject<AnimationEvent>();

  @Input()
  noAutoFocus: boolean;

  constructor(
    private readonly multiSelect: MultiSelect,
    @Optional() private readonly editableColumn: EditableColumn,
    @Optional() private readonly table: Table
  ) {}

  ngOnInit(): void {
    this.onOverlayAnimationStartSource = this.multiSelect.onOverlayAnimationStart;
    this.multiSelect.onOverlayAnimationStart = (event: AnimationEvent): void =>
      this.onOverlayAnimationStart(this.multiSelect, event);

    if (this.editableColumn && this.table && !this.noAutoFocus) {
      this.multiSelect.show();
      this.multiSelect.cd.detectChanges();
      this.onHide
        .pipe(take(1))
        .subscribe((event) => this.editableColumn.closeEditingCell(true, <Event>(<unknown>event)));
    }
  }

  private onOverlayAnimationStart(multiSelect: MultiSelect, event: AnimationEvent): void {
    if (event.toState === "void") {
      this.onHide.next(event);
    }
    return this.onOverlayAnimationStartSource.call(multiSelect, event);
  }
}
