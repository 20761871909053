import { Injectable } from "@angular/core";
import { StaticDataModel, SupportedImmigrationCountry } from "@ankaadia/graphql";
import { map, Observable } from "rxjs";
import { GetCertificateValuationAuthoritiesGQL } from "../../_generated/graphql";

export type StaticDataModelWithFederalState = StaticDataModel & { federalState: string };

@Injectable({ providedIn: "root" })
export class CertificateValuationAuthorityService {
  constructor(private readonly authoritiesGet: GetCertificateValuationAuthoritiesGQL) {}

  getCertificateValuationAuthorities(
    immigrationCountry: SupportedImmigrationCountry,
    federalStates?: string[]
  ): Observable<StaticDataModel[]> {
    return this.authoritiesGet
      .fetch({ input: { immigrationCountry, federalStates } }, { fetchPolicy: "cache-first" })
      .pipe(
        map((x) => x.data.getCertificateValuationAuthorities.map((x) => ({ label: x.title, value: x.id.toString() })))
      );
  }

  getCertificateValuationAuthoritiesWithFederalState(
    immigrationCountry: SupportedImmigrationCountry,
    federalStates?: string[]
  ): Observable<StaticDataModelWithFederalState[]> {
    return this.authoritiesGet
      .fetch({ input: { immigrationCountry, federalStates } }, { fetchPolicy: "cache-first" })
      .pipe(
        map((x) =>
          x.data.getCertificateValuationAuthorities.map((x) => ({
            label: x.title,
            value: x.id.toString(),
            federalState: x.federalState,
          }))
        )
      );
  }
}
