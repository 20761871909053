import { Pipe, PipeTransform } from "@angular/core";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";

// eslint-disable-next-line @angular-eslint/no-pipe-impure
@Pipe({ name: "dirtyControls", pure: false })
export class DirtyControlsPipe implements PipeTransform {
  transform(form: FormGroup | FormArray): object | boolean {
    return this.getAllDirtyControls(form);
  }

  private getAllDirtyControls(form: FormGroup | FormArray | AbstractControl): object | boolean {
    if (form instanceof FormGroup || form instanceof FormArray) {
      const errors = {};
      Object.keys(form.controls).forEach((key: string) => {
        const nestedErrors = this.getAllDirtyControls(form.get(key));
        if (nestedErrors instanceof Object || nestedErrors === true) {
          errors[key] = nestedErrors;
        }
      });

      if (Object.keys(errors).length > 0) {
        return errors;
      }
    }

    return form?.dirty;
  }
}
