<ng-container *transloco="let t">
  <div class="layout-sidebar computer-only" (click)="onSidebarClick()">
    <div class="layout-tabmenu">
      <ul class="layout-tabmenu-nav">
        <ng-container *ngFor="let section of sections">
          <li *ngIf="section.items.length > 0" [class.active-item]="activeTabIndex === section.index">
            <a
              href="javascript:void(0)"
              class="tabmenuitem-link"
              (click)="onTabClick($event, section.index)"
              [pTooltip]="section.label"
            >
              <i *ngIf="(section.badge | async) == null" class="pi pi-fw {{ section.icon }}"></i>
              <i
                *ngIf="(section.badge | async) != null"
                pBadge
                [value]="section.badge | async"
                class="pi pi-fw {{ section.icon }}"
              ></i>
            </a>
          </li>
        </ng-container>
      </ul>

      <div class="layout-tabmenu-contents">
        <ng-container *ngFor="let section of sections">
          <div
            *ngIf="section.items.length > 0"
            class="layout-tabmenu-content"
            [class.layout-tabmenu-content-active]="activeTabIndex === section.index"
          >
            <div class="layout-submenu-title clearfix">
              <span>{{ section.label }}</span>
              <a href="javascript:void(0)" class="menu-button pi pi-bars" (click)="closeSidebar($event)"></a>
            </div>
            <div class="layout-submenu-content">
              <app-menu [items]="section.items"></app-menu>
            </div>
          </div>
        </ng-container>
      </div>

      <ul class="layout-tabmenu-nav layout-tabmenu-nav-bottom">
        <li *ngIf="helpUrl">
          <a [href]="helpUrl" class="tabmenuitem-link" target="_blank" [pTooltip]="t('help.title')">
            <i class="pi pi-fw pi-question-circle"></i>
          </a>
        </li>

        <li>
          <a
            href="javascript:void(0)"
            class="tabmenuitem-link"
            (click)="openFeedback()"
            [pTooltip]="t('feedback.title')"
          >
            <i class="pi pi-fw pi-thumbs-up"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
