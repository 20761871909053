<p-multiSelect
  *ngIf="!to.readonly || (to.readonly && !to.readonlyProxy)"
  [placeholder]="to.placeholder"
  [options]="to.options | formlySelectOptions: field | async"
  [formControl]="$any(formControl)"
  [showToggleAll]="to.showToggleAll != null ? to.showToggleAll : true"
  [formlyAttributes]="field"
  [readonly]="to.readonly"
  [emptyValue]="to.emptyValue"
></p-multiSelect>

<p *ngIf="to.readonly && to.readonlyProxy">
  {{ getText(formControl.value, to.options | formlySelectOptions: field | async) }}
</p>
