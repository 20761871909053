import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Dropdown } from "primeng/dropdown";
import { StaticDataModel } from "@ankaadia/graphql";
import { translate } from "@ngneat/transloco";
import { ConfirmationService } from "primeng/api";
import { v4 as uuidv4 } from "uuid";

export interface SelectedStaticDataItem {
  value: string;
  overwriteExisting: boolean;
}

@Component({
  selector: "app-static-data-selector",
  templateUrl: "./static-data-selector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticDataSelectorComponent {
  protected readonly dialogKey = uuidv4();
  protected overwriteExisting = false;

  @Input()
  options: StaticDataModel[];

  @Output()
  readonly selected = new EventEmitter<SelectedStaticDataItem>();

  @ViewChild(Dropdown)
  dropdown: Dropdown;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly confirmationService: ConfirmationService
  ) {}

  protected apply(value: string): void {
    this.dropdown.hide();
    if (!value) {
      return;
    }

    const label = this.options.find((option) => option.value === value).label;
    const messageKey = this.overwriteExisting
      ? "documents.staticDataSelector.confirmation.messageOverwrite"
      : "documents.staticDataSelector.confirmation.message";

    this.confirmationService.confirm({
      target: this.elementRef.nativeElement,
      message: translate(messageKey, { label }),
      header: translate("documents.staticDataSelector.confirmation.title"),
      icon: "pi pi-exclamation-triangle",
      key: this.dialogKey,
      accept: () => this.selected.emit({ value, overwriteExisting: this.overwriteExisting }),
    });
  }

  show(): void {
    this.dropdown.updateSelectedOption(null);
    this.overwriteExisting = false;
    this.dropdown.show();
  }
}
