import { Injectable } from "@angular/core";
import { DefaultSpecific } from "../default/default-specifics";
import { SupportedTaskTypes } from "../organization-specific";
import { uniq } from "lodash";

@Injectable({ providedIn: "root" })
export class VidactaSpecific extends DefaultSpecific {
  override showCandidateFunctionFilter(): boolean {
    return true;
  }

  override getSupportedTaskTypes(): SupportedTaskTypes[] {
    return uniq(super.getSupportedTaskTypes().concat(["ProcessTaskAutoOperationModel"]));
  }
}
