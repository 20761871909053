import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StaticDataPipe } from "./static-data.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [StaticDataPipe],
  providers: [StaticDataPipe],
  exports: [StaticDataPipe],
})
export class StaticDataModule {}
