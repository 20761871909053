import { Injectable } from "@angular/core";
import { Document } from "@ankaadia/graphql";
import { DocumentsService } from "./documents.service";
import { map, merge, Observable } from "rxjs";
import { first, groupBy, uniq, values } from "lodash";
import {
  RequestBufferServiceBase,
  IdentifiableResult,
  IdentifiableRequest,
} from "../../shared/request-buffer/request-buffer.service";

type DocumentRequest = Pick<Document, "type" | "organizationId" | "candidateId" | "familyMemberId">;

function createRequestGroupKey(request: DocumentRequest): string {
  return `${request.organizationId}-${request.candidateId}-${request.familyMemberId}`;
}

@Injectable({ providedIn: "root" })
export class DocumentRequestBufferService extends RequestBufferServiceBase<DocumentRequest, Document> {
  constructor(documentService: DocumentsService) {
    super({
      bufferInMilliseconds: 200,
      performRequests(requests: IdentifiableRequest<DocumentRequest>[]): Observable<IdentifiableResult<Document>[]> {
        const groupedRequests = values(groupBy(requests, (request) => createRequestGroupKey(request)));
        const observables$ = groupedRequests.map((requests) => {
          const documentTypes = uniq(requests.map(({ type }) => type));
          const { organizationId, candidateId, familyMemberId } = first(requests);

          return documentService.getByTypes(documentTypes, organizationId, candidateId, familyMemberId).pipe(
            map((documents) =>
              requests.map((request) => ({
                requestId: request.requestId,
                result: documents.find((document) => document.type === request.type) ?? null,
              }))
            )
          );
        });

        return merge(...observables$);
      },
    });
  }
}
