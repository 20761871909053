import { NgModule } from "@angular/core";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { SharedModule } from "../../shared/shared.module";
import { DocumentTemplateModule } from "../document-template/document-template.module";
import { ProfessionModule } from "../profession/profession.module";
import { CandidateFilesComponent } from "./candidate-files/candidate-files.component";
import { AiResultIndicatorComponent } from "../document-dropzone/ai-result-indicator/ai-result-indicator.component";
import { CircularProgressIndicatorComponent } from "../document-dropzone/circular-progress-indicator/circular-progress-indicator.component";
import { CustomDocumentTypeDialogComponent } from "./custom-document-types/custom-document-type-dialog/custom-document-type-dialog.component";
import { CustomDocumentTypesComponent } from "./custom-document-types/custom-document-types.component";
import { DocumentCriteriaConfigComponent } from "./document-criteria-config/document-criteria-config.component";
import { DocumentCriterionLabelComponent } from "./document-criterion-label/document-criterion-label.component";
import { DocumentCriterionSelectorComponent } from "./document-criterion-selector/document-criterion-selector.component";
import { DocumentEditDialogComponent } from "./document-edit-dialog/document-edit-dialog.component";
import { DocumentOverviewComponent } from "./document-overview/document-overview.component";
import { DocumentStatusIconPipe } from "./document-overview/document-overview.component.pipe";
import { DocumentPreviewDialogComponent } from "./document-preview-dialog/document-preview-dialog.component";
import { DocumentPropertiesComponent } from "./document-properties-component/document-properties.component";
import { DocumentSelectorFilterPipe } from "./document-selector-filter/document-selector-filter.pipe";
import { DocumentSelectorComponent } from "./document-selector/document-selector.component";
import { DocumentSetSelectorComponent } from "./document-set-selector/document-set-selector.component";
import { DocumentTableComponent } from "./document-table/document-table.component";
import { DocumentsComponent } from "./documents.component";
import { FreeFormatDocumentSelectorComponent } from "./free-format-document-selector/free-format-document-selector.component";
import { DocumentPreviewService } from "./document-preview-dialog/document-preview.service";
import { DocumentDropZoneTableComponent } from "../document-dropzone/document-dropzone-table.component";
import { DocumentDropZoneComponent } from "../document-dropzone/document-dropzone.component";
import { DropZoneDirective } from "../document-dropzone/dropzone.directive";
import { HoverDirective } from "../document-dropzone/hover.directive";
import { ResizeDirective } from "../document-dropzone/resize.directive";
import { StaticDataSelectorComponent } from "../document-dropzone/static-data-selector/static-data-selector.component";
import { StaticDataMultiSelectorComponent } from "../document-dropzone/static-data-multi-selector/static-data-multi-selector.component";

@NgModule({
  imports: [SharedModule, ProfessionModule, NgxDocViewerModule, DocumentTemplateModule],
  providers: [DocumentPreviewService],
  declarations: [
    StaticDataSelectorComponent,
    StaticDataMultiSelectorComponent,
    AiResultIndicatorComponent,
    CircularProgressIndicatorComponent,
    DocumentEditDialogComponent,
    DocumentPreviewDialogComponent,
    DocumentSelectorComponent,
    FreeFormatDocumentSelectorComponent,
    DocumentSetSelectorComponent,
    DocumentTableComponent,
    CandidateFilesComponent,
    DocumentsComponent,
    DocumentOverviewComponent,
    DocumentStatusIconPipe,
    DocumentCriteriaConfigComponent,
    DocumentCriterionSelectorComponent,
    DocumentCriterionLabelComponent,
    DocumentSelectorFilterPipe,
    CustomDocumentTypesComponent,
    CustomDocumentTypeDialogComponent,
    DocumentPropertiesComponent,
    DocumentDropZoneComponent,
    DropZoneDirective,
    HoverDirective,
    ResizeDirective,
    DocumentDropZoneTableComponent,
  ],
  exports: [
    DocumentsComponent,
    DocumentTableComponent,
    DocumentSetSelectorComponent,
    DocumentPreviewDialogComponent,
    DocumentSelectorComponent,
    FreeFormatDocumentSelectorComponent,
    CandidateFilesComponent,
    DocumentEditDialogComponent,
    DocumentOverviewComponent,
    DocumentStatusIconPipe,
    CustomDocumentTypesComponent,
    CustomDocumentTypeDialogComponent,
    DocumentPropertiesComponent,
    DocumentSelectorFilterPipe,
    DocumentDropZoneComponent,
    DropZoneDirective,
    HoverDirective,
  ],
})
export class DocumentsModule {}
