import { Component, Input } from "@angular/core";

@Component({
  selector: "app-circular-progress-indicator",
  templateUrl: "./circular-progress-indicator.component.html",
  styleUrls: ["./circular-progress-indicator.component.scss"],
})
export class CircularProgressIndicatorComponent {
  @Input() size = 50;
  @Input() strokeWidth = 10;
  @Input() progress = 0;
  @Input() failed = false;

  get radius(): number {
    return (this.size - this.strokeWidth) / 2;
  }

  get circumference(): number {
    return 2 * Math.PI * this.radius;
  }

  get center(): number {
    return this.size / 2;
  }

  get viewBox(): string {
    return `0 0 ${this.size} ${this.size}`;
  }

  get dashOffset(): string {
    const offset = this.circumference - (this.progress / 100) * this.circumference;
    return `${offset}px`;
  }
}
