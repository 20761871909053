import { Injectable } from "@angular/core";
import { CandidateForViewFragment, StaticDataType } from "@ankaadia/graphql";
import {
  ICandidatePathOfRecognition,
  ICandidateQualificationMeasure,
  ICountrySpecificRequiredDocumentsFields,
  MigrationSpecifics,
} from "../migration-specifics.model";

@Injectable({ providedIn: "root" })
export class AustriaSpecifics implements MigrationSpecifics {
  getMigrationCountrySpecificRequiredDocumentsFields(): ICountrySpecificRequiredDocumentsFields[] {
    return [
      //order maters
      {
        labelKey: "targetRecognition.title",
        name: "targetRecognition",
        staticDataType: StaticDataType.TargetRecognition,
      },
      {
        labelKey: "pathOfRecognition.title",
        name: "pathOfRecognition",
        staticDataType: StaticDataType.PathOfRecognition,
      },
      {
        labelKey: "legalBasis.title",
        name: "legalBasis",
        staticDataType: StaticDataType.RecognitionType,
      },
    ];
  }

  getRecognitionPath(candidate: CandidateForViewFragment): ICandidatePathOfRecognition {
    const recognitionPath = candidate?.migration?.at?.recognitionPath;
    if (!recognitionPath) return null;
    return { current: recognitionPath, alternative: undefined };
  }

  getQualificationMeasure(_candidate: CandidateForViewFragment): ICandidateQualificationMeasure {
    return null;
  }
}
