import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DirectivesModule } from "../directives/directives.module";
import { FlagModule } from "../flag/flag.module";
import { PipesModule } from "../pipes/pipes.module";
import { PrimeNGModule } from "../primeng/primeng.module";
import { TranslocoModule } from "../transloco/transloco.module";
import { TreeTableComponent } from "./tree-table.component";

@NgModule({
  imports: [CommonModule, PrimeNGModule, TranslocoModule, FlagModule, PipesModule, DirectivesModule],
  declarations: [TreeTableComponent],
  exports: [TreeTableComponent],
})
export class TreeTableModule {}
