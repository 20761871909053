export enum DocumentSelectionCriterion {
  Language = "Language",
  Profession = "Profession",
  FederalState = "FederalState",
  ImmigrationCountry = "ImmigrationCountry",
  ImmigrationAuthority = "ImmigrationAuthority",
  RecognitionAuthority = "RecognitionAuthority",
  CountryOfOrigin = "CountryOfOrigin",
  DiplomaticMission = "DiplomaticMission",
  Employer = "Employer",
  EmployerAfterRecognition = "EmployerAfterRecognition",
  Source = "Source",
  Function = "Function",
  QualificationMeasure = "QualificationMeasure",
}

interface Dependency<T> {
  parent: T;
  children: T[];
}

export function getDocumentSelectionDependencies(): Dependency<DocumentSelectionCriterion>[] {
  return [
    {
      parent: DocumentSelectionCriterion.ImmigrationCountry,
      children: [
        DocumentSelectionCriterion.Employer,
        DocumentSelectionCriterion.EmployerAfterRecognition,
        DocumentSelectionCriterion.ImmigrationAuthority,
        DocumentSelectionCriterion.RecognitionAuthority,
        DocumentSelectionCriterion.FederalState,
        DocumentSelectionCriterion.DiplomaticMission,
      ],
    },
    {
      parent: DocumentSelectionCriterion.FederalState,
      children: [DocumentSelectionCriterion.ImmigrationAuthority, DocumentSelectionCriterion.RecognitionAuthority],
    },
    {
      parent: DocumentSelectionCriterion.CountryOfOrigin,
      children: [DocumentSelectionCriterion.DiplomaticMission],
    },
  ];
}
