import { Injectable } from "@angular/core";
import { DefaultSpecific } from "../default/default-specifics";
import { SupportedTaskTypes } from "../organization-specific";
import { uniq } from "lodash";
import { WorkExperiencePrecissionEnum } from "@ankaadia/graphql";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { medwingFactory } from "./custom-formly-definition";

@Injectable({ providedIn: "root" })
export class MedwingSpecific extends DefaultSpecific {
  override getSupportedTaskTypes(): SupportedTaskTypes[] {
    return uniq(super.getSupportedTaskTypes().concat(["ProcessTaskAutoOperationModel"]));
  }

  override getCandidateProfileCustomFields(
    requiredFields?: string[],
    _workExperiencePrecission?: WorkExperiencePrecissionEnum,
    _educationPrecission?: WorkExperiencePrecissionEnum
  ): FormlyFieldConfig[] {
    return medwingFactory(this.language, this.staticDataService, requiredFields);
  }
}
