<div class="footer" *appTranslate="let t">
  <div class="footer-inner">
    <div class="logo-wrap logo-wrap-left" *ngIf="logoLeft">
      <img class="logo" [src]="logoLeft" default="/assets/blank.jpeg" />
    </div>

    <div class="logo-wrap logo-wrap-right" *ngIf="logoRight">
      <img class="logo" [src]="logoRight" default="/assets/blank.jpeg" />
    </div>
  </div>
</div>
