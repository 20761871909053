import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { DashboardBlockLayoutComponent } from "./dashboard-block-layout/dashboard-block-layout.component";
import { DashboardCounterComponent } from "./dashboard-counter/dashboard-counter.component";
import { AnkaadiaUpdatesComponent } from "./ankaadia-updates/ankaadia-updates.component";
import { DashboardListComponent } from "./dashboard-list/dashboard-list.component";
import { DashboardChartComponent } from "./dashboard-chart/dashboard-chart.component";
import { UpdateDialogComponent } from "./update-dialog/update-dialog.component";
import { DashboardComponent } from "./dashboard.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    DashboardComponent,
    DashboardBlockLayoutComponent,
    DashboardCounterComponent,
    DashboardListComponent,
    DashboardChartComponent,
    UpdateDialogComponent,
    AnkaadiaUpdatesComponent,
  ],
  exports: [DashboardComponent, UpdateDialogComponent],
})
export class DashboardModule {}
