import { Pipe, PipeTransform } from "@angular/core";
import { ProcessModelString } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";

@Pipe({ name: "processModelString" })
export class ProcessModelStringPipe implements PipeTransform {
  constructor(private readonly transloco: TranslocoService) {}

  transform(str: ProcessModelString[], language: string = this.transloco.getActiveLang()): string {
    return (str?.find((n) => n.language === language) ?? str?.find((n) => n.language === "de-DE"))?.value;
  }
}
