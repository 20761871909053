import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface Message {
  severity: "success" | "warning" | "error";
  summary: string;
  detail?: string;
}

const RESET_MESSAGE_IN_X_SECONDS = 5;

@Injectable({ providedIn: "root" })
export class MessageService {
  private timer: Timer;
  private readonly messageSubject = new BehaviorSubject<Message>(null);

  readonly message$ = this.messageSubject.asObservable();

  add(message: Message): void {
    // do nothing if the timer is paused
    if (this.timer?.paused) return;

    this.messageSubject.next(message);

    // kill the previous timer
    this.timer?.stop();

    // and start a new one
    this.timer = new Timer(() => {
      // that will reset the current message
      this.messageSubject.next(null);
    }, RESET_MESSAGE_IN_X_SECONDS * 1000);
  }

  pause(): void {
    this.timer?.pause();
  }

  resume(): void {
    this.timer?.resume();
  }
}

class Timer {
  private _start: Date;
  private _remaining: number;
  private _durationTimeoutId?: number;
  private readonly _callback: (...args: any[]) => void;
  private _done = false;

  get paused(): boolean {
    return !this._done && !this._durationTimeoutId;
  }

  constructor(callback: (...args: any[]) => void, ms: number) {
    this._callback = (): void => {
      callback();
      this._done = true;
    };
    this._remaining = ms;
    this.resume();
  }

  pause(): void {
    if (this._durationTimeoutId && !this._done) {
      this._clearTimeoutRef();
      this._remaining -= new Date().getTime() - this._start.getTime();
    }
  }

  resume(): void {
    if (!this._durationTimeoutId && !this._done) {
      this._start = new Date();
      this._durationTimeoutId = window.setTimeout(this._callback, this._remaining);
    }
  }

  stop(): void {
    this._clearTimeoutRef();
    this._done = true;
  }

  private _clearTimeoutRef(): void {
    if (this._durationTimeoutId) {
      clearTimeout(this._durationTimeoutId);
      this._durationTimeoutId = undefined;
    }
  }
}
