<ng-container *appTranslate="let t">
  <form [formGroup]="form" class="app-form" (ngSubmit)="save()">
    <p-card styleClass="flex flex-column">
      <ng-template pTemplate="header">
        <div class="pt-5 pb-4 pl-3 pr-3">
          <div class="pl-1 pr-1 pt-2 mt-1">
            <h3>{{ t("professions.my") }}</h3>
          </div>
        </div>
      </ng-template>

      <div class="flex flex-column h-full">
        <div class="app-form mb-3">
          <p-tree
            styleClass="h-full overflow-auto"
            [value]="professions"
            [filter]="true"
            [filterLocale]="language"
            [filterPlaceholder]="t('common.search')"
            selectionMode="checkbox"
            [(selection)]="professionSelection"
          ></p-tree>
        </div>

        <div class="p-fluid formgrid grid">
          <div class="field col-12 lg:col-3">
            <label for="defaultFunction">{{ t("function.default") }}</label>
            <p-dropdown
              inputId="defaultFunction"
              [options]="functions$ | async"
              formControlName="defaultFunction"
              [showClear]="true"
              [placeholder]="t('function.placeholder')"
            ></p-dropdown>
          </div>

          <div class="field col-12 lg:col-3">
            <label for="defaultProfession">{{ t("profession.default") }}</label>
            <p-dropdown
              inputId="defaultProfession"
              [options]="selectedProfessions"
              formControlName="defaultProfession"
              [showClear]="true"
              [placeholder]="t('profession.placeholder')"
            ></p-dropdown>
          </div>
        </div>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [disabled]="!form.valid || !form.dirty"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
          ></p-button>
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            (onClick)="cancel()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
