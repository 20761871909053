import { Pipe, PipeTransform } from "@angular/core";
import { translate } from "@ngneat/transloco";

@Pipe({ name: "enum" })
export class EnumPipe implements PipeTransform {
  transform<T>(
    value: T | T[],
    enumName: string,
    language?: string,
    format: "normal" | "short" = "normal",
    nullValue?: T
  ): string {
    const shorten = format === "short";
    language = language === null ? undefined : language;
    return Array.isArray(value)
      ? value.map((v) => this.transformSingle(v, enumName, language, shorten, nullValue)).join(", ")
      : this.transformSingle(value, enumName, language, shorten, nullValue);
  }

  private transformSingle<T>(value: T, enumName: string, language?: string, shorten?: boolean, nullValue?: T): string {
    if (!value && !nullValue) return null;
    return shorten
      ? translate(`enum.${enumName}.${value ?? nullValue}`, null, language).substring(0, 1)
      : translate(`enum.${enumName}.${value ?? nullValue}`, null, language);
  }
}
