<ng-container *ngIf="showInputForm">
  <div class="background">
    <app-guetegemeinschaft-organization-creation
      [message]="message"
      [buttonLabel]="buttonLabel"
      (navigatedBack)="navigatedBack()"
      (organizationCreated)="organizationCreated($event)"
    ></app-guetegemeinschaft-organization-creation>
  </div>
</ng-container>
