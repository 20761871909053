import { defaultImmigrationCountry, toRealImmigrationCountry } from "./migration-specific/immigration-country";

const CountryCityStateMapping: Record<string, Record<string, string>> = {
  DE: {
    BERLIN: "DE-BE",
    HAMBURG: "DE-HH",
    MUENCHEN: "DE-BY",
    KOELN: "DE-NW",
    "FRANKFURT-AM-MAIN": "DE-HE",
    STUTTGART: "DE-BW",
    DUESSELDORF: "DE-NW",
    LEIPZIG: "DE-SN",
    DORTMUND: "DE-NW",
    ESSEN: "DE-NW",
    BREMEN: "DE-HB",
    DRESDEN: "DE-SN",
    HANNOVER: "DE-NI",
    NUERNBERG: "DE-BY",
    DUISBURG: "DE-NW",
    BOCHUM: "DE-NW",
    WUPPERTAL: "DE-NW",
    BIELEFELD: "DE-NW",
    BONN: "DE-NW",
    MUENSTER: "DE-NW",
    MANNHEIM: "DE-BW",
    KARLSRUHE: "DE-BW",
    AUGSBURG: "DE-BY",
    WIESBADEN: "DE-HE",
    MOENCHENGLADBACH: "DE-NW",
    GELSENKIRCHEN: "DE-NW",
    AACHEN: "DE-NW",
    BRAUNSCHWEIG: "DE-NI",
    KIEL: "DE-SH",
    CHEMNITZ: "DE-SN",
    "HALLE-SAALE": "DE-ST",
    MAGDEBURG: "DE-ST",
    "FREIBURG-IM-BREISGAU": "DE-BW",
    KREFELD: "DE-NW",
    MAINZ: "DE-RP",
    LUEBECK: "DE-SH",
    ERFURT: "DE-TH",
    OBERHAUSEN: "DE-NW",
    ROSTOCK: "DE-MV",
    KASSEL: "DE-HE",
    HAGEN: "DE-NW",
    POTSDAM: "DE-BB",
    SAARBRUECKEN: "DE-SL",
    HAMM: "DE-NW",
    "LUDWIGSHAFEN-AM-RHEIN": "DE-RP",
    "MUELHEIM-AN-DER-RUHR": "DE-NW",
    OLDENBURG: "DE-NI",
    OSNABRUECK: "DE-NI",
    LEVERKUSEN: "DE-NW",
    SOLINGEN: "DE-NW",
    DARMSTADT: "DE-HE",
    HEIDELBERG: "DE-BW",
    HERNE: "DE-NW",
    NEUSS: "DE-NW",
    REGENSBURG: "DE-BY",
    PADERBORN: "DE-NW",
    INGOLSTADT: "DE-BY",
    "OFFENBACH-AM-MAIN": "DE-HE",
    FUERTH: "DE-BY",
    WUERZBURG: "DE-BY",
    HEILBRONN: "DE-BW",
    ULM: "DE-BW",
    PFORZHEIM: "DE-BW",
    WOLFSBURG: "DE-NI",
    BOTTROP: "DE-NW",
    GOETTINGEN: "DE-NI",
    REUTLINGEN: "DE-BW",
    BREMERHAVEN: "DE-HB",
    KOBLENZ: "DE-RP",
    ERLANGEN: "DE-BY",
    "BERGISCH-GLADBACH": "DE-NW",
    REMSCHEID: "DE-NW",
    JENA: "DE-TH",
    RECKLINGHAUSEN: "DE-NW",
    TRIER: "DE-RP",
    SALZGITTER: "DE-NI",
    MOERS: "DE-NW",
    SIEGEN: "DE-NW",
    HILDESHEIM: "DE-NI",
    GUETERSLOH: "DE-NW",
    KAISERSLAUTERN: "DE-RP",
    COTTBUS: "DE-BB",
    HANAU: "DE-HE",
    WITTEN: "DE-NW",
    SCHWERIN: "DE-MV",
    LUDWIGSBURG: "DE-BW",
    "ESSLINGEN-AM-NECKAR": "DE-BW",
    GERA: "DE-TH",
    ISERLOHN: "DE-NW",
    DUEREN: "DE-NW",
    TUEBINGEN: "DE-BW",
    GIESSEN: "DE-HE",
    FLENSBURG: "DE-SH",
    ZWICKAU: "DE-SN",
    RATINGEN: "DE-NW",
    LUENEN: "DE-NW",
    "VILLINGEN-SCHWENNINGEN": "DE-BW",
    KONSTANZ: "DE-BW",
    MARL: "DE-NW",
    WORMS: "DE-RP",
    MINDEN: "DE-NW",
    VELBERT: "DE-NW",
    NEUMUENSTER: "DE-SH",
    "DESSAU-ROSSLAU": "DE-ST",
    NORDERSTEDT: "DE-SH",
    DELMENHORST: "DE-NI",
    VIERSEN: "DE-NW",
    BAMBERG: "DE-BY",
    MARBURG: "DE-HE",
    RHEINE: "DE-NW",
    GLADBECK: "DE-NW",
    LUENEBURG: "DE-NI",
    WILHELMSHAVEN: "DE-NI",
    TROISDORF: "DE-NW",
    DORSTEN: "DE-NW",
    DETMOLD: "DE-NW",
    BAYREUTH: "DE-BY",
    ARNSBERG: "DE-NW",
    "CASTROP-RAUXEL": "DE-NW",
    LANDSHUT: "DE-BY",
    "BRANDENBURG-AN-DER-HAVEL": "DE-BB",
    LUEDENSCHEID: "DE-NW",
    BOCHOLT: "DE-NW",
    ASCHAFFENBURG: "DE-BY",
    CELLE: "DE-NI",
    "KEMPTEN-ALLGAEU": "DE-BY",
    AALEN: "DE-BW",
    FULDA: "DE-HE",
    LIPPSTADT: "DE-NW",
    DINSLAKEN: "DE-NW",
    HERFORD: "DE-NW",
    "RUESSELSHEIM-AM-MAIN": "DE-HE",
    KERPEN: "DE-NW",
    WEIMAR: "DE-TH",
    NEUWIED: "DE-RP",
    SINDELFINGEN: "DE-BW",
    DORMAGEN: "DE-NW",
    PLAUEN: "DE-SN",
    GREVENBROICH: "DE-NW",
    ROSENHEIM: "DE-BY",
    NEUBRANDENBURG: "DE-MV",
    HERTEN: "DE-NW",
    BERGHEIM: "DE-NW",
    FRIEDRICHSHAFEN: "DE-BW",
    "SCHWAEBISCH-GMUEND": "DE-BW",
    GARBSEN: "DE-NI",
    OFFENBURG: "DE-BW",
    WESEL: "DE-NW",
    HUERTH: "DE-NW",
    GREIFSWALD: "DE-MV",
  },
  AT: {
    WIEN: "AT-9",
    GRAZ: "AT-6",
    LINZ: "AT-4",
    SALZBURG: "AT-5",
    INNSBRUCK: "AT-7",
    KLAGENFURT: "AT-2",
    VILLACH: "AT-2",
    WELS: "AT-4",
    "ST-PÖLTEN": "AT-3",
    DORNBIRN: "AT-8",
    "WIENER-NEUSTADT": "AT-3",
    STEYR: "AT-4",
    FELDKIRCH: "AT-8",
    BREGENZ: "AT-8",
    LEONDING: "AT-4",
    KLOSTERNEUBURG: "AT-3",
    BADEN: "AT-3",
    LEOBEN: "AT-6",
    WOLFSBERG: "AT-2",
    TRAUN: "AT-4",
    EISENSTADT: "AT-1",
  },
};

export function getCityState(country: string, city: string): string {
  country = toRealImmigrationCountry(country) ?? defaultImmigrationCountry();
  country = country.toUpperCase();
  city = city.toUpperCase();
  return CountryCityStateMapping[country][city];
}
