import { Component, Input } from "@angular/core";
import type { ChartData, ChartOptions, ChartType } from "chart.js";

@Component({
  selector: "app-dashboard-chart",
  templateUrl: "./dashboard-chart.component.html",
})
export class DashboardChartComponent {
  @Input()
  label: string;

  @Input()
  labelIcon: string;

  @Input()
  type: ChartType;

  @Input()
  data: ChartData;

  @Input()
  options: ChartOptions;
}
