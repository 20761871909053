import { Injectable } from "@angular/core";
import {
  isDocumentSelectionMode,
  getFiles,
  DocumentWriteMode,
  FileAccess,
  isInternalDocument,
  isSingleDocumentSet,
} from "@ankaadia/ankaadia-shared";
import {
  Archive,
  ChangeLockModeForDocumentsByCandidateGQL,
  ChangeLockModeForDocumentsGQL,
  CreateDocumentGQL,
  DeleteDocumentGQL,
  Document,
  DocumentCreateInput,
  DocumentDeleteInput,
  DocumentFormats,
  DocumentFragmentDoc,
  DocumentGenerationMode,
  DocumentLockAllInput,
  DocumentLockInput,
  DocumentLockMode,
  DocumentMode,
  DocumentSelectionCriterion,
  DocumentTemplateMode,
  DocumentTypeDataFragment,
  DocumentUpdateInput,
  DocumentUpsertInput,
  DocumentUrl,
  GetAvailableDocumentFormatsGQL,
  GetCandidateArchiveGQL,
  GetDocumentByTypeGQL,
  GetDocumentDownloadUrlGQL,
  GetDocumentDownloadWithContainerUrlGQL,
  GetDocumentGQL,
  GetDocumentsGQL,
  GetDocumentTypesAndValidityGQL,
  GetDocumentUploadUrlGQL,
  GetDocumentUploadWithContainerUrlGQL,
  GetKnowTemplateVariablesDocumentGQL,
  GetMissingDocumentTemplatesGQL,
  GetMissingOrganizationDocumentsGQL,
  GetSharingDocumentsGQL,
  GetTaskArchiveGQL,
  ProfilePictureUpdatedGQL,
  Sharing,
  UpdateDocumentGQL,
  UpsertDocumentWithoutProcessStateChangeGQL,
  ValidateDocumentsGQL,
  GetDocumentDownloadUrlWithFileNameGQL,
  GetEffectiveDocumentAccessGQL,
  DocumentAccess,
  GetEffectiveFileAccessGQL,
  SharingFileQueryInput,
  SharingDocumentQueryInput,
  GetCreatableDocumentTypesGQL,
  GetWritableDocumentTypesGQL,
  StaticDataModel,
  CandidateDocumentListInput,
  BlobDeleteInput,
  DeleteBlobGQL,
  ConvertAndGetDocumentDownloadUrlGQL,
  DocumentLoadWithFileNameInput,
  ConvertManyDocumentsGQL,
} from "@ankaadia/graphql";
import { filter, map, Observable, of, switchMap, tap } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { CandidatesService } from "../candidates/candidates.service";
import { DocumentNotificationService } from "./document-notification.service";
import { isNil, translate } from "@ngneat/transloco";
import { MessageService } from "../message/message.service";
import { CleanDocument, DocumentForm } from "./document-form.model";
import { DocumentRequirementService } from "../document-requirements/document-requirement.service";

@Injectable({ providedIn: "root" })
export class DocumentsService {
  constructor(
    private readonly documentsGet: GetDocumentsGQL,
    private readonly documentsSharingGet: GetSharingDocumentsGQL,
    private readonly documentGet: GetDocumentGQL,
    private readonly documentGetByType: GetDocumentByTypeGQL,
    private readonly candidateArchive: GetCandidateArchiveGQL,
    private readonly taskArchive: GetTaskArchiveGQL,
    private readonly documentUploadUrl: GetDocumentUploadUrlGQL,
    private readonly documentUploadWithContainerUrl: GetDocumentUploadWithContainerUrlGQL,
    private readonly documentDownloadUrl: GetDocumentDownloadUrlGQL,
    private readonly documentDownloadUrlWithFileName: GetDocumentDownloadUrlWithFileNameGQL,
    private readonly documentDownloadWithContainerUrl: GetDocumentDownloadWithContainerUrlGQL,
    private readonly documentCreate: CreateDocumentGQL,
    private readonly documentUpdate: UpdateDocumentGQL,
    private readonly documentUpsert: UpsertDocumentWithoutProcessStateChangeGQL,
    private readonly documentDelete: DeleteDocumentGQL,
    private readonly blobDelete: DeleteBlobGQL,
    private readonly profilePictureUpdated: ProfilePictureUpdatedGQL,
    private readonly changeLockMode: ChangeLockModeForDocumentsGQL,
    private readonly changeLockModeAll: ChangeLockModeForDocumentsByCandidateGQL,
    private readonly documentValidate: ValidateDocumentsGQL,
    private readonly missingDocuments: GetMissingOrganizationDocumentsGQL,
    private readonly missingTemplates: GetMissingDocumentTemplatesGQL,
    private readonly knownVariablesDocument: GetKnowTemplateVariablesDocumentGQL,
    private readonly availableFormats: GetAvailableDocumentFormatsGQL,
    private readonly settings: SettingsService,
    private readonly candidateService: CandidatesService,
    private readonly getDocumentTypesAndValidityGQL: GetDocumentTypesAndValidityGQL,
    private readonly documentNotificationService: DocumentNotificationService,
    private readonly effectiveFileAccess: GetEffectiveFileAccessGQL,
    private readonly effectiveDocumentAccess: GetEffectiveDocumentAccessGQL,
    private readonly documentRequirementService: DocumentRequirementService,
    private readonly creatableDocumentTypes: GetCreatableDocumentTypesGQL,
    private readonly writableDocumentTypes: GetWritableDocumentTypesGQL,
    private readonly messageService: MessageService,
    private readonly documentConvert: ConvertAndGetDocumentDownloadUrlGQL,
    private readonly convertManyDocumentsGQL: ConvertManyDocumentsGQL
  ) {}

  getEffectiveDocumentAccess(input: SharingDocumentQueryInput): Observable<DocumentAccess> {
    return !input.candidateId
      ? of(DocumentAccess.Full)
      : this.effectiveDocumentAccess.fetch({ input }).pipe(map((x) => x.data.getEffectiveDocumentAccess.access));
  }

  canReadDocument(document: Document, showToastOnAccessDenied = true): Observable<boolean> {
    const { organizationId, candidateId, type } = document;
    return this.getEffectiveDocumentAccess({ organizationId, candidateId, type }).pipe(
      map((effectiveAccess) => {
        if (effectiveAccess === DocumentAccess.Full) {
          return true;
        }

        if (effectiveAccess === DocumentAccess.FullWhenNotInternal && !isInternalDocument(document)) {
          return true;
        }

        if (showToastOnAccessDenied) {
          this.messageService.add({
            severity: "error",
            summary: translate("document.error.accessDenied.title"),
            detail: translate("document.error.accessDenied.description"),
          });
        }

        return false;
      })
    );
  }

  canEditDocument(document: Document, showToastOnAccessDenied = true): Observable<boolean> {
    const validAccessTypes = [DocumentAccess.Full, DocumentAccess.FullWhenNotInternal, DocumentAccess.Metadata];
    const { organizationId, candidateId, type } = document;
    return this.getEffectiveDocumentAccess({ organizationId, candidateId, type }).pipe(
      map((effectiveAccess) => {
        if (validAccessTypes.includes(effectiveAccess)) {
          return true;
        }

        if (showToastOnAccessDenied)
          this.messageService.add({
            severity: "error",
            summary: translate("document.error.accessDenied.title"),
            detail: translate("document.error.accessDenied.description"),
          });

        return false;
      })
    );
  }

  canReadFile(input: SharingFileQueryInput, showToastOnAccessDenied = true): Observable<boolean> {
    return !input.candidateId
      ? of(true)
      : this.effectiveFileAccess.fetch({ input }).pipe(
          map((x) => x.data.getEffectiveFileAccess.access),
          map((access) => access === FileAccess.Full),
          tap((hasAccess) => {
            if (!hasAccess && showToastOnAccessDenied) {
              this.messageService.add({
                severity: "error",
                summary: translate("document.error.accessDenied.title"),
                detail: translate("document.error.accessDenied.description"),
              });
            }
          })
        );
  }

  getCreatableDocumentTypes(input: CandidateDocumentListInput): Observable<StaticDataModel[]> {
    return this.creatableDocumentTypes.fetch({ input }).pipe(map((x) => x.data.getCreatableDocumentTypes));
  }

  getWritableDocumentTypes(input: CandidateDocumentListInput): Observable<StaticDataModel[]> {
    return this.writableDocumentTypes.fetch({ input }).pipe(map((x) => x.data.getWritableDocumentTypes));
  }

  getKnownTemplateVariablesDocument(organizationId: string): Observable<string> {
    return this.knownVariablesDocument
      .fetch({ organizationId })
      .pipe(map((result) => result.data.getKnowTemplateVariablesDocument));
  }

  getMissingOrganizationDocuments(organizationId: string): Observable<string[]> {
    return this.missingDocuments
      .fetch({ organizationId })
      .pipe(map((result) => result.data.getMissingOrganizationDocuments));
  }

  getMissingDocumentTemplates(organizationId: string): Observable<string[]> {
    return this.missingTemplates
      .fetch({ organizationId })
      .pipe(map((result) => result.data.getMissingDocumentTemplates));
  }

  isReadOnly(document: Document, ignoreTouchedOnlyByCandidate = false): boolean {
    if (!isNil(document.lockState) && document.lockState !== DocumentLockMode.Unlocked) {
      return true;
    }

    if (!this.settings.isCandidate) {
      return false;
    }

    if (isNil(document.id)) {
      return false;
    }

    if (ignoreTouchedOnlyByCandidate) {
      return false;
    }

    if (document.touchedOnlyByCandidate && !this.settings.readonlyProfile) {
      return false;
    }

    return true;
  }

  createEmptyDocument(
    mode: DocumentMode,
    organizationId: string,
    candidateId: string,
    familyMemberId?: string,
    type?: string
  ): Observable<Document> {
    const isCandidateDocument = mode === DocumentMode.Candidate && !isNil(type);
    const isInternalByRequirement$ = isCandidateDocument ? this.isInternal(organizationId, type) : of(false);

    return isInternalByRequirement$.pipe(
      map((isInternalByRequirement) => ({
        id: null,
        _etag: null,
        organizationId,
        candidateId,
        familyMemberId,
        mode,
        templateMode: mode === DocumentMode.Template ? DocumentTemplateMode.Docx : null,
        isInternalByRequirement,
        displayName: null,
        changedAt: null,
        changedBy: null,
        touchedOnlyByCandidate: null,
        selectionCriteria: isDocumentSelectionMode(mode) ? [DocumentSelectionCriterion.Language] : null,
        documentSets: [],
        freeFormatFiles: [],
        type,
      }))
    );
  }

  changeLockModeForDocuments(input: DocumentLockInput): Observable<Document[]> {
    return this.changeLockMode.mutate({ input: input }).pipe(map((x) => x.data.changeLockModeForDocuments));
  }

  changeLockModeForDocumentsByCandidate(input: DocumentLockAllInput): Observable<Document[]> {
    return this.changeLockModeAll
      .mutate({ input: input })
      .pipe(map((x) => x.data.changeLockModeForDocumentsByCandidate));
  }

  getAll(
    mode: DocumentMode,
    organizationId: string,
    candidateId?: string,
    familyMemberId?: string,
    sharing?: Sharing,
    requiredDocumentSetIds?: string[],
    requiredDocumentsOrgId?: string
  ): Observable<Document[]> {
    return this.documentsGet
      .watch({
        input: {
          mode: mode,
          organizationId: organizationId,
          candidateId: candidateId,
          familyMemberId: familyMemberId,
          collectionId: sharing?.sharedCollectionId,
          collectionOrganizationId: sharing?.organizationId,
          requiredDocumentSetIds: requiredDocumentSetIds,
          requiredDocumentsOrgId: requiredDocumentsOrgId,
        },
      })
      .valueChanges.pipe(map((result) => result.data.getDocuments));
  }

  getAllTypesAndValidity(
    mode: DocumentMode,
    organizationId: string,
    candidateId?: string,
    familyMemberId?: string,
    sharing?: Sharing
  ): Observable<DocumentTypeDataFragment[]> {
    return this.getDocumentTypesAndValidityGQL
      .fetch({
        input: {
          mode: mode,
          organizationId: organizationId,
          candidateId: candidateId,
          familyMemberId: familyMemberId,
          collectionId: sharing?.sharedCollectionId,
          collectionOrganizationId: sharing?.organizationId,
        },
      })
      .pipe(map((result) => result.data.getDocuments));
  }

  getAllForSharing(organizationId: string, sharingId: string): Observable<Document[]> {
    return this.documentsSharingGet
      .fetch({ input: { organizationId: organizationId, sharingId: sharingId } })
      .pipe(map((result) => result.data.getSharingDocuments));
  }

  get(documentId: string, organizationId: string, candidateId?: string): Observable<Document> {
    return this.documentGet
      .fetch({ input: { id: documentId, organizationId: organizationId, candidateId: candidateId } })
      .pipe(map((result) => result.data.getDocument));
  }

  getByType(type: string, organizationId: string, candidateId: string, familyMemberId?: string): Observable<Document> {
    return this.documentGetByType
      .fetch({ input: { type: type, organizationId, candidateId, familyMemberId } })
      .pipe(map((result) => result.data.getDocumentByType));
  }

  validateTemplate(document: Document): Observable<boolean> {
    return this.documentValidate
      .fetch({
        input: {
          templateMode: document.templateMode,
          organizationId: document.organizationId,
          candidateId: document.candidateId,
          generationMode: document.generationMode ?? DocumentGenerationMode.OneCandidate,
          files: getFiles(document).map((f) => ({ blob: f.blob, name: f.name })),
        },
      })
      .pipe(map((x) => x.data.validateDocuments.status));
  }

  convertManyDocuments(
    language: string,
    organizationId: string,
    candidateId: string,
    familyMemberId: string,
    requiredDocumentSetsIds: string[],
    requiredDocumentOrganizationId: string
  ): Observable<{ url: string; fileName: string }> {
    return this.convertManyDocumentsGQL
      .fetch({
        input: {
          organizationId: organizationId,
          candidateId: candidateId,
          familyMemberId: familyMemberId,
          language: language,
          requiredDocumentOrganizationId: requiredDocumentOrganizationId,
          requiredDocumentSetIds: requiredDocumentSetsIds,
        },
      })
      .pipe(map((result) => result.data.convertManyDocuments));
  }

  getCandidateArchive(
    language: string,
    organizationId: string,
    candidateId: string,
    familyMemberId: string,
    requiredDocumentSetsIds: string[],
    requiredDocumentOrganizationId: string
  ): Observable<Archive> {
    return this.candidateArchive
      .fetch({
        input: {
          organizationId: organizationId,
          candidateId: candidateId,
          familyMemberId: familyMemberId,
          language: language,
          requiredDocumentOrganizationId: requiredDocumentOrganizationId,
          requiredDocumentSetIds: requiredDocumentSetsIds,
        },
      })
      .pipe(map((result) => result.data.getCandidateArchive));
  }

  getTaskArchive(language: string, organizationId: string, taskId: string, processId: string): Observable<Archive> {
    return this.taskArchive
      .fetch({
        input: {
          processId: processId,
          organizationId: organizationId,
          taskId: taskId,
          language: language,
        },
      })
      .pipe(map((result) => result.data.getTaskArchive));
  }

  getUploadUrl(
    blob: string,
    organizationId: string,
    candidateId?: string,
    image?: boolean,
    timeSpanInHours?: number
  ): Observable<DocumentUrl> {
    return this.canReadFile({ candidateId, organizationId, blob })
      .pipe(
        filter((hasAccess) => hasAccess),
        switchMap(() =>
          this.documentUploadUrl.fetch({
            input: {
              blob: blob,
              organizationId: organizationId,
              candidateId: candidateId,
              fromImageContainer: image,
              timeSpanInHours: timeSpanInHours,
            },
          })
        )
      )
      .pipe(map((result) => result.data.getDocumentUploadUrl));
  }

  getDownloadUrl(
    blob: string,
    organizationId: string,
    candidateId?: string,
    timeSpanInHours?: number,
    image?: boolean
  ): Observable<string> {
    return this.canReadFile({ candidateId, organizationId, blob })
      .pipe(
        filter((hasAccess) => hasAccess),
        switchMap(() =>
          this.documentDownloadUrl.fetch({
            input: {
              blob: blob,
              organizationId: organizationId,
              candidateId: candidateId,
              timeSpanInHours: timeSpanInHours,
              fromImageContainer: image,
            },
          })
        )
      )
      .pipe(map((result) => result.data.getDocumentDownloadUrl));
  }

  getDownloadUrlAndFileName(
    blob: string,
    organizationId: string,
    candidateId: string,
    language: string
  ): Observable<{ url: string; fileName: string }> {
    return this.canReadFile({ candidateId, organizationId, blob })
      .pipe(
        filter((hasAccess) => hasAccess),
        switchMap(() =>
          this.documentDownloadUrlWithFileName.fetch({
            input: {
              blob: blob,
              organizationId: organizationId,
              candidateId: candidateId,
              language: language,
            },
          })
        )
      )
      .pipe(map((result) => result.data.getDocumentDownloadUrlWithFileName));
  }

  convertAndGetDocumentDownloadUrl(
    blob: string,
    organizationId: string,
    candidateId: string,
    language: string
  ): Observable<{ url: string; fileName: string }> {
    const request: DocumentLoadWithFileNameInput = {
      blob,
      organizationId,
      candidateId,
      language,
    };

    return this.documentConvert.fetch({ input: request }).pipe(
      map((result) => {
        return result.data.convertAndGetDocumentDownloadUrl;
      })
    );
  }

  getUploadWithContainerUrl(
    container: string,
    blob: string,
    organizationId: string,
    candidateId?: string,
    timeSpanInHours?: number
  ): Observable<string> {
    return this.canReadFile({ candidateId, organizationId, blob })
      .pipe(
        filter((hasAccess) => hasAccess),
        switchMap(() =>
          this.documentUploadWithContainerUrl.fetch({
            input: {
              container: container,
              blob: blob,
              organizationId: organizationId,
              candidateId: candidateId,
              timeSpanInHours: timeSpanInHours,
            },
          })
        )
      )
      .pipe(map((result) => result.data.getDocumentUploadWithContainerUrl));
  }

  getDownloadWithContainerUrl(
    container: string,
    blob: string,
    organizationId: string,
    candidateId?: string,
    timeSpanInHours?: number
  ): Observable<string> {
    return this.canReadFile({ candidateId, organizationId, blob }).pipe(
      filter((hasAccess) => hasAccess),
      switchMap(() =>
        this.documentDownloadWithContainerUrl.fetch({
          input: {
            container,
            blob,
            organizationId,
            candidateId,
            timeSpanInHours,
          },
        })
      ),
      map((result) => result.data.getDocumentDownloadWithContainerUrl)
    );
  }

  create(
    document: CleanDocument,
    writeMode: DocumentWriteMode,
    ignoreConcurrencyError?: boolean
  ): Observable<Document> {
    const input = new DocumentCreateInput();
    input.displayName = document.displayName;
    input.mode = document.mode;
    input.writeMode = writeMode;
    input.templateMode = document.templateMode;
    input.generationMode = document.generationMode;
    input.type = document.type;
    input.comment = document.comment;
    input.documentSets = document.documentSets;
    input.freeFormatFiles = document.freeFormatFiles;
    input.organizationId = document.organizationId;
    input.candidateId = document.candidateId;
    input.familyMemberId = document.familyMemberId;
    input.selectionCriteria = document.selectionCriteria;
    input.availableForCandidates = document.availableForCandidates;
    input.hideCandidateNameInFileName = document.hideCandidateNameInFileName;
    input.throwErrorOnNoSelection = document.throwErrorOnNoSelection;
    input.isInternalDocument = document.isInternalDocument;
    input.completionState = document.completionState;
    input.generationStrategy = document.generationStrategy?.strategyId ? document.generationStrategy : null;
    input.ignoreConcurrencyError = ignoreConcurrencyError;
    return this.documentCreate
      .mutate(
        { input: input },
        {
          update: (cache, result) =>
            cache.modify({
              fields: {
                getDocuments: (refs, helper) =>
                  updateApolloCache(input, refs, helper, cache, result.data.createDocument),
              },
            }),
        }
      )
      .pipe(
        map((result) => result.data.createDocument),
        tap((document) => this.documentNotificationService.onUpdate(document))
      );
  }

  update(
    document: CleanDocument,
    writeMode: DocumentWriteMode,
    ignoreConcurrencyError?: boolean
  ): Observable<Document> {
    const input = new DocumentUpdateInput();
    input.id = document.id;
    input._etag = document._etag;
    input.displayName = document.displayName;
    input.mode = document.mode;
    input.writeMode = writeMode;
    input.templateMode = document.templateMode;
    input.generationMode = document.generationMode;
    input.type = document.type;
    input.comment = document.comment;
    input.documentSets = document.documentSets;
    input.freeFormatFiles = document.freeFormatFiles;
    input.organizationId = document.organizationId;
    input.candidateId = document.candidateId;
    input.familyMemberId = document.familyMemberId;
    input.selectionCriteria = document.selectionCriteria;
    input.availableForCandidates = document.availableForCandidates;
    input.hideCandidateNameInFileName = document.hideCandidateNameInFileName;
    input.throwErrorOnNoSelection = document.throwErrorOnNoSelection;
    input.isInternalDocument = document.isInternalDocument;
    input.completionState = document.completionState;
    input.generationStrategy = document.generationStrategy?.strategyId ? document.generationStrategy : null;
    input.ignoreConcurrencyError = ignoreConcurrencyError;

    return this.documentUpdate.mutate({ input: input }).pipe(
      map((result) => result.data.updateDocument),
      tap((document) => this.documentNotificationService.onUpdate(document))
    );
  }

  upsertWithoutProcessStateChange(document: CleanDocument, writeMode: DocumentWriteMode): Observable<Document> {
    const input = new DocumentUpsertInput();
    input.displayName = document.displayName;
    input.mode = document.mode;
    input.writeMode = writeMode;
    input.templateMode = document.templateMode;
    input.generationMode = document.generationMode;
    input.type = document.type;
    input.comment = document.comment;
    input.documentSets = document.documentSets;
    input.freeFormatFiles = document.freeFormatFiles;
    input.organizationId = document.organizationId;
    input.candidateId = document.candidateId;
    input.familyMemberId = document.familyMemberId;
    input.selectionCriteria = document.selectionCriteria;
    input.availableForCandidates = document.availableForCandidates;
    input.hideCandidateNameInFileName = document.hideCandidateNameInFileName;
    input.throwErrorOnNoSelection = document.throwErrorOnNoSelection;
    input.isInternalDocument = document.isInternalDocument;
    input.completionState = document.completionState;

    return this.documentUpsert.mutate({ input: input }).pipe(
      map((result) => result.data.upsertDocumentWithoutProcessStateChange),
      tap((document) => this.documentNotificationService.onUpdate(document))
    );
  }

  delete(document: Document): Observable<boolean> {
    const input = new DocumentDeleteInput();
    input.id = document.id;
    input._etag = document._etag;
    input.organizationId = document.organizationId;
    input.candidateId = document.candidateId;
    return this.documentDelete
      .mutate(
        { input: input },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getDocuments: (refs, { readField }) => refs.filter((ref) => readField("id", ref) !== input.id),
              },
            }),
        }
      )
      .pipe(
        map((x) => x.data.deleteDocument.status),
        tap(() => this.documentNotificationService.onDelete(document))
      );
  }

  deleteBlob(input: BlobDeleteInput): Observable<boolean> {
    return this.blobDelete.mutate({ input }).pipe(map((x) => x.data.deleteBlob.status));
  }

  notifyProfilePictureUpdated(organizationId: string, candidateId: string): Observable<boolean> {
    return this.profilePictureUpdated
      .mutate({ input: { organizationId: organizationId, candidateId: candidateId } })
      .pipe(map((value) => value.data.profilePictureUpdated.status));
  }

  loadDocumentFormats(form: DocumentForm): Observable<DocumentFormats> {
    const type = form.controls.type.value;
    if (form.controls.mode.value !== DocumentMode.Candidate || !type || !isSingleDocumentSet(type)) {
      return of(null);
    }

    const { familyMemberId, candidateId, organizationId } = form.getRawValue();
    return this.getAvailableDocumentFormats(type, familyMemberId, candidateId, organizationId).pipe(
      tap((documentFormats) => {
        if (documentFormats) {
          this.cleanupExistingTags(form, documentFormats);
        }
      })
    );
  }

  private getAvailableDocumentFormats(
    type: string,
    familyMemberId: string,
    candidateId: string,
    organizationId: string
  ): Observable<DocumentFormats> {
    return this.candidateService
      .readCandidateContextInfoFromApolloCache(candidateId, organizationId)
      .pipe(
        switchMap((candidate) =>
          this.availableFormats.fetch({
            input: {
              type,
              familyMemberId,
              candidateId,
              organizationId,
              immigrationCountry: candidate?.immigrationCountry,
            },
          })
        )
      )
      .pipe(map((x) => x.data.getAvailableDocumentFormats));
  }

  private isInternal(organizationId: string, type: string): Observable<boolean> {
    return this.documentRequirementService
      .getByType({ organizationId, type })
      .pipe(map((requirement) => requirement.isInternal ?? false));
  }

  private cleanupExistingTags(form: DocumentForm, documentFormats: DocumentFormats): void {
    form.controls.documentSets.controls
      .flatMap((set) => set.controls.files.controls)
      .forEach((file) => {
        const tagsControl = file.controls.tags;
        const tags = tagsControl.value ?? [];
        if (tags.some((tag) => !documentFormats.availableFormats.includes(tag))) {
          tagsControl.setValue(tags.filter((tag) => documentFormats.availableFormats.includes(tag)));
        }
      });
  }
}

function updateApolloCache(input, refs, { storeFieldName }, cache, data): any {
  if (!storeFieldName.includes(input.organizationId)) return refs;
  const ref = cache.writeFragment({
    data: data,
    fragment: DocumentFragmentDoc,
    fragmentName: "Document",
  });
  if (refs != null && refs.length > 0) {
    return [...refs, ref];
  } else {
    return [ref];
  }
}
