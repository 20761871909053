import { Injectable } from "@angular/core";
import { ToggleMessagesReceptionGQL } from "@ankaadia/graphql";
import { map, Observable } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";

@Injectable({ providedIn: "root" })
export class UnsubscribeService {
  constructor(
    private readonly toggleMsgReception: ToggleMessagesReceptionGQL,
    private readonly settings: SettingsService
  ) {}

  resolveUnsubscribe(): Observable<boolean> {
    return this.toggleMsgReception
      .mutate({ enabled: false, isCandidate: this.settings.isCandidate })
      .pipe(map((x) => x.data.toggleMessagesReception.enabled));
  }
}
