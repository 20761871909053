import { Directive } from "@angular/core";
import { Sidebar } from "primeng/sidebar";

@Directive({ selector: "p-sidebar" })
export class SidebarOptionsDirective {
  constructor(private readonly sidebar: Sidebar) {
    this.sidebar.closeOnEscape = false;
    this.sidebar.dismissible = false;
    this.sidebar.showCloseIcon = false;
    this.sidebar.position = "right";
  }
}
