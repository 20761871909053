<ng-container *appTranslate="let t">
  <div class="grid grid-nogutter">
    <div class="grid grid-nogutter col">
      <p-autoComplete
        class="col"
        styleClass="h-full"
        [readonly]="props.readonly"
        [suggestions]="suggestions$ | async"
        [formControl]="$any(this.formControl)"
        [dropdown]="true"
        (completeMethod)="getSuggestions($event.query)"
        [formlyAttributes]="field"
        [optionValue]="'value'"
        field="label"
      ></p-autoComplete>
      <div class="col-fixed flex justify-center align-items-center" *ngIf="displayInfoButton && !isCandidate">
        <p-button
          styleClass="p-button-rounded p-button-text"
          icon="pi pi-question-circle"
          (click)="openDetails()"
        ></p-button>
      </div>
    </div>
  </div>
</ng-container>

<p-sidebar
  [visible]="(showSidebar$ | async) && !!selectedSuggestionDetail"
  [closeOnEscape]="false"
  *appTranslate="let t"
  styleClass="p-sidebar-lg"
>
  <p-card *ngIf="selectedSuggestionDetail" [header]="selectedSuggestionDetail.description[0].value">
    <ng-template pTemplate="body">
      <p-accordion [multiple]="true" [activeIndex]="[0, 1]">
        <p-accordionTab [header]="t('description.title')">
          <table class="w-full">
            <tr *ngFor="let description of selectedSuggestionDetail.description">
              <td *ngIf="selectedSuggestionDetail.__typename === 'AnabinDegreesForInfoView'" class="font-bold">
                {{ t($any("anabin.degree." + description.key)) }}
              </td>
              <td *ngIf="selectedSuggestionDetail.__typename === 'AnabinInstitutionInfoViewDetails'" class="font-bold">
                {{ t($any("anabin.institution." + description.key)) }}
              </td>
              <td *ngIf="description.key === 'country'">
                {{ description.value | staticData: StaticDataType.Countries | async }}
              </td>
              <td *ngIf="description.key !== 'country'">
                {{ description.value }}
              </td>
            </tr>
          </table>
        </p-accordionTab>
        <ng-container *ngIf="selectedSuggestionDetail.__typename === 'AnabinDegreesForInfoView'">
          <p-accordionTab
            [header]="t('anabin.degree.ratings.title')"
            *ngIf="selectedSuggestionDetail.ratings.length > 0"
          >
            <ng-container *ngTemplateOutlet="anabinTable; context: { purpose: 'ratings', t: t }"></ng-container>
          </p-accordionTab>

          <p-accordionTab
            [header]="t('anabin.degree.institution.title')"
            *ngIf="selectedSuggestionDetail.institutions.length > 0"
          >
            <ng-container *ngTemplateOutlet="anabinTable; context: { purpose: 'institutions', t: t }"></ng-container>
          </p-accordionTab>
        </ng-container>

        <ng-container *ngIf="selectedSuggestionDetail.__typename === 'AnabinInstitutionInfoViewDetails'">
          <p-accordionTab
            [header]="t('anabin.institution.altName')"
            *ngIf="selectedSuggestionDetail.alternativeNames.length > 0"
          >
            <ng-container
              *ngTemplateOutlet="anabinTable; context: { purpose: 'alternativeNames', t: t }"
            ></ng-container>
          </p-accordionTab>

          <p-accordionTab
            [header]="t('anabin.institution.degrees')"
            *ngIf="selectedSuggestionDetail.degrees.length > 0"
          >
            <ng-container *ngTemplateOutlet="anabinTable; context: { purpose: 'degrees', t: t }"></ng-container>
          </p-accordionTab>
        </ng-container>
      </p-accordion>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="flex flex-row justify-content-end">
        <p-button
          (click)="showSidebar$.next(false)"
          [label]="t('common.close')"
          icon="pi pi-times"
          class="p-button-secondary"
        ></p-button>
      </div>
    </ng-template>
  </p-card>
</p-sidebar>

<ng-template #anabinTable let-purpose="purpose" let-t="t">
  <table class="w-full">
    <thead>
      <tr>
        <th *ngFor="let label of tableConfig[selectedSuggestionDetail.__typename][purpose].labels">{{ t(label) }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of selectedSuggestionDetail[purpose]">
        <td *ngFor="let field of tableConfig[selectedSuggestionDetail.__typename][purpose].fields">
          <ng-container *ngIf="field === 'country'">
            {{ item[field] | staticData: StaticDataType.Countries | async }}
          </ng-container>
          <ng-container *ngIf="field !== 'country'">
            {{ item[field] }}
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
