import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { ProfessionEditComponent } from "./profession-edit/profession-edit.component";
import { ProfessionSelectorComponent } from "./profession-selector/profession-selector.component";

@NgModule({
  imports: [SharedModule],
  declarations: [ProfessionSelectorComponent, ProfessionEditComponent],
  exports: [ProfessionSelectorComponent, ProfessionEditComponent],
})
export class ProfessionModule {}
