import { Injectable, NgZone } from "@angular/core";
import { Observable, fromEvent, merge, debounceTime } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class IdleNotificationService {
  constructor(private readonly ngZone: NgZone) {}

  startWatching(timeOutMinutes: number): Observable<any> {
    const timeOutMilliSeconds = timeOutMinutes * 1000 * 60;
    const activity$ = merge(
      fromEvent(document, "mousemove"),
      fromEvent(document, "click"),
      fromEvent(document, "mousedown"),
      fromEvent(document, "keypress"),
      fromEvent(document, "DOMMouseScroll"),
      fromEvent(document, "mousewheel"),
      fromEvent(document, "touchmove"),
      fromEvent(document, "MSPointerMove"),
      fromEvent(window, "mousemove"),
      fromEvent(window, "resize")
    );

    return new Observable((observer) => {
      this.ngZone.runOutsideAngular(() => activity$.pipe(debounceTime(timeOutMilliSeconds)).subscribe(observer));
    });
  }
}
