export function isSupportedByDocumentPreview(filename: string, fileType: string): boolean {
  return (
    ["docx", "doc", "pdf", "xlsx", "xls", "ppt", "pptx"].some((x) => filename?.toLocaleLowerCase()?.endsWith(x)) ||
    isImage(fileType)
  );
}

export function isImage(type: string): boolean {
  return type.split("/")[0] === "image";
}
