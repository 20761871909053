import { ValueOf } from "ts-essentials";
import { Icon } from "./icon.helper";

export class Color {
  static readonly Info = "color-info";
  static readonly Success = "color-success";
  static readonly Warning = "color-warning";
  static readonly Error = "color-error";
}

export class TagColor {
  static readonly Primary = "app-tag-primary";
  static readonly Secondary = "app-tag-secondary";
  static readonly Success = "app-tag-success";
  static readonly Info = "app-tag-info";
  static readonly Warning = "app-tag-warning";
  static readonly Danger = "app-tag-danger";
}

export class TagIconColor {
  static readonly Primary = "app-tag-icon-primary";
  static readonly Secondary = "app-tag-icon-secondary";
  static readonly Success = "app-tag-icon-success";
  static readonly Info = "app-tag-icon-info";
  static readonly Warning = "app-tag-icon-warning";
  static readonly Danger = "app-tag-icon-danger";
}

type AnyIcon = ValueOf<typeof Icon>;

export class IconColor {
  static readonly Info = (icon: AnyIcon): string => `${icon} ${Color.Info}`;
  static readonly Success = (icon: AnyIcon): string => `${icon} ${Color.Success}`;
  static readonly Warning = (icon: AnyIcon): string => `${icon} ${Color.Warning}`;
  static readonly Error = (icon: AnyIcon): string => `${icon} ${Color.Error}`;
}
