import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import {
  DocumentAiExtractionResult,
  DocumentAiExtractionInput,
  ExtractDocumentDataGQL,
  GetDocumentAiUploadUrlGQL,
  DocumentAiUploadInput,
  DocumentAiUrl,
  DocumentAiUrlInput,
  DocumentAiPerformanceCreateInput,
  DocumentAiPerformanceInput,
  CreateDocumentAiPerformancesGQL,
} from "@ankaadia/graphql";
import { SettingsService } from "../../../shared/services/settings.service";
import { isNil, omit } from "lodash";
import { CandidateEntry } from "../../document-dropzone/document-dropzone-form.model";
import { CollectionInformation } from "../../document-dropzone/document-dropzone.model";
import { isCollectionInformation } from "../../document-dropzone/document-dropzone.functions";

@Injectable({ providedIn: "root" })
export class DocumentAiService {
  constructor(
    private readonly extractDocumentDataGQL: ExtractDocumentDataGQL,
    private readonly getDocumentAiUploadUrlGQL: GetDocumentAiUploadUrlGQL,
    private readonly createDocumentAiPerformanceGQL: CreateDocumentAiPerformancesGQL,
    private readonly settings: SettingsService
  ) {}

  getDocumentAiUploadUrl(blob: string, organizationId: string): Observable<DocumentAiUrl> {
    const input: DocumentAiUploadInput = {
      organizationId,
      blob,
    };
    return this.getDocumentAiUploadUrlGQL.fetch({ input }).pipe(map((x) => x.data.getDocumentAiUploadUrl));
  }

  extractDocumentData(
    requestId: string,
    documentAiUrl: DocumentAiUrlInput,
    candidateOrCollection: CandidateEntry | CollectionInformation
  ): Observable<DocumentAiExtractionResult> {
    const request: DocumentAiExtractionInput = {
      requestId,
      documentAiUrl: omit(documentAiUrl, "__typename"),
      organizationId: this.settings.organizationId,
    };

    if (!isNil(candidateOrCollection)) {
      this.buildRequest(requestId, documentAiUrl, candidateOrCollection);
    }
    return this.extractDocumentDataGQL.subscribe({ input: request }).pipe(
      map((x) => {
        return x.data.extractDocumentData;
      })
    );
  }

  logAiPerformance(performanceEntries: DocumentAiPerformanceInput[]): void {
    const input = new DocumentAiPerformanceCreateInput();
    input.performanceEntries = performanceEntries;
    input.organizationId = this.settings.organizationId;
    this.createDocumentAiPerformanceGQL.mutate({ input }).subscribe();
  }

  private buildRequest(
    requestId: string,
    documentAiUrl: DocumentAiUrlInput,
    candidateOrCollection: CandidateEntry | CollectionInformation
  ): DocumentAiExtractionInput {
    const request: DocumentAiExtractionInput = {
      requestId,
      documentAiUrl: omit(documentAiUrl, "__typename"),
      organizationId: this.settings.organizationId,
    };

    if (!isCollectionInformation(candidateOrCollection)) {
      request.candidateId = { id: candidateOrCollection.id, orgId: candidateOrCollection.organizationId };
      return request;
    }

    if (candidateOrCollection.event.filters?.favorite) {
      request.isFavorite = true;
    } else {
      request.filters = candidateOrCollection.event.filters;
    }

    if (candidateOrCollection.selectedCollectionId) {
      request.collectionId = {
        id: candidateOrCollection.selectedCollectionId,
        orgId: candidateOrCollection.selectedCollectionOrganizationId,
      };
    }

    if (candidateOrCollection.selectedSharing) {
      request.selectedSharingId = candidateOrCollection.selectedSharing.id;
    }

    return request;
  }
}
