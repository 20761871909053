<ng-container *transloco="let t; lang: language + '|static'">
  <p-card>
    <div style="height: calc(100% - 65px)">
      <div class="formgrid grid pl-2">
        <p-dropdown
          *ngIf="processId == null"
          rememberState
          [stateKey]="'org_' + stateKey"
          [afterFirstValueSet]="true"
          class="field col-4"
          inputId="organization"
          [options]="organizations"
          [formControl]="selectedOrganization"
        ></p-dropdown>
        <p-multiSelect
          *ngIf="processId == null"
          rememberState
          [stateKey]="'sets_' + stateKey"
          [afterFirstValueSet]="true"
          class="field col-6"
          inputId="requiredDocumentSet"
          [options]="requiredDocumentSets"
          [showClear]="true"
          optionLabel="name"
          optionValue="id"
          [placeholder]="t('requiredDocument.placeholder')"
          [showToggleAll]="true"
          [formControl]="selectedSet"
        ></p-multiSelect>
        <p-multiSelect
          class="field col col-2"
          [placeholder]="t('deliveryFormat.placeholderOverview')"
          inputId="physicalTypes"
          [options]="knownDeliveryFormats"
          [formControl]="documentDeliveryFormat"
          optionLabel="label"
          optionValue="value"
          [showClear]="true"
        ></p-multiSelect>
      </div>
      <p-table [value]="rowData" scrollDirection="both" styleClass="p-datatable-sm p-bad-datatable">
        <ng-template pTemplate="header">
          <tr>
            <th
              pFrozenColumn
              [style]="'min-width: 250px;max-width: 500px;width:' + 100 / (candidateData?.length ?? 0 + 1) + '%'"
            >
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" [formControl]="filterText" [placeholder]="t('common.search')" />
              </span>
            </th>
            <th
              class="flex flex-column align-items-start"
              [style]="'width:' + 100 / (candidateData?.length ?? 0 + 1) + '%' + ';min-width:80px;max-width:160px'"
              *ngFor="let candidate of candidateData"
            >
              <a [routerLink]="getCandidateEditLink(candidate.candidateId, candidate.organizationId)">
                {{ candidate.displayId }}</a
              >
              <small class="clamp" [pTooltip]="candidate.displayName">{{ candidate.displayName }}</small>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td
              pFrozenColumn
              [pTooltip]="row.documentName"
              [style]="'min-width: 250px;max-width: 500px;width:' + (100 / (row.cols.length + 1)) * 2 + '%'"
            >
              <span
                style="
                  display: -webkit-box;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  -webkit-box-orient: vertical;
                  line-clamp: 1;
                "
              >
                {{ row.documentName }}
              </span>
            </td>
            <td
              *ngFor="let col of row.cols; let indexOfelement = index"
              [style]="'width:' + 100 / (row.cols.length + 1) + '%' + ';min-width:80px;max-width:160px'"
            >
              <div>
                <i
                  [class]="col.documentStatus | documentStatus: 'Icon' : col.completionState"
                  [style.color]="col.documentStatus | documentStatus: 'Color' : col.completionState"
                  [style.cursor]="'pointer'"
                  [pTooltip]="buildTooltip(col)"
                  (click)="openMenue(row, col, indexOfelement, $event)"
                  (dblclick)="openDocument(row, col, indexOfelement)"
                ></i>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-card>

  <p-sidebar #sidebar [(visible)]="showSidebar" styleClass="p-sidebar-md">
    <app-document-edit-dialog
      *ngIf="showSidebar && selectedDocument && !selectedFile"
      [document]="selectedDocument"
      [mode]="documentMode.Candidate"
      [processLanguage]="language"
      [confirmCreation]="true"
      [personName]="personName"
      (closed)="closeDocument()"
      (saved)="saveDocument($event)"
    ></app-document-edit-dialog>

    <app-document-preview-dialog
      *ngIf="showSidebar && selectedFile && selectedDocument"
      [document]="selectedDocument"
      [file]="selectedFile"
      [fileName]="selectedFile.name"
      [url]="fileUrl"
      [processLanguage]="language"
      (closed)="closePreview()"
    ></app-document-preview-dialog>
  </p-sidebar>
</ng-container>
<p-contextMenu [model]="menueItems"></p-contextMenu>
