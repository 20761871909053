import { Component, Input } from "@angular/core";
import { ActivityType } from "@ankaadia/graphql";

@Component({
  selector: "app-activity-icon",
  templateUrl: "./activity-icon.component.html",
})
export class ActivityIconComponent {
  readonly ActivityType = ActivityType;

  @Input()
  type: ActivityType;
}
