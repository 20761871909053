import { PrimeIcons } from "primeng/api";

/**
 * PrimeNG v16 assumes PrimeIcons v6, however, we're already on v7!
 * Use this class if you need the full set of available icons.
 * Remove this class if you update PrimeNG to v17+.
 */
export class Icon extends PrimeIcons {
  static readonly ADDRESS_BOOK = i("address-book");
  static readonly ARROW_DOWN_LEFT_AND_ARROW_UP_RIGHT_TO_CENTER = i("arrow-down-left-and-arrow-up-right-to-center");
  static readonly ARROW_UP_RIGHT_AND_ARROW_DOWN_LEFT_FROM_CENTER = i("arrow-up-right-and-arrow-down-left-from-center");
  static readonly ASTERISKS = i("asterisks");
  static readonly BARCODE = i("barcode");
  static readonly BELL_SLASH = i("bell-slash");
  static readonly BUILDING_COLUMNS = i("building-columns");
  static readonly CALENDAR_CLOCK = i("calendar-clock");
  static readonly CART_ARROW_DOWN = i("cart-arrow-down");
  static readonly CART_MINUS = i("cart-minus");
  static readonly CHART_SCATTER = i("chart-scatter");
  static readonly CLIPBOARD = i("clipboard");
  static readonly CROWN = i("crown");
  static readonly EQUALS = i("equals");
  static readonly EXPAND = i("expand");
  static readonly FACE_SMILE = i("face-smile");
  static readonly FILE_ARROW_UP = i("file-arrow-up");
  static readonly FILE_CHECK = i("file-check");
  static readonly FILE_PLUS = i("file-plus");
  static readonly FOLDER_PLUS = i("folder-plus");
  static readonly GAUGE = i("gauge");
  static readonly GRADUATION_CAP = i("graduation-cap");
  static readonly HAMMER = i("hammer");
  static readonly HEADPHONES = i("headphones");
  static readonly INDIAN_RUPEE = i("indian-rupee");
  static readonly LIGHTBULB = i("lightbulb");
  static readonly LIST_CHECK = i("list-check");
  static readonly MARS = i("mars");
  static readonly MICROCHIP = i("microchip");
  static readonly MICROCHIP_AI = i("microchip-ai");
  static readonly OBJECTS_COLUMN = i("objects-column");
  static readonly PAUSE_CIRCLE = i("pause-circle");
  static readonly PEN_TO_SQUARE = i("pen-to-square");
  static readonly PINTEREST = i("pinterest");
  static readonly PLAY_CIRCLE = i("play-circle");
  static readonly RECEIPT = i("receipt");
  static readonly SHOP = i("shop");
  static readonly SORT_DOWN_FILL = i("sort-down-fill");
  static readonly SORT_UP_FILL = i("sort-up-fill");
  static readonly SPARKLES = i("sparkles");
  static readonly STAR_HALF = i("star-half");
  static readonly STAR_HALF_FILL = i("star-half-fill");
  static readonly THUMBTACK = i("thumbtack");
  static readonly TIKTOK = i("tiktok");
  static readonly TROPHY = i("trophy");
  static readonly TURKISH_LIRA = i("turkish-lira");
  static readonly TWITCH = i("twitch");
  static readonly VENUS = i("venus");
  static readonly WAREHOUSE = i("warehouse");
  static readonly WAVE_PULSE = i("wave-pulse");
}

function i<T extends string>(icon: T): `pi pi-${T}` {
  return `pi pi-${icon}`;
}
