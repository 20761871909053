export enum KnownConfigTokens {
  DEFAULT = "DEFAULT",
  ONEACARE = "ONEACARE",
  DEFA = "DEFA",
  DEMO = "DEMO",
  GUETEGEMEINSCHAFT = "GUETEGEMEINSCHAFT",
  HOFFMANNMEDICAL = "HOFFMANNMEDICAL",
  ANKAADIA = "ANKAADIA",
  SIGNITE = "SIGNITE",
  CARETS = "CARETS",
  TRUSTEDJOB = "TRUSTEDJOB",
  SANIPONT = "SANIPONT",
  HIRE = "HIRE",
  ALFAPC = "ALFAPC",
  MEDBEST = "MEDBEST",
  LANDW = "LANDW",
  KOLPING = "KOLPING",
  CURATO = "CURATO",
  WBSINT = "WBSINT",
  VOELKERSCHULE = "VOELKERSCHULE",
  VIDACTA = "VIDACTA",
  FACHKRAFTAGENTEN = "FACHKRAFTAGENTEN",
  MEDWING = "MEDWING",
  REKRUUT = "REKRUUT",
  COMSENSE = "COMSENSE",
  KLINIKUMOSNABRUECK = "KLINIKUMOSNABRUECK",
  SCHUECHTERMANNKLINIK = "SCHUECHTERMANNKLINIK",
  SAFFARNI = "SAFFARNI",
  TRIAPS = "TRIAPS",
  MANDS = "MANDS",
  FAIRRECRUITING = "FAIRRECRUITING",
  STFRANZISKUS = "STFRANZISKUS",
  HOLALEMANIA = "HOLALEMANIA",
}
