import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormArray } from "@angular/forms";
import { Document, DocumentMode } from "@ankaadia/graphql";
import { translate } from "@ngneat/transloco";
import { FileUpload } from "primeng/fileupload";
import { Observable, concat, of, toArray } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { MessageDialogService } from "../../../shared/message-dialog/message-dialog.service";
import { DownloadService } from "../../../shared/services/download.service";
import { FileUploadService } from "../../../shared/services/file-upload.service";
import { DocumentForm, FreeFormatFileForm } from "../document-form.model";
import { DocumentFormBuilder, DocumentFormService } from "../document-form.service";
import { DocumentsService } from "../documents.service";

@Component({
  selector: "app-free-format-document-selector",
  templateUrl: "./free-format-document-selector.component.html",
  styleUrls: ["./free-format-document-selector.component.scss"],
  providers: [FileUploadService],
})
export class FreeFormatDocumentSelectorComponent implements OnInit, OnChanges, AfterViewInit {
  private uploadQueue: { blob: string; file: File }[];

  @Input({ required: true })
  form: DocumentForm;

  @Input({ required: true })
  mode: DocumentMode;

  @Input({ required: true })
  readonly: boolean;

  @Input()
  customDownload: (file: { blob: string; file: File }) => void;

  @Input()
  customUpload: (files: { blob: string; file: File }[]) => void;

  @Input()
  processLanguage?: string;

  @Output()
  readonly upload = new EventEmitter<boolean>();

  @ViewChild("fileUpload")
  fileUpload: FileUpload;

  @ContentChild("toolbar", { read: TemplateRef })
  toolbarTemplate?: TemplateRef<any>;

  get document(): Document {
    return this.form.getRawValue();
  }

  get freeFormatFiles(): FormArray<FreeFormatFileForm> {
    return this.form.controls.freeFormatFiles;
  }

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly documentService: DocumentsService,
    private readonly fileUploadService: FileUploadService,
    private readonly errorService: MessageDialogService,
    private readonly downloadService: DownloadService,
    private readonly documentFormService: DocumentFormService
  ) {}

  ngOnInit(): void {
    this.fileUploadService.onProgress.subscribe((x) => {
      this.fileUpload.progress = x;
      this.fileUpload.cd.detectChanges();
    });
    this.fileUploadService.onUpload.subscribe(() => this.onUpload());
    this.fileUploadService.onError.subscribe((x) => this.onError(x));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form) {
      this.initFiles();
    }
    if (changes.document) {
      this.initFiles();
    }
  }

  ngAfterViewInit(): void {
    this.initFiles();
  }

  triggerUpload(): void {
    this.fileUpload.upload();
  }

  selectFiles(selectedFiles: File[]): void {
    // Filter Files with same file name
    const finallySelectedFiles = this.fileUploadService.ensureValidFiles(this.fileUpload, selectedFiles);
    const builder = this.createDocumentFormBuilder();
    for (const file of finallySelectedFiles) {
      if (this.findIndexInFiles(file) === -1) {
        const control = builder.createFreeFormatFileForm(file);
        this.freeFormatFiles.push(control);
        control.markAsDirty();
      }
    }
    this.changeDetector.detectChanges();
  }

  uploadFiles(): void {
    const fileControls = this.freeFormatFiles.controls.filter((file) => file.controls.file.value);
    this.validateFiles(fileControls.map((control) => control.getRawValue().file)).subscribe({
      next: () => {
        const controlsToUpload = fileControls.filter((control) => !control.controls.blob.value);
        controlsToUpload.forEach((control) => control.controls.blob.setValue(uuidv4()));
        this.uploadQueue = controlsToUpload.map((control) => {
          const { blob, file } = control.getRawValue();
          return { blob, file };
        });

        if (this.customUpload) {
          this.customUpload(this.uploadQueue);
        } else {
          this.onUpload();
        }
      },
      error: (e) => this.onError(e),
    });
  }

  removeFile(file: FreeFormatFileForm): void {
    const index = this.freeFormatFiles.controls.indexOf(file);
    this.freeFormatFiles.removeAt(index);
    this.freeFormatFiles.markAsDirty();
    this.changeDetector.detectChanges();
  }

  downloadFile(fileForm: FreeFormatFileForm): void {
    const { file, blob } = fileForm.getRawValue();
    if (this.customDownload) {
      this.customDownload({ blob, file });
      return;
    }

    if (blob) {
      const { organizationId, candidateId } = this.document;
      this.documentService
        .getDownloadUrl(blob, organizationId, candidateId)
        .subscribe((url) => this.downloadService.downloadFile(file.name, url));
    } else {
      this.downloadService.downloadFile(file.name, URL.createObjectURL(file));
    }
  }

  private validateFiles(files: File[]): Observable<any> {
    return files.length > 0 ? concat(...files.map((f) => this.fileUploadService.validate(f))).pipe(toArray()) : of({});
  }

  private initFiles(): void {
    if (this.fileUpload) {
      this.fileUpload.files = [];
      this.fileUpload.progress = 100;
      this.fileUpload.cd.detectChanges();
      this.changeDetector.detectChanges();
    }
  }

  private onUpload(): void {
    const upload = this.uploadQueue.pop();
    if (upload) {
      this.documentService
        .getUploadUrl(upload.blob, this.document.organizationId, this.document.candidateId)
        .subscribe(({ url }) => this.fileUploadService.upload(upload.file, url));
    } else {
      this.upload.emit(true);
    }
  }

  private onError(event: any): void {
    this.errorService.showMessage(
      translate("file.uploadFailed", null, this.processLanguage),
      translate("file.bad", { name: event.message }, this.processLanguage)
    );
    this.upload.emit(false);
  }

  private findIndexInFiles(file: File): number {
    return this.freeFormatFiles.value.findIndex(
      (x) => x.name === file.name && x.size === file.size && x.type === file.type
    );
  }

  private createDocumentFormBuilder(): DocumentFormBuilder {
    return this.documentFormService.createDocumentFormBuilder(this.document, {
      documentMode: this.mode,
      isReadonly: this.readonly,
    });
  }
}
