import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslocoModule } from "../transloco/transloco.module";
import { ShowMoreComponent } from "./show-more.component";

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [ShowMoreComponent],
  exports: [ShowMoreComponent],
})
export class ShowMoreModule {}
