import { nameofFactory, TravelBooking } from "@ankaadia/ankaadia-shared";
import { StaticDataType } from "@ankaadia/graphql";
import { translate as transloco, TranslateParams } from "@ngneat/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { StaticDataService } from "../../../shared/static-data/static-data.service";

export function travelBookingFormFactory(language: string, staticDataService: StaticDataService): FormlyFieldConfig[] {
  const nameOf = nameofFactory<TravelBooking>();
  const translate = <T>(key: TranslateParams, params?: Record<string, any>): T => transloco<T>(key, params, language);
  return [
    {
      fieldGroupClassName: "grid",
      fieldGroup: [
        {
          className: "col-2",
          key: nameOf("salutationInPassport"),
          type: "dropdown",
          props: {
            label: translate("salutationInPassport.title"),
            placeholder: translate("salutationInPassport.placeholder"),
            required: true,
            options: staticDataService.getStaticData(StaticDataType.Salutation, language),
            filter: true,
            filterBy: "label",
            blocklyType: "field_staticdata_salutation",
          },
        },

        {
          className: "col-5",
          type: "input",
          key: nameOf("firstNameInPassport"),
          props: {
            label: translate("firstNameInPassport.title"),
            type: "text",
            maxLength: 100,
            required: true,
            hideRequiredMarker: true,
            blocklyType: "String",
          },
          validation: {
            messages: {
              required: translate("firstNameInPassport.required"),
            },
          },
        },
        {
          className: "col-5",
          type: "input",
          key: nameOf("nameInPassport"),
          props: {
            label: translate("nameInPassport.title"),
            type: "text",
            maxLength: 100,
            required: true,
            hideRequiredMarker: true,
            blocklyType: "String",
          },
          validation: {
            messages: {
              required: translate("nameInPassport.required"),
            },
          },
        },
        {
          className: "col-4",
          key: nameOf("birthDateInPassport"),
          type: "datepicker",
          props: {
            label: translate("birthDateInPassport.title"),
            showIcon: true,
            required: true,
            hideRequiredMarker: true,
            maxDate: new Date(),
            blocklyType: "field_date",
          },
          validation: {
            messages: {
              required: translate("birthDateInPassport.required"),
              maxDate: translate("birthDateInPassport.invalid"),
            },
          },
        },
        {
          className: "col-8",
          type: "input",
          key: nameOf("employerName"),
          props: {
            label: translate("employer.title"),
            type: "text",
            maxLength: 100,
            readonly: true,
            blocklyType: "String",
          },
        },
        {
          className: "col-6",
          type: "input",
          key: nameOf("emailAddress"),
          props: {
            label: translate("email.title"),
            type: "text",
            maxLength: 100,
            required: true,
            hideRequiredMarker: true,
            blocklyType: "String",
          },
          validation: {
            messages: {
              required: translate("email.required"),
            },
          },
        },
        {
          className: "col-6",
          type: "input",
          key: nameOf("phoneNumber"),
          props: {
            label: translate("phone.title"),
            type: "text",
            maxLength: 100,
            required: true,
            hideRequiredMarker: true,
            blocklyType: "String",
          },
          validation: {
            messages: {
              required: translate("phone.required"),
            },
          },
        },
        {
          className: "col-4",
          key: nameOf("arrivalDate"),
          type: "datepicker",
          props: {
            label: translate("arrivalDate.title"),
            showIcon: true,
            required: true,
            hideRequiredMarker: true,
            blocklyType: "field_date",
          },
          validation: {
            messages: {
              required: translate("arrivalDate.required"),
            },
          },
        },
        {
          className: "col-8",
          type: "input",
          key: nameOf("arrivalTimeSlot"),
          props: {
            label: translate("arrivalTimeSlot.title"),
            type: "text",
            maxLength: 100,
            required: true,
            hideRequiredMarker: true,
            blocklyType: "String",
          },
          validation: {
            messages: {
              required: translate("arrivalTimeSlot.required"),
            },
          },
        },
        {
          className: "col-6",
          type: "input",
          key: nameOf("departurePoint"),
          props: {
            label: translate("departurePoint.title"),
            type: "text",
            maxLength: 100,
            required: true,
            hideRequiredMarker: true,
            blocklyType: "String",
          },
          validation: {
            messages: {
              required: translate("departurePoint.required"),
            },
          },
        },
        {
          className: "col-6",
          type: "input",
          key: nameOf("arrivalPoint"),
          props: {
            label: translate("arrivalPoint.title"),
            type: "text",
            maxLength: 100,
            required: true,
            hideRequiredMarker: true,
            blocklyType: "String",
          },
          validation: {
            messages: {
              required: translate("arrivalPoint.required"),
            },
          },
        },
        {
          className: "col-6",
          key: nameOf("travelVehicle"),
          type: "multiselect",
          props: {
            label: translate("travelVehicle.title"),
            placeholder: translate("travelVehicle.placeholder"),
            required: false,
            options: staticDataService.getStaticData(StaticDataType.TravelVehicles, language),
            filter: true,
            filterBy: "label",
            blocklyType: "field_staticdata_travelvehicles",
          },
        },
        {
          className: "col-6",
          type: "input",
          key: nameOf("travelVehicleOther"),
          props: {
            label: translate("travelVehicleOther.title"),
            type: "text",
            maxLength: 100,
            required: true,
            hideRequiredMarker: true,
            blocklyType: "String",
          },
          expressionProperties: {
            "props.disabled": (model) => !model.travelVehicle?.includes("OTHER"),
          },
          validation: {
            messages: {
              required: translate("travelVehicleOther.required"),
            },
          },
        },
        {
          className: "col-12",
          type: "textarea",
          key: nameOf("invoiceAddress"),
          props: {
            label: translate("invoiceAddress.title"),
            type: "text",
            rows: 4,
            maxLength: 1000,
            required: true,
            hideRequiredMarker: true,
            blocklyType: "String",
          },
          validation: {
            messages: {
              required: translate("invoiceAddress.required"),
            },
          },
        },
      ],
    },
  ];
}
