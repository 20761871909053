import { Injectable } from "@angular/core";
import {
  CreateOrganizationAndCertificationProcessGQL,
  CreateReCertificationProcessGQL,
  GetDataForFormerAuditGQL,
  GetDataForFormerAuditQuery,
  GetReCertificationLinkGQL,
  GuetegemeinschaftOrganizationLinkOutput,
  GuetegemeinschaftOrganizationReCertificationInput,
  LinkGuetegemeinschaftOrganizationGQL,
  ReCertificationProcessCreationResult,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";
import { AuthInterceptor } from "../../shared/interceptors/auth.interceptor";
import { SettingsService } from "../../shared/services/settings.service";

@Injectable({ providedIn: "root" })
export class GuetegemeinschaftService {
  constructor(
    private readonly settings: SettingsService,
    private readonly orgLink: LinkGuetegemeinschaftOrganizationGQL,
    private readonly createReCertificationProcessGQL: CreateReCertificationProcessGQL,
    private readonly createOrganizationAndCertificationProcessGQL: CreateOrganizationAndCertificationProcessGQL,
    private readonly getReCertificationLinkGQL: GetReCertificationLinkGQL,
    private readonly getDataForFormerAudit: GetDataForFormerAuditGQL,
    private readonly authInterceptor: AuthInterceptor
  ) {
    this.authInterceptor.registerNoLoginOperation(this.createOrganizationAndCertificationProcessGQL);
  }

  getTaskStateFromInitialAudit(
    organizationId: string,
    processId: string,
    taskId: string
  ): Observable<GetDataForFormerAuditQuery["getDataForFormerAudit"]> {
    return this.getDataForFormerAudit
      .fetch({ organizationId: organizationId, processId: processId, taskId: taskId })
      .pipe(map((x) => x.data.getDataForFormerAudit));
  }

  getReCertificationLink(): Observable<string> {
    return this.getReCertificationLinkGQL.fetch().pipe(map((x) => x.data.getReCertificationLink));
  }

  link(): Observable<GuetegemeinschaftOrganizationLinkOutput> {
    return this.orgLink
      .mutate({ input: { organizationId: this.settings.organizationId } })
      .pipe(map((x) => x.data.linkGuetegemeinschaftOrganization));
  }

  createReCertificationProcess(organizationId: string): Observable<ReCertificationProcessCreationResult> {
    return this.createReCertificationProcessGQL
      .mutate({ organizationId: organizationId })
      .pipe(map((x) => x.data.createReCertificationProcess));
  }

  createOrganizationAndReCertificationProcess(
    input: GuetegemeinschaftOrganizationReCertificationInput
  ): Observable<ReCertificationProcessCreationResult> {
    return this.createOrganizationAndCertificationProcessGQL
      .mutate({ input: input })
      .pipe(map((x) => x.data.createOrganizationAndCertificationProcess));
  }
}
