import { FormlyExtension, FormlyFieldConfig } from "@ngx-formly/core";

export class FormlyShowValidationExtension implements FormlyExtension {
  prePopulate(field: FormlyFieldConfig): void {
    if (!field.validation) {
      field.validation = {};
    }
    field.validation.show = true;
  }
}
