<div class="field">
  <label [for]="key" [class]="showError ? 'p-error' : ''">{{ to.label }}</label>

  <p-messages *ngIf="showError" severity="error" [closable]="false">
    <ng-template pTemplate>
      <formly-validation-message class="text-sm" [field]="field"></formly-validation-message>
    </ng-template>
  </p-messages>

  <div [id]="key">
    <div class="tab-container">
      <p-button
        icon="pi pi-plus"
        class="add-tab-button"
        [styleClass]="'p-button-rounded p-button-text ml-2' + (isAddEnabled ? ' p-button-active' : '')"
        [disabled]="!isAddEnabled"
        (onClick)="handleAdd($event)"
      ></p-button>

      <p-tabView class="repeat-tabs" [controlClose]="!to.readonly" (onClose)="handleClose($event)">
        <p-tabPanel
          *ngFor="let tab of field.fieldGroup; let i = index"
          [disabled]="isDisabled"
          [headerStyleClass]="validity[i] === false ? 'p-error' : ''"
          [closable]="!to.readonly && formControl.enabled && i >= to.minCount"
          [header]="to.formatLabel(model, i)"
        >
          <p-panel>
            <formly-field [field]="tab"></formly-field>
          </p-panel>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
