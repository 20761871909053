<ng-container *appTranslate="let t">
  <table>
    <tr>
      <td>
        <app-dashboard-counter
          [label]="t('dashboard.tasksFinished', { days: metrics.tasksFinished?.days ?? 0 })"
          [routerLink]="metrics.tasksFinished ? ['/app/activity'] : null"
          [state]="{
            type: [ActivityType.TaskFinished],
            date: lastDays(metrics.tasksFinished?.days),
            organization: 'all',
          }"
          [amount]="metrics.tasksFinished?.value ?? 0"
          icon="pi-briefcase"
        ></app-dashboard-counter>
      </td>

      <td>
        <app-dashboard-counter
          [label]="t('dashboard.documentsUploaded', { days: metrics.documentsUploaded?.days ?? 0 })"
          [routerLink]="metrics.documentsUploaded ? ['/app/activity'] : null"
          [state]="{
            type: [ActivityType.FileUploaded],
            date: lastDays(metrics.documentsUploaded?.days),
            organization: 'all',
          }"
          [amount]="metrics.documentsUploaded?.value ?? 0"
          icon="pi-file"
        ></app-dashboard-counter>
      </td>

      <td rowspan="2">
        <app-dashboard-list
          [label]="t('dashboard.candidateBirthdays')"
          labelIcon="pi-star"
          [routerLink]="['/app/activity']"
          [state]="{
            type: [ActivityType.CandidateBirthday],
            date: lastDays(0),
            organization: 'all',
          }"
          [items]="metrics.candidateBirthdays?.candidates ?? []"
        >
          <ng-template #item let-candidate>
            <a
              class="grid grid-nogutter align-items-center"
              style="color: white"
              [routerLink]="['/app/candidates/edit', candidate.organizationId, candidate.id]"
            >
              <div class="col-fixed">
                <img class="candidate-img" [src]="candidate | thumbnail | async" default="/assets/user.jpeg" />
              </div>
              <div class="col white-space-nowrap text-overflow-ellipsis overflow-hidden ml-3 mr-3">
                {{ candidate.displayName }}
              </div>
              <div class="col-fixed">({{ candidate.age }})</div>
            </a>
          </ng-template>
          <ng-template #empty>
            <div class="flex flex-column align-items-center justify-content-center h-full">
              {{ t("primeng.emptyMessage") }}
            </div>
          </ng-template>
        </app-dashboard-list>
      </td>

      <td>
        <app-dashboard-chart
          [label]="t('dashboard.candidatesLoggedIn', { days: metrics.candidatesLoggedIn?.days ?? 0 })"
          [routerLink]="metrics.candidatesLoggedIn ? ['/app/activity'] : null"
          [state]="{
            type: [ActivityType.CandidateLoggedIn],
            date: lastDays(metrics.candidatesLoggedIn?.days),
            organization: 'all',
          }"
          type="bar"
          [data]="{
            labels: dayNames,
            datasets: [{ data: metrics.candidatesLoggedIn?.values ?? [], backgroundColor: '#ffffff80' }],
          }"
          [options]="{
            plugins: { legend: { display: false } },
            scales: { x: { ticks: { color: '#ffffff' } }, y: { ticks: { color: '#ffffff', precision: 0 } } },
          }"
        ></app-dashboard-chart>
      </td>
    </tr>

    <tr>
      <td>
        <app-dashboard-counter
          [label]="t('dashboard.candidatesCreated', { days: metrics.candidatesCreated?.days ?? 0 })"
          [routerLink]="metrics.candidatesCreated ? ['/app/activity'] : null"
          [state]="{
            type: [ActivityType.CandidateAdded],
            date: lastDays(metrics.candidatesCreated?.days),
            organization: 'all',
          }"
          [amount]="metrics.candidatesCreated?.value ?? 0"
          icon="pi-user-plus"
        ></app-dashboard-counter>
      </td>

      <td>
        <app-dashboard-counter
          [label]="t('dashboard.documentsExpiring', { days: metrics.documentsExpiring?.days ?? 0 })"
          [routerLink]="metrics.documentsExpiring ? ['/app/candidates/events', 'DocumentExpiry'] : null"
          [amount]="metrics.documentsExpiring?.value ?? 0"
          icon="pi-exclamation-triangle"
        ></app-dashboard-counter>
      </td>

      <td>
        <app-ankaadia-updates
          [updates]="metrics.ankaadiaUpdates?.releases ?? []"
          (popUpSeen)="ankaadiaUpdatesSeen($event)"
        >
        </app-ankaadia-updates>
      </td>
    </tr>
  </table>
</ng-container>
