<ng-container *transloco="let t">
  <div class="wrapper" [class.wrapper-required]="required">
    <p-multiSelect
      #multiSelect
      *ngIf="multi"
      styleClass="w-full"
      [inputId]="inputId"
      [options]="allProfessions | async"
      [ngModel]="model"
      [placeholder]="placeholder ?? t('occupation.placeholder')"
      [disabled]="disabled"
      [pTooltip]="multiSelect.valuesAsString"
      [readonly]="readonly"
      (onBlur)="onModelTouched()"
      (onChange)="professionChanged($event.value, $event.itemValue); updateModel()"
    ></p-multiSelect>

    <p-dropdown
      #dropdown
      *ngIf="!multi"
      styleClass="w-full"
      [inputId]="inputId"
      [options]="allProfessions | async"
      [ngModel]="model"
      [placeholder]="placeholder ?? t('occupation.placeholder')"
      [disabled]="disabled"
      [pTooltip]="dropdown.label"
      [showClear]="!required && showClear"
      [readonly]="readonly"
      (onBlur)="onModelTouched()"
      (onChange)="professionChanged($event.value, null); updateModel()"
    ></p-dropdown>

    <p-button
      class="details"
      [pTooltip]="t('common.details')"
      [disabled]="disabled || readonly"
      icon="pi pi-ellipsis-h"
      styleClass="p-button-rounded p-button-text"
      (onClick)="$event.preventDefault(); onModelTouched(); details.show($event)"
    ></p-button>
  </div>

  <small class="p-error" *ngIf="!criteriaMode && required && (!model || model.length === 0)">
    {{ errorLabel ?? t("profession.required") }}
  </small>

  <p-overlayPanel #details [style]="{ width: '600px' }">
    <ng-template pTemplate>
      <div class="grid">
        <div class="col mw-1">
          <div class="p-fluid grid formgrid">
            <div class="field col-4">
              <label for="area">{{ t("professionArea.title") }}</label>
              <p-dropdown
                inputId="area"
                [options]="areas | async"
                [showClear]="true"
                [placeholder]="t('professionArea.placeholder')"
                [readonly]="readonly"
                (onBlur)="onModelTouched()"
                (onChange)="areaChanged($event.value)"
              ></p-dropdown>
            </div>

            <div class="field col-4">
              <label for="subArea">{{ t("professionSubArea.title") }}</label>
              <p-dropdown
                inputId="subArea"
                [options]="subAreas | async"
                [showClear]="true"
                [placeholder]="t('professionSubArea.placeholder')"
                [readonly]="readonly"
                (onBlur)="onModelTouched()"
                (onChange)="subAreaChanged($event.value)"
              ></p-dropdown>
            </div>

            <div class="field col-4">
              <label for="profession">{{ t("occupation.title") }}</label>
              <p-multiSelect
                *ngIf="multi"
                inputId="profession"
                [options]="professions | async"
                [placeholder]="t('occupation.placeholder')"
                [readonly]="readonly"
                (onChange)="professionChanged($event.value, $event.itemValue)"
              ></p-multiSelect>

              <p-dropdown
                *ngIf="!multi"
                inputId="profession"
                [options]="professions | async"
                [placeholder]="t('occupation.placeholder')"
                [showClear]="true"
                [readonly]="readonly"
                (onChange)="professionChanged($event.value, null)"
              ></p-dropdown>
            </div>
          </div>
        </div>

        <div class="col-fixed field">
          <label>&nbsp;</label>
          <div class="mt-1">
            <p-button
              [label]="t('common.ok')"
              icon="pi pi-check"
              [disabled]="readonly"
              (onClick)="updateModel(); details.hide()"
            ></p-button>
          </div>
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>
</ng-container>
