import { Injectable } from "@angular/core";
import { KeyOf, PickPropertyKeys } from "@ankaadia/ankaadia-shared";
import { isNil } from "lodash";
import { filter, map, merge, Observable, of, Subject } from "rxjs";

interface StorageKeyTypeMap {
  "ai-preview-hidden": boolean;
  "ai-doc-edit-hidden": boolean;
}

export type StorageKey = KeyOf<StorageKeyTypeMap>;
export type BooleanStorageKey = PickPropertyKeys<StorageKeyTypeMap, boolean>;
export type StorageKeyValueType<TKey extends StorageKey> = StorageKeyTypeMap[TKey];

@Injectable({ providedIn: "root" })
export class LocalStorageService {
  private readonly storage: Storage = localStorage;
  private readonly subject = new Subject<StorageKey>();

  observe<TKey extends StorageKey>(key: TKey): Observable<StorageKeyValueType<TKey>> {
    return merge(
      of(this.get(key)),
      this.subject.pipe(
        filter((currentKey) => currentKey === key),
        map((_) => this.get(key))
      )
    );
  }

  flip(key: BooleanStorageKey): void {
    this.set(key, !this.get(key));
  }

  set<TKey extends StorageKey>(key: TKey, value: StorageKeyValueType<TKey>): void {
    const newValue = JSON.stringify(value);
    if (newValue && this.storage.getItem(key) !== newValue) {
      this.storage.setItem(key, newValue);
      this.subject.next(key);
    }
  }

  get<TKey extends StorageKey>(key: TKey): StorageKeyValueType<TKey> | null {
    const item = this.storage.getItem(key);
    return !isNil(item) ? JSON.parse(item) : null;
  }

  remove(key: StorageKey): void {
    if (this.storage.getItem(key)) {
      this.storage.removeItem(key);
      this.subject.next(key);
    }
  }
}
