import { Directive, OnInit } from "@angular/core";
import { DomHandler } from "primeng/dom";
import { Menu } from "primeng/menu";

/**
 * Solves https://github.com/primefaces/primeng/issues/13568.
 *
 * This is fixed in the last version.
 */
@Directive({ selector: "p-menu" })
export class MenuFocusedOptionFixDirective implements OnInit {
  private changeFocusedOptionIndexSource: Menu["changeFocusedOptionIndex"];

  constructor(private readonly menu: Menu) {}

  ngOnInit(): void {
    this.changeFocusedOptionIndexSource = this.menu.changeFocusedOptionIndex;
    this.menu.changeFocusedOptionIndex = (index: number): void => this.changeFocusedOptionIndex(this.menu, index);
  }

  private changeFocusedOptionIndex(menu: Menu, index: number): void {
    const links = DomHandler.find(
      menu.containerViewChild.nativeElement,
      'li[data-pc-section="menuitem"][data-p-disabled="false"]'
    );
    if (!links.length) return;

    return this.changeFocusedOptionIndexSource.call(menu, index);
  }
}
