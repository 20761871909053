import { isSelectedSet } from "@ankaadia/ankaadia-shared";
import { DocumentSetForm, AnyDocumentForm, DocumentForm } from "./document-form.model";

export function isSelectedSetForm(form: DocumentSetForm): boolean {
  const documentForm = extractDocumentForm(form);
  const selectedSets = documentForm.controls.selectedSets.value;
  return selectedSets.some((selectedSet) => isSelectedSet(form.getRawValue(), selectedSet));
}

export function extractDocumentForm(form: AnyDocumentForm): DocumentForm | null {
  if (!form) {
    return null;
  }

  const testedForm = form as DocumentForm;
  const controls = testedForm?.controls;

  if (controls?.id && controls?.organizationId && controls?.writeMode && controls?.requirement) {
    return testedForm;
  }

  return extractDocumentForm(form.parent);
}

export function extractDocumentSetForm(form: AnyDocumentForm): DocumentSetForm | null {
  if (!form) {
    return null;
  }

  const testedForm = form as DocumentSetForm;
  const controls = testedForm?.controls;

  if (controls?.id && controls?.validFrom && controls?.validUntil && controls?.issueDate && controls?.dateOfReceipt) {
    return testedForm;
  }

  return extractDocumentSetForm(form.parent);
}
