import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DocumentSelectionCriterion } from "@ankaadia/graphql";
import { DocumentCriteriaService } from "../document-criteria-config/document-criteria.service";

@Component({
  selector: "app-document-criterion-label",
  templateUrl: "./document-criterion-label.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentCriterionLabelComponent implements OnChanges {
  @Input()
  criterion: DocumentSelectionCriterion;

  label: string;

  constructor(private readonly criteriaService: DocumentCriteriaService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.criterion) {
      this.label = this.criteriaService.getLabel(changes.criterion.currentValue);
    }
  }
}
