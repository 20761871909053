import { Directive, Host, Input, OnChanges, Self, SimpleChanges } from "@angular/core";
import { isArray, isDate } from "lodash";
import { Calendar } from "primeng/calendar";

// https://github.com/primefaces/primeng/issues/6841

/**
 * The PrimeNG Calendar component doesn't handle timezones when using it with date only and showTime=false. This directive does the necessary conversion to make it work.
 *
 * @remarks
 * Date times represent a point in time. They are independent of time zones. The same point in time can be represented in different time zones, thus there is no need to do any conversion.
 *
 * The problem arises when working with dates only, e.g. a birthdate. In this case, the time component is irrelevant. The PrimeNG Calendar component doesn't handle this case correctly.
 *
 * Updating UI from Server:
 *  1. The Server send the date string in UTC. Example: "2023-08-04T00:00:00.000Z"
 *  2. The json date extension converts all dates in local dates: Example: Thu Aug 03 2023 21:00:00 GMT-0300 (Horário Padrão de Brasília). Hence the UTC date and the local date represent the same point in time but in different time zones
 *  3. In writeValue we convert the local date in a local date with the date component taken from UTC only.
 * Hence Thu Aug 03 2023 21:00:00 GMT-0300 (Horário Padrão de Brasília)  becomes Fri Aug 04 2023 00:00:00 GMT-0300 (Horário Padrão de Brasília)
 *
 * Updating Model and Server from UI
 *  1. The UI sends Fri Aug 04 2023 00:00:00 GMT-0300 (Horário Padrão de Brasília)
 *  2. We Convert it to Thu Aug 03 2023 21:00:00 GMT-0300 (Horário Padrão de Brasília) before writing the value to the model
 *  3. When sending the model to the server the json date extension serializes the UTC date string "2023-08-04T00:00:00.000Z"
 * Hence the model always contains a local date/time which becomes the write UTC date when converting it to UTC
 */
@Directive({ selector: "p-calendar" })
export class CalendarUtcFixDirective implements OnChanges {
  private readonly writeValueSource: Calendar["writeValue"];
  private readonly registerOnChangeSource: Calendar["registerOnChange"];
  private onChangeSource: Parameters<Calendar["registerOnChange"]>[0];

  get shouldFix(): boolean {
    return !this.calendar.showTime && this.calendar.dataType === "date";
  }

  @Input()
  showTime?: boolean;

  private originalValue: any;

  private static localDateTimeWhichIsUTCDateValueToLocalDateTimeWhichRepresentsUTCDate(value: Date): Date | Date[] {
    const convert = (date: Date): Date =>
      new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    if (isArray(value)) {
      return value.map((x) => (x ? convert(x) : x)); // selection mode 'range' may have null values
    } else if (isDate(value)) {
      return convert(value);
    } else {
      return value;
    }
  }

  static localDateTimeToUTCDateInLocalDateTime(value: Date): Date | Date[] {
    const convert = (date: Date): Date =>
      new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
    if (isArray(value)) {
      return value.map((x) => (x ? convert(x) : x)); // selection mode 'range' may have null values
    } else if (isDate(value)) {
      return convert(value);
    } else {
      return value;
    }
  }

  private static truncate(
    datePart: "hours" | "minutes" | "seconds" | "milliseconds",
    value: null | Date[] | Date
  ): any {
    function truncateSeconds(date: Date): Date {
      switch (datePart) {
        case "hours":
          date.setHours(0, 0, 0, 0);
          break;
        case "minutes":
          date.setMinutes(0, 0, 0);
          break;
        case "seconds":
          date.setSeconds(0, 0);
          break;
        case "milliseconds":
          date.setMilliseconds(0);
          break;
      }
      return date;
    }

    if (isDate(value)) {
      return truncateSeconds(value);
    }

    if (Array.isArray(value)) {
      return value.map(truncateSeconds);
    }

    return value;
  }

  constructor(@Host() @Self() private readonly calendar: Calendar) {
    this.writeValueSource = this.calendar.writeValue;
    this.calendar.writeValue = (value): void => this.writeValue(value);

    this.registerOnChangeSource = this.calendar.registerOnChange;
    this.calendar.registerOnChange = (fn): void => this.registerOnChange(fn);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showTime && !changes.showTime.firstChange) {
      this.writeValue(this.originalValue);
    }
  }

  private writeValue(value: any): void {
    value = CalendarUtcFixDirective.truncate("seconds", value);
    this.originalValue = value;

    if (this.shouldFix) {
      if (value) {
        value = CalendarUtcFixDirective.localDateTimeToUTCDateInLocalDateTime(value);
      }
    }
    return this.writeValueSource.call(this.calendar, value);
  }

  private registerOnChange(fn: typeof this.onChangeSource): void {
    this.onChangeSource = fn;
    fn = (value: any): void => this.onChange(value);
    return this.registerOnChangeSource.call(this.calendar, fn);
  }

  private onChange(value: any): void {
    value = CalendarUtcFixDirective.truncate("seconds", value);
    this.originalValue = value;

    if (this.shouldFix) {
      value = CalendarUtcFixDirective.localDateTimeWhichIsUTCDateValueToLocalDateTimeWhichRepresentsUTCDate(value);
    }

    return this.onChangeSource(value);
  }
}
