import { Injectable } from "@angular/core";
import { FormArray, FormBuilder, FormControl, ValidationErrors, Validators } from "@angular/forms";
import { RequiredVaccinations, RequiredVaccinationsEntryAt, RequiredVaccinationsEntryDe } from "@ankaadia/graphql";
import { cloneDeep, groupBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  VaccinationFormAT,
  VaccinationFormDE,
  VaccinationFormEntryAT,
  VaccinationFormEntryDE,
  VaccinesConfigForm,
} from "./required-vaccinations-form.model";

const MAX_PROFESSIONS = 10;

@Injectable({ providedIn: "root" })
export class RequiredVaccinationsFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createFullForm(data: RequiredVaccinations | null): VaccinesConfigForm {
    return this.formBuilder.group<VaccinesConfigForm["controls"]>({
      id: this.formBuilder.control(data?.id ?? uuidv4(), Validators.required),
      organizationId: this.formBuilder.control(data?.organizationId, Validators.required),
      _etag: this.formBuilder.control(data?._etag),
      de: this.formBuilder.group<VaccinationFormDE["controls"]>({
        configs: this.formBuilder.array<VaccinationFormEntryDE>(this.createRows(data?.de?.configs), {
          validators: this.validateForDuplicateRows.bind(this),
        }),
      }),
      at: this.formBuilder.group<VaccinationFormAT["controls"]>({
        configs: this.formBuilder.array<VaccinationFormEntryAT>(this.createRows(data?.at?.configs), {
          validators: this.validateForDuplicateRows.bind(this),
        }),
      }),
    });
  }

  createRow(
    data: RequiredVaccinationsEntryAt | RequiredVaccinationsEntryDe | null
  ): VaccinationFormEntryDE | VaccinationFormEntryAT {
    return this.formBuilder.group<VaccinationFormEntryDE["controls"]>({
      federalState: this.formBuilder.control(
        data?.federalState,
        Validators.compose([this.onlyAllValidator, Validators.required])
      ),
      countryOfOrigin: this.formBuilder.control(
        data?.countryOfOrigin,
        Validators.compose([this.onlyAllValidator, Validators.required])
      ),
      employer: this.formBuilder.control(
        data?.employer,
        Validators.compose([this.onlyAllValidator, Validators.required])
      ),
      id: this.formBuilder.control(
        data?.id ?? uuidv4(),
        Validators.compose([this.onlyAllValidator, Validators.required])
      ),
      vaccines: this.formBuilder.control(
        data?.vaccines,
        Validators.compose([this.onlyAllValidator, Validators.required])
      ),
      mandatoryVaccines: this.formBuilder.control(data?.mandatoryVaccines ?? false, Validators.required),
      profession: this.formBuilder.control(
        data?.profession,
        Validators.compose([Validators.required, Validators.maxLength(MAX_PROFESSIONS), this.onlyAllValidator])
      ),
      vaccineFunction: this.formBuilder.control(
        data?.vaccineFunction,
        Validators.compose([this.onlyAllValidator, Validators.required])
      ),
    });
  }

  private getUniqueEntries(
    data: VaccinationFormEntryAT["value"] | VaccinationFormEntryDE["value"]
  ): UniqueRequiredVaccineEntry[] {
    if (this.rowIsInvalidFilledOut(data)) return [];
    return data.countryOfOrigin.flatMap((countryOfOrigin) =>
      data.federalState.flatMap((federalState) =>
        data.profession.flatMap((profession) =>
          data.employer.flatMap((employer) =>
            data.vaccines.flatMap((vaccine) =>
              data.vaccineFunction.flatMap((vaccineFunction) => ({
                countryOfOrigin,
                federalState,
                profession,
                vaccine,
                vaccineFunction,
                employer,
              }))
            )
          )
        )
      )
    );
  }

  private validateForDuplicateRows(
    formArray: FormArray<VaccinationFormEntryAT | VaccinationFormEntryDE>
  ): null | ValidationErrors {
    formArray.controls.forEach((x) => x.setErrors(null));

    const allCombinations = formArray.controls.map((row) => ({
      control: row,
      uniqueEntries: this.getUniqueEntries(row.value),
    }));

    const allRows = allCombinations.flatMap((x) =>
      x.uniqueEntries.map((y) => ({ control: x.control, uniqueEntries: y, hash: Object.values(y).join(",") }))
    );

    const grouped = groupBy(allRows, (x) => x.hash);

    const duplicate = Object.values(grouped).find((value) => value.length > 1);
    if (duplicate) {
      duplicate.forEach((row) => row.control.setErrors({ duplicate: null }));

      return {
        duplicate: cloneDeep(duplicate[0].uniqueEntries),
      };
    }

    return null;
  }

  private onlyAllValidator(allControl: FormControl): null | ValidationErrors {
    return (<string[]>allControl.value ?? []).includes("ALL") && (<string[]>allControl.value ?? []).length > 1
      ? { onlyAll: true }
      : null;
  }

  private rowIsInvalidFilledOut(data: Partial<RequiredVaccinationsEntryDe | RequiredVaccinationsEntryAt>): boolean {
    return (
      !data?.countryOfOrigin ||
      !data.federalState ||
      !data.profession ||
      !data.vaccines ||
      !data.vaccineFunction ||
      !data.employer
    );
  }

  private createRows(data: RequiredVaccinationsEntryDe[]): VaccinationFormEntryDE[];
  private createRows(data: RequiredVaccinationsEntryAt[]): VaccinationFormEntryAT[];
  private createRows(
    data: RequiredVaccinationsEntryDe[] | RequiredVaccinationsEntryAt[]
  ): VaccinationFormEntryDE[] | VaccinationFormEntryAT[] {
    return data?.map((entry: RequiredVaccinationsEntryDe | RequiredVaccinationsEntryAt) => this.createRow(entry)) ?? [];
  }
}

export interface UniqueRequiredVaccineEntry {
  federalState: string;
  countryOfOrigin: string;
  vaccine: string;
  profession: string;
  vaccineFunction: string;
  employer: string;
}
