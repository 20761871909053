import { CandidateStatus, StaticDataModel } from "@ankaadia/graphql";
import { StaticDataService } from "../../../shared/static-data/static-data.service";

export const SortedCandidateStatus: CandidateStatus[] = [
  CandidateStatus.New,
  CandidateStatus.Approved,
  CandidateStatus.Associated,
  CandidateStatus.Matched,
  CandidateStatus.Transferred,
  CandidateStatus.InRecognition,
  CandidateStatus.LabourMigrationFinished,
  CandidateStatus.Paused,
  CandidateStatus.Rejected,
  CandidateStatus.Archived,
  CandidateStatus.DroppedOut,
];

export const getSortedCandidateStatusList = (
  staticDataService: StaticDataService,
  language?: string
): StaticDataModel[] => {
  return staticDataService.transformEnumToStaticDataModel("CandidateStatus", CandidateStatus, {
    language,
    include: SortedCandidateStatus,
  });
};
