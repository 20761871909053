import { Component, OnInit } from "@angular/core";
import { DocumentTypeOverwrite, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService, translate } from "@ngneat/transloco";
import { clone } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { forkJoin } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { AppDatePipe } from "../../../shared/pipes/date.pipe";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { TableColumn, TableOperation } from "../../../shared/table/table.model";
import { MessageService } from "../../message/message.service";
import { CustomDocumentTypesService } from "./custom-document-types.service";
import { StaticDataPipe } from "../../../shared/static-data/static-data.pipe";

@Component({
  selector: "app-custom-document-types",
  templateUrl: "./custom-document-types.component.html",
})
export class CustomDocumentTypesComponent implements OnInit {
  private readonly language = this.transloco.getActiveLang();
  private allOtherTypes: StaticDataModel[];

  newOperations: TableOperation[] = [
    {
      label: translate("common.new"),
      icon: PrimeIcons.PLUS,
      operation: (): void => this.addDoc(),
      disabled: (): boolean => this.isAvailableFileTypes,
    },
  ];

  tableOperations: TableOperation[] = [
    {
      label: translate("common.edit"),
      icon: PrimeIcons.PENCIL,
      operation: (x: DocumentTypeOverwrite): void => this.edit(x),
    },
    {
      label: translate("common.delete"),
      icon: PrimeIcons.TRASH,
      operation: (x: DocumentTypeOverwrite, e: Event): void => this.delete(x, e),
    },
  ];

  columns: TableColumn[] = [
    {
      header: translate("customDocumentTypes.documentName"),
      fieldname: "name",
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("customDocumentTypes.documentDescription"),
      fieldname: "description",
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("customDocumentTypes.documentFile"),
      fieldname: "fileName",
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("customDocumentTypes.overwrittenDocumentType"),
      fieldname: "type",
      sortable: true,
      includeInGlobalFilter: true,
      pipeName: StaticDataPipe,
      pipeArgs: [StaticDataType.AllowedUploadFileTypes, null, null, false, false],
    },
    { header: translate("changedBy.title"), fieldname: "changedBy", sortable: true, includeInGlobalFilter: true },
    {
      header: translate("changedAt.title"),
      fieldname: "changedAt",
      sortable: true,
      includeInGlobalFilter: true,
      pipeName: AppDatePipe,
      pipeArgs: [{ dateStyle: "short", timeStyle: "short" }],
    },
  ];

  isEdit = false;
  showDialog = false;
  selectedDoc: DocumentTypeOverwrite;
  invalidTypes = [];
  availableFileTypes: { value: string; label: string }[] = [];
  staticTypeNames: string[];
  staticFileNames: string[];
  documentSets: DocumentTypeOverwrite[];
  warningMessage: string;
  existingTypes: StaticDataModel[];

  get isAvailableFileTypes(): boolean {
    return this.availableFileTypes && !this.availableFileTypes.length;
  }

  constructor(
    private readonly customDocumentTypesService: CustomDocumentTypesService,
    private readonly settings: SettingsService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    forkJoin([
      this.staticDataService.getStaticData(StaticDataType.AllowedUploadFileTypes, this.language, null, false),
      this.staticDataService.getStaticData(StaticDataType.FileType2FileNameMapping, this.language, null, false),
    ]).subscribe(([fileTypes, fileNames]) => {
      this.staticFileNames = fileNames.map((d) => d.label);
      this.staticTypeNames = fileTypes.map((d) => d.label);
      this.existingTypes = fileTypes;
      this.allOtherTypes = fileTypes.filter((t) => t.value.includes("OTHER"));
      this.getAllDocumentSets();
    });
  }

  getAllDocumentSets(): void {
    this.showDialog = false;
    this.selectedDoc = null;
    this.customDocumentTypesService.getAll(this.settings.organizationId).subscribe((docs) => {
      this.documentSets = clone(docs);
      this.invalidTypes = docs.map((d) => d.type);
      this.updateAvailableFileTypes();
      this.checkForDuplicatedNames(docs);
    });
  }

  addDoc(): void {
    this.updateAvailableFileTypes();

    this.selectedDoc = {
      id: uuidv4(),
      organizationId: this.settings.organizationId,
      name: null,
      description: null,
      changedBy: null,
      changedAt: null,
      fileName: null,
      type: null,
    };
    this.isEdit = false;
    this.showDialog = true;
  }

  edit(doc: DocumentTypeOverwrite): void {
    this.customDocumentTypesService.get(doc.id, this.settings.organizationId).subscribe((document) => {
      this.updateAvailableFileTypes(document.type);

      this.selectedDoc = document;
      this.showDialog = true;
      this.isEdit = true;
    });
  }

  delete(doc: DocumentTypeOverwrite, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("customDocumentTypes.confirmDelete"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () =>
        this.customDocumentTypesService.delete(doc).subscribe(() => {
          this.messageService.add({ severity: "success", summary: translate("customDocumentTypes.deleted") });
          this.getAllDocumentSets();
        }),
    });
  }

  save(doc: DocumentTypeOverwrite): void {
    this.customDocumentTypesService.update({ ...doc, language: this.language }).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: this.isEdit ? translate("customDocumentTypes.updated") : translate("customDocumentTypes.added"),
      });

      this.getAllDocumentSets();
    });
  }

  close(): void {
    this.showDialog = false;
    this.isEdit = false;
    this.selectedDoc = null;
  }

  updateAvailableFileTypes(type?: string): void {
    this.availableFileTypes = this.allOtherTypes?.filter(
      (t) => !this.invalidTypes?.includes(t.value) || (type && type === t.value)
    );
  }

  checkForDuplicatedNames(docs: DocumentTypeOverwrite[]): void {
    const duplicatedNames = docs
      .filter((d) => this.existingTypes.find((et) => et.value != d.type && et.label == d.name))
      ?.map((d) => d.name);
    if (duplicatedNames?.length > 0) {
      this.warningMessage = this.transloco.translate("customDocumentTypes.duplicated", {
        names: duplicatedNames.join(", "),
      });
    }
  }
}
