import { isObject, transform } from "lodash";

export function omitDeep(object: any, keys: (string | number | symbol)[]): any {
  if (object instanceof Date) {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map((value) => omitDeep(value, keys));
  }

  if (!isObject(object)) {
    return object;
  }

  return transform(
    object,
    function (result: any, value: any, key: string | number | symbol) {
      if (keys.includes(key)) {
        return result;
      }
      result[key] = omitDeep(value, keys);
      return result;
    },
    {}
  );
}
