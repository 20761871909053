import { nameofFactory, SubmissionDate } from "@ankaadia/ankaadia-shared";
import { TranslateParams, translate as transloco } from "@jsverse/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";

export interface SubmissionDateContext {}

export function submissionDateFormFactory(_context: SubmissionDateContext, language: string): FormlyFieldConfig[] {
  const nameOf = nameofFactory<SubmissionDate>();
  const translate = <T>(key: TranslateParams, params?: Record<string, any>): T => transloco<T>(key, params, language);
  return [
    {
      className: "block m-1",
      fieldGroup: [
        {
          key: nameOf("date"),
          type: "datepicker",
          props: {
            label: translate("date.title"),
            showIcon: true,
            inline: false,
            minDate: new Date(1900, 0, 1),
            required: true,
            hideRequiredMarker: true,
            blocklyType: "field_date",
          },
        },
      ],
    },
  ];
}
