import { Injectable } from "@angular/core";
import { KnownConfigTokens } from "@ankaadia/ankaadia-shared";
import { filter, first, map, Observable, of, pipe, tap } from "rxjs";
import { OrganizationsService } from "../features/organizations/organizations.service";
import { SettingsService } from "../shared/services/settings.service";
import { DefaSpecific } from "./defa/defa-specifics";
import { DefaultSpecific } from "./default/default-specifics";
import { DemoSpecific } from "./demo/demo-specifics";
import { GueteSpecific } from "./guetegemeinschaft/guete-specifics";
import { MedBestSpecific } from "./medbest/medbest-specifics";
import { OrganizationSpecific } from "./organization-specific";
import { SigniteSpecific } from "./signite/signite-specifics";
import { VidactaSpecific } from "./vidacta/vidacta-specifics";
import { StFranziskusSpecific } from "./stFranziskus/stFranzikus-specifics";
import { AlfaSpecific } from "./alfa/alfa-specifics";
import { MedwingSpecific } from "./medwing/medwing-specifics";
import { OneaSpecific } from "./onea/onea-specifics";
import { VoelkerschuleSpecific } from "./voelkerschule/voelkerschule-specifics";

@Injectable({ providedIn: "root" })
export class OrganizationFactoryService {
  private static readonly tokenCache = new Map<string, string>();

  constructor(
    private readonly settings: SettingsService,
    private readonly organizationService: OrganizationsService,
    private readonly defaultSpecifics: DefaultSpecific,
    private readonly defaSpecifics: DefaSpecific,
    private readonly signiteSpecifics: SigniteSpecific,
    private readonly demoSpecifics: DemoSpecific,
    private readonly guteSpecifics: GueteSpecific,
    private readonly medBestSpecifics: MedBestSpecific,
    private readonly vidactaSpecific: VidactaSpecific,
    private readonly stFranziskus: StFranziskusSpecific,
    private readonly alfaSpecific: AlfaSpecific,
    private readonly medwingSpecific: MedwingSpecific,
    private readonly oneaSpecific: OneaSpecific,
    private readonly voelkerschuleSpecific: VoelkerschuleSpecific
  ) {}

  getOrganizationSpecifics(organizationId: string): Observable<OrganizationSpecific> {
    let retVal: Observable<string>;
    if (organizationId == this.settings.organizationId) {
      retVal = this.settings.isAuthorized$.pipe(
        filter((x) => x),
        pipe(first()),
        map(() => this.settings.configToken)
      );
    } else if (OrganizationFactoryService.tokenCache.has(organizationId)) {
      retVal = of(OrganizationFactoryService.tokenCache.get(organizationId));
    } else {
      if (organizationId != null) {
        retVal = this.organizationService.getOrganization(organizationId).pipe(
          map((x) => x.configToken),
          tap((x) => OrganizationFactoryService.tokenCache.set(organizationId, x))
        );
      } else {
        retVal = of("");
      }
    }
    return retVal.pipe(map((x) => this.createSpecific(x)));
  }

  private createSpecific(configToken: string): OrganizationSpecific {
    configToken = configToken ?? "";
    switch (<KnownConfigTokens>configToken.toUpperCase()) {
      case KnownConfigTokens.DEMO:
        return this.demoSpecifics;
      case KnownConfigTokens.DEFA:
        return this.defaSpecifics;
      case KnownConfigTokens.ONEACARE:
        return this.oneaSpecific;
      case KnownConfigTokens.SIGNITE:
        return this.signiteSpecifics;
      case KnownConfigTokens.GUETEGEMEINSCHAFT:
        return this.guteSpecifics;
      case KnownConfigTokens.MEDBEST:
        return this.medBestSpecifics;
      case KnownConfigTokens.VIDACTA:
        return this.vidactaSpecific;
      case KnownConfigTokens.STFRANZISKUS:
        return this.stFranziskus;
      case KnownConfigTokens.ALFAPC:
        return this.alfaSpecific;
      case KnownConfigTokens.MEDWING:
        return this.medwingSpecific;
      case KnownConfigTokens.VOELKERSCHULE:
        return this.voelkerschuleSpecific;
      default:
        return this.defaultSpecifics;
    }
  }
}
