<ng-container *transloco="let t">
  <div class="text" #text>
    <ng-content></ng-content>
  </div>
  <div class="show-more">
    <a href (click)="$event.preventDefault(); toggleReadMore()" *ngIf="isEllipsisActive">{{
      isCollapsed ? t("common.showMore") : t("common.showLess")
    }}</a>
  </div>
</ng-container>
