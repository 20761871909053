import { PipeTransform } from "@angular/core";
import { Constructor, HeadParameters, TailParameters, UserPermission } from "@ankaadia/ankaadia-shared";
import { MenuItem } from "primeng/api";
import { Observable } from "rxjs";

export interface TableTag {
  label: string;
  value?: any;
  styleClass?: string;
  children?: TableTag[];
}

export interface StartOperationType {
  event: Event;
  rowData: any;
  operation: (item: unknown, event?: unknown) => void;
}

export enum TableOperationMode {
  Button = "Button",
  Menu = "Menu",
  SplitButton = "SplitButton",
}

export interface TableMenuItem extends MenuItem {
  operation: (item: unknown, event?: unknown) => void;
  canOperate?: (item: unknown) => boolean;
}

export class PipeDescription<TPipe extends PipeTransform> {
  readonly pipeArguments: TailParameters<TPipe["transform"]>;

  constructor(
    readonly pipeType: Constructor<TPipe>,
    ...pipeArguments: TailParameters<TPipe["transform"]>
  ) {
    this.pipeArguments = pipeArguments;
  }

  transform(pipeInstance: TPipe, param: HeadParameters<TPipe["transform"]>): ReturnType<TPipe["transform"]> {
    return pipeInstance.transform(param, ...this.pipeArguments);
  }
}

export interface TableColumn {
  header: string;
  fieldname: string;
  sortable?: boolean;
  includeInGlobalFilter?: boolean;
  fullWidthTags?: boolean;
  routeLink?: (item: unknown) => any[] | null;
  tags?: (item: unknown) => Observable<TableTag[]>;
  tagClick?: (item: unknown, value: any) => void;
  icon?: (item: unknown) => string | null;
  iconClick?: (item: unknown, event: Event) => void;
  /**
   * Normally, when {@link icon} or {@link tags} are provided, the field value is not displayed in the column.
   * This flag will disable this behavior and the value will be displayed.
   */
  forceDisplayField?: boolean;
  canIconClick?: (item: unknown) => boolean;
  tooltip?: (item: unknown) => string;
  includeFlag?: boolean;
  default?: string;
  className?: (item: unknown) => string | null;
  permission?: UserPermission;
  isLoading?: (item: unknown) => boolean;

  pipeDescription?: PipeDescription<any>;
}

export interface TableOperation {
  icon: string | ((item: unknown) => string);
  label: string;
  mode?: TableOperationMode;
  items?: TableMenuItem[] | ((item: unknown) => TableMenuItem[]);
  styleClass?: string;
  operation: (item: unknown, event?: unknown) => void;
  disabled?: boolean | ((item: unknown) => boolean);
  canOperate?: (item: unknown) => boolean;
  permission?: UserPermission;
}

export type SideBarSizeType = "medium" | "large" | "full";

export interface TableCacheEntry {
  pipeDescription: PipeDescription<any>;
  pipeInstance: PipeTransform;
  values: Record<string, string>;
}

export interface TableCache {
  entries: TableCacheEntry[];
  columns: TableColumn[];
  language: string;
}
