import { ChangeDetectionStrategy, Component, Input, OnChanges, Optional, SimpleChanges } from "@angular/core";
import { getDocumentSelectionDependencies } from "@ankaadia/ankaadia-shared";
import { DocumentSelectionCriterion, StaticDataModel } from "@ankaadia/graphql";
import { OverlayPanel } from "primeng/overlaypanel";
import { DocumentForm } from "../document-form.model";
import { DocumentCriteriaService } from "./document-criteria.service";

@Component({
  selector: "app-document-criteria-config",
  templateUrl: "./document-criteria-config.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentCriteriaConfigComponent implements OnChanges {
  readonly allCriteria = this.criteriaService.getAllCriteria();

  @Input()
  form: DocumentForm;

  selectedCriteria: StaticDataModel[];

  get unselectedCriteria(): StaticDataModel[] {
    return this.allCriteria.filter((x) => !this.selectedCriteria?.some((y) => y.value === x.value));
  }

  constructor(
    @Optional() private readonly overlayPanel: OverlayPanel,
    private readonly criteriaService: DocumentCriteriaService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form) {
      this.selectedCriteria = changes.form.currentValue.controls.selectionCriteria.value?.map((x) =>
        this.allCriteria.find((y) => y.value === x)
      );
    }
  }

  addCriterion(value: DocumentSelectionCriterion): void {
    this.selectedCriteria = [...(this.selectedCriteria ?? []), this.allCriteria.find((x) => x.value === value)];
  }

  removeCriterion(value: DocumentSelectionCriterion): void {
    this.selectedCriteria = this.selectedCriteria?.filter((x) => x.value !== value);
  }

  save(): void {
    const oldCriteria = this.form.controls.selectionCriteria.value;
    const newCriteria = this.selectedCriteria?.map((x) => x.value as DocumentSelectionCriterion);
    this.fixupCriteria(newCriteria);
    this.updateSelectionValues(newCriteria, oldCriteria);
    this.form.controls.selectionCriteria.setValue(newCriteria ?? null);
    this.form.controls.selectionCriteria.markAsDirty();
    this.overlayPanel?.hide();
  }

  cancel(): void {
    this.overlayPanel?.hide();
  }

  private updateSelectionValues(newCriteria: string[], oldCriteria: string[]): void {
    for (const documentSet of this.form.controls.documentSets.controls) {
      for (const file of documentSet.controls.files.controls) {
        const oldValues = file.controls.selectionValues.value;
        const newValues = newCriteria?.map((x) => {
          const i = oldCriteria?.indexOf(x);
          return i > -1 ? oldValues[i] : null;
        });
        file.controls.selectionValues.setValue(newValues ?? null);
      }
    }
  }

  private fixupCriteria(criteria: DocumentSelectionCriterion[]): void {
    // if a child criterion is selected, ensure parent criterion is also selected
    for (const dependency of getDocumentSelectionDependencies()) {
      const parentLessCriteria = criteria.filter(
        (criterion) => dependency.children.includes(criterion) && !criteria.includes(dependency.parent)
      );

      if (parentLessCriteria.length) {
        const minIndex = Math.min(...parentLessCriteria.map((criterion) => criteria.indexOf(criterion)));
        criteria.splice(minIndex, 0, dependency.parent);
      }
    }
  }
}
