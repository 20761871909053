import { Injectable } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { Observable } from "rxjs";
import { MessageDialogComponent } from "./message-dialog.component";

@Injectable({ providedIn: "root" })
export class MessageDialogService {
  constructor(private readonly dynamicDialog: DialogService) {}

  showMessage(header: string, message: string | string[]): Observable<any> {
    return this.dynamicDialog.open(MessageDialogComponent, {
      width: "50%",
      header: header,
      data: { messages: Array.isArray(message) ? message : [message] },
    }).onClose;
  }
}
