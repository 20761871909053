import { Injectable } from "@angular/core";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { ANKAADIA_VERSION } from "@ankaadia/ankaadia-shared";
import { VersionGQL } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { filter, Subject } from "rxjs";
import { MessageDialogService } from "../message-dialog/message-dialog.service";

@Injectable({ providedIn: "root" })
export class PromptUpdateService {
  minorUpdateAvailable$ = new Subject<boolean>();

  checkForSoftwareUpdate(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate();
    }
  }

  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly version: VersionGQL,
    private readonly msgDialogService: MessageDialogService
  ) {
    if (!this.swUpdate.isEnabled) {
      // eslint-disable-next-line no-console
      console.log("Service worker is not enabled");
    } else {
      // eslint-disable-next-line no-console
      console.log("Service worker is enabled");
    }
    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"))
      .subscribe(() => {
        // eslint-disable-next-line no-console
        console.log("New version available");
        this.version.fetch().subscribe((data) => {
          if (data.data.version != ANKAADIA_VERSION) {
            const patchVersionNrBackend = Number.parseInt(data.data.version.split(".")[2]);
            if (patchVersionNrBackend == 0 || patchVersionNrBackend % 2 == 0) {
              // eslint-disable-next-line no-console
              console.log("Major update available");
              this.minorUpdateAvailable$.next(true);
              const timeToReload = 30;
              this.msgDialogService
                .showMessage(
                  translate("user.version.title"),
                  translate("user.version.message", { timeToReload: timeToReload })
                )
                .subscribe(() => {
                  setTimeout(() => {
                    window.location.reload();
                  }, 30000);
                });
            } else {
              // eslint-disable-next-line no-console
              console.log("Minor update available with uneven patch number");
              this.minorUpdateAvailable$.next(true);
            }
          } else {
            // eslint-disable-next-line no-console
            console.log("Minor update available with unchanged version");
          }
        });
      });
  }
}
