import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-email-view-encapsulation",
  templateUrl: "./email-viewencapsulation.component.html",
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class EmailViewEncapsulationComponent {
  @Input()
  isEditing = false;

  @Input()
  emailHtml: string;

  @ViewChild("bodyEl")
  bodyEl: ElementRef<HTMLDivElement>;

  get innerEmailHTML(): string {
    return this.bodyEl.nativeElement.innerHTML;
  }
}
