<form [formGroup]="form" class="user-form" (ngSubmit)="submit()" *transloco="let t">
  <p-card [header]="isEdit ? t('customDocumentTypes.edit') : t('customDocumentTypes.create')">
    <ng-template pTemplate="header"> </ng-template>
    <div class="p-fluid">
      <div class="field">
        <label for="type">{{ t("fileType.title") }}</label>
        <p-dropdown
          inputId="type"
          [options]="availableFileTypes"
          filterBy="label"
          [required]="true"
          [placeholder]="t('fileType.placeholder')"
          formControlName="type"
        >
        </p-dropdown>
        <small class="p-error" *ngIf="form.controls.type.errors?.required">
          {{ t("fileType.required") }}
        </small>
      </div>
      <div class="field">
        <label for="name">{{ t("name.title") }}</label>
        <input
          type="text"
          pInputText
          id="name"
          [placeholder]="t('name.placeholder')"
          formControlName="name"
          maxlength="100"
        />
        <small class="p-error" *ngIf="form.controls.name.errors?.required">
          {{ t("name.required") }}
        </small>
        <small class="p-error" *ngIf="form.controls.name.errors?.unique">
          {{ t("name.unique") }}
        </small>
      </div>

      <div class="field">
        <label for="description">{{ t("description.title") }}</label>
        <textarea
          pInputTextarea
          formControlName="description"
          id="description"
          [placeholder]="t('description.placeholder')"
          [autoResize]="true"
          autoResizeFix
          [rows]="5"
          [cols]="30"
          [maxlength]="1000"
        ></textarea>
      </div>
      <div class="field">
        <label for="fileName">{{ t("fileName.title") }}</label>
        <input
          type="text"
          pInputText
          id="fileName"
          [placeholder]="t('fileName.placeholder')"
          formControlName="fileName"
          maxlength="100"
        />
        <small class="p-error" *ngIf="form.controls.fileName.errors?.required">
          {{ t("fileName.required") }}
        </small>
        <small class="p-error" *ngIf="form.controls.fileName.errors?.valid">
          {{ t("fileName.notValid") }}
        </small>
        <small class="p-error" *ngIf="form.controls.fileName.errors?.unique">
          {{ t("fileName.unique") }}
        </small>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <div class="flex flex-row justify-content-end">
        <p-button
          [disabled]="!form.valid || !form.dirty || disableSubmit"
          [label]="t('common.save')"
          icon="pi pi-check"
          type="submit"
          class="mr-2"
        ></p-button>
        <p-button
          closeOnEscape
          [label]="t('common.cancel')"
          icon="pi pi-times"
          class="p-button-secondary"
          type="reset"
          (onClick)="closed.emit()"
        ></p-button>
      </div>
    </ng-template>
  </p-card>
</form>
