import { Injectable } from "@angular/core";
import { DateFormatOptions, Locale, TranslocoLocaleService, ValidDate } from "@jsverse/transloco-locale";
import { AppDateFormatOptions, AppDateTimeFormatOptions } from "../pipes/date.pipe";
import { CalendarUtcFixDirective } from "../primeng/calendar-utc-fix/calendar-utc-fix.directive";
import { isNil } from "lodash";

@Injectable({ providedIn: "root" })
export class DateFormatterService {
  protected currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor(private readonly localeService: TranslocoLocaleService) {}

  /**
   * Transforms a date to a string. The date needs to be in the form 2024-10-02T00:00:00.000Z in order to be displayed as October 2nd 2024.
   * @param value The date to transform.
   * @param options The options to use for formatting the date.
   * @param locale The locale to use for formatting the date.
   */
  transformDate(value: ValidDate, options?: AppDateFormatOptions, locale?: Locale): string {
    if (value instanceof Date) {
      value = CalendarUtcFixDirective.localDateTimeToUTCDateInLocalDateTime(value) as ValidDate;
    }

    return this.localizeDate(value, options, locale);
  }

  /**
   * Transforms a datetime to a string.
   * @param value The datetime to transform.
   * @param options The options to use for formatting the datetime.
   * @param locale The locale to use for formatting the datetime.
   */
  transformDateTime(value: ValidDate, options?: AppDateTimeFormatOptions, locale?: Locale): string {
    return this.localizeDate(value, options, locale) + (options.showTimeZone ? ` (${this.currentTimeZone})` : "");
  }

  private localizeDate(date: ValidDate, options: DateFormatOptions = {}, locale?: Locale): string {
    if (isNil(date)) return "";

    locale = locale || this.localeService.getLocale();

    return this.localeService.localizeDate(date, locale, options);
  }
}
