import { InterviewAppointmentSchedule, nameofFactory } from "@ankaadia/ankaadia-shared";
import { translate as transloco, TranslateParams } from "@ngneat/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { DateFormatterService } from "../../../shared/services/date-formatter.service";
import { DatePipeOptionsBuilder } from "../../../shared/pipes/date.pipe";

export interface InterviewAppointmentScheduleContext {
  formatter: DateFormatterService;
}

export function interviewAppointmentScheduleFormFactory(
  context: InterviewAppointmentScheduleContext,
  language: string
): FormlyFieldConfig[] {
  const nameOf = nameofFactory<InterviewAppointmentSchedule>();
  const translate = <T>(key: TranslateParams, params?: Record<string, any>): T => transloco<T>(key, params, language);
  return [
    {
      className: "block m-1",
      fieldGroup: [
        { key: nameOf("dates") },
        {
          key: nameOf("date"),
          type: "dropdown",
          props: {
            filter: true,
            filterBy: "label",
            label: translate("meetingDate.title"),
            placeholder: translate("meetingDate.placeholder"),
            options: [],
            required: true,
            readonlyFormat: { dateStyle: "short", timeStyle: "short" },
            hideRequiredMarker: true,
            prePopulateSingleOption: true,
            blocklyType: "field_date",
          },
          expressionProperties: {
            "props.options": (model: InterviewAppointmentSchedule) =>
              model.dates
                .filter((x) => x.selected)
                .map((x) => ({
                  label: context.formatter.transform(
                    x.date,
                    { dateStyle: "short", timeStyle: "short" },
                    language,
                    DatePipeOptionsBuilder.default.withShowTimeZone(true).options
                  ),
                  value: x.date,
                })),
            "validation.show": (model) => !model?.date,
          },
        },
        {
          key: nameOf("link"),
          type: "input",
          props: {
            label: translate("meetingLink.title"),
            placeholder: translate("meetingLink.placeholder"),
            type: "text",
            maxLength: 1000,
            required: true,
            hideRequiredMarker: true,
            blocklyType: "String",
          },
        },
      ],
    },
  ];
}
