import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ActivityType } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { noop } from "lodash";

@Component({
  selector: "app-activity-type-selector",
  templateUrl: "./activity-type-selector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ActivityTypeSelectorComponent, multi: true }],
})
export class ActivityTypeSelectorComponent implements ControlValueAccessor {
  readonly types = [
    { label: translate("enum.ActivityType.CandidateAdded"), value: ActivityType.CandidateAdded },
    { label: translate("enum.ActivityType.CandidateUpdated"), value: ActivityType.CandidateUpdated },
    { label: translate("enum.ActivityType.CandidateDeleted"), value: ActivityType.CandidateDeleted },
    { label: translate("enum.ActivityType.CandidateDeletionReminder"), value: ActivityType.CandidateDeletionReminder },
    { label: translate("enum.ActivityType.CandidateRequestDeletion"), value: ActivityType.CandidateRequestDeletion },
    { label: translate("enum.ActivityType.CandidateBirthday"), value: ActivityType.CandidateBirthday },
    { label: translate("enum.ActivityType.CandidateBirthdaySoon"), value: ActivityType.CandidateBirthdaySoon },
    { label: translate("enum.ActivityType.FileUploaded"), value: ActivityType.FileUploaded },
    { label: translate("enum.ActivityType.FileModified"), value: ActivityType.FileModified },
    { label: translate("enum.ActivityType.FileDeleted"), value: ActivityType.FileDeleted },
    { label: translate("enum.ActivityType.FileExpiresSoon"), value: ActivityType.FileExpiresSoon },
    { label: translate("enum.ActivityType.FreeFormatFileUploaded"), value: ActivityType.FreeFormatFileUploaded },
    { label: translate("enum.ActivityType.FreeFormatFileDeleted"), value: ActivityType.FreeFormatFileDeleted },
    {
      label: translate("enum.ActivityType.DocumentSetMetaDataModified"),
      value: ActivityType.DocumentSetMetaDataModified,
    },

    { label: translate("enum.ActivityType.CollectionAdded"), value: ActivityType.CollectionAdded },
    { label: translate("enum.ActivityType.CollectionDeleted"), value: ActivityType.CollectionDeleted },
    { label: translate("enum.ActivityType.CollectionUpdated"), value: ActivityType.CollectionUpdated },
    {
      label: translate("enum.ActivityType.CandidateAddedToCollection"),
      value: ActivityType.CandidateAddedToCollection,
    },
    {
      label: translate("enum.ActivityType.CandidateRemovedFromCollection"),
      value: ActivityType.CandidateRemovedFromCollection,
    },
    {
      label: translate("enum.ActivityType.CollectionEmbeddedIntoCollection"),
      value: ActivityType.CollectionEmbeddedIntoCollection,
    },
    {
      label: translate("enum.ActivityType.CollectionRemovedFromCollection"),
      value: ActivityType.CollectionRemovedFromCollection,
    },
    { label: translate("enum.ActivityType.SharingAdded"), value: ActivityType.SharingAdded },
    { label: translate("enum.ActivityType.SharingDeleted"), value: ActivityType.SharingDeleted },
    { label: translate("enum.ActivityType.SharingUpdated"), value: ActivityType.SharingUpdated },
    { label: translate("enum.ActivityType.SharingEnabled"), value: ActivityType.SharingEnabled },
    { label: translate("enum.ActivityType.SharingDisabled"), value: ActivityType.SharingDisabled },
    { label: translate("enum.ActivityType.SharingTypeModified"), value: ActivityType.SharingTypeModified },
    { label: translate("enum.ActivityType.ProcessCreated"), value: ActivityType.ProcessCreated },
    { label: translate("enum.ActivityType.ProcessDeleted"), value: ActivityType.ProcessDeleted },
    { label: translate("enum.ActivityType.TaskManuallyCreated"), value: ActivityType.TaskManuallyCreated },
    { label: translate("enum.ActivityType.TaskDeleted"), value: ActivityType.TaskDeleted },
    { label: translate("enum.ActivityType.TaskStarted"), value: ActivityType.TaskStarted },
    { label: translate("enum.ActivityType.TaskFinished"), value: ActivityType.TaskFinished },
    { label: translate("enum.ActivityType.TaskCancelled"), value: ActivityType.TaskCancelled },
    { label: translate("enum.ActivityType.TaskFailed"), value: ActivityType.TaskFailed },
    { label: translate("enum.ActivityType.TaskReminderSent"), value: ActivityType.TaskReminderSent },
    { label: translate("enum.ActivityType.EmailNotDelivered"), value: ActivityType.EmailNotDelivered },
    { label: translate("enum.ActivityType.CandidateLoggedIn"), value: ActivityType.CandidateLoggedIn },
    { label: translate("enum.ActivityType.CandidateLoggedIn"), value: ActivityType.UserLoggedIn },
    { label: translate("enum.ActivityType.FeedbackProvided"), value: ActivityType.FeedbackProvided },
    { label: translate("enum.ActivityType.CandidateDroppedOut"), value: ActivityType.CandidateDroppedOut },
    {
      label: translate("enum.ActivityType.AutoProcessOperationTriggered"),
      value: ActivityType.AutoProcessOperationTriggered,
    },
    { label: translate("enum.ActivityType.TaskNoteAdded"), value: ActivityType.TaskNoteAdded },
    {
      label: translate("enum.ActivityType.ProcessModelReleaseCreated"),
      value: ActivityType.ProcessModelReleaseCreated,
    },
    { label: translate("enum.ActivityType.ProcessModelPublished"), value: ActivityType.ProcessModelPublished },
    { label: translate("enum.ActivityType.ProcessModelUnpublished"), value: ActivityType.ProcessModelUnpublished },
    { label: translate("enum.ActivityType.ProcessModelDeleted"), value: ActivityType.ProcessModelDeleted },
    { label: translate("enum.ActivityType.CandidateOptedOut"), value: ActivityType.CandidateOptedOut },
    { label: translate("enum.ActivityType.CandidateOptedIn"), value: ActivityType.CandidateOptedIn },
    { label: translate("enum.ActivityType.CandidateNotFound"), value: ActivityType.CandidateNotFound },
    { label: translate("enum.ActivityType.UserNotFound"), value: ActivityType.UserNotFound },
    { label: translate("enum.ActivityType.CollectionNotFound"), value: ActivityType.CollectionNotFound },
    { label: translate("enum.ActivityType.OrganizationNotFound"), value: ActivityType.OrganizationNotFound },
    { label: translate("enum.ActivityType.ProcessNotFound"), value: ActivityType.ProcessNotFound },
    { label: translate("enum.ActivityType.DocumentNotFound"), value: ActivityType.DocumentNotFound },
    { label: translate("enum.ActivityType.TaskNotFound"), value: ActivityType.TaskNotFound },
    {
      label: translate("enum.ActivityType.InvalidSharingTemplate"),
      value: ActivityType.InvalidSharingTemplate,
    },
    {
      label: translate("enum.ActivityType.InvalidUserGroupRoleMapping"),
      value: ActivityType.InvalidUserGroupRoleMapping,
    },
    { label: translate("enum.ActivityType.OrganizationNotLinked"), value: ActivityType.OrganizationNotLinked },
    { label: translate("enum.ActivityType.EducationExamCreated"), value: ActivityType.EducationExamCreated },
    { label: translate("enum.ActivityType.EducationExamUpdated"), value: ActivityType.EducationExamUpdated },
    { label: translate("enum.ActivityType.EducationExamDeleted"), value: ActivityType.EducationExamDeleted },
    {
      label: translate("enum.ActivityType.EducationExamResultCreated"),
      value: ActivityType.EducationExamResultCreated,
    },
    {
      label: translate("enum.ActivityType.EducationExamResultDeleted"),
      value: ActivityType.EducationExamResultDeleted,
    },
    { label: translate("enum.ActivityType.CandidateAddedToExam"), value: ActivityType.CandidateAddedToExam },
    { label: translate("enum.ActivityType.CandidateRemovedFromExam"), value: ActivityType.CandidateRemovedFromExam },
  ];

  @Input()
  inputId: string;

  @Input()
  formControl: FormControl;

  model: string[];
  disabled: boolean;
  onModelChange = noop;
  onModelTouched = noop;

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  writeValue(model: string[]): void {
    this.model = model;
    this.changeDetector.markForCheck();
  }

  registerOnChange(onModelChange: () => void): void {
    this.onModelChange = onModelChange;
  }

  registerOnTouched(onModelTouched: () => void): void {
    this.onModelTouched = onModelTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.changeDetector.markForCheck();
  }

  updateModel(model: string[]): void {
    this.model = model;
    this.onModelChange(model);
    this.formControl?.setValue(model);
  }
}
