import { Injectable } from "@angular/core";
import {
  Archive,
  AvailableDocumentTemplate,
  AvailableDocumentTemplateGroup,
  DocumentTemplate,
  DocumentTemplateEditedInput,
  DocumentTemplateForListFragment,
  DocumentTemplateFragmentDoc,
  DocumentTemplateGenerateInput,
  DocumentTemplateStatus,
  DocumentTemplateType,
  DownloadAllDocumentTemplatesForCandidateGQL,
  DownloadAllDocumentTemplatesForCollectionGQL,
  GenerateAllDocumentTemplatesForCandidateGQL,
  GenerateAllDocumentTemplatesForCollectionGQL,
  GenerateDocumentTemplateGQL,
  GenerateReportTemplateGQL,
  GenerateReportTemplateMutation,
  GenerateTemplateReportInput,
  GetAndGenerateNonEditedTemplateGQL,
  GetAvailableDocumentTemplatesGQL,
  GetAvailableReportTemplatesGQL,
  GetAvailableReportTemplatesQuery,
  GetCandidateDocumentTemplatesGQL,
  GetCollectionDocumentTemplatesGQL,
  GetDataStrategiesGQL,
  GetDataStrategyConfigurationFormlyGQL,
  GetDataStrategyGenerationFormlyGQL,
  GetDocumentTemplateGQL,
  KnownPdfFormTokens,
  MarkDocumentTemplateAsEditedGQL,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { Observable, map, tap, combineLatestWith } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataService } from "../../shared/static-data/static-data.service";

@Injectable({ providedIn: "root" })
export class DocumentTemplatesService {
  constructor(
    private readonly settings: SettingsService,
    private readonly templatesAvailable: GetAvailableDocumentTemplatesGQL,
    private readonly templatesForCandidate: GetCandidateDocumentTemplatesGQL,
    private readonly templatesForCollection: GetCollectionDocumentTemplatesGQL,
    private readonly templateGet: GetDocumentTemplateGQL,
    private readonly templateGenerate: GenerateDocumentTemplateGQL,
    private readonly templateMarkAsEdited: MarkDocumentTemplateAsEditedGQL,
    private readonly templatesGenerateForCandidate: GenerateAllDocumentTemplatesForCandidateGQL,
    private readonly templatesGenerateForCollection: GenerateAllDocumentTemplatesForCollectionGQL,
    private readonly templatesDownloadForCandidate: DownloadAllDocumentTemplatesForCandidateGQL,
    private readonly templatesDownloadForCollection: DownloadAllDocumentTemplatesForCollectionGQL,
    private readonly availableReportTemplates: GetAvailableReportTemplatesGQL,
    private readonly generateReportTemplate: GenerateReportTemplateGQL,
    private readonly generateNotEditedTemplate: GetAndGenerateNonEditedTemplateGQL,
    private readonly getDataStrategiesGQL: GetDataStrategiesGQL,
    private readonly getDataStrategyConfigurationFormlyGQL: GetDataStrategyConfigurationFormlyGQL,
    private readonly getDataStrategyGenerationFormlyGQL: GetDataStrategyGenerationFormlyGQL,
    private readonly staticDataService: StaticDataService
  ) {}

  getDataStrategyConfigurationFormly(
    organization: string,
    strategyId: string,
    language: string
  ): Observable<FormlyFieldConfig[]> {
    return this.getDataStrategyConfigurationFormlyGQL
      .fetch(
        { input: { organizationId: organization, dataStrategyId: strategyId, language: language } },
        { fetchPolicy: "cache-first" }
      )
      .pipe(map((x) => x.data.getDataStrategyConfigurationFormly));
  }

  getDataStrategyGenerationFormly(
    organization: string,
    documentId: string,
    strategyId: string,
    language: string
  ): Observable<FormlyFieldConfig[]> {
    return this.getDataStrategyGenerationFormlyGQL
      .fetch(
        {
          input: {
            organizationId: organization,
            dataStrategyId: strategyId,
            language: language,
            documentId: documentId,
          },
        },
        { fetchPolicy: "cache-first" }
      )
      .pipe(map((x) => x.data.getDataStrategyGenerationFormly));
  }

  getDataStrategies(organizationId: string, language: string): Observable<StaticDataModel[]> {
    return this.getDataStrategiesGQL
      .fetch({ input: { organizationId: organizationId, language: language } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getDataStrategies));
  }

  getAvailableReportTemplates(): Observable<GetAvailableReportTemplatesQuery["getAvailableReportTemplates"]> {
    return this.availableReportTemplates
      .fetch({ input: { organizationId: this.settings.organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getAvailableReportTemplates));
  }

  generateReport(
    input: GenerateTemplateReportInput
  ): Observable<GenerateReportTemplateMutation["generateReportTemplate"]> {
    return this.generateReportTemplate.mutate({ input: input }).pipe(map((x) => x.data.generateReportTemplate));
  }

  getAvailableTemplates(
    organizationId: string,
    candidateId: string,
    candidateOrganizationId: string,
    collectionId: string
  ): Observable<AvailableDocumentTemplateGroup[]> {
    return this.templatesAvailable
      .fetch({
        input: {
          organizationId: organizationId,
          candidateId: candidateId,
          candidateOrganizationId: candidateOrganizationId,
          collectionId: collectionId,
        },
      })
      .pipe(map((x) => x.data.getAvailableDocumentTemplates));
  }

  getAllForCandidate(
    candidateId: string,
    candidateOrganizationId: string,
    organizationId: string
  ): Observable<DocumentTemplateForListFragment[]> {
    const query = this.templatesForCandidate.watch(
      { input: { candidateId, candidateOrganizationId, organizationId } },
      { pollInterval: 3000 }
    );
    return query.valueChanges.pipe(
      map((x) => x.data.getCandidateDocumentTemplates),
      tap((xs) => {
        if (xs.every((x) => x.status !== DocumentTemplateStatus.InProgress)) {
          query.stopPolling();
        }
      })
    );
  }

  getAllForCollection(
    collectionId: string,
    organizationId: string,
    templates: AvailableDocumentTemplate[]
  ): Observable<DocumentTemplateForListFragment[]> {
    templates = templates.map(({ __typename, ...x }) => x);
    const query = this.templatesForCollection.watch(
      { input: { collectionId, organizationId, templates } },
      { pollInterval: 3000 }
    );
    return query.valueChanges.pipe(
      map((x) => x.data.getCollectionDocumentTemplates),
      tap((xs) => {
        if (xs.every((x) => x.status !== DocumentTemplateStatus.InProgress)) {
          query.stopPolling();
        }
      })
    );
  }

  get(
    id: string,
    type: DocumentTemplateType,
    organizationId: string,
    parameters?: unknown
  ): Observable<DocumentTemplate> {
    return this.templateGet
      .fetch({ input: { id, type, organizationId, parameters } })
      .pipe(map((x) => x.data.getDocumentTemplate));
  }

  generate(template: DocumentTemplateGenerateInput): Observable<DocumentTemplate> {
    return this.templateGenerate
      .mutate(
        {
          input: {
            candidateId: template.candidateId,
            candidateOrganizationId: template.candidateOrganizationId,
            organizationId: template.organizationId,
            collectionOrganizationId: template.collectionOrganizationId,
            collectionId: template.collectionId,
            name: template.name,
            id: template.id,
            _etag: template._etag,
            type: template.type,
          },
        },
        {
          update: (cache, x) =>
            cache.modify({
              fields: {
                getAllForCandidate: (refs, helper) =>
                  updateApolloCache(template, refs, helper, cache, x.data.generateDocumentTemplate),
                getAllForCollection: (refs, helper) =>
                  updateApolloCache(template, refs, helper, cache, x.data.generateDocumentTemplate),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.generateDocumentTemplate));
  }

  generateAndGetNonEdited(template: DocumentTemplateGenerateInput): Observable<DocumentTemplate> {
    return this.generateNotEditedTemplate
      .mutate(
        {
          input: {
            candidateId: template.candidateId,
            candidateOrganizationId: template.candidateOrganizationId,
            organizationId: template.organizationId,
            name: template.name,
            id: template.id,
            _etag: template._etag,
            type: template.type,
          },
        },
        {
          update: (cache, x) =>
            cache.modify({
              fields: {
                getAllForCandidate: (refs, helper) =>
                  updateApolloCache(template, refs, helper, cache, x.data.getAndGenerateNonEditedTemplate),
                getAllForCollection: (refs, helper) =>
                  updateApolloCache(template, refs, helper, cache, x.data.getAndGenerateNonEditedTemplate),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.getAndGenerateNonEditedTemplate));
  }

  markAsEdited(template: DocumentTemplateEditedInput): Observable<DocumentTemplate> {
    return this.templateMarkAsEdited
      .mutate(
        {
          input: {
            id: template.id,
            organizationId: template.organizationId,
            _etag: template._etag,
            type: template.type,
          },
        },
        {
          update: (cache, x) =>
            cache.modify({
              fields: {
                getAllForCandidate: (refs, helper) =>
                  updateApolloCache(template, refs, helper, cache, x.data.markDocumentTemplateAsEdited),
                getAllForCollection: (refs, helper) =>
                  updateApolloCache(template, refs, helper, cache, x.data.markDocumentTemplateAsEdited),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.markDocumentTemplateAsEdited));
  }

  generateAllForCandidate(
    candidateId: string,
    candidateOrganizationId: string,
    templateOrganizationId: string,
    collectionId: string,
    collectionOrganizationId: string
  ): Observable<DocumentTemplate[]> {
    return this.templatesGenerateForCandidate
      .mutate({
        input: {
          candidateId,
          candidateOrganizationId,
          organizationId: templateOrganizationId,
          collectionId: collectionId,
          collectionOrganizationId: collectionOrganizationId,
        },
      })
      .pipe(map((x) => x.data.generateAllDocumentTemplatesForCandidate));
  }

  generateAllForCollection(
    collectionId: string,
    organizationId: string,
    templates: AvailableDocumentTemplate[]
  ): Observable<DocumentTemplate[]> {
    templates = templates.map(({ __typename, ...x }) => x);
    return this.templatesGenerateForCollection
      .mutate({ input: { collectionId, organizationId, templates } })
      .pipe(map((x) => x.data.generateAllDocumentTemplatesForCollection));
  }

  downloadAllForCandidate(
    candidateId: string,
    candidateOrganizationId: string,
    organizationId: string,
    inAllFormats: boolean
  ): Observable<Archive> {
    return this.templatesDownloadForCandidate
      .fetch({ input: { candidateId, candidateOrganizationId, organizationId, inAllFormats } })
      .pipe(map((x) => x.data.downloadAllDocumentTemplatesForCandidate));
  }

  downloadAllForCollection(
    collectionId: string,
    organizationId: string,
    templates: AvailableDocumentTemplate[],
    inAllFormats: boolean
  ): Observable<Archive> {
    templates = templates.map(({ __typename, ...x }) => x);
    return this.templatesDownloadForCollection
      .fetch({ input: { collectionId, organizationId, templates, inAllFormats } })
      .pipe(map((x) => x.data.downloadAllDocumentTemplatesForCollection));
  }

  getAvailablePdfTemplatesByImmigrationCountryForProcessModel(
    organizationId: string
  ): Observable<{ label: string; items: StaticDataModel[] }[]> {
    const knownPdfFormTokens = this.staticDataService.transformEnumToStaticDataModel(
      "KnownPdfFormTokens",
      KnownPdfFormTokens
    );

    const supportedImmigrationCountryOrdered = [
      SupportedImmigrationCountry[SupportedImmigrationCountry.De],
      SupportedImmigrationCountry[SupportedImmigrationCountry.At],
      SupportedImmigrationCountry[SupportedImmigrationCountry.Unknown],
    ];

    return this.getAvailableTemplates(organizationId, null, null, null).pipe(
      map((groups) =>
        groups
          .filter((group) => group.templates.length > 0)
          .sort((a, b) =>
            supportedImmigrationCountryOrdered.indexOf(a.immigrationCountry) >
            supportedImmigrationCountryOrdered.indexOf(b.immigrationCountry)
              ? 1
              : -1
          )
      ),
      combineLatestWith(this.staticDataService.getStaticData(StaticDataType.SupportedImmigrationCountries)),
      map(([groups, supportedImmigrationCountries]) => {
        return groups.map((group) => {
          return {
            label: supportedImmigrationCountries.find((country) => country.value === group.immigrationCountry).label,
            items: group.templates
              .filter((template) => template.type === DocumentTemplateType.Pdf)
              .map((template) => ({
                label: knownPdfFormTokens.find((token) => token.value == template.name)?.label ?? template.name,
                value: template.name,
              })),
          };
        });
      })
    );
  }
}

function updateApolloCache(input, refs, { storeFieldName }, cache, data): any {
  if (!storeFieldName.includes(input.organizationId)) return refs;
  const ref = cache.writeFragment({
    data: data,
    fragment: DocumentTemplateFragmentDoc,
    fragmentName: "DocumentTemplate",
  });
  if (refs != null && refs.length > 0) {
    return [...refs, ref];
  } else {
    return [ref];
  }
}
