import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { EmailDetailsComponent } from "./email-details/email-details.component";
import { StripAuthLinksPipe } from "./email-details/strip-auth-links.pipe";
import { EmailEventsComponent } from "./email-events/email-events.component";
import { EmailSelectorComponent } from "./email-selector/email-selector.component";
import { EmailsComponent } from "./emails.component";
import { EmailViewEncapsulationComponent } from "./email-viewencapsulation/email-viewencapsulation.component";
import { EmailMessengerMessageComponent } from "./email-messenger-message/email-messenger-message.component";
import { EmailBannedComponent } from "./email-banned/email-banned.component";
import { DocumentsModule } from "../documents/documents.module";

@NgModule({
  imports: [SharedModule, DocumentsModule],
  declarations: [
    EmailsComponent,
    EmailDetailsComponent,
    EmailEventsComponent,
    EmailSelectorComponent,
    EmailBannedComponent,
    EmailViewEncapsulationComponent,
    StripAuthLinksPipe,
    EmailMessengerMessageComponent,
  ],
  providers: [StripAuthLinksPipe],
  exports: [EmailsComponent],
})
export class EmailsModule {}
