import { Injectable } from "@angular/core";
import {
  AreCollectionsFavoriteGQL,
  CollectionIdInput,
  CollectionListFavoriteFragment,
  FavoriteFragment,
  GetFavoriteCandidateIdsGQL,
  GetFavoriteCandidatesGQL,
  GetFavoriteCandidatesQuery,
  IsCandidateFavoriteGQL,
  IsCollectionFavoriteGQL,
  SetFavoriteCandidateGQL,
  SetFavoriteCollectionGQL,
  UnsetFavoriteCandidateGQL,
  UnsetFavoriteCollectionGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

export type FavoriteCandidate = GetFavoriteCandidatesQuery["getFavoriteCandidates"][0];

@Injectable({ providedIn: "root" })
export class FavoriteService {
  constructor(
    private readonly favoriteCandidates: GetFavoriteCandidatesGQL,
    private readonly candidateFavorite: IsCandidateFavoriteGQL,
    private readonly candidateSet: SetFavoriteCandidateGQL,
    private readonly candidateUnset: UnsetFavoriteCandidateGQL,
    private readonly collectionFavorite: IsCollectionFavoriteGQL,
    private readonly collectionsFavorite: AreCollectionsFavoriteGQL,
    private readonly collectionSet: SetFavoriteCollectionGQL,
    private readonly collectionUnset: UnsetFavoriteCollectionGQL,
    private readonly favoriteList: GetFavoriteCandidateIdsGQL
  ) {}

  getFavoriteCandidateIds(): Observable<string[]> {
    return this.favoriteList.fetch().pipe(map((x) => x.data.getFavoriteCandidateIds.map((y) => y.id)));
  }

  getFavoriteCandidates(collectionId?: string, collectionOrganizationId?: string): Observable<FavoriteCandidate[]> {
    return this.favoriteCandidates
      .fetch(collectionId && collectionOrganizationId ? { input: { collectionId, collectionOrganizationId } } : null, {
        fetchPolicy: "no-cache",
      })
      .pipe(map((x) => x.data.getFavoriteCandidates));
  }

  isCandidateFavorite(candidateId: string, candidateOrganizationId: string): Observable<FavoriteFragment> {
    return this.candidateFavorite
      .fetch({ input: { candidateId, candidateOrganizationId } })
      .pipe(map((x) => x.data.isCandidateFavorite));
  }

  setFavoriteCandidate(candidateId: string, candidateOrganizationId: string): Observable<boolean> {
    return this.candidateSet
      .mutate({ input: { candidateId, candidateOrganizationId } })
      .pipe(map((x) => x.data.setFavoriteCandidate.status));
  }

  unsetFavoriteCandidate(candidateId: string, candidateOrganizationId: string): Observable<boolean> {
    return this.candidateUnset
      .mutate({ input: { candidateId, candidateOrganizationId } })
      .pipe(map((x) => x.data.unsetFavoriteCandidate.status));
  }

  isCollectionFavorite(collectionId: string, collectionOrganizationId: string): Observable<FavoriteFragment> {
    return this.collectionFavorite
      .fetch({ input: { collectionId, collectionOrganizationId } })
      .pipe(map((x) => x.data.isCollectionFavorite));
  }

  areCollectionsFavorite(collections: CollectionIdInput[]): Observable<CollectionListFavoriteFragment[]> {
    return this.collectionsFavorite.fetch({ input: { collections } }).pipe(map((x) => x.data.areCollectionsFavorite));
  }

  setFavoriteCollection(collectionId: string, collectionOrganizationId: string): Observable<boolean> {
    return this.collectionSet
      .mutate({ input: { collectionId, collectionOrganizationId } })
      .pipe(map((x) => x.data.setFavoriteCollection.status));
  }

  unsetFavoriteCollection(collectionId: string, collectionOrganizationId: string): Observable<boolean> {
    return this.collectionUnset
      .mutate({ input: { collectionId, collectionOrganizationId } })
      .pipe(map((x) => x.data.unsetFavoriteCollection.status));
  }
}
