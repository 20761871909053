import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { RequiredVaccinationsComponent } from "./required-vaccinations.component";
import { RequiredVaccinationsTableComponent } from "./required-vaccinations-table/required-vaccinations-table.component";

@NgModule({
  declarations: [RequiredVaccinationsComponent, RequiredVaccinationsTableComponent],
  imports: [SharedModule],
})
export class RequiredVaccinationsModule {}
