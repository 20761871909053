import { ChangeDetectorRef, Injectable } from "@angular/core";
import {
  declaredDocumentSetTypes,
  DocumentMode,
  DocumentWriteMode,
  filterDocumentFormats,
  firstOrDefault,
  getSetByType,
  getSets,
  getTags,
  hasDeclaredDocumentSetTypes,
  isSelectedSet,
  singleOrDefault,
} from "@ankaadia/ankaadia-shared";
import {
  Candidate,
  CandidateFamilyMember,
  Document,
  DocumentRequirement,
  StaticDataModel,
  StaticDataType,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { first, isEmpty, isEqual, isNil, orderBy, uniq, values } from "lodash";
import {
  distinctUntilChanged,
  forkJoin,
  map,
  MonoTypeOperatorFunction,
  Observable,
  of,
  retry,
  shareReplay,
  switchMap,
  tap,
} from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { VOID } from "../../shared/functions/rxjs-extra";
import { removeControls, updateValueAndValidity } from "../../shared/services/form.helper";
import { safeForkJoin } from "../../shared/services/safe-fork-join";
import { StaticDataService } from "../../shared/static-data/static-data.service";
import { ForeignKeyData } from "../candidate-document-metadata/candidate-document-metadata.model";
import { CandidateDocumentMetadataService } from "../candidate-document-metadata/candidate-document-metadata.service";
import { CandidatesService } from "../candidates/candidates.service";
import { DocumentRequirementService } from "../document-requirements/document-requirement.service";
import { DocumentForm } from "../documents/document-form.model";
import { DocumentFormBuilder, DocumentFormService } from "../documents/document-form.service";
import { DocumentsService } from "../documents/documents.service";
import { FavoriteService } from "../favorite/favorite.service";
import {
  CandidateEntry,
  DocumentDropZoneForm,
  DocumentDropZoneRow,
  DocumentDropZoneRowForm,
  SetMetadata,
} from "./document-dropzone-form.model";
import {
  areDocumentsEqual,
  areFilesEqual,
  areSetsEqual,
  extractDocumentDetails,
  isCandidateRecommended,
  isCollectionInformation,
  isDocumentTypeRecommended,
  isFamilyMemberRecommended,
} from "./document-dropzone.functions";
import {
  CandidateDictionary,
  CollectionInformation,
  DigitalDocumentRequirement,
  DocumentMetadata,
  DropzoneRowOptionsDictionary,
  GetDocumentByTypeParameters,
  GetFormDocumentByType,
} from "./document-dropzone.model";

function retryTwice<T>(): MonoTypeOperatorFunction<T> {
  return retry({ count: 2, delay: 1000 });
}

@Injectable({ providedIn: "root" })
export class DocumentDropzoneOptionsStoreFactory {
  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly candidateService: CandidatesService,
    private readonly favoriteService: FavoriteService,
    private readonly documentService: DocumentsService,
    private readonly documentFormService: DocumentFormService,
    private readonly documentMetadataService: CandidateDocumentMetadataService,
    private readonly documentRequirementService: DocumentRequirementService
  ) {}

  create(
    changeDetectorRef: ChangeDetectorRef,
    candidateOrCollection: CandidateEntry | CollectionInformation,
    processLanguage: string,
    digitalDocumentRequirements?: DigitalDocumentRequirement[],
    getDocumentByType?: GetFormDocumentByType
  ): DocumentDropzoneOptionsStore {
    return new DocumentDropzoneOptionsStore(
      changeDetectorRef,
      this.staticDataService,
      this.candidateService,
      this.favoriteService,
      this.documentService,
      this.documentFormService,
      this.documentMetadataService,
      this.documentRequirementService,
      candidateOrCollection,
      processLanguage,
      digitalDocumentRequirements,
      getDocumentByType
    );
  }
}

export class DocumentDropzoneOptionsStore {
  private readonly candidateMap: CandidateDictionary = {};
  private readonly rowMap: DropzoneRowOptionsDictionary = {};
  readonly candidates$: Observable<CandidateEntry[]>;

  get digitalTypesToUpload(): DigitalDocumentRequirement[] {
    return this.digitalDocumentRequirements ?? [];
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly staticDataService: StaticDataService,
    private readonly candidateService: CandidatesService,
    private readonly favoriteService: FavoriteService,
    private readonly documentService: DocumentsService,
    private readonly documentFormService: DocumentFormService,
    private readonly documentMetadataService: CandidateDocumentMetadataService,
    private readonly documentRequirementService: DocumentRequirementService,
    private readonly candidateOrCollection: CandidateEntry | CollectionInformation,
    private readonly processLanguage: string,
    private readonly digitalDocumentRequirements?: DigitalDocumentRequirement[],
    private readonly getDocumentByType?: GetFormDocumentByType
  ) {
    const candidates$ = isCollectionInformation(this.candidateOrCollection)
      ? this.loadCandidateList(this.candidateOrCollection)
      : of([this.candidateOrCollection]);

    this.candidates$ = candidates$.pipe(retryTwice(), shareReplay());
  }

  candidate(form: DocumentDropZoneRowForm): Observable<Candidate> {
    const { candidateEntry } = form.getRawValue();
    return candidateEntry ? this.candidateMap[candidateEntry.id]?.candidate$ : of(null);
  }

  candidateOptions(form: DocumentDropZoneRowForm): Observable<CandidateEntry[]> {
    const { fileId, extraction } = form.getRawValue();
    return this.candidates$.pipe(
      map((candidates) => orderBy(candidates, (candidate) => isCandidateRecommended(candidate, extraction), "desc")),
      map((candidates) => {
        this.rowMap[fileId] ??= { candidates: [], familyMembers: [], documentTypes: [] };
        const existingCandidates = this.rowMap[fileId].candidates;
        if (isEqual(existingCandidates, candidates)) {
          return existingCandidates;
        }

        this.rowMap[fileId].candidates = candidates;
        return candidates;
      })
    );
  }

  familyMemberOptions(form: DocumentDropZoneRowForm): Observable<CandidateFamilyMember[]> {
    const { fileId, candidateEntry, familyMember, extraction } = form.getRawValue();
    const fallback = familyMember ? [familyMember] : [];
    if (!candidateEntry) {
      return of(fallback);
    }

    const familyMembers$ = this.candidateMap[candidateEntry.id]?.candidate$.pipe(
      map((candidate) => candidate.family?.members ?? []),
      map((members) => orderBy(members, (member) => isFamilyMemberRecommended(member, extraction), "desc")),
      map((members) => {
        this.rowMap[fileId] ??= { candidates: [], familyMembers: [], documentTypes: [] };
        const existingFamilyMembers = this.rowMap[fileId].familyMembers;
        if (isEqual(existingFamilyMembers, members)) {
          return existingFamilyMembers;
        }

        this.rowMap[fileId].familyMembers = members;
        return members;
      })
    );

    return familyMembers$ ?? of(fallback);
  }

  documentTypeOptions(
    form: DocumentDropZoneRowForm,
    allowedUploadFileTypes: Record<string, string>
  ): Observable<StaticDataModel[]> {
    const { fileId, documentType, extraction } = form.getRawValue();
    const fallback = documentType ? [{ value: documentType, label: allowedUploadFileTypes[documentType] }] : [];
    return this.getDocumentTypes(form, fallback).pipe(
      map((types) => {
        return !isEmpty(this.digitalDocumentRequirements)
          ? types.filter((type) => this.digitalTypesToUpload.some(({ documentType }) => documentType === type.value))
          : types;
      }),
      map((types) => orderBy(types, (type) => isDocumentTypeRecommended(type, extraction), "desc")),
      map((types) => {
        this.rowMap[fileId] ??= { candidates: [], familyMembers: [], documentTypes: [] };
        const existingTypes = this.rowMap[fileId].documentTypes;
        if (isEqual(existingTypes, types)) {
          return existingTypes;
        }

        this.rowMap[fileId].documentTypes = types;
        return types;
      })
    );
  }

  documentMetadataOptions(form: DocumentDropZoneRowForm): Observable<DocumentMetadata> {
    const { documentSet } = form.getRawValue();
    const selectableSets = documentSet ? [documentSet] : [];
    return this.getDocumentMetadata(form, {
      documentForm: null,
      formats: null,
      availableFormats: null,
      selectableSets,
      hasDocumentSetTypes: false,
      experiencedErrors: false,
    });
  }

  getDocumentMetadata(form: DocumentDropZoneRowForm, fallback: DocumentMetadata = null): Observable<DocumentMetadata> {
    const { candidateEntry, familyMember, documentType } = form.getRawValue();
    const cache = this.candidateMap[candidateEntry?.id];
    const familyCache = cache?.familyMembers[familyMember?.id];
    const candidateDocument$ = cache?.documents[documentType];
    const familyDocument$ = familyCache?.documents[documentType];
    return (familyMember ? familyDocument$ : candidateDocument$) ?? of(fallback);
  }

  createDocumentForm(document: Document, documentRequirement: DocumentRequirement): DocumentForm {
    const builder = this.createDocumentFormBuilder(document);
    const form = builder.createDocumentForm();
    form.controls.writeMode.setValue(DocumentWriteMode.Full);
    form.controls.requirement.setValue(documentRequirement);
    return form;
  }

  registerCandidate(candidateEntry: CandidateEntry): void {
    const { id: candidateId, organizationId } = candidateEntry;
    const candidate$ = this.loadCandidate(candidateId, organizationId).pipe(retryTwice(), shareReplay());
    const documentTypesRequest = { candidateId, organizationId, language: this.processLanguage };
    const documentTypes$ = this.documentService
      .getWritableDocumentTypes(documentTypesRequest)
      .pipe(retryTwice(), shareReplay());

    this.candidateMap[candidateId] ??= { candidate$, documentTypes$, documents: {}, familyMembers: {} };
  }

  registerMetadata(form: DocumentDropZoneForm, ...metadata: DocumentMetadata[]): void {
    this.runOutsideChangeDetection(() => this.registerMetadataInternal(form, metadata));
  }

  reloadDocument(form: DocumentDropZoneForm, rowForm: DocumentDropZoneRowForm): void {
    this.runOutsideChangeDetection(() => this.reloadDocumentInternal(form, rowForm));
  }

  clearFamilyMembers(form: DocumentDropZoneForm): void {
    this.runOutsideChangeDetection(() => {
      form.controls.rows.controls.forEach((row) => {
        row.controls.familyMember.setValue(null);
        this.updateOptionObservables(row);
      });

      return this.fixupForm(form);
    });
  }

  private registerMetadataInternal(
    form: DocumentDropZoneForm,
    metadata: DocumentMetadata[]
  ): Observable<DocumentForm[]> {
    metadata.forEach((meta) => this.registerMetadataEntry(meta));
    return this.fixupForm(form);
  }

  private reloadDocumentInternal(
    form: DocumentDropZoneForm,
    rowForm: DocumentDropZoneRowForm
  ): Observable<DocumentForm[]> {
    const { candidateEntry, documentType, familyMember } = rowForm.getRawValue();
    const cache = this.candidateMap[candidateEntry.id];
    const documentInfo$ = this.buildDocumentInfo(rowForm).pipe(shareReplay());

    if (familyMember) {
      cache.familyMembers[familyMember.id].documents[documentType] = documentInfo$;
    } else {
      cache.documents[documentType] = documentInfo$;
    }

    return this.fixupForm(form);
  }

  updateOptionObservables(rowForm: DocumentDropZoneRowForm): void {
    const { candidateEntry, familyMember } = rowForm.getRawValue();
    if (isNil(candidateEntry)) {
      return;
    }

    this.registerCandidate(candidateEntry);
    const { id: candidateId, organizationId } = candidateEntry;
    if (familyMember) {
      const language = this.processLanguage;
      const request = { candidateId, familyMemberId: familyMember.id, organizationId, language };
      const documentTypes$ = this.documentService.getWritableDocumentTypes(request).pipe(shareReplay());
      this.candidateMap[candidateId].familyMembers[familyMember.id] ??= { documentTypes$, documents: {} };
    }

    const { documentType } = rowForm.getRawValue();
    if (documentType) {
      const cache = this.candidateMap[candidateId];
      const documentInfo$ = this.buildDocumentInfo(rowForm).pipe(shareReplay());

      if (familyMember) {
        cache.familyMembers[familyMember.id].documents[documentType] ??= documentInfo$;
      } else {
        cache.documents[documentType] ??= documentInfo$;
      }
    }
  }

  fixupForm(form: DocumentDropZoneForm): Observable<DocumentForm[]> {
    const rowFixes$ = form.controls.rows.controls.map((row) =>
      this.getFamilyMembers(row).pipe(
        tap((familyMembers) => this.fixupFamilyMember(row, familyMembers)),
        switchMap(() => this.getDocumentTypes(row)),
        tap((types) => this.fixupDocumentType(row, types)),
        switchMap(() => this.getDocumentMetadata(row)),
        tap((documentMeta) => this.fixupDocumentSet(row, documentMeta))
      )
    );

    return safeForkJoin(rowFixes$, []).pipe(
      switchMap(() => this.getDocumentForms()),
      tap((documentForms) => this.updateFileAssociations(form, documentForms)),
      switchMap((documentForms) => {
        const observables = form.controls.rows.controls.map((row) => this.prefillDocumentFormats(row));
        return safeForkJoin(observables, []).pipe(map(() => documentForms));
      })
    );
  }

  private getFamilyMembers(form: DocumentDropZoneRowForm): Observable<CandidateFamilyMember[]> {
    const { candidateEntry } = form.getRawValue();
    const cache = this.candidateMap[candidateEntry?.id];
    return isNil(cache) ? of([]) : cache.candidate$.pipe(map((candidate) => candidate.family?.members ?? []));
  }

  private getDocumentTypes(
    form: DocumentDropZoneRowForm,
    fallback: StaticDataModel[] = []
  ): Observable<StaticDataModel[]> {
    const { candidateEntry, familyMember } = form.getRawValue();
    const cache = this.candidateMap[candidateEntry?.id];
    const familyCache = cache?.familyMembers[familyMember?.id];
    return (familyMember ? familyCache?.documentTypes$ : cache?.documentTypes$) ?? of(fallback);
  }

  private getDocumentForms(): Observable<DocumentForm[]> {
    const documentFormObservables = values(this.candidateMap)
      .flatMap((candidateMeta) => [
        ...values(candidateMeta.documents),
        ...values(candidateMeta.familyMembers).flatMap((familyMemberMeta) => values(familyMemberMeta.documents)),
      ])
      .map((documentInfo$) => documentInfo$.pipe(map(({ documentForm }) => documentForm)));

    return safeForkJoin(documentFormObservables, []);
  }

  private fixupFamilyMember(form: DocumentDropZoneRowForm, familyMembers: CandidateFamilyMember[]): void {
    const { familyMember } = form.getRawValue();
    if (familyMember) {
      form.controls.familyMember.setValue(familyMembers.find(({ id }) => id === familyMember.id) ?? null);
    }
  }

  private fixupDocumentType(form: DocumentDropZoneRowForm, types: StaticDataModel[]): void {
    const { documentType } = form.getRawValue();
    if (documentType) {
      form.controls.documentType.setValue(types.some(({ value }) => value === documentType) ? documentType : null);
    }
  }

  private fixupDocumentSet(form: DocumentDropZoneRowForm, documentMeta: DocumentMetadata): void {
    const { documentSet } = form.getRawValue();
    const sets = documentMeta?.selectableSets ?? [];
    if (documentSet) {
      const equalSet = sets.find((set) => areSetsEqual(set, documentSet));
      form.controls.documentSet.setValue(equalSet ?? null);
    }
  }

  private updateFileAssociations(form: DocumentDropZoneForm, documentForms: DocumentForm[]): void {
    documentForms.forEach((documentForm) => this.updateDocumentFileAssociations(form, documentForm));
  }

  private updateDocumentFileAssociations(form: DocumentDropZoneForm, documentForm: DocumentForm): void {
    const associatedRows = this.getAssociatedRowsForDocument(form, documentForm);
    const documentSets = documentForm.controls.documentSets;
    this.removeDeadFileAssociations(documentForm, associatedRows);

    const builder = this.createDocumentFormBuilder(documentForm.getRawValue());
    associatedRows.forEach(({ file, documentSet }) => {
      const fileForm = builder.createFileForm(file, false);
      fileForm.markAsDirty();

      const existingSet = singleOrDefault(documentSets.controls, (set) => areSetsEqual(set.getRawValue(), documentSet));
      if (existingSet) {
        const existingFiles = existingSet.controls.files;
        if (!existingFiles.controls.some((existingFile) => areFilesEqual(file, existingFile.getRawValue()))) {
          existingSet.controls.files.push(fileForm);
          existingSet.controls.files.markAsDirty();
        }
      } else {
        const newSet = builder.createEmptySetForm({ ...documentSet, original: false });
        newSet.markAsDirty();

        newSet.controls.files.push(fileForm);
        newSet.controls.files.markAsDirty();

        documentSets.push(newSet);
        documentSets.markAsDirty();
      }
    });

    if (!isEmpty(documentSets.controls) && documentSets.controls.every((setForm) => !setForm.value.isDefaultSet)) {
      first(documentSets.controls).controls.isDefaultSet.setValue(true);
    }

    documentForm.controls.selectedSets.setValue(associatedRows.map(({ documentSet }) => documentSet));
    updateValueAndValidity(documentForm);
  }

  private getAssociatedRowsForDocument(form: DocumentDropZoneForm, documentForm: DocumentForm): DocumentDropZoneRow[] {
    const { rows } = form.getRawValue();
    const document = documentForm.getRawValue();
    return rows
      .filter((row) => row.candidateEntry && row.documentType && row.documentSet)
      .filter((row) => areDocumentsEqual(document, extractDocumentDetails(row)));
  }

  private removeDeadFileAssociations(documentForm: DocumentForm, relevantRows: DocumentDropZoneRow[]): void {
    const documentSetsForm = documentForm.controls.documentSets;
    documentSetsForm.controls.forEach((setForm) => {
      const relevantFiles = relevantRows
        .filter((row) => isSelectedSet(setForm.getRawValue(), row.documentSet))
        .map((row) => row.file);

      const filesForm = setForm.controls.files;
      removeControls(filesForm, (file) => {
        return !file.original && relevantFiles.every((relevantFile) => !areFilesEqual(relevantFile, file));
      });
      filesForm.markAsDirty();
    });

    removeControls(documentSetsForm, (set) => !set.original && isEmpty(set.files));
    documentSetsForm.markAsDirty();
  }

  private buildDocumentInfo(form: DocumentDropZoneRowForm): Observable<DocumentMetadata> {
    const { candidateEntry, documentType, familyMember } = form.getRawValue();
    const { organizationId, id: candidateId } = candidateEntry;

    const documentRequirement$ = this.documentRequirementService
      .getByType({ type: documentType, organizationId })
      .pipe(retryTwice());

    const document$ = this.loadDocument(documentType, organizationId, candidateId, familyMember?.id).pipe(
      retryTwice(),
      switchMap((document) =>
        isNil(document)
          ? this.createDocument(organizationId, candidateId, familyMember?.id, documentType)
          : of(document)
      )
    );

    return forkJoin([documentRequirement$, document$]).pipe(
      map(([documentRequirement, document]) => this.createDocumentForm(document, documentRequirement)),
      switchMap((documentForm) => {
        const context = { candidateId, organizationId };
        const formats$ = this.staticDataService
          .getStaticData(StaticDataType.DocumentFormats, this.processLanguage, context)
          .pipe(retryTwice());

        const documentFormats$ = this.documentService.loadDocumentFormats(documentForm).pipe(retryTwice());
        const foreignKeyData$ = this.candidateMap[candidateId].candidate$.pipe(
          switchMap((candidate) =>
            this.documentMetadataService
              .getMetadata({ type: documentType, candidateId, organizationId }, candidate)
              .pipe(retryTwice())
          )
        );

        return forkJoin([formats$, documentFormats$, foreignKeyData$]).pipe(
          map(([formats, documentFormats, foreignKeyData]) => ({
            documentForm,
            formats,
            documentFormats,
            foreignKeyData,
          }))
        );
      }),
      map(({ formats, documentForm, documentFormats, foreignKeyData }) => {
        const rawValue = documentForm.getRawValue();
        const hasDocumentSetTypes = hasDeclaredDocumentSetTypes(documentType);
        const selectableSets = hasDocumentSetTypes
          ? this.getLabelsForTypedSets(rawValue)
          : this.getLabelsForUntypedSets(rawValue, foreignKeyData);

        const availableFormats = documentFormats?.availableFormats;
        const experiencedErrors = false;

        if (isEmpty(this.digitalDocumentRequirements)) {
          return { documentForm, formats, availableFormats, hasDocumentSetTypes, selectableSets, experiencedErrors };
        }

        const validOptions = this.digitalDocumentRequirements
          .filter((requirement) => requirement.documentType === documentType)
          .map((requirement) => {
            if (isNil(requirement.documentSetType)) {
              return selectableSets.find(({ isDefaultSet }) => isDefaultSet) ?? firstOrDefault(selectableSets);
            }
            return selectableSets.find((selectableSet) => selectableSet.type === requirement.documentSetType);
          })
          .filter((selectableSet) => !isNil(selectableSet));

        return {
          documentForm,
          formats,
          availableFormats,
          hasDocumentSetTypes,
          selectableSets: uniq(validOptions),
          experiencedErrors,
        };
      })
    );
  }

  private loadDocument(...params: GetDocumentByTypeParameters): Observable<Document | null> {
    const getViaService$ = this.documentService.getByType(...params);
    if (isNil(this.getDocumentByType)) {
      return getViaService$;
    }

    return this.getDocumentByType(...params).pipe(
      switchMap((document) => (isNil(document) ? getViaService$ : of(document)))
    );
  }

  private createDocument(
    organizationId: string,
    candidateId: string,
    familyMemberId: string,
    type: string
  ): Observable<Document> {
    const mode = DocumentMode.Candidate;
    return this.documentService.createEmptyDocument(mode, organizationId, candidateId, familyMemberId, type).pipe(
      retryTwice(),
      map((document) => {
        if (!hasDeclaredDocumentSetTypes(type)) {
          return document;
        }

        const sets = declaredDocumentSetTypes(type).map((type) => ({ id: uuidv4(), type, isDefaultSet: false }));
        return { ...document, documentSets: [...(document.documentSets ?? []), ...sets] };
      })
    );
  }

  private getLabelsForTypedSets(document: Document): SetMetadata[] {
    const declaredTypes = declaredDocumentSetTypes(document.type) ?? [];
    return declaredTypes.map((type) => {
      const label = translate(`documentSet.types.${type}`);
      const selectedSet = getSetByType(document, type) ?? { id: uuidv4(), isDefaultSet: false };
      return { ...selectedSet, type, label };
    });
  }

  private getLabelsForUntypedSets(document: Document, metadata: ForeignKeyData[]): SetMetadata[] {
    const existingSets = getSets(document);
    const foreignKeys = existingSets.map((set) => set.foreignKey).filter((key) => !isNil(key));
    const unlinkedMeta = metadata.filter((meta) => !foreignKeys.includes(meta.id) && !meta.isSupressed);
    const setProposals = unlinkedMeta.map((meta) => {
      return { id: uuidv4(), isDefaultSet: false, name: null, foreignKey: meta.id };
    });

    const foreignDataMap = [...existingSets, ...setProposals].map((set) => {
      return { set, data: metadata.find(({ id }) => set.foreignKey === id) };
    });

    const orderedMap = orderBy(foreignDataMap, ({ data }) => data?.index ?? -1);
    const newSet = { id: uuidv4(), isDefaultSet: false, name: null, foreignKey: null };

    return [...orderedMap, { set: newSet, data: null }].map(({ set, data }) => {
      const label = data
        ? `#${data.index + 1}: ${data.label}`
        : set.isDefaultSet
          ? translate("documentSet.default")
          : (set.name ?? translate("documentSet.add"));

      return { ...set, type: null, label };
    });
  }

  private loadCandidateList(info: CollectionInformation): Observable<CandidateEntry[]> {
    const { selectedCollectionId, selectedCollectionOrganizationId, selectedSharing, event } = info;
    const newEvent = { ...event, first: 0, rows: 100000 };

    return newEvent.filters?.favorite
      ? this.favoriteService.getFavoriteCandidates(selectedCollectionId, selectedCollectionOrganizationId)
      : this.candidateService
          .getFilteredCandidates(selectedCollectionId, selectedCollectionOrganizationId, newEvent, selectedSharing?.id)
          .pipe(map((x) => x.nodes));
  }

  private loadCandidate(candidateId: string, organizationId: string): Observable<Candidate> {
    if (isCollectionInformation(this.candidateOrCollection) && this.candidateOrCollection.selectedSharing) {
      const selectedSharing = this.candidateOrCollection.selectedSharing;
      const { id: sharingId, organizationId: sharingOrgId } = selectedSharing;
      return this.candidateService.getCandidate(candidateId, organizationId, sharingId, sharingOrgId);
    }

    return this.candidateService.getCandidate(candidateId, organizationId);
  }

  private registerMetadataEntry(meta: DocumentMetadata): void {
    const { candidateId, familyMemberId, type } = meta.documentForm.getRawValue();
    const cache = this.candidateMap[candidateId];
    if (familyMemberId) {
      cache.familyMembers[familyMemberId].documents[type] = of(meta);
    } else {
      cache.documents[type] = of(meta);
    }
  }

  private prefillDocumentFormats(row: DocumentDropZoneRowForm): Observable<void> {
    const { documentSet, file } = row.getRawValue();
    if (!documentSet || !file) {
      return of(VOID);
    }

    const metadata$ = this.getDocumentMetadata(row);
    return metadata$.pipe(
      distinctUntilChanged(),
      map((metadata) => {
        const documentSetControls = metadata?.documentForm?.controls.documentSets.controls ?? [];
        const existingSet = singleOrDefault(documentSetControls, (set) => areSetsEqual(set.getRawValue(), documentSet));
        const fileControls = existingSet?.controls.files.controls ?? [];
        const foundFile = singleOrDefault(fileControls, (control) => areFilesEqual(control.getRawValue(), file));
        const tagsControl = foundFile?.controls.tags;
        if (isNil(tagsControl) || tagsControl.dirty) {
          return;
        }

        const existingTags = uniq(fileControls.flatMap((file) => getTags(file.getRawValue())));
        const desiredTags = this.digitalTypesToUpload
          .filter((x) => x.documentType === row.controls.documentType.value)
          .flatMap((x) => x.fileformats);

        const selectableTags = filterDocumentFormats(metadata.formats, metadata.availableFormats);
        const validOptions = selectableTags
          .filter((tag) => desiredTags.includes(tag.value))
          .filter((tag) => !existingTags.includes(tag.value))
          .map((option) => option.value);

        tagsControl.setValue(uniq([...tagsControl.value, ...validOptions]));
        tagsControl.markAsDirty();
      })
    );
  }

  private createDocumentFormBuilder(document: Document): DocumentFormBuilder {
    const options = { documentMode: document.mode, isReadonly: false };
    return this.documentFormService.createDocumentFormBuilder(document, options);
  }

  private runOutsideChangeDetection<T>(observable: () => Observable<T>): void {
    this.changeDetectorRef.detach();
    observable().subscribe({
      next: () => this.changeDetectorRef.reattach(),
      error: () => this.changeDetectorRef.reattach(),
      complete: () => this.changeDetectorRef.reattach(),
    });
  }
}
