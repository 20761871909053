<div *appTranslate="let t; lang: processLanguage + '|static'" class="flex flex-column h-full">
  <p-messages
    [style.flex]="'0 0 auto'"
    [value]="[{ severity: 'info', key: t('documents.infoHeader.title'), detail: t('documents.infoHeader.message') }]"
  />
  <p-splitter
    class="p-fluid"
    [style.flex]="'1 1 auto'"
    [style.min-height]="0"
    [panelSizes]="[60, 40]"
    [minSizes]="[40, 30]"
    styleClass="h-full"
  >
    <ng-template pTemplate>
      <p-splitter
        layout="vertical"
        styleClass="h-full splitter-panel-overflow"
        [panelSizes]="[60, 40]"
        [minSizes]="[20, 20]"
      >
        <ng-template pTemplate>
          <p-table
            styleClass="p-datatable"
            sortField="type"
            [value]="filteredRows"
            [sortOrder]="-1"
            [customSort]="true"
            (sortFunction)="customSort($event)"
            [selection]="selectedRow"
            (selectionChange)="setSelectedRow($event)"
            dataKey="controls.file.value.name"
            selectionMode="single"
            [tableStyle]="{ 'table-layout': 'fixed' }"
          >
            <ng-template pTemplate="caption">
              <div class="flex flex-column md:flex-row justify-content-between md:align-items-center table-header">
                <div class="flex-1 p-input-icon-left mr-2 table-search">
                  <i class="pi pi-search"></i>
                  <input
                    pInputText
                    [ngModel]="''"
                    (ngModelChange)="searchInput$.next($event)"
                    type="text"
                    [placeholder]="t('common.search')"
                  />
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th class="ai-column">
                  <app-ai-result-indicator style="transform: translate(0px, -2px)" [pTooltip]="t('ai.status')" />
                </th>
                <th class="icon-column">
                  <i class="pi pi-file-check" [pTooltip]="t('file.status')"></i>
                </th>
                <th class="fixed-column-width" pSortableColumn="file">
                  {{ t("file.title") }}
                  <p-sortIcon field="file" />
                </th>
                <th pSortableColumn="candidateEntry">
                  {{ t("candidate.title") }}
                  <p-sortIcon field="candidateEntry" />
                </th>
                <th *ngIf="showFamilyMemberSelection" pSortableColumn="familyMember">
                  {{ t("familyMember.title") }}
                  <p-sortIcon field="familyMember" />
                </th>
                <th>
                  {{ t("documentType.title") }}
                  <i
                    class="pi pi-ellipsis-v cursor-pointer"
                    [pTooltip]="t('documents.columnOptions.title')"
                    (click)="menuDocType.show()"
                  ></i>
                  <app-static-data-selector
                    #menuDocType
                    [options]="documentTypes"
                    (selected)="updateColumn($event, 'documentType')"
                  />
                </th>
                <th>
                  {{ t("documentSet.type.title") }}
                  <i
                    class="pi pi-ellipsis-v cursor-pointer"
                    [pTooltip]="t('documents.columnOptions.title')"
                    (click)="menuDocSet.show()"
                  >
                  </i>
                  <app-static-data-selector
                    #menuDocSet
                    [options]="documentSets"
                    (selected)="updateColumn($event, 'documentSet')"
                  />
                </th>
                <th>
                  {{ t("documentFormat.title") }}
                  <i
                    class="pi pi-ellipsis-v cursor-pointer"
                    [pTooltip]="t('documents.columnOptions.title')"
                    (click)="menuDocFormat.show()"
                  >
                  </i>
                  <app-static-data-multi-selector
                    #menuDocFormat
                    [options]="documentFormats"
                    (selected)="updateColumn($event, 'documentFormat')"
                  />
                </th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-rowData
              let-editing="editing"
              let-index="rowIndex"
              let-expanded="expanded"
            >
              <tr [formGroup]="rowData" [pSelectableRow]="rowData">
                <td class="ai-column text-center">
                  <div [pTooltip]="rowData.controls.extraction.value?.error ?? ''">
                    <app-circular-progress-indicator
                      [progress]="rowData.controls.extraction.value.progress"
                      [failed]="rowData.controls.extraction.value.done && !rowData.controls.extraction.value.success"
                      [size]="14"
                      [strokeWidth]="4"
                    />
                  </div>
                </td>
                <td class="icon-column" [(hover)]="rowData.hovered">
                  <i
                    *ngIf="rowState(rowData) | async as s"
                    class="pi"
                    [style.color]="s.valid ? '#51bd8a' : rowData.hovered || !s.exists ? '#bd5155' : 'Orange'"
                    [pTooltip]="
                      !s.exists ? t('document.unspecified') : s.valid ? t('document.valid') : t('document.invalid')
                    "
                    [ngClass]="{
                      'pi-ban': !rowData.hovered && !s.exists,
                      'pi-exclamation-circle': !rowData.hovered && s.exists && !s.valid,
                      'pi-check-circle': s.exists && s.valid,
                      'pi-trash': rowData.hovered && (!s.exists || !s.valid),
                    }"
                    (click)="removeRow(rowData, s, $event)"
                  >
                  </i>
                </td>
                <td class="fixed-column-width">
                  <div
                    class="mb-1 white-space-nowrap text-overflow-ellipsis overflow-hidden"
                    [pTooltip]="rowData.controls.file.value.name"
                  >
                    {{ rowData.controls.file.value.name }}
                  </div>
                  <div>
                    <small>
                      {{ t("lastModified.title") }}
                      {{
                        rowData.controls.file.value.lastModified
                          | appDateTime: { dateStyle: "short", timeStyle: "medium" } : processLanguage
                      }}
                    </small>
                  </div>
                  <div>
                    <small>
                      {{ t("file.size") }}
                      {{
                        rowData.controls.file.value.size
                          | fileSize
                            : { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }
                            : processLanguage
                      }}
                    </small>
                  </div>
                </td>
                <td>
                  <p-dropdown
                    formControlName="candidateEntry"
                    [options]="store.candidateOptions(rowData) | async"
                    [placeholder]="t('candidate.placeholder')"
                    [showClear]="true"
                    (onChange)="dispatchUpdate(form, rowData)"
                    [virtualScroll]="true"
                    [virtualScrollItemSize]="37.5"
                    [panelStyle]="{ width: '350px' }"
                    panelStyleClass="no-overflow"
                    styleClass="w-full"
                    filterBy="displayId,displayName"
                  >
                    <ng-template pTemplate="item" let-candidate>
                      <div class="flex align-items-center gap-2 overflow-hidden">
                        <app-ai-result-indicator
                          *ngIf="isCandidateRecommended(candidate, rowData.controls.extraction.value)"
                          [pTooltip]="t('ai.recommendation')"
                        />
                        <app-flag *ngIf="candidate.id" [country]="candidate.country" [small]="true"></app-flag>
                        <div
                          class="overflow-hidden white-space-nowrap text-overflow-ellipsis"
                          [pTooltip]="candidate.displayId + ' - ' + candidate.displayName"
                          tooltipPosition="bottom"
                        >
                          {{ candidate.displayId }} - {{ candidate.displayName }}
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="selectedItem" let-candidate>
                      <div class="flex align-items-center gap-2 overflow-hidden">
                        <app-ai-result-indicator
                          *ngIf="isCandidateRecommended(candidate, rowData.controls.extraction.value)"
                          [pTooltip]="t('ai.recommendation')"
                        />
                        <app-flag *ngIf="candidate.id" [country]="candidate.country" [small]="true"></app-flag>
                        <div
                          class="overflow-hidden white-space-nowrap text-overflow-ellipsis"
                          [pTooltip]="candidate.displayId + ' - ' + candidate.displayName"
                          tooltipPosition="bottom"
                        >
                          {{ candidate.displayId }} - {{ candidate.displayName }}
                        </div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </td>
                <td *ngIf="showFamilyMemberSelection">
                  <p-dropdown
                    #familyMemberControl
                    formControlName="familyMember"
                    [options]="store.familyMemberOptions(rowData) | async"
                    [readonly]="isEmpty(familyMemberControl.options)"
                    [placeholder]="t('familyMember.placeholder')"
                    [showClear]="true"
                    (onChange)="dispatchUpdate(form, rowData)"
                    styleClass="w-full"
                    filterBy="firstName,lastName,birthName"
                  >
                    <ng-template pTemplate="item" let-familyMember>
                      <app-ai-result-indicator
                        *ngIf="isFamilyMemberRecommended(familyMember, rowData.controls.extraction.value)"
                        [pTooltip]="t('ai.recommendation')"
                      />
                      {{ getFamilyMemberDisplayName(familyMember) }}
                    </ng-template>
                    <ng-template pTemplate="selectedItem" let-familyMember>
                      <app-ai-result-indicator
                        *ngIf="isFamilyMemberRecommended(familyMember, rowData.controls.extraction.value)"
                        [pTooltip]="t('ai.recommendation')"
                      />
                      {{ getFamilyMemberDisplayName(familyMember) }}
                    </ng-template>
                  </p-dropdown>
                </td>
                <td>
                  <p-dropdown
                    #documentTypeControl
                    formControlName="documentType"
                    [options]="store.documentTypeOptions(rowData, allowedUploadFileTypes) | async"
                    [readonly]="isEmpty(documentTypeControl.options)"
                    [placeholder]="t('documentType.placeholder')"
                    [showClear]="true"
                    (onChange)="dispatchUpdate(form, rowData)"
                    [virtualScroll]="true"
                    [virtualScrollItemSize]="37.5"
                    [panelStyle]="{ width: '350px' }"
                    panelStyleClass="no-overflow"
                    styleClass="w-full"
                    optionValue="value"
                  >
                    <ng-template pTemplate="item" let-documentType>
                      <div class="flex align-items-center gap-2 overflow-hidden">
                        <app-ai-result-indicator
                          *ngIf="isDocumentTypeRecommended(documentType, rowData.controls.extraction.value)"
                          [pTooltip]="t('ai.recommendation')"
                        />
                        <div
                          class="overflow-hidden white-space-nowrap text-overflow-ellipsis"
                          [pTooltip]="documentType.label"
                          tooltipPosition="bottom"
                        >
                          {{ documentType.label }}
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="selectedItem" let-documentType>
                      <div class="flex align-items-center gap-2 overflow-hidden">
                        <app-ai-result-indicator
                          *ngIf="isDocumentTypeRecommended(documentType, rowData.controls.extraction.value)"
                          [pTooltip]="t('ai.recommendation')"
                        />
                        <div
                          class="overflow-hidden white-space-nowrap text-overflow-ellipsis"
                          [pTooltip]="documentType.label"
                          tooltipPosition="bottom"
                        >
                          {{ documentType.label }}
                        </div>
                      </div>
                    </ng-template></p-dropdown
                  >
                </td>
                <td>
                  <p-dropdown
                    #documentSetControl
                    formControlName="documentSet"
                    [options]="(store.documentMetadataOptions(rowData) | async)?.selectableSets"
                    [readonly]="isEmpty(documentSetControl.options)"
                    [placeholder]="t('documentSet.placeholder')"
                    [showClear]="true"
                    (onChange)="dispatchUpdate(form, rowData)"
                    styleClass="w-full"
                    optionLabel="label"
                  />
                </td>
                <td *ngIf="store.documentMetadataOptions(rowData) | async as documentMeta">
                  <p-multiSelect
                    *ngIf="!isSingleDocumentSet(rowData) && tagsControl(rowData) | async as tagsControl; else noTags"
                    [formControl]="tagsControl"
                    [options]="documentMeta.formats | documentSelectorFilter: documentMeta.availableFormats"
                    [showToggleAll]="false"
                    [placeholder]="t('documentFormat.placeholder')"
                    [tooltip]="t('document.tooltipFormat')"
                    [showClear]="true"
                    styleClass="w-full"
                    optionLabel="label"
                    optionValue="value"
                  />
                  <ng-template #noTags>
                    <p-multiSelect
                      [placeholder]="t('documentFormat.placeholder')"
                      [readonly]="true"
                      styleClass="w-full"
                    />
                  </ng-template>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </ng-template>
        <!-- Checks if another file preview exists, since multiple instances of some file previewers are not supported -->
        <ng-template
          pTemplate
          *ngIf="previewableRow && !(hasOverlay$ | async) && !('ai-preview-hidden' | observe | async)"
        >
          <div [ngSwitch]="previewableRow.fileType" class="preview flex flex-column w-full h-full overflow-hidden">
            <div class="file-info">{{ previewableRow.file.name }}</div>
            <app-image-viewer *ngSwitchCase="'image'" class="flex-1 min-h-0" [url]="previewableRow.fileUrl" />
            <ngx-extended-pdf-viewer
              *ngSwitchCase="'pdf'"
              class="flex-1 min-h-0"
              [showDownloadButton]="false"
              [showPrintButton]="false"
              [showPresentationModeButton]="false"
              [showUnverifiedSignatures]="true"
              [showBorders]="false"
              [showInfiniteScrollButton]="false"
              [showBookModeButton]="false"
              [showDrawEditor]="false"
              [showTextEditor]="false"
              [showOpenFileButton]="false"
              [showStampEditor]="false"
              [textLayer]="true"
              [src]="previewableRow.fileUrl"
              [filenameForDownload]="previewableRow.file.name"
              [language]="processLanguage"
            />
            <div *ngSwitchDefault class="no-preview-container flex-1 min-h-0">
              <div class="no-preview-content">
                <i class="no-preview-icon pi pi-eye-slash"></i>
                <div>{{ t("preview.unavailable.title") }}</div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-splitter>
    </ng-template>
    <ng-template pTemplate *ngIf="selectedRow?.controls.documentSet.value && !('ai-doc-edit-hidden' | observe | async)">
      <div
        *ngIf="store.getDocumentMetadata(selectedRow) | async as documentMeta"
        [style.padding]="'0.75rem 0 0.75rem 1rem'"
        [style.overflow-y]="'auto'"
      >
        <div *ngIf="documentMeta.experiencedErrors" class="mb-5">
          <p-message severity="error" styleClass="mb-2" [text]="t('user.concurrency.message')" />
          <p-button
            icon="pi pi-refresh"
            [label]="t('document.reload')"
            (click)="store.reloadDocument(form, selectedRow)"
          />
        </div>

        <app-document-set-selector
          #documentCandidateFilesSelector
          *ngIf="store.candidate(selectedRow) | async as candidate"
          class="block mb-5"
          [form]="documentMeta.documentForm"
          [mode]="DocumentMode.Candidate"
          [expandMetadata]="true"
          [readonly]="false"
          [processLanguage]="processLanguage"
          [candidateFormOrCandidate]="candidate"
          [availableTags]="documentMeta.availableFormats"
          [availablePhysicalFileTypes]="documentMeta.formats"
        />

        <app-document-properties
          [form]="documentMeta.documentForm"
          [language]="processLanguage"
          [readonly]="false"
          [showInternalDoc]="true"
          [showDocumentCompletion]="true"
        />
      </div>
    </ng-template>
  </p-splitter>
</div>
