import { Injectable, RendererFactory2 } from "@angular/core";
import { KeyOf } from "@ankaadia/ankaadia-shared";
import { isEmpty } from "lodash";
import { BehaviorSubject, map, Observable } from "rxjs";

const ElementClassMap = { Sidebar: "p-sidebar-mask" } as const;
export type OverlayElementType = KeyOf<typeof ElementClassMap>;

@Injectable({ providedIn: "root" })
export class OverlayDetectorService {
  readonly detectedOverlays$ = new BehaviorSubject<Element[]>([]);

  constructor(rendererFactory: RendererFactory2) {
    const renderer = rendererFactory.createRenderer(null, null);
    const body: HTMLBodyElement = renderer.selectRootElement("body", true);

    const observer = new MutationObserver(() => this.detectedOverlays$.next(this.hasOverlayAsDirectChild(body)));
    observer.observe(body, { childList: true, subtree: false });
  }

  hasOverlay(...ignoredElementTypes: OverlayElementType[]): Observable<boolean> {
    return this.detectedOverlays$.pipe(
      map((overlays) => overlays.filter((overlay) => !isElementIgnored(overlay, ignoredElementTypes))),
      map((overlays) => !isEmpty(overlays))
    );
  }

  private hasOverlayAsDirectChild(body: HTMLBodyElement): Element[] {
    return Array.from(body.children).filter((child) => child.classList.contains("p-component-overlay"));
  }
}

function isElementIgnored(overlay: Element, ignoredElementTypes: OverlayElementType[]): boolean {
  return ignoredElementTypes.some((type) => overlay.classList.contains(ElementClassMap[type]));
}
