import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ensure4LetterIsoLanguage } from "@ankaadia/ankaadia-shared";
import { Consent } from "@ankaadia/graphql";
import { TranslocoService } from "@ngneat/transloco";
import { TabView } from "primeng/tabview";

@Component({
  selector: "app-tc-consent-view",
  templateUrl: "./tc-consent-view.component.html",
})
export class TcConsentViewComponent {
  readonly ensure4LetterIsoLanguage = ensure4LetterIsoLanguage;

  activeIndex: number;

  @Input()
  set initalIndex(index: number) {
    this.activeIndex = index;
  }

  @Output()
  readonly tabChanged = new EventEmitter<{ language: string; tabIndex: number }>();

  @Input()
  consents: Pick<Consent, "language" | "contents">[];

  @Input()
  viewLanguage: string = this.transloco.getActiveLang();

  @ViewChild("tabs")
  tab: TabView;

  constructor(private readonly transloco: TranslocoService) {}

  onTabChanged(): void {
    this.tabChanged.emit({ language: this.consents[this.activeIndex].language, tabIndex: this.activeIndex });
  }
}
