import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ActivityFragment, ActivityType, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { AppDatePipe } from "../../../shared/pipes/date.pipe";
import { SettingsService } from "../../../shared/services/settings.service";

@Component({
  selector: "app-activity-description",
  templateUrl: "./activity-description.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityDescriptionComponent {
  readonly ActivityType = ActivityType;
  readonly StaticDataType = StaticDataType;

  get organizationId(): string {
    return this.settings.organizationId;
  }

  @Input()
  activity: ActivityFragment;

  constructor(
    private readonly trans: TranslocoService,
    private readonly settings: SettingsService,
    private readonly datePipe: AppDatePipe
  ) {}

  getDocumentSetModifiedChanges(activity: ActivityFragment): string {
    return (activity.parameters.changes ?? [])
      .map(
        (change) =>
          `${this.trans.translate(change.field + ".title")}: ${this.datePipe.transform(change.oldValue, {
            dateStyle: "short",
          })} -> ${this.datePipe.transform(change.newValue, { dateStyle: "short" })}`
      )
      .join(", ");
  }
}
