<p-dropdown
  [placeholder]="to.placeholder"
  [options]="to.options | formlySelectOptions: field | async"
  [formControl]="$any(formControl)"
  [formlyAttributes]="field"
  [showClear]="to.showClear || !to.required"
  [filter]="to.filter"
  [filterBy]="to.filterBy"
  [filterValue]="to.filterValue"
  [filterPlaceholder]="to.filterPlaceholder"
  (onChange)="to.change && to.change(field, $event)"
  [dataKey]="to.dataKey"
  [readonly]="to.readonly"
  [prePopulateSingleOption]="to.prePopulateSingleOption"
></p-dropdown>
