<ng-container *ngIf="menuItems?.length">
  <ng-container *ngIf="menuItems.length === 1">
    <button
      pButton
      type="button"
      icon="pi pi-upload"
      class="p-button-rounded p-button-text"
      [disabled]="to.readonly"
      [pTooltip]="to.tooltip"
      tooltipPosition="left"
      (click)="openDialog(to.types[0])"
    ></button>
  </ng-container>

  <ng-container *ngIf="menuItems.length > 1">
    <p-menu #menu [popup]="true" [model]="menuItems" styleClass="p-menu-wide"></p-menu>
    <button
      pButton
      type="button"
      icon="pi pi-upload"
      class="p-button-rounded p-button-text"
      [disabled]="to.readonly"
      [pTooltip]="to.tooltip"
      tooltipPosition="left"
      (click)="menu.toggle($event)"
    ></button>
  </ng-container>
</ng-container>
