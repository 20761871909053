import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FieldActivityDiffFragment } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";

@Component({
  selector: "app-field-diff",
  templateUrl: "./field-diff.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldDiffComponent {
  readonly language = this.transloco.getActiveLang();

  @Input()
  diff: FieldActivityDiffFragment[];

  constructor(private readonly transloco: TranslocoService) {}
}
