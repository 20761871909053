<div class="flex flex-row justify-content-start">
  <p-inputSwitch
    id="formlyInputSwitch"
    [formControl]="$any(formControl)"
    [formlyAttributes]="field"
    [readonly]="props.readonly"
    (onChange)="to.change && to.change(field, $event)"
  ></p-inputSwitch>
  <label for="formlyInputSwitch" class="ml-2">{{ props.label }}</label>
</div>
