<ng-container *transloco="let t">
  <p-button
    *ngIf="showBtn?.saveAndClose"
    class="edit-save-close-btn"
    icon="pi pi-check"
    type="submit"
    [label]="saveAndCloseBtn?.label ?? t('common.saveAndClose')"
    [disabled]="saveAndCloseBtn?.disabled"
    (onClick)="onSaveAndClose($event)"
  ></p-button>

  <p-button
    *ngIf="showBtn?.save"
    class="edit-save-btn"
    icon="pi pi-check"
    type="submit"
    [label]="saveBtn?.label ?? t('common.save')"
    [disabled]="saveBtn?.disabled"
    (onClick)="onSave($event)"
  ></p-button>

  <p-button
    *ngIf="showBtn?.cancel"
    closeOnEscape
    class="edit-cancel-btn"
    icon="pi pi-times"
    type="reset"
    [label]="cancelBtn?.label ?? t('common.cancel')"
    [disabled]="cancelBtn?.disabled"
    (onClick)="onCancel($event)"
  ></p-button>
</ng-container>
