export interface TravelBooking {
  firstNameInPassport: string;
  nameInPassport: string;
  salutationInPassport: string;
  birthDateInPassport: Date;
  employerName: string;
  emailAddress: string;
  phoneNumber: string;
  arrivalDate: Date;
  arrivalTimeSlot: string;
  arrivalPoint: string;
  departurePoint: string;
  travelVehicle: string[];
  travelVehicleOther: string;
  invoiceAddress: string;
}
