import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { TileMenuComponent } from "./tile-menu.component";

@NgModule({
  imports: [SharedModule],
  declarations: [TileMenuComponent],
  exports: [TileMenuComponent],
})
export class TileMenuModule {}
