import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { translate } from "@ngneat/transloco";
import { FieldArrayType, FormlyFieldConfig } from "@ngx-formly/core";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { TabView, TabViewCloseEvent } from "primeng/tabview";
import { debounceTime } from "rxjs";

@Component({
  selector: "app-formly-repeat-tabs",
  templateUrl: "./formly-repeat-tabs.component.html",
  styleUrls: ["./formly-repeat-tabs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyRepeatTabsComponent extends FieldArrayType implements OnInit {
  validity: Record<number, boolean> = {};
  isDisabled: boolean;

  @ViewChild(TabView)
  tabView: TabView;

  get isAddEnabled(): boolean {
    return !this.to.readonly && this.formControl.enabled && this.to.maxCount > this.field.fieldGroup.length;
  }

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly confirmationService: ConfirmationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.field.fieldGroup.forEach((_tab, i) => (this.validity[i] = true));
    this.field.formControl.statusChanges.pipe(debounceTime(200)).subscribe(() => this.statusChanged());
    this.field.formControl.valueChanges.pipe(debounceTime(100)).subscribe(() => this.valueChanged());
  }

  handleAdd(event: Event): void {
    this.add(null, this.to?.defaultValue?.());
    this.changeDetector.detectChanges();
    this.tabView.open(event, this.tabView.tabs[this.tabView.tabs.length - 1]);
  }

  handleClose(event: TabViewCloseEvent): void {
    this.confirmationService.confirm({
      target: event.originalEvent.target,
      message: translate("addresses.confirmDelete"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.remove(event.index);
        event.close();
        this.tabView.open(event.originalEvent, this.tabView.tabs[0]);
      },
    });
  }

  private valueChanged(): void {
    this.tabView?.updateInkBar();
  }

  private statusChanged(): void {
    this.field.fieldGroup.forEach((group, i) => {
      this.validity[i] = this.isValid(group);
      this.isDisabled = !this.isDisabled;
      this.changeDetector.detectChanges();
      this.isDisabled = !this.isDisabled;
      this.changeDetector.detectChanges();
      this.tabView?.updateInkBar();
    });
  }

  private isValid(field: FormlyFieldConfig): boolean {
    return field.fieldGroup && field.fieldGroup.length > 0
      ? field.fieldGroup.every((f) => this.isValid(f))
      : field.formControl.status !== "INVALID";
  }
}
