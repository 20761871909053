import { MemoizedFunction, memoize } from "lodash";
import { AnyFunction } from "ts-essentials";

export type MemoizedWithTTL = MemoizedFunction & { validUntil: number | null };

export function memoizeWithTTL<T extends AnyFunction>(func: T, ttl: number): T & MemoizedWithTTL {
  const resolver = (...args: Parameters<T>): string => JSON.stringify(args);
  const memoized = memoize(func, resolver);

  const memoizedWithTTL = (...args: Parameters<T>): ReturnType<T> => {
    if (!memoizedWithTTL.validUntil) {
      memoizedWithTTL.validUntil = Date.now() + ttl;
    } else if (Date.now() >= memoizedWithTTL.validUntil) {
      memoizedWithTTL.validUntil = Date.now() + ttl;
      memoizedWithTTL.cache.delete(resolver(...args));
    }

    return memoized(...args);
  };

  memoizedWithTTL.cache = memoized.cache;
  memoizedWithTTL.validUntil = null;

  return memoizedWithTTL as T & MemoizedWithTTL;
}
