import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { PromptUpdateService } from "../services/promptUpdateService";
import { APP_SHA } from "@ankaadia/ankaadia-shared-frontend";

@Injectable({ providedIn: "root" })
export class UpdateRecognizerInterceptor implements HttpInterceptor {
  lastVersionCheck = Date.now();
  constructor(
    @Inject(APP_SHA) private readonly app_git_sha: string,
    readonly promptUpdateService: PromptUpdateService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if ((event as any).headers != null) {
          const sha = (event as HttpResponse<any>).headers.get("X-App-Sha");
          if (sha != null && sha != this.app_git_sha) {
            if (Date.now() - this.lastVersionCheck > 1000 * 30) {
              // Check only every 30 seconds in case there is a mismatch
              this.promptUpdateService.checkForSoftwareUpdate();
              this.lastVersionCheck = Date.now();
            }
          }
        }
      })
    );
  }
}
