<ng-container *transloco="let t">
  <p-table *ngIf="activity" [value]="[activity]">
    <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap">{{ t("process.title") }}</th>
        <th class="white-space-nowrap">{{ t("user.title") }}</th>
        <th class="white-space-nowrap">{{ t("date.title") }}</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-activity>
      <tr>
        <td class="white-space-nowrap">
          <a
            *ngIf="activity.process"
            [routerLink]="['/app/processes/view', activity.process.organizationId, activity.process.id]"
          >
            {{ activity.process.name }}
          </a>
        </td>

        <td class="white-space-nowrap">
          <div>{{ activity.user.name }}</div>
          <div
            *ngIf="
              activity.user.organizationId !== organizationId && activity.user.organizationName !== 'SystemOrganization'
            "
          >
            ({{ activity.user.organizationName }})
          </div>
        </td>

        <td class="white-space-nowrap">
          {{ activity.date | appDate: { dateStyle: "short", timeStyle: "medium" } : language }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
