export const countryCodes: string[] = [
  "AF", // Afghanistan
  "AO", // Angola
  "GQ", // Äquatorialguinea
  "ET", // Äthiopien
  "BD", // Bangladesch
  "BJ", // Benin
  "BF", // Burkina Faso
  "BI", // Burundi
  "CI", // Côte d'Ivoire
  "DJ", // Dschibuti
  "ER", // Eritrea
  "GA", // Gabun
  "GM", // Gambia
  "GH", // Ghana
  "GN", // Guinea
  "GW", // Guinea-Bissau
  "HT", // Haiti
  "YE", // Jemen
  "CM", // Kamerun
  "KI", // Kiribati
  "CD", // Kongo (Demokratische Republik)
  "CG", // Kongo (Republik)
  "LS", // Lesotho
  "LR", // Liberia
  "MG", // Madagaskar
  "MW", // Malawi
  "ML", // Mali
  "MR", // Mauretanien
  "FM", // Mikronesien
  "MZ", // Mosambik
  "NP", // Nepal
  "NE", // Niger
  "NG", // Nigeria
  "PK", // Pakistan
  "PG", // Papua-Neuguinea
  "SB", // Salomonen
  "SN", // Senegal
  "SL", // Sierra Leone
  "SO", // Somalia
  "SD", // Sudan
  //"SS", // Südsudan
  "TZ", // Tansania
  "TG", // Togo
  "TD", // Tschad
  "UG", // Uganda
  "VU", // Vanuatu
  "CF", // Zentralafrikanische Republik
];
