import {
  DestroyRef,
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { BehaviorSubject, debounceTime, distinctUntilChanged } from "rxjs";

export interface Size {
  width: number;
  height: number;
}

@Directive({
  selector: "[resize]",
})
export class ResizeDirective implements OnInit, DoCheck {
  private readonly subject = new BehaviorSubject({ width: 0, height: 0 });

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnInit(): void {
    this.subject
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged((prev, curr) => prev.width === curr.width && prev.height === curr.height),
        debounceTime(100)
      )
      .subscribe((size) => this.resizeChange.emit(size));
  }

  @Input()
  resize: Size = { width: 0, height: 0 };

  @Output()
  readonly resizeChange = new EventEmitter<Size>();

  @HostListener("window:resize")
  onWindowResize(): void {
    this.pushSizeToSubject();
  }

  ngDoCheck(): void {
    this.pushSizeToSubject();
  }

  private pushSizeToSubject(): void {
    this.subject.next({
      width: this.elementRef.nativeElement.offsetWidth,
      height: this.elementRef.nativeElement.offsetHeight,
    });
  }
}
