import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NotificationSettingsForm } from "./notification-configuration-form.model";

@Injectable({ providedIn: "root" })
export class NotificationSettingsFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createForm(): NotificationSettingsForm {
    return this.formBuilder.group<NotificationSettingsForm["controls"]>({
      enabled: this.formBuilder.control(false),
      activities: this.formBuilder.control(null, Validators.required),
      documents: this.formBuilder.control(null),
      scopes: this.formBuilder.control(null, Validators.required),
    });
  }
}
