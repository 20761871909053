import { Injectable } from "@angular/core";
import {
  CreateRequiredDocumentGQL,
  DeleteRequiredDocumentGQL,
  DocumentOverviewInputCandidate,
  DocumentOverviewInputCollection,
  DocumentOverviewInputProcess,
  DuplicateRequiredDocumentGQL,
  GetOverviewOfRequiredDocumentsForCandidateGQL,
  GetOverviewOfRequiredDocumentsForCandidateQuery,
  GetOverviewOfRequiredDocumentsForCollectionGQL,
  GetOverviewOfRequiredDocumentsForCollectionQuery,
  GetOverviewOfRequiredDocumentsForProcessGQL,
  GetOverviewOfRequiredDocumentsForProcessQuery,
  GetRequiredDocumentGQL,
  GetRequiredDocumentsForCandidateGQL,
  GetRequiredDocumentsForOrganizationGQL,
  RequiredDocumentCreateInput,
  RequiredDocumentDeleteInput,
  RequiredDocumentForListFragment,
  RequiredDocumentFragment,
  RequiredDocumentFragmentDoc,
  RequiredDocumentUpdateInput,
  RequiredDocumentUpdateMetaInput,
  UpdateRequiredDocumentGQL,
  UpdateRequiredDocumentMetaGQL,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class RequiredDocumentService {
  constructor(
    private readonly allForOrganization: GetRequiredDocumentsForOrganizationGQL,
    private readonly allForCandidate: GetRequiredDocumentsForCandidateGQL,
    private readonly RequiredDocumentGet: GetRequiredDocumentGQL,
    private readonly RequiredDocumentCreate: CreateRequiredDocumentGQL,
    private readonly RequiredDocumentUpdate: UpdateRequiredDocumentGQL,
    private readonly RequiredDocumentDelete: DeleteRequiredDocumentGQL,
    private readonly RequiredDocumentUpdateMeta: UpdateRequiredDocumentMetaGQL,
    private readonly overviewCandidate: GetOverviewOfRequiredDocumentsForCandidateGQL,
    private readonly overviewCollection: GetOverviewOfRequiredDocumentsForCollectionGQL,
    private readonly overviewProcess: GetOverviewOfRequiredDocumentsForProcessGQL,
    private readonly requiredDocumentDuplicate: DuplicateRequiredDocumentGQL
  ) {}

  getOverviewOfRequiredDocumentsForCandidate(
    input: DocumentOverviewInputCandidate
  ): Observable<GetOverviewOfRequiredDocumentsForCandidateQuery["getOverviewOfRequiredDocumentsForCandidate"]> {
    return this.overviewCandidate
      .fetch({ input: input })
      .pipe(map((x) => x.data.getOverviewOfRequiredDocumentsForCandidate));
  }

  getOverviewOfRequiredDocumentsForProcess(
    input: DocumentOverviewInputProcess
  ): Observable<GetOverviewOfRequiredDocumentsForProcessQuery["getOverviewOfRequiredDocumentsForProcess"]> {
    return this.overviewProcess
      .fetch({ input: input })
      .pipe(map((x) => x.data.getOverviewOfRequiredDocumentsForProcess));
  }

  getOverviewOfRequiredDocumentsForCollection(
    input: DocumentOverviewInputCollection
  ): Observable<GetOverviewOfRequiredDocumentsForCollectionQuery["getOverviewOfRequiredDocumentsForCollection"]> {
    return this.overviewCollection
      .fetch({ input: input })
      .pipe(map((x) => x.data.getOverviewOfRequiredDocumentsForCollection));
  }

  watchAllForOrganization(organizationId: string): Observable<RequiredDocumentForListFragment[]> {
    return this.allForOrganization
      .watch({ input: { organizationId } })
      .valueChanges.pipe(map((x) => x.data.getRequiredDocumentsForOrganization));
  }

  getAllForOrganization(organizationId: string): Observable<RequiredDocumentForListFragment[]> {
    return this.allForOrganization
      .fetch({ input: { organizationId } })
      .pipe(map((x) => x.data.getRequiredDocumentsForOrganization));
  }

  getAllForCandidate(
    candidateId: string,
    candidateOrganizationId: string,
    organizationId: string
  ): Observable<RequiredDocumentForListFragment[]> {
    return this.allForCandidate
      .fetch({ input: { candidateId, candidateOrganizationId, organizationId } })
      .pipe(map((x) => x.data.getRequiredDocumentsForCandidate));
  }

  get(documentSetId: string, organizationId: string): Observable<RequiredDocumentFragment> {
    return this.RequiredDocumentGet.fetch({ input: { id: documentSetId, organizationId: organizationId } }).pipe(
      map((result) => result.data.getRequiredDocument)
    );
  }

  create(template: RequiredDocumentFragment): Observable<RequiredDocumentFragment> {
    const input = new RequiredDocumentCreateInput();
    input.name = template.name;
    input.description = template.description;
    input.immigrationCountry = template.immigrationCountry;
    input.organizationId = template.organizationId;
    input.setsAvailableForCandidates = template.setsAvailableForCandidates;
    input.functions = template.functions;
    return this.RequiredDocumentCreate.mutate(
      { input: input },
      {
        update: (cache, result) =>
          cache.modify({
            fields: {
              getRequiredDocuments: (refs, helper) =>
                updateApolloCache(input.organizationId, refs, helper, cache, result.data.createRequiredDocument),
            },
          }),
      }
    ).pipe(map((result) => result.data.createRequiredDocument));
  }

  duplicate(id: string, organizationId: string): Observable<RequiredDocumentFragment> {
    return this.requiredDocumentDuplicate
      .mutate(
        { input: { organizationId: organizationId, id: id } },
        {
          update: (cache, result) =>
            cache.modify({
              fields: {
                getRequiredDocuments: (refs, helper) =>
                  updateApolloCache(organizationId, refs, helper, cache, result.data.duplicateRequiredDocument),
              },
            }),
        }
      )
      .pipe(map((result) => result.data.duplicateRequiredDocument));
  }

  update(template: RequiredDocumentFragment): Observable<RequiredDocumentFragment> {
    const input = new RequiredDocumentUpdateInput();
    input.id = template.id;
    input._etag = template._etag;
    input.name = template.name;
    input.immigrationCountry = template.immigrationCountry;
    input.description = template.description;
    input.organizationId = template.organizationId;
    input.configData = template.configData;
    input.setsAvailableForCandidates = template.setsAvailableForCandidates;
    input.functions = template.functions;
    return this.RequiredDocumentUpdate.mutate(
      { input: input },
      {
        update: (cache, result) =>
          cache.modify({
            fields: {
              getRequiredDocuments: (refs, helper) =>
                updateApolloCache(input.organizationId, refs, helper, cache, result.data.updateRequiredDocument),
            },
          }),
      }
    ).pipe(map((result) => result.data.updateRequiredDocument));
  }

  updateMeta(template: RequiredDocumentFragment): Observable<RequiredDocumentFragment> {
    const input = new RequiredDocumentUpdateMetaInput();
    input.id = template.id;
    input._etag = template._etag;
    input.name = template.name;
    input.description = template.description;
    input.organizationId = template.organizationId;
    input.setsAvailableForCandidates = template.setsAvailableForCandidates;
    input.functions = template.functions;
    return this.RequiredDocumentUpdateMeta.mutate(
      { input: input },
      {
        update: (cache, result) =>
          cache.modify({
            fields: {
              getRequiredDocuments: (refs, helper) =>
                updateApolloCache(input.organizationId, refs, helper, cache, result.data.updateRequiredDocumentMeta),
            },
          }),
      }
    ).pipe(map((result) => result.data.updateRequiredDocumentMeta));
  }

  delete(template: RequiredDocumentFragment): Observable<boolean> {
    const input = new RequiredDocumentDeleteInput();
    input.id = template.id;
    input._etag = template._etag;
    input.organizationId = template.organizationId;
    return this.RequiredDocumentDelete.mutate(
      { input: input },
      {
        update: (cache) =>
          cache.modify({
            fields: {
              getRequiredDocuments: (refs, { readField }) => refs.filter((ref) => readField("id", ref) !== input.id),
            },
          }),
      }
    ).pipe(map((x) => x.data.deleteRequiredDocument.status));
  }
}

function updateApolloCache(organizationId: string, refs, { storeFieldName }, cache, data): any[] {
  if (!storeFieldName.includes(organizationId)) return refs;
  const ref = cache.writeFragment({
    data: data,
    fragment: RequiredDocumentFragmentDoc,
    fragmentName: "RequiredDocument",
  });
  if (refs?.length > 0) {
    const index = refs.findIndex((r) => r.__ref === ref.__ref);
    if (index > -1) {
      return [...refs.slice(0, index), ref, ...refs.slice(index + 1)];
    } else {
      return [...refs, ref];
    }
  } else {
    return [ref];
  }
}
