import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { finalize } from "rxjs";
import { DownloadService } from "../../../shared/services/download.service";
import {
  DocumentTemplatePreviewComponent,
  saveSupportedByDocumentTemplatePreview,
} from "../document-template-preview/document-template-preview.component";

@Component({
  selector: "app-document-template-preview-dialog",
  styleUrls: ["./document-template-preview-dialog.component.scss"],
  templateUrl: "./document-template-preview-dialog.component.html",
})
export class DocumentTemplatePreviewDialogComponent implements OnChanges {
  @Input()
  url: string;

  @Input()
  fileName: string;

  @Output()
  readonly saved = new EventEmitter<File>();

  @Output()
  readonly closed = new EventEmitter<void>();

  @ViewChild(DocumentTemplatePreviewComponent)
  previewComponent: DocumentTemplatePreviewComponent;

  protected isBusy: boolean;
  protected dataChanged = false;
  protected canSave = false;

  constructor(private readonly downloadService: DownloadService) {}

  protected formDataChanged(): void {
    this.dataChanged = true;
  }

  download(): void {
    this.downloadService.downloadFile(this.fileName, this.url);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileName) {
      this.canSave = saveSupportedByDocumentTemplatePreview(this.fileName);
    }
  }

  save(): void {
    this.isBusy = true;
    this.previewComponent
      .getFile()
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe((file) => this.saved.emit(file));
  }
}
