import { IEnvironment } from "@ankaadia/ankaadia-shared-frontend";
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: IEnvironment = {
  production: false,
  useServiceWorker: true,
  //graphqlURI: "https://ankaadia-dev.azurewebsites.net/graphql",
  graphqlURI: "https://backend-dev.ankaadia.com/api/graphql",
  wsURI: "wss://backend-dev.ankaadia.com/api/subscriptions",
  auth0_clientId: "OrQySMsAq2gDReyQPXbIvrwnXPcs9krP",
  auth0_domain: "dev-goodthing.eu.auth0.com",
  auth0_issuer: "dev-goodthing.eu.auth0.com",
  auth0_audience: "https://goodthing.reininger.de",
  appinsights_connectionstring:
    "InstrumentationKey=1d13da41-e94b-476b-b6a5-a0c4f29a0965;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/",
  emailEditorURI: "https://emaileditor.ankaadia.com",
  facebookAppId: "549955693327085",
  facebookPageId: "100547019496155",
  applicationName: "Ankaadia-Frontend",
  userFlowToken: "ct_mjnhtlld3zewbofo5z742gftby",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
