import { Directive } from "@angular/core";
import { noop } from "lodash";
import { Dropdown } from "primeng/dropdown";

/* just a placeholder so that the import does not look weird */
@Directive({ selector: "p-dropdown" })
export class DropdownTabFixDirective {
  constructor(private readonly dropdown: Dropdown) {
    noop(this.dropdown);
  }
}

// fix for https://github.com/primefaces/primeng/issues/13916
const origOnKeydown = Dropdown.prototype.onKeydown;
Dropdown.prototype.onKeydown = function (event: KeyboardEvent, search: boolean): any {
  if (event.which == 9) {
    this.hide();
    return;
  }
  origOnKeydown.apply(this, [event, search]);
};
