import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { DocumentsModule } from "../documents/documents.module";
import { ActivitiesComponent } from "./activities.component";
import { ActivityDescriptionComponent } from "./activity-description/activity-description.component";
import { ActivityDiffComponent } from "./activity-diff/activity-diff.component";
import { ActivityFilterComponent } from "./activity-filter/activity-filter.component";
import { ActivityIconComponent } from "./activity-icon/activity-icon.component";
import { ActivityInfoComponent } from "./activity-info/activity-info.component";
import { ActivityTableComponent } from "./activity-table/activity-table.component";
import { ActivityTypeSelectorComponent } from "./activity-type-selector/activity-type-selector.component";
import { DocumentTypeSelectorComponent } from "./document-type-selector/document-type-selector.component";
import { FieldDiffComponent } from "./field-diff/field-diff.component";

@NgModule({
  imports: [SharedModule, DocumentsModule],
  declarations: [
    ActivitiesComponent,
    ActivityTableComponent,
    ActivityFilterComponent,
    ActivityIconComponent,
    ActivityDescriptionComponent,
    ActivityTypeSelectorComponent,
    ActivityInfoComponent,
    ActivityDiffComponent,
    DocumentTypeSelectorComponent,
    FieldDiffComponent,
  ],
  exports: [
    ActivitiesComponent,
    ActivityTableComponent,
    ActivityTypeSelectorComponent,
    ActivityDiffComponent,
    DocumentTypeSelectorComponent,
    FieldDiffComponent,
  ],
})
export class ActivityModule {}
