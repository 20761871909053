<ng-container *transloco="let t; lang: processLanguage + '|static'">
  <div *ngIf="anyNotInvited()">
    <p-message
      [severity]="allNotInvited() ? 'error' : 'warn'"
      [text]="t('invited.missing', { candidates: notInvitedCandidates().join('; ') })"
    >
    </p-message>
  </div>
  <div *ngIf="email && !outdated" class="details p-4">
    <div #subjectEl class="subject mb-2" [attr.contenteditable]="isEditing">{{ email.subject }}</div>

    <div class="to mb-2">
      <ng-container *ngFor="let to of email.to; index as i">
        <span class="inline-block underline" [pTooltip]="to.email" tooltipStyleClass="p-tooltip-fluid">
          {{ to.name }}
        </span>
        <ng-container *ngIf="i < email.to.length - 1">; </ng-container>
      </ng-container>
    </div>

    <div class="buttons">
      <p-toolbar>
        <ng-template pTemplate="right">
          <p-button
            *ngIf="!isEditing && editable"
            icon="pi pi-pencil"
            styleClass="mr-2"
            [pTooltip]="t('common.edit')"
            tooltipPosition="left"
            (onClick)="onEdit()"
          ></p-button>
          <p-splitButton
            *ngIf="!isEditing && attachments?.length > 0"
            [menuStyle]="{ width: '400px' }"
            icon="pi pi-file"
            [model]="attachments"
          ></p-splitButton>
          <p-button
            *ngIf="isEditing && editable"
            icon="pi pi-send"
            styleClass="mr-2"
            [pTooltip]="t('email.send')"
            tooltipPosition="left"
            (onClick)="onSend($event, subjectEl.innerText, bodyEl.innerEmailHTML)"
          ></p-button>
          <p-button
            *ngIf="isEditing && editable"
            icon="pi pi-times"
            [pTooltip]="t('common.cancel')"
            tooltipPosition="left"
            (onClick)="onCancel()"
          ></p-button>
        </ng-template>
      </p-toolbar>
    </div>

    <app-email-view-encapsulation [emailHtml]="emailbody" [isEditing]="isEditing"> </app-email-view-encapsulation>
  </div>

  <div *ngIf="!email && !outdated" class="overlay">
    <i class="pi pi-envelope mb-2 overlay-icon"></i>
    <div class="overlay-title mb-2">{{ t("emails.select") }}</div>
    <div class="overlay-subtitle p-text-secondary">{{ t("emails.noSelection") }}</div>
  </div>

  <a *ngIf="outdated" class="overlay" href="javascript:void(0)" (click)="update.emit()">
    <i class="pi pi-exclamation-circle mb-2 overlay-icon"></i>
    <div class="overlay-title mb-2">{{ t("emails.refresh") }}</div>
    <div class="overlay-subtitle p-text-secondary">{{ t("emails.outdated") }}</div>
  </a>

  <p-sidebar
    [visible]="previewAttachment != null"
    [fullScreen]="sidebarSize == 'full'"
    [styleClass]="sideBarSizeMapping[sidebarSize]"
  >
    <ng-template pTemplate="header">
      <button
        class="mr-2 p-button-rounded p-button-text"
        *ngIf="!toggleArrow"
        pButton
        type="button"
        (click)="sidebarSize = 'medium'"
        icon="pi pi-arrow-right"
      ></button>
      <button
        class="mr-2 p-button-rounded p-button-text"
        *ngIf="toggleArrow"
        pButton
        type="button"
        (click)="sidebarSize = 'full'"
        icon="pi pi-arrow-left"
      ></button>
    </ng-template>
    <app-document-preview-dialog
      [fileName]="this.previewAttachment?.name"
      [renderAsCard]="true"
      [url]="fileUrl"
      (closed)="closePreview()"
    ></app-document-preview-dialog>
  </p-sidebar>
</ng-container>
