import { NgModule } from "@angular/core";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { FormlyModule } from "../../formly/formly.module";
import { SharedModule } from "../../shared/shared.module";
import { DocumentTemplatePreviewDialogComponent } from "./document-template-preview-dialog/document-template-preview-dialog.component";
import { DocumentTemplatePreviewComponent } from "./document-template-preview/document-template-preview.component";
import { DocumentTemplateReportsButtonComponent } from "./document-template-reports-button/document-template-reports-button.component";
import { DocumentReportTemplateDataStrategyComponent } from "./document-template-reports/document-template-reports-dataStrategy-component/document-template-reports-dataStrategy.component";
import { DocumentReportTemplatesComponent } from "./document-template-reports/document-template-reports.component";
import { DocumentTemplatesComponent } from "./document-template-templates/document-templates.component";
import { DocumentTemplateWarningsDialogComponent } from "./document-template-warnings-dialog/document-template-warnings-dialog.component";
import { DocumentTemplateWarningsComponent } from "./document-template-warnings/document-template-warnings.component";

@NgModule({
  imports: [SharedModule, FormlyModule, NgxDocViewerModule],
  declarations: [
    DocumentReportTemplatesComponent,
    DocumentTemplatesComponent,
    DocumentTemplateWarningsComponent,
    DocumentTemplateWarningsDialogComponent,
    DocumentTemplatePreviewComponent,
    DocumentTemplatePreviewDialogComponent,
    DocumentReportTemplateDataStrategyComponent,
    DocumentTemplateReportsButtonComponent,
  ],
  exports: [
    DocumentTemplatesComponent,
    DocumentTemplatePreviewComponent,
    DocumentTemplateWarningsComponent,
    DocumentReportTemplatesComponent,
    DocumentReportTemplateDataStrategyComponent,
    DocumentTemplateReportsButtonComponent,
  ],
})
export class DocumentTemplateModule {}
