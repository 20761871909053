import { NgModule } from "@angular/core";
import { APOLLO_OPTIONS, ApolloModule as ApolloCoreModule } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { apolloFactory } from "./apollo.factory";
import { WsConnectionParamsProvider } from "./ws-connection-params.provider";

@NgModule({
  imports: [ApolloCoreModule],
  providers: [{ provide: APOLLO_OPTIONS, useFactory: apolloFactory, deps: [HttpLink, WsConnectionParamsProvider] }],
  exports: [ApolloCoreModule],
})
export class ApolloModule {}
