import { Directive, OnInit } from "@angular/core";
import { Menu } from "primeng/menu";

/**
 * Repositions the menu when trying to open an already visible menu.
 */
@Directive({ selector: "p-menu" })
export class MenuShowFixDirective implements OnInit {
  private showSource: Menu["show"];

  constructor(private readonly menu: Menu) {}

  ngOnInit(): void {
    this.showSource = this.menu.show;
    this.menu.show = (event: Event): void => this.show(this.menu, event);
  }

  private show(menu: Menu, event: Event): void {
    const wasVisible = menu.visible;
    const result = this.showSource.call(menu, event);
    if (wasVisible) {
      menu.alignOverlay();
    }
    return result;
  }
}
