import { Injectable } from "@angular/core";
import { DocumentFile } from "@ankaadia/graphql";
import { DialogService } from "primeng/dynamicdialog";
import { DocumentPreviewDialogComponent, IDialogPreviewData } from "./document-preview-dialog.component";

@Injectable({ providedIn: "root" })
export class DocumentPreviewService {
  constructor(private readonly dialogService: DialogService) {}

  showPreview(
    file: DocumentFile,
    url: string,
    filename: string,
    subHeader?: string,
    language?: string,
    renderAsCard?: boolean,
    showExternalLinkButton?: boolean
  ): void {
    this.dialogService.open(DocumentPreviewDialogComponent, {
      closable: true,
      closeOnEscape: false,
      maximizable: true,
      modal: true,
      width: "85vw",
      height: "85vh",
      data: {
        documentFile: file,
        url: url,
        fileName: filename,
        subheader: subHeader,
        language: language,
        renderAsCard: renderAsCard ?? false,
        showExternalLinkButton: showExternalLinkButton ?? true,
      } as IDialogPreviewData,
    });
  }
}
