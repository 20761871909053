<ng-container *transloco="let t">
  <p-multiSelect
    #multiSelect
    class="multiselect-full-width"
    [options]="options"
    [placeholder]="t('common.select')"
    scrollHeight="300px"
    styleClass="overlay-only"
  >
    <ng-template pTemplate="header">
      <div class="multiselect-header-full-width p-2">
        <b>{{ t("documents.staticDataSelector.updateAll") }}</b>
      </div>
      <div class="multiselect-header-full-width p-2">
        <span [pTooltip]="t('documents.staticDataSelector.overwrite.tooltip')">
          {{ t("documents.staticDataSelector.overwrite.title") }}
        </span>
        <p-inputSwitch [(ngModel)]="overwriteExisting" [style]="{ float: 'right' }" />
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="p-multiselect-footer">
        <div class="p-2">
          <p-button label="{{ t('common.ok') }}" (onClick)="apply(multiSelect.value)" class="right"></p-button>
        </div>
      </div>
    </ng-template>
  </p-multiSelect>
  <p-confirmDialog [style]="{ width: '50vw' }" [key]="dialogKey" />
</ng-container>
