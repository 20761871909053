import { ModuleWithProviders, NgModule } from "@angular/core";
import { translate } from "@ngneat/transloco";
import { ConfigOption, FormlyModule as FormlyCoreModule } from "@ngx-formly/core";
import { FormlySelectModule } from "@ngx-formly/core/select";
import { CandidateDocumentMetadataModule } from "../features/candidate-document-metadata/candidate-document-metadata.module";
import { ProfessionModule } from "../features/profession/profession.module";
import { SharedModule } from "../shared/shared.module";
import { FormlyCertifiedEducationExamsComponent } from "./certified-education-exams/formly-certified-education-exams.component";
import { FormlyCheckboxComponent } from "./checkbox/formly-checkbox.component";
import { FormlyCompetenciesComponent } from "./competencies/formly-competencies.component";
import { FormlyDatepickerComponent } from "./datepicker/formly-datepicker.component";
import { FormlyDocumentDateComponent } from "./document-date/formly-document-date.component";
import { FormlyDocumentUploadComponent } from "./document-upload/formly-document-upload.component";
import { FormlyDropdownComponent } from "./dropdown/formly-dropdown.component";
import { FormlyFieldSetComponent } from "./field-set/formly-field-set.component";
import { FormlyFieldComponent } from "./field/formly-field.component";
import { FormlyInputComponent } from "./input/formly-input.component";
import { FormlyMultiSelectComponent } from "./multiselect/formly-multiselect.component";
import { FormlyProfessionSelectorComponent } from "./profession-selector/formly-profession-selector.component";
import { FormlyRadioButtonComponent } from "./radio-button/formly-radio-button.component";
import { FormlyRatingComponent } from "./rating/formly-rating.component";
import { FormlyReadonlyExtension } from "./readonly/formly-readonly.extension";
import { FormlyRepeatTabsComponent } from "./repeat-tabs/formly-repeat-tabs.component";
import { FormlyRepeatComponent } from "./repeat/formly-repeat.component";
import { FormlyShowValidationExtension } from "./show-validation/formly-show-validation.extension";
import { FormlyTabsComponent } from "./tabs/formly-tabs.component";
import { FormlyTextareaComponent } from "./textarea/formly-textarea.component";
import { FormlyTranslateExtension } from "./translate/formly-translate.extension";
import { FormlyRootExtension } from "../features/formly/root/formly-root.extension";
import { FormlyEnhancedDropdownComponent } from "../features/formly/enhanced-dropdown/formly-enhanced-dropdown.component";
import { FormlyLanguageLevelByPlacementTestComponent } from "./language-level-by-placement-test/formly-language-level-by-placement-test.component";
import { FormlyVaccinationInformationComponent } from "./vaccination-information/formly-vaccination-information.component";
import { FormlyChipsComponent } from "./chips-with comment/chips.component";

const config: ConfigOption = {
  extras: { immutable: true },
  validationMessages: [
    { name: "required", message: (): string => translate("common.validation.required") },
    {
      name: "minLength",
      message: (_, f): string => translate("common.validation.minLength", { length: f.props.minLength }),
    },
    {
      name: "maxlLength",
      message: (_, f): string => translate("common.validation.maxLength", { length: f.props.maxLength }),
    },
    {
      name: "min",
      message: (_, f): string => translate("common.validation.min", { value: f.props.min }),
    },
    {
      name: "max",
      message: (_, f): string => translate("common.validation.max", { value: f.props.max }),
    },
    {
      name: "range",
      message: (): string => translate("common.validation.range"),
    },
  ],
  wrappers: [
    { name: "form-field", component: FormlyFieldComponent },
    { name: "fieldset", component: FormlyFieldSetComponent },
  ],
  types: [
    { name: "checkbox", component: FormlyCheckboxComponent, wrappers: ["form-field"] },
    { name: "dropdown", component: FormlyDropdownComponent, wrappers: ["form-field"] },
    { name: "radio", component: FormlyRadioButtonComponent, wrappers: ["form-field"] },
    { name: "rating", component: FormlyRatingComponent, wrappers: ["form-field"] },
    { name: "datepicker", component: FormlyDatepickerComponent, wrappers: ["form-field"] },
    { name: "tabs", component: FormlyTabsComponent },
    { name: "repeat", component: FormlyRepeatComponent },
    { name: "repeat-tabs", component: FormlyRepeatTabsComponent },
    { name: "multiselect", component: FormlyMultiSelectComponent, wrappers: ["form-field"] },
    { name: "documentUpload", component: FormlyDocumentUploadComponent, wrappers: ["form-field"] },
    { name: "profession", component: FormlyProfessionSelectorComponent, wrappers: ["form-field"] },
    { name: "input", component: FormlyInputComponent, wrappers: ["form-field"] },
    { name: "textarea", component: FormlyTextareaComponent, wrappers: ["form-field"] },
    { name: "document-date", component: FormlyDocumentDateComponent },
    { name: "certified-education-exams", component: FormlyCertifiedEducationExamsComponent },
    { name: "autocomplete", component: FormlyEnhancedDropdownComponent, wrappers: ["form-field"] },
    {
      name: "language-level-by-placement-test",
      component: FormlyLanguageLevelByPlacementTestComponent,
      wrappers: ["form-field"],
    },
    { name: "vaccination-information", component: FormlyVaccinationInformationComponent },
    { name: "competencies", component: FormlyCompetenciesComponent },
    { name: "chips", component: FormlyChipsComponent, wrappers: ["form-field"] },
  ],
  extensions: [
    { name: "showValidation", extension: new FormlyShowValidationExtension() },
    { name: "translate", extension: new FormlyTranslateExtension() },
    { name: "readonly", extension: new FormlyReadonlyExtension() },
    { name: "root", extension: new FormlyRootExtension() },
  ],
};

@NgModule({
  imports: [SharedModule, FormlyCoreModule, FormlySelectModule, ProfessionModule, CandidateDocumentMetadataModule],
  declarations: [
    FormlyDatepickerComponent,
    FormlyMultiSelectComponent,
    FormlyRepeatComponent,
    FormlyRepeatTabsComponent,
    FormlyTabsComponent,
    FormlyDropdownComponent,
    FormlyFieldComponent,
    FormlyFieldSetComponent,
    FormlyDocumentUploadComponent,
    FormlyCheckboxComponent,
    FormlyProfessionSelectorComponent,
    FormlyRatingComponent,
    FormlyRadioButtonComponent,
    FormlyInputComponent,
    FormlyTextareaComponent,
    FormlyDocumentDateComponent,
    FormlyCertifiedEducationExamsComponent,
    FormlyEnhancedDropdownComponent,
    FormlyLanguageLevelByPlacementTestComponent,
    FormlyVaccinationInformationComponent,
    FormlyCompetenciesComponent,
    FormlyChipsComponent,
  ],
  exports: [FormlyCoreModule, FormlyEnhancedDropdownComponent],
})
export class FormlyModule {
  static forRoot(): ModuleWithProviders<FormlyModule>[] {
    return [{ ngModule: FormlyModule }, FormlyCoreModule.forRoot(config)];
  }
}
