<div [style.width.px]="size" [style.height.px]="size">
  <svg
    [attr.width]="size"
    [attr.height]="size"
    [attr.viewBox]="viewBox"
    xmlns="http://www.w3.org/2000/svg"
    style="transform: rotate(-90deg)"
  >
    <!-- Background Circle -->
    <circle
      [attr.r]="radius"
      [attr.cx]="center"
      [attr.cy]="center"
      fill="transparent"
      stroke="#d0d0d0"
      [attr.stroke-width]="strokeWidth"
      [attr.stroke-dasharray]="circumference"
      stroke-dashoffset="0"
    ></circle>

    <!-- Foreground Circle (Progress Indicator) -->
    <circle
      [attr.r]="radius"
      [attr.cx]="center"
      [attr.cy]="center"
      fill="transparent"
      [attr.stroke]="failed ? '#bd5155' : '#51bd8a'"
      [attr.stroke-width]="strokeWidth"
      stroke-linecap="butt"
      [attr.stroke-dasharray]="circumference"
      [attr.stroke-dashoffset]="dashOffset"
      class="progress-circle"
    ></circle>
  </svg>
</div>
