import { StaticDataType } from "@ankaadia/graphql";
import { translate as transloco, TranslateParams } from "@ngneat/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { map, Observable } from "rxjs";
import { StaticDataService } from "../../../shared/static-data/static-data.service";

export interface RecruitingConceptContext {
  staticDataService: StaticDataService;
}

export function recruitingConceptFormFactory(
  context: RecruitingConceptContext,
  language: string
): Observable<FormlyFieldConfig[]> {
  const translate = <T>(key: TranslateParams, params?: Record<string, any>): T => transloco<T>(key, params, language);
  return context.staticDataService.getStaticData(StaticDataType.Countries, language).pipe(
    map((countries) => {
      return [
        {
          className: "block m-1",
          fieldGroup: [
            {
              key: "countries",
              type: "multiselect",
              props: {
                label: translate("organizationAuditAct.recruitingConcept.countries"),
                options: countries,
                required: true,
              },
            },
            {
              key: "sourcing",
              type: "textarea",
              props: {
                label: translate("organizationAuditAct.recruitingConcept.sourcing"),
                rows: 10,
                grow: true,
                required: true,
              },
            },
            {
              key: "languageTraining",
              type: "textarea",
              props: {
                label: translate("organizationAuditAct.recruitingConcept.languageTraining"),
                rows: 10,
                grow: true,
                required: true,
              },
            },
            {
              key: "matching",
              type: "textarea",
              props: {
                label: translate("organizationAuditAct.recruitingConcept.matching"),
                rows: 10,
                grow: true,
                required: true,
              },
            },
            {
              key: "other",
              type: "textarea",
              props: {
                label: translate("organizationAuditAct.recruitingConcept.other"),
                rows: 10,
                grow: true,
                required: true,
              },
            },
          ],
        },
      ];
    })
  );
}
