<ng-container *transloco="let t">
  <form [formGroup]="form">
    <ng-container formArrayName="configs">
      <p-message
        class="mr-2"
        *ngIf="!form.valid && duplicate"
        severity="error"
        [text]="
          t('requiredVaccines.errors.duplicate')
            | concat
              : (duplicate.countryOfOrigin
                  | staticData: StaticDataTypeEnum.Countries : null : staticDataContext : true
                  | async)
            | join: ': '
            | concat
              : (duplicate.federalState
                  | staticData: StaticDataTypeEnum.FederalStates : null : staticDataContext : true
                  | async)
            | concat
              : (duplicate.profession
                  | staticData: StaticDataTypeEnum.Profession : null : staticDataContext : true
                  | async)
            | concat
              : (duplicate.vaccine
                  | staticData: StaticDataTypeEnum.Vaccination : null : staticDataContext : true
                  | async)
            | concat
              : (duplicate.vaccineFunction
                  | staticData: StaticDataTypeEnum.VaccineFunction : null : staticDataContext : true
                  | async)
            | join
        "
      ></p-message>

      <p-message
        class="mr-2"
        *ngIf="!form.valid && maxLengthError"
        severity="error"
        [text]="t('requiredVaccines.errors.maxLengthProfession')"
      ></p-message>

      <p-message
        class="mr-2"
        *ngIf="!form.valid && onlyAllError"
        severity="error"
        [text]="t('requiredVaccines.errors.onlyAll')"
      ></p-message>

      <p-table
        [columns]="cols$"
        [value]="configs?.controls"
        responsiveLayout="scroll"
        styleClass="p-datatable-sm p-datatable-striped vaccines-table"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              [pSortableColumn]="col.sortable ? getFieldName(col.controlName) : undefined"
              *ngFor="let col of columns"
              [style]="col.style"
            >
              {{ col.header }}<p-sortIcon *ngIf="col.sortable" [field]="getFieldName(col.controlName)"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-editing="editing" let-cols="columns">
          <tr [formGroup]="rowData" [pSelectableRow]="rowData" [class.duplicatedRows]="rowData.errors?.duplicate">
            <td class="button-cell">
              <button
                pButton
                type="button"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text p-button-sm"
                (click)="removeRow($event, rowData)"
              ></button>
              <button
                pButton
                type="button"
                icon="pi pi-copy"
                class="p-button-rounded p-button-text p-button-sm"
                [disabled]="!form.valid"
                (click)="duplicateRow(rowData)"
              ></button>
            </td>

            <ng-container *ngFor="let col of cols">
              <td pEditableColumn *ngIf="col.type === 'static-data-multi-select'">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-multiSelect
                      [formControlName]="col.controlName"
                      [options]="col.options | async"
                      [showClear]="false"
                      [showToggleAll]="col.showToggleAll ?? true"
                      [placeholder]="col.placeholder"
                      [tooltip]="col.placeholder"
                    >
                    </p-multiSelect>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{
                      rowData.controls[col.controlName].value
                        | staticData: col.staticDataType : null : staticDataContext : true
                        | async
                    }}
                    <small class="p-error" *ngIf="rowData.controls[col.controlName].errors?.required">
                      {{ t("common.validation.requiredShort") }}
                    </small>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td pEditableColumn *ngIf="col.type === 'employer-multi-select'">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-multiSelect
                      [formControlName]="col.controlName"
                      [options]="col.options | async"
                      [showClear]="false"
                      [showToggleAll]="col.showToggleAll ?? true"
                      [placeholder]="col.placeholder"
                      [tooltip]="col.placeholder"
                    ></p-multiSelect>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ col.toOutPut(rowData.controls[col.controlName].value) }}
                    <small class="p-error" *ngIf="rowData.controls[col.controlName].errors?.required">
                      {{ t("common.validation.requiredShort") }}
                    </small>
                  </ng-template>
                </p-cellEditor>
              </td>
            </ng-container>
            <td>
              <p-checkbox [binary]="true" formControlName="mandatoryVaccines"></p-checkbox>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="summary">
          <tr class="p-3">
            <p-button icon="pi pi-plus" (click)="addRow()" styleClass="add-row-btn" [disabled]="!form.valid"></p-button>
          </tr>
        </ng-template>
      </p-table>
    </ng-container>
  </form>
</ng-container>
