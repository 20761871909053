import { customFieldLabelMapping, ICustomFieldsModel, nameofFactory } from "@ankaadia/ankaadia-shared";
import { translate } from "@jsverse/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { PrimeIcons } from "primeng/api";

const _dummy: ICustomFieldsModel = null;
const nameOfDeep = nameofFactory<typeof _dummy.stFranziskus>();

export const stFranziskusFactory: (requiredFields: string[]) => FormlyFieldConfig[] = (_requiredFields: string[]) => {
  return [
    {
      id: "stFranziskus-custom",
      props: {
        label: translate("customFields.SFS-Daten"),
        icon: PrimeIcons.USER_EDIT,
      },
      fieldGroupClassName: "p-fluid",
      fieldGroup: [
        {
          fieldGroupClassName: "p-fluid formgrid grid",
          fieldGroup: [
            {
              key: nameOfDeep("educationalAttribution"),
              type: "dropdown",
              className: "field col-12 lg:col-12",
              props: {
                options: [
                  { label: translate("customFields.educationalAttribution.option1"), value: "IR" },
                  { label: translate("customFields.educationalAttribution.option2"), value: "IQ" },
                ],
                label: translate(customFieldLabelMapping.stFranziskus.educationalAttribution),
              },

              validation: {
                messages: {
                  required: translate("required"),
                },
              },
            },
          ],
        },
      ],
    },
  ];
};
