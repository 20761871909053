import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthModule } from "@auth0/auth0-angular";
import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import { ConfirmationService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { FormlyModule } from "./formly/formly.module";
import { OrganizationSpecificModule } from "./organization-specific/organization-specific.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./structure/app.component";
import { StructureModule } from "./structure/structure.module";
import { ServiceWorkerModule } from "@angular/service-worker";

@NgModule({
  imports: [
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.useServiceWorker,

      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).

      registrationStrategy: "registerWhenStable:30000",
    }),
    AuthModule.forRoot({
      clientId: environment.auth0_clientId,
      domain: environment.auth0_domain,
      errorPath: "/error",
      skipRedirectCallback: false,
      authorizationParams: {
        audience: environment.auth0_audience,
        redirect_uri: window.location.origin + "/callback",
      },
      httpInterceptor: {
        allowedList: [environment.graphqlURI],
      },
    }),
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    FormlyModule.forRoot(),
    OrganizationSpecificModule,
    StructureModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService }, // must be a singleton and therefor placed in the app module
    ConfirmationService,
    DialogService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
