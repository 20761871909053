<p-card *appTranslate="let t; lang: language + '|static'">
  <div class="flex flex-column app-form p-card-content">
    <p-toolbar *ngIf="title || newOperations?.length" styleClass="mb-4">
      <ng-template *ngIf="title" pTemplate="left">
        <h3>{{ title }}</h3>
      </ng-template>
      <ng-template *ngIf="newOperations?.length" pTemplate="right">
        <ng-container *ngFor="let op of newOperations; index as i">
          <ng-container *ngIf="!op.canOperate || op.canOperate(null)">
            <ng-container *ngIf="!op.mode || op.mode == TableOperationMode.Button">
              <ng-container
                *ngTemplateOutlet="button; context: { op: op, label: op.label, rowData: newOp }"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="op.mode == TableOperationMode.SplitButton">
              <ng-container
                *ngTemplateOutlet="splitButton; context: { op: op, label: op.label, rowData: newOp, i: i }"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="op.mode == TableOperationMode.Menu">
              <ng-container
                *ngTemplateOutlet="menu; context: { op: op, label: op.label, rowData: newOp, i: i }"
              ></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </p-toolbar>

    <p-treeTable
      #dt
      [value]="items"
      [columns]="columns"
      [rows]="numberOfRows"
      [paginator]="paginator"
      [globalFilterFields]="globalFilterFields"
      [rowHover]="true"
      [sortField]="sortField ?? columns?.[0].fieldname"
      [sortOrder]="sortOrder ?? 1"
      [customSort]="customSort"
      (sortFunction)="sort($event)"
      dataKey="id"
      class="app-form"
      styleClass="p-treetable app-form"
    >
      <ng-template pTemplate="caption">
        <div
          class="flex flex-column md:flex-row align-items-end md:align-items-center md:justify-content-end table-header"
        >
          <div class="mr-auto mw-1" [class.flex-1]="!search" *ngIf="captionTemplate">
            <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
          </div>
          <div style="width: 100%" *ngIf="warningMessage">
            <p style="color: red">{{ warningMessage }}</p>
          </div>
          <span class="p-input-icon-left" *ngIf="search">
            <i class="pi pi-search"></i>
            <input
              pInputText
              [(ngModel)]="searchInput"
              type="text"
              (input)="dt.filterGlobal($any($event.target).value, filterName)"
              [placeholder]="t('common.search')"
            />
          </span>

          <ng-container *ngFor="let op of captionOperations; index as i">
            <div class="mr-2"></div>
            <ng-container *ngIf="!op.mode || op.mode == TableOperationMode.Button">
              <ng-container
                *ngTemplateOutlet="button; context: { op: op, label: op.label, rowData: captionOp }"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="op.mode == TableOperationMode.SplitButton">
              <ng-container
                *ngTemplateOutlet="splitButton; context: { op: op, label: op.label, rowData: captionOp, i: i }"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="op.mode == TableOperationMode.Menu">
              <ng-container
                *ngTemplateOutlet="menu; context: { op: op, label: op.label, rowData: captionOp, i: i }"
              ></ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [ttSortableColumn]="col.sortable ? col.fieldname : null">
            {{ col.header }}
            <p-treeTableSortIcon *ngIf="col.sortable" [field]="col.fieldname"></p-treeTableSortIcon>
          </th>
          <th *ngIf="tableOperations?.length"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
          <td *ngFor="let col of columns; index as i" [class.flex-wrap]="!!col.tags">
            <span [pTooltip]="calculatedTooltips[i][rowData.id]" tooltipStyleClass="p-tooltip-fluid">
              <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
              <ng-container *ngIf="col.routeLink && col.routeLink(rowData); else plain">
                <ng-container *ngIf="col.pipeName">
                  <ng-container *ngIf="col.includeFlag">
                    <app-flag class="mr-2" *ngIf="rowData.id" [country]="rowData?.country" [small]="true"></app-flag
                  ></ng-container>
                  <a href="javascript:void(0)" [routerLink]="col.routeLink(rowData)">
                    {{ rowData[col.fieldname] | dynamic: col.pipeName : col.pipeArgs | async }}
                  </a>
                </ng-container>
                <ng-container *ngIf="!col.pipeName">
                  <ng-container *ngIf="col.includeFlag">
                    <app-flag class="mr-2" *ngIf="rowData.id" [country]="rowData?.country" [small]="true"></app-flag>
                  </ng-container>
                  <a href="javascript:void(0)" [routerLink]="col.routeLink(rowData)">
                    {{ rowData[col.fieldname] }}
                  </a>
                </ng-container>
              </ng-container>
              <ng-template #plain>
                <ng-container *ngIf="col.tags">
                  <p-chip *ngFor="let tag of calculatedTags[i][rowData.id]" styleClass="m-1 table-chip">
                    <a
                      *ngIf="!tag.children?.length"
                      href="javascript:void(0)"
                      (click)="col.tagClick(rowData, tag.value)"
                    >
                      {{ tag.label }}
                    </a>
                    <ng-container *ngIf="tag.children?.length > 0">
                      <p-menu
                        #tagMenu
                        [popup]="true"
                        [model]="prepareTagChildren(col, rowData, tag)"
                        [styleClass]="col.fullWidthTags ? 'w-auto' : ''"
                      ></p-menu>
                      <a href="javascript:void(0)" (click)="tagMenu.toggle($event)">
                        {{ tag.label }}
                      </a>
                    </ng-container>
                  </p-chip>
                </ng-container>
                <ng-container *ngIf="col.icon">
                  <ng-container *ngIf="calculatedIcons[i][rowData.id] as icon">
                    <ng-container
                      *ngIf="col.iconClick && (!col.canIconClick || col.canIconClick(rowData)); else plainIcon"
                    >
                      <a href="javascript:void(0)" (click)="col.iconClick(rowData, $event)">
                        <i class="text-2xl" [ngClass]="icon"></i>
                      </a>
                    </ng-container>
                    <ng-template #plainIcon>
                      <i class="text-2xl" [ngClass]="icon"></i>
                    </ng-template>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!col.tags && !col.icon">
                  <ng-container *ngIf="col.pipeName">
                    {{ rowData[col.fieldname] | dynamic: col.pipeName : col.pipeArgs | async }}
                  </ng-container>
                  <ng-container *ngIf="!col.pipeName">
                    {{ rowData[col.fieldname] }}
                  </ng-container>
                </ng-container>
              </ng-template>
            </span>
          </td>
          <td *ngIf="tableOperations?.length" class="button-cell">
            <ng-container *ngFor="let op of tableOperations; index as i">
              <ng-container *ngIf="!op.canOperate || op.canOperate(rowData)">
                <ng-container *ngIf="!op.mode || op.mode == TableOperationMode.Button">
                  <ng-container *ngTemplateOutlet="button; context: { op: op, rowData: rowData }"></ng-container>
                </ng-container>
                <ng-container *ngIf="op.mode == TableOperationMode.SplitButton">
                  <ng-container
                    *ngTemplateOutlet="splitButton; context: { op: op, rowData: rowData, i: i }"
                  ></ng-container>
                </ng-container>
                <ng-container *ngIf="op.mode == TableOperationMode.Menu">
                  <ng-container *ngTemplateOutlet="menu; context: { op: op, rowData: rowData, i: i }"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </td>
        </tr>
      </ng-template>

      <ng-template *ngIf="footerOperations?.length" pTemplate="summary">
        <ng-container *ngFor="let op of footerOperations; index as i">
          <ng-container *ngIf="!op.mode || op.mode == TableOperationMode.Button">
            <ng-container
              *ngTemplateOutlet="button; context: { op: op, label: op.label, rowData: footerOp }"
            ></ng-container>
          </ng-container>
          <ng-container *ngIf="op.mode == TableOperationMode.SplitButton">
            <ng-container
              *ngTemplateOutlet="splitButton; context: { op: op, label: op.label, rowData: footerOp, i: i }"
            ></ng-container>
          </ng-container>
          <ng-container *ngIf="op.mode == TableOperationMode.Menu">
            <ng-container
              *ngTemplateOutlet="menu; context: { op: op, label: op.label, rowData: footerOp, i: i }"
            ></ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </p-treeTable>
  </div>
</p-card>

<p-sidebar
  #sidebar
  [(visible)]="showSidebar"
  [styleClass]="sideBarSizeMapping[sidebarSize]"
  [fullScreen]="sidebarSize == 'full'"
>
  <ng-template pTemplate="header"
    ><button
      class="mr-2 p-button-rounded p-button-text"
      *ngIf="showArrows && !toggleArrow"
      pButton
      type="button"
      (click)="sidebarSize = 'medium'"
      icon="pi pi-arrow-right"
    ></button>
    <button
      class="mr-2 p-button-rounded p-button-text"
      *ngIf="showArrows && toggleArrow"
      pButton
      type="button"
      (click)="sidebarSize = 'full'"
      icon="pi pi-arrow-left"
    ></button>
  </ng-template>

  <ng-content></ng-content>
</p-sidebar>

<ng-template #button let-op="op" let-rowData="rowData" let-label="label">
  <p-button
    *appPermissions="op.permission"
    [pTooltip]="op.label !== label ? op.label : null"
    [label]="label"
    [icon]="getButtonIcon(op.icon, rowData)"
    class="p-button-success mr-2"
    [styleClass]="op.styleClass"
    (onClick)="startOperation($event, rowData, op.operation)"
    [disabled]="getDisabledState(rowData, op.disabled)"
  ></p-button>
</ng-template>

<ng-template #splitButton let-op="op" let-rowData="rowData" let-label="label" let-i="i">
  <p-splitButton
    #splitBtn
    *appPermissions="op.permission"
    [pTooltip]="op.label !== label ? op.label : null"
    [label]="label"
    [icon]="getButtonIcon(op.icon, rowData)"
    class="inline-block p-splitbutton-success mr-2"
    styleClass="p-splitbutton-success {{ op.styleClass ?? '' }}"
    [disabled]="getDisabledState(rowData, op.disabled)"
    (onClick)="eventTarget = null; startOperation($event, rowData, op.operation)"
    (onDropdownClick)="eventTarget = splitBtn?.containerViewChild?.nativeElement"
    [model]="calculatedButtonItems[rowData.id][i]"
  ></p-splitButton>
</ng-template>

<ng-template #menu let-op="op" let-rowData="rowData" let-label="label" let-i="i">
  <p-menu #menu [popup]="true" [model]="calculatedButtonItems[rowData.id][i]"></p-menu>
  <button
    #btn
    *appPermissions="op.permission"
    pButton
    [pTooltip]="op.label !== label ? op.label : null"
    [label]="label"
    [icon]="getButtonIcon(op.icon, rowData)"
    class="mr-2 {{ op.styleClass }}"
    (click)="eventTarget = btn; menu.toggle($event)"
    [disabled]="getDisabledState(rowData, op.disabled)"
  ></button>
</ng-template>
