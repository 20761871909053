import { Injectable } from "@angular/core";

import { Observable, map } from "rxjs";
import {
  GetEducationExamResultsUpdateTableGQL,
  UpsertEducationExamResultGQL,
  DeleteEducationExamResultGQL,
  EducationExamResultDeleteInput,
  EducationExamResultListInput,
  EducationExamResultUpsertInput,
  GetEducationExamResultsByCandidateGQL,
  EducationExamResultsByCandidateIdInput,
  GetEducationExamResultsByExamIdGQL,
  EducationExamResultUpdateResultFragment,
  EducationExamResultFullDataFragment,
} from "@ankaadia/graphql";
import { omit, pick } from "lodash";
import { removeCandidateExamQueriesFromCache } from "../education-exams/education-exam.service";

@Injectable({
  providedIn: "root",
})
export class EducationExamResultService {
  constructor(
    private readonly getExamResultsTable: GetEducationExamResultsUpdateTableGQL,
    private readonly getExamResultsByExamAndCourse: GetEducationExamResultsByExamIdGQL,
    private readonly getExamResultsByCandidate: GetEducationExamResultsByCandidateGQL,
    private readonly upsertExamResult: UpsertEducationExamResultGQL,
    private readonly deleteExamResult: DeleteEducationExamResultGQL
  ) {}

  getTable(input: EducationExamResultListInput): Observable<EducationExamResultFullDataFragment[]> {
    return this.getExamResultsTable.fetch({ input }).pipe(map((x) => x.data.getEducationExamResultsUpdateTable));
  }

  getByExamId(input: EducationExamResultListInput): Observable<EducationExamResultFullDataFragment[]> {
    return this.getExamResultsByExamAndCourse.fetch({ input }).pipe(map((x) => x.data.getEducationExamResultsByExamId));
  }

  getByCandidateId(input: EducationExamResultsByCandidateIdInput): Observable<EducationExamResultFullDataFragment[]> {
    return this.getExamResultsByCandidate
      .fetch({ input }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getEducationExamResultsByCandidateId));
  }

  upsert(examResult: EducationExamResultUpsertInput): Observable<EducationExamResultUpdateResultFragment> {
    return this.upsertExamResult
      .mutate(
        { input: omit(examResult, "changedAt", "changedAt") },
        {
          update: (cache) => {
            removeExamResultsByCandidateFromCache(cache, examResult.candidateId);
            removeCandidateExamQueriesFromCache(cache, examResult.organizationId);
          },
        }
      )
      .pipe(map((x) => x.data.upsertEducationExamResult));
  }

  delete(examResult: EducationExamResultDeleteInput): Observable<boolean> {
    return this.deleteExamResult
      .mutate(
        {
          input: pick(examResult, "id", "_etag", "organizationId", "examId", "candidateId", "candidateOrganizationId"),
        },
        {
          update: (cache) => {
            removeExamResultsByCandidateFromCache(cache, examResult.candidateId);
            removeCandidateExamQueriesFromCache(cache, examResult.organizationId);
          },
        }
      )
      .pipe(map((x) => x.data.deleteEducationExamResult.status));
  }
}

function removeExamResultsByCandidateFromCache(cache: any, candidateId: string): void {
  removeQueryFromRootCache(cache, new RegExp(`getEducationExamResultsByCandidateId.*${candidateId}`));
}

//go through the cache and remove any query that matches the matcher
export function removeQueryFromRootCache(cache: any, matcher: string | RegExp): void {
  const rootQuery = cache.data.data.ROOT_QUERY;
  Object.keys(rootQuery).forEach((key) => {
    if (key.match(matcher)) cache.evict({ id: "ROOT_QUERY", fieldName: key });
  });
}
