import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { DocumentTemplateType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { lookup } from "mrmime";
import { AnnotationEditorEditorModeChangedEvent, NgxExtendedPdfViewerService } from "ngx-extended-pdf-viewer";
import { Observable, from, map } from "rxjs";

export function isSupportedByDocumentTemplatePreview(templateType: DocumentTemplateType): boolean {
  return (
    templateType != null &&
    (DocumentTemplateType.Docx == templateType ||
      DocumentTemplateType.Pdf == templateType ||
      DocumentTemplateType.Xlsx == templateType ||
      DocumentTemplateType.Json == templateType)
  );
}

export function saveSupportedByDocumentTemplatePreview(filename: string): boolean {
  return ["pdf"].some((x) => filename?.toLocaleLowerCase()?.endsWith(x));
}

@Component({
  selector: "app-document-template-preview",
  templateUrl: "./document-template-preview.component.html",
  styleUrl: "./document-template-preview.component.scss",
})
export class DocumentTemplatePreviewComponent implements OnChanges {
  readonly language = this.transloco.getActiveLang();

  @Input()
  url: string;

  @Input()
  fileName: string;

  @Input()
  height: string;

  @Output()
  readonly rendered = new EventEmitter();

  @Output()
  readonly formDataChanged = new EventEmitter();

  protected fileType: string;

  constructor(
    private readonly transloco: TranslocoService,
    private readonly pdfViewerService: NgxExtendedPdfViewerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileName) {
      this.fileType = this?.fileName?.split(".")?.pop()?.toLocaleLowerCase();
    }
  }

  pdfFormDataChanged(event: any): void {
    this.formDataChanged.emit(event);
  }

  annotationModeChanged(event: AnnotationEditorEditorModeChangedEvent): void {
    if (event.mode == 3) {
      this.formDataChanged.emit(event);
    }
  }

  loadingStarts(): void {
    this.rendered.emit();
  }

  getFile(): Observable<File> {
    return from(this.pdfViewerService.getCurrentDocumentAsBlob()).pipe(
      map((data) => new File([data], this.fileName, { type: lookup("pdf") }))
    );
  }
}
