import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { TableColumn, TableTag } from "../table.model";
import { MenuItem } from "primeng/api";

@Component({
  selector: "app-table-tag",
  templateUrl: "./table-tag.component.html",
  styleUrls: ["./table-tag.component.scss"],
})
export class TableTagComponent implements OnInit, OnChanges {
  @Input() tag: TableTag;
  @Input() rowData: any;
  @Input() column: TableColumn;

  menuItems: MenuItem[] = [];

  ngOnInit(): void {
    this.menuItems = this.buildMenuItem();
  }

  ngOnChanges(): void {
    this.menuItems = this.buildMenuItem();
  }

  private buildMenuItem(): MenuItem[] {
    return (this.tag?.children ?? []).map((t) => ({
      label: t.label,
      command: () => (this.column.tagClick ? this.column.tagClick(this.rowData, t.value) : {}),
    }));
  }
}
