<p-card *appTranslate="let t" [header]="fileName">
  <ng-template pTemplate="header">
    <button
      class="rightCorner mr-2 p-button-rounded p-button-text"
      pButton
      pTooltip="{{ t('attachment.download') }}"
      type="button"
      (click)="download()"
      icon="pi pi-download"
    ></button>
  </ng-template>
  <app-document-template-preview
    [url]="url"
    [fileName]="fileName"
    (formDataChanged)="formDataChanged()"
  ></app-document-template-preview>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-end">
      <p-button
        [label]="t('common.save')"
        icon="pi pi-check"
        [disabled]="isBusy || !dataChanged || !canSave"
        (onClick)="save()"
        class="mr-2"
      ></p-button>
      <p-button
        closeOnEscape
        [label]="t('common.cancel')"
        icon="pi pi-times"
        [disabled]="isBusy"
        (onClick)="closed.emit()"
      ></p-button>
    </div>
  </ng-template>
</p-card>
