import {
  InterviewAppointmentProposal,
  InterviewAppointmentProposalDate,
  nameofFactory,
} from "@ankaadia/ankaadia-shared";
import { TranslateParams, translate as transloco } from "@jsverse/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";

export interface InterviewAppointmentProposalContext {}

export function interviewAppointmentProposalFormFactory(
  _context: InterviewAppointmentProposalContext,
  language: string
): FormlyFieldConfig[] {
  const nameOf = nameofFactory<InterviewAppointmentProposal>();
  const nameOfDate = nameofFactory<InterviewAppointmentProposalDate>();
  const translate = <T>(key: TranslateParams, params?: Record<string, any>): T => transloco<T>(key, params, language);
  return [
    {
      className: "block m-1",
      fieldGroup: [
        {
          key: nameOf("dates"),
          type: "repeat",
          props: {
            addLabel: translate("date.add"),
            required: true,
          },
          fieldArray: {
            fieldGroupClassName: "p-fluid formgrid grid w-full",
            fieldGroup: [
              {
                key: nameOfDate("date"),
                type: "datepicker",
                className: "field col",
                props: {
                  label: translate("date.title"),
                  showIcon: true,
                  inline: false,
                  minDate: new Date(1900, 0, 1),
                  required: true,
                  showTime: true,
                  showTimezone: true,
                  readonlyFormat: { dateStyle: "short", timeStyle: "short" },
                  hideRequiredMarker: true,
                  blocklyType: "field_date",
                },
              },
            ],
          },
        },
      ],
    },
  ];
}
