import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { isRealImmigrationCountry } from "@ankaadia/ankaadia-shared";
import { Candidate } from "@ankaadia/graphql";

@Component({
  selector: "app-flag",
  templateUrl: "./flag.component.html",
  styleUrls: ["./flag.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagComponent {
  readonly isRealImmigrationCountry = isRealImmigrationCountry;

  @Input()
  candidate: Candidate;

  @Input()
  country: string;

  @Input()
  small: boolean;
}
