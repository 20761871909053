<app-profession-selector
  [multi]="to.multi"
  [required]="false"
  [all]="to.all"
  [profession]="to.profession"
  [candidateOrganizationId]="formState.organizationId"
  [showClear]="false"
  [readonly]="to.readonly"
  [formControl]="$any(formControl)"
></app-profession-selector>
