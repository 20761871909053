import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ClickCaptureDirective } from "./click-capture.directive";

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [ClickCaptureDirective],
  exports: [ClickCaptureDirective],
})
export class ClickCaptureModule {}
