import { CommonModule, SlicePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { BooleanPipe } from "./boolean.pipe";
import { BulletedPipe } from "./bulleted.pipe";
import { ConcatPipe } from "./concat.pipe";
import { AppDatePipe } from "./date.pipe";
import { AppDecimalPipe } from "./decimal.pipe";
import { DirtyControlsPipe } from "./dirty-controls.pipe";
import { DynamicPipe } from "./dynamic.pipe";
import { EnumPipe } from "./enum.pipe";
import { FileSizePipe } from "./file-size.pipe";
import { FormErrorsExceptForPipe, FormErrorsPipe } from "./form-errors.pipe";
import { HasFormErrorsExceptForPipe, HasFormErrorsPipe } from "./has-form-errors.pipe";
import { JoinPipe } from "./join.pipe";
import { ObserveLocalStoragePipe } from "./observe-local-storage.pipe";
import { OptionsPipe } from "./options.pipe";
import { OrderByPipe } from "./order-by.pipe";
import { ProcessModelStringPipe } from "./process-model-string.pipe";
import { ProcessStringPipe } from "./process-string.pipe";
import { PurgeEmptyFieldsPipe } from "./purge-empty-fields.pipe";
import { SafePipe } from "./safe.pipe";
import { ThumbnailPipe } from "./thumbnail.pipe";

const pipes = [
  AppDatePipe,
  AppDecimalPipe,
  BooleanPipe,
  BulletedPipe,
  ConcatPipe,
  DirtyControlsPipe,
  DynamicPipe,
  EnumPipe,
  FileSizePipe,
  FormErrorsPipe,
  FormErrorsExceptForPipe,
  HasFormErrorsPipe,
  HasFormErrorsExceptForPipe,
  JoinPipe,
  ObserveLocalStoragePipe,
  OptionsPipe,
  OrderByPipe,
  ProcessModelStringPipe,
  ProcessStringPipe,
  PurgeEmptyFieldsPipe,
  SafePipe,
  ThumbnailPipe,
];

@NgModule({
  imports: [CommonModule],
  providers: [...pipes, SlicePipe],
  declarations: pipes,
  exports: pipes,
})
export class PipesModule {}
