<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <ng-container *ngIf="form">
    <p-calendar
      [inputId]="inputId"
      [formControl]="form.controls.date"
      [readonly]="readonly"
      [showClear]="showClear && !readonly"
      [showTime]="form.controls.hasTime?.value ?? false"
    >
      <ng-template pTemplate="header">
        <p-checkbox
          inputId="showTime"
          [binary]="true"
          [formControl]="form.controls.hasTime"
          class="mt-2 pr-1"
        ></p-checkbox>
        <label for="showTime">{{ t("showTime.title") }}</label>
      </ng-template>
    </p-calendar>
  </ng-container>
</ng-container>
