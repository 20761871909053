import { Component, Input } from "@angular/core";
import { CustomMenuItem } from "./menu.model";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
})
export class MenuComponent {
  @Input()
  items: CustomMenuItem[];
}
