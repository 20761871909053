import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PrimeNGModule } from "../primeng/primeng.module";
import { TranslocoModule } from "../transloco/transloco.module";
import { EditToolBarComponent } from "./edit-tool-bar.component";
import { DirectivesModule } from "../directives/directives.module";

@NgModule({
  imports: [CommonModule, PrimeNGModule, TranslocoModule, DirectivesModule],
  declarations: [EditToolBarComponent],
  exports: [EditToolBarComponent],
})
export class EditToolBarModule {}
