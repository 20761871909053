<p-messages *ngIf="to.messages" [(value)]="to.messages" [enableService]="false" [closable]="to.closeable"> </p-messages>

<div *ngFor="let field of field.fieldGroup; let i = index">
  <div class="flex flex-row">
    <formly-group [field]="$any(field)">
      <small *ngIf="showError" class="p-error">
        <formly-validation-message class="ui-message-text" [field]="field"></formly-validation-message>
      </small>
    </formly-group>
    <p-button
      *ngIf="!to.readonly && formControl.enabled"
      [label]="to.removeLabel"
      icon="pi pi-times"
      iconPos="left"
      type="button"
      class="align-self-center ml-2"
      (onClick)="remove(i)"
    ></p-button>
  </div>
  <hr class="mt-0" />
</div>

<div class="flex justify-content-start">
  <p-button
    [disabled]="to.disabledAddButton || to.readonly"
    [label]="to.addLabel"
    icon="pi pi-plus"
    iconPos="left"
    type="button"
    (onClick)="add(null, to?.defaultValue?.())"
  ></p-button>
  <p-button
    class="ml-2"
    *ngIf="to.additionalLabel"
    m
    [disabled]="to.disableAdditionalAddButton || to.readonly"
    [label]="to.additionalLabel"
    icon="pi pi-plus"
    type="button"
    (onClick)="add(null, to?.additionalDefaultValue?.())"
  ></p-button>
</div>
