import { Injectable } from "@angular/core";
import { defaultImmigrationCountry, toRealImmigrationCountry } from "@ankaadia/ankaadia-shared";
import { SupportedImmigrationCountry } from "@ankaadia/graphql";
import { AustriaSpecifics } from "./austria/austria-specifics.model";
import { GermanySpecifics } from "./germany/germany-specifics.model";
import { MigrationSpecifics } from "./migration-specifics.model";

@Injectable({ providedIn: "root" })
export class MigrationSpecificsFactory {
  constructor(
    private readonly germanySpecifics: GermanySpecifics,
    private readonly austriaSpecifics: AustriaSpecifics
  ) {}

  getSpecifics(immigrationCountry: SupportedImmigrationCountry): MigrationSpecifics {
    switch (toRealImmigrationCountry(immigrationCountry) ?? defaultImmigrationCountry()) {
      case SupportedImmigrationCountry.De:
        return this.germanySpecifics;
      case SupportedImmigrationCountry.At:
        return this.austriaSpecifics;
    }
    throw new Error(`No specifics for ${immigrationCountry}.`);
  }
}
