import { Component, Input, Output, EventEmitter } from "@angular/core";

interface BtnInput {
  label?: string;
  disabled?: boolean;
}

interface ToolbarConfiguration {
  saveAndClose?: boolean;
  save?: boolean;
  cancel?: boolean;
}

@Component({
  selector: "app-edit-tool-bar",
  templateUrl: "./edit-tool-bar.component.html",
  styleUrl: "./edit-tool-bar.component.scss",
})
export class EditToolBarComponent {
  @Input()
  showBtn: ToolbarConfiguration;

  @Input()
  saveAndCloseBtn: BtnInput;

  @Input()
  saveBtn: BtnInput;

  @Input()
  cancelBtn: BtnInput;

  @Output()
  readonly saveAndClose = new EventEmitter<MouseEvent>();

  @Output()
  readonly save = new EventEmitter<MouseEvent>();

  @Output()
  readonly cancel = new EventEmitter<MouseEvent>();

  protected onSave(event: MouseEvent): void {
    this.save.emit(event);
  }

  protected onCancel(event: MouseEvent): void {
    this.cancel.emit(event);
  }

  protected onSaveAndClose(event: MouseEvent): void {
    this.saveAndClose.emit(event);
  }
}
