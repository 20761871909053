import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ANYTHING } from "@ankaadia/ankaadia-shared";
import { DocumentSelectionCriterion, StaticDataModel } from "@ankaadia/graphql";
import {
  CriterionValueSelection,
  DocumentCriteriaService,
} from "../document-criteria-config/document-criteria.service";

@Component({
  selector: "app-document-criterion-selector",
  templateUrl: "./document-criterion-selector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentCriterionSelectorComponent implements OnChanges {
  readonly DocumentSelectionCriterion = DocumentSelectionCriterion;

  @Input()
  criterion: DocumentSelectionCriterion;

  @Input()
  readonly: boolean;

  @Input()
  value: string[];

  @Input()
  parameters: CriterionValueSelection[];

  @Output()
  readonly valueChange = new EventEmitter<string[]>();

  options: StaticDataModel[];
  placeholder: string;
  label: string;
  internalReadonly: boolean;

  constructor(
    private readonly criteriaService: DocumentCriteriaService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.criterion) {
      this.updateOptions(changes.criterion.currentValue, this.parameters);
      this.placeholder = this.criteriaService.getPlaceholder(changes.criterion.currentValue);
    }
    if (
      changes.parameters &&
      JSON.stringify(changes.parameters.previousValue) !== JSON.stringify(changes.parameters.currentValue)
    ) {
      this.updateOptions(this.criterion, changes.parameters.currentValue);
    }
    if (changes.value) {
      this.updateLabel(changes.value.currentValue, this.options);
    }
  }

  onValueChange(value: string[], toggledValue: string): void {
    if (toggledValue === ANYTHING) {
      value = [ANYTHING];
    } else {
      value = value?.filter((x) => x !== ANYTHING);
    }
    this.valueChange.emit(value);
  }

  private updateOptions(criterion: DocumentSelectionCriterion, parameters: CriterionValueSelection[]): void {
    this.criteriaService.getOptions(criterion, parameters).subscribe((x) => {
      this.options = x;
      this.updateLabel(this.value, this.options);
      this.changeDetector.detectChanges();
      if (this.value?.length && this.options && this.value.some((v) => this.options.every((x) => x.value !== v))) {
        this.valueChange.emit(null);
      }
    });
  }

  private updateLabel(value: string[], options: StaticDataModel[]): void {
    this.label = value?.map((v) => options?.find((x) => x.value === v)?.label)?.join(", ");
  }
}
