export const OrganizationsWithBeds: string[] = [
  "UNVHOSPITAL", // Universitätsklinik
  "MILTHOSPITAL", // Milt. Krankenhaus
  "PRIVHOSPITAL", // Privates Krankenhaus
  "PUBHOSPITAL", // Öffentliches Krankenhaus
  "HOSPITAL", // Krankenhaus
  "COMPNURSE", // Betriebskrankenpflege
  "REHA", // Rehaklinik
  "HCA", // Heimpflege / Erwachsene
  "HCC", // Heimpflege / Kinder
  "ECC", // Altenpflege
  "POLICLINIC", // Poliklinik
];
