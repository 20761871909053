import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DocumentActivityTypes } from "@ankaadia/ankaadia-shared";
import { NotificationScope, NotificationSettingsFragment } from "@ankaadia/graphql";
import { intersection } from "lodash";
import { startWith } from "rxjs";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { NotificationSettingsFormService } from "./notification-configuration-form.service";

@Component({
  selector: "app-notification-configuration",
  templateUrl: "./notification-configuration.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationConfigurationComponent implements OnInit {
  readonly form = this.formService.createForm();
  readonly scopes = this.staticDataService.transformEnumToStaticDataModel("NotificationScope", NotificationScope);

  @Input()
  set settings(settings: NotificationSettingsFragment) {
    this.form.reset(undefined, { emitEvent: false });
    this.form.patchValue(settings);
  }

  @Output()
  readonly changed = new EventEmitter<NotificationSettingsFragment>();

  @Output()
  readonly closed = new EventEmitter<void>();

  showDocumentTypeFilter: boolean;

  constructor(
    private readonly formService: NotificationSettingsFormService,
    private readonly staticDataService: StaticDataService
  ) {
  }

  ngOnInit(): void {
    this.form.controls.activities.valueChanges
      .pipe(startWith(this.form.controls.activities.value))
      .subscribe((types) => {
        if (!types?.length) {
          this.showDocumentTypeFilter = true;
        } else if (!intersection(types, DocumentActivityTypes).length) {
          this.form.controls.documents.reset();
          this.showDocumentTypeFilter = false;
        } else {
          this.showDocumentTypeFilter = true;
        }
      });
  }

  save(): void {
    this.changed.emit(this.form.value);
    this.close();
  }

  close(): void {
    this.closed.emit();
  }
}
