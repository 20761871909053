<ng-container *ngIf="message$ | async as message">
  <div *ngIf="Icons[message.severity] as icon" class="badge">
    <i class="text-xl" [ngClass]="icon" [pTooltip]="tooltip" tooltipPosition="left"></i>
  </div>

  <ng-template #tooltip>
    <h6 class="mt-0 mb-0">{{ message.summary }}</h6>
    <p *ngIf="message.detail" class="mt-2">{{ message.detail }}</p>
  </ng-template>
</ng-container>
