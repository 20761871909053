import { Injectable } from "@angular/core";
import { GetRecognitionAuthoritiesGQL, StaticDataModel } from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class RecognitionAuthorityService {
  constructor(private readonly get: GetRecognitionAuthoritiesGQL) {}

  getRecognitionAuthorities(
    organizationId: string,
    immigrationCountry: string,
    federalStates?: string[]
  ): Observable<StaticDataModel[]> {
    return this.get
      .fetch({ input: { organizationId, immigrationCountry, federalStates } }, { fetchPolicy: "cache-first" })
      .pipe(
        map((x) => x.data.getRecognitionAuthorities),
        map((authorities) =>
          authorities.map((authority) => ({
            label: authority.respTitle,
            value: authority.respId.toString(),
          }))
        )
      );
  }
}
