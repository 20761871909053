import { Injectable } from "@angular/core";
import { CreateUserFeedbackGQL, UserFeedbackCreateInput } from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class UserFeedbackService {
  constructor(private readonly userFeedbackCreate: CreateUserFeedbackGQL) {}

  create(input: UserFeedbackCreateInput): Observable<boolean> {
    return this.userFeedbackCreate
      .mutate({ input: input })
      .pipe(map((result) => result.data.createUserFeedback.status));
  }
}
