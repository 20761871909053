export interface IQualificationModel {
  id: string;
  qualification?: string;
  nameOfInstitution?: string;
  addressOfInstitution?: string;
  startOfTraining?: Date;
  endOfTraining?: Date;
  occupation?: string;
  professionalTitle?: string;
  occupationalTitle?: string;
  diplomaIssuance?: Date;
  city?: string;
  country?: string;
}

export type ExperienceAPI = Omit<IExperienceModel, "id" | "experienceFields"> & {
  experienceField: string[];
};

export interface IExperienceModel {
  id: string;
  experienceStartDate: Date;
  experienceEndDate: Date;
  experienceFields: IExperienceFieldModel[];
  experienceFunction?: string;
  experienceFacility: string;
  experienceInstitution?: string;
  experienceBedCount?: string;
  experienceWorkArea?: string;
  experienceYears?: number;
  experienceMonth?: number;
  experienceCount?: number;
  experienceCoreTasks?: string;
  city?: string;
  country?: string;
  occupation?: string;
  experienceType?: ExperienceType;
  otherActivity?: string;
  asFreelancer?: boolean;
  mandatoryInternship?: boolean;
  shiftSystem?: boolean;
  workHours?: string;
  hoursPerWeek?: number;
}

export interface IExperienceFieldModel {
  id: string;
  experienceField: string;
  experienceNotes: IQuillModel;
}

export interface IQuillModel {
  contents: string;
  delta: object;
}

export interface IBasicLanguageSkillModel {
  id: string;
  language: string;
  skillLevel: string;
  certified?: boolean;
  examInstitution?: string;
}

export interface ILanguageSkillModel extends IBasicLanguageSkillModel {
  examCertificateDate?: Date;
}

export interface IFurtherEducation {
  id: string;
  startDate: Date;
  endDate: Date;
  fieldOfEducation: string;
  nameOfInstitution?: string;
  totalHours?: number;
  description?: string;
  nameOfEducation?: string;
  notProfessionRelated?: boolean;
}

export interface IProcessStatusModel {
  estimatedArrivalDate?: Date;
  estimatedWorkStartDate?: Date;
}

export interface IAddressModel {
  id: string;
  addressType: AddressType[];
  residentSince?: Date;
  address?: string;
  zipcode?: string;
  city?: string;
  region?: string;
  country?: string;
}

export interface IBankDetailsModel {
  id: string;
  // typeOfBankTransfer: string;
  isSepa: boolean;
  // countryOfBankDetail: string;
  isImmigrationCountryBankDetail: boolean;
  isCurrentAccount: boolean;
  accountHolder?: string;
  bankName?: string;

  //SEPA
  iban?: string;
  bic?: string;

  //SWIFT
  bankAccountNumber?: string;
  bankCountry?: string;
  currency?: string;

  isBic?: boolean;
  bankCode?: string;
}

export interface IOtherModel {
  wayToUs?: string;
  hobbies?: string;
  socialSkills?: string;
  itSkills?: string;
  familiarEquipment?: string;
}

export interface ICompetencyModel {
  id: string;
  skillId: string;
  proficiency: string;
}

export interface ICompetenciesModel {
  knowledges: ICompetencyModel[];
  skills: ICompetencyModel[];
  customKnowledges: ICompetencyModel[];
  customSkills: ICompetencyModel[];
}

export interface IBasicVaccinationModel {
  id: string;
  vaccinations: string[];
  vaccine?: string;
  plannedNextShot?: Date;
  plannedFinalShot?: Date;
  comments?: string;
  vaccinationStatus: string;
}

export interface IVaccinationModel extends IBasicVaccinationModel {
  lastShot?: Date;
}

export interface ICandidateOSModel {
  title?: string;
  salutation: string;
  firstname: string;
  lastname: string;
  birthname?: string;
  email?: string;
  phone?: string;
  mobilePhone?: string;
  immigrationCountryPhone?: string;
  birthdate?: Date;
  martialStatus?: string;
  maritalStatusSince?: Date;
  numberOfChildren?: number;
  citizenship?: string;
  countryOfOrigin?: string;
  countryOfHabitualResidence?: string;
  countryOfBirth?: string;
  cityOfBirth?: string;
  passNumber?: string;
  passAuthority?: string;
  idCardNumber?: string;
  taxNumber?: string;
  socSecNumber?: string;
  healthInsuranceCompanyName?: string;
  healthInsuranceNumber?: string;
  hasLiabilityInsurance?: boolean;
  liabilityInsuranceNumber?: string;
  liabilityInsuranceCompanyName?: string;
  drivingLicense?: string[]; //?

  addresses?: IAddressModel[];
  qualifications?: IQualificationModel[];
  furtherEducations?: IFurtherEducation[];
  noExperienceAvailable?: boolean;
  noFurtherEducationAvailable?: boolean;

  experiences?: IExperienceModel[];
  totalExperienceInMonth?: number;

  languageLevelCV?: string;
  languageSkills?: ILanguageSkillModel[];

  preferredCareFacility?: string[];
  preferredCareFacilityFlexibility?: boolean;
  preferredWorkingField?: string[];
  preferredWorkingFieldFlexibility?: boolean;
  preferredLocationState?: string[];
  preferredLocationStateFlexibility?: boolean;
  preferredCity?: string[];
  preferredCityFlexibility?: boolean;
  preferredCandidates?: string;
  preferredPathOfRecognition?: string;
  preferredLanguageLevelForInterview?: string;
  preferredLanguageLevelForTransfer?: string;
  preferredStartDate?: Date;
  preferredEmploymentType?: string;
  preferredTemporalScope?: string;
  preferredTypeOfImmigration?: string;
  preferredFamilyReunion?: string;

  vaccinations?: IVaccinationModel[];

  customFields?: any;

  other?: IOtherModel;

  bankDetails?: IBankDetailsModel[];

  competencies?: ICompetenciesModel;
}

export interface IDateWithTime {
  date?: Date;
  hasTime?: boolean;
}

export enum AddressType {
  IdAddress = "ID-ADDRESS",
  CountryOfOriginAddress = "COUNTRY-OF-ORIGIN-ADDRESS",
  CurrentAddress = "CURRENT-ADDRESS",
  ImmigrationCountryAddress = "IMMIGRATION-COUNTRY-ADDRESS",
}

export interface IInternalModel {
  costCenter?: string;
  languageSchooldId?: string;
  sourceOrganizationId?: string;
  category?: string;
  notes?: string;
}

export enum ExperienceType {
  Normal = "Normal",
  OtherActivities = "OtherActivities",
}

export enum LanguageModules {
  Oral = "Oral",
  Written = "Written",
  Reading = "Reading",
  Speaking = "Speaking",
  Listening = "Listening",
  Writing = "Writing",
}

export enum KnowledgeProficiency {
  None = "None",
  Basic = "Basic",
  Expert = "Expert",
}

export enum YearsOfSkillExperience {
  NoExperience = "NoExperience",
  LessThanOne = "LessThanOne",
  BetweenOneAndThree = "BetweenOneAndThree",
  MoreThanThree = "MoreThanThree",
}
