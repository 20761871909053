export type PictureType = "picture" | "alternative-picture" | "thumbnail";

export function getImageDownlodURI(organizationId: string, candidateId: string, type: PictureType): string {
  return `${getImageContainerName(organizationId)}/${getImageBlobName(candidateId, type)}`;
}

export function getLogoDownloadURI(organizationId: string): string {
  return `${getImageContainerName(organizationId)}/${getLogoBlobName()}`;
}

export function getImageContainerName(organizationId: string): string {
  return `${organizationId}-images`;
}

export function getAiContainerName(organizationId: string): string {
  return `${organizationId}-ai`;
}

export function getImageBlobName(candidateId: string, type: PictureType): string {
  return `${candidateId}-${type}.jpeg`;
}

export function getLogoBlobName(): string {
  return `logo`;
}

export function getReportContainerName(): string {
  return `reports`;
}

export function getXlsxContainerName(organizationId: string): string {
  return `${organizationId}-xlsx`;
}

export function getTranslationContainerName(organizationId: string): string {
  return `${organizationId}-translation`;
}

export function getUsedVariablesContainerName(organizationId: string): string {
  return `${organizationId}-used-variables`;
}

export function getExportStaticDataContainerName(organizationId: string): string {
  return `${organizationId}-export-static-data`;
}
