<div class="field" [ngClass]="to.styleClass">
  <div *ngIf="to.useAlignmentCheckBox; else simpleLabel" class="field-checkbox">
    <p-checkbox class="alignment-checkbox"></p-checkbox>
    <ng-container *ngTemplateOutlet="labelData"></ng-container>
  </div>
  <ng-template #simpleLabel>
    <ng-container *ngTemplateOutlet="labelData"></ng-container>
  </ng-template>

  <ng-template #labelData>
    <label *ngIf="to.label && to.hideLabel !== true" [for]="id" [pTooltip]="to.tooltip">
      {{ to.label }}
      <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
      <i class="ml-1 pi pi-info-circle" *ngIf="to.tooltip"></i>
    </label>
  </ng-template>

  <ng-container #fieldComponent></ng-container>

  <small *ngIf="showError" class="p-error">
    <formly-validation-message class="ui-message-text" [field]="field"></formly-validation-message>
  </small>
</div>
