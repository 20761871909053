import { Injectable } from "@angular/core";
import {
  EmailFragment,
  EmailForListFragment,
  GetEmailsForCandidateGQL,
  GetEmailsForProcessGQL,
  GetEmailsForUserGQL,
  GetEmailGQL,
  GetOutboxForProcessGQL,
  GetOutboxEmailGQL,
  GetNextOutboxRunGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class EmailsService {
  constructor(
    private readonly emailsForCandidate: GetEmailsForCandidateGQL,
    private readonly emailsForProcess: GetEmailsForProcessGQL,
    private readonly emailsForUser: GetEmailsForUserGQL,
    private readonly outboxForProcess: GetOutboxForProcessGQL,
    private readonly emailGet: GetEmailGQL,
    private readonly outboxEmailGet: GetOutboxEmailGQL,
    private readonly outboxNextRun: GetNextOutboxRunGQL
  ) {}

  getAllForCandidate(candidateId: string, organizationId: string): Observable<EmailForListFragment[]> {
    return this.emailsForCandidate
      .watch({ input: { candidateId: candidateId, organizationId: organizationId } }, { fetchPolicy: "network-only" })
      .valueChanges.pipe(map((result) => result.data.getEmailsForCandidate));
  }

  getAllForUser(userId: string, organizationId: string): Observable<EmailForListFragment[]> {
    return this.emailsForUser
      .watch({ input: { userId: userId, organizationId: organizationId } }, { fetchPolicy: "network-only" })
      .valueChanges.pipe(map((result) => result.data.getEmailsForUser));
  }

  getAllForProcess(
    processId: string,
    organizationId: string,
    candidateId?: string
  ): Observable<EmailForListFragment[]> {
    return this.emailsForProcess
      .watch(
        { input: { processId: processId, organizationId: organizationId, candidateId: candidateId ?? null } },
        { fetchPolicy: "network-only" }
      )
      .valueChanges.pipe(map((result) => result.data.getEmailsForProcess));
  }

  getOutboxForProcess(
    processId: string,
    organizationId: string,
    candidateId?: string
  ): Observable<EmailForListFragment[]> {
    return this.outboxForProcess
      .watch(
        { input: { processId: processId, organizationId: organizationId, candidateId: candidateId ?? null } },
        { fetchPolicy: "network-only" }
      )
      .valueChanges.pipe(map((result) => result.data.getOutboxForProcess));
  }

  get(emailId: string, organizationId: string): Observable<EmailFragment> {
    return this.emailGet
      .fetch({ input: { id: emailId, organizationId: organizationId } })
      .pipe(map((result) => result.data.getEmail));
  }

  getOutboxEmail(emailId: string, organizationId: string): Observable<EmailFragment> {
    return this.outboxEmailGet
      .fetch({ input: { id: emailId, organizationId: organizationId } })
      .pipe(map((result) => result.data.getOutboxEmail));
  }

  getNextOutboxRun(processId: string, organizationId: string): Observable<Date> {
    return this.outboxNextRun.fetch({ input: { processId, organizationId } }).pipe(map((x) => x.data.getNextOutboxRun));
  }
}
