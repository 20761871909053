import { Injectable } from "@angular/core";
import {
  GetRequiredVaccinationsGQL,
  RequiredVaccinations,
  UpdateRequiredVaccinationsGQL,
  RequiredVaccinationsUpdateInput,
  GetRequiredVaccinationsConfigurationGQL,
  RequiredVaccinationsResultEntry,
  RequiredVaccinationsForCandidateInput,
  GetVaccinationStatesForCandidatesGQL,
  CandidateVaccinationStatus,
  RequiredVaccinationsStatesForCollectionInput,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RequiredVaccinationsService {
  constructor(
    private readonly getRequiredVaccinesConfigs: GetRequiredVaccinationsConfigurationGQL,
    private readonly getRequiredVaccines: GetRequiredVaccinationsGQL,
    private readonly getVaccinationStatesForCandidates: GetVaccinationStatesForCandidatesGQL,
    private readonly updateRequiredVaccines: UpdateRequiredVaccinationsGQL
  ) {}

  getConfiguration(organizationId: string): Observable<RequiredVaccinations> {
    return this.getRequiredVaccinesConfigs
      .fetch({
        input: { organizationId: organizationId },
      })
      .pipe(map((result) => result.data.getRequiredVaccinationsConfiguration));
  }

  get(input: RequiredVaccinationsForCandidateInput): Observable<RequiredVaccinationsResultEntry[]> {
    return this.getRequiredVaccines
      .fetch(
        {
          input,
        },
        { fetchPolicy: "cache-first" }
      )
      .pipe(map((result) => result.data?.getRequiredVaccinations ?? []));
  }

  getStatesForCandidates(
    input: RequiredVaccinationsStatesForCollectionInput
  ): Observable<CandidateVaccinationStatus[]> {
    return this.getVaccinationStatesForCandidates
      .fetch({
        input,
      })
      .pipe(map((result) => result.data?.getRequiredVaccinationStatesForCandidates ?? []));
  }

  update(input: RequiredVaccinationsUpdateInput): Observable<RequiredVaccinations> {
    return this.updateRequiredVaccines
      .mutate({ input: input })
      .pipe(map((result) => result.data.updateRequiredVaccinations));
  }
}
