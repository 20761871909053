export const CandidateRole = "Candidate";
export const EmployerRole = "Employer";
export const LocalPartnerRole = "LocalPartner";
export const LanguageSchoolRole = "LanguageSchool";
export const PSARole = "PSA";
export const CountryManagerRole = "CountryManager";
export const StatusReaderRole = "StatusReader";
export const EducationProviderRole = "EducationProviderRole";
export const RelocationProviderRole = "RelocationProviderRole";
export const IntegrationProviderRole = "IntegrationProviderRole";
export const ProjectManagerRole = "ProjectManagerRole";
export const CandidateManagerRole = "CandidateManagerRole";
export const HRDepartmentRole = "HRDepartmentRole";
export const SupervisorRole = "SupervisorRole"; // Vorgesetzter

// Pariah Role. Only used as fallback value in dropdowns. Don't include it in any other list.
export const OtherRole = "Other";

const allProcessRoles = [
  CandidateRole,
  EmployerRole,
  LocalPartnerRole,
  LanguageSchoolRole,
  PSARole,
  CountryManagerRole,
  StatusReaderRole,
  EducationProviderRole,
  RelocationProviderRole,
  IntegrationProviderRole,
  ProjectManagerRole,
  CandidateManagerRole,
  HRDepartmentRole,
  SupervisorRole,
];
export function getAllProcessRoles(): KnownProcessRoles[] {
  return allProcessRoles;
}
export function getAllResponsibleRoles(): KnownProcessRoles[] {
  return [
    EmployerRole,
    CandidateRole,
    LocalPartnerRole,
    PSARole,
    RelocationProviderRole,
    IntegrationProviderRole,
    EducationProviderRole,
  ];
}
export function areRepresentativesAllowedForRecognitionRole(role: string): boolean {
  return role && role !== CandidateRole;
}

export function getAllScholarshipRoles(): KnownProcessRoles[] {
  return [LocalPartnerRole, LanguageSchoolRole, PSARole, EmployerRole];
}

export function getAllEducationVoucherRoles(): KnownProcessRoles[] {
  return [PSARole, EmployerRole, CandidateRole];
}

export function getAllRecognitionPaymentRoles(): KnownProcessRoles[] {
  return [PSARole, EmployerRole, CandidateRole, RelocationProviderRole];
}

export function getAllQualificationEvaluationRoles(): KnownProcessRoles[] {
  return [EmployerRole, CandidateRole, LocalPartnerRole, PSARole, EducationProviderRole];
}

export function getAllQualificationEvaluationPaymentRoles(): KnownProcessRoles[] {
  return [EmployerRole, CandidateRole, PSARole, RelocationProviderRole, EducationProviderRole];
}

export function getAllRecognitionRoles(): KnownProcessRoles[] {
  return [PSARole, LocalPartnerRole, EmployerRole, CandidateRole, RelocationProviderRole];
}

export function getAllQualificationMeasureRoles(): KnownProcessRoles[] {
  return [PSARole, LocalPartnerRole, EmployerRole, CandidateRole, EducationProviderRole, RelocationProviderRole];
}

export function getAllComplementaryMeasureRoles(): KnownProcessRoles[] {
  return [PSARole, LocalPartnerRole, EmployerRole, CandidateRole];
}

export function getAllLaborMarketRoles(): KnownProcessRoles[] {
  return [PSARole, LocalPartnerRole, EmployerRole, CandidateRole, RelocationProviderRole];
}

export function getAllVisaRoles(): KnownProcessRoles[] {
  return [PSARole, LocalPartnerRole, EmployerRole, CandidateRole, RelocationProviderRole];
}

export function getAllRwrRoles(): KnownProcessRoles[] {
  return [PSARole, LocalPartnerRole, EmployerRole, CandidateRole];
}

export function getAllResidenceRoles(): KnownProcessRoles[] {
  return [PSARole, LocalPartnerRole, EmployerRole, CandidateRole, RelocationProviderRole];
}

export function getAllEducationExamPaymentRoles(): KnownProcessRoles[] {
  return [PSARole, EmployerRole, CandidateRole];
}

export function getAllHousingAcquisitionRoles(): KnownProcessRoles[] {
  return [
    EmployerRole,
    CandidateRole,
    LocalPartnerRole,
    PSARole,
    RelocationProviderRole,
    IntegrationProviderRole,
    EducationProviderRole,
  ];
}

export type KnownProcessRoles = (typeof allProcessRoles)[number];
