import { Component, DestroyRef, OnInit, ViewChild } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { MultiSelectChangeEvent } from "primeng/multiselect";
import { OverlayPanel } from "primeng/overlaypanel";
import Quill from "quill";
import { v4 as uuidv4 } from "uuid";
import { startWith } from "rxjs";
import { StaticDataModel } from "@ankaadia/graphql";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "app-chips",
  templateUrl: "./chips.component.html",
})
export class FormlyChipsComponent extends FieldType implements OnInit {
  constructor(private readonly destroyRef: DestroyRef) {
    super();
  }

  @ViewChild("chip") chip: OverlayPanel;
  protected selectedChip: any;

  protected selectedOptions: StaticDataModel[];

  private editor: Quill;

  ngOnInit(): void {
    this.field.formControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .pipe(startWith(this.field.formControl.value))
      .subscribe((value) => {
        this.selectedOptions = value?.map((item) => item.experienceField) ?? [];
      });
  }

  change(event: MultiSelectChangeEvent): void {
    if (event.value.includes(event.itemValue.value)) {
      this.field.formControl.setValue([
        ...(this.field.formControl.value || []),
        { id: uuidv4(), experienceField: event.itemValue.value, experienceNotes: null },
      ]);
    } else {
      this.field.formControl.setValue(
        this.field.formControl.value.filter((item) => item.experienceField !== event.itemValue.value)
      );
    }
  }

  clear(): void {
    this.field.formControl.reset([]);
  }

  displayOverlay(event: MouseEvent, field: any): void {
    this.selectedChip = field;
    this.updateEditor(this.editor);
    this.chip.toggle(event);
  }

  updateEditor(editor: Quill): void {
    if (editor) {
      this.editor = editor;
      this.editor.setContents(this.selectedChip?.experienceNotes?.delta);
    }
  }

  updateHtml(): void {
    const value = this.field.formControl.value;
    value.find((item) => item.id === this.selectedChip.id).experienceNotes = {
      contents: this.editor.getText(),
      delta: this.editor.getContents(),
    };
    this.field.formControl.setValue(value);
  }
}
