import { DeepTypeable } from "./deepTypeable";
import {
  IAddressModel,
  IBankDetailsModel,
  ICandidateOSModel,
  ICompetenciesModel,
  ICompetencyModel,
  IExperienceModel,
  IFurtherEducation,
  IInternalModel,
  ILanguageSkillModel,
  IOtherModel,
  IProcessStatusModel,
  IQualificationModel,
  IVaccinationModel,
} from "./model/candidate.model";
import { ICustomFieldsModel, ILanguageProgressTracking } from "./model/customFields.model";

//Replace a nested type in a nested type...
// stolen from https://stackoverflow.com/questions/51599481/replacing-property-of-a-typescript-type
type Identity<T> = { [P in keyof T]: T[P] };
type Replace<T, K extends keyof T, TReplace> = Identity<
  Pick<T, Exclude<keyof T, K>> & {
    [P in K]: TReplace;
  }
>;

type CustomMedwingFields = Replace<
  ICustomFieldsModel["medwing"],
  "languageProgressTracking",
  Omit<ILanguageProgressTracking, "id">
>;
type CustomFieldsWithConvertedArray = Replace<ICustomFieldsModel, "medwing", CustomMedwingFields>;

export const customFieldLabelMapping: DeepTypeable<CustomFieldsWithConvertedArray, string> = {
  stFranziskus: {
    educationalAttribution: "customFields.educationalAttribution.title",
  },
  defa: {
    expectationWorkliveInGermany: "customFields.expectationWorkliveInGermany.title",
    whyStopWorkingForExistingEmployer: "customFields.whyStopWorkingForExistingEmployer.title",
    analysisScore: "customFields.analysisScore.title",
    assertivenessScore: "customFields.assertivenessScore.title",
    basicCalculationScore: "customFields.basicCalculationScore.title",
    communicationSkillScore: "customFields.communicationSkillScore.title",
    percentageCalculationScore: "customFields.percentageCalculationScore.title",
    spatialThinkingScore: "customFields.spatialThinkingScore.title",
    stressHandlungSkillScore: "customFields.stressHandlungSkillScore.title",
    teamworkSkillScore: "customFields.teamworkSkillScore.title",
  },
  medwing: {
    languageProgressTracking: {
      comment: "customFields.languageProgressTracking.comment",
      module: "customFields.languageProgressTracking.module",
      presenceRatio: "customFields.languageProgressTracking.presenceRatio",
    },
  },
};

export const candidateLabelMapping: Record<keyof ICandidateOSModel, string> & {
  noExperienceAvailable: string;
  noFurtherEducationAvailable: string;
  immigrationCountry: string;
  recogNoticeAuthority: string;
  recogNoticeFederalState: string;
  recogNoticeFileNumber: string;
  recogNoticePracticeHours: string;
  recogNoticeReceived: string;
  recogNoticeTheorieHours: string;
  recogStarted: string;
  recogSubmissionDate: string;
  pathOfRecognition: string;
  relocationRequirements: string;
} = {
  title: "title.title",
  salutation: "salutation.title",
  firstname: "firstName.title",
  lastname: "lastName.title",
  birthname: "birthName.title",
  email: "email.title",
  phone: "phone.title",
  mobilePhone: "mobile.title",
  immigrationCountryPhone: "immigrationCountryPhone.title",
  birthdate: "birthDate.title",
  martialStatus: "maritalStatus.title",
  maritalStatusSince: "maritalStatusSince.title",
  numberOfChildren: "children.title",
  addresses: "address.title",
  citizenship: "citizenship.title",
  countryOfOrigin: "countryOfOrigin.title",
  countryOfHabitualResidence: "countryOfHabitualResidence.title",
  countryOfBirth: "countryOfBirth.title",
  cityOfBirth: "cityOfBirth.title",
  passNumber: "passNumber.title",
  passAuthority: "passAuthority.title",
  idCardNumber: "idCardNumber.title",
  taxNumber: "taxNumber.title",
  healthInsuranceCompanyName: "healthInsuranceCompanyName.title",
  healthInsuranceNumber: "healthInsuranceNumber.title",
  hasLiabilityInsurance: "hasLiabilityInsurance.title",
  liabilityInsuranceNumber: "liabilityInsuranceNumber.title",
  liabilityInsuranceCompanyName: "liabilityInsuranceCompanyName.title",
  socSecNumber: "socSecNumber.title",
  drivingLicense: "drivingLicense.title",
  qualifications: "qualification.title",
  experiences: "experience.title",
  languageLevelCV: "languageTrainingTracking.languageLevelCV.title",
  languageSkills: "languageSkills.title",
  preferredCareFacility: "preferences.careFacility.title",
  preferredCareFacilityFlexibility: "preferences.careFacility.flexibility.title",
  preferredWorkingField: "preferences.field.title",
  preferredWorkingFieldFlexibility: "preferences.field.flexibility.title",
  preferredLocationState: "preferences.state.title",
  preferredLocationStateFlexibility: "preferences.state.flexibility.title",
  preferredCity: "preferences.city.title",
  preferredCityFlexibility: "preferences.city.flexibility.title",
  preferredCandidates: "preferences.candidates.title",
  preferredPathOfRecognition: "preferences.pathOfRecognition.title",
  preferredLanguageLevelForInterview: "preferences.languageLevelForInterview.title",
  preferredLanguageLevelForTransfer: "preferences.languageLevelForTransfer.title",
  preferredStartDate: "preferences.preferredStartDate.title",
  preferredEmploymentType: "preferences.employmentType.title",
  preferredTemporalScope: "preferences.temporalScope.title",
  preferredTypeOfImmigration: "preferences.typeOfImmigration.title",
  preferredFamilyReunion: "preferences.familyReunion.title",
  furtherEducations: "furtherEducation.title",
  totalExperienceInMonth: "experience.total",
  // socSecNumber: "",
  vaccinations: "vaccination.title",
  other: "other.title2",
  customFields: "",
  noExperienceAvailable: "noExperienceAvailabl",
  noFurtherEducationAvailable: "experience.noFurtherEducationAvailable",
  immigrationCountry: "immigrationCountry.title",
  recogNoticeAuthority: "recognitionAuthority.title",
  recogNoticeFederalState: "federalState.recognition",
  recogNoticeFileNumber: "fileNumber.title",
  recogNoticePracticeHours: "recognition.practiceHours",
  recogNoticeReceived: "recognition.received",
  recogNoticeTheorieHours: "recognition.theoryHours",
  recogStarted: "recognition.started",
  recogSubmissionDate: "recognition.submissionDate",
  pathOfRecognition: "pathOfRecognition.title",
  relocationRequirements: "relocationRequirements.title",
  bankDetails: "bankDetails.title",
  competencies: "competencies.title",
};

export const experienceLabelMapping: Record<keyof IExperienceModel, string> = {
  id: "",
  experienceStartDate: "startOfExperience.title",
  experienceEndDate: "endOfExperience.title",
  experienceFields: "experienceFields.title",
  experienceFunction: "function.title",
  experienceFacility: "careFacility.title",
  experienceInstitution: "nameOfInstitution2.title",
  experienceBedCount: "beds.title",
  experienceCount: "experience.opPerMonth",
  experienceMonth: "experience.month",
  experienceWorkArea: "experienceWorkArea.title",
  experienceYears: "experience.years",
  city: "city.title",
  country: "country.title",
  experienceCoreTasks: "experience.coreTasks",
  occupation: "",
  experienceType: "experienceType.title",
  otherActivity: "otherActivity.title",
  asFreelancer: "asFreelancer.title",
  mandatoryInternship: "mandatoryInternship.title",
  shiftSystem: "shiftSystem.title",
  workHours: "workHours.title",
  hoursPerWeek: "hoursPerWeek.title",
};

export const languageSkillLabelMapping: Partial<Record<keyof ILanguageSkillModel, string>> = {
  language: "language.title",
  skillLevel: "skillLevel.shortTitle",
  certified: "languageSkills.certified",
  examCertificateDate: "examCertificateDate.title",
  examInstitution: "examInstitution.title",
};

export const qualificationLabelMapping: Record<keyof IQualificationModel, string> = {
  id: "",
  qualification: "qualification.degree",
  nameOfInstitution: "nameOfInstitution.title",
  startOfTraining: "startOfTraining.title",
  endOfTraining: "endOfTraining.title",
  occupation: "occupation.title",
  professionalTitle: "professionalTitle.title",
  occupationalTitle: "occupationalTitle.title",
  diplomaIssuance: "diplomaIssuance.title",
  addressOfInstitution: "addressOfInstitution.title",
  city: "city.title",
  country: "",
};

export const furtherEducationLabelMapping: Record<keyof IFurtherEducation, string> = {
  id: "",
  startDate: "furtherEducation.startDate.title",
  endDate: "furtherEducation.endDate.title",
  fieldOfEducation: "furtherEducation.fieldOfEducation.title",
  nameOfInstitution: "nameOfInstitution.title",
  totalHours: "furtherEducation.totalHours.title",
  description: "furtherEducation.description.title",
  nameOfEducation: "furtherEducation.nameOfEducation.title",
  notProfessionRelated: "furtherEducation.notProfessionRelated.title",
};

export const processStatusLabelMapping: Record<keyof IProcessStatusModel, string> = {
  estimatedArrivalDate: "estimatedArrivalDate.title",
  estimatedWorkStartDate: "estimatedWorkStartDate.title",
};

export const addressLabelMapping: Record<keyof IAddressModel, string> = {
  id: "",
  address: "address.title",
  residentSince: "residentSince.title",
  addressType: "addressType.title",
  city: "city.title",
  country: "country.title",
  region: "federalState.title",
  zipcode: "zipCode.title",
};

export const otherLabelMapping: Record<keyof IOtherModel, string> = {
  wayToUs: "wayToUs.title",
  hobbies: "hobbies.title",
  socialSkills: "socialSkills.title",
  itSkills: "itSkills.title",
  familiarEquipment: "familiarEquipment.title",
};

export const bankDetailsLabelMapping: Record<keyof IBankDetailsModel, string> = {
  id: "",
  isSepa: "typeOfBankTransfer.title",
  isImmigrationCountryBankDetail: "countryTypeOfBankDetail.title",
  isCurrentAccount: "isCurrentAccount.title",
  accountHolder: "accountHolder.title",
  bankName: "bankName.title",
  iban: "iban.title",
  bic: "bic.title",
  bankAccountNumber: "bankAccountNumber.title",
  bankCountry: "bankCountry.title",
  currency: "currency.title",
  isBic: "isBic.title",
  bankCode: "bankCode.title",
};

export const languageProgressTrackingLabelMapping = {
  assessmentTestDate: "languageTrainingTracking.languageProgressTracking.assessmentTestDate.title",
  assessmentTestResult: "languageTrainingTracking.languageProgressTracking.assessmentTestResult.title",
  assessmentTestSecondTryDate: "languageTrainingTracking.languageProgressTracking.assessmentTestSecondTryDate.title",
  assessmentTestSecondTryResult:
    "languageTrainingTracking.languageProgressTracking.assessmentTestSecondTryResult.title",
  level: "languageTrainingTracking.languageProgressTracking.level.title",
  module: "languageTrainingTracking.languageProgressTracking.module.title",
  presenceRatio: "languageTrainingTracking.languageProgressTracking.presenceRatio.title",
  statusLanguageCertificate: "languageTrainingTracking.languageProgressTracking.statusLanguageCertificate.title",
  statusLanguageCertificateDate:
    "languageTrainingTracking.languageProgressTracking.statusLanguageCertificateDate.title",
};

export const VaccinationLabelMapping: Record<keyof IVaccinationModel, string> = {
  id: "",
  vaccinations: "vaccinations.name.title",
  vaccine: "vaccinations.vaccine.title",
  lastShot: "vaccinations.lastShot.title",
  comments: "vaccinations.comments.title",
  vaccinationStatus: "vaccinations.vaccinationStatus.title",
  plannedNextShot: "vaccinations.plannedNextShot.title",
  plannedFinalShot: "vaccinations.plannedFinalShot.title",
};

export const internalLabelMapping: Record<keyof IInternalModel, string> = {
  costCenter: "costCenter.title",
  languageSchooldId: "partner.languageSchool",
  sourceOrganizationId: "partner.source",
  category: "partner.category",
  notes: "candidate.notesFull",
};

export const competenciesLabelMapping: Record<keyof ICompetenciesModel, string> = {
  knowledges: "knowledges.title",
  skills: "skills.title",
  customKnowledges: "customKnowledges.title",
  customSkills: "customSkills.title",
};

export const competencyLabelMapping: Record<keyof ICompetencyModel, string> = {
  id: "",
  skillId: "skill.title",
  proficiency: "proficiency.title",
};
