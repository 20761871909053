import { UserRole } from "./user-permissions-roles";

export const responsibleEmployeeRoles = [
  UserRole.Administrator,
  UserRole.CandidateManager,
  UserRole.ContentAdministrator,
  UserRole.PartnerAdministrator,
  UserRole.ProcessAdministrator,
  UserRole.ProcessObserver,
  UserRole.User,
];
