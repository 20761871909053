<ng-container *transloco="let t">
  <h5 class="mt-4">{{ t("facebookMessenger.consentheader") }}</h5>
  <!-- <p-checkbox [binary]="true" [formControl]="agreedControl" inputId="binary" class="mt-2"></p-checkbox> -->
  <div class="flex flex-column align-items-center" style="max-width: 40rem">
    <p>{{ t("facebookMessenger.consent") }}</p>
    <div class="fb-root"></div>
    <div
      class="fb-send-to-messenger flex flex-column align-items-center"
      [attr.messenger_app_id]="facebookAppId"
      [attr.page_id]="facebookPageId"
      [attr.data-ref]="facebookRef"
      color="blue"
      size="xlarge"
      cta_text="SEND_ME_MESSAGES"
    ></div>

    <!-- <label for="binary" class="mt-2">
        <strong>{{ t("consent.read") }}</strong>
      </label> -->
    <div class="mt-4 flex justify-content-end" style="width: 100%">
      <p-button [label]="t('common.close')" (onClick)="okClicked()"> </p-button>
    </div>
  </div>
</ng-container>
