import { AfterViewInit, Directive, Renderer2 } from "@angular/core";
import { FileUpload } from "primeng/fileupload";

@Directive({ selector: "p-fileUpload" })
export class UploadDropAreaDirective implements AfterViewInit {
  constructor(
    private readonly fileUpload: FileUpload,
    private readonly renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    const className = "p-fileupload-content-drag";
    const content = this.fileUpload.content?.nativeElement;
    if (content) {
      this.renderer.listen(content, "dragenter", () => this.renderer.addClass(content, className));
      this.renderer.listen(content, "dragleave", () => this.renderer.removeClass(content, className));
      this.renderer.listen(content, "drop", () => this.renderer.removeClass(content, className));
    }
  }
}
