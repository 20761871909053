<ng-container *transloco="let t">
  <form [formGroup]="form" (ngSubmit)="save()">
    <div class="p-fluid mb-3">
      <div class="field-checkbox">
        <p-checkbox inputId="enabled" formControlName="enabled" [binary]="true"></p-checkbox>
        <label for="enabled">{{ t("enabled.title") }}</label>
        <small class="p-error" *ngIf="form.controls.enabled.errors?.required">{{ t("required") }}</small>
      </div>

      <div class="field">
        <label for="activities">{{ t("activityType.title") }}</label>
        <app-activity-type-selector inputId="activities" formControlName="activities"></app-activity-type-selector>
        <small class="p-error" *ngIf="form.controls.activities.errors?.required">{{ t("required") }}</small>
      </div>

      <div class="field">
        <label for="scopes">{{ t("scope.title") }}</label>
        <p-multiSelect
          inputId="scopes"
          [options]="scopes"
          [placeholder]="t('scope.placeholder')"
          [showClear]="true"
          formControlName="scopes"
        ></p-multiSelect>
        <small class="p-error" *ngIf="form.controls.scopes.errors?.required">{{ t("required") }}</small>
      </div>

      <div class="field" *ngIf="showDocumentTypeFilter">
        <label for="documents">{{ t("documentType.title") }}</label>
        <app-document-type-selector inputId="documents" formControlName="documents"></app-document-type-selector>
        <small class="p-error" *ngIf="form.controls.documents.errors?.required">{{ t("required") }}</small>
      </div>
    </div>

    <div class="flex flex-row justify-content-end">
      <p-button [label]="t('common.save')" icon="pi pi-check" type="submit" class="mr-2"></p-button>

      <p-button
        closeOnEscape
        [label]="t('common.close')"
        icon="pi pi-times"
        class="p-button-secondary"
        type="reset"
        (onClick)="close()"
      ></p-button>
    </div>
  </form>
</ng-container>
