<ng-container *transloco="let t">
  <p-dropdown
    #dropdown
    [options]="options"
    [placeholder]="t('common.select')"
    (onChange)="apply(dropdown.value)"
    scrollHeight="300px"
    [virtualScroll]="true"
    [virtualScrollItemSize]="37.5"
    [panelStyle]="{ width: '350px' }"
    panelStyleClass="no-overflow"
    styleClass="overlay-only"
  >
    <ng-template pTemplate="header">
      <div class="p-dropdown-header">
        <b>{{ t("documents.staticDataSelector.updateAll") }}</b>
        <div class="mt-3">
          <span [pTooltip]="t('documents.staticDataSelector.overwrite.tooltip')">
            {{ t("documents.staticDataSelector.overwrite.title") }}
          </span>
          <p-inputSwitch [(ngModel)]="overwriteExisting" [style]="{ float: 'right' }" />
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="item" let-item>
      <span [pTooltip]="item.label" tooltipPosition="bottom">{{ item.label }}</span>
    </ng-template>
  </p-dropdown>
  <p-confirmDialog [style]="{ width: '50vw' }" [key]="dialogKey" />
</ng-container>
