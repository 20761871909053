import { FormlyExtension, FormlyFieldConfig } from "@ngx-formly/core";

export type FormlyFieldConfigRoot = FormlyFieldConfig & { root: FormlyFieldConfig };

export class FormlyRootExtension implements FormlyExtension {
  prePopulate(field: FormlyFieldConfigRoot): void {
    let root: FormlyFieldConfig = field;
    while (root.parent) root = root.parent;
    field.root = root;
  }
}
