import { Directive, Host, Self, OnChanges, SimpleChanges, Input } from "@angular/core";
import { isArray, isDate } from "lodash";
import { Calendar } from "primeng/calendar";

// https://github.com/primefaces/primeng/issues/6841

/* Updating UX from Server:
  1. The Server send the date string in UTC. Example: "2023-08-04T00:00:00.000Z"
  2. The json date extension converts all dates in local dates: Example: Thu Aug 03 2023 21:00:00 GMT-0300 (Horário Padrão de Brasília). Hence the utc date and the local date   represent the same point in time but in different time zones
  3. In writeValue we convert the local date in a local date with the date component taken from UTC only.
Hence Thu Aug 03 2023 21:00:00 GMT-0300 (Horário Padrão de Brasília)  becomes Fri Aug 04 2023 00:00:00 GMT-0300 (Horário Padrão de Brasília)

Updating Model and Server from UX
 1. The UX sends Fri Aug 04 2023 00:00:00 GMT-0300 (Horário Padrão de Brasília)
 2. We Convert it to Thu Aug 03 2023 21:00:00 GMT-0300 (Horário Padrão de Brasília) before writing the value to the model
 3. When sending the model to the server the json date extension serializes the UTC date string "2023-08-04T00:00:00.000Z"


Hence the model always contains a local date/time which becomes the write UTC date when converting it to UTC*/

@Directive({ selector: "p-calendar" })
export class CalendarUtcFixDirective implements OnChanges {
  private readonly writeValueSource: Calendar["writeValue"];
  private readonly registerOnChangeSource: Calendar["registerOnChange"];
  private onChangeSource: Parameters<Calendar["registerOnChange"]>[0];
  private originalValue: any;

  @Input() showTime: boolean;

  get shouldFix(): boolean {
    return this.calendar.dataType === "date";
  }

  static localDateTimeWhichIsUTCDateValueToLocalDateTimeWhichRepresentsUTCDate(
    value: Date,
    useTime?: boolean
  ): Date | Date[] {
    const convert = (date: Date): Date =>
      new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          useTime ? date.getHours() : 0,
          useTime ? date.getMinutes() : 0,
          useTime ? date.getSeconds() : 0
        )
      );
    if (isArray(value)) {
      return value.map((x) => (x ? convert(x) : x)); // selection mode 'range' may have null values
    } else if (isDate(value)) {
      return convert(value);
    } else {
      return value;
    }
  }

  static localDateTimeToUTCDateInLocalDateTime(value: Date, useTime?: boolean): Date | Date[] {
    const convert = (date: Date): Date =>
      new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        useTime ? date.getUTCHours() : 0,
        useTime ? date.getUTCMinutes() : 0,
        useTime ? date.getUTCSeconds() : 0
      );
    if (isArray(value)) {
      return value.map((x) => (x ? convert(x) : x)); // selection mode 'range' may have null values
    } else if (isDate(value)) {
      return convert(value);
    } else {
      return value;
    }
  }

  constructor(@Host() @Self() private readonly calendar: Calendar) {
    this.writeValueSource = this.calendar.writeValue;
    this.calendar.writeValue = (value): void => this.writeValue(value);

    this.registerOnChangeSource = this.calendar.registerOnChange;
    this.calendar.registerOnChange = (fn): void => this.registerOnChange(fn);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showTime) {
      this.writeValueOnChange(this.originalValue, this.calendar.showTime);
    }
  }

  private writeValue(value: any): void {
    this.originalValue = value;

    if (this.shouldFix) {
      if (value) {
        value = CalendarUtcFixDirective.localDateTimeToUTCDateInLocalDateTime(value, this.calendar.showTime);
      }
    }
    return this.writeValueSource.call(this.calendar, value);
  }

  private writeValueOnChange(value: any, showTime: boolean): void {
    if (this.shouldFix) {
      if (value) {
        value = CalendarUtcFixDirective.localDateTimeToUTCDateInLocalDateTime(value, showTime);
      }
    }
    return this.writeValueSource.call(this.calendar, value);
  }

  private registerOnChange(fn: typeof this.onChangeSource): void {
    this.onChangeSource = fn;
    if (this.shouldFix) {
      fn = (value: any): void => this.onChange(value);
    }
    return this.registerOnChangeSource.call(this.calendar, fn);
  }

  private onChange(value: any): void {
    value = CalendarUtcFixDirective.localDateTimeWhichIsUTCDateValueToLocalDateTimeWhichRepresentsUTCDate(
      value,
      this.calendar.showTime
    );
    return this.onChangeSource(value);
  }
}
