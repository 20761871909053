<ng-container *appTranslate="let t">
  <div class="flex flex-wrap gap-3" *ngIf="showSourceOrganizationControlSwitch">
    <span class="align-content-center" [textContent]="t('vaccinations.overviewSource.title') + ':'"></span>
    <div class="flex align-items-center" *ngFor="let option of organizationOptions">
      <p-radioButton
        [name]="field.name || id"
        [inputId]="field.name || id"
        [formControl]="sourceOrganizationControlSwitch"
        [value]="option.value"
      ></p-radioButton>
      <label for="{{ field.name || id }}" class="ml-2">{{ option.label }}</label>
    </div>
  </div>

  <p-messages *ngIf="messages" [(value)]="messages" [enableService]="false" [closable]="to.closeable"> </p-messages>
</ng-container>
