<ng-container *transloco="let t">
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="saved.emit(form.value)">
    <p-card [header]="t('userFeedback.create')">
      <div class="p-fluid">
        <div class="field">
          <p>{{ t("userFeedback.text") }}</p>
        </div>

        <div class="field">
          <label for="overallRating">{{ t("userFeedback.overallRating") }}</label>
          <p-rating formControlName="overallRating" [cancel]="false"></p-rating>
        </div>

        <div class="field">
          <label for="usabilityRating">{{ t("userFeedback.usabilityRating") }}</label>
          <p-rating formControlName="usabilityRating" [cancel]="false"></p-rating>
        </div>

        <div class="field">
          <label for="functionalityRating">{{ t("userFeedback.functionalityRating") }}</label>
          <p-rating formControlName="functionalityRating" [cancel]="false"></p-rating>
        </div>

        <div class="field">
          <label for="performanceRating">{{ t("userFeedback.performanceRating") }}</label>
          <p-rating formControlName="performanceRating" [cancel]="false"></p-rating>
        </div>

        <div class="field">
          <label for="comment">{{ t("userFeedback.freeTextTitle") }}</label>
          <textarea id="comment" pInputTextarea rows="6" formControlName="comment" maxlength="10000"></textarea>
        </div>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [disabled]="!form.valid || !form.dirty || disabled"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
          ></p-button>
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="closed.emit()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
