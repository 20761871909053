export async function delay(timeInMs: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, timeInMs);
  });
}

export async function freeCPUCyclesForOtherWork(): Promise<void> {
  await delay(1);
}

export async function randomDelay(minTimeInMs: number, maxTimeInMs: number): Promise<void> {
  const randomDelayInMs = minTimeInMs + Math.random() * (maxTimeInMs - minTimeInMs);
  return new Promise((resolve) => {
    setTimeout(resolve, randomDelayInMs);
  });
}
