import { Component, DoCheck, ElementRef, HostBinding, ViewChild } from "@angular/core";

@Component({
  selector: "app-show-more",
  templateUrl: "./show-more.component.html",
  styleUrls: ["./show-more.component.scss"],
})
export class ShowMoreComponent implements DoCheck {
  @HostBinding("class.collapsed")
  isCollapsed = true;

  @ViewChild("text") textElementRef: ElementRef | undefined;

  isEllipsisActive: boolean | null = null;

  toggleReadMore(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  ngDoCheck(): void {
    if (this.isCollapsed && this.textElementRef) {
      this.isEllipsisActive =
        this.textElementRef.nativeElement.offsetWidth < this.textElementRef.nativeElement.scrollWidth ||
        this.textElementRef.nativeElement.offsetHeight < this.textElementRef.nativeElement.scrollHeight;
    }
  }
}
