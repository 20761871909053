import { Injectable } from "@angular/core";
import { DefaultTranspiler, TranspileParams } from "@jsverse/transloco";
import { ApplicationInsightsService } from "../services/application-insights.service";

// We do not want that e failed interpolation of a string causes an error

@Injectable({ providedIn: "root" })
export class RelaxedTranslocoTranspiler extends DefaultTranspiler {
  override transpile(params: TranspileParams): any {
    try {
      return super.transpile(params);
    } catch (e) {
      ApplicationInsightsService.bigTroubleNotifier("Transloco Transpiler. Interpolation failed", {
        exception: e,
        exceptionMessage: e.message,
        key: params.key,
        value: params.value,
        params: params.params,
      });
      return params.key;
    }
  }
}
