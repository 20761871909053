import { Injectable } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import userflow from "userflow.js";
import { environment } from "../../../environments/environment";
import { mobileAndTabletCheck } from "../../shared/services/mobileDevice.helper";
import { SettingsService } from "../../shared/services/settings.service";
import { OrganizationsService } from "../organizations/organizations.service";
@Injectable({ providedIn: "root" })
export class UserFlowService {
  constructor(
    private readonly settings: SettingsService,
    private readonly languageService: TranslocoService,
    private readonly organizationsService: OrganizationsService
  ) {}

  init(): void {
    userflow.init(environment.userFlowToken);

    userflow.identify(this.settings.userOrCandidateId, {
      displayId: this.settings.userDisplayId,
      organization: this.settings.organizationName,
      role: this.settings.isCandidate ? "candidate" : "user",
      language: this.languageService.getActiveLang(),
      onMobile: mobileAndTabletCheck(),
      isLicensed: this.settings.isLicensed,
    });

    this.organizationsService.getOrganization(this.settings.organizationId).subscribe((organization) => {
      userflow.updateUser({ organization: organization.name });
    });

    this.languageService.langChanges$.subscribe((lang) => {
      userflow.updateUser({ language: lang });
    });
  }
}
