import { Directive, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { BehaviorSubject, debounceTime, distinctUntilChanged } from "rxjs";

@Directive({
  selector: "[hover]",
})
export class HoverDirective {
  private readonly isActiveSubject = new BehaviorSubject(false);

  @Input()
  preventBodyDrop = true;

  @Input()
  hover = false;

  @Output()
  readonly hoverChange = new EventEmitter<boolean>();

  constructor() {
    this.isActiveSubject.pipe(distinctUntilChanged(), debounceTime(100)).subscribe((active) => {
      this.hover = active;
      this.hoverChange.emit(active);
    });
  }

  @HostListener("mouseenter", ["$event"])
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isActiveSubject.next(true);
  }

  @HostListener("mouseleave", ["$event"])
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isActiveSubject.next(false);
  }

  @HostListener("body:mouseenter", ["$event"])
  onBodyDragOver(event: DragEvent): void {
    if (this.preventBodyDrop) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener("body:mouseleave", ["$event"])
  onBodyDrop(event: DragEvent): void {
    if (this.preventBodyDrop) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
