import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ImageViewerComponent } from "./image-viewer.component";
import { ImageViewerDirective } from "./image-viewer.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [ImageViewerComponent, ImageViewerDirective],
  exports: [ImageViewerComponent],
})
export class ImageViewerModule {}
