import { Injectable } from "@angular/core";
import {
  SetNotificationSettingsGQL,
  GetNotificationSettingsGQL,
  NotificationSettingsFragment,
  NotificationSettingsInput,
  ClearNotificationGQL,
  ClearAllNotificationsGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class NotificationSettingsService {
  constructor(
    private readonly settingsGet: GetNotificationSettingsGQL,
    private readonly settingsSet: SetNotificationSettingsGQL,
    private readonly notificationClear: ClearNotificationGQL,
    private readonly allNotificationsClear: ClearAllNotificationsGQL
  ) {}

  get(): Observable<NotificationSettingsFragment> {
    return this.settingsGet.fetch().pipe(map((x) => x.data.getNotificationSettings));
  }

  set(settings: NotificationSettingsInput): Observable<NotificationSettingsFragment> {
    return this.settingsSet.mutate({ input: settings }).pipe(map((x) => x.data.setNotificationSettings));
  }

  clear(activityId: string): Observable<boolean> {
    return this.notificationClear.mutate({ id: activityId }).pipe(map((x) => x.data.clearNotification));
  }

  clearAll(): Observable<boolean> {
    return this.allNotificationsClear.mutate().pipe(map((x) => x.data.clearAllNotifications));
  }
}
