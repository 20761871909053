export function getContainerNameFromTask(task: {
  organizationId: string;
  candidateId?: string;
  rootId: string;
  candidateOrganizationId?: string;
}): string {
  if (task.candidateId != null) {
    return makeContainerName(task.candidateOrganizationId, task.candidateId);
  } else {
    return getContainerNameForProcessFiles(task.organizationId, task.rootId);
  }
}

export function makeContainerName(organizationId: string, candidateId?: string): string {
  return candidateId
    ? `${organizationId.substring(0, 25)}-${candidateId.substring(0, 37)}`.toLowerCase() // Blobname max is 63 character
    : organizationId.substring(0, 63).toLowerCase();
}

export function getContainerNameForProcessFiles(organizationId: string, processId: string): string {
  return `${organizationId.substring(0, 25)}-${processId.substring(0, 37)}`.toLowerCase();
}
