<ng-container *transloco="let t">
  <span class="mr-2" style="color: white" *ngIf="translationProposalService.isOn">{{
    t("translationProposal.activated")
  }}</span>
  <i
    [pTooltip]="
      this.translationProposalService.isOn ? t('translationProposal.turnOff') : t('translationProposal.tooltip')
    "
    [class]="icon"
    (click)="onClick($event)"
    [style]="
      this.translationProposalService.canBeActivated || this.translationProposalService.isOn ? null : { color: 'gray' }
    "
  ></i>
</ng-container>
