<ng-container *appTranslate="let t">
  <form *ngIf="form" [formGroup]="form">
    <p-card>
      <p-toolbar styleClass="mb-4">
        <ng-template pTemplate="left">
          <h5 class="mr-4">{{ t("vaccinationConfig.title") }}</h5>
        </ng-template>
        <ng-template pTemplate="right">
          <div class="flex justify-content-between">
            <app-edit-tool-bar
              class="flex flex-row justify-content-end"
              [showBtn]="{ cancel: true, save: true }"
              [saveBtn]="{ disabled: !form.valid || !form.dirty }"
              [cancelBtn]="{ disabled: !form.dirty }"
              (cancel)="cancel()"
              (save)="save()"
            >
            </app-edit-tool-bar>
          </div>
        </ng-template>
      </p-toolbar>

      <p-tabView>
        <p-tabPanel header="DE" [headerStyleClass]="form.controls.de.valid ? '' : 'p-error'">
          <app-required-vaccinations-table
            #de
            [staticDataContext]="{ organizationId: organizationId, immigrationCountry: SupportedImmigrationCountry.De }"
            [form]="form.controls.de"
          ></app-required-vaccinations-table>
        </p-tabPanel>

        <p-tabPanel header="AT" [headerStyleClass]="form.controls.at.valid ? '' : 'p-error'">
          <app-required-vaccinations-table
            [staticDataContext]="{ organizationId: organizationId, immigrationCountry: SupportedImmigrationCountry.At }"
            [form]="form.controls.at"
          ></app-required-vaccinations-table>
        </p-tabPanel>
      </p-tabView>
    </p-card>
  </form>
</ng-container>
