import { Injectable } from "@angular/core";
import { StaticDataType } from "@ankaadia/graphql";
import { translate } from "@ngneat/transloco";
import { CandidateProfile } from "../../features/candidates/candidates.service";
import { DefaultSpecific } from "../default/default-specifics";
import { CandidateProfileTab, SupportedTaskTypes } from "../organization-specific";
import { SigniteProfileFields } from "./signite-profile-fields.model";
import { PrimeIcons } from "primeng/api";
import { uniq } from "lodash";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { v4 as uuidv4 } from "uuid";

@Injectable({ providedIn: "root" })
export class SigniteSpecific extends DefaultSpecific {
  override getCandidateProfileTabs(): CandidateProfileTab[] {
    return [
      ...super.getCandidateProfileTabs(),
      {
        id: "signite-overview",
        label: translate("overview.title"),
        icon: PrimeIcons.TABLE,
        fields: [
          { key: "firstname", label: translate("firstName.title") },
          { key: "lastname", label: translate("lastName.title") },
          { key: "phone", label: translate("phone.title") },
          { key: "countryOfOrigin", label: translate("countryOfOrigin.title") },
          {
            key: SigniteProfileFields.Degree,
            label: translate("qualification.degree"),
            type: "dropdown",
            props: {
              label: translate("qualification.degree"),
              placeholder: translate("qualification.placeholder"),
              options: this.staticDataService.getStaticData(StaticDataType.Education, this.language),
              filter: true,
              filterBy: "label",
            },
          },
          {
            key: SigniteProfileFields.Occupation,
            label: translate("occupation.title"),
            type: "profession",
            props: {
              label: translate("occupation.title"),
              all: true,
            },
          },
          {
            key: SigniteProfileFields.ExperienceField,
            label: translate("experienceField.title"),
            type: "multiselect",
            props: {
              label: translate("experienceField.title"),
              placeholder: translate("experienceField.placeholder"),
              showToggleAll: false,
              options: this.staticDataService.getStaticData(StaticDataType.ProfessionalFields, this.language),
            },
          },
          {
            key: SigniteProfileFields.GermanLanguageSkill,
            label: translate("skillLevel.title"),
            type: "dropdown",
            props: {
              label: translate("skillLevel.title"),
              placeholder: translate("skillLevel.placeholder"),
              options: this.staticDataService.getStaticData(StaticDataType.LanguageLevels, this.language),
              filter: true,
              filterBy: "label",
            },
          },
          { key: "preferredCareFacility", label: translate("preferences.careFacility.title") },
          { key: "preferredWorkingField", label: translate("preferences.field.title") },
          { key: "preferredLocationState", label: translate("preferences.state.title") },
          { key: "preferredCity", label: translate("preferences.city.title") },
          { key: "preferredPathOfRecognition", label: translate("preferences.pathOfRecognition.title") },
          {
            key: "preferredLanguageLevelForInterview",
            label: translate("preferences.languageLevelForInterview.title"),
          },
          { key: "preferredLanguageLevelForTransfer", label: translate("preferences.languageLevelForTransfer.title") },
          { key: "preferredStartDate", label: translate("preferences.preferredStartDate.title") },
          { key: "preferredEmploymentType", label: translate("preferences.employmentType.title") },
          { key: "preferredTemporalScope", label: translate("preferences.temporalScope.title") },
        ],
      },
    ];
  }

  override loadProfile(profile: CandidateProfile, tabs: CandidateProfileTab[]): void {
    super.loadProfile(profile, tabs);

    if (this.hasField(tabs, SigniteProfileFields.Degree)) {
      profile[SigniteProfileFields.Degree] = profile.qualifications?.[0]?.qualification;
    }

    if (this.hasField(tabs, SigniteProfileFields.Occupation)) {
      profile[SigniteProfileFields.Occupation] = profile.qualifications?.[0]?.occupation;
    }

    if (this.hasField(tabs, SigniteProfileFields.ExperienceField)) {
      profile[SigniteProfileFields.ExperienceField] = profile.experiences?.[0]?.experienceField;
    }

    if (this.hasField(tabs, SigniteProfileFields.GermanLanguageSkill)) {
      profile[SigniteProfileFields.GermanLanguageSkill] = profile.languageSkills?.find(
        (s) => s.language === "DE"
      )?.skillLevel;
    }
  }

  override saveProfile(profile: CandidateProfile, tabs: CandidateProfileTab[]): void {
    super.saveProfile(profile, tabs);

    if (this.hasField(tabs, SigniteProfileFields.Degree)) {
      const degree = profile[SigniteProfileFields.Degree];
      if (degree) {
        if (!profile.qualifications?.length) {
          profile.qualifications = [{ id: uuidv4() }];
        }
        profile.qualifications[0].qualification = degree;
        delete profile[SigniteProfileFields.Degree];
      }
    }

    if (this.hasField(tabs, SigniteProfileFields.Occupation)) {
      const occupation = profile[SigniteProfileFields.Occupation];
      if (occupation) {
        if (!profile.qualifications?.length) {
          profile.qualifications = [{ id: uuidv4() }];
        }
        profile.qualifications[0].occupation = occupation;
        delete profile[SigniteProfileFields.Occupation];
      }
    }

    if (this.hasField(tabs, SigniteProfileFields.ExperienceField)) {
      const experienceField = profile[SigniteProfileFields.ExperienceField];
      if (experienceField) {
        if (!profile.experiences?.length) {
          profile.experiences = [
            {
              id: uuidv4(),
            },
          ];
        }
        profile.experiences[0].experienceField = experienceField;
        delete profile[SigniteProfileFields.ExperienceField];
      }
    }

    if (this.hasField(tabs, SigniteProfileFields.GermanLanguageSkill)) {
      const germanLanguageSkill = profile[SigniteProfileFields.GermanLanguageSkill];
      if (germanLanguageSkill) {
        if (!profile.languageSkills?.find((s) => s.language === "DE")) {
          profile.languageSkills = profile.languageSkills ?? [];
          profile.languageSkills.push({
            language: "DE",
            id: uuidv4(),
          });
        }
        profile.languageSkills.find((s) => s.language === "DE").skillLevel = germanLanguageSkill;
        delete profile[SigniteProfileFields.GermanLanguageSkill];
      }
    }
  }

  override getSupportedTaskTypes(): SupportedTaskTypes[] {
    return uniq(super.getSupportedTaskTypes().concat(["ProcessTaskAutoOperationModel"]));
  }

  private hasField(tabs: CandidateProfileTab[], field: SigniteProfileFields): boolean {
    return tabs
      ?.find((x) => x.id === "signite-overview")
      ?.fields?.flatMap((x: FormlyFieldConfig) => x.fieldGroup)
      ?.some((x) => x.key === field);
  }
}
