<ng-container *appTranslate="let t">
  <p-card styleClass="flex flex-column">
    <ng-template pTemplate="header">
      <div class="pt-5 pb-4 pl-3 pr-3">
        <div class="pl-1 pr-1 pt-2 mt-1">
          <h3>{{ t("activityLog.title") }}</h3>
        </div>
      </div>
    </ng-template>

    <p-splitter
      *ngIf="organizations$ | async as organizations"
      class="app-form block"
      styleClass="app-form activities-splitter"
      [panelSizes]="[20, 80]"
      [minSizes]="[10, 30]"
      stateKey="ankaadia_activity_splitter"
    >
      <ng-template pTemplate>
        <div class="p-overflow-auto w-full p-2">
          <p-blockUI [target]="filterComponent" [blocked]="isFilterBlocked"></p-blockUI>
          <app-activity-filter
            #filterComponent
            [disabled]="isLoading"
            [organizations]="organizations"
            [(filter)]="filter"
            (busy)="isFilterBlocked = $event"
          ></app-activity-filter>
        </div>
      </ng-template>

      <ng-template pTemplate>
        <div class="p-overflow-auto w-full p-2">
          <app-activity-table
            [activities]="activities"
            [totalActivities]="totalActivities"
            [lazy]="true"
            (pageChange)="onPageChange($event)"
          ></app-activity-table>
        </div>
      </ng-template>
    </p-splitter>
  </p-card>
</ng-container>
