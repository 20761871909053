/** Ensures that the immigration country is a valid country and not a placeholder like UNKNOWN. */
export function toRealImmigrationCountry(immigrationCountry: string | null): string | null {
  if (isPlaceholderImmigrationCountry(immigrationCountry)) {
    return defaultImmigrationCountry();
  }
  return immigrationCountry;
}

/** Ensures that the list only contains valid countries and not placeholders like UNKNOWN. */
export function toRealImmigrationCountries(immigrationCountries: string[] | null): string[] | null {
  return immigrationCountries?.filter((x) => isRealImmigrationCountry(x));
}

/** Checks if the immigration country is a real country and not a placeholder like UNKNOWN. */
export function isRealImmigrationCountry(immigrationCountry: string): boolean {
  return !isPlaceholderImmigrationCountry(immigrationCountry);
}

/** Sorts the immigration countries alphabetically, placing the default country on top. */
export function sortImmigrationCountries<T extends { label: string; value: string }>(
  immigrationCountries: T[] | null,
  language: string
): T[] | null {
  return immigrationCountries?.sort((left, right) => {
    if (left.value === defaultImmigrationCountry()) {
      return -1;
    }
    return left.label.localeCompare(right.label, language);
  });
}

/** Checks if the immigration country is a placeholder like UNKNOWN. */
export function isPlaceholderImmigrationCountry(immigrationCountry: string): boolean {
  return immigrationCountry === "UNKNOWN";
}

/** Returns the default immigration country. */
export function defaultImmigrationCountry(): string {
  return "DE";
}
