import { Injectable } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { DocumentDropZoneForm, DocumentDropZoneRow, DocumentDropZoneRowForm } from "./document-dropzone-form.model";

@Injectable({ providedIn: "root" })
export class DocumentDropZoneFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createForm(rows?: DocumentDropZoneRow[]): DocumentDropZoneForm {
    const settings = rows ?? [];
    return this.formBuilder.group<DocumentDropZoneForm["controls"]>({
      rows: this.formBuilder.array<DocumentDropZoneRowForm>(
        settings.map((requirement) => this.createFileSettingForm(requirement))
      ),
    });
  }

  createFileSettingForm(row: DocumentDropZoneRow): DocumentDropZoneRowForm {
    const { candidateEntry, familyMember, documentType, documentSet } = row;
    return this.formBuilder.group<DocumentDropZoneRowForm["controls"]>({
      file: new FormControl<File>(row.file),
      fileId: this.formBuilder.control(row.fileId),
      fileUrl: this.formBuilder.control(row.fileUrl),
      fileType: this.formBuilder.control(row.fileType),
      extraction: this.formBuilder.control(row.extraction),
      candidateEntry: this.formBuilder.control(candidateEntry),
      familyMember: this.formBuilder.control(familyMember),
      documentType: this.formBuilder.control(documentType),
      documentSet: this.formBuilder.control(documentSet),
    });
  }
}
