<ng-container *appTranslate="let t">
  <p-table
    dataKey="id"
    [value]="activities"
    [totalRecords]="totalActivities"
    [paginator]="lazy"
    [lazy]="lazy"
    [rows]="50"
    [rowHover]="true"
    [sortOrder]="-1"
    (onLazyLoad)="pageChange.emit($event)"
    class="p-overflow-auto w-full"
    styleClass="p-datatable p-datatable-sm app-form"
    paginatorStyleClass="p-paginator-last-hide"
    sortField="date"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap" pSortableColumn="type">
          {{ t("type.title") }}
          <p-sortIcon field="type"></p-sortIcon>
        </th>

        <th *ngIf="showCandidate" class="white-space-nowrap" pSortableColumn="candidate.name">
          {{ t("candidate.title") }}
          <p-sortIcon field="candidate.name"></p-sortIcon>
        </th>

        <th class="white-space-nowrap">{{ t("description.title") }}</th>

        <th *ngIf="showProcess" class="white-space-nowrap" pSortableColumn="process.name">
          {{ t("process.title") }}
          <p-sortIcon field="process.name"></p-sortIcon>
        </th>

        <th *ngIf="showUser" class="white-space-nowrap" pSortableColumn="user.name">
          {{ t("user.title") }}
          <p-sortIcon field="user.name"></p-sortIcon>
        </th>

        <th *ngIf="showDate" class="white-space-nowrap" pSortableColumn="date">
          {{ t("date.title") }}
          <p-sortIcon field="date"></p-sortIcon>
        </th>

        <th class="white-space-nowrap">&nbsp;</th>

        <th *ngIf="previewableFilesExist" class="white-space-nowrap">{{ t("document.title") }}</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-activity>
      <tr>
        <td class="white-space-nowrap">
          <app-activity-icon [type]="activity.type"></app-activity-icon>
          {{ activity.type | enum: "ActivityType" }}
        </td>

        <td class="white-space-nowrap" *ngIf="showCandidate">
          <ng-container *ngIf="activity.candidate">
            <div>
              <a
                *ngIf="activity.type !== ActivityType.CandidateDeleted"
                [routerLink]="['/app/candidates/edit', activity.candidate.organizationId, activity.candidate.id]"
              >
                {{ activity.candidate.displayId }}
              </a>
              <div *ngIf="activity.type === ActivityType.CandidateDeleted">{{ activity.candidate.displayId }}</div>
            </div>

            <div>{{ activity.candidate.name }}</div>

            <div *ngIf="activity.candidate.organizationId !== organizationId">
              ({{ activity.candidate.organizationName }})
            </div>
          </ng-container>
        </td>

        <td style="min-width: 300px; max-width: 400px">
          <app-activity-description [activity]="activity"></app-activity-description>
        </td>

        <td *ngIf="showProcess" class="white-space-nowrap">
          <a
            *ngIf="activity.process"
            [routerLink]="['/app/processes/view', activity.process.organizationId, activity.process.id]"
          >
            {{ activity.process.name }}
          </a>
        </td>

        <td *ngIf="showUser" class="white-space-nowrap">
          <ng-container *ngIf="activity.user">
            <ng-container
              *ngIf="
                activity.process?.organizationId === organizationId ||
                activity.user.organizationId === organizationId ||
                activity.candidate?.organizationId === organizationId ||
                activity.user.organizationName === 'SystemOrganization'
              "
            >
              <div>{{ activity.user.name }}</div>
              <div
                *ngIf="
                  activity.user.organizationId !== organizationId &&
                  activity.user.organizationName !== 'SystemOrganization'
                "
              >
                ({{ activity.user.organizationName }})
              </div>
            </ng-container>
          </ng-container>
        </td>

        <td *ngIf="showDate" class="white-space-nowrap">
          {{ activity.date | appDateTime: { dateStyle: "short", timeStyle: "medium" } : language }}
        </td>

        <td class="white-space-nowrap">
          <p-button
            *ngIf="!showProcess && !showUser && !showDate"
            icon="pi pi-info"
            styleClass="mr-2"
            [disabled]="disabled"
            [pTooltip]="t('common.more')"
            (onClick)="showInfo(activity, $event)"
          ></p-button>

          <p-button
            *ngIf="showNotificationClear"
            icon="pi pi-trash"
            [disabled]="disabled"
            [pTooltip]="t('common.clear')"
            (onClick)="notificationCleared.emit(activity)"
          ></p-button>

          <p-button
            *ngIf="activity.parameters?.diff && activity.parameters?.old && activity.parameters?.new"
            icon="pi pi-info-circle"
            styleClass="p-button-rounded p-button-text mr-2"
            [disabled]="disabled"
            [pTooltip]="t('differences.title')"
            (onClick)="showDiff(activity, $event)"
          ></p-button>
        </td>

        <td class="white-space-nowrap">
          <ng-container *ngIf="hasPreviewableFiles(activity)">
            <p-chip
              styleClass="m-1 table-chip"
              icon="pi pi-eye"
              *ngFor="let previewableFile of activity.previewableFiles"
              [label]="getFileLabel(previewableFile)"
              (click)="showFile(previewableFile)"
            >
            </p-chip>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-overlayPanel #infoPanel>
    <ng-template pTemplate>
      <app-activity-info [activity]="selectedActivity"></app-activity-info>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #diffPanel>
    <ng-template pTemplate>
      <app-activity-diff [diff]="selectedActivityDiff"></app-activity-diff>
    </ng-template>
  </p-overlayPanel>

  <p-sidebar [(visible)]="showSidebar" styleClass="p-sidebar-lg">
    <app-document-preview-dialog
      *ngIf="selectedFile"
      [document]="selectedFile.document"
      [file]="selectedFile.file"
      [fileName]="selectedFile.file.name"
      [url]="selectedFile.url"
      (closed)="close()"
    ></app-document-preview-dialog>
  </p-sidebar>
</ng-container>
