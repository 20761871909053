import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class CurrentCollectionService {
  collectionId: string;
  organizationId: string;
  organizationName: string;
  collectionName: string;
  sharingId: string;
  sharingOrganizationId: string;
  sharingETag: string;

  get hasCollection(): boolean {
    return !!this.collectionId && !!this.organizationId;
  }

  reset(): void {
    this.collectionId = null;
    this.organizationId = null;
    this.collectionName = null;
    this.sharingId = null;
    this.sharingOrganizationId = null;
    this.sharingETag = null;
  }

  set(
    collectionId: string | null,
    organizationId: string | null,
    collectionName: string | null,
    sharingId: string | null,
    sharingOrganizationId: string | null,
    sharingETag: string | null,
    organizationName: string | null
  ): void {
    this.collectionId = collectionId;
    this.organizationId = organizationId;
    this.collectionName = collectionName;
    this.sharingId = sharingId;
    this.sharingOrganizationId = sharingOrganizationId;
    this.sharingETag = sharingETag;
    this.organizationName = organizationName;
  }
}
