<ng-container
  *ngIf="
    (mode == 'Configuration' &&
      documentForm.value?.mode == DocumentModeEnum.Template &&
      documentForm.value?.templateMode == DocumentTemplateModeEnum.Xlsx &&
      documentForm.value?.generationMode == DocumentGenerationModeEnum.AllCandidates) ||
    mode == 'Generation'
  "
>
  <ng-container *appTranslate="let t">
    <div class="field" *ngIf="mode == 'Configuration'">
      <label>{{ t("dataStrategy.title") }}</label>
      <p-dropdown
        [options]="availableStrategies"
        [(ngModel)]="selectedStrategyId"
        optionValue="value"
        [showClear]="false"
        (onChange)="selectedStrategyChanged()"
      >
      </p-dropdown>
    </div>
    <p-fieldset *ngIf="mode == 'Configuration'" [legend]="t('dataStrategy.settings')">
      <div class="p-fluid">
        <formly-form
          *ngIf="configurationConfigFields"
          [fields]="configurationConfigFields"
          [model]="configurationConfigData"
          [form]="configurationForm"
          (modelChange)="modelChanged.emit($event)"
        ></formly-form>
        <p *ngIf="configurationConfigFields?.length == 0">{{ t("dataStrategy.noFilterSettings") }}</p>
      </div>
    </p-fieldset>

    <div *ngIf="mode == 'Generation'">
      <p-fieldset [legend]="t('description.title')" *ngIf="reportTemplateDescription">
        <div class="field" *ngIf="reportTemplateDescription">
          <p class="reportDescription" [innerHTML]="reportTemplateDescription | safe: 'html'"></p>
        </div>
      </p-fieldset>
      <p-fieldset [legend]="t('dataStrategy.settings')">
        <div class="p-fluid">
          <formly-form
            *ngIf="generationConfigFields"
            [fields]="generationConfigFields"
            [model]="generationConfigData"
            [form]="configurationForm"
            (modelChange)="modelChanged.emit($event)"
          ></formly-form>
          <p *ngIf="generationConfigFields?.length == 0">{{ t("dataStrategy.noFilterSettings") }}</p>
        </div>
      </p-fieldset>
    </div>
  </ng-container>
</ng-container>
