<ngx-extended-pdf-viewer
  *ngIf="fileType == 'pdf'"
  [showOpenFileButton]="false"
  [showDownloadButton]="true"
  [showInfiniteScrollButton]="false"
  [showBookModeButton]="false"
  [showDrawEditor]="false"
  [showStampEditor]="false"
  [showPrintButton]="true"
  [showTextEditor]="true"
  [showPresentationModeButton]="false"
  [showUnverifiedSignatures]="true"
  [showBorders]="false"
  [textLayer]="true"
  [src]="url"
  [filenameForDownload]="fileName"
  [useBrowserLocale]="true"
  [language]="language"
  [height]="height"
  (formDataChange)="pdfFormDataChanged($event)"
  (annotationEditorModeChanged)="annotationModeChanged($event)"
  (pdfLoadingStarts)="loadingStarts()"
></ngx-extended-pdf-viewer>

<app-image-viewer *ngIf="fileType == 'image'" [url]="url"></app-image-viewer>

<ngx-doc-viewer
  *ngIf="
    fileType == 'docx' ||
    fileType == 'doc' ||
    fileType == 'xls' ||
    fileType == 'xlsx' ||
    fileType == 'ppt' ||
    fileType == 'pptx'
  "
  [url]="url"
  viewer="office"
  style="height: 100%"
></ngx-doc-viewer>
