import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { StaticDataModel, StaticDataType, TreeDataModel } from "@ankaadia/graphql";
import { TranslocoService, translate } from "@ngneat/transloco";
import { cloneDeep } from "lodash";
import { TreeNode } from "primeng/api";
import { Tree } from "primeng/tree";
import { forkJoin } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { MessageService } from "../../message/message.service";
import { ProfessionConfigurationService } from "../profession-configuration.service";
import { ProfessionService } from "../profession.service";

@Component({
  selector: "app-profession-edit",
  templateUrl: "./profession-edit.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfessionEditComponent implements AfterViewInit {
  private _professionSelection: TreeNode[];
  private initialProfessionSetup = false;

  readonly language = this.transloco.getActiveLang();
  readonly functions$ = this.staticDataService.getStaticData(StaticDataType.Functions, this.language);
  readonly form = this.formBuilder.group({
    id: this.formBuilder.control<string>(null),
    _etag: this.formBuilder.control<string>(null),
    organizationId: this.formBuilder.control(this.settings.organizationId),
    professions: this.formBuilder.control<string[]>(null),
    defaultFunction: this.formBuilder.control<string>(null),
    defaultProfession: this.formBuilder.control<string>(null),
  });

  @ViewChild(Tree)
  readonly professionSelector: Tree;

  get professionSelection(): TreeNode | TreeNode[] {
    return this._professionSelection;
  }

  set professionSelection(professionSelection: TreeNode | TreeNode[]) {
    this._professionSelection = professionSelection as TreeNode[];
    this.selectedProfessions = this._professionSelection
      ?.filter((x) => !x.children)
      .sort((a, b) => a.label?.localeCompare(b.label))
      .map((x) => ({ value: x.data, label: x.label }));
    this.form.controls.professions.setValue(this.selectedProfessions?.map((x) => x.value) ?? null);
    if (!this.initialProfessionSetup) {
      this.form.controls.professions.markAsDirty();
    } else {
      this.initialProfessionSetup = false;
    }
  }

  professions: TreeDataModel[];
  selectedProfessions: StaticDataModel[];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService,
    private readonly router: Router,
    private readonly settings: SettingsService,
    private readonly professionService: ProfessionService,
    private readonly professionConfigService: ProfessionConfigurationService,
    private readonly messageService: MessageService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    forkJoin([
      this.professionService.getProfessionTree(this.language, this.settings.organizationId),
      this.professionConfigService.get(),
    ]).subscribe(([professions, config]) => {
      this.professions = cloneDeep(professions);
      this.changeDetector.detectChanges();

      this.form.setValue({
        id: config.id,
        _etag: config._etag,
        organizationId: config.organizationId,
        professions: config.professions ?? null,
        defaultFunction: config.defaultFunction ?? null,
        defaultProfession: config.defaultProfession ?? null,
      });
      this.initialProfessionSetup = true;
      this.setProfessionSelection(config.professions);
      this.changeDetector.detectChanges();
    });
  }

  save(): void {
    this.professionConfigService.set(this.form.getRawValue()).subscribe(() => {
      this.messageService.add({ severity: "success", summary: translate("professions.saved") });
      this.cancel();
    });
  }

  cancel(): void {
    this.router.navigate(["/app"]);
  }

  private setProfessionSelection(professions: string[]): void {
    for (const area of this.professionSelector.value ?? []) {
      for (const subArea of area.children ?? []) {
        for (const profession of subArea.children ?? []) {
          if (professions?.includes(profession.data)) {
            this.professionSelector.propagateDown(profession, true);
            this.professionSelector.propagateUp(subArea, true);
            this.professionSelector.propagateUp(area, true);
          }
        }
      }
    }
    this.professionSelection = this.professionSelector.selection;
  }
}
